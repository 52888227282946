import * as React from 'react';
import { SVGProps } from 'react';
const SvgAutoRecarga = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <g data-name="Capa 1">
      <path
        d="M15 0C6.72 0 0 6.72 0 15s6.72 15 15 15 15-6.72 15-15S23.28 0 15 0Zm3.87 20.67-5.53-2.77c-.61-.3-.94-.94-.89-1.58-.02-.02.6-7.18.58-7.2.12-1.58 2.49-1.57 2.61 0l.5 6.19 4.1 3.14c1.25.94 0 2.92-1.38 2.21Z"
        style={{
          fillRule: 'evenodd',
          fill: '#abadc4',
          opacity: 0.4,
        }}
      />
      <path
        d="M15 3.91C8.88 3.91 3.91 8.87 3.91 15S8.87 26.09 15 26.09 26.09 21.13 26.09 15 21.12 3.91 15 3.91Zm3.87 16.76-5.53-2.77c-.61-.3-.94-.94-.89-1.58-.02-.02.6-7.18.58-7.2.12-1.58 2.49-1.57 2.61 0l.5 6.19 4.1 3.14c1.25.94 0 2.92-1.38 2.21Z"
        style={{
          fillRule: 'evenodd',
          fill: '#abadc4',
        }}
      />
    </g>
  </svg>
);
export default SvgAutoRecarga;
