import { Modal } from 'react-bootstrap';

export const ReferralTerms = ({
  isVisible,
  onHide,
}: {
  isVisible: boolean;
  onHide: () => void;
}) => {
  return (
    <Modal show={isVisible} onHide={onHide} centered>
      <Modal.Header closeButton className="mega-modal-title">
        <Modal.Title>Condiciones</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Para ganar la comisión usted no puede crear una cuenta referida por usted mismo. SOLO
          validamos esta oferta si se cumplen las siguientes condiciones.
        </p>
        <ul>
          <li className="mb-3">
            El nuevo cliente debe registrarse y hacer un pago en cualquiera de nuestros servicios.
          </li>
          <li className="mb-3">
            Su cuenta debe estar activa con algún pago realizado en menos de 6 meses.
          </li>
          <li className="mb-3">Su dirección IP y la del nuevo cliente deben ser diferentes.</li>
          <li className="mb-3">
            Si el pago se realiza por Tarjeta de Crédito no puede ser una tarjeta que haya sido
            utilizada en nuestro sitio.
          </li>
          <li>
            Si el pago se realiza por PayPal no puede ser una cuenta que haya sido utilizada en
            nuestro sitio.
          </li>
        </ul>
      </Modal.Body>
    </Modal>
  );
};
