import { styled } from '@mui/material/styles';
import { DataGrid, esES, GridColumns, GridRowIdGetter } from '@mui/x-data-grid';
import { useState } from 'react';
import { ReportsConfig } from '../../../config/reportsConfig';

interface ReportDataGridProps<T> {
  columns: GridColumns;
  getRowId?: GridRowIdGetter<any> | undefined;
  rows: Array<T>;
  isSearching: boolean;
}

const StyledDataGrid = styled(DataGrid)(({}) => ({
  fontFamily: [
    'Inter-Regular',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    'sans-serif',
  ].join(','),
  WebkitFontSmoothing: 'auto',
  '& .MuiButtonBase-root:hover': {
    backgroundColor: 'rgba(255, 255, 255, 0.04)',
  },
  '& .MuiSvgIcon-root': {
    fill: '#8F979F',
  },
  '& .MuiDataGrid-root': {
    border: 'none',
    borderColor: 'transparent',
  },
  '& .MuiDataGrid-main': {
    border: 'none',
  },
  '& .MuiDataGrid-columnHeaders': {
    borderColor: 'transparent',
    border: 'none',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
  '& .MuiCircularProgress-root': {
    color: '#78b03c',
  },
  '& .MuiDataGrid-columnHeader': {
    background: 'transparent',
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    color: '#8F979F',
    fontWeight: 400,
  },
  '& .MuiDataGrid-footerContainer p': {
    marginBottom: '14px',
    marginTop: '14px',
    background: 'transparent',
  },
}));

export function ReportDataGrid<T>({
  columns,
  rows,
  isSearching,
  getRowId,
}: ReportDataGridProps<T>) {
  const [pageSize, setPageSize] = useState(ReportsConfig.defaultPageSize);

  return (
    <StyledDataGrid
      autoHeight
      columns={columns}
      getRowId={getRowId}
      loading={isSearching}
      localeText={esES.components.MuiDataGrid.defaultProps.localeText}
      onPageSizeChange={(pageSize: number) => setPageSize(pageSize)}
      pageSize={pageSize}
      rows={rows as any}
      isRowSelectable={() => false}
      disableSelectionOnClick={true}
      rowsPerPageOptions={ReportsConfig.rowsPerPageOptions}
      className="report-table"
    />
  );
}
