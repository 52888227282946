import { useContext } from 'react';
import { Button, Dropdown, NavDropdown } from 'react-bootstrap';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';
import { SessionContext } from '../../../providers/session.provider';
import { AccountServices } from '../../../services';
import { QueryTypes } from '../../../types/queryTypes';
import { Formatter } from '../../../utilities';
import { MiCuentaUser, Salir, User } from '../../svg';

import styles from './userMenu.module.scss';

export const UserMenu = () => {
  const queryCache = useQueryClient();
  const navigate = useNavigate();

  const { getUser, isAuthenticated, isUserUpdated, logOut, setIsUserUpdated, userBalance } =
    useContext(SessionContext);

  const profileQuery = useQuery(QueryTypes.GetProfile, AccountServices.getProfileData);
  const account = profileQuery.data?.account;

  let authUser = getUser();

  const localLogOut = () => {
    queryCache.invalidateQueries();
    localStorage.clear();
    logOut();
  };

  const navigateLink = (link: string) => {
    navigate(link);
  };

  return (
    <NavDropdown
      id="user-top-menu"
      title={
        <div>
          <User width={25} className="me-2" />
          <span className="m-0 p-0 d-none d-lg-inline">
            {authUser!.CUSTOMERNAME} ({Formatter.toCurrency(userBalance)})
          </span>
        </div>
      }
      className={styles.menuWrapper}
      align="end">
      <div className={styles.wrapperMenuItems}>
        <NavDropdown.Item as={'div'} className="w-100">
          <div className="d-flex flex-row w-100">
            <div className="d-flex flex-column flex-fill">
              <div>Mi Saldo</div>
              <div>
                <h6 className="text-secondary m-0 p-0">${account?.balance_format}</h6>
              </div>
            </div>
            <div className="d-flex align-items-center justify-content-end">
              <Button
                variant="outline-secondary"
                onClick={() => navigateLink(ROUTES.cuenta.fondos)}>
                Agregar Saldo
              </Button>
            </div>
          </div>
        </NavDropdown.Item>
        <Dropdown.Divider className={styles.divider} />
        <NavDropdown.Item as={'div'}>
          <div className="d-flex flex-row w-100">
            <div className="flex-fill">SMS Gratis</div>
            <div>{account?.sms_free_out}</div>
          </div>
        </NavDropdown.Item>
        <Dropdown.Divider className={styles.divider} />
        <NavDropdown.Item as={'div'}>
          <div className="d-flex flex-row w-100">
            <div className="flex-fill">Minutos Gratis</div>
            <div>{account?.free_min_cuba}</div>
          </div>
        </NavDropdown.Item>
      </div>
      <NavDropdown.Item
        as={'div'}
        className={`${styles.menuItemWrapper} ${styles.itemWrapperOutside} mt-2`}
        role="button"
        onClick={() => navigateLink(ROUTES.cuenta.inicio)}>
        <MiCuentaUser width={20} className="me-2" /> Mi Cuenta
      </NavDropdown.Item>
      <Dropdown.Divider className={styles.divider} />
      <NavDropdown.Item
        as={'div'}
        onClick={() => localLogOut()}
        className={`${styles.menuItemWrapper} ${styles.itemWrapperOutside}`}
        role="button">
        <Salir width={20} className="me-2" />
        Salir
      </NavDropdown.Item>
    </NavDropdown>
  );
};
