import * as React from 'react';
import { SVGProps } from 'react';
const SvgEliminarItemListado = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13 13" {...props}>
    <g data-name="Capa 1">
      <path
        data-name="Uni\xF3n 10"
        d="M.44 10.42 4.36 6.5.44 2.58C-.13 1.97-.11 1.02.5.44c.58-.55 1.5-.55 2.08 0L6.5 4.36 10.42.44c.59-.59 1.55-.59 2.14 0s.59 1.55 0 2.14L8.64 6.5l3.92 3.92c.57.61.55 1.56-.06 2.14-.58.55-1.5.55-2.08 0L6.5 8.64l-3.92 3.92c-.59.59-1.55.59-2.14 0s-.59-1.55 0-2.14Z"
        style={{
          fill: '#757897',
        }}
      />
    </g>
  </svg>
);
export default SvgEliminarItemListado;
