import * as React from 'react';
import { SVGProps } from 'react';
const SvgFilterComida = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Capa 1" viewBox="0 0 23 23" {...props}>
    <g fill="#abadc4" data-name="Grupo 16768">
      <path
        d="M11.75 23c-2.63 0-4.59-.05-5.99-.1a4.015 4.015 0 0 1-4.05-3.79v-.04a2.407 2.407 0 0 1 1.06-1.96 3.57 3.57 0 0 1 2.05-.63h13.86a3.57 3.57 0 0 1 2.05.63 2.421 2.421 0 0 1 1.06 1.96v.04a4.015 4.015 0 0 1-4.05 3.79c-1.4.06-3.35.1-5.99.1Z"
        opacity={0.4}
      />
      <path
        data-name="Trazado 34108"
        d="M13.14 18.842a2.72 2.72 0 0 0-3.312 0 4.6 4.6 0 0 1-5.459 0h.013a3.638 3.638 0 0 0-2.6-.191 1.1 1.1 0 0 1-1.22-.34A4.442 4.442 0 0 1 .02 16.52a2.561 2.561 0 0 1 .768-2.176 1.406 1.406 0 0 1-.247-.615 7.493 7.493 0 0 1-.068-1.016 6.852 6.852 0 0 1 .068-1.021 1.6 1.6 0 0 1 1.419-1.279 2.2 2.2 0 0 0 1.88 1.686 60.326 60.326 0 0 0 7.92.373 62.633 62.633 0 0 0 7.92-.373 2.226 2.226 0 0 0 1.83-1.508 1.608 1.608 0 0 1 .979 1.279h.013a6.986 6.986 0 0 1 0 1.682 1.541 1.541 0 0 1-.323.777 2.552 2.552 0 0 1 .78 2.191 4.342 4.342 0 0 1-.538 1.791 1.092 1.092 0 0 1-1.22.34 3.638 3.638 0 0 0-2.6.191 4.6 4.6 0 0 1-5.459 0Z"
      />
      <path
        data-name="Union 2"
        d="M11.75 1.42q-1.965 0-3.45.06a6.684 6.684 0 0 0-6.58 6.65 12.48 12.48 0 0 0 .2 2.18 2.218 2.218 0 0 0 1.91 1.79 63.278 63.278 0 0 0 7.92.37 61.269 61.269 0 0 0 7.92-.37 2.218 2.218 0 0 0 1.91-1.79 11.493 11.493 0 0 0 .2-2.18 6.671 6.671 0 0 0-6.58-6.65q-1.485-.06-3.45-.06Z"
        opacity={0.4}
      />
      <path
        d="M18.02 6.94a.75.75 0 0 0 0 1.5h.25a.75.75 0 0 0 0-1.5h-.25Zm-3.76-1.25a.749.749 0 0 1 .75-.75h.25a.75.75 0 0 1 0 1.5h-.25a.749.749 0 0 1-.75-.75Zm.5 2.76a.75.75 0 0 1 0 1.5h-.25a.75.75 0 0 1 0-1.5Z"
        fillRule="evenodd"
      />
    </g>
  </svg>
);
export default SvgFilterComida;
