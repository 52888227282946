import { useContext, useEffect, useState } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';
import { MODAL_TYPES, useGlobalModalContext } from '../../../providers/globalModal.provider';
import { SessionContext } from '../../../providers/session.provider';
import { AuthService, RegisterService } from '../../../services';

import { WizardSteps } from '../../../components/shared';
import styles from './register-validation-screen.module.scss';

export const RegisterValidationScreen = () => {
  const { setMegaUser, setCurrentToken, isAuthenticated, setCurrentRefreshToken } =
    useContext(SessionContext);
  const { showModal } = useGlobalModalContext();

  const location = useLocation();
  const navigate = useNavigate();

  const [userData, setUserData] = useState<any>({});
  const [validationMethod, setValidationMethod] = useState<'sms' | 'call'>('sms');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (isAuthenticated()) {
      navigate(ROUTES.cuenta.inicio);
    }

    if (!location.state) {
      navigate(ROUTES.register);
    } else {
      setUserData(location.state);
    }
  }, []);

  const requestPin = async () => {
    setErrorMessage('');
    setIsSubmitting(true);
    const response = await RegisterService.requestPinValidation(
      validationMethod === 'sms' ? 1 : 2,
      userData.phone,
      userData.countryCode
    );
    setIsSubmitting(false);

    if (!response.success) {
      let error = '';
      switch (response.code) {
        case '002':
          error =
            '<strong>Su cuenta ya contiene un PIN válido por 5 minutos o 3 intentos.</strong><br/> Por favor seleccione el botón "Ya tengo un PIN" o espere 5 minutos para generar un nuevo código.';
          break;
        case '010':
        case '003':
        case '500':
        default:
          error = 'Hubo un error enviando su código PIN. Por favor intente de nuevo.';
          break;
      }

      setErrorMessage(error);
      return;
    }

    // success
    openPinModal();
  };

  const openPinModal = () => {
    setErrorMessage('');
    showModal(MODAL_TYPES.PIN_VALIDATION, {
      onClose: onCloseValidationModal,
      state: userData,
    });
  };

  const onCloseValidationModal = (isSuccess?: boolean) => {
    if (!isSuccess) {
      return;
    }

    requestAccountCreation();
  };

  const requestAccountCreation = async () => {
    // 002 003 004 dup'ed account
    // 500
    setIsSubmitting(true);
    const response = await RegisterService.createAccount(
      userData.email,
      userData.phone,
      userData.countryCode,
      userData.firstName,
      userData.lastName,
      userData.password,
      userData.promoSms,
      userData.cofReference || '',
      ''
    );

    if (!response.success) {
      setIsSubmitting(false);

      let error = '';
      switch (response.code) {
        case '002':
        case '003':
        case '004':
          error = 'Ya existe una cuneta con la información que usted proporcionó.';
          break;
        case '100':
          // if there was an error authenticating the user, let's navigate to the login page
          navigate(ROUTES.login);
          break;
        case '500':
        default:
          error = 'Hubo un error creando su cuenta. Por favor, intente de nuevo.';
          break;
      }

      setErrorMessage(error);
      return;
    }

    setCurrentToken(response.token!);
    setCurrentRefreshToken(response.refreshToken!);

    getUser();
  };

  const getUser = async () => {
    const user = await AuthService.getUser();
    setIsSubmitting(false);

    if (!!user) {
      setMegaUser(user);
      navigate(ROUTES.cuenta.inicio, { replace: true });
    } else {
      navigate(ROUTES.login);
    }
  };

  return (
    <div className="container my-7">
      <div className="row">
        <div className="col-xl-3 col-lg-2 col-md-0"></div>
        <div className="col-xl-6 col-lg-8 col-md-12">
          <h1 className="text-center text-uppercase mega-font-bold fs-3 text-center">
            CREA TU CUENTA
          </h1>

          <WizardSteps
            currentStep={2}
            initialStep={2}
            steps={[{ title: 'Datos Personales' }, { title: 'Validación' }]}
          />

          <p className="w-100 text-center my-5">
            Por motivos de seguridad necesitamos validar tu teléfono{' '}
            <span className="text-secondary">{userData?.phone}</span>.<br /> Seleccione el método de
            verificación de su preferencia
          </p>
        </div>
        <div className="col-xl-3 col-lg-2 col-md-0"></div>
      </div>

      <div className="row">
        <div className="col-lg-4 col-md-0"></div>
        <div className="col-lg-4 col-md-12">
          <div className={`${styles.mega_radio}`}>
            <div className={`form-check form-check-inline`}>
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio1"
                value="sms"
                checked={validationMethod === 'sms'}
                onChange={(e) => {
                  if (e.target.checked) {
                    setValidationMethod('sms');
                  }
                }}
              />
              <label
                className={`form-check-label ${styles.form_check_label}`}
                htmlFor="inlineRadio1">
                Mensaje SMS
              </label>
            </div>
          </div>
          <div className={`${styles.mega_radio}`}>
            <div className={`form-check form-check-inline`}>
              <input
                className="form-check-input"
                type="radio"
                name="inlineRadioOptions"
                id="inlineRadio2"
                value="call"
                checked={validationMethod === 'call'}
                onChange={(e) => {
                  if (e.target.checked) {
                    setValidationMethod('call');
                  }
                }}
              />
              <label
                className={`form-check-label ${styles.form_check_label}`}
                htmlFor="inlineRadio2">
                Llamada telefonica
              </label>
            </div>
          </div>
        </div>
        <div className="col-lg-4 col-md-0"></div>
      </div>

      <div className="row mt-5">
        <div className="col-lg-4 col-md-0"></div>
        <div className="col-lg-4 col-md-12 text-center">
          {errorMessage && !isSubmitting && (
            <div className="mt-3 text-start">
              <Alert variant={'danger'}>
                <div dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
              </Alert>
            </div>
          )}

          <div className="px-0">
            <Button
              variant="secondary"
              className="w-100 py-2 mt-3"
              onClick={requestPin}
              disabled={isSubmitting}>
              {isSubmitting ? (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  variant="light"
                  aria-hidden="true"
                />
              ) : (
                <span>Enviar Código</span>
              )}
            </Button>

            <Button
              variant="mega_secondary_2"
              className="w-100 py-2 mt-3"
              onClick={openPinModal}
              disabled={isSubmitting}>
              <span>Ya tengo un PIN</span>
            </Button>
          </div>
        </div>
        <div className="col-lg-4 col-md-0"></div>
      </div>

      <div className="row mt-5">
        <div className="col-xl-3 col-lg-2 col-md-0"></div>
        <div className="col-xl-6 col-lg-8 col-md-12">
          <div className={styles.pWrapper}>
            <ul>
              <li>
                <p className="">
                  El PIN enviado solo tendrá un tiempo de vida de 5 minutos o 3 intentos. Una vez
                  pasada los 5 minutos o 3 intentos deberá volver a generar un nuevo PIN.
                </p>
              </li>
              <li>
                <p className="m-0">
                  Por ambas vías usted recibirá un código PIN de 4 dígitos. Introduzca este Pin y su
                  nueva cuenta en MegaConecta quedará activada.
                </p>
              </li>
            </ul>
          </div>
        </div>
        <div className="col-xl-3 col-lg-2 col-md-0"></div>
      </div>
    </div>
  );
};
