import moment from 'moment';
import { useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FechaInput } from '../../svg';

import styles from './reports.module.scss';

type ReportDateSearchProps = {
  endDate: Date;
  isSearching: boolean;
  startDate: Date;
  onSearch: (startDate: Date, endDate: Date) => void;
};

export const ReportDateSearch = ({
  endDate,
  isSearching,
  startDate,
  onSearch,
}: ReportDateSearchProps) => {
  const [startDateState, setStartDateState] = useState<Date>(startDate);
  const [endDateState, setEndDateState] = useState<Date>(endDate);

  useEffect(() => {
    if (moment(startDateState).isAfter(moment(endDateState))) {
      setEndDateState(moment(startDateState).add(1, 'day').toDate());
    }
  }, [startDateState]);

  useEffect(() => {
    if (moment(endDateState).isBefore(moment(startDateState))) {
      setEndDateState(moment(endDateState).add(-1, 'day').toDate());
    }
  }, [endDateState]);

  const onSearchSubmit = () => {
    onSearch(startDateState, endDateState);
  };

  return (
    <Row>
      <Col xs={6} md={4}>
        <div className="megaInputWithIcon">
          <DatePicker
            selected={startDateState}
            onChange={(date) => !!date && setStartDateState(date)}
            className="form-control mega"
            selectsStart
            startDate={startDateState}
            endDate={endDateState}
          />
          <FechaInput width={20} className="icon" />
        </div>
      </Col>
      <Col xs={6} md={4}>
        <div className="megaInputWithIcon">
          <DatePicker
            selected={endDateState}
            onChange={(date) => !!date && setEndDateState(date)}
            selectsEnd
            className="form-control mega"
            startDate={startDateState}
            endDate={endDateState}
            minDate={startDateState}
          />
          <FechaInput width={20} className="icon" />
        </div>
      </Col>
      <Col xs={12} md={4} className="mt-4 mt-md-0">
        <Button
          className={`${styles.btnSearch} d-none d-md-block`}
          disabled={isSearching}
          onClick={onSearchSubmit}
          variant="secondary">
          {isSearching ? 'Buscando...' : 'Buscar'}
        </Button>

        <Button
          className={`${styles.btnSearch} w-100 d-block d-md-none`}
          disabled={isSearching}
          onClick={onSearchSubmit}
          variant="outline-secondary">
          {isSearching ? 'Buscando...' : 'Buscar'}
        </Button>
      </Col>
    </Row>
  );
};
