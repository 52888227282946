import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { MegaStoreProduct } from '../../../types/reports/storeProduct';

import 'swiper/css';
import 'swiper/css/pagination';
import styles from './productImageSlider.module.scss';

import { productImgUrl } from '../../../utilities/productImgUrl';

type ProductImageSliderProps = {
  product: MegaStoreProduct;
};

export const ProductImageSlider = ({ product }: ProductImageSliderProps) => {
  return (
    <div className={styles.product_image_slider}>
      <Swiper
        modules={[Pagination]}
        className="mySwiper"
        pagination={{
          clickable: true,
        }}>
        {[...product.images, ...product.images, ...product.images].map((image, index) => (
          <SwiperSlide key={`product-${product.id}-image-${index}`}>
            <img src={productImgUrl(image.image_url)} className={styles.product_image_slider_img} />
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};
