import * as React from 'react';
import { SVGProps } from 'react';
const SvgListo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="listo_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    {...props}>
    <defs>
      <style>{'.listo_svg__cls-3{fill:#263645}.listo_svg__cls-4{fill:#d0e3ae}'}</style>
    </defs>
    <g id="listo_svg__Capa_1-2" data-name="Capa 1">
      <path
        d="M33.3 57.98c-2.83-.24-6.07-.95-8.98-2.75C15.73 49.94 8.68 43.11 3.7 34.29c-4.91-8.7-1.34-21.47 7.34-26.47.55-.32 1.19-.53 1.78-.68 3.64-.9 7.51 4.52 9.07 8.25 1.06 2.53.94 4.23-.87 6.17-4.79 5.12-4.73 5.29-.4 11.04 2.6 3.44 5.83 6.11 9.5 8.35 2.21 1.35 3.87 1.29 5.71-.74 1.65-1.82 3.31-4.81 6.22-3.64 3.63 1.46 7.7 3.74 9.55 6.84.88 1.48.97 3.39-.18 4.75-4.62 5.43-9.96 9.7-18.12 9.81Z"
        style={{
          fill: '#8ab934',
        }}
      />
      <path
        d="M33.61 53.41c-2.58.24-5.3-.61-7.41-2.13-2.02-1.34-3.92-2.92-5.47-4.8-1.04-1.3.57-3.03 1.93-2.1 1.84 1.3 3.66 2.57 5.57 3.77 1.75 1.19 3.6 1.87 5.67 2.43 1.53.46 1.29 2.69-.28 2.83Z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        className="listo_svg__cls-4"
        d="M50.34 10.24c-5.16-5.16-11.1-7.52-16.67-8.19-1.37-.17-2.59.74-2.94 2.04l-.59 2.17c-.48 1.76.77 3.46 2.52 3.76 4.1.7 8.54 2.39 12.03 5.88 3.49 3.49 5.18 7.92 5.88 12.03.3 1.74 2 2.99 3.76 2.52l2.17-.59c1.29-.35 2.21-1.57 2.04-2.94-.68-5.56-3.03-11.5-8.19-16.67Z"
      />
      <path
        className="listo_svg__cls-4"
        d="M41.45 19.12c-3.19-3.19-6.28-4.52-9.52-4.96-1.43-.2-2.67.83-2.95 2.2l-.39 1.93c-.3 1.5.82 2.84 2.22 3.17 1.75.41 3.76 1.27 5.39 2.9s2.49 3.64 2.9 5.39c.33 1.4 1.67 2.52 3.17 2.22l1.93-.39c1.36-.27 2.4-1.51 2.2-2.95-.45-3.24-1.77-6.33-4.96-9.52Z"
      />
      <path
        className="listo_svg__cls-3"
        d="M42.47 35.57c-3.16-1.27-5.16 1.17-6.62 2.96-.28.35-.55.67-.81.96-1.41 1.55-2.47 1.68-4.34.54-3.87-2.36-6.88-5-9.2-8.07-1.89-2.51-3.03-4.02-3-5.06.03-1 1.16-2.26 3.33-4.57 2.04-2.18 2.35-4.3 1.08-7.33-1.75-4.17-5.94-9.97-10.34-8.89-.81.2-1.5.46-2.06.79-9.3 5.33-12.99 18.65-7.75 27.92 4.83 8.56 11.89 15.73 20.99 21.34 2.63 1.62 5.82 2.6 9.46 2.9h.1c9.14-.12 14.7-5.22 18.93-10.19 1.38-1.63 1.5-3.98.29-6.01-2.09-3.51-6.63-5.9-10.07-7.29Zm8.13 11.89c-3.91 4.59-9.01 9.3-17.25 9.43-3.28-.28-6.12-1.15-8.45-2.58C16.12 48.9 9.31 41.99 4.66 33.76.05 25.61 3.42 13.47 11.58 8.77c.39-.22.89-.41 1.5-.56.18-.04.36-.07.55-.07 2.64 0 5.84 4.3 7.26 7.68.94 2.25.78 3.46-.67 5.01-5.15 5.5-5.17 6.2-.47 12.43 2.49 3.3 5.69 6.12 9.8 8.62 2.78 1.69 4.96 1.4 7.09-.93.3-.33.6-.69.88-1.04 1.53-1.86 2.56-2.95 4.13-2.32 3.13 1.26 7.24 3.39 9.02 6.39.55.92.86 2.39-.08 3.49ZM59.61 26.77c-.8-6.55-3.74-12.54-8.5-17.3-4.77-4.77-10.75-7.71-17.3-8.5-1.88-.23-3.61.96-4.12 2.83l-.59 2.17c-.3 1.1-.12 2.24.5 3.21.65 1.01 1.7 1.71 2.89 1.91 4.63.79 8.58 2.72 11.44 5.58 2.86 2.86 4.79 6.82 5.58 11.44.2 1.19.9 2.24 1.91 2.89.66.42 1.4.64 2.15.64.35 0 .7-.05 1.05-.14l2.17-.59c1.87-.51 3.06-2.24 2.83-4.12Zm-3.4 2.02-2.17.59c-.5.14-1.02.05-1.46-.23-.5-.32-.84-.83-.94-1.42-.86-5.07-3-9.43-6.18-12.61S37.92 9.8 32.85 8.94c-.58-.1-1.1-.44-1.42-.94-.28-.45-.37-.97-.23-1.46l.59-2.17c.21-.75.85-1.26 1.57-1.26.06 0 .13 0 .19.01 6.15.75 11.55 3.4 16.03 7.88s7.14 9.88 7.88 16.03c.1.8-.43 1.54-1.25 1.76Z"
      />
      <path
        className="listo_svg__cls-3"
        d="M30.57 22.52c1.34.32 3.28 1.03 4.87 2.62 1.59 1.59 2.3 3.52 2.62 4.87.43 1.83 2.04 3.1 3.76 3.1.23 0 .45-.02.68-.07l1.93-.39c1.98-.4 3.33-2.22 3.06-4.16-.53-3.85-2.21-7.08-5.27-10.14-3.06-3.06-6.29-4.74-10.14-5.27-1.93-.27-3.76 1.08-4.16 3.06l-.39 1.93c-.39 1.96.97 3.96 3.04 4.44Zm-.9-4.02.39-1.93c.14-.7.74-1.35 1.53-1.35.07 0 .13 0 .2.01 3.41.47 6.15 1.91 8.9 4.65 2.75 2.75 4.18 5.49 4.65 8.9.12.89-.58 1.58-1.33 1.73l-1.93.39c-.93.18-1.71-.58-1.9-1.4-.38-1.62-1.25-3.96-3.19-5.91-1.95-1.95-4.29-2.81-5.91-3.19-.82-.19-1.59-.97-1.4-1.9Z"
      />
    </g>
  </svg>
);
export default SvgListo;
