import * as React from 'react';
import { SVGProps } from 'react';
const SvgEnviarSmsPage = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <g data-name="Capa 1">
      <path
        d="M8.8 44.39c-2.96-.21-5.27-2.42-5.53-5.37-.28-3.24-.56-8.15-.56-14.98s.28-11.74.56-14.98C3.53 6.11 5.84 3.9 8.8 3.69c3.75-.26 9.73-.54 18.28-.54s14.53.28 18.28.54c2.96.21 5.27 2.42 5.53 5.37.28 3.24.56 8.15.56 14.98s-.28 11.74-.56 14.98c-.26 2.95-2.57 5.16-5.53 5.37-3.49.24-8.91.5-16.53.53l-8.36 7.16c-1.51 1.29-3.83.22-3.83-1.76v-5.55c-3.27-.1-5.86-.24-7.84-.38Z"
        style={{
          fill: '#d0e3ae',
        }}
      />
      <path
        d="M52.02 50.13c2-.14 3.57-1.64 3.74-3.64.19-2.19.38-5.52.38-10.14 0-4.62-.19-7.95-.38-10.14-.18-2-1.74-3.5-3.74-3.64-2.54-.18-6.59-.36-12.38-.36-5.79 0-9.84.19-12.38.36-2 .14-3.57 1.64-3.74 3.64-.19 2.19-.38 5.52-.38 10.14s.19 7.95.38 10.14c.18 2 1.74 3.5 3.74 3.64 2.36.16 6.03.34 11.2.36l5.66 4.85c1.02.87 2.59.15 2.59-1.19v-3.76c2.21-.07 3.97-.17 5.31-.26Z"
        style={{
          fill: '#8ab934',
        }}
      />
      <path
        d="M56.85 26.11a5.08 5.08 0 0 0-4.33-4.58c-.05-4.7-.23-8.92-.55-12.57-.3-3.44-3.05-6.12-6.53-6.36-3.53-.25-9.58-.54-18.35-.54s-14.83.29-18.35.54c-3.48.24-6.23 2.92-6.53 6.36-.38 4.28-.57 9.36-.57 15.08s.19 10.79.57 15.08c.3 3.44 3.05 6.12 6.53 6.36 2.07.14 4.36.26 6.82.35v4.5c0 1.35.76 2.53 1.98 3.1.46.21.95.32 1.43.32.79 0 1.57-.28 2.21-.83l3.22-2.76c.78.6 1.74.99 2.8 1.06 2.13.15 5.73.33 10.87.36l5.36 4.59c.5.43 1.1.64 1.72.64.38 0 .76-.08 1.12-.25a2.64 2.64 0 0 0 1.55-2.42v-2.71c1.55-.06 2.99-.13 4.29-.23 2.53-.18 4.53-2.12 4.75-4.63.26-2.91.39-6.36.39-10.24s-.13-7.33-.39-10.24ZM19.76 51.26c-.38.32-.87.39-1.32.18-.45-.21-.72-.63-.72-1.12v-6.6l-1.05-.03c-2.83-.09-5.46-.22-7.79-.38-2.45-.17-4.31-1.97-4.52-4.38-.26-2.9-.56-7.84-.56-14.89s.3-11.98.56-14.89c.21-2.41 2.07-4.21 4.52-4.38 3.49-.24 9.49-.53 18.2-.53s14.71.29 18.2.53c2.45.17 4.31 1.97 4.52 4.38.22 2.52.48 6.59.54 12.22-2.47-.13-6.01-.26-10.7-.26-5.95 0-10.06.2-12.45.37-2.53.18-4.53 2.12-4.75 4.63-.26 2.91-.39 6.36-.39 10.24s.13 7.33.39 10.24c.06.68.26 1.32.55 1.9l-3.24 2.78Zm34.92-4.86c-.13 1.46-1.25 2.54-2.73 2.65-1.57.11-3.34.2-5.26.26l-1.05.03v4.81c0 .27-.18.39-.28.44-.11.05-.31.1-.52-.07l-5.96-5.11h-.4c-5.29-.03-8.97-.21-11.13-.36-1.48-.1-2.61-1.19-2.73-2.65-.17-1.96-.38-5.29-.38-10.05s.21-8.09.38-10.05c.13-1.46 1.25-2.54 2.73-2.65 2.36-.16 6.42-.36 12.3-.36s9.94.2 12.3.36c1.48.1 2.61 1.19 2.73 2.65.17 1.96.38 5.29.38 10.05s-.21 8.09-.38 10.05Z"
        style={{
          fill: '#263645',
        }}
      />
      <path
        data-name="Uni\xF3n 3"
        d="M30.97 38.48q0-1.63 5.32-1.63t5.32 1.63-5.32 1.63-5.32-1.63Zm0-5.63q0-1.63 9.98-1.63t9.98 1.63q0 1.63-9.98 1.63t-9.98-1.63Z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);
export default SvgEnviarSmsPage;
