import { Button } from 'react-bootstrap';
import { useTopUp } from '../../../context/TopupContext';
import { TopUpInternetItem, TopUpMobileItem, TopUpNautaItem } from '../../../types/topupItems';
import { TopUpCartInternetItem } from './topupCartInternetItem';
import { TopUpCartMobileItem } from './topupCartMobileItem';
import { TopUpCartNautaItem } from './topupCartNautaItem';

import { forwardRef, useImperativeHandle, useState } from 'react';
import { Formatter } from '../../../utilities';
import { NoTienesRecarga } from '../../svg';
import { PaymentLine, PaymentWizard } from '../paymentWizard';
import styles from './topUpCartItems.module.scss';

interface TopUpCartItemsProps {
  allowToDelete?: boolean;
  hideCTA?: boolean;

  onSuccess?: Function;
  onCancel?: Function;
}

const TopUpCart = (
  { allowToDelete = true, hideCTA = false, onCancel, onSuccess }: TopUpCartItemsProps,
  ref: any
) => {
  const { internetItems, mobileItems, nautaItems } = useTopUp();
  const [paymentScreenVisible, setPaymentScreenVisible] = useState<boolean>(false);

  useImperativeHandle(ref, () => ({
    pay() {
      openPaymentScreen();
    },
  }));

  const isCartEmpty = () => {
    return !internetItems.length && !mobileItems.length && !nautaItems.length;
  };

  const getTotalCount = () => {
    return internetItems.length + mobileItems.length + nautaItems.length;
  };

  const getTotalAmount = () => {
    const sum1 = internetItems.reduce((acc, item) => {
      acc += Number(item.carrierRate.real_amount);
      return acc;
    }, 0);

    const sum2 = mobileItems.reduce((acc, item) => {
      acc += Number(item.carrierRate.real_amount);
      return acc;
    }, 0);

    const sum3 = nautaItems.reduce((acc, item) => {
      acc += Number(item.carrierRate.real_amount);
      return acc;
    }, 0);

    return sum1 + sum2 + sum3;
  };

  const onCancelPaymentScreen = () => {
    setPaymentScreenVisible(false);

    if (onCancel) {
      onCancel();
    }
  };

  const onSuccessPayment = (goToReport?: boolean) => {
    setPaymentScreenVisible(false);

    if (onSuccess) {
      onSuccess();
    }
  };

  const openPaymentScreen = () => {
    setPaymentScreenVisible(true);
  };

  if (isCartEmpty()) {
    return (
      <div className="w-100 text-center">
        {!hideCTA && <h5>Listado de recargas</h5>}

        <NoTienesRecarga width={70} className="mt-8" />

        <h5 className={`${styles.emptySubtitleH5} mt-5`}>No tienes recargas recientes</h5>
        <p className={`${styles.emptySubtitleP}`}>Adicione recargas al listado</p>
      </div>
    );
  }

  return (
    <>
      {!hideCTA && <h5 className="mb-4">Listado de recargas ({getTotalCount()})</h5>}

      <div className={`${styles.wrapperList} ${hideCTA ? styles.hide_cta : ''}`}>
        {mobileItems.length > 0 && (
          <div className="">
            {mobileItems.map((mobileItem: TopUpMobileItem, index) => (
              <div key={`mobile-item-${index}`}>
                <TopUpCartMobileItem
                  index={index}
                  mobileItem={mobileItem}
                  allowToDelete={allowToDelete}
                  isLast={
                    index + 1 === mobileItems.length && !nautaItems.length && !internetItems.length
                  }
                />
              </div>
            ))}
          </div>
        )}

        {nautaItems.length > 0 && (
          <div className="">
            {nautaItems.map((nautaItem: TopUpNautaItem, index) => (
              <div key={`nauta-item-${index}`}>
                <TopUpCartNautaItem
                  index={index}
                  nautaItem={nautaItem}
                  allowToDelete={allowToDelete}
                  isLast={index + 1 === nautaItems.length && !internetItems.length}
                />
              </div>
            ))}
          </div>
        )}

        {internetItems.length > 0 && (
          <div>
            {internetItems.map((internetItem: TopUpInternetItem, index) => (
              <div key={`internet-item-${index}`}>
                <TopUpCartInternetItem
                  index={index}
                  internetItem={internetItem}
                  allowToDelete={allowToDelete}
                  isLast={index + 1 === internetItems.length}
                />
              </div>
            ))}
          </div>
        )}

        {!hideCTA && (
          <div className="mt-5 w-100 d-flex flex-column flex-lg-row">
            <div className="flex-fill d-flex align-items-center">
              <div className="me-2 flex-fill flex-lg-grow-0">
                <b>Total a Pagar: </b>
              </div>
              <div className="flex-fill flex-lg-grow-0 text-end text-lg-start">
                <b>
                  <span className="text-secondary">{Formatter.toCurrency(getTotalAmount())}</span>
                </b>
              </div>
            </div>
            <div className="flex-fill mt-3 mt-lg-0">
              <Button variant="secondary" className="w-100" onClick={openPaymentScreen}>
                Comprar ahora ({getTotalCount()})
              </Button>
            </div>
          </div>
        )}

        {paymentScreenVisible && (
          <PaymentWizard
            show={paymentScreenVisible}
            paymentType={PaymentLine.topup}
            totalToPay={Number(getTotalAmount())}
            serviceFee={0}
            lines={[]}
            onCancel={onCancelPaymentScreen}
            onSuccess={onSuccessPayment}
          />
        )}
      </div>
    </>
  );
};

export const TopUpCartItems = forwardRef(TopUpCart);
