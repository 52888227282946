import { MegaReferralReportItem } from '../types/reports/referralReportItem';
import { UserReferralBalance } from '../types/user';
import api from './axios/api';

const getReferralBalance = async (): Promise<number> => {
  try {
    const response = await api.get<UserReferralBalance>('/api/v1/account/getreferralbalance');

    if (!!response.data) {
      return parseFloat(response.data.referral_balance);
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const sendReferral = async ({
  country,
  language = 'es',
  phone,
}: {
  country: string;
  language?: string | undefined;
  phone: string;
}): Promise<any> => {
  try {
    const response = await api.post<any>('/api/v1/account/sendreferral', {
      country,
      language,
      phone,
    });

    if (!response.has_error) {
      return true;
    }

    throw new Error(response.error ?? 'ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const withdrawByZelle = async (params: {
  full_name: string;
  phone: string;
  email: string;
}): Promise<any> => {
  try {
    const response = await api.post<any>('/api/v1/account/withdrawbyzelle', params);

    if (!response.has_error) {
      return true;
    }

    throw new Error(response.error ?? 'ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const getReferralReport = async ({
  startDate,
  endDate,
}: {
  startDate: Date;
  endDate: Date;
}): Promise<MegaReferralReportItem[]> => {
  try {
    const response = await api.get<MegaReferralReportItem>('/api/v1/account/getreferralreport', {
      params: {
        start_date: startDate.toLocaleDateString('en-US'),
        end_date: endDate.toLocaleDateString('en-US'),
        sort_field: 'CREATED',
        sort_dir: 'DESC',
      },
    });

    if (!response.has_error && response.array) {
      return response.array;
    }

    throw new Error(response.error ?? 'ERROR: No data');
  } catch (error) {
    throw error;
  }
};

export const ReferralServices = {
  getReferralBalance,
  getReferralReport,
  sendReferral,
  withdrawByZelle,
};
