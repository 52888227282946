import { Link } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';
import {
  CorreoFooter,
  Facebook,
  IconBackground,
  Instagram,
  LogoBlanco,
  Twiter,
  Youtube
} from '../../svg';

import styles from './footer.module.scss';

export const Footer = () => {
  const socialMediaIcons = [
    {
      key: 1,
      icon: Facebook,
      size: 20,
      link: 'https://www.facebook.com/MegaConecta/',
    },
    {
      key: 2,
      icon: Twiter,
      size: 20,
      link: 'https://twitter.com/MegaConecta',
    },
    {
      key: 3,
      icon: Instagram,
      size: 20,
      link: 'https://www.facebook.com/MegaConecta/',
    },
    {
      key: 4,
      icon: Youtube,
      size: 20,
      link: 'https://www.youtube.com/channel/UC0DeBP9gdjh3YFAFndk9m_Q',
    },
  ];

  return (
    <footer className={`footer mt-auto bg-primary ${styles.footerWrapper}`}>
      <div className="container pb-3">
        <div className="row">
          <div className="col-12 col-md-12 col-lg-4 text-white text-center text-lg-start">
            <h4 className="text-white mb-3">
              Ponerse <br />
              en contacto
            </h4>
            <p className="p-0 m-0">Miami, Florida USA</p>
            <p>US: (+1) 855 210 3000, (+1) 305 507 8545</p>
            <CorreoFooter width={25} />{' '}
            <a href="mailto:ayuda@megaconecta.com" className="ms-2">
              ayuda@megaconecta.com
            </a>
          </div>
          <div className="col-12 col-md-12 col-lg-2 text-white text-center text-lg-start mt-8 mt-lg-0">
            <p className={styles.titleSections}>PÁGINAS</p>
            <div className="mb-3">
              <Link to={ROUTES.welcome}>Inicio</Link>
            </div>
            <div className="mb-3">
              <Link to={ROUTES.cuenta.inicio}>Mi Cuenta</Link>
            </div>
            <div className="mb-3">
              <Link to={ROUTES.external.contact}>Contáctnemos</Link>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-2 text-white text-center text-lg-start mt-4 mt-lg-0">
            <p className={styles.titleSections}>SERVICIOS</p>
            <div className="mb-3">
              <Link to={ROUTES.welcome}>Llamadas</Link>
            </div>
            <div className="mb-3">
              <Link to={ROUTES.cuenta.inicio}>Recargas</Link>
            </div>
            <div className="mb-3">
              <Link to={ROUTES.external.contact}>Mensajes</Link>
            </div>
            <div className="mb-3">
              <Link to={ROUTES.external.contact}>Mercado</Link>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-2 text-white text-center text-lg-start mt-4 mt-lg-0">
            <p className={styles.titleSections}>SOPORTE</p>
            <div className="mb-3">
              <Link to={ROUTES.welcome}>Términos y condiciones</Link>
            </div>
            <div className="mb-3">
              <Link to={ROUTES.cuenta.inicio}>Política de privacidad</Link>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-2 text-white text-center text-lg-start mt-4 mt-lg-0">
            <p className={styles.titleSections}>SÍGUENOS</p>

            {socialMediaIcons.map((icon) => (
              <div className={styles.socialMediaWrapper} key={icon.key}>
                <IconBackground width={30} />

                <a href={icon.link} rel="noreferrer" target="_blank">
                  <icon.icon width={20} className="me-3" />
                </a>
              </div>
            ))}
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <hr className={styles.hrWrapper} />
          </div>
        </div>

        <div className="row">
          <div className="col-12 text-center">
            <p className={`text-white pt-3 pb-2 ${styles.titleSections}`}>
              <LogoBlanco width={140} className="me-4 mb-3 mb-lg-0" />
              <br className="d-block d-lg-none" />
              Copyright © Mega Connect Corp. Todos los derechos reservados.
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};
