import * as nameCase from 'namecase';
import { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { UtilService } from '../../../services';
import { ReportsService } from '../../../services/reports';
import { QueryTypes } from '../../../types/queryTypes';
import { MegaRemittanceReportItem } from '../../../types/reports/remittanceReportItem';
import { FechaInput } from '../../svg';
import { MegaError } from '../megaError';

import { getStatusColors } from './baseColumnDef';
import styles from './storeReportItemDetails.module.scss';

type RemittanceReportItemDetailsProps = {
  id?: number;
  onHide: () => void;
};

export const RemittanceReportItemDetails = ({ id, onHide }: RemittanceReportItemDetailsProps) => {
  const [remittance, setRemittance] = useState<MegaRemittanceReportItem>();

  const remittanceReportDetailsQuery = useQuery([QueryTypes.GetRemittanceReportDetails, id], () => {
    if (id !== undefined) {
      return ReportsService.getRemittanceReportDetails(id);
    }
  });

  const allQueries = [remittanceReportDetailsQuery];

  useEffect(() => {
    if (remittanceReportDetailsQuery.status === 'success') {
      setRemittance(remittanceReportDetailsQuery.data);
    }
  }, [remittanceReportDetailsQuery.data]);

  const modalBody = () => {
    if (UtilService.isErrorQueries(allQueries)) {
      return (
        <MegaError
          displayRetry
          retry={() => {
            UtilService.refetchQueries(allQueries);
          }}
        />
      );
    }

    return (
      <Modal.Body className="px-0 pt-0">
        <div className="bg-mega_light_gray p-4">
          <Row className="no-guttsers">
            <Col xs={12} lg={8} className="mb-5 mb-lg-0">
              <div className={styles.sectionWrapper}>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <span className={styles.labelBig}>Información de remesa</span>
                  </div>
                  <div className="col-12 col-lg-6 text-start text-lg-end">
                    <FechaInput width={16} fill="#8ab934" className={styles.icon} />{' '}
                    {remittance?.created_date}
                  </div>
                </div>

                <div className="mt-3 d-flex flex-column flex-lg-row">
                  <div className="flex-fill mb-3 mb-lg-0">
                    <div className={styles.labelSmall}>Contacto de entrega</div>
                    <div>{nameCase(remittance?.full_name)}</div>
                    <div>{remittance?.address}</div>
                    <div>
                      {remittance?.municipality}, {remittance?.province}
                    </div>
                    {!!remittance?.document && (
                      <div>Carnet de identidad: {remittance?.document}</div>
                    )}
                    {!!remittance?.card && <div>Tarjeta MLC: {remittance?.card}</div>}
                  </div>
                  <div className="flex-fill mb-3 mb-lg-0">
                    <div className={styles.labelSmall}>Método de Pago</div>
                    <div>{remittance?.payment_type}</div>
                    <div>{remittance?.transaction}</div>
                  </div>
                  <div className="flex-fill">
                    <div className={styles.labelSmall}>Estado</div>
                    {getStatusColors(remittance?.status || '')}
                  </div>
                </div>
              </div>
            </Col>

            <Col xs={12} lg={4} className="mt-4 mt-md-0">
              <div className={styles.sectionWrapper}>
                <div className={`${styles.labelBig} mb-1`}>Resumen</div>

                <div className={styles.paymentRow}>
                  <div className={styles.label}>Fecha de entrega</div>
                  <div className={styles.price}>{remittance?.received_date || 'N/A'}</div>
                </div>

                <div className={`${styles.paymentRow} ${styles.border}`}>
                  <div className={styles.label}>A Recibir</div>
                  <div className={`${styles.price} ${styles.highlight}`}>
                    {remittance?.received} {remittance?.receivedCurrency}
                  </div>
                </div>

                <div className={`${styles.paymentRow}`}>
                  <div className={styles.label}>Subtotal</div>
                  <div className={`${styles.price} ${styles.highlight}`}>
                    ${remittance?.subtotal}
                  </div>
                </div>

                <div className={`${styles.paymentRow} ${styles.border}`}>
                  <div className={styles.label}>Cargo</div>
                  <div className={`${styles.price} ${styles.highlight}`}>
                    ${remittance?.charged}
                  </div>
                </div>

                <div className={`${styles.paymentRow} ${styles.total} mt-2`}>
                  <div className={styles.label}>Pagado</div>
                  <div className={`${styles.price}`}>${remittance?.paid}</div>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    );
  };

  return (
    <Modal
      show={id !== undefined}
      onHide={onHide}
      centered
      size="xl"
      backdrop={'static'}
      backdropClassName="mega-double-backdrop"
      dialogClassName="mega-double-dialog">
      <Modal.Header closeButton className="mega-modal-title">
        <Modal.Title>Detalles de la Remesa</Modal.Title>
      </Modal.Header>
      {modalBody()}
    </Modal>
  );
};
