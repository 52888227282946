import * as React from 'react';
import { SVGProps } from 'react';
const SvgCarritoMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="carritoMenu_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    {...props}>
    <defs>
      <style>
        {
          '.carritoMenu_svg__cls-2{fill:#8ab934}.carritoMenu_svg__cls-3{fill:#263645}.carritoMenu_svg__cls-4{fill:#d0e3ae}'
        }
      </style>
    </defs>
    <g id="carritoMenu_svg__Capa_1-2" data-name="Capa 1">
      <path
        className="carritoMenu_svg__cls-4"
        d="M14.85 2.76c-.05-.82-.62-1.48-1.36-1.59a12.384 12.384 0 0 0-3.7 0c-.74.11-1.31.78-1.36 1.59a74.84 74.84 0 0 0-.13 4.33c0 1.5.04 2.95.13 4.33.05.82.62 1.48 1.36 1.59a12.384 12.384 0 0 0 3.7 0c.74-.11 1.31-.78 1.36-1.59.08-1.38.13-2.83.13-4.33s-.04-2.95-.13-4.33Z"
      />
      <path
        className="carritoMenu_svg__cls-3"
        d="M11.64 13.77c-.65 0-1.31-.05-1.94-.14-1.03-.16-1.83-1.07-1.9-2.18-.08-1.42-.13-2.89-.13-4.37s.04-2.95.13-4.37C7.87 1.6 8.66.69 9.7.53c1.27-.19 2.61-.19 3.88 0 1.03.16 1.83 1.07 1.9 2.18.08 1.41.13 2.88.13 4.37s-.04 2.95-.13 4.37c-.07 1.11-.86 2.02-1.9 2.18-.64.1-1.29.14-1.94.14Zm0-12.12c-.59 0-1.18.04-1.75.13-.45.07-.8.49-.83 1.01-.08 1.39-.13 2.84-.13 4.29s.04 2.9.13 4.29c.03.52.38.94.83 1.01 1.15.17 2.36.17 3.51 0 .45-.07.8-.49.83-1.01.08-1.39.13-2.83.13-4.29s-.04-2.9-.13-4.29c-.03-.52-.38-.94-.83-1.01-.57-.09-1.16-.13-1.75-.13Z"
      />
      <path
        className="carritoMenu_svg__cls-4"
        d="M20.06 4.7c.02-.51-.37-.97-.93-1.11a12.152 12.152 0 0 0-2.84-.35c-.58 0-1.07.35-1.17.85-.17.84-.31 1.73-.42 2.66s-.19 1.82-.23 2.68c-.02.51.37.97.93 1.11.45.11.92.2 1.41.26s.97.09 1.43.09c.58 0 1.07-.35 1.17-.85.17-.84.31-1.73.42-2.66s.19-1.82.23-2.68Z"
      />
      <path
        className="carritoMenu_svg__cls-3"
        d="M18.24 11.52c-.5 0-1.01-.03-1.51-.1-.5-.06-1-.15-1.48-.27-.85-.21-1.44-.95-1.4-1.75a37.221 37.221 0 0 1 .66-5.43c.16-.79.91-1.36 1.78-1.36.49 0 1 .03 1.51.09s1 .15 1.48.27c.85.21 1.44.95 1.4 1.75a37.221 37.221 0 0 1-.66 5.43c-.16.79-.91 1.36-1.78 1.36Zm-1.95-7.65c-.27 0-.51.15-.55.35-.17.84-.31 1.72-.42 2.61-.11.89-.18 1.77-.23 2.63 0 .2.18.41.45.47.43.11.88.19 1.34.24.45.06.91.08 1.36.09.27 0 .51-.15.55-.35.17-.84.31-1.72.42-2.61.11-.89.18-1.77.23-2.63 0-.2-.18-.41-.45-.47-.43-.11-.88-.19-1.34-.25-.46-.06-.91-.08-1.36-.08Z"
      />
      <path
        d="M9.96 17.64c-1.42-.26-2.39-.98-3.01-2.23-.78-1.58-1.98-8.42-2.37-10.11-.23-1-.89-1.65-2.06-1.71C1.33 3.52.73 2.99.77 2.26c.05-.81.85-1.3 1.98-1.22 1.91.13 3.65 1.56 4.21 3.48.21.73.72 2.14.72 2.14h12.4c1.13.01 2.05.92 2.06 2.05v.8c0 2.22-.63 4.31-1.6 6.26-.84 1.7-2.55 1.93-4.21 2.08-.82.08-5.06.04-6.37-.21Z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        className="carritoMenu_svg__cls-3"
        d="M14.82 18.51c-1.64 0-4.02-.07-4.98-.25-1.62-.3-2.75-1.14-3.45-2.57-.58-1.17-1.28-4.57-2.01-8.26-.17-.85-.31-1.59-.41-1.99-.18-.79-.65-1.18-1.48-1.23C1.64 4.16.97 3.9.57 3.44.27 3.1.12 2.68.15 2.22c.03-.48.24-.91.6-1.23C1.24.56 1.96.36 2.8.41c2.17.14 4.14 1.76 4.77 3.93.14.46.39 1.22.56 1.69h11.95c1.47.01 2.67 1.21 2.68 2.67v.81c0 2.16-.56 4.3-1.67 6.54-1.03 2.08-3.16 2.28-4.71 2.43-.27.02-.84.04-1.56.04Zm-4.75-1.49c1.24.23 5.41.27 6.19.2 1.69-.16 3.04-.39 3.71-1.74 1.03-2.07 1.53-4.03 1.54-5.99V8.7c0-.78-.65-1.42-1.43-1.42H7.24l-.15-.43c-.02-.06-.51-1.43-.73-2.17-.49-1.67-1.99-2.92-3.65-3.03-.49-.03-.91.06-1.14.27-.11.1-.17.22-.18.37 0 .13.02.23.1.31.17.2.56.32 1.06.35 1.4.08 2.33.86 2.64 2.2.1.42.24 1.13.41 2.03.5 2.55 1.35 6.83 1.9 7.94.53 1.06 1.34 1.66 2.56 1.89Z"
      />
      <path
        className="carritoMenu_svg__cls-2"
        d="M21.05 19.22c-.01 1.48-1.27 2.73-2.74 2.71-1.47-.02-2.73-1.3-2.71-2.76.02-1.48 1.27-2.71 2.76-2.69 1.5.01 2.71 1.24 2.69 2.74Z"
      />
      <path
        className="carritoMenu_svg__cls-3"
        d="M18.35 22.55h-.05c-1.82-.03-3.35-1.58-3.33-3.39.02-1.81 1.59-3.34 3.39-3.31.89 0 1.73.36 2.36 1 .63.64.97 1.48.96 2.37 0 .88-.38 1.74-1.03 2.37-.63.62-1.45.96-2.3.96Zm-.01-5.45c-1.13 0-2.1.95-2.11 2.07-.01 1.11.97 2.1 2.09 2.12.55.02 1.05-.2 1.46-.6.41-.4.64-.94.65-1.49 0-.56-.21-1.09-.6-1.48-.39-.4-.91-.62-1.47-.62h-.02Z"
      />
      <path
        className="carritoMenu_svg__cls-2"
        d="M11.23 19.22c-.01 1.48-1.27 2.73-2.74 2.71-1.47-.02-2.73-1.3-2.71-2.76.02-1.48 1.27-2.71 2.76-2.69 1.5.01 2.71 1.24 2.69 2.74Z"
      />
      <path
        className="carritoMenu_svg__cls-3"
        d="M8.53 22.55h-.05c-1.82-.03-3.35-1.58-3.33-3.39.02-1.8 1.56-3.31 3.36-3.31h.03c.89 0 1.73.36 2.36 1 .63.64.97 1.48.96 2.37 0 .88-.38 1.74-1.03 2.37-.63.62-1.45.96-2.3.96Zm-.01-5.45c-1.13 0-2.1.95-2.11 2.07-.01 1.11.97 2.1 2.09 2.12.54.02 1.05-.2 1.46-.6.41-.4.64-.94.65-1.49 0-.56-.21-1.09-.6-1.48-.39-.4-.91-.62-1.47-.62h-.02Z"
      />
      <path
        id="carritoMenu_svg__Trazado_190"
        data-name="Trazado 190"
        className="carritoMenu_svg__cls-3"
        d="M16.32 9.54c.62 0 .87.7.87 2.49s-.24 2.49-.87 2.49-.87-.7-.87-2.49.24-2.49.87-2.49Z"
      />
      <path
        id="carritoMenu_svg__Trazado_190-2"
        data-name="Trazado 190"
        className="carritoMenu_svg__cls-3"
        d="M11.74 9.54c.62 0 .87.7.87 2.49s-.24 2.49-.87 2.49-.87-.7-.87-2.49.24-2.49.87-2.49Z"
      />
    </g>
  </svg>
);
export default SvgCarritoMenu;
