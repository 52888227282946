import * as nameCase from 'namecase';
import { MdOutlineAttachMoney } from 'react-icons/md';
import { components, GroupBase, OptionProps, SingleValueProps, StylesConfig } from 'react-select';
import colors from '../assets/styles/_variables.module.scss';
import { MegaContact } from '../types/contacts';
import { AuthorizedCountry, Country, TopUpCountry } from '../types/country';
import { MegaCarrier, MegaCarrierRate } from '../types/topups';

import { BiSolidDownArrow, BiSolidUpArrow } from 'react-icons/bi';
import { Operador, ProvinciaSelect } from '../components/svg';
import { CallCountry } from '../types/call';
import { Municipality } from '../types/municipality';
import { SortOption } from '../types/products/sortOption';
import { Province } from '../types/province';
import { RemittanceMethod } from '../types/remittanceMethod';
import styles from './reactSelect.module.scss';

const { Option, SingleValue } = components;

export const reactSelectStyles: StylesConfig<any, false, GroupBase<any>> = {
  option: (base) => ({
    ...base,
    backgroundColor: '#ffffff',
    color: colors.primary,
    borderBottomWidth: 1,
    borderBottomColor: colors.mega_light_gray_active,
    borderBottomStyle: 'solid',
  }),
  control: (base) => ({
    ...base,
    borderColor: colors.primary,
  }),
  indicatorSeparator: (base) => ({
    ...base,
    backgroundColor: 'white',
  }),
  indicatorsContainer: (base) => ({
    ...base,
    color: colors.primary,
  }),
  menu: (base) => ({
    ...base,
    zIndex: 10000,
  }),
};

export const IconOptionContact = (
  props: OptionProps<MegaContact, false, GroupBase<MegaContact>>
) => {
  return (
    <Option {...props}>
      <div className="contact_row no_border">
        <div className="contact_right">
          <span className={`fi fi-${(props.data.country_code || '').toLowerCase()}`}></span>
        </div>
        <div className="contact_center">
          <div>{nameCase(props.data.contact_name)}</div>
          <small className="contact_phone">{props.data.contact_number}</small>
        </div>
      </div>
    </Option>
  );
};

export const IconSingleValueContact = ({
  children,
  ...props
}: SingleValueProps<MegaContact, false, GroupBase<MegaContact>>) => {
  return (
    <SingleValue {...props}>
      <div>
        <span className={`me-2 fi fi-${(props.data.country_code || '').toLowerCase()}`}></span>
        {props.data.contact_name}
      </div>
    </SingleValue>
  );
};

export const IconOptionContactNauta = (
  props: OptionProps<MegaContact, false, GroupBase<MegaContact>>
) => {
  return (
    <Option {...props}>
      <div className="contact_row no_border">
        <div className="contact_right">
          <span className={`fi fi-${(props.data.country_code || '').toLowerCase()}`}></span>
        </div>
        <div className="contact_center">
          <div>{nameCase(props.data.contact_name)}</div>
          <small className="contact_phone">{props.data.email}</small>
        </div>
      </div>
    </Option>
  );
};

export const IconSingleValueContactNauta = ({
  children,
  ...props
}: SingleValueProps<MegaContact, false, GroupBase<MegaContact>>) => {
  return (
    <SingleValue {...props}>
      <div>
        <span className={`me-2 fi fi-${(props.data.country_code || '').toLowerCase()}`}></span>
        {props.data.contact_name} ({props.data.email})
      </div>
    </SingleValue>
  );
};

export const IconOptionProduct = (
  props: OptionProps<MegaCarrierRate, false, GroupBase<MegaCarrierRate>>
) => {
  return (
    <Option {...props}>
      <div className="contact_row">
        <div className="contact_right">
          <MdOutlineAttachMoney size={25} />
        </div>
        <div className="contact_center">
          <h6>{props.data.description}</h6>
        </div>
      </div>
    </Option>
  );
};

export const IconSingleValueProduct = ({
  children,
  ...props
}: SingleValueProps<MegaCarrierRate, false, GroupBase<MegaCarrierRate>>) => {
  return (
    <SingleValue {...props}>
      <div className="contact_row">
        <div className="contact_right">
          <MdOutlineAttachMoney size={25} />
        </div>
        <div className="contact_center">
          <h6>{props.data.description}</h6>
        </div>
      </div>
    </SingleValue>
  );
};

export const IconOptionCallCountry = (
  props: OptionProps<CallCountry, false, GroupBase<CallCountry>>
) => {
  return (
    <Option {...props}>
      <div className="contact_row no_border">
        <div className="contact_right">
          <span className={`fi fi-${(props.data.country_code || '').toLowerCase()}`}></span>
        </div>
        <div className="contact_center">{nameCase(props.data.country)}</div>
      </div>
    </Option>
  );
};

export const IconSingleValueCallCountry = ({
  children,
  ...props
}: SingleValueProps<CallCountry, false, GroupBase<CallCountry>>) => {
  return (
    <SingleValue {...props}>
      <div>
        <span className={`me-2 fi fi-${(props.data.country_code || '').toLowerCase()}`}></span>
        {nameCase(props.data.country)}
      </div>
    </SingleValue>
  );
};

export const IconOptionCountry = (props: OptionProps<Country, false, GroupBase<Country>>) => {
  return (
    <Option {...props}>
      <div className="contact_row no_border">
        <div className="contact_right">
          <span className={`fi fi-${(props.data.countcod || '').toLowerCase()}`}></span>
        </div>
        <div className="contact_center">{nameCase(props.data.short_name)}</div>
      </div>
    </Option>
  );
};

export const IconSingleValueCountry = ({
  children,
  ...props
}: SingleValueProps<Country, false, GroupBase<Country>>) => {
  return (
    <SingleValue {...props}>
      <div>
        <span className={`me-2 fi fi-${(props.data.countcod || '').toLowerCase()}`}></span>
        {nameCase(props.data.short_name)}
      </div>
    </SingleValue>
  );
};

export const IconOptionAuthorizedCountry = (
  props: OptionProps<AuthorizedCountry, false, GroupBase<AuthorizedCountry>>
) => {
  return (
    <Option {...props}>
      <div className="contact_row no_border">
        <div className="contact_right">
          <span className={`fi fi-${(props.data.count_cod || '').toLowerCase()}`}></span>
        </div>
        <div className="contact_center">
          {props.data.count_name} (+{props.data.count_prefix})
        </div>
      </div>
    </Option>
  );
};

export const IconSingleValueAuthorizedCountry = ({
  children,
  ...props
}: SingleValueProps<AuthorizedCountry, false, GroupBase<AuthorizedCountry>>) => {
  return (
    <SingleValue {...props}>
      <div>
        <span className={`fi fi-${(props.data.count_cod || '').toLowerCase()}`}></span>{' '}
        <span>
          {props.data.count_name} (+{props.data.count_prefix})
        </span>
      </div>
    </SingleValue>
  );
};

export const IconOptionTopUpCountry = (
  props: OptionProps<TopUpCountry, false, GroupBase<TopUpCountry>>
) => {
  return (
    <Option {...props}>
      <div className="contact_row no_border">
        <div className="contact_right">
          <span className={`fi fi-${(props.data.count_cod || '').toLowerCase()}`}></span>
        </div>
        <div className="contact_center">{nameCase(props.data.short_name)}</div>
      </div>
    </Option>
  );
};

export const IconSingleValueTopUpCountry = ({
  children,
  ...props
}: SingleValueProps<TopUpCountry, false, GroupBase<TopUpCountry>>) => {
  return (
    <SingleValue {...props}>
      <div>
        <span className={`me-2 fi fi-${(props.data.count_cod || '').toLowerCase()}`}></span>
        {nameCase(props.data.short_name)}
      </div>
    </SingleValue>
  );
};

export const IconOptionCarrierRate = (
  props: OptionProps<MegaCarrierRate, false, GroupBase<MegaCarrierRate>>
) => {
  return (
    <Option {...props}>
      <div className="contact_row">
        <div className="contact_right">
          <MdOutlineAttachMoney size={25} />
        </div>
        <div className="contact_center">{props.data.description}</div>
      </div>
    </Option>
  );
};

export const IconSingleValueCarrierRate = ({
  children,
  ...props
}: SingleValueProps<MegaCarrierRate, false, GroupBase<MegaCarrierRate>>) => {
  return (
    <SingleValue {...props}>
      <div className="contact_row">
        <div className="contact_right">
          <MdOutlineAttachMoney size={25} />
        </div>
        <div className="contact_center">{props.data.description}</div>
      </div>
    </SingleValue>
  );
};

export const OptionCarrierRate = (
  props: OptionProps<MegaCarrierRate, false, GroupBase<MegaCarrierRate>>
) => {
  return (
    <Option {...props}>
      <div className="contact_row">
        <div className="contact_center">
          <div dangerouslySetInnerHTML={{ __html: props.data.description }} />
        </div>
      </div>
    </Option>
  );
};

export const SingleValueCarrierRate = ({
  children,
  ...props
}: SingleValueProps<MegaCarrierRate, false, GroupBase<MegaCarrierRate>>) => {
  return (
    <SingleValue {...props}>
      <div className="contact_row">
        <div className="contact_center">
          <div dangerouslySetInnerHTML={{ __html: props.data.description }} />
        </div>
      </div>
    </SingleValue>
  );
};

export const IconOptionCarrier = (
  props: OptionProps<MegaCarrier, false, GroupBase<MegaCarrier>>
) => {
  return (
    <Option {...props}>
      <div className={styles.contact_row}>
        <div className={styles.contact_right}>
          <Operador width={20} />
        </div>
        <div className={styles.contact_center}>{props.data.name}</div>
      </div>
    </Option>
  );
};

export const IconSingleValueCarrier = ({
  children,
  ...props
}: SingleValueProps<MegaCarrier, false, GroupBase<MegaCarrier>>) => {
  return (
    <SingleValue {...props}>
      <div className={styles.contact_row}>
        <div className={styles.contact_right}>
          <Operador width={20} />
        </div>
        <div className={styles.contact_center}>{props.data.name}</div>
      </div>
    </SingleValue>
  );
};

export const IconOptionLocation = (props: OptionProps<Province, false, GroupBase<Province>>) => {
  return (
    <Option {...props}>
      <div className="contact_row no_border">
        <div>{props.data.name}</div>
      </div>
    </Option>
  );
};

export const IconSingleValueLocation = ({
  children,
  ...props
}: SingleValueProps<Province, false, GroupBase<Province>>) => {
  return (
    <SingleValue {...props}>
      <div className={styles.contact_row}>
        <ProvinciaSelect width={20} fill="#fe4a81" className="mt-ish" />
        <div className="ms-2">{props.data.name}</div>
      </div>
    </SingleValue>
  );
};

export const IconOptionProvince = (props: OptionProps<Province, false, GroupBase<Province>>) => {
  return (
    <Option {...props}>
      <div className="contact_row no_border">
        <div>{props.data.name}</div>
      </div>
    </Option>
  );
};

export const IconSingleValueProvince = ({
  children,
  ...props
}: SingleValueProps<Province, false, GroupBase<Province>>) => {
  return (
    <SingleValue {...props}>
      <div className={styles.contact_row}>
        <div className="ms-2">{props.data.name}</div>
      </div>
    </SingleValue>
  );
};

export const IconOptionMunicipality = (
  props: OptionProps<Municipality, false, GroupBase<Municipality>>
) => {
  return (
    <Option {...props}>
      <div className="contact_row no_border">
        <div className="contact_center">{nameCase(props.data.name)}</div>
      </div>
    </Option>
  );
};

export const IconSingleValueMunicipality = ({
  children,
  ...props
}: SingleValueProps<Municipality, false, GroupBase<Municipality>>) => {
  return (
    <SingleValue {...props}>
      <div>{nameCase(props.data.name)}</div>
    </SingleValue>
  );
};

export const IconOptionRemittanceMethod = (
  props: OptionProps<RemittanceMethod, false, GroupBase<RemittanceMethod>>
) => {
  return (
    <Option {...props}>
      <div className="contact_row no_border">
        <div>{props.data.name}</div>
      </div>
    </Option>
  );
};

export const IconSingleValueRemittanceMethod = ({
  children,
  ...props
}: SingleValueProps<RemittanceMethod, false, GroupBase<RemittanceMethod>>) => {
  return (
    <SingleValue {...props}>
      <div className={styles.contact_row}>
        <div className="ms-2">{props.data.name}</div>
      </div>
    </SingleValue>
  );
};

export const IconOptionSort = (props: OptionProps<SortOption, false, GroupBase<SortOption>>) => {
  return (
    <Option {...props}>
      <div className="contact_row no_border">
        {props.data.direction == 'asc' ? (
          <BiSolidDownArrow size={20} fill="#ABADC4" className="mt-ish" />
        ) : (
          <BiSolidUpArrow size={20} fill="#ABADC4" className="mt-ish" />
        )}
        <div className="ms-2">{nameCase(props.data.label)}</div>
      </div>
    </Option>
  );
};

export const IconSingleValueSort = ({
  children,
  ...props
}: SingleValueProps<SortOption, false, GroupBase<SortOption>>) => {
  return (
    <SingleValue {...props}>
      <div className="contact_row no_border">
        {props.data.direction == 'asc' ? (
          <BiSolidDownArrow size={20} fill="#fe4a81" className="mt-ish" />
        ) : (
          <BiSolidUpArrow size={20} fill="#fe4a81" className="mt-ish" />
        )}
        <div className="ms-2">{nameCase(props.data.label)}</div>
      </div>
    </SingleValue>
  );
};
