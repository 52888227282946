import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper-bundle.css';
import { StoreService } from '../../../services/store';
import { QueryTypes } from '../../../types/queryTypes';
import { MegaStoreProduct } from '../../../types/reports/storeProduct';
import { StoreProductCard } from '../../account';
import styles from './productSlider.module.scss';

export const ProductSlider = () => {
  const [promotedProducts, setPromotedProducts] = useState<MegaStoreProduct[]>([]);
  const promotedProductQuery = useQuery(QueryTypes.GetPromotedProducts, () => {
    return StoreService.getPromotedProducts();
  });

  useEffect(() => {
    if (promotedProductQuery.status === 'success') {
      setPromotedProducts(promotedProductQuery.data);
    }
  }, [promotedProductQuery.data]);

  return (
    <div className={styles.wrapper}>
      <h6>Productos destacados</h6>

      <Swiper
        modules={[Navigation, Pagination]}
        slidesPerView={1}
        spaceBetween={20}
        className="container"
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          992: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
        }}
        autoHeight={true}
        navigation={{
          nextEl: '.swiper_button_next',
          prevEl: '.swiper_button_prev',
        }}
        pagination={{ clickable: true }}>
        <div>
          {promotedProducts.map((promotedProduct: MegaStoreProduct) => (
            <SwiperSlide key={promotedProduct.id}>
              <StoreProductCard product={promotedProduct} />
            </SwiperSlide>
          ))}
        </div>
        <div className={`d-flex justify-content-between ${styles.navigation_wrapper}`}>
          <div className={`swiper_button_prev ${styles.swiper_button_prev}`}></div>
          <div className={`swiper_button_next ${styles.swiper_button_next}`}></div>
        </div>
      </Swiper>
    </div>
  );
};
