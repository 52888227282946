import * as React from 'react';
import { SVGProps } from 'react';
const SvgPanelControl = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="panel_control_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    {...props}>
    <defs>
      <style>
        {
          '.panel_control_svg__cls-1{fill:#fff}.panel_control_svg__cls-2{fill:#8ab934}.panel_control_svg__cls-3{fill:#263645}'
        }
      </style>
    </defs>
    <g id="panel_control_svg__Capa_1-2" data-name="Capa 1">
      <path
        d="M27.73 51.67c-7.92 0-13.48-.3-16.98-.58-3.52-.29-6.23-3-6.52-6.52-.28-3.5-.58-9.06-.58-16.98s.3-13.48.58-16.98c.29-3.52 3-6.23 6.52-6.52 3.5-.28 9.06-.58 16.98-.58s13.48.3 16.98.58c3.52.29 6.23 3 6.52 6.52.28 3.5.58 9.06.58 16.98s-.3 13.48-.58 16.98c-.29 3.52-3 6.23-6.52 6.52-3.5.28-9.06.58-16.98.58Z"
        style={{
          fill: '#d0e3ae',
        }}
      />
      <path
        className="panel_control_svg__cls-2"
        d="M51.56 15.81H3.9c.1-2.08.21-3.81.33-5.21.29-3.52 3-6.23 6.52-6.52 3.5-.28 9.06-.58 16.98-.58s13.48.3 16.98.58c3.52.29 6.23 3 6.52 6.52.11 1.4.23 3.13.33 5.21Z"
      />
      <path
        className="panel_control_svg__cls-1"
        d="M19.5 9.76c0-1.51.77-1.96 3.31-1.96s3.31.46 3.31 1.96-.77 1.96-3.31 1.96-3.31-.46-3.31-1.96Z"
      />
      <circle className="panel_control_svg__cls-2" cx={42} cy={42.15} r={14.35} />
      <path
        className="panel_control_svg__cls-3"
        d="M13.06 11.73c2.54 0 3.31-.46 3.31-1.96s-.77-1.96-3.31-1.96-3.31.46-3.31 1.96.77 1.96 3.31 1.96Z"
      />
      <path
        className="panel_control_svg__cls-3"
        d="M52.88 31.2c.01-1.15.02-2.35.02-3.62 0-4.24-.09-7.79-.21-10.69l-.05-1.14c0-.13-.01-.26-.02-.39 0-.17-.02-.33-.03-.49-.01-.27-.03-.54-.04-.81-.02-.28-.03-.55-.05-.81 0-.14-.02-.28-.03-.42-.02-.36-.05-.7-.07-1.02 0-.06 0-.12-.01-.19-.03-.39-.06-.77-.08-1.11a8.186 8.186 0 0 0-7.52-7.52c-3.29-.27-8.92-.59-17.07-.59s-13.78.32-17.07.59a8.186 8.186 0 0 0-7.52 7.52c-.03.35-.06.72-.09 1.12 0 .06 0 .11-.01.17-.02.32-.05.67-.07 1.03 0 .14-.02.28-.03.42-.02.27-.03.54-.05.83-.01.26-.03.52-.04.78 0 .17-.02.33-.03.51 0 .13-.01.26-.02.39l-.05 1.14c-.12 2.9-.21 6.45-.21 10.69 0 8.15.32 13.78.59 17.07a8.186 8.186 0 0 0 7.52 7.52c3.29.27 8.92.59 17.07.59 1.06 0 2.08 0 3.06-.02 2.82 2.98 6.8 4.85 11.22 4.85 8.51 0 15.44-6.92 15.44-15.44 0-4.27-1.75-8.15-4.56-10.94ZM10.84 5.17c3.25-.26 8.82-.58 16.89-.58s13.64.31 16.89.58c3.01.24 5.28 2.52 5.52 5.52l.06.8c0 .12.02.24.03.36l.03.46c.05.72.1 1.53.15 2.41H5.05c.05-.88.1-1.69.15-2.41l.03-.46c0-.12.02-.24.03-.36l.06-.8c.24-3.01 2.52-5.28 5.52-5.52Zm16.89 45.41c-8.08 0-13.64-.31-16.89-.58-3.01-.24-5.28-2.52-5.52-5.52-.26-3.25-.58-8.82-.58-16.89 0-4.25.09-7.8.21-10.69h45.57c.12 2.89.21 6.44.21 10.69v1.83a15.299 15.299 0 0 0-8.72-2.71c-8.51 0-15.44 6.93-15.44 15.44 0 3.11.93 6 2.51 8.43h-1.35ZM42 55.41c-7.31 0-13.26-5.95-13.26-13.26S34.69 28.89 42 28.89s13.26 5.95 13.26 13.26S49.31 55.41 42 55.41Z"
      />
      <path
        className="panel_control_svg__cls-1"
        d="M43.69 41.92V24.41c0-.84-.7-1.52-1.55-1.48-11.26.6-18.31 7.65-18.9 18.92-.04.84.63 1.54 1.48 1.54h17.49c.82 0 1.48-.66 1.48-1.48Z"
      />
    </g>
  </svg>
);
export default SvgPanelControl;
