import { FaCreditCard, FaPaypal } from 'react-icons/fa';
import { NewCardInformation } from '../../../types/newCardInformation';
import { NewPaypalInformation } from '../../../types/newPaypalInformation';
import { PaymentMethodCard, PaymentMethodType } from '../../../types/payment';
import { SummaryCard } from './summaryCard';

export const PaymentMethodSummaryCard = ({
  currentPaymentMethod,
  currentPaymentMethodType,
  newCardInformation,
  newPaypalInformation,
}: {
  currentPaymentMethod: PaymentMethodCard | undefined;
  currentPaymentMethodType: PaymentMethodType | undefined;
  newCardInformation: NewCardInformation | undefined;
  newPaypalInformation: NewPaypalInformation | undefined;
}) => {
  let title = '';

  switch (currentPaymentMethodType) {
    case 'new-card':
      title = newCardInformation?.title ?? '';
      break;
    case 'new-paypal':
      title = newPaypalInformation?.details.email ?? '';
      break;
    case 'card':
    case 'paypal':
      title = currentPaymentMethod?.title ?? '';
      break;
  }

  return (
    <SummaryCard header="Método de pago">
      <div>
        {['paypal', 'new-paypal'].includes(currentPaymentMethodType ?? '') ? (
          <>
            <FaPaypal size={20} className="me-1" />
            Paypal
          </>
        ) : (
          <>
            <FaCreditCard size={20} className="me-1" />
            Tarjeta de crédito
          </>
        )}
      </div>
      <div>{title}</div>
    </SummaryCard>
  );
};
