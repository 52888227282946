import { Modal } from 'react-bootstrap';
import Lottie from 'react-lottie';
import creditCardJson from '../../../assets/animations/credit-card.json';
import loadingJson from '../../../assets/animations/loading.json';
import { useGlobalModalContext } from '../../../providers/globalModal.provider';

export const ProcessingPaymentModal = () => {
  const { hideModal, currentModalProps } = useGlobalModalContext();

  const closeModal = () => {
    hideModal();
    !!currentModalProps?.onClose && currentModalProps.onClose();
  };

  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: creditCardJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: loadingJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Modal
      size="sm"
      show={true}
      backdrop="static"
      backdropClassName="mega-double-backdrop"
      dialogClassName="mega-double-dialog"
      centered>
      <Modal.Body>
        <Lottie options={defaultOptions1} height={50} width={50} />

        <h6 className="text-center">Procesando pago...</h6>
        <p className="text-center mega-fs-small">Este proceso puede tomar hasta un minuto</p>

        <Lottie options={defaultOptions2} height={45} width={45} />
      </Modal.Body>
    </Modal>
  );
};
