import * as React from 'react';
import { SVGProps } from 'react';
const SvgLlamarMisContactos = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="llamar_mis_contactos_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 66"
    {...props}>
    <defs>
      <style>{'.llamar_mis_contactos_svg__cls-3{fill:#263645}'}</style>
    </defs>
    <g id="llamar_mis_contactos_svg__Capa_1-2" data-name="Capa 1">
      <path
        d="M18.26 3.56c-4.56.5-8.07 3.46-8.44 7.12C9.1 17.76 8.71 25.25 8.71 33s.39 15.24 1.11 22.32c.37 3.66 3.88 6.63 8.44 7.12 5.79.63 11.85.97 18.11.97s12.32-.34 18.11-.97c4.56-.5 8.07-3.46 8.44-7.12.72-7.08 1.11-14.57 1.11-22.32s-.39-15.24-1.11-22.32c-.37-3.66-3.88-6.63-8.44-7.12-5.79-.63-11.85-.97-18.11-.97s-12.32.34-18.11.97Z"
        style={{
          fill: '#d0e3ae',
        }}
      />
      <path
        className="llamar_mis_contactos_svg__cls-3"
        d="M36.36 64.6c-6.17 0-12.31-.33-18.24-.97-5.16-.56-9.07-3.93-9.5-8.19C7.88 48.18 7.5 40.63 7.5 33s.38-15.18 1.12-22.44c.43-4.26 4.34-7.63 9.5-8.19 11.86-1.29 24.62-1.29 36.48 0 5.16.56 9.07 3.93 9.5 8.19.74 7.26 1.12 14.81 1.12 22.44s-.38 15.18-1.12 22.44c-.43 4.26-4.34 7.63-9.5 8.19-5.93.65-12.07.97-18.24.97ZM18.38 4.75c-3.96.43-7.07 2.98-7.38 6.06-.73 7.18-1.1 14.65-1.1 22.2s.37 15.02 1.1 22.2c.31 3.08 3.42 5.63 7.38 6.06 11.69 1.27 24.27 1.27 35.96 0 3.96-.43 7.07-2.98 7.38-6.06.73-7.18 1.1-14.65 1.1-22.2s-.37-15.02-1.1-22.2c-.31-3.08-3.42-5.63-7.38-6.06-11.69-1.27-24.27-1.27-35.96 0Z"
      />
      <path
        className="llamar_mis_contactos_svg__cls-3"
        d="M7.81 21.15c5.34 0 6.96-.79 6.96-3.38s-1.62-3.38-6.96-3.38-6.96.79-6.96 3.38 1.62 3.38 6.96 3.38ZM7.81 29.62c-5.34 0-6.96.79-6.96 3.38s1.62 3.38 6.96 3.38 6.96-.79 6.96-3.38-1.62-3.38-6.96-3.38ZM7.81 44.85c-5.34 0-6.96.79-6.96 3.38s1.62 3.38 6.96 3.38 6.96-.79 6.96-3.38-1.62-3.38-6.96-3.38Z"
      />
      <path
        id="llamar_mis_contactos_svg__Uni\xF3n_11"
        data-name="Uni\xF3n 11"
        d="M37.6 51.17c-5.49-.05-10.97-.58-16.37-1.6-1.85-.91-2.12-1.81-1.21-3.66.81-1.57 1.88-2.99 3.16-4.19 7.9-7.91 20.71-7.91 28.61-.02 1.16 1.16 2.17 2.45 3.02 3.85 1.29 2.18 1.08 2.95-1.21 4.09a73.663 73.663 0 0 1-15.61 1.53h-.4ZM27.44 22.31c0-5.62 4.56-10.18 10.18-10.18S47.8 16.69 47.8 22.31s-4.55 10.18-10.17 10.18-10.18-4.55-10.18-10.17Z"
        style={{
          fill: '#8ab934',
        }}
      />
      <path
        className="llamar_mis_contactos_svg__cls-3"
        d="M38.62 52.38h-1.04c-5.54-.06-11.12-.6-16.57-1.63l-.16-.03-.15-.07c-2.42-1.19-2.96-2.81-1.75-5.26a16.74 16.74 0 0 1 3.42-4.54c8.32-8.33 21.92-8.34 30.27 0 1.22 1.22 2.3 2.59 3.2 4.08.75 1.27 1.18 2.32.86 3.4-.34 1.14-1.43 1.82-2.56 2.39l-.14.07-.15.03c-5 1.04-10.12 1.56-15.23 1.56Zm-17.01-3.95c5.26.98 10.64 1.5 15.99 1.55h.42c5.07.04 10.19-.44 15.18-1.47 1.03-.53 1.18-.79 1.2-.85.02-.07.04-.38-.62-1.49-.79-1.31-1.75-2.53-2.83-3.61a18.903 18.903 0 0 0-13.45-5.57h-.01c-5.08 0-9.86 1.99-13.46 5.58-1.22 1.14-2.2 2.44-2.94 3.89-.59 1.21-.57 1.42.53 1.97Zm16-14.74c-3.04 0-5.89-1.18-8.04-3.33-2.15-2.15-3.33-5-3.33-8.04 0-6.28 5.1-11.38 11.37-11.38s11.37 5.1 11.37 11.37c0 3.04-1.18 5.89-3.33 8.04-2.15 2.15-5 3.33-8.04 3.33Zm0-20.36c-4.95 0-8.98 4.03-8.98 8.98 0 4.96 4.03 8.98 8.98 8.98 2.4 0 4.65-.94 6.35-2.63a8.91 8.91 0 0 0 2.63-6.35c0-4.95-4.03-8.98-8.98-8.98Z"
      />
      <path
        id="llamar_mis_contactos_svg__Trazado_190"
        data-name="Trazado 190"
        d="M45.17 45.44c0-1.87-2.11-2.61-7.49-2.61-5.38 0-7.49.74-7.49 2.61 0 1.87 2.11 2.61 7.49 2.61s7.49-.74 7.49-2.61Z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);
export default SvgLlamarMisContactos;
