import { Navigate, Route, Routes } from 'react-router-dom';
import { RequireAuth } from './components/account/requireAuth';
import { Root } from './components/external/root';
import { ContactsScreen } from './screens/account/contacts';
import { LoginScreen } from './screens/external/login';

import { ROUTES } from './config/routes';
import { AddFundsScreen } from './screens/account/add-funds';
import { CheckoutPaymentMethodsScreen } from './screens/account/checkout-payment-methods';
import { CheckoutSummaryScreen } from './screens/account/checkout-summary';
import { EditProfileScreen } from './screens/account/edit-profile';
import { PaymentScreen } from './screens/account/payment';
import { PaymentMethodsScreen } from './screens/account/payment-methods';
import { ProfileScreen } from './screens/account/profile';
import { ReferFriendScreen } from './screens/account/refer-friend';
import { RemittanceScreen } from './screens/account/remittance';
import { ReportsScreen } from './screens/account/reports';
import { SendSmsScreen } from './screens/account/send-sms';
import { ShoppingCartScreen } from './screens/account/shopping-cart';
import { StoreScreen } from './screens/account/store';
import { StoreProductScreen } from './screens/account/store-product';
import { TopupScreen } from './screens/account/topup';
import { TopUpPaymentMethodsScreen } from './screens/account/topup-payment-methods';
import { TopUpSummaryScreen } from './screens/account/topup-summary';
import { ContactScreen } from './screens/external/contact';
import { ChangePasswordScreen, ForgotPasswordScreen } from './screens/external/forgot-password';
import { HomepageScreen } from './screens/external/homepage';
import { RegisterScreen } from './screens/external/register';
import { RegisterValidationScreen } from './screens/external/register-validation';
import { ServiceCallsScreen } from './screens/external/service-calls';
import { ServiceMessagesScreen } from './screens/external/service-messages';
import { ServiceTopupsScreen } from './screens/external/service-topups';
import { TermsAndConditionsScreen } from './screens/external/terms-and-conditions';

export const MegaRouter = () => {
  return (
    <Routes>
      <Route element={<Root />}>
        {/* open routes */}
        <Route path={ROUTES.external.home} element={<HomepageScreen />} />
        <Route path={ROUTES.external.services.calls} element={<ServiceCallsScreen />} />
        <Route path={ROUTES.external.services.topups} element={<ServiceTopupsScreen />} />
        <Route path={ROUTES.external.services.messages} element={<ServiceMessagesScreen />} />
        <Route path={ROUTES.external.services.combos} element={<StoreScreen />} />
        <Route path={ROUTES.external.contact} element={<ContactScreen />} />

        <Route path={ROUTES.login} element={<LoginScreen />} />
        <Route path={ROUTES.register} element={<RegisterScreen />} />
        <Route path={ROUTES.register_validation} element={<RegisterValidationScreen />} />
        <Route path={ROUTES.forgotPassword} element={<ForgotPasswordScreen />} />
        <Route path={ROUTES.changePassword} element={<ChangePasswordScreen />} />
        <Route path={ROUTES.termsAndConditions} element={<TermsAndConditionsScreen />} />

        {/* secured routes */}
        <Route
          path={ROUTES.cuenta.inicio}
          element={
            <RequireAuth>
              <ProfileScreen />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.cuenta.editarCuenta}
          element={
            <RequireAuth>
              <EditProfileScreen />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.cuenta.paymentMethods}
          element={
            <RequireAuth>
              <PaymentMethodsScreen />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.cuenta.contactos}
          element={
            <RequireAuth>
              <ContactsScreen />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.cuenta.fondos}
          element={
            <RequireAuth>
              <AddFundsScreen />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.cuenta.topUpPaymentMethods}
          element={
            <RequireAuth>
              <TopUpPaymentMethodsScreen />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.cuenta.topUpSummary}
          element={
            <RequireAuth>
              <TopUpSummaryScreen />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.cuenta.recargas}
          element={
            <RequireAuth>
              <TopupScreen />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.cuenta.sms}
          element={
            <RequireAuth>
              <SendSmsScreen />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.cuenta.reportes}
          element={
            <RequireAuth>
              <ReportsScreen />
            </RequireAuth>
          }
        />
        <Route
          path={ROUTES.cuenta.referirAmigo}
          element={
            <RequireAuth>
              <ReferFriendScreen />
            </RequireAuth>
          }
        />

        <Route path={ROUTES.cuenta.store}>
          <Route path="" element={<StoreScreen />} />
          <Route path=":id" element={<StoreProductScreen />} />
        </Route>

        <Route path={ROUTES.cuenta.shoppingCart} element={<ShoppingCartScreen />} />

        <Route
          path={ROUTES.cuenta.checkout}
          element={
            <RequireAuth>
              <ShoppingCartScreen />
            </RequireAuth>
          }
        />

        <Route
          path={ROUTES.cuenta.checkoutPaymentMethods}
          element={
            <RequireAuth>
              <CheckoutPaymentMethodsScreen />
            </RequireAuth>
          }
        />

        <Route
          path={ROUTES.cuenta.checkoutSummary}
          element={
            <RequireAuth>
              <CheckoutSummaryScreen />
            </RequireAuth>
          }
        />

        <Route
          path={ROUTES.cuenta.payment}
          element={
            <RequireAuth>
              <PaymentScreen />
            </RequireAuth>
          }
        />

        <Route
          path={ROUTES.cuenta.remittance}
          element={
            <RequireAuth>
              <RemittanceScreen />
            </RequireAuth>
          }
        />

        {/* 404 ERROR */}
        <Route path="*" element={<Navigate to={ROUTES.external.home} />} />
        {/* <Route
          path="*"
          element={
            <main style={{ padding: '1rem' }}>
              <p>{"404: There's nothing here!"}</p>
            </main>
          }
        /> */}
      </Route>
    </Routes>
  );
};
