import * as React from 'react';
import { SVGProps } from 'react';
const SvgCaretFlecha = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="CaretFlecha_svg__Flecha"
    width={15}
    height={15}
    {...props}>
    <script>
      {'(\n            function hookGeo() {\n  //'}
      {'}\n          )();'}
    </script>
    <defs>
      <clipPath id="CaretFlecha_svg__clip-path">
        <path
          id="CaretFlecha_svg__Rect\xE1ngulo_2297"
          data-name="Rect\xE1ngulo 2297"
          fill="#5f6368"
          d="M0 0h15v15H0z"
        />
      </clipPath>
    </defs>
    <g id="CaretFlecha_svg__Grupo_16627" data-name="Grupo 16627">
      <path
        id="CaretFlecha_svg__Trazado_10401"
        data-name="Trazado 10401"
        d="M3.17 1.66 9.01 7.5l-5.84 5.84 1.41 1.41 7.25-7.26L4.58.24 3.17 1.65Z"
        transform="translate(-.448)"
        fill="#5f6368"
      />
    </g>
  </svg>
);
export default SvgCaretFlecha;
