import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { RemittanceServices } from '../../../services/remittance';
import { QueryTypes } from '../../../types/queryTypes';
import { RemittanceMethod } from '../../../types/remittanceMethod';
import { RemittanceAmount, RemittanceOrder } from '../../../types/remittances';

import { toUpper } from 'lodash';
import { Button } from 'react-bootstrap';
import { FlechaDer } from '../../../components/svg';
import { UtilService } from '../../../services';

import {
  PaymentLine,
  PaymentWizard,
  TopupProducts,
  TopupProductsProps,
} from '../../../components/account';
import { MegaLoading } from '../../../components/external';
import styles from './remittance-screen.module.scss';

export type RemittanceProductsScreenProps = {
  receiver: {
    name: string;
    phone: string;
    address: string;
    carnet: string;
    mlcCard?: string;
    municipalityId: number;
    provinceId: number;
  };
  deliveryMethod: RemittanceMethod;
  remittanceOrder: Partial<RemittanceOrder>;
  navigateBack: (reset?: boolean) => void;
};

export const RemittanceProductsScreen = (props: RemittanceProductsScreenProps) => {
  const [amountList, setAmountList] = useState<RemittanceAmount[]>([]);
  const [productList, setProductList] = useState<TopupProductsProps['products']>([]);
  const [selectedAmount, setSelectedAmount] = useState<RemittanceAmount | null>(null);
  const [remittanceOrder, setRemittanceOrder] = useState<RemittanceOrder | null>(null);
  const [deliveryCurrency, setDeliveryCurrency] = useState<'usd' | 'cup' | 'mlc'>('usd');
  const [instructions, setInstructions] = useState<string>('');
  const [paymentScreenVisible, setPaymentScreenVisible] = useState<boolean>(false);

  const amountQuery = useQuery(
    [
      QueryTypes.GetRemittanceAmounts,
      deliveryCurrency,
      props.deliveryMethod.id,
      props.receiver.municipalityId,
      props.receiver.provinceId,
    ],
    () => {
      if (props.deliveryMethod.id == 'mlc'){
        setDeliveryCurrency('mlc');
      }
      
      return RemittanceServices.getAmounts(
        toUpper(deliveryCurrency),
        props.receiver.municipalityId,
        props.receiver.provinceId,
        props.deliveryMethod.id
      );
      
    },
    {
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (amountQuery.status === 'success') {
      setAmountList(amountQuery.data);
      setSelectedAmount(amountQuery.data[0]);
      setProductList(convertAmountToProduct(amountQuery.data));
    }
  }, [amountQuery.data]);

  const convertAmountToProduct = (amounts: RemittanceAmount[]): TopupProductsProps['products'] => {
    return amounts.map((a) => {
      return {
        id: a.id.toString(),
        priceTitle: `${a.amount_to_add} ${toUpper(deliveryCurrency)}`,
        priceSubtitle: `${a.amount_to_pay} USD` ,
        description: `Fecha de entrega: ${a.duration}`,
      };
    });
  };

  const onChangeSelectedAmount = (id: string) => {
    const found = amountList.find((a) => `${a.id}` === id);

    if (found) {
      setSelectedAmount(found);
    } else {
      setSelectedAmount(amountList[0]);
    }
  };

  const onPay = () => {
    if (!selectedAmount) {
      return;
    }

    setRemittanceOrder({
      ...props.remittanceOrder,
      productId: selectedAmount.id,
      instructions: instructions,
      currency: deliveryCurrency,
    } as RemittanceOrder);

    setPaymentScreenVisible(true);
  };

  return (
    <>
      <div className="row">
        <div className="col-12">
          <div className={`${styles.receiverInfoWrapper} d-flex flex-column flex-lg-row`}>
            <div className="d-flex flex-column w-100 w-lg-75">
              <div className={styles.ellipsis}>
                <b>{props.receiver.name}</b>
                {', '}
                <span>{props.receiver.phone}</span>
              </div>
              <div className={styles.ellipsis} title={`${props.receiver.address}`}>
                {props.receiver.address}
              </div>
              <div className={styles.ellipsis}>Carnet: {props.receiver.carnet}</div>
              {props.deliveryMethod.id === 'mlc' && (
                <div className={styles.ellipsis}>
                  Tarjeta MLC: {UtilService.formatCardNumber(props.receiver.mlcCard || '')}
                </div>
              )}
            </div>
            <div className="d-flex d-column w-100 w-lg-25 justify-content-start justify-content-lg-end align-items-center mt-3 mt-lg-0">
              <Button
                variant="link"
                className="text-decoration-none text-secondary px-0"
                onClick={(_) => props.navigateBack()}>
                Cambiar <FlechaDer width={20} fill="#8AB934" className="ms-1" />
              </Button>
            </div>
          </div>
        </div>
      </div>

      {props.deliveryMethod.id === 'cash' && (
        <div className="row mt-4">
          <div className="col-12">
            <div className="megaInput">
              <textarea
                className={`form-control mega ${styles.txtArea}`}
                id="instructions"
                value={instructions}
                autoComplete="off"
                onChange={(e) => setInstructions(e.target.value)}></textarea>
              <label htmlFor="instructions">Instrucciones</label>
            </div>
          </div>
        </div>
      )}

      <div className="row mt-4">
        <div className="col-12">
          <h6 className="mega-fs-mid">¿Qué cantidad deseas enviar?</h6>
        </div>

        {props.deliveryMethod.id === 'cash' && (
          <div className="col-12">
            <div
              className={`form-check form-check-inline ${styles.checkboxWrapper} ${
                deliveryCurrency === 'usd' ? styles.active : ''
              }`}>
              <input
                className="form-check-input"
                type="radio"
                id="usd_val"
                name="currency"
                value="usd"
                checked={deliveryCurrency === 'usd'}
                onChange={() => setDeliveryCurrency('usd')}
              />
              <label className="form-check-label" htmlFor="usd_val">
                Recibir en USD
              </label>
            </div>
            <div
              className={`form-check form-check-inline ${styles.checkboxWrapper} ${
                deliveryCurrency === 'cup' ? styles.active : ''
              }`}>
              <input
                className="form-check-input"
                type="radio"
                name="currency"
                id="cup_val"
                value="cup"
                checked={deliveryCurrency === 'cup'}
                onChange={() => setDeliveryCurrency('cup')}
              />
              <label className="form-check-label" htmlFor="cup_val">
                Recibir en CUP
              </label>
            </div>
          </div>
        )}
      </div>

      <div className="row mt-3">
        <div className="col-12">
          {UtilService.isLoadingQueries([amountQuery]) && <MegaLoading isLoading={true} />}

          {selectedAmount && !UtilService.isLoadingQueries([amountQuery]) && (
            <TopupProducts
              serviceType="remittance"
              selectedId={`${selectedAmount.id}`}
              products={productList}
              onSelectedChange={onChangeSelectedAmount}
            />
          )}
        </div>
      </div>

      <div className="row mt-2">
        <div className="col-12 mb-3">
          <hr />
        </div>
        <div className="col-12 col-lg-6 d-flex flex-row align-items-center mb-2 mb-lg-0">
          <div className="flex-fill flex-lg-grow-0 me-0 me-lg-3">Total a Pagar</div>
          <div className="flex-row align-items-center">
            <h6 className="text-secondary mb-0 pb-0">${selectedAmount?.amount_to_pay}</h6>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <Button variant="secondary" className="w-100" onClick={onPay}>
            Pagar
          </Button>
        </div>
      </div>

      {selectedAmount && paymentScreenVisible && remittanceOrder && (
        <PaymentWizard
          show={paymentScreenVisible}
          paymentType={PaymentLine.remittance}
          totalToPay={selectedAmount.amount_to_pay}
          serviceFee={0.0}
          deliveryDate={selectedAmount.duration}
          remittanceOrder={remittanceOrder}
          lines={[
            {
              fullName: props.receiver.name,
              phone: props.receiver.phone,
              productCost: selectedAmount.amount_to_send,
              deliveryCost: selectedAmount.amount_to_pay - selectedAmount.amount_to_send,
              amountToReceive: `${selectedAmount.amount_to_add} ${deliveryCurrency.toUpperCase()}` ,
              price: selectedAmount.amount_to_pay,
              title: `Remesa ${selectedAmount.amount_to_add} ${deliveryCurrency.toUpperCase()}`,
              subtitle: `$${selectedAmount.amount_to_pay}`,
              recibenDescription: props.receiver.address,
              carnet: props.receiver.carnet,
              mlcCard: props.receiver.mlcCard,
            },
          ]}
          onCancel={() => {
            setPaymentScreenVisible(false);
          }}
          onSuccess={() => {
            setPaymentScreenVisible(false);

            setInstructions('');
            setSelectedAmount(null);
            setDeliveryCurrency('usd');

            props.navigateBack(true);
          }}
        />
      )}
    </>
  );
};
