import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Container, Modal, Row, Spinner } from 'react-bootstrap';
import { FieldValues, useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from 'react-query';
import * as yup from 'yup';

import { MODAL_TYPES, useGlobalModalContext } from '../../../providers/globalModal.provider';
import { AccountServices, PaymentServices, UtilService } from '../../../services';
import { PaymentMethodCard } from '../../../types/payment';
import { QueryTypes } from '../../../types/queryTypes';
import { MegaLoading } from '../../external/megaLoading';
import { MegaError } from '../megaError';
import { ProfilePaymentMethods } from '../profilePaymentMethods';


export type AutoFundsProps = {
  display: boolean;
  onClose: () => void;
  onSave: () => void;
};

const reloadSchema = yup
  .object()
  .shape({
    minBal: yup.number().required(),
    amount: yup.number().required(),
  })
  .required();

export const AutoFunds = ({ display, onClose, onSave }: AutoFundsProps) => {
  const queryCache = useQueryClient();
  const { showModal, hideModal } = useGlobalModalContext();

  const [creditCardList, setCreditCardList] = useState<PaymentMethodCard[]>([]);
  const [selectedCreditCard, setSelectedCreditCard] = useState<PaymentMethodCard | null>(null);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  //queries
  const autoRechargeQuery = useQuery(
    QueryTypes.GetAutoRechargeData,
    AccountServices.getAutoRechargeData
  );
  const paymentMethodsQuery = useQuery(
    QueryTypes.GetPaymentMethods,
    PaymentServices.getPaymentMethods
  );

  const modalQueries = [autoRechargeQuery, paymentMethodsQuery];

  const { register, handleSubmit, formState, reset, setValue } = useForm({
    resolver: yupResolver(reloadSchema),
    mode: 'onChange',
  });

  useEffect(() => {
    if (autoRechargeQuery.status === 'success' && paymentMethodsQuery.status === 'success') {
      if (paymentMethodsQuery.data.length > 0) {
        setCreditCardList(paymentMethodsQuery.data);
      }
    }
  }, [autoRechargeQuery.status, paymentMethodsQuery.status]);

  useEffect(() => {
    if (display) {
      reset();
    }
  }, [display]);

  const validateValue = (value: string, minValue: number, maxValue: number, formField: string) => {
    if (!value || isNaN(Number(value)) || Number(value) < minValue) {
      setValue(formField, Number(minValue).toFixed(2));
      return;
    }

    if (Number(value) > maxValue) {
      setValue(formField, Number(maxValue).toFixed(2));
      return;
    }

    setValue(formField, Number(value).toFixed(2));
  };

  const onBlurBalMin = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    validateValue(event.target.value, 1, 100, 'minBal');
  };

  const onBlurAmount = (event: React.FocusEvent<HTMLInputElement, Element>) => {
    validateValue(event.target.value, 5, 25, 'amount');
  };

  const onSubmit = async (formData: FieldValues) => {
    if (!formData.amount || !formData.minBal || !selectedCreditCard) {
      setIsSubmitting(false);
      setErrorMessage(
        'Verifique que la información de Balance Mínimo y la Cantidad a recargar esten correctas.'
      );
      return;
    }

    showModal(MODAL_TYPES.LOADING, {
      title: 'Salvando su información',
    });

    const isSuccess = await AccountServices.updateAutoRecharge(
      Number(selectedCreditCard.last_four_digits),
      formData.minBal,
      formData.amount
    );

    hideModal();

    if (isSuccess) {
      setErrorMessage('');
      queryCache.invalidateQueries(QueryTypes.GetAutoRechargeData);
      onSave();
      return;
    }

    setErrorMessage('Hubo un error salvando su información. Por favor, intente de nuevo.');
  };

  return (
    <Modal size="lg" show={display} onHide={onClose} backdrop="static" centered>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="mega-modal-title px-4">
          <Modal.Title className="">Auto-recarga de saldo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xs={12} className="mb-3">
                <p className="mega-fs-small">
                  Esta opción le permite agregar dinero a su cuenta automáticamente cada vez que el
                  balance cae por debajo de un límite que usted fija.
                </p>
              </Col>
            </Row>

            {UtilService.isLoadingQueries(modalQueries) && <MegaLoading isLoading />}
            {UtilService.isErrorQueries(modalQueries) && (
              <MegaError displayRetry retry={() => UtilService.refetchQueries(modalQueries)} />
            )}

            {UtilService.isSuccessQueries(modalQueries) && (
              <div>
                <Row>
                  {errorMessage && !isSubmitting && (
                    <Col xs={12} lg={12} className="mb-4">
                      <Alert variant={'danger'}>{errorMessage}</Alert>
                    </Col>
                  )}
                  <Col xs={12} lg={6} className="mb-4">
                    <div className="mb-4">
                      <div className="megaInput">
                        <label htmlFor="minBal" className="form-label mega">
                          Balance Mínimo
                        </label>
                        <input
                          type="number"
                          className={`form-control mega${
                            formState.errors.minBal ? 'border border-danger' : ''
                          }`}
                          defaultValue={
                            !!Number(autoRechargeQuery.data?.trigger)
                              ? autoRechargeQuery.data?.trigger
                              : '1.00'
                          }
                          id="minBal"
                          {...register('minBal')}
                          onBlur={onBlurBalMin}
                        />
                      </div>
                      {formState.errors.minBal && (
                        <div className="text-danger">El Balance Mínimo es requerido</div>
                      )}
                    </div>
                  </Col>

                  <Col xs={12} lg={6} className="mb-4">
                    <div className="mb-4">
                      <div className="megaInput">
                        <label htmlFor="amount" className="form-label mega">
                          Cantidad a recargar
                        </label>
                        <input
                          type="number"
                          className={`form-control mega${
                            formState.errors.amount ? 'border border-danger' : ''
                          }`}
                          id="amount"
                          defaultValue={
                            !!Number(autoRechargeQuery.data?.amount)
                              ? autoRechargeQuery.data?.amount
                              : '5.00'
                          }
                          {...register('amount')}
                          onBlur={onBlurAmount}
                        />
                      </div>
                      {formState.errors.amount && (
                        <div className="text-danger">La Cantidad a recargar es requerida</div>
                      )}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={12} className="mb-4">
                    <h6 className="fs-6">Método de Pago</h6>

                    <ProfilePaymentMethods
                      onSelectPaymentMethod={(item) => {
                        setSelectedCreditCard(item as PaymentMethodCard);
                      }}
                      hideManageButtons={true}
                      showResponsive={true}
                      onlySavePmOnDb={true}
                      forceAddNewPaymentMethod={true}
                    />
                  </Col>
                </Row>
              </div>
            )}
          </Container>
        </Modal.Body>
        {UtilService.isSuccessQueries(modalQueries) && (
          <Modal.Footer>
            <div className="w-100 text-end">
              <Button
                variant="outline-secondary"
                onClick={onClose}
                type="button"
                disabled={isSubmitting}
                className={`px-5 me-3`}>
                Cancelar
              </Button>

              <Button
                variant="secondary text-white"
                type="submit"
                className="px-5"
                disabled={isSubmitting}>
                {isSubmitting ? (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    variant="light"
                    aria-hidden="true"
                  />
                ) : !!autoRechargeQuery.data ? (
                  <span>Modificar</span>
                ) : (
                  <span>Crear</span>
                )}
              </Button>
            </div>
          </Modal.Footer>
        )}
      </form>
    </Modal>
  );
};
