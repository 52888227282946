import * as React from 'react';
import { SVGProps } from 'react';
const SvgMiCuenta = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="mi_cuenta_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 66"
    {...props}>
    <defs>
      <style>{'.mi_cuenta_svg__cls-3{fill:#263645}'}</style>
    </defs>
    <g id="mi_cuenta_svg__Capa_1-2" data-name="Capa 1">
      <path
        id="mi_cuenta_svg__Trazado_308"
        data-name="Trazado 308"
        d="M32.97 2.69c-14.45 0-22.25 1.52-26.3 7.44-3.14 4.57-4.04 11.76-4.04 22.89 0 14.25 1.47 22.03 7.2 26.14 4.55 3.26 11.8 4.2 23.13 4.2 11.91 0 19.31-1.03 23.81-4.71 5.17-4.23 6.52-11.95 6.52-25.62 0-10.77-.84-17.85-3.73-22.44-3.96-6.31-11.79-7.9-26.61-7.9Z"
        style={{
          fill: '#d0e3ae',
        }}
      />
      <path
        className="mi_cuenta_svg__cls-3"
        d="M32.97 64.56c-10.6 0-18.71-.75-23.83-4.42-6.44-4.61-7.7-13.44-7.7-27.11 0-10.44.72-18.44 4.25-23.57 4.55-6.66 13.45-7.97 27.28-7.97s23.17 1.38 27.62 8.46c3.25 5.16 3.91 12.96 3.91 23.07 0 13.2-1.14 21.78-6.96 26.55-5.06 4.14-13.48 4.98-24.57 4.98Zm0-60.67c-15.2 0-21.82 1.81-25.31 6.92-2.76 4.01-3.83 10.24-3.83 22.22 0 15.04 1.75 21.62 6.7 25.17 3.99 2.86 10.28 3.97 22.44 3.97s19.14-1.25 23.05-4.44c4.44-3.63 6.09-10.33 6.09-24.7 0-11.48-1.03-17.8-3.54-21.8-3.41-5.42-10.1-7.34-25.59-7.34Z"
      />
      <path
        id="mi_cuenta_svg__Uni\xF3n_11"
        data-name="Uni\xF3n 11"
        d="M33.01 54.09c-5.91-.05-11.8-.63-17.6-1.72-1.99-.98-2.28-1.95-1.3-3.94.87-1.69 2.02-3.21 3.4-4.51 8.49-8.5 22.27-8.51 30.78-.02 1.25 1.24 2.34 2.64 3.25 4.14 1.39 2.35 1.16 3.17-1.3 4.4a78.897 78.897 0 0 1-16.79 1.65h-.43ZM22.08 23.05c0-6.05 4.9-10.95 10.95-10.95S43.98 17 43.98 23.05c0 6.05-4.9 10.95-10.94 10.95-6.05 0-10.95-4.9-10.95-10.94Z"
        style={{
          fill: '#8ab934',
        }}
      />
      <path
        className="mi_cuenta_svg__cls-3"
        d="M34.11 55.3h-1.12c-5.96-.06-11.95-.65-17.8-1.75l-.16-.03-.15-.07c-2.58-1.27-3.13-2.92-1.85-5.54a17.61 17.61 0 0 1 3.66-4.85c4.31-4.31 10.07-6.7 16.2-6.71h.01c6.13 0 11.89 2.38 16.22 6.71 1.31 1.31 2.47 2.78 3.43 4.37.79 1.34 1.25 2.46.92 3.58-.35 1.2-1.51 1.91-2.71 2.51l-.14.07-.15.03a80.963 80.963 0 0 1-16.36 1.68Zm-18.32-4.07a98.608 98.608 0 0 0 17.23 1.67h.45c5.47.05 10.98-.48 16.36-1.58 1.08-.55 1.31-.85 1.34-.98.04-.13 0-.53-.68-1.68-.85-1.41-1.89-2.73-3.06-3.91a20.416 20.416 0 0 0-14.53-6.02h-.01c-5.49 0-10.66 2.15-14.54 6.03a15.478 15.478 0 0 0-3.18 4.21c-.66 1.35-.62 1.63.62 2.25ZM33.03 35.2c-3.24 0-6.29-1.26-8.58-3.55a12.072 12.072 0 0 1-3.56-8.59c0-6.7 5.45-12.15 12.14-12.15s12.14 5.45 12.14 12.14c0 3.24-1.26 6.29-3.55 8.59a12.067 12.067 0 0 1-8.58 3.56Zm0-21.89c-5.38 0-9.75 4.37-9.75 9.75 0 2.61 1.01 5.06 2.86 6.9a9.682 9.682 0 0 0 6.89 2.85c2.6 0 5.05-1.02 6.89-2.86a9.682 9.682 0 0 0 2.85-6.89c0-5.38-4.37-9.75-9.75-9.75Z"
      />
      <path
        id="mi_cuenta_svg__Trazado_190"
        data-name="Trazado 190"
        d="M40.57 46.88c0-1.87-2.11-2.61-7.49-2.61-5.38 0-7.49.74-7.49 2.61s2.11 2.61 7.49 2.61 7.49-.74 7.49-2.61Z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);
export default SvgMiCuenta;
