import { useEffect, useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { useQuery } from 'react-query';
import Select from 'react-select';
import {
  IconOptionLocation,
  IconSingleValueLocation,
  reactSelectStyles,
} from '../../../config/reactSelect';
import { StoreService } from '../../../services/store';
import { MegaProductCategory } from '../../../types/products/productCategory';
import { SortOption } from '../../../types/products/sortOption';
import { Province } from '../../../types/province';
import { QueryTypes } from '../../../types/queryTypes';
import { FilterCombo } from '../../svg';

import { StoreSidebarFilter } from '../storeSidebarFilter';
import styles from './storeFilter.module.scss';

export type StoreFilterProps = {
  selectedProvince: Province | null;
  selectedCategory: MegaProductCategory | null;
  selectedSortOption: SortOption | null;

  onProvinceChange?: (province: Province | null) => void;
  onCategoryChange?: (category: MegaProductCategory | null) => void;
  onSortOptionChange?: (sortOption: SortOption | null) => void;
};

export const StoreFilter = (props: StoreFilterProps) => {
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [selectedProvince, setSelectedProvince] = useState<Province | null>(props.selectedProvince);
  const [openFilterMenu, setOpenFilterMenu] = useState<boolean>(false);
  const provinceQuery = useQuery(QueryTypes.GetStoreProvinces, StoreService.getProvinces);

  const [selectedCategory, setSelectedCategory] = useState<MegaProductCategory | null>(
    props.selectedCategory
  );
  const [selectedSortOption, setSelectedSortOption] = useState<SortOption | null>(
    props.selectedSortOption
  );

  useEffect(() => {
    if (provinceQuery.status === 'success' && provinceQuery.data.length) {
      const newArray = [{ id: 0, name: 'Todas las localidades' }, ...provinceQuery.data];
      setProvinces(newArray);
      !selectedProvince && setSelectedProvince(newArray[0]);
    }
  }, [provinceQuery.status]);

  useEffect(() => {
    document.body.classList.add('filter-open');

    return () => {
      document.body.classList.remove('filter-open');
    };
  }, []);

  return (
    <>
      <div className={`d-flex flex-row ${styles.wrapper}`}>
        <div className="flex-fill">
          <div className="megaInput">
            <label htmlFor="locality">Provincia</label>
            <Select
              id="locality"
              placeholder="Seleccione una localidad"
              value={selectedProvince}
              options={provinces}
              isSearchable={false}
              isClearable={false}
              className="w-100 mega-select"
              isMulti={false}
              styles={reactSelectStyles}
              components={{
                Option: IconOptionLocation,
                SingleValue: IconSingleValueLocation,
              }}
              onChange={(newValue) => {
                setSelectedProvince(newValue);
                props.onProvinceChange && props.onProvinceChange(newValue);
              }}
            />
          </div>
        </div>
        <div className="">
          <Button variant="link" onClick={() => setOpenFilterMenu(true)}>
            <FilterCombo width={30} />
          </Button>
        </div>
      </div>

      <Offcanvas
        id="cartCanvas"
        show={openFilterMenu}
        placement={'end'}
        onHide={() => setOpenFilterMenu(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Categorías</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className={styles.sidebar_body_content}>
            <div className={styles.sidebar_body_items_container}>
              <StoreSidebarFilter
                fromMenu
                selectedCategory={selectedCategory}
                selectedProvince={selectedProvince}
                selectedSortOption={selectedSortOption}
                onCategoryChange={(v) => {
                  setSelectedCategory(v);
                  props.onCategoryChange && props.onCategoryChange(v);
                  setOpenFilterMenu(false);
                }}
                onProvinceChange={(v) => {
                  setSelectedProvince(v);
                  props.onProvinceChange && props.onProvinceChange(v);
                  setOpenFilterMenu(false);
                }}
                onSortOptionChange={(v) => {
                  setSelectedSortOption(v);
                  props.onSortOptionChange && props.onSortOptionChange(v);
                  setOpenFilterMenu(false);
                }}
              />
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
