import { Modal } from 'react-bootstrap';
import Lottie from 'react-lottie';
import loadingJson from '../../../assets/animations/loading.json';
import { useGlobalModalContext } from '../../../providers/globalModal.provider';

export const LoadingModal = () => {
  const { currentModalProps } = useGlobalModalContext();

  const title = currentModalProps?.title || 'Cargando...';
  const description = currentModalProps?.description || 'Este proceso puede tomar hasta un minuto';

  const defaultOptionsLoading = {
    loop: true,
    autoplay: true,
    animationData: loadingJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Modal
      size="sm"
      show={true}
      backdrop="static"
      backdropClassName="mega-double-backdrop"
      dialogClassName="mega-modal-loading mega-double-dialog"
      centered>
      <Modal.Body>
        <Lottie options={defaultOptionsLoading} height={70} width={70} />

        <h6 className="text-center" dangerouslySetInnerHTML={{ __html: title }}></h6>
        <p
          className="text-center mega-fs-small"
          dangerouslySetInnerHTML={{ __html: description }}></p>
      </Modal.Body>
    </Modal>
  );
};
