import * as React from 'react';
import { SVGProps } from 'react';
const SvgWhatsapp = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <g data-name="Capa 1">
      <path
        d="M14.75 23.93c0 12.06 9.78 21.84 21.84 21.84 3.97 0 7.69-1.06 10.9-2.91 2.39.88 5.1 1.75 7.77 2.38 1.61.38 3.05-1 2.72-2.63-.56-2.79-1.42-5.59-2.34-8.01 1.77-3.15 2.77-6.79 2.77-10.66 0-12.06-9.78-21.84-21.84-21.84s-21.84 9.78-21.84 21.84Z"
        style={{
          fill: '#d0e3ae',
        }}
      />
      <path
        d="M23.41 14.23c-12.06 0-21.84 9.78-21.84 21.84 0 3.87 1.01 7.51 2.77 10.66-.92 2.42-1.77 5.22-2.34 8.01a2.232 2.232 0 0 0 2.72 2.63c2.67-.63 5.38-1.49 7.77-2.38 3.21 1.85 6.93 2.91 10.9 2.91 12.06 0 21.84-9.78 21.84-21.84s-9.78-21.84-21.84-21.84Z"
        style={{
          fill: '#8ab934',
        }}
      />
      <path
        d="M56.85 34.67c1.75-3.29 2.67-6.99 2.67-10.74 0-12.64-10.28-22.92-22.92-22.92-9.27 0-17.27 5.54-20.87 13.47C6.85 17.65.48 26.13.48 36.07c0 3.75.92 7.45 2.67 10.74-.7 1.91-1.6 4.67-2.21 7.71-.23 1.12.13 2.27.94 3.06.82.79 1.98 1.11 3.1.85 2.29-.54 4.85-1.31 7.43-2.24 3.36 1.84 7.15 2.81 11 2.81 9.28 0 17.28-5.54 20.88-13.48 1.13-.4 2.24-.88 3.3-1.46 2.58.93 5.14 1.71 7.43 2.24a3.327 3.327 0 0 0 4.04-3.91c-.5-2.47-1.26-5.12-2.21-7.71ZM23.41 56.82c-3.64 0-7.22-.96-10.36-2.77l-.44-.25-.48.18c-2.66.98-5.3 1.79-7.65 2.34-.4.09-.8-.01-1.09-.29-.28-.28-.4-.66-.32-1.07.5-2.5 1.29-5.22 2.29-7.84l.18-.47-.25-.44a20.822 20.822 0 0 1-2.64-10.13c0-11.44 9.31-20.75 20.75-20.75s20.75 9.31 20.75 20.75-9.31 20.75-20.75 20.75Zm33.2-12.94c-.29.28-.68.39-1.09.29-2.35-.55-4.99-1.36-7.65-2.34l-.48-.18-.44.25c-.52.3-1.06.58-1.61.83.64-2.11.99-4.35.99-6.67 0-12.64-10.28-22.92-22.92-22.92-1.66 0-3.28.18-4.84.52C22.15 7.4 28.89 3.17 36.6 3.17c11.44 0 20.75 9.31 20.75 20.75 0 3.55-.91 7.05-2.64 10.13l-.25.44.18.47c.99 2.63 1.78 5.34 2.29 7.84.08.4-.04.79-.32 1.07Z"
        style={{
          fill: '#263645',
        }}
      />
      <path
        d="M26.4 46.61c-1.21-.1-2.6-.41-3.84-1.17-3.68-2.26-6.69-5.19-8.82-8.96-2.1-3.72-.57-9.19 3.14-11.32.23-.13.51-.23.76-.29 1.56-.38 3.21 1.94 3.88 3.53.45 1.08.4 1.81-.37 2.64-2.05 2.19-2.03 2.26-.17 4.72 1.11 1.47 2.5 2.62 4.07 3.57.95.58 1.66.55 2.45-.32.71-.78 1.42-2.06 2.66-1.56 1.55.63 3.3 1.6 4.09 2.93.38.63.42 1.45-.08 2.03-1.98 2.32-4.26 4.15-7.75 4.2Z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);
export default SvgWhatsapp;
