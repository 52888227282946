import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import Select from 'react-select';
import {
  IconOptionLocation,
  IconSingleValueLocation,
  reactSelectStyles,
} from '../../../config/reactSelect';
import { StoreService } from '../../../services/store';
import { Province } from '../../../types/province';
import { QueryTypes } from '../../../types/queryTypes';

import { MegaProductCategory } from '../../../types/products/productCategory';
import { SortOption } from '../../../types/products/sortOption';
import {
  FilterAlimentos,
  FilterAseo,
  FilterComida,
  FilterCumpleannos,
  FilterMiscelaneas,
  FilterMixtos
} from '../../svg';
import styles from './storeSidebarFilter.module.scss';

export type StoreSidebarFilterProps = {
  fromMenu?: boolean;
  selectedProvince?: Province | null;
  selectedCategory?: MegaProductCategory | null;
  selectedSortOption?: SortOption | null;

  onProvinceChange?: (province: Province | null) => void;
  onCategoryChange?: (category: MegaProductCategory | null) => void;
  onSortOptionChange?: (sortOption: SortOption | null) => void;
};

export const StoreSidebarFilter = (props: StoreSidebarFilterProps) => {
  //Provinces
  const [selectedProvince, setSelectedProvince] = useState<Province | null>(
    props.selectedProvince ?? null
  );
  const [provinces, setProvinces] = useState<Province[]>([]);
  const provinceQuery = useQuery(QueryTypes.GetStoreProvinces, StoreService.getProvinces);

  useEffect(() => {
    if (provinceQuery.status === 'success' && provinceQuery.data.length) {
      const newArray = [{ id: 0, name: 'Todas las localidades' }, ...provinceQuery.data];
      setProvinces(newArray);
    }
  }, [provinceQuery.status]);

  useEffect(() => {
    setSelectedProvince(provinces[0]);
  }, [provinces]);

  //SortOptions
  const sortOptions: SortOption[] = [
    {
      label: 'Menor precio',
      field: 'price',
      direction: 'asc',
    },
    {
      label: 'Mayor precio',
      field: 'price',
      direction: 'desc',
    },
  ];
  const [selectedSortOption, setSelectedSortOption] = useState<SortOption | null>(
    props.selectedSortOption ?? sortOptions[0]
  );
  const [selectedSortOptionDirection, setSelectedSortOptionDirection] = useState<string>(
    props.selectedSortOption?.direction ?? sortOptions[0].direction
  );

  //Categories
  const [selectedCategory, setSelectedCategory] = useState<MegaProductCategory | null>(
    props.selectedCategory ?? null
  );
  const [categories, setCategories] = useState<MegaProductCategory[]>([]);
  const categoryQuery = useQuery(
    QueryTypes.GetStoreProductCategories,
    StoreService.getProductCategories
  );

  useEffect(() => {
    if (categoryQuery.status === 'success' && categoryQuery.data.length) {
      const newArray = [
        { id: 0, name: 'Todas las categorías', icon: 'todas.png' },
        ...categoryQuery.data,
      ];
      setCategories(newArray);
      !selectedCategory && setSelectedCategory(newArray[0]);
    }
  }, [categoryQuery.status]);

  const getCategoryIcon = (icon: string) => {
    switch (icon) {
      case 'todas.png':
        return <FilterMiscelaneas width={20} />;
      case 'aseo.png':
        return <FilterAseo width={20} />;
      case 'comida.png':
        return <FilterComida width={20} />;
      case 'comida-preparada.png':
        return <FilterAlimentos width={20} />;
      case 'mixto.png':
        return <FilterMixtos width={20} />;
      case 'buffet.png':
        return <FilterCumpleannos width={20} />;
    }
  };

  const onClickCategory = (category: MegaProductCategory) => {
    setSelectedCategory(category);
    props.onCategoryChange && props.onCategoryChange(category);
  };

  return (
    <>
      <div className={`${props.fromMenu ? styles.frameMenu : styles.frame}`}>
        {!props.fromMenu && (
          <>
            <div className={`${styles.section_title}`}>Filtros</div>

            <div className="megaInput">
              <label htmlFor="locality">Localidad</label>
              <Select
                id="locality"
                placeholder="Seleccione una localidad"
                value={selectedProvince}
                options={provinces}
                isSearchable={false}
                isClearable={false}
                className="w-100 mega-select"
                isMulti={false}
                styles={reactSelectStyles}
                components={{
                  Option: IconOptionLocation,
                  SingleValue: IconSingleValueLocation,
                }}
                onChange={(newValue) => {
                  setSelectedProvince(newValue);
                  props.onProvinceChange && props.onProvinceChange(newValue);
                }}
              />
            </div>

            <div className={`${styles.horizontal_separator}`}></div>

            <div className={`${styles.section_title}`}>Categorías</div>
          </>
        )}

        {categories.map((category) => (
          <div key={category.id} className="my-3">
            <div
              className={`${styles.category} ${
                selectedCategory?.id == category.id ? styles.categoryActive : styles.category
              }`}
              onClick={() => onClickCategory(category)}>
              {getCategoryIcon(category.icon)}
              <span className={`${styles.text}`}>{category.name}</span>
            </div>
          </div>
        ))}

        <div className={`${styles.horizontal_separator}`}></div>

        <div className={`${styles.section_title}`}>Precios</div>

        {sortOptions.map((option, index) => (
          <div className={`${styles.mega_radio}`} key={option.label}>
            <div className={`form-check`}>
              <input
                className="form-check-input"
                type="radio"
                name="filterRadioOptions"
                id={'filterRadio' + index}
                value={option.direction}
                checked={option.direction === selectedSortOption?.direction}
                onChange={(e) => {
                  if (e.target.checked) {
                    setSelectedSortOption(option);
                    props.onSortOptionChange && props.onSortOptionChange(option);
                  }
                }}
              />
              <label
                className={`form-check-label ${styles.form_check_label}`}
                htmlFor={'filterRadio' + index}>
                {option.label}
              </label>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};
