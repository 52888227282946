import { createContext, ReactNode, useContext, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { NewCardInformation } from '../types/newCardInformation';
import { NewPaypalInformation } from '../types/newPaypalInformation';
import { PaymentMethodCard, PaymentMethodType } from '../types/payment';
import { TopUpInternetItem, TopUpMobileItem, TopUpNautaItem } from '../types/topupItems';
import { Formatter } from '../utilities';
import { MegaCarrierInitialData } from '../types/topups';

type GetApplicableMobileDiscountParams = {
  countryCode: string | undefined;
  remote_amount: number;
};

type TopUpContext = {
  addInternetItem: (internetItem: TopUpInternetItem) => void;
  addMobileItem: (mobileItem: TopUpMobileItem) => void;
  addNautaItem: (nautaItem: TopUpNautaItem) => void;
  cartTotal: number;
  clearAfterSuccessfulTopup: () => void;
  clearCurrentPaymentMethod: () => void;
  currentPaymentMethod: PaymentMethodCard | undefined;
  currentPaymentMethodType?: PaymentMethodType;
  deleteNewCardInformation: () => void;
  deleteNewPaypalInformation: () => void;
  getApplicableMobileDiscount: ({
    countryCode,
    remote_amount,
  }: GetApplicableMobileDiscountParams) => number;
  getIsFuturePromotionalApplicable: (countryCode: string | undefined) => boolean;
  internetItems: TopUpInternetItem[];
  isCartFull: boolean;
  itemsCount: number;
  mobileItems: TopUpMobileItem[];
  nautaItems: TopUpNautaItem[];
  newCardInformation: NewCardInformation | undefined;
  newPaypalInformation: NewPaypalInformation | undefined;
  removeInternetItem: (internetItemIndex: number) => void;
  removeMobileItem: (mobileItemIndex: number) => void;
  removeNautaItem: (nautaItemIndex: number) => void;
  setCurrentPaymentMethod: (
    currentPaymentMethodType?: PaymentMethodType,
    paymentMethod?: PaymentMethodCard
  ) => void;
  setTopUpInitialData: (data: MegaCarrierInitialData | undefined) => void;
  topUpInitialData: MegaCarrierInitialData | undefined;
  updateNewCardInformation: (newCardInformation?: NewCardInformation) => void;
  updateNewPaypalInformation: (newPaypalInformation?: NewPaypalInformation) => void;
};

const TopUpContext = createContext({} as TopUpContext);

export function useTopUp() {
  return useContext(TopUpContext);
}

type TopUpProviderProps = {
  children: ReactNode;
};

export function TopUpProvider({ children }: TopUpProviderProps) {
  const [mobileItems, setMobileItems] = useLocalStorage<TopUpMobileItem[]>(
    'topup-mobile-items',
    []
  );

  const [nautaItems, setNautaItems] = useLocalStorage<TopUpNautaItem[]>('topup-nauta-items', []);

  const [internetItems, setInternetItems] = useLocalStorage<TopUpInternetItem[]>(
    'topup-internet-items',
    []
  );

  const [topUpInitialData, setTopUpInitialData] = useState<MegaCarrierInitialData>();
  const [newCardInformation, setNewCardInformation] = useSessionStorage<
    NewCardInformation | undefined
  >('new-card-information', undefined);
  const [newPaypalInformation, setNewPaypalInformation] = useSessionStorage<
    NewPaypalInformation | undefined
  >('new-paypal-information', undefined);
  const [currentPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodCard | undefined>(
    undefined
  );
  const [currentPaymentMethodType, setCurrentPaymentMethodType] = useState<
    'new-card' | 'new-paypal' | 'card' | 'paypal' | undefined
  >(undefined);

  // Item management

  const addMobileItem = (mobileItem: TopUpMobileItem) => {
    if (_isPhoneAdded(mobileItem, mobileItems)) {
      throw 'El teléfono ya se encuentra en tu lista de recargas.';
    }  
    const phoneLength = mobileItem?.phoneNumber?.length ? mobileItem?.phoneNumber?.length : 0;
    if (phoneLength < 5) {
      throw 'Debe introducir un teléfono válido.';
    }  

    setMobileItems([...mobileItems, mobileItem]);
  };

  const removeMobileItem = (mobileItemIndex: number) => {
    setMobileItems(mobileItems.filter((_item, index) => index !== mobileItemIndex));
  };

  const addNautaItem = (nautaItem: TopUpNautaItem) => {
    if (nautaItem?.nauta == 'undefined@nauta.com.cu'){
      nautaItem.nauta = '';
    }
    if (_isNautaAdded(nautaItem, nautaItems)) {
      throw 'La cuenta de Nauta ya se encuentra en tu lista de recargas.';
    }   
    if (nautaItem?.type == 'other'){
      const emailLength = nautaItem?.nauta?.length ? nautaItem?.nauta?.length : 0;
      if (emailLength < 14) {
        throw 'Debe introducir una cuenta de Nauta válida.';
      }  
    }    
    setNautaItems([...nautaItems, nautaItem]);
  };

  const removeNautaItem = (nautaItemIndex: number) => {
    setNautaItems(nautaItems.filter((_item, index) => index !== nautaItemIndex));
  };

  const addInternetItem = (internetItem: TopUpInternetItem) => {
    if (_isPhoneAdded(internetItem, internetItems)) {
      throw 'El teléfono ya se encuentra en tu lista de recargas';
    }

    setInternetItems([...internetItems, internetItem]);
  };

  const removeInternetItem = (internetItemIndex: number) => {
    setInternetItems(internetItems.filter((_item, index) => index !== internetItemIndex));
  };

  const getIsFuturePromotionalApplicable = (countryCode: string | undefined) => {
    // return _isPromotionalApplicable(countryCode) && topUpInitialData!.is_future;
    return _isCountryPromotionApplicable(countryCode) && (topUpInitialData?.future==1);
  };

  const getApplicableMobileDiscount = ({
    countryCode,
    remote_amount,
  }: GetApplicableMobileDiscountParams) => {
    if (
      _isPromotionalApplicable(countryCode) &&
      remote_amount >= topUpInitialData!.promotion &&
      _anyMobileItemWithDiscount() === false
    ) {
      return topUpInitialData!.discount;
    }

    return 0;
  };

  const itemsCount = mobileItems.length + nautaItems.length + internetItems.length;

  const isCartFull = itemsCount >= 5;

  const clearCart = () => {
    setMobileItems([]);
    setNautaItems([]);
    setInternetItems([]);
  };

  const _isNautaAdded = (
    needle: TopUpNautaItem,
    haystack: TopUpNautaItem[]
  ) => {
    const email =
    needle.type === 'other'
      ? needle.nauta!
      : needle.contact?.email;

    return (
      haystack.find((item) => {
        const itemEmail =
          item.type === 'other'
            ? item.nauta!
            : item.contact?.email;

        return email === itemEmail;
      }) !== undefined
    );
  };

  const _isPhoneAdded = (
    needle: TopUpMobileItem | TopUpInternetItem,
    haystack: TopUpMobileItem[] | TopUpInternetItem[]
  ) => {
    const phone =
      needle.type === 'other'
        ? Formatter.onlyNumbers(needle.phoneNumber!)
        : needle.contact?.contact_number;

    return (
      haystack.find((item) => {
        const itemPhone =
          item.type === 'other'
            ? Formatter.onlyNumbers(item.phoneNumber!)
            : item.contact?.contact_number;

        return phone === itemPhone;
      }) !== undefined
    );
  };
  

  // Payment methods

  const updateNewCardInformation = (newCardInformation?: NewCardInformation) => {
    setNewCardInformation(newCardInformation);
  };

  const updateNewPaypalInformation = (newPaypalInformation?: NewPaypalInformation) => {
    setNewPaypalInformation(newPaypalInformation);
  };

  const deleteNewCardInformation = () => {
    setNewCardInformation(undefined);
    if (currentPaymentMethodType === 'new-card') {
      setCurrentPaymentMethodType(undefined);
    }
  };

  const deleteNewPaypalInformation = () => {
    setNewPaypalInformation(undefined);
    if (currentPaymentMethodType === 'new-paypal') {
      setCurrentPaymentMethodType(undefined);
    }
  };

  const setCurrentPaymentMethod = (
    currentPaymentMethodType?: PaymentMethodType,
    paymentMethod?: PaymentMethodCard
  ) => {
    setCurrentPaymentMethodType(currentPaymentMethodType);
    if (['new-card', 'new-paypal'].includes(currentPaymentMethodType ?? '')) {
      setSelectedPaymentMethod(undefined);
    } else {
      setSelectedPaymentMethod(paymentMethod);
    }
  };

  const clearCurrentPaymentMethod = () => {
    setCurrentPaymentMethodType(undefined);
    setSelectedPaymentMethod(undefined);
  };

  const clearAfterSuccessfulTopup = () => {
    clearCart();
    setNewCardInformation(undefined);
    setNewPaypalInformation(undefined);
    setSelectedPaymentMethod(undefined);
    setCurrentPaymentMethodType(undefined);
  };

  const _isPromotionalApplicable = (countryCode: string | undefined) => {
    return (
      topUpInitialData !== undefined &&
      topUpInitialData.offer &&
      _isCountryPromotionApplicable(countryCode)
    );
  };

  const _isCountryPromotionApplicable = (countryCode: string | undefined) => {
    return (countryCode || '').toLowerCase() === 'cu';
  };

  const _anyMobileItemWithDiscount = () => {
    return mobileItems.some((item) => item.discount > 0);
  };

  const _getCartTotal = () => {
    let cartTotal = 0;

    cartTotal += mobileItems.reduce(
      (acc, item) => acc + Number(item.carrierRate.real_amount) - (item.discount ?? 0),
      0
    );

    cartTotal += nautaItems.reduce((acc, item) => acc + Number(item.carrierRate.real_amount), 0);

    cartTotal += internetItems.reduce(
      (acc, item) => acc + Number(item.carrierRate.real_amount) - (item.discount ?? 0),
      0
    );

    return Number(cartTotal.toFixed(2));
  };

  const cartTotal = _getCartTotal();

  return (
    <TopUpContext.Provider
      value={{
        addInternetItem,
        addMobileItem,
        addNautaItem,
        cartTotal,
        clearAfterSuccessfulTopup,
        clearCurrentPaymentMethod,
        currentPaymentMethod,
        currentPaymentMethodType,
        deleteNewCardInformation,
        deleteNewPaypalInformation,
        getApplicableMobileDiscount,
        getIsFuturePromotionalApplicable,
        internetItems,
        isCartFull,
        itemsCount,
        mobileItems,
        nautaItems,
        newCardInformation,
        newPaypalInformation,
        removeInternetItem,
        removeMobileItem,
        removeNautaItem,
        setCurrentPaymentMethod,
        setTopUpInitialData,
        topUpInitialData,
        updateNewCardInformation,
        updateNewPaypalInformation,
      }}>
      {children}
    </TopUpContext.Provider>
  );
}
