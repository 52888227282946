import parsePhoneNumber, { CountryCode } from 'libphonenumber-js';
import { MegaContact } from '../../../types/contacts';

import { Button } from 'react-bootstrap';
import { ROUTES } from '../../../config/routes';
import { useNavigateSearch } from '../../../hooks/useNavigateSearch';
import { UtilService } from '../../../services';
import { Carnet, Correo, Direccion, EnviarSms, MarcadoDirecto1, TarjetaContacto } from '../../svg';

import styles from './contactInfo.module.scss';

export type ContactInfoProps = {
  contact: MegaContact;
  accountCountry: string;
  canBeDeleted: boolean;
  isDeleting: boolean;
  onDelete: (c: MegaContact) => void;
  onEdit: (c: MegaContact) => void;
};

export const ContactInfo = ({
  contact,
  accountCountry,
  canBeDeleted,
  isDeleting,
  onDelete,
  onEdit,
}: ContactInfoProps) => {
  const parsedPhone = parsePhoneNumber(contact.direct_dial, accountCountry as CountryCode);
  const parsedSmsPhone = parsePhoneNumber(contact.line_usa || '', accountCountry as CountryCode);

  const formattedContactNumber = UtilService.getFormattedPhoneNumber(
    contact.contact_number,
    contact.country_code
  );

  const navigateSearch = useNavigateSearch();

  const getCountryFrom = (): string => {
    let countryName = '';

    switch (accountCountry) {
      case 'US':
        countryName = 'U.S.A.';
        break;
      case 'CA':
        countryName = 'Canada';
        break;
      case 'ES':
        countryName = 'España';
        break;
      case 'CU':
        countryName = 'Cuba';
        break;
      default:
        countryName = 'U.S.A.';
    }

    return countryName;
  };

  const deleteContact = () => {
    onDelete(contact);
  };

  const onEditContact = (c: MegaContact) => {
    onEdit(c);
  };

  const dataInfo = [
    {
      title: 'Correo',
      icon: Correo,
      value: contact.email,
      showButton: !contact.email,
    },
    {
      title: 'Dirección',
      icon: Direccion,
      value: `${contact.address}, ${contact.town}, ${contact.municipality_name}, ${contact.province_name}`,
      showButton: !contact.address,
    },
    {
      title: 'Carnet',
      icon: Carnet,
      value: contact.carnet,
      showButton: !contact.carnet,
    },
    {
      title: 'MLC',
      icon: TarjetaContacto,
      value: contact.mlc_card ? UtilService.formatCardNumber(contact.mlc_card) : '',
      showButton: !contact.mlc_card,
    },
  ];

  return (
    <>
      <div className="w-100 d-flex flex-column flex-lg-row">
        <div className="d-flex flex-row flex-fill mb-5 mb-lg-0">
          <div className="d-inline">
            <span
              className={`fi fis fi-${(contact.country_code || '').toLowerCase()} ${
                styles.flagContact
              }`}></span>
          </div>
          <div className="flex-column ms-2">
            <h6 className="m-0 p-0">{contact.contact_name}</h6>
            <div>{formattedContactNumber || contact.contact_name}</div>
          </div>
        </div>

        <div className="d-flex align-items-start justify-content-center justify-content-lg-end flex-fill mt-5 mt-lg-0">
          <Button
            variant="outline-secondary"
            className={styles.buttonProfileEditar}
            onClick={() => onEditContact(contact)}>
            Editar
          </Button>
          <Button
            variant="mega_danger"
            className={styles.buttonProfileEliminar}
            onClick={() => deleteContact()}>
            Eliminar
          </Button>
        </div>
      </div>

      <div className="column-alert flex-column flex-lg-row mt-3">
        {dataInfo.map((info) => (
          <div
            key={info.title}
            className={`item-column centered w-100 w-lg-25 ${styles.columnWrapper}`}>
            <div
              className={`w-100 d-flex flex-row flex-lg-column align-items-start justify-content-start justify-content-lg-end`}>
              <info.icon width={20} className="me-1 mt-1 mt-lg-0" />
              <div className={`${styles.columnTitle} me-3 me-lg-0`}>{info.title}</div>

              {info.showButton && (
                <div className="flex-fill text-end">
                  <Button
                    variant="link"
                    className="p-0 text-secondary text-decoration-none"
                    onClick={() => onEditContact(contact)}>
                    Editar
                  </Button>
                </div>
              )}
              {!info.showButton && (
                <div
                  className={`${styles.columnValue} flex-fill text-end text-lg-start`}
                  title={info.value}>
                  {info.value}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>

      <div className={styles.contact_table}>
        <div className={`${styles.contact_table_row} ${styles.thr}`}>
          <div className={`${styles.contact_table_column} ${styles.th}`}>Nombre</div>
          <div className={`${styles.contact_table_column} ${styles.th}`}>Teléfono</div>
          <div className={`${styles.contact_table_column} ${styles.th} ${styles.tarifa}`}>
            Tarifa
          </div>
        </div>

        <div className={styles.contact_table_wrapper}>
          <div className={styles.contact_table_row}>
            <div className={styles.contact_table_column}>
              <MarcadoDirecto1 width={20} />{' '}
              <span className={styles.phone_link}>Marcado Directo</span>
            </div>
            <div className={styles.contact_table_column}>
              <a href={parsedPhone?.getURI()} className={styles.phone_link}>
                {parsedPhone?.formatInternational()}
              </a>
            </div>
            <div className={`${styles.contact_table_column} ${styles.price} ${styles.tarifa}`}>
              ${contact.call_rate}
            </div>
          </div>

          <div className={styles.contact_table_row}>
            <div className={styles.contact_table_column}>
              <EnviarSms width={20} />{' '}
              <span className={styles.phone_link}>Enviar SMS desde {getCountryFrom()}</span>
            </div>
            <div className={styles.contact_table_column}>
              <a href={parsedSmsPhone?.getURI()} className={styles.phone_link}>
                {parsedSmsPhone?.formatInternational()}
              </a>
            </div>
            <div className={`${styles.contact_table_column} ${styles.price} ${styles.tarifa}`}>
              ${contact.sms_rate}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.buttons_section}>
        <>
          <Button
            variant="mega_secondary_2"
            className="me-2 px-4"
            onClick={() => navigateSearch(ROUTES.cuenta.recargas, { contact: contact.id })}>
            Enviar Recarga
          </Button>

          <Button
            variant="mega_secondary_2"
            className="px-4"
            onClick={() => navigateSearch(ROUTES.cuenta.sms, { contact: contact.id })}>
            Enviar SMS
          </Button>
        </>
      </div>
    </>
  );
};
