import { useState, useEffect } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import { FechaInput } from '../../svg';

type ReportDateAndNumberSearchProps = {
  endDate: Date;
  isSearching: boolean;
  phoneNumber: string;
  startDate: Date;
  onSearch: (startDate: Date, endDate: Date, phone: string) => void;
};

export const ReportDateAndNumberSearch = ({
  endDate,
  isSearching,
  startDate,
  phoneNumber,
  onSearch,
}: ReportDateAndNumberSearchProps) => {
  const [startDateState, setStartDateState] = useState<Date>(startDate);
  const [endDateState, setEndDateState] = useState<Date>(endDate);
  const [phone, setPhone] = useState<string>('');

  useEffect(() => {
    if (moment(startDateState).isAfter(moment(endDateState))) {
      setEndDateState(moment(startDateState).add(1, 'day').toDate());
    }
  }, [startDateState]);

  useEffect(() => {
    if (moment(endDateState).isBefore(moment(startDateState))) {
      setEndDateState(moment(endDateState).add(-1, 'day').toDate());
    }
  }, [endDateState]);

  const onSearchSubmit = () => {
    onSearch(startDateState, endDateState, phone);
  };

  return (
    <Row>
      <Col xs={6} md={3}>
        <div className="megaInputWithIcon">
          <DatePicker
            selected={startDateState}
            onChange={(date) => !!date && setStartDateState(date)}
            className="form-control mega"
            selectsStart
            startDate={startDateState}
            endDate={endDateState}
          />
          <FechaInput width={20} className="icon" />
        </div>
      </Col>
      <Col xs={6} md={3}>
        <div className="megaInputWithIcon">
          <DatePicker
            selected={endDateState}
            onChange={(date) => !!date && setEndDateState(date)}
            selectsEnd
            className="form-control mega"
            startDate={startDateState}
            endDate={endDateState}
            minDate={startDateState}
          />
          <FechaInput width={20} className="icon" />
        </div>
      </Col>
      <Col md={4} className="my-4 my-md-0">
        <input
          type="text"
          placeholder="Teléfono"
          className={`form-control mega`}
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              onSearchSubmit();
            }
          }}
        />
      </Col>
      <Col md={2}>
        <Button
          className="w-100"
          disabled={isSearching}
          onClick={onSearchSubmit}
          variant="secondary">
          {isSearching ? 'Buscando...' : 'Buscar'}
        </Button>
      </Col>
    </Row>
  );
};
