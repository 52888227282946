export class Formatter {
  public static toCurrency(value: string | number | undefined): string {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
      //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
    });

    const formattable = typeof value === 'number' ? value : parseFloat(`${value ?? 0}`);

    return formatter.format(formattable);
  }

  public static cardTitle(value: string | undefined): string {
    if (value === undefined) {
      return '';
    }

    return `···· ···· ···· ${value.slice(-4)}`;
  }

  public static joinPurgeEmpty(elements: any[], separator: string = ', ') {
    return elements
      .filter((element) => element && element.toString().trim() !== '')
      .join(separator);
  }

  public static errorMessage(err: any): string | undefined {
    if (typeof err === 'object' && err !== null) {
      console.log(err['error']);
    }
    return '';
  }

  public static inputPhoneMask(countryPhoneMask: string) {
    const symbol = countryPhoneMask.startsWith('+') ? '' : '+';
    const prefix = countryPhoneMask.replaceAll('N', '').trim();
    const suffix = countryPhoneMask.replace(prefix, '').replaceAll('N', '0');

    return `${symbol}{${prefix.replaceAll('0', '\\0')}} ${suffix}`;
  }

  public static onlyNumbers(text: string) {
    return text.replace(/\D/g, '');
  }
}
