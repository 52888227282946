import * as React from 'react';
import { SVGProps } from 'react';
const SvgLocalidad = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Grupo 395" viewBox="0 0 23 23" {...props}>
    <defs>
      <clipPath id="Localidad_svg__a">
        <path data-name="Rect\xE1ngulo 339" fill="#fe4a81" d="M0 0h23v23H0z" />
      </clipPath>
      <clipPath id="Localidad_svg__b">
        <path
          data-name="Rect\xE1ngulo 337"
          transform="translate(2.883 4.13)"
          fill="#fe4a81"
          d="M0 0h20.002v12.942H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Grupo 394" clipPath="url(#Localidad_svg__a)">
      <g data-name="Grupo 393">
        <g data-name="Grupo 392" clipPath="url(#Localidad_svg__a)">
          <g data-name="Grupo 391" opacity={0.4}>
            <g data-name="Grupo 390">
              <g data-name="Grupo 389" clipPath="url(#Localidad_svg__b)">
                <path
                  data-name="Trazado 296"
                  d="M22.831 10.244a2.656 2.656 0 0 0-2.474-2.513c-.9-.063-1.856-.09-2.586-.064-.329.012-.65.022-.959.03a40.36 40.36 0 0 0-.1-1.659 1.9 1.9 0 0 0-1.752-1.752c-.941-.076-3.087-.156-5.216-.156s-4.013.08-4.954.156a1.9 1.9 0 0 0-1.751 1.752c-.077.941-.157 2.434-.157 4.563s.08 3.622.157 4.562a1.9 1.9 0 0 0 1.752 1.752c.941.077 2.826.157 4.954.157s4.275-.08 5.216-.157c.052 0 .1-.011.155-.019.712.033 1.645.058 2.655.058 1.528 0 3.068-.057 3.743-.112a1.361 1.361 0 0 0 1.257-1.257c.055-.676.092-1.747.112-3.274a35.091 35.091 0 0 0-.052-2.067"
                  fill="#fe4a81"
                />
              </g>
            </g>
          </g>
          <path
            data-name="Trazado 297"
            d="M15.219 16.57a2.3 2.3 0 1 1 2.3 2.3 2.3 2.3 0 0 1-2.3-2.3"
            fill="#fe4a81"
          />
          <path
            data-name="Trazado 298"
            d="M5.549 16.57a2.3 2.3 0 1 1 2.3 2.3 2.3 2.3 0 0 1-2.3-2.3"
            fill="#fe4a81"
          />
          <path
            data-name="Trazado 299"
            d="M.115 9.9c0-.553 1.176-.721 5.054-.721s5.055.168 5.055.721-1.177.721-5.055.721S.115 10.45.115 9.9"
            fill="#fe4a81"
          />
          <path
            data-name="Trazado 300"
            d="M1.731 12.726c0-.553.776-.721 3.334-.721s3.333.168 3.333.721-.776.721-3.333.721-3.334-.168-3.334-.721"
            fill="#fe4a81"
          />
          <path
            data-name="Trazado 301"
            d="M18.312 8.281c0-.222 0-.426-.005-.623-.188 0-.368 0-.536.01-.307.011-.607.02-.9.028v.585c0 2.558.168 3.334.72 3.334s.721-.776.721-3.334"
            fill="#fe4a81"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgLocalidad;
