import { useEffect, useState } from 'react';
import { Alert, Button, Col, Row } from 'react-bootstrap';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { ProductImageSlider } from '../../../components/account/productImageSlider';
import { MegaLoading } from '../../../components/external/megaLoading';
import { ROUTES } from '../../../config/routes';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import { StoreService } from '../../../services/store';
import { MegaStoreProduct } from '../../../types/reports/storeProduct';
import { Formatter } from '../../../utilities/Formatter';

import { AddToCart } from '../../../components/account/addToCart';
import { Allbreadcrumb, Localidad, Pago100Seguro } from '../../../components/svg';
import styles from './store-product-screen.module.scss';

export const StoreProductScreen = () => {
  const [showAddToCart, setShowAddToCart] = useState<boolean>(false);
  const { id } = useParams();
  const [quantity, setQuantity] = useState<number>(1);
  const [product, setProduct] = useState<MegaStoreProduct>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { addProduct, getProductCount } = useShoppingCart();
  const navigate = useNavigate();

  useEffect(() => {
    const loadProduct = async () => {
      const product = await StoreService.getProductDetails(id);
      setProduct(product);
    };

    loadProduct();
  }, []);

  const productStock = product ? product.in_stock - getProductCount(product.id) : 0;

  const addToCart = () => {
    try {
      setErrorMessage(undefined);
      addProduct(product as MegaStoreProduct, quantity);
      setShowAddToCart(true);
    } catch (error: any) {
      setErrorMessage(error);
    }
  };

  const onAddToCartClose = () => {};

  const addToCartAndBuy = () => {
    try {
      setErrorMessage(undefined);
      addProduct(product as MegaStoreProduct, quantity);
      navigate(ROUTES.cuenta.shoppingCart);
    } catch (error: any) {
      setErrorMessage(error);
    }
  };

  if (product === undefined) {
    return <MegaLoading isLoading={true} />;
  }

  return (
    <>
      <div className="container g-0 g-md-4 pb-5 pt-0 pt-md-5">
        <Row className="g-0">
          <Col xs={12}>
            <div className={styles.breadcrumb_border}>
              <NavLink to={ROUTES.cuenta.store}>
                <Allbreadcrumb width={20} />
                <span className={`${styles.breadcrumb_link}`}>Todas</span>
              </NavLink>
              <span className={styles.breadcrumb_text}>|</span>
              <span className={styles.breadcrumb_text}>{product.category.name}</span>
              <span className={styles.breadcrumb_text}>|</span>
              <span className={`${styles.breadcrumb_text} ${styles.active}`}>
                Detalle del producto
              </span>
            </div>
          </Col>
        </Row>
      </div>

      <div className="container pb-5 pt-0 pt-md-5">
        <Row className="">
          <Col md={6}>
            <ProductImageSlider product={product} />

            <div className="mega-alert mt-3 w-lg-75 m-auto d-none d-md-block">
              <ul>
                <li>
                  <p>
                    Las imágenes sólo tienen propósito ilustrativo y no representan exactamente el
                    producto que reciben los clientes.
                  </p>
                </li>
              </ul>
            </div>
          </Col>
          <Col md={6} className={`mt-5 mt-md-0 ${styles.product_info}`}>
            <h5>{product.name}</h5>
            <p>{product.description}</p>

            <h5>{Formatter.toCurrency(product.price)}</h5>

            <div className="d-flex flex-row">
              <div>
                <Localidad width={23} />
              </div>
              <div>
                {product.details.map((detail, index) => (
                  <div
                    className={styles.product_info_detail}
                    key={`product-card--detail-${product.id}-${index}`}>
                    <span>
                      {detail.title}: {detail.description}
                    </span>
                  </div>
                ))}
                {product.deliveries.map((delivery, index) => (
                  <div
                    className={styles.product_info_detail}
                    key={`product-card--detail-${product.id}-${index}`}>
                    <span className={styles.product_info_detail_title}>{delivery.name}: </span>
                    {delivery.duration}
                  </div>
                ))}
              </div>
            </div>

            {errorMessage && <Alert variant="danger">{errorMessage}</Alert>}

            <Row className="mt-3 mb-3">
              <Col>
                <div className={`mb-3 ${styles.title_quantity}`}>Cantidad:</div>
                <div className="d-flex d-grid gap-3 mb-3">
                  <div className={`${styles.pink_square}`}>
                    <div
                      className={`${styles.square_text}`}
                      onClick={() => {
                        quantity > 1 ? setQuantity(quantity - 1) : setQuantity(1);
                      }}>
                      -
                    </div>
                  </div>
                  <div className={`${styles.quantity}`}>{quantity}</div>
                  <div className={`${styles.pink_square}`}>
                    <div
                      className={`${styles.square_text}`}
                      onClick={() => {
                        quantity < productStock ? setQuantity(quantity + 1) : setQuantity(quantity);
                      }}>
                      +
                    </div>
                  </div>
                </div>

                <Button
                  variant="mega_danger_2"
                  disabled={isNaN(quantity) || productStock < quantity}
                  onClick={addToCart}
                  className="w-100 w-md-50">
                  Añadir al carrito
                </Button>
              </Col>
            </Row>

            <Row>
              <Col>
                <Button variant="mega_danger_3" onClick={addToCartAndBuy} className="w-100 w-md-50">
                  Comprar ahora
                </Button>
              </Col>
            </Row>

            <div className="d-flex mt-3">
              <div>
                <Pago100Seguro width={39} className={`me-2`} />
              </div>
              <div className={`flex-column ${styles.textWrapper}`}>
                <div className="fw-bold">Pago 100% seguro</div>
                <div>Su información de pago está segura con nosotros</div>
              </div>
            </div>
          </Col>
        </Row>

        <AddToCart
          product={product}
          display={showAddToCart}
          onCancel={() => setShowAddToCart(false)}
          onSave={onAddToCartClose}
        />
      </div>
    </>
  );
};
