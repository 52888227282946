import { useContext, useEffect, useState } from 'react';
import { Button, FormCheck } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { PageTitle, PaymentLine, PaymentWizard } from '../../../components/account';
import { AutoFunds } from '../../../components/account/autoFunds';
import { MegaError } from '../../../components/account/megaError';
import { PriceContainer } from '../../../components/account/priceContainer';
import { AgregarSaldo, AutoRecarga, FlechaDer } from '../../../components/svg';
import { SessionContext } from '../../../providers/session.provider';
import { AccountServices, FundServices, UtilService } from '../../../services';
import { MegaFund, MegaFundSelector } from '../../../types/funds';
import { QueryTypes } from '../../../types/queryTypes';
import { AutoRechargeFund } from '../../../types/user';

import { ROUTES } from '../../../config/routes';
import { MODAL_TYPES, useGlobalModalContext } from '../../../providers/globalModal.provider';
import styles from './add-funds-screen.module.scss';

export const AddFundsScreen = () => {
  const serviceFee = 0.35;
  const navigate = useNavigate();
  const { showModal, hideModal } = useGlobalModalContext();

  const [paymentScreenVisible, setPaymentScreenVisible] = useState<boolean>(false);
  const [selectedFund, setSelectedFund] = useState<MegaFundSelector | null>(null);
  const [showReloadModal, setShowReloadModal] = useState(false);
  const [productList, settProductList] = useState<MegaFundSelector[]>([]);

  const [isReferralBalanceApplicable, setIsReferralBalanceApplicable] = useState<boolean>(false);
  const [autoRechargeInfo, setAutoRechargeInfo] = useState<AutoRechargeFund | null>(null);

  const { referralBalance, reloadUserBalance, reloadReferralBalance } = useContext(SessionContext);

  const fundsQuery = useQuery(QueryTypes.GetFundProducts, FundServices.getFundProducts);
  const profileQuery = useQuery(QueryTypes.GetProfile, AccountServices.getProfileData);
  const autoRechargeQuery = useQuery(
    QueryTypes.GetAutoRechargeData,
    AccountServices.getAutoRechargeData
  );

  const screenQueries = [fundsQuery, profileQuery, autoRechargeQuery];

  useEffect(() => {
    if (fundsQuery.status === 'success' && profileQuery.status === 'success') {
      let newProductList: MegaFund[] = [];

      if (profileQuery.data.user.new_account_promo.is_active) {
        newProductList.push({
          amount: profileQuery.data.user.new_account_promo.amount.toString(),
          bonus: '',
          label: `${profileQuery.data.user.new_account_promo.amount.toFixed(2)}`,
        });
      }

      newProductList = [...newProductList, ...fundsQuery.data];

      const productSelectors: MegaFundSelector[] = newProductList.map((item, index) => {
        return {
          id: `mega-price-${index}`,
          priceTitle: `$${item.amount}`,
          amount: item.amount,
          priceSubtitle: `$${Number(item.amount) + serviceFee}`,
          description: `Recibes $${item.amount} de saldo`,
        };
      });

      settProductList(productSelectors);
      setSelectedFund(productSelectors[0]);
    }
  }, [fundsQuery.status, profileQuery.data]);

  useEffect(() => {
    if (autoRechargeQuery.status === 'success') {
      setAutoRechargeInfo(autoRechargeQuery.data);
    }
  }, [autoRechargeQuery.data]);

  const openReloadModal = () => {
    setShowReloadModal(true);
  };

  const closeReloadModal = () => {
    autoRechargeQuery.refetch();
    setShowReloadModal(false);
  };

  const onSaveReloadModal = () => {
    autoRechargeQuery.refetch();
    closeReloadModal();
  };

  const onSelectedPriceChange = (id: string | number) => {
    const product = productList.find((item) => item.id === id);
    setSelectedFund(product || productList[0]);
  };

  // if (UtilService.isLoadingQueries(screenQueries)) {
  //   return <MegaLoading isLoading={true} />;
  // }

  if (UtilService.isErrorQueries(screenQueries)) {
    return <MegaError displayRetry retry={() => UtilService.refetchQueries(screenQueries)} />;
  }

  const onCancelPaymentScreen = () => {
    setPaymentScreenVisible(false);
  };

  const openPaymentScreen = () => {
    setPaymentScreenVisible(true);
  };

  const onSuccessPayment = (goToReport?: boolean) => {
    reloadReferralBalance();
    reloadUserBalance();

    profileQuery.refetch();
    fundsQuery.refetch();
    autoRechargeQuery.refetch();

    setPaymentScreenVisible(false);
    if (goToReport) {
      navigate(ROUTES.cuenta.reportes);
    }
  };

  const deleteAutoRecharge = async () => {
    showModal(MODAL_TYPES.LOADING, {
      title: 'Eliminando su auto-recarga',
    });

    const isSuccess = await AccountServices.updateAutoRecharge(0, 0, 0);

    hideModal();

    if (isSuccess) {
      // queryCache.invalidateQueries(QueryTypes.GetAutoRechargeData);
      autoRechargeQuery.refetch();
      return;
    }

    showModal(MODAL_TYPES.ERROR, {
      title: '¡Error de auto-recarga!',
      description: 'Hubo un error eliminando su información. Por favor, intente de nuevo.',
      state: {
        buttonTitle: 'Reintentar',
      },
      onClose: (state) => {
        if (state === 'primary') {
          deleteAutoRecharge();
        }
      },
    });
  };

  const onChangeAutoRecargaSwitch = async (checked: boolean) => {
    if (!checked) {
      await deleteAutoRecharge();
    } else {
      openReloadModal();
    }
  };

  const hasAutoRechargeInfo = () => {
    if (autoRechargeInfo && autoRechargeInfo.amount > 0 && autoRechargeInfo.trigger) {
      return true;
    }

    return false;
  };

  return (
    <div className="bg-mega_light_gray">
      <div className="container py-5">
        <PageTitle
          title="Agregar Saldo"
          description="Usted puede usar su saldo para realizar llamadas o enviar SMS."
          icon={AgregarSaldo}
        />

        <div className="row mt-5">
          <div className="col-12 col-lg-6">
            <div className={styles.priceWrapper}>
              <h6>Elegir Saldo</h6>
              <div className={styles.messageAlert}>
                <ul className="m-0 ps-3">
                  <li className="m-0 p-0">
                    Este servicio tiene un costo adicional de $0.35 sin importar la cantidad que
                    elija.
                  </li>
                </ul>
              </div>
              <div className="mt-3">
                <PriceContainer
                  prices={productList}
                  selectedId={selectedFund?.id}
                  onSelectedChange={onSelectedPriceChange}
                />
              </div>

              <div className="d-block d-lg-none mt-4">
                <hr />
              </div>

              <div className="mt-3 mt-lg-5 d-flex w-100 flex-column flex-lg-row">
                <div className="flex-fill d-flex align-items-center">
                  <div className="me-2 flex-fill flex-lg-grow-0">
                    <b>Total a Pagar: </b>
                  </div>
                  <div className="flex-fill flex-lg-grow-0 text-end text-lg-start">
                    <b>
                      <span className="text-secondary">{selectedFund?.priceTitle}</span>
                    </b>
                  </div>
                </div>
                <div className="flex-fill mt-3 mt-lg-0">
                  <Button variant="secondary" className="w-100" onClick={openPaymentScreen}>
                    Comprar ahora
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className={styles.autoRechargeWrapper}>
              <div className="d-flex flex-row">
                <h6 className="flex-fill">
                  <AutoRecarga width={20} className="me-1" /> Auto-recarga de saldo
                </h6>
                <div>
                  <FormCheck
                    type="switch"
                    className="mega-check"
                    checked={hasAutoRechargeInfo()}
                    onChange={(e) => {
                      onChangeAutoRecargaSwitch(e.target.checked);
                    }}
                  />
                </div>
              </div>
              <div>
                {hasAutoRechargeInfo() && (
                  <p className="m-0">
                    Tu saldo se auto-recargará al llegar a <b>${autoRechargeInfo?.trigger}</b> con
                    una cantidad de <b>${autoRechargeInfo?.amount}</b>
                  </p>
                )}

                {!hasAutoRechargeInfo() && (
                  <p className="m-0">
                    Activa auto-recarga para agregar a su cuenta automáticamente
                  </p>
                )}
              </div>

              <div className="mt-3" onClick={openReloadModal} role="button">
                <span className="text-secondary">
                  Modificar <FlechaDer width={20} fill={'#8AB934'} className="ms-2" />
                </span>
              </div>
            </div>
          </div>
        </div>

        <AutoFunds
          display={showReloadModal}
          onClose={closeReloadModal}
          onSave={onSaveReloadModal}
        />

        {selectedFund && paymentScreenVisible && (
          <PaymentWizard
            show={paymentScreenVisible}
            paymentType={PaymentLine.funds}
            totalToPay={Number(selectedFund.amount)}
            serviceFee={serviceFee}
            lines={[
              {
                productCost: 0,
                deliveryCost: 0,
                amountToReceive: '',
                price: Number(selectedFund.amount),
                title: `Saldo ${selectedFund.priceTitle}`,
                subtitle: selectedFund.priceTitle,
                recibenDescription: `Recibes ${selectedFund.priceTitle} de saldo`,
              },
            ]}
            onCancel={onCancelPaymentScreen}
            onSuccess={onSuccessPayment}
          />
        )}
      </div>
    </div>
  );
};
