import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import styles from './otpTimer.module.scss';

type OtpTimerProps = {
  seconds: number;
  disabledBtn: boolean;
  onComplete?: () => void;
  onActionClick: () => void;
  title?: string;
};

export const OtpTimer = (props: OtpTimerProps) => {
  const [countDown, setCountDown] = useState(props.seconds);
  const [timer, setTimer] = useState<null | any>(null);

  const startCountDown = () => {
    const tmr = setInterval(() => {
      if (countDown === 0) {
        clearInterval(tmr);
        setTimer(null);
      } else {
        setCountDown(countDown - 1);
      }
    }, 1000);

    setTimer(tmr);
  };

  useEffect(() => {
    const timer1 = countDown > 0 && setInterval(() => setCountDown(countDown - 1), 1000);
    return () => {
      !!timer1 && clearInterval(timer1);
    };
  }, [countDown]);

  return (
    <div className={styles.wrapper}>
      <p className="m-0 p-0">{props.title || '¿No has recibido tu código SMS aún?'}</p>
      {countDown > 0 && <span>Espera {countDown} segundos.</span>}
      {countDown === 0 && !timer && (
        <Button
          variant="link"
          className="w-100 mt-2 text-secondary text-decoration-none fw-bold"
          disabled={props.disabledBtn}
          onClick={() => {
            setCountDown(props.seconds);
            props.onActionClick();
          }}>
          Reenviar código nuevo
        </Button>
      )}
    </div>
  );
};
