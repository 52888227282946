import { useSearchParams } from 'react-router-dom';
import { MegaContact } from '../types/contacts';

export const useFindPreSelectedContact = () => {
  const [searchParams] = useSearchParams();

  return ({
    contacts,
    onPreSelectedContactFound,
    onPreSelectedContactNotFound,
  }: {
    contacts: MegaContact[];
    onPreSelectedContactFound: (contact: MegaContact) => void;
    onPreSelectedContactNotFound?: () => void;
  }) => {
    const preSelectedContactId = searchParams.get('contact');
    const preSelectedContact = contacts.find((item) => `${item.id}` === preSelectedContactId);

    if (preSelectedContact) {
      onPreSelectedContactFound(preSelectedContact);
    } else if (onPreSelectedContactNotFound !== undefined) {
      onPreSelectedContactNotFound();
    }
  };
};
