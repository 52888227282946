import { useContext, useEffect, useState } from 'react';
import { Alert, Col, FormCheck, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Steps } from '../../../components/account';
import { CartTotal } from '../../../components/account/cartTotal';
import { CheckoutActions } from '../../../components/account/checkoutActions';
import { PaymentMethodSummaryCard } from '../../../components/account/summaryCard';
import { TopUpCartItems } from '../../../components/account/topUpCartItems';
import { ROUTES } from '../../../config/routes';
import { useTopUp } from '../../../context/TopupContext';
import { SessionContext } from '../../../providers/session.provider';
import { TopUpServices } from '../../../services/top_up';
import { Formatter } from '../../../utilities';
import { AlertHelper } from '../../../utilities/AlertHelper';

export const TopUpSummaryScreen = () => {
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);
  const [isReferralBalanceApplicable, setIsReferralBalanceApplicable] = useState<boolean>(false);

  const { referralBalance, reloadReferralBalance } = useContext(SessionContext);

  const {
    clearAfterSuccessfulTopup,
    currentPaymentMethodType,
    currentPaymentMethod,
    newCardInformation,
    newPaypalInformation,
    mobileItems,
    nautaItems,
    internetItems,
    cartTotal,
  } = useTopUp();

  const navigate = useNavigate();

  const completePurchase = () => {
    switch (currentPaymentMethodType) {
      case 'new-card':
        return TopUpServices.purchaseWithNewCreditCard({
          newCardInformation: newCardInformation!,
          mobileItems,
          nautaItems,
          internetItems,
          total: cartTotal,
          acceptReferral: isReferralBalanceApplicable,
        });

      case 'new-paypal':
        return TopUpServices.purchaseWithNewPaypal({
          newPayPalInformation: newPaypalInformation!,
          mobileItems,
          nautaItems,
          internetItems,
          total: cartTotal,
          acceptReferral: isReferralBalanceApplicable,
        });

      case 'paypal':
        return TopUpServices.purchaseWithPaypal({
          paymentMethod: currentPaymentMethod!,
          mobileItems,
          nautaItems,
          internetItems,
          total: cartTotal,
          acceptReferral: isReferralBalanceApplicable,
        });

      case 'card':
        return TopUpServices.purchaseWithCreditCard({
          paymentMethod: currentPaymentMethod!,
          mobileItems,
          nautaItems,
          internetItems,
          total: cartTotal,
          acceptReferral: isReferralBalanceApplicable,
        });
    }
  };

  useEffect(() => {
    if (currentPaymentMethodType === undefined) {
      navigate(ROUTES.cuenta.topUpPaymentMethods);
    } else {
      reloadReferralBalance();
      setIsLoading(true);
    }

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [referralBalance]);

  const processPurchase = async () => {
    try {
      setIsProcessing(true);
      if (await completePurchase()) {
        clearAfterSuccessfulTopup();
        AlertHelper.show({ title: 'Recarga exitosa', icon: 'success' });
        navigate(ROUTES.cuenta.recargas);
      }
    } catch (e: any) {
      setErrorMessage('La recarga no pudo ser completada, por favor verifique su método de pago');
    } finally {
      setIsProcessing(false);
    }
  };

  // if (isLoading) {
  //   return <MegaLoading isLoading={true} />;
  // }

  return (
    <div className="container">
      <div className="mt-6">
        <Steps
          steps={[
            { title: 'Detalles de envío' },
            { title: 'Método de Pago' },
            { title: 'Resumen de compra' },
          ]}
          currentStep={3}
        />
        <h2 className="mt-6 mg-page-title mb-4">RESUMEN DE COMPRA</h2>
      </div>

      <Row>
        <Col lg={6}>
          <PaymentMethodSummaryCard
            currentPaymentMethod={currentPaymentMethod}
            currentPaymentMethodType={currentPaymentMethodType}
            newCardInformation={newCardInformation}
            newPaypalInformation={newPaypalInformation}
          />
        </Col>
      </Row>

      <div className="mt-5">
        <TopUpCartItems allowToDelete={false} />
        <hr />
        <Row>
          <Col lg={6} className="mt-4">
            <div className="d-flex align-items-center">
              <div className="me-4">
                Acepto los términos y condiciones, he revisado cada número a recargar y confirmo que
                este servicio no admite rembolso una vez completado el pago.
              </div>
              <FormCheck
                type="switch"
                checked={isTermsAccepted}
                onChange={(e) => {
                  e.stopPropagation();
                  setIsTermsAccepted(!isTermsAccepted);
                }}
              />
            </div>

            {referralBalance > 0 && (
              <div className="mt-4 d-flex align-items-center">
                <div className="me-4">
                  Use su Balance de Referidos. Tiene {Formatter.toCurrency(referralBalance)}{' '}
                  disponibles.
                </div>
                <FormCheck
                  type="switch"
                  checked={isReferralBalanceApplicable}
                  onChange={(e) => {
                    e.stopPropagation();
                    setIsReferralBalanceApplicable(!isReferralBalanceApplicable);
                  }}
                />
              </div>
            )}
          </Col>
          <Col lg={6} className="mt-4">
            <CartTotal
              subtotal={cartTotal}
              referralBalance={isReferralBalanceApplicable ? referralBalance : 0}
            />
          </Col>
        </Row>
      </div>

      {errorMessage && <Alert variant={'danger'}>{errorMessage}</Alert>}

      <CheckoutActions
        onBackClick={() => navigate(ROUTES.cuenta.topUpPaymentMethods)}
        className="my-5"
        disabled={isProcessing}
        label="Pagar"
        processing={isProcessing}
        onClick={async () => {
          if (isTermsAccepted) {
            processPurchase();
          } else {
            AlertHelper.show({
              icon: 'error',
              text: 'Para completar la compra es necesario aceptar los términos y condiciones.',
              title: 'Error',
            });
          }
        }}
      />
    </div>
  );
};
