import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { PageTitle, TopupMobile, TopupNauta } from '../../../components/account';
import { MegaError } from '../../../components/account/megaError';
import { TopUpCartItems } from '../../../components/account/topUpCartItems';
import {
  EnviarRecargas,
  RecargarCelularInactive,
  RecargarDatosInactive,
  RecargarNautaInactive,
} from '../../../components/svg';
import { useTopUp } from '../../../context/TopupContext';
import { ContactService, UtilService } from '../../../services';
import { TopUpServices } from '../../../services/top_up';
import { MegaContact } from '../../../types/contacts';
import { QueryTypes } from '../../../types/queryTypes';
import { useFindPreSelectedContact } from '../../../hooks/useFindPreSelectedContact';

import styles from './topup-screen.module.scss';

export const TopupScreen = () => {
  const findPreSelectedContact = useFindPreSelectedContact();
  const [activeTopup, setActiveTopup] = useState<'internet' | 'nauta' | 'mobile'>('mobile');
  const [selectedContact, setSelectedContact] = useState<MegaContact | null>(null);

  const [activeTabTopup, setActiveTabTopup] = useState<'otro' | 'contact'>('otro');

  const [contacts, setContacts] = useState<MegaContact[]>([]);
  const { cartTotal, setTopUpInitialData, itemsCount } = useTopUp();

  const contactsQuery = useQuery(QueryTypes.GetContacts, ContactService.getContacts);
//  const initialDataQuery = useQuery(QueryTypes.GetTopUpInitialData, TopUpServices.getInitialData);

  const allQueries = [contactsQuery];
  const navigate = useNavigate();

  useEffect(() => {
    if (contactsQuery.status === 'success') {
      setContacts(contactsQuery.data);
    }
  }, [contactsQuery.data]);

  useEffect(() => {
    if (contacts && contacts.length) {
      findPreSelectedContact({
        contacts: contacts,
        onPreSelectedContactFound: (c) => {
          setSelectedContact(c)
        },
      });
    }
  }, [contacts]);

/*  useEffect(() => {
    if (initialDataQuery.status === 'success') {
      setTopUpInitialData(initialDataQuery.data);
    }
  }, [initialDataQuery.data]);*/

  if (UtilService.isErrorQueries(allQueries)) {
    return (
      <MegaError
        displayRetry
        retry={() => {
          UtilService.refetchQueries(allQueries);
        }}
      />
    );
  }

  return (
    <div className="bg-mega_light_gray">
      <div className="container py-5">
        <PageTitle
          title="Enviar Recargas"
          description="Envía recargas, nauta y datos a familiares y amigos en Cuba y el mundo."
          icon={EnviarRecargas}
        />

        <div className="row mt-4">
          <div className="col-12 col-lg-6">
            <div className={styles.wrapperInput}>
              <div className="d-flex flex-row">
                <div
                  role="button"
                  onClick={() => setActiveTopup('mobile')}
                  className={`${styles.tabRecarga} ${
                    activeTopup === 'mobile' ? styles.tabRecargaActive : ''
                  }`}>
                  <span>
                    {activeTopup === 'mobile' ? (
                      <RecargarCelularInactive
                        fill={`#8ab934`}
                        width={20}
                        className="me-0 me-lg-2"
                      />
                    ) : (
                      <RecargarCelularInactive
                        fill={`#abadc4`}
                        width={20}
                        className="me-0 me-lg-2"
                      />
                    )}
                    <br className="d-block d-xxl-none" /> Recargar
                    <br className="d-block d-xxl-none" /> Celulares
                  </span>
                </div>
                <div
                  role="button"
                  onClick={() => setActiveTopup('nauta')}
                  className={`${styles.tabRecarga} ${
                    activeTopup === 'nauta' ? styles.tabRecargaActive : ''
                  }`}>
                  <span>
                    {activeTopup === 'nauta' ? (
                      <RecargarNautaInactive fill={`#8ab934`} width={20} className="me-0 me-lg-2" />
                    ) : (
                      <RecargarNautaInactive fill={`#abadc4`} width={20} className="me-0 me-lg-2" />
                    )}
                    <br className="d-block d-xxl-none" /> Recargar
                    <br className="d-block d-xxl-none" /> Nauta
                  </span>
                </div>
                
              </div>

              {activeTopup === 'mobile' && <TopupMobile contacts={contacts} selectedContact={selectedContact} />}
              {activeTopup === 'nauta' && <TopupNauta contacts={contacts} />}              
            </div>
          </div>
          <div className="col-12 col-lg-6 mt-5 mt-lg-0">
            <TopUpCartItems />
          </div>
        </div>
      </div>
    </div>
  );
};
