import * as React from 'react';
import { SVGProps } from 'react';
const SvgReporteMisCompras = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="reporte_mis_compras_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 26"
    {...props}>
    <defs>
      <style>{'.reporte_mis_compras_svg__cls-4{fill-rule:evenodd}'}</style>
    </defs>
    <g id="reporte_mis_compras_svg__Capa_1-2" data-name="Capa 1">
      <path
        d="M8.49 1.16c-1.08 0-2.11.55-2.66 1.51-.68 1.17-1.76 3.18-2.68 5.47.51 0 1.13-.01 1.88-.02.81-1.91 1.71-3.57 2.3-4.59.23-.4.67-.65 1.16-.65h1.63a.87.87 0 0 0 0-1.74H8.49Zm11.69 1.51c.68 1.17 1.76 3.18 2.68 5.47-.51 0-1.13-.01-1.88-.02-.81-1.91-1.71-3.57-2.3-4.59-.23-.4-.67-.65-1.16-.65h-1.63a.87.87 0 0 1 0-1.74h1.63c1.08 0 2.11.55 2.66 1.51ZM13 13.29c-5.59 0-8.98-.04-10.54-.06-.16 0-.35-.02-.54-.05.06.5.13 1.08.21 1.73.28 2.12.72 4.94 1.39 7.73a3.972 3.972 0 0 0 3.45 3.04 57.097 57.097 0 0 0 12.06 0c1.69-.18 3.06-1.4 3.45-3.04.66-2.78 1.11-5.61 1.39-7.73.09-.65.16-1.23.21-1.73-.19.04-.38.05-.54.05-1.56.02-4.95.06-10.54.06Z"
        style={{
          fillRule: 'evenodd',
          opacity: 0.4,
        }}
      />
      <path
        className="reporte_mis_compras_svg__cls-2"
        d="M13 13.29c-5.59 0-8.98-.04-10.54-.06-.78-.01-2.01-.33-2.34-1.56-.07-.29-.12-.61-.12-.98s.05-.69.12-.98c.33-1.23 1.56-1.55 2.34-1.56 1.56-.02 4.95-.06 10.54-.06s8.98.04 10.54.06c.78.01 2.01.33 2.34 1.56a4.04 4.04 0 0 1 0 1.96c-.33 1.23-1.56 1.55-2.34 1.56-1.56.02-4.95.06-10.54.06ZM13 4.04c.93 0 1.61-.01 2.06-.03.78-.02 1.61-.56 1.68-1.54 0-.14.02-.29.02-.46s0-.32-.02-.46c-.07-.98-.9-1.51-1.68-1.54C14.6 0 13.93-.02 13-.02s-1.61.01-2.06.03c-.78.02-1.61.56-1.68 1.54 0 .14-.02.29-.02.46s0 .32.02.46c.07.98.9 1.51 1.68 1.54.46.01 1.13.03 2.06.03Z"
      />
      <path
        className="reporte_mis_compras_svg__cls-4"
        d="M8.27 16.18c.47-.06.91.28.97.75l.58 4.62c.06.47-.28.91-.75.97a.874.874 0 0 1-.97-.75l-.58-4.62c-.06-.47.28-.91.75-.97ZM17.73 16.18a.874.874 0 0 0-.97.75l-.58 4.62c-.06.47.28.91.75.97.47.06.91-.28.97-.75l.58-4.62a.874.874 0 0 0-.75-.97ZM13 16.18c.48 0 .87.39.87.87v4.62a.87.87 0 0 1-1.74 0v-4.62c0-.48.39-.87.87-.87Z"
      />
    </g>
  </svg>
);
export default SvgReporteMisCompras;
