import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Alert, Button, Col, Modal, Row } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { BiError } from 'react-icons/bi';
import NumberFormat from 'react-number-format';
import { useQuery } from 'react-query';
import Select from 'react-select';
import {
  IconOptionAuthorizedCountry,
  IconSingleValueAuthorizedCountry,
  reactSelectStyles,
} from '../../../config/reactSelect';
import { AccountServices, CountryServices, UtilService } from '../../../services';
import { NewCardInformation } from '../../../types/newCardInformation';
import { PaymentMethodCard } from '../../../types/payment';
import { QueryTypes } from '../../../types/queryTypes';
import { Formatter } from '../../../utilities';
import { SmallSpinner } from '../../external';
import { existingCardSchema, newCardSchema } from './schemas';

type CreditCardFormProps = {
  display: boolean;
  onCancel: VoidFunction;
  onSave?: (data: any) => Promise<void>;
  editablePaymentMethod?: PaymentMethodCard | NewCardInformation;
  editablePaymentMethodType?: 'new-card' | 'card';
  modalTitle: string;
  submitButtonText: string;
};

export const CreditCardForm: React.FC<CreditCardFormProps> = ({
  display,
  onSave,
  onCancel,
  editablePaymentMethod,
  editablePaymentMethodType,
  children,
  modalTitle,
  submitButtonText,
}) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const {
    control,
    formState: { errors },
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    watch,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(editablePaymentMethod === undefined ? newCardSchema : existingCardSchema),
  });

  const countryQuery = useQuery(
    QueryTypes.GetAuthCountries,
    CountryServices.getAllAuthorizedCountries
  );

  useEffect(() => {
    setErrorMessage(undefined);
    reset({
      cardNumber: '',
      cardExpiration: '',
      cardCvc: '',
      firstName: '',
      lastName: '',
      address1: '',
      city: '',
      state: '',
      zip: '',
    });

    loadPaymentMethod();
  }, []);

  useEffect(() => {
    if (countryQuery.data?.length && !getValues('country')) {
      setValue('country', findCountryByCode('us') || countryQuery.data[0]);
    }
  }, [countryQuery.data]);

  const loadPaymentMethod = () => {
    if (editablePaymentMethod) {
      setValue('address1', editablePaymentMethod.address1);
      setValue('city', editablePaymentMethod.city);
      setValue('state', editablePaymentMethod.state);
      setValue('zip', editablePaymentMethod.zip);
      setValue('country', findCountryByCode(editablePaymentMethod.country));
      setValue('firstName', editablePaymentMethod.first_name);
      setValue('lastName', editablePaymentMethod.last_name);

      if (editablePaymentMethodType === 'card') {
        const expDate = (editablePaymentMethod as PaymentMethodCard).exp_date;
        const expDateM = expDate.slice(0, 2);
        const expDateY = expDate.slice(2);

        setValue('cardExpiration', `${expDateM} / ${expDateY}`);
      } else {
        setValue('cardNumber', (editablePaymentMethod as NewCardInformation).cardNumber);
        setValue('cardExpiration', (editablePaymentMethod as NewCardInformation).cardExpiration);
        setValue('cardCvc', (editablePaymentMethod as NewCardInformation).cardCvc);
      }
    }
  };

  const errorClass = (field: string) => {
    return errors[field] ? 'border border-danger' : '';
  };

  const formLabel = (label: string, htmlFor: string) => {
    return (
      <label htmlFor={htmlFor} className="form-label mega">
        {label}
      </label>
    );
  };

  const formError = (field: string) => {
    if (errors[field]) {
      return <div className="text-danger">{errors[field]!.message}</div>;
    }
  };

  const loadOwnerInfo = async () => {
    const profile = await AccountServices.getProfileData();
    if (editablePaymentMethod === undefined || editablePaymentMethodType === 'new-card') {
      setValue('firstName', profile.user.firstName);
      setValue('lastName', profile.user.lastName);
    }
    setValue('address1', profile.user.address.address1 ?? '');
    setValue('city', profile.user.address.city ?? '');
    setValue('state', profile.user.address.state ?? '');
    setValue('zip', profile.user.address.zip ?? '');
    setValue('country', findCountryByCode(profile.user.address.country));
  };

  const findCountryByCode = (code: string | undefined) => {
    if (countryQuery.data && code) {
      return countryQuery.data.find((i) => i.count_cod?.toLowerCase() === code.toLowerCase());
    }
  };

  const handleSave = async (data: any) => {
    setIsProcessing(true);
    setErrorMessage(undefined);
    try {
      onSave &&
        (await onSave({
          ...data,
          country: data.country.count_cod,
          phone: data.phoneNumber,
          first_name: data.firstName,
          last_name: data.lastName,
          title: Formatter.cardTitle(data.cardNumber),
        }));
    } catch (err: any) {
      // TODO: handle error being an array
      Formatter.errorMessage(err);
      setErrorMessage('Hubo un error al guardar los datos. Verifica todos los campos.');
    } finally {
      setIsProcessing(false);
    }
  };

  const onHide = () => {
    setIsProcessing(false);
    setErrorMessage(undefined);
    onCancel();
  };

  const isAmexCard = (value: string) => {
    return `${value}`.startsWith('34') || `${value}`.startsWith('37');
  };

  const watchCardNumber = watch('cardNumber');

  return (
    <Modal
      size="lg"
      show={display}
      onHide={onHide}
      backdrop={'static'}
      centered
      backdropClassName="mega-double-backdrop"
      dialogClassName="mega-double-dialog">
      <form
        noValidate
        onSubmit={(event: React.FormEvent<HTMLFormElement>) => {
          event.stopPropagation();
          event.preventDefault();

          handleSubmit(handleSave)();
        }}
        className="w-100">
        <Modal.Header closeButton className="mega-modal-title">
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Row>
            <Col lg={6} className="my-4">
              <div className="megaInput">
                {formLabel('Número de la tarjeta', 'cardNumber')}
                {editablePaymentMethod === undefined || editablePaymentMethodType === 'new-card' ? (
                  <Controller
                    defaultValue={''}
                    control={control}
                    name="cardNumber"
                    render={({ field: { onChange, name, value } }) => (
                      <NumberFormat
                        format={isAmexCard(value) ? '#### ###### #####' : '#### #### #### ####'}
                        name={name}
                        value={value}
                        onChange={onChange}
                        className={`form-control mega ${errorClass('cardNumber')}`}
                      />
                    )}
                  />
                ) : (
                  <input
                    className="form-control mega"
                    disabled={true}
                    value={editablePaymentMethod.title}
                  />
                )}
              </div>
              {formError('cardNumber')}
            </Col>
          </Row>
          <Row>
            <Col xs={6} className="mb-4">
              <div className="megaInput">
                {formLabel('Fecha de expiración', 'cardExpiration')}
                <Controller
                  control={control}
                  defaultValue={''}
                  name="cardExpiration"
                  render={({ field: { onChange, name, value } }) => (
                    <NumberFormat
                      format="## / ##"
                      name={name}
                      value={value}
                      onChange={onChange}
                      placeholder="MM / YY"
                      className={`form-control mega ${errorClass('cardExpiration')}`}
                      isAllowed={(values) => {
                        return (
                          values.value != '00' &&
                          (values.value!.length > 1 ||
                            values.floatValue! < 2 ||
                            values.value! === '')
                        );
                      }}
                    />
                  )}
                />
              </div>
              {formError('cardExpiration')}
            </Col>
            <Col xs={6} className="mb-4">
              <div className="megaInput">
                {formLabel('CVC', 'cardCvc')} {getValues}
                <Controller
                  control={control}
                  defaultValue={''}
                  name="cardCvc"
                  render={({ field: { onChange, name, value } }) => (
                    <NumberFormat
                      format={isAmexCard(watchCardNumber) ? '####' : '###'}
                      name={name}
                      value={value}
                      onChange={onChange}
                      className={`form-control mega ${errorClass('cardCvc')}`}
                    />
                  )}
                />
              </div>
              {formError('cardCvc')}
            </Col>
          </Row>

          <div className="mb-3">
            <input
              type="checkbox"
              className={`form-check-input mega me-2`}
              id="loadOwnerInfo"
              onChange={(e) => e.currentTarget.checked && loadOwnerInfo()}
            />
            <label htmlFor="loadOwnerInfo" className="form-check-label mega">
              Usar la información de mi cuenta
            </label>
          </div>

          <Row>
            <Col lg={6} className="mt-2 mb-4">
              <div className="megaInput">
                {formLabel('País', 'country')}
                <Controller
                  control={control}
                  name="country"
                  render={({ field: { onChange, name, value } }) => (
                    <Select
                      name={name}
                      placeholder=""
                      value={value}
                      options={countryQuery.data}
                      isSearchable={false}
                      isClearable={false}
                      isLoading={UtilService.isLoadingQueries([countryQuery])}
                      className="w-100 mega-select"
                      isMulti={false}
                      styles={reactSelectStyles}
                      components={{
                        Option: IconOptionAuthorizedCountry,
                        SingleValue: IconSingleValueAuthorizedCountry,
                      }}
                      onChange={onChange}
                    />
                  )}
                />
              </div>
              {formError('country')}
            </Col>
          </Row>

          <Row>
            <Col lg={6} className="mb-4">
              <div className="megaInput">
                {formLabel('Nombre', 'firstName')}
                <input
                  type="text"
                  className={`form-control mega ${errorClass('firstName')}`}
                  id="firstName"
                  {...register('firstName')}
                  disabled={
                    editablePaymentMethod !== undefined || editablePaymentMethodType === 'card'
                  }
                />
              </div>
              {formError('firstName')}
            </Col>
            <Col lg={6} className="mb-4">
              <div className="megaInput">
                {formLabel('Apellido(s)', 'lastName')}
                <input
                  type="text"
                  className={`form-control mega ${errorClass('lastName')}`}
                  id="lastName"
                  {...register('lastName')}
                  disabled={
                    editablePaymentMethod !== undefined || editablePaymentMethodType === 'card'
                  }
                />
              </div>
              {formError('lastName')}
            </Col>
          </Row>

          <Row>
            <Col lg={6} className="mb-4">
              <div className="megaInput">
                {formLabel('Dirección', 'address1')}
                <input
                  type="text"
                  className={`form-control mega ${errorClass('address1')}`}
                  id="address1"
                  {...register('address1')}
                />
              </div>
              {formError('address1')}
            </Col>
            <Col lg={6} className="mb-4">
              <div className="megaInput">
                {formLabel('Ciudad', 'city')}
                <input
                  type="text"
                  className={`form-control mega ${errorClass('city')}`}
                  id="city"
                  {...register('city')}
                />
              </div>
              {formError('city')}
            </Col>
          </Row>

          <Row>
            <Col lg={6} className="mb-4">
              <div className="megaInput">
                {formLabel('Estado', 'state')}
                <input
                  type="text"
                  className={`form-control mega ${errorClass('state')}`}
                  id="state"
                  {...register('state')}
                />
              </div>
              {formError('state')}
            </Col>
            <Col lg={6} className="mb-4">
              <div className="megaInput">
                {formLabel('Código Postal', 'zip')}
                <input
                  type="text"
                  className={`form-control mega ${errorClass('zip')}`}
                  id="zip"
                  {...register('zip')}
                />
              </div>
              {formError('zip')}
            </Col>
          </Row>

          {errorMessage && (
            <Row className="mb-4">
              <Col xs={12}>
                <Alert variant="danger" className="w-100 mb-2 d-flex align-items-center">
                  <BiError className="me-1" />
                  {errorMessage}
                </Alert>
              </Col>
            </Row>
          )}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={onCancel}
            className="px-5"
            disabled={isProcessing}
            type="button">
            Cancelar
          </Button>
          <Button
            variant="secondary text-white"
            className="px-5 d-flex align-items-center"
            type="submit"
            disabled={isProcessing}>
            {isProcessing ? <SmallSpinner /> : submitButtonText}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
