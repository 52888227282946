import { useEffect, useState } from 'react';
import { BiChevronLeft } from 'react-icons/bi';
import { useQuery } from 'react-query';
import { Link, NavLink } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';
import { AccountServices } from '../../../services';
import { MegaUserProfile } from '../../../types/profile';
import { QueryTypes } from '../../../types/queryTypes';

import styles from './accountMenu.module.scss';

type AccountMenuProps = {
  displayBackButton?: boolean;
};

export const AccountMenu = ({ displayBackButton = false }: AccountMenuProps) => {
  const [profile, setProfile] = useState<MegaUserProfile>();
  const profileQuery = useQuery(QueryTypes.GetProfile, AccountServices.getProfileData);

  useEffect(() => {
    if (profileQuery.data) {
      setProfile(profileQuery.data.user);
    }
  }, [profileQuery.data]);

  const buildMenu = () => {
    const menu = [
      { id: 'contact', title: 'Mis Contactos', path: ROUTES.cuenta.contactos },
      { id: 'topup', title: 'Enviar Recargas', path: ROUTES.cuenta.recargas },
      { id: 'sms', title: 'Enviar SMS', path: ROUTES.cuenta.sms },
      { id: 'reports', title: 'Reportes', path: ROUTES.cuenta.reportes },
      { id: 'refer_friend', title: 'Referir Amigos', path: ROUTES.cuenta.referirAmigo },
      { id: 'combo', title: 'Mercado', path: ROUTES.cuenta.store },
    ];

    if (profile?.accept_remittance) {
      menu.splice(2, 0, {
        id: 'remittance',
        title: 'Enviar Remesas',
        path: ROUTES.cuenta.remittance,
      });
    }

    return menu;
  };

  return (
    <div className="d-none d-lg-block">
      <section className="bg-mega_secondary_2">
        <div className="container">
          <div className="row">
            <div className={styles.menu_wrapper}>
              {buildMenu().map((item) => (
                <div
                  className={`d-flex text-center align-self-center ${styles.menu_item}`}
                  key={item.title}>
                  <NavLink
                    to={item.path}
                    className={(navData) =>
                      `d-block text-center my-3 py-2 ${
                        navData.isActive ? styles.menuItemActive : 'text-primary'
                      }`
                    }>
                    <div
                      className="px-2 d-inline-block"
                      dangerouslySetInnerHTML={{ __html: item.title }}></div>
                  </NavLink>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
      {displayBackButton && (
        <div className="container">
          <div className="row">
            <div className="row mt-4">
              <div className="col-12">
                <Link to={ROUTES.cuenta.inicio} className={`text-secondary`}>
                  <BiChevronLeft size={20} className="mb-1" />
                  Atrás
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
