function setItem<T>(key: string, value: T): void {
  localStorage.setItem(key, JSON.stringify(value));
}

function getItem<T>(key: string, rawValue?: boolean): T | null {
  try {
    const value = localStorage.getItem(key);

    if (!value) {
      return null;
    }

    return rawValue ? <any>value : (JSON.parse(value) as T);
  } catch (e) {
    return null;
  }
}

function removeItem(key: string): void {
  localStorage.removeItem(key);
}

function clearAll(): void {
  localStorage.clear();
}

export const StorageService = {
  setItem,
  getItem,
  removeItem,
  clearAll,
};
