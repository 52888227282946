import styles from './wizard-steps.module.scss';

export type WizardStepsProps = {
  currentStep: number;
  initialStep: number;
  steps: {
    title: string;
  }[];
};

export const WizardSteps = ({ currentStep, initialStep, steps }: WizardStepsProps) => {

  const widthSteps = 100 / steps.length;
  const filledArray = new Array(steps.length - (currentStep - initialStep)  );

  for (let i = 0; i < filledArray.length; i++) {
    if (i === 0) {
      filledArray[0] = widthSteps * ((currentStep - initialStep) + 1);
    } else {
      filledArray[i] = widthSteps;
    }
  }

  return (
    <div>
      <div className={styles.wrapperContent}>
        {steps.map((step, index) => (
          <div
            key={index}
            style={{ flexGrow: 0, flexShrink: 0, flexBasis: `${widthSteps}%` }}
            className={`${styles.stepTitle} ${
              currentStep === index + 1
                ? styles.stepTitleActive
                : index + 1 < currentStep
                ? styles.stepTitleOld
                : styles.stepTitleInactive
            }`}>
            {`${index + 1}. ${step.title}`}
          </div>
        ))}
      </div>

      <div className={styles.wrapper}>
        {filledArray.map((step, index) => (
          <div
            key={index}
            style={{ flexGrow: 0, flexShrink: 0, flexBasis: `${step}%` }}
            className={`${styles.step} ${index === 0 ? styles.stepActive : ''}`}></div>
        ))}
      </div>
    </div>
  );
};
