import { Button, Col, Row } from 'react-bootstrap';
import { IoArrowBack } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';

export const PaymentMethodsScreen = () => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="mt-6">
        <div className="mt-5">
          <Button variant="outline-primary" onClick={() => navigate(ROUTES.cuenta.inicio)}>
            <IoArrowBack /> Atrás
          </Button>
        </div>
        <h2 className="mt-6 mg-page-title">MÉTODOS DE PAGO</h2>
        <Row className="mt-4 mb-6">
          <Col md={6}>{/* <ProfilePaymentMethods cardFormSubmitButtonText="Guardar" /> */}</Col>
        </Row>
      </div>
    </div>
  );
};
