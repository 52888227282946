import { MegaFundSelector } from '../../../types/funds';
import { SignoRemesa } from '../../svg';
import styles from './priceContainer.module.scss';

export type PriceContainerProps = {
  prices: MegaFundSelector[];

  selectedId?: string | number;
  onSelectedChange?: (id: string | number) => void;
};

export const PriceContainer = (props: PriceContainerProps) => {
  const onSelect = (id: string | number) => {
    props.onSelectedChange && props.onSelectedChange(id);
  };

  return (
    <ul className={styles.priceContainer}>
      {props.prices.map((item) => (
        <li className={`${styles.priceWrapper}`} key={item.id} onClick={() => onSelect(item.id)}>
          <div className={`${styles.price} ${item.id === props.selectedId ? styles.selected : ''}`}>
            <h6 className="m-0">{item.priceTitle}</h6>
            <SignoRemesa width={18} className={styles.selectedIcon} />
            <div className="text-secondary">
              <span>{item.priceSubtitle}</span>
            </div>
            <p className="m-0 p-0">{item.description}</p>
          </div>
        </li>
      ))}
    </ul>
  );
};
