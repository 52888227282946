import * as React from 'react';
import { SVGProps } from 'react';
const SvgCartRemove = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33" {...props}>
    <g data-name="Grupo 16771">
      <g data-name="Trazado 277">
        <path
          data-name="Trazado 34114"
          d="M16.5 1a66.839 66.839 0 0 0-8.96.39c-2.13.32-3.49.86-4.39 1.77a7.549 7.549 0 0 0-1.77 4.39 65.018 65.018 0 0 0-.39 8.96 66.75 66.75 0 0 0 .39 8.96c.32 2.13.86 3.49 1.77 4.39a7.549 7.549 0 0 0 4.39 1.77 65.018 65.018 0 0 0 8.96.39 66.75 66.75 0 0 0 8.96-.39c2.13-.32 3.49-.86 4.39-1.77a7.549 7.549 0 0 0 1.77-4.39 65.018 65.018 0 0 0 .39-8.96 66.75 66.75 0 0 0-.39-8.96c-.32-2.13-.86-3.49-1.77-4.39a7.549 7.549 0 0 0-4.39-1.77A65.018 65.018 0 0 0 16.5 1m0-1C31.97 0 33 1.03 33 16.5S31.97 33 16.5 33 0 31.97 0 16.5 1.03 0 16.5 0Z"
          fill="#e2e2e2"
        />
      </g>
      <path
        data-name="Uni\xF3n 10"
        d="m10.86 20.15 3.65-3.65-3.65-3.65a1.408 1.408 0 0 1 1.99-1.99l3.65 3.65 3.65-3.65a1.407 1.407 0 1 1 1.99 1.99l-3.65 3.65 3.65 3.65a1.408 1.408 0 0 1-1.99 1.99l-3.65-3.65-3.65 3.65a1.407 1.407 0 1 1-1.99-1.99Z"
        fill="#747999"
      />
    </g>
  </svg>
);
export default SvgCartRemove;
