import * as React from 'react';
import { SVGProps } from 'react';
const SvgAmericanExpress = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="AmericanExpress_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 57.53 20"
    {...props}>
    <defs>
      <style>
        {
          '.AmericanExpress_svg__cls-1{fill:#2fabf7}.AmericanExpress_svg__cls-2{fill:#228fe0}.AmericanExpress_svg__cls-3{fill:#0571c1}'
        }
      </style>
    </defs>
    <g id="AmericanExpress_svg__Capa_1-2" data-name="Capa 1">
      <path className="AmericanExpress_svg__cls-1" d="M4.93 4.93h2.01l-1-2.56-1 2.56Z" />
      <path
        className="AmericanExpress_svg__cls-2"
        d="M29.86 2.74c-.18-.09-.46-.09-.73-.09H27.3v1.46h1.83c.27 0 .55 0 .73-.09s.27-.37.27-.64c.09-.37-.09-.55-.27-.64Z"
      />
      <path
        className="AmericanExpress_svg__cls-3"
        d="M49.41 0v1.1L48.86 0h-4.29v1.1L44.02 0h-5.84c-1.01 0-1.83.18-2.56.55V0h-4.11v.55C31.05.18 30.5 0 29.77 0h-14.7l-1 2.28L13.06 0H8.4v1.1L7.85 0H3.92L2.09 4.29 0 9.04h4.66l.55-1.46h1.28l.55 1.46h5.3v-1.1l.46 1.1h2.65l.46-1.1v1.1H28.6V6.67h.18q.18 0 .18.27v2.01h6.58V8.4c.55.27 1.37.55 2.47.55h2.74l.55-1.46h1.28l.55 1.46h5.3V7.58l.82 1.37h4.29V0h-4.11ZM18.54 7.67h-1.55V2.65L14.8 7.67h-1.37l-2.19-5.02v5.02H8.13L7.49 6.3H4.38l-.55 1.46H2.09l2.75-6.48h2.28L9.68 7.4V1.28h2.47l2.01 4.38 1.83-4.38h2.56v6.39Zm6.21-5.02h-3.56v1.19h3.47v1.28h-3.47V6.4h3.56v1.37h-5.11V1.28h5.11v1.37ZM31.6 5.3c.18.37.27.64.27 1.19v1.28h-1.55v-.82c0-.37 0-.91-.27-1.28-.27-.27-.55-.27-1.1-.27h-1.64v2.37h-1.55V1.28h3.47c.82 0 1.37 0 1.83.27s.73.73.73 1.46c0 1-.64 1.55-1.1 1.74.46.09.73.37.91.55Zm2.74 2.37h-1.55V1.19h1.55v6.48Zm17.99 0h-2.19l-2.92-4.84v4.84h-3.11l-.55-1.37h-3.2l-.55 1.46h-1.74c-.73 0-1.64-.18-2.19-.73s-.82-1.28-.82-2.47c0-.91.18-1.83.82-2.56.46-.55 1.28-.73 2.28-.73h1.46v1.37h-1.46c-.55 0-.82.09-1.19.37-.27.27-.46.82-.46 1.46 0 .73.09 1.19.46 1.55.27.27.64.37 1.1.37h.64l2.1-5.02h2.28l2.56 6.12V1.37h2.28l2.65 4.47V1.37h1.55v6.3h.18Z"
      />
      <path
        className="AmericanExpress_svg__cls-2"
        d="M40.91 4.93h2.1l-1-2.56-1.1 2.56ZM25.38 18.08v-5.21l-2.37 2.56 2.37 2.65Z"
      />
      <path
        className="AmericanExpress_svg__cls-1"
        d="M15.62 13.52v1.19H19v1.28h-3.38v1.37h3.74l1.74-1.92-1.64-1.92h-3.84Z"
      />
      <path
        className="AmericanExpress_svg__cls-2"
        d="M28.86 13.52h-1.92v1.64h2.01c.55 0 .91-.27.91-.82-.09-.55-.46-.82-1-.82Z"
      />
      <path
        className="AmericanExpress_svg__cls-3"
        d="M57.08 15.07v-4.11h-3.84c-.82 0-1.46.18-1.92.55v-.55h-4.2c-.64 0-1.46.18-1.83.55v-.55h-7.4v.55c-.55-.46-1.55-.55-2.01-.55h-4.93v.55c-.46-.46-1.55-.55-2.1-.55h-5.48l-1.28 1.37-1.19-1.37h-8.22v8.95h8.04L22 18.54l1.19 1.37h4.93v-2.1h.64c.64 0 1.46 0 2.1-.27v2.47h4.11v-2.37h.18q.27 0 .27.27v2.1h12.42c.82 0 1.64-.18 2.1-.55v.55h3.93c.82 0 1.64-.09 2.19-.46.91-.55 1.46-1.55 1.46-2.74 0-.64-.18-1.28-.46-1.74Zm-28.31 1.46h-1.83v2.19h-2.92l-1.83-2.1-1.92 2.1h-6.03v-6.48h6.12l1.83 2.1 1.92-2.1h4.84c1.19 0 2.56.37 2.56 2.1-.09 1.83-1.37 2.19-2.74 2.19Zm9.13-.37c.18.27.27.64.27 1.19v1.28h-1.55v-.82c0-.37 0-1-.27-1.28-.18-.27-.55-.27-1.1-.27h-1.64v2.37h-1.55v-6.48h3.47c.73 0 1.37 0 1.83.27s.82.73.82 1.46c0 1-.64 1.55-1.1 1.74.46.18.73.37.82.55Zm6.3-2.65h-3.56v1.19h3.47v1.28h-3.47v1.28h3.56v1.37h-5.11v-6.48h5.11v1.37Zm3.84 5.11h-2.92v-1.37h2.92c.27 0 .46 0 .64-.18.09-.09.18-.27.18-.46s-.09-.37-.18-.46c-.09-.09-.27-.18-.55-.18-1.46-.09-3.2 0-3.2-2.01 0-.91.55-1.92 2.19-1.92h3.01v1.55H47.3c-.27 0-.46 0-.64.09s-.18.27-.18.46c0 .27.18.37.37.46s.37.09.55.09h.82c.82 0 1.37.18 1.74.55.27.27.46.73.46 1.37 0 1.37-.82 2.01-2.37 2.01Zm7.85-.64c-.37.37-1 .64-1.92.64h-2.92v-1.37h2.92c.27 0 .46 0 .64-.18.09-.09.18-.27.18-.46s-.09-.37-.18-.46c-.09-.09-.27-.18-.55-.18-1.46-.09-3.2 0-3.2-2.01 0-.91.55-1.92 2.19-1.92h3.01v1.55h-2.74c-.27 0-.46 0-.64.09s-.18.27-.18.46c0 .27.09.37.37.46.18.09.37.09.55.09h.82c.82 0 1.37.18 1.74.55.09 0 .09.09.09.09.27.37.37.82.37 1.28 0 .55-.18 1-.55 1.37Z"
      />
      <path
        className="AmericanExpress_svg__cls-2"
        d="M36.26 13.7c-.18-.09-.46-.09-.73-.09H33.7v1.46h1.83c.27 0 .55 0 .73-.09s.27-.37.27-.64c.09-.37-.09-.55-.27-.64Z"
      />
      <path
        className="AmericanExpress_svg__cls-2"
        d="M29.86 2.74c-.18-.09-.46-.09-.73-.09H27.3v1.46h1.83c.27 0 .55 0 .73-.09s.27-.37.27-.64c.09-.37-.09-.55-.27-.64Zm11.05 2.19h2.1l-1-2.56-1.1 2.56ZM25.38 18.08v-5.21l-2.37 2.56 2.37 2.65Zm3.47-4.57h-1.92v1.64h2.01c.55 0 .91-.27.91-.82-.09-.55-.46-.82-1-.82Zm7.4.18c-.18-.09-.46-.09-.73-.09h-1.83v1.46h1.83c.27 0 .55 0 .73-.09s.27-.37.27-.64c.09-.37-.09-.55-.27-.64Z"
      />
      <path
        className="AmericanExpress_svg__cls-1"
        d="m28.22 18.45-1.28-1.37v1.55h-3.01l-1.83-2.1-2.01 2.1h-6.03v-6.39h6.12l1.92 2.1.91-1.1-2.28-2.28h-8.04v8.95h8.04l1.37-1.37 1.19 1.37h4.93v-1.46ZM18.72 8.95l-1.19-1.28h-.55v-.55l-1.37-1.37-.91 1.92h-1.28l-2.19-5.02v5.02H8.12L7.48 6.3H4.37l-.64 1.37H2.09l2.75-6.39h2.28L9.68 7.4V1.28h1.37L9.77 0H8.4v1.1L7.95 0H3.93L2.1 4.29 0 8.95h4.75l.55-1.37h1.28l.64 1.37h5.11v-1.1l.46 1.1h2.65l.46-1.1v1.1h2.83Z"
      />
      <path className="AmericanExpress_svg__cls-1" d="m14.52 4.75-1.46-1.46 1.1 2.37.37-.91Z" />
      <path
        className="AmericanExpress_svg__cls-2"
        d="M56.07 19.45c.82-.55 1.37-1.46 1.46-2.47l-1.28-1.28c.09.27.18.55.18.91 0 .55-.18 1-.55 1.37-.37.37-1 .64-1.92.64h-2.92v-1.37h2.92c.27 0 .46 0 .64-.18.09-.09.18-.27.18-.46s-.09-.37-.18-.46c-.09-.09-.27-.18-.55-.18-1.46-.09-3.2 0-3.2-2.01 0-.91.55-1.74 1.92-1.92l-1-1c-.18.09-.27.18-.37.18v-.55h-4.2c-.64 0-1.46.18-1.83.55v-.55h-7.49v.55c-.55-.46-1.55-.55-2.01-.55h-4.93v.55c-.46-.46-1.55-.55-2.1-.55h-5.48l-1.28 1.37-1.19-1.37h-1l2.74 2.74L24 11.95h4.84c1.19 0 2.56.37 2.56 2.1 0 1.83-1.28 2.19-2.65 2.19h-1.83v1.37l1.37 1.37v-1.37h.46c.64 0 1.46 0 2.1-.27v2.47h4.11v-2.37h.18q.27 0 .27.27v2.1h12.42c.82 0 1.64-.18 2.1-.55v.55h3.93c.73.09 1.55 0 2.19-.37ZM37.9 16.16c.18.27.27.64.27 1.19v1.28h-1.55v-.82c0-.37 0-1-.27-1.28-.18-.27-.55-.27-1.1-.27h-1.64v2.37h-1.55v-6.48h3.47c.73 0 1.37 0 1.83.27s.82.73.82 1.46c0 1-.64 1.55-1.1 1.74.46.18.73.37.82.55Zm6.3-2.65h-3.56v1.19h3.47v1.28h-3.47v1.28h3.56v1.37h-5.11v-6.48h5.11v1.37Zm3.84 5.11h-2.92v-1.37h2.92c.27 0 .46 0 .64-.18.09-.09.18-.27.18-.46s-.09-.37-.18-.46c-.09-.09-.27-.18-.55-.18-1.46-.09-3.2 0-3.2-2.01 0-.91.55-1.92 2.19-1.92h3.01v1.55H47.3c-.27 0-.46 0-.64.09s-.18.27-.18.46c0 .27.18.37.37.46s.37.09.55.09h.82c.82 0 1.37.18 1.74.55.27.27.46.73.46 1.37 0 1.37-.82 2.01-2.37 2.01Z"
      />
      <path
        className="AmericanExpress_svg__cls-2"
        d="M52.42 14.16c0 .27.09.37.37.46.18.09.37.09.55.09h.82c.55 0 .91.09 1.28.27l-1.37-1.37h-.82c-.27 0-.46 0-.64.09-.09.09-.18.27-.18.46ZM49.22 8.68l.18.27h.09l-.27-.27ZM44.2 3.66l1.55 3.74V5.21L44.2 3.66Z"
      />
      <path
        className="AmericanExpress_svg__cls-2"
        d="M28.58 6.67h.18q.18 0 .18.27v2.01h6.58V8.4c.55.27 1.37.55 2.47.55h2.74l.55-1.46h1.28l.55 1.46h5.3v-.91l-1.28-1.28v1h-3.11l-.46-1.46h-3.2l-.55 1.46h-1.74c-.73 0-1.64-.18-2.19-.73s-.82-1.28-.82-2.47c0-.91.18-1.83.82-2.56.46-.55 1.28-.73 2.28-.73h1.46v1.37h-1.46c-.55 0-.82.09-1.19.37-.27.27-.46.82-.46 1.46 0 .73.09 1.19.46 1.55.27.27.64.37 1.1.37h.64l2.1-5.02h.91L40.44.09h-2.37c-1 0-1.83.18-2.56.55V.09h-4.02v.55c-.46-.37-1-.55-1.74-.55h-14.7l-1 2.28-.99-2.28H9.04l1.28 1.28h1.83l1.55 3.38.55.55 1.64-4.02h2.56v6.48H16.9V2.74l-1.55 3.65L18 9.04h10.5l.09-2.37Zm4.2-5.39h1.55v6.48h-1.55V1.28Zm-8.04 1.37h-3.56v1.19h3.47v1.28h-3.47V6.4h3.56v1.37h-5.11V1.28h5.11v1.37Zm2.56 5.02h-1.55V1.19h3.47c.82 0 1.37 0 1.83.27s.73.73.73 1.46c0 1-.64 1.55-1.1 1.74.37.09.64.37.73.55.18.37.27.64.27 1.19v1.28h-1.55v-.82c0-.37 0-.91-.27-1.28-.09-.18-.37-.18-.91-.18h-1.64v2.28Z"
      />
    </g>
  </svg>
);
export default SvgAmericanExpress;
