import { createRef, useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { IMaskInput } from 'react-imask';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { MegaError } from '../../../components/account/megaError';
import { WizardSteps } from '../../../components/shared';
import {
  IconOptionAuthorizedCountry,
  IconSingleValueAuthorizedCountry,
  reactSelectStyles
} from '../../../config/reactSelect';
import { ROUTES } from '../../../config/routes';
import { MODAL_TYPES, useGlobalModalContext } from '../../../providers/globalModal.provider';
import { SessionContext } from '../../../providers/session.provider';
import { CountryServices, RegisterService, UtilService } from '../../../services';
import { AuthorizedCountry } from '../../../types/country';
import { QueryTypes } from '../../../types/queryTypes';

import styles from './forgot-password-screen.module.scss';

const captchaRef = createRef<ReCAPTCHA>();

export const ForgotPasswordScreen = () => {
  const phoneRef = useRef<any>(null);
  const { showModal } = useGlobalModalContext();
  const { isAuthenticated } = useContext(SessionContext);
  const navigate = useNavigate();

  // state
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneHasError, setPhoneHasError] = useState(false);
  const [captchaHasError, setCaptchaHasError] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<AuthorizedCountry | null>(null);
  const [validationMethod, setValidationMethod] = useState<'sms' | 'call'>('sms');

  const countryQuery = useQuery(
    QueryTypes.GetAuthCountries,
    CountryServices.getAllAuthorizedCountries
  );

  const initialQueries = [countryQuery];

  useEffect(() => {
    if (isAuthenticated()) {
      navigate(ROUTES.cuenta.inicio);
    }
  }, []);

  useEffect(() => {
    if (countryQuery.data?.length && !selectedCountry) {
      const usa = countryQuery.data.find((i) => i.count_cod?.toLowerCase() === 'us');

      setSelectedCountry(usa || countryQuery.data[0]);
    }
  }, [countryQuery.data]);

  useEffect(() => {
    !!phone && validatePhone();
  }, [phone]);

  useEffect(() => {
    setPhone('');
    setPhoneHasError(false);

    if (phoneRef && phoneRef.current && phoneRef.current.maskRef) {
      phoneRef.current.maskRef.masked.reset();
    }
  }, [selectedCountry]);

  const onCaptchaChange = (token: string | null) => {
    if (token) {
      setCaptchaHasError(false);
    }
  };

  const validatePhone = () => {
    const error = UtilService.getMaskLength(selectedCountry?.phone_mask) !== phone.length;
    setPhoneHasError(error);
    return error;
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    let hasAnyError = validatePhone();
    const token = captchaRef.current?.getValue();
    if (!token) {
      setCaptchaHasError(true);
      return;
    }

    if (hasAnyError || !selectedCountry) {
      return;
    }

    // success
    setIsSubmitting(true);
    setErrorMessage('');

    const response = await RegisterService.requestForgotPassword(
      phone,
      validationMethod === 'sms' ? 1 : 2,
      token
    );

    setIsSubmitting(false);
    captchaRef.current?.reset();

    if (!response.success) {
      let error = '';
      const accountError =
        'Si cree que hay un error, por favor contacte nuestro Departamento de Servicios al cliente.';
      switch (response.code) {
        case '003':
          error =
            '<strong>No existe ninguna cuenta con el número de teléfono entrado.</strong><br/>' +
            accountError;
          break;
        case '004':
          error =
            '<strong>Su cuenta ya contiene un PIN válido por 5 minutos o 3 intentos.</strong><br/>Por favor seleccione el botón "Ya tengo un PIN" o espere 5 minutos para generar un nuevo PIN.' +
            accountError;
          break;
        case '002':
        case '500':
        default:
          error = 'Hubo un error verificando su información. Por favor intente de nuevo.';
          break;
      }

      setErrorMessage(error);
      return;
    }

    // success
    openPinModal();
  };

  const openPinModal = () => {
    setErrorMessage('');
    showModal(MODAL_TYPES.PIN_VALIDATION, {
      onClose: onCloseValidationModal,
      state: { phone },
    });
  };

  const onCloseValidationModal = (isSuccess?: boolean) => {
    if (!isSuccess) {
      return;
    }

    navigate(ROUTES.changePassword, { state: { phone } });
  };

  // if (UtilService.isLoadingQueries(initialQueries)) {
  //   return <MegaLoading isLoading={true} />;
  // }

  if (UtilService.isErrorQueries(initialQueries)) {
    return <MegaError displayRetry retry={() => UtilService.refetchQueries(initialQueries)} />;
  }

  return (
    <div className="container my-7">
      <form noValidate onSubmit={(e) => onSubmit(e)}>
        <div className="row">
          <div className="col-lg-3 col-md-0"></div>
          <div className="col-lg-6 col-md-12">
            <h1 className="text-center text-uppercase mega-font-bold fs-3 text-center">
              RECUPERAR CONTRASEÑA
            </h1>

            <WizardSteps
              currentStep={1}
              initialStep={1}
              steps={[{ title: 'Datos de Cuenta' }, { title: 'Validación' }]}
            />

            <p className="w-100 text-center my-5">
              Introduzca su número de teléfono que utilizó al registrarse en nuestro sitio y el
              método de verificación que usted prefiera en el formulario a la izquierda y recibirá
              un PIN por cualquier vía que usted selecicione.
            </p>
          </div>
          <div className="col-lg-3 col-md-0"></div>
        </div>

        <div className="row mt-3">
          <div className="col-xl-4 col-lg-3 col-md-0"></div>
          <div className="col-xl-4 col-lg-6 col-md-12">
            <div className="px-0">
              <div className="megaInput">
                <label htmlFor="country">Seleccione el país</label>
                <Select
                  id="country"
                  placeholder=""
                  value={selectedCountry}
                  options={countryQuery.data}
                  isSearchable={false}
                  isClearable={false}
                  isLoading={UtilService.isLoadingQueries([countryQuery])}
                  className="w-100 mega-select"
                  isMulti={false}
                  styles={reactSelectStyles}
                  components={{
                    Option: IconOptionAuthorizedCountry,
                    SingleValue: IconSingleValueAuthorizedCountry,
                  }}
                  onChange={(newValue) => {
                    setSelectedCountry(newValue);
                  }}
                />
              </div>

              <div className="mt-5">
                <div className="megaInput">
                  <label htmlFor="phone">Teléfono</label>
                  <IMaskInput
                    id="phone"
                    mask={UtilService.convertPhoneMask(selectedCountry?.phone_mask)}
                    unmask={true}
                    ref={phoneRef}
                    value={phone}
                    onAccept={(value, _) => setPhone(`${value}`)}
                    className={`form-control mega ${phoneHasError ? 'border border-danger' : ''}`}
                  />
                </div>
                {phoneHasError && <div className="text-danger">El teléfono es requerido</div>}
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-3 col-md-0"></div>
        </div>

        <div className="row mt-4">
          <div className="col-xl-4 col-lg-3 col-md-0"></div>
          <div className="col-xl-4 col-lg-6 col-md-12">
            <label className="form-label mega-bold text-center">Metodo de verificación:</label>
            <div>
              <div className={`${styles.mega_radio}`}>
                <div className={`form-check form-check-inline`}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio1"
                    value="sms"
                    checked={validationMethod === 'sms'}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValidationMethod('sms');
                      }
                    }}
                  />
                  <label
                    className={`form-check-label ${styles.form_check_label}`}
                    htmlFor="inlineRadio1">
                    Mensaje SMS
                  </label>
                </div>
              </div>
              <div className={`${styles.mega_radio}`}>
                <div className={`form-check form-check-inline`}>
                  <input
                    className="form-check-input"
                    type="radio"
                    name="inlineRadioOptions"
                    id="inlineRadio2"
                    value="call"
                    checked={validationMethod === 'call'}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setValidationMethod('call');
                      }
                    }}
                  />
                  <label
                    className={`form-check-label ${styles.form_check_label}`}
                    htmlFor="inlineRadio2">
                    Llamada telefonica
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-lg-3 col-md-0"></div>
        </div>

        <div className="row mt-2">
          <div className="col-xl-4 col-lg-3 col-md-0"></div>
          <div className="col-xl-4 col-lg-6 col-md-12 text-center">
            <div className="w-100 text-center">
              <ReCAPTCHA
                ref={captchaRef}
                sitekey={process.env.REACT_APP_CAPTCHA_V2_KEY || ''}
                onChange={onCaptchaChange}
                hl={'es'}
                style={{ display: 'inline-block' }}
              />
              {captchaHasError && (
                <div className="text-danger text-center">La validación es requerida</div>
              )}
            </div>
          </div>
          <div className="col-xl-4 col-lg-3 col-md-0"></div>
        </div>

        <div className="row">
          <div className="col-xl-4 col-lg-3 col-md-0"></div>
          <div className="col-xl-4 col-lg-6 col-md-12 text-center">
            {errorMessage && !isSubmitting && (
              <div className="mt-3 text-start">
                <Alert variant={'danger'}>
                  <div dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
                </Alert>
              </div>
            )}

            <div className="px-0 px-lg-8 mt-3">
              <Button variant="primary" type="submit" className="w-100 py-2">
                {isSubmitting ? (
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    variant="light"
                    aria-hidden="true"
                  />
                ) : (
                  <span>Enviar Código</span>
                )}
              </Button>
            </div>
          </div>
          <div className="col-xl-4 col-lg-3 col-md-0"></div>
        </div>

        <div className="row mt-5">
          <div className="col-lg-3 col-md-0"></div>
          <div className="col-lg-6 col-md-12">
            <div className={styles.pWrapper}>
              <ul>
                <li>
                  <p className="mt-5">
                    El PIN enviado solo tendrá un tiempo de vida de 5 minutos o 3 intentos. Una vez
                    pasada los 5 minutos o 3 intentos deberá volver a generar un nuevo PIN.
                  </p>
                </li>
                <li>
                  <p className="m-0">
                    Introduzca este PIN una vez recibido y podrá tener accesso a cambiar su
                    contraseña de acceso a nuestro sitio.
                  </p>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-3 col-md-0"></div>
        </div>
      </form>
    </div>
  );
};
