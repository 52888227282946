import * as React from 'react';
import { SVGProps } from 'react';
const SvgUser = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <g data-name="Capa 1">
      <path
        data-name="Trazado 308"
        d="M15 0C7.86 0 4 .75 2 3.68.45 5.94 0 9.49 0 15c0 7.05.73 10.9 3.56 12.92C5.81 29.53 9.39 30 15 30c5.89 0 9.55-.51 11.77-2.33C29.32 25.58 30 21.76 30 15c0-5.33-.42-8.83-1.84-11.09C26.2.79 22.33 0 15 0Z"
        style={{
          fill: '#8ab934',
        }}
      />
      <path
        data-name="Uni\xF3n 11"
        d="M15.01 23.4c-2.35-.02-4.7-.25-7.01-.68-.79-.39-.91-.78-.52-1.57.35-.67.8-1.28 1.35-1.8 3.38-3.39 8.87-3.39 12.26 0 .5.5.93 1.05 1.29 1.65.55.94.46 1.26-.52 1.75-2.2.46-4.44.68-6.69.66H15Zm-4.35-12.37c0-2.41 1.95-4.36 4.36-4.36s4.36 1.95 4.36 4.36-1.95 4.36-4.36 4.36-4.36-1.95-4.36-4.36Z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);
export default SvgUser;
