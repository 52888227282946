import * as React from 'react';
import { SVGProps } from 'react';
const SvgFilterCumpleannos = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23.001" {...props}>
    <g data-name="Capa 1">
      <g data-name="Grupo 16770">
        <g data-name="Grupo 16769" fill="#abadc4">
          <path
            data-name="Trazado 34109"
            d="M22.88 15.481c-.06-1.12-.09-2.25-.18-3.37-.15-1.88-1.14-3.21-2.53-3.4-.49-.06-.99-.12-1.48-.17 0-1.44-.03-2.27-.07-2.74a1.032 1.032 0 0 0-.33-.7.975.975 0 0 0-.63-.26c-.17-.01-.39-.02-.66-.02a6.237 6.237 0 0 0-.66.02 1.009 1.009 0 0 0-.96.96c-.03.45-.07 1.24-.07 2.6h-2.15c0-1.36-.04-2.15-.07-2.6a1.072 1.072 0 0 0-.28-.65 1.008 1.008 0 0 0-.68-.31c-.17-.01-.39-.02-.66-.02a6.237 6.237 0 0 0-.66.02 1.021 1.021 0 0 0-.97.96c-.03.45-.07 1.24-.07 2.6H7.41c0-1.36-.04-2.15-.07-2.6a1.173 1.173 0 0 0-.22-.59 1 1 0 0 0-.74-.38c-.17-.01-.39-.02-.66-.02a6.237 6.237 0 0 0-.66.02 1.008 1.008 0 0 0-.66.29 1.066 1.066 0 0 0-.3.67c-.03.47-.07 1.3-.07 2.75-.42.04-.85.1-1.27.16-1.33.2-2.31 1.49-2.48 3.27-.11 1.15-.12 2.31-.19 3.47v.48c.07 1.16.08 2.32.19 3.47.18 1.78 1.15 3.07 2.48 3.27a26.276 26.276 0 0 0 3.3.3c1.82.05 3.64.01 5.46.01 1.77 0 3.55.03 5.32-.01a30.807 30.807 0 0 0 3.31-.29c1.39-.18 2.38-1.51 2.53-3.4.09-1.12.12-2.25.18-3.37v-.44Z"
            opacity={0.4}
          />
          <path
            data-name="Trazado 34110"
            d="M11.55 8.411c1.77 0 3.55-.03 5.32 0 1.1.02 2.21.1 3.31.21a2.716 2.716 0 0 1 2.53 2.49c.09.82.12 1.65.18 2.48a1.254 1.254 0 0 1-.38.99 3.884 3.884 0 0 1-5.67.22l-.05-.05a1.526 1.526 0 0 0-2.46-.01 4.055 4.055 0 0 1-1.9 1.07 3.739 3.739 0 0 1-3.47-.87c-.19-.17-.37-.35-.57-.5a1.411 1.411 0 0 0-1.84.05c-.16.13-.3.28-.46.42a3.9 3.9 0 0 1-5.61-.41 1.262 1.262 0 0 1-.34-.94c.07-.85.08-1.7.19-2.55a2.737 2.737 0 0 1 2.48-2.4c1.1-.12 2.2-.2 3.3-.22 1.82-.04 3.64 0 5.46-.01Z"
          />
          <path
            data-name="Trazado 34111"
            d="M18.82 2.271a1.687 1.687 0 0 1-1.83 1.78 1.735 1.735 0 0 1-1.51-2.02 3.047 3.047 0 0 1 1.08-1.8.862.862 0 0 1 1.09-.07 3.087 3.087 0 0 1 1.17 2.11Z"
          />
          <path
            data-name="Trazado 34112"
            d="M13.16 2.271a1.687 1.687 0 0 1-1.83 1.78 1.735 1.735 0 0 1-1.51-2.02 3.047 3.047 0 0 1 1.08-1.8.862.862 0 0 1 1.09-.07 3.087 3.087 0 0 1 1.17 2.11Z"
          />
          <path
            data-name="Trazado 34113"
            d="M7.42 2.271a1.687 1.687 0 0 1-1.83 1.78 1.735 1.735 0 0 1-1.51-2.02 3.047 3.047 0 0 1 1.08-1.8.862.862 0 0 1 1.09-.07 3.087 3.087 0 0 1 1.17 2.11Z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgFilterCumpleannos;
