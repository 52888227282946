const wpBaseUrl = process.env.REACT_APP_MEGA_WORDPRESS_URL;
const topUpBaseUrl = '/topup';

export const ROUTES = {
  welcome: '/',
  login: `/login`,
  register: `/register`,
  register_validation: '/register/validate',
  forgotPassword: '/forgot-password/request',
  changePassword: '/forgot-password/password',
  termsAndConditions: '/terms-conditions',

  external: {
    home: `/`,
    contact: `contactenos`,
    services: {
      calls: `llamadas`,
      topups: `recargas`,
      nauta: `nauta`,
      combos: 'mercado',
      messages: `mensajes`,
    },
  },

  cuenta: {
    inicio: '/profile',
    editarCuenta: '/profile/edit',
    contactos: '/contacts',
    sms: '/send-sms',
    fondos: '/add-funds',
    recargas: topUpBaseUrl,
    reportes: '/reports',
    referirAmigo: '/refer-friend',
    shoppingCart: '/shopping-cart',
    store: '/store',
    productStore: '/store/:id',
    checkout: '/checkout',
    checkoutPaymentMethods: '/checkout-payment-methods',
    checkoutSummary: '/checkout-summary',
    paymentMethods: '/payment-methods',
    topUpPaymentMethods: `${topUpBaseUrl}/payment-methods`,
    topUpSummary: `${topUpBaseUrl}/summary`,
    remittance: '/remittance',
    payment: '/pay',
  },
};

export const ROUTES_REGISTERED = [...Object.values(ROUTES.cuenta)];
