import api from './axios/api';
import { MegaProfile } from '../types/profile';
import { AutoRechargeFund, UserBalance } from '../types/user';

const getProfileData = async (): Promise<MegaProfile> => {
  try {
    const response = await api.get<MegaProfile>('/api/v1/account/getprofile');

    if (!!response.data) {
      if (!!response.data.account.balance && response.data.account.balance > 0) {
        response.data.account.balance_format = (
          Math.round(response.data.account.balance * 100) / 100
        ).toFixed(2);
      }

      return response.data;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

type updateProfileParams = {
  firstName?: string;
  lastName?: string;
  email?: string;
  password?: string;
  changePassword: boolean;
  address1?: string;
  city?: string;
  state?: string;
  country?: string;
  zipCode?: string;
  receiveEmail: boolean;
  receiveSms: boolean;
};

const updateProfile = async (params: updateProfileParams) => {
  const url = '/api/v1/account/profile';
  const payload = {
    first_name: params.firstName,
    last_name: params.lastName,
    email: params.email,
    change_password: params.changePassword,
    password: params.changePassword ? params.password : undefined,
    address1: params.address1,
    city: params.city,
    state: params.state,
    country: params.country,
    zip: params.zipCode,
    receive_email: params.receiveEmail,
    receive_sms: params.receiveSms,
  };

  try {
    const response = await api.put<boolean>(url, payload);

    if (!response.has_error) {
      return true;
    }

    throw new Error(response.error ?? 'ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const getBalance = async (): Promise<UserBalance> => {
  try {
    const response = await api.get<UserBalance>('/api/v1/account/getbalance');

    if (!!response.data) {
      const userBalance = response.data;

      return userBalance;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const getAutoRechargeData = async () => {
  try {
    const response = await api.get<AutoRechargeFund>('/api/v1/account/getaautorechargeinfo');

    if (!!response.data) {
      return response.data;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const updateAutoRecharge = async (
  ccProfile: number,
  minBalance: number,
  amount: number
): Promise<boolean> => {
  try {
    const response = await api.put('/api/v1/account/auto-recharge', {
      cc_profile: ccProfile,
      min_balance: minBalance,
      amount_add: amount,
    });

    return !response.has_error;
  } catch (error) {
    return false;
  }
};

export const AccountServices = {
  getProfileData,
  getBalance,
  getAutoRechargeData,
  updateAutoRecharge,
  updateProfile,
};
