import * as React from 'react';
import { SVGProps } from 'react';
const SvgFlechaDer = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" {...props}>
    <g data-name="Capa 1">
      <path
        data-name="Trazado 10288"
        className="flecha_der_svg__cls-1"
        d="m14.87 3.37-1.89 1.89 4.91 4.91H0v2.67h17.89l-4.91 4.91 1.89 1.89L23 11.51l-8.13-8.14Z"
      />
    </g>
  </svg>
);
export default SvgFlechaDer;
