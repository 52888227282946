import * as React from 'react';
import { SVGProps } from 'react';
const SvgCorreo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="correo_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    {...props}>
    <defs>
      <style>{'.correo_svg__cls-1{fill:#8f979f}'}</style>
    </defs>
    <g id="correo_svg__Capa_1-2" data-name="Capa 1">
      <path
        className="correo_svg__cls-1"
        d="M11.3 21.4c-3.26 0-6.14-.12-7.58-.24a2.898 2.898 0 0 1-2.68-2.68C.92 17.04.8 14.76.8 11.5s.12-5.54.24-6.98a2.898 2.898 0 0 1 2.68-2.68c1.44-.12 4.32-.24 7.58-.24s6.54.12 7.98.24c1.45.12 2.56 1.23 2.68 2.68.12 1.44.24 3.72.24 6.98s-.12 5.54-.24 6.98a2.898 2.898 0 0 1-2.68 2.68c-1.44.12-4.72.24-7.98.24Z"
        style={{
          opacity: 0.4,
        }}
      />
      <path
        className="correo_svg__cls-1"
        d="M11.14 9.97c-3.74 0-9.9-3.2-10.23-3.38-.04.8-.07 1.73-.09 2.8 2.15 1.04 6.87 3.12 10.32 3.12s8.91-2.27 11.04-3.26c-.02-1.05-.05-1.97-.09-2.76-.07.02-.14.05-.2.09-.74.4-7.19 3.39-10.74 3.39Z"
      />
    </g>
  </svg>
);
export default SvgCorreo;
