import Swal, { SweetAlertIcon } from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

export class AlertHelper {
  public static show({
    icon,
    html,
    text,
    title,
  }: {
    icon?: SweetAlertIcon | undefined;
    html?: string | HTMLElement | JQuery | undefined;
    text?: string | undefined;
    title?: string | undefined;
  } = {}) {
    const MySwal = withReactContent(Swal);

    MySwal.fire({
      buttonsStyling: false,
      customClass: {
        confirmButton: 'btn btn-primary',
      },
      icon,
      html,
      text,
      title,
    });
  }
}
