import { ReactNode } from 'react';

import styles from './summaryCard.module.scss';

type SummaryCardProps = {
  children: ReactNode;
  header: string;
};

export const SummaryCard = ({ children, header }: SummaryCardProps) => {
  return (
    <div className={`bg-mega_light_gray rounded p-4 ${styles.summary_card}`}>
      <div className={styles.header}>{header}</div>
      {children}
    </div>
  );
};
