import { Button, Modal } from 'react-bootstrap';
import Lottie from 'react-lottie';
import creditCardErrorJson from '../../../assets/animations/card-payment-rejected-rear.json';
import errorJson from '../../../assets/animations/error.json';
import { useGlobalModalContext } from '../../../providers/globalModal.provider';

export const ErrorModal = () => {
  const { hideModal, currentModalProps } = useGlobalModalContext();

  const closeModal = (source: 'primary' | 'secondary') => {
    hideModal();
    !!currentModalProps?.onClose && currentModalProps.onClose(source);
  };

  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: creditCardErrorJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  const defaultOptions2 = {
    loop: true,
    autoplay: true,
    animationData: errorJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Modal
      size="sm"
      show={true}
      backdrop="static"
      backdropClassName="mega-double-backdrop"
      dialogClassName="mega-double-dialog"
      centered>
      <Modal.Body>
        {currentModalProps?.state.cardError ? (
          <Lottie options={defaultOptions1} height={90} width={90} />
        ) : (
          <Lottie options={defaultOptions2} height={90} width={90} />
        )}

        <h6 className="text-center mt-2">{currentModalProps?.title}</h6>
        <p
          className="text-center mega-fs-small mt-3"
          dangerouslySetInnerHTML={{ __html: currentModalProps?.description || '' }}></p>

        <div className="mt-5 text-center">
          <Button variant="secondary" className="px-4" onClick={() => closeModal('primary')}>
            {currentModalProps?.state?.buttonTitle}
          </Button>
        </div>

        {!!currentModalProps?.state?.buttonLinkTitle && (
          <div className="mt-3 text-center">
            <Button
              variant="link"
              className="text-secondary text-decoration-none mega-fs-small"
              onClick={() => closeModal('secondary')}>
              {currentModalProps?.state?.buttonLinkTitle}
            </Button>
          </div>
        )}
      </Modal.Body>
    </Modal>
  );
};
