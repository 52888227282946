import { useContext, useEffect, useRef, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useQuery, useQueryClient } from 'react-query';
import { IMAGES } from '../../../config/images';
import { MODAL_TYPES, useGlobalModalContext } from '../../../providers/globalModal.provider';
import { SessionContext } from '../../../providers/session.provider';
import {
  AccountServices,
  ContactService,
  CountryServices,
  FundServices,
  PaymentServices,
  UtilService,
} from '../../../services';
import { NewCardInformation } from '../../../types/newCardInformation';
import { NewPaypalInformation } from '../../../types/newPaypalInformation';
import { PaymentMethodCard } from '../../../types/payment';
import { QueryTypes } from '../../../types/queryTypes';
import { Formatter } from '../../../utilities';
import { WizardSteps } from '../../shared';
import {
  FlechaDer,
  FlechaIzq,
  LogoM,
  PagoSeguroVerde,
  Paypal,
  SignoRemesa,
  TarjetaCredito,
  User,
} from '../../svg';
import { ProfilePaymentMethods } from '../profilePaymentMethods';

import { Link } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import { useTopUp } from '../../../context/TopupContext';
import { ShippingInformationParams } from '../../../context/shoppingCartContextHelpers';
import { RemittanceServices } from '../../../services/remittance';
import { TopUpServices } from '../../../services/top_up';
import { AuthorizedCountry } from '../../../types/country';
import { MegaUserProfile } from '../../../types/profile';
import { RemittanceOrder } from '../../../types/remittances';
import { TopUpInternetItem, TopUpMobileItem, TopUpNautaItem } from '../../../types/topupItems';
import { CheckoutAddressWrapper } from '../checkoutAddress';
import { ShoppingCartProductList } from '../shoppingCartProductList';
import { TopUpCartInternetItem, TopUpCartMobileItem, TopUpCartNautaItem } from '../topUpCartItems';
import styles from './paymentWizard.module.scss';

export enum PaymentLine {
  funds = 'funds',
  topup = 'topup',
  combos = 'combos',
  remittance = 'remittance',
}

export enum PaymentLineTopupType {
  carrier = 'carrier',
  nauta = 'nauta',
  data = 'data',
}

export type PaymentWizardProps = {
  show: boolean;
  onCancel: Function;
  onSuccess: (primary: boolean) => void;
  paymentType: PaymentLine;
  totalToPay: number;
  serviceFee: number;
  deliveryDate?: string;
  remittanceOrder?: RemittanceOrder;

  lines: {
    title: string;
    subtitle: string;
    productCost: number;    
    deliveryCost: number;
    amountToReceive: string;
    price: number;
    futureDescription?: string;
    topupType?: PaymentLineTopupType;
    recibenDescription?: string;
    carrierName?: string;
    fullName?: string;
    phone?: string;
    carnet?: string;
    mlcCard?: string;
  }[];
};

export const PaymentWizard = (props: PaymentWizardProps) => {
  const queryCache = useQueryClient();
  const formShippingRef = useRef<any>();

  const { showModal, hideModal } = useGlobalModalContext();
  const { referralBalance, reloadReferralBalance, reloadUserBalance } = useContext(SessionContext);
  const [profile, setProfile] = useState<MegaUserProfile>();
  const profileQuery = useQuery(QueryTypes.GetProfile, AccountServices.getProfileData);
  const [createContact, setCreateContact] = useState<any | null>(null);

  useEffect(() => {
    if (profileQuery.data) {
      setProfile(profileQuery.data.user);
    }
  }, [profileQuery.data]);

  const {
    clearAfterSuccessfulPurchase,
    currentPaymentMethod,
    products,
    productsTotal,
    getProductsCount,
    shippingInformation,
    updateShippingInformation,
  } = useShoppingCart();

  const [discount, setDiscount] = useState<number>(0);
  const [isReferralBalanceApplicable, setIsReferralBalanceApplicable] = useState(false);
  const [steps, setSteps] = useState<{ title: string }[]>([]);
  const [initialStep, setInitialStep] = useState<number>(1);
  const [currentStep, setCurrentStep] = useState<number>(1);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    PaymentMethodCard | NewCardInformation | NewPaypalInformation | undefined
  >(undefined);
  const [paymentMethodType, setPaymentMethodType] = useState<
    'card' | 'paypal' | 'new-card' | 'new-paypal' | undefined
  >(undefined);

  const paymentMethodsQuery = useQuery(
    QueryTypes.GetPaymentMethods,
    PaymentServices.getPaymentMethods
  );

  const maxStep = 3;
  const [selectedCountry, setSelectedCountry] = useState<AuthorizedCountry | null>(null);

  const { internetItems, mobileItems, nautaItems, cartTotal, clearAfterSuccessfulTopup } =
    useTopUp();
  const [countLines, setCountLines] = useState(0);

  useEffect(() => {
    if (props.show) {      
      reloadReferralBalance();
      reloadUserBalance();

      switch (props.paymentType) {
        case PaymentLine.funds:
          setSteps([{ title: 'Método de Pago' }, { title: 'Resumen de Compra' }]);
          setCurrentStep(2);
          setInitialStep(2);
          setCountLines(1);
          break;

        case PaymentLine.remittance:
          setSteps([{ title: 'Método de Pago' }, { title: 'Resumen de Compra' }]);
          setCurrentStep(2);
          setInitialStep(2);
          break;

        case PaymentLine.topup:
          setSteps([{ title: 'Método de Pago' }, { title: 'Resumen de Compra' }]);
          setCurrentStep(2);
          setInitialStep(2);
          break;

        case PaymentLine.combos:
          setSteps([
            { title: 'Detalles de envío' },
            { title: 'Método de Pago' },
            { title: 'Resumen de Compra' },
          ]);
          setCurrentStep(1);
          setInitialStep(1);
          break;
      }
    }
  }, [props.show]);

  useEffect(() => {
    if (isReferralBalanceApplicable && referralBalance > 0) {
      setDiscount(referralBalance);
    } else {
      setDiscount(0);
    }
  }, [isReferralBalanceApplicable]);

  useEffect(() => {
    if (paymentMethodsQuery.status === 'success') {
      const pmList = paymentMethodsQuery.data;

      if (!pmList || !pmList.length) {
        return;
      }

      setSelectedPaymentMethod(pmList[0]);
      setPaymentMethodType(pmList[0].payment_type.toLowerCase() === 'paypal' ? 'paypal' : 'card');
    }
  }, [paymentMethodsQuery.data]);

  const onChangePaymentMethod = (
    item: PaymentMethodCard | NewCardInformation | NewPaypalInformation | undefined,
    type: 'card' | 'paypal' | 'new-card' | 'new-paypal' | undefined
  ) => {
    setSelectedPaymentMethod(item);
    setPaymentMethodType(type);
  };

  const navigateNext = () => {
    setCurrentStep(currentStep + 1);
  };

  const submitShippingForm = async () => {   

    if (!formShippingRef?.current?.isFormValid()) {
      return;
    }

    const formValues = formShippingRef?.current?.getFormValues();
    const newShippingInformation: Partial<ShippingInformationParams> = {
      firstName: formValues.firstName,
      lastName: formValues.lastName,
      address: formValues.address,
      city: formValues.town,
      provinceId: formValues.province.id,
      province: formValues.province.name,
      municipalityId: formValues.municipality.id,
      municipality: formValues.municipality.name,
      phoneNumber:
        formValues.step === 'contact' ? formValues.contact.NumeroContacto : `53${formValues.phone}`,
    };
    updateShippingInformation(newShippingInformation);    

    if (formValues.save) {
      const contact = {
        contactId: null,
        firstName: formValues.firstName,
        lastName: formValues.lastName,
        country: 'CU',
        phone: `53${formValues.phone}`,
        email: undefined,
        address: formValues.address,
        province: formValues.province.id,
        municipality: formValues.municipality.id,
        town: formValues.town,
        mlc: '',
        carnet: '',
      };

      if (formValues.step === 'contact') {
        contact.contactId = formValues.contact.id;
      }

      setCreateContact(contact);
    }

    setCurrentStep(currentStep + 1);
  };

  const onNewContactError = (isDuplicated: boolean) => {
    showModal(MODAL_TYPES.ERROR, {
      title: '¡Error!',
      description: isDuplicated
        ? 'Ya existe un contacto con el número de teléfono entrado. Por favor entre uno diferente.'
        : 'Hubo un error salvando su contacto. Por favor, intente de nuevo.',
      state: {
        buttonTitle: isDuplicated ? 'Continuar' : 'Reintentar',
        cardError: false,
      },
      onClose: (_) => {},
    });
  };

  const navigateBack = () => {
    setCurrentStep(currentStep - 1);
  };

  const getFullName = () => {
    let name = '';

    if (!selectedPaymentMethod) {
      return name;
    }

    switch (paymentMethodType) {
      case 'paypal':
      case 'card':
        name = `${(selectedPaymentMethod as PaymentMethodCard).first_name} ${
          (selectedPaymentMethod as PaymentMethodCard).last_name
        }`;
        break;

      case 'new-card':
        name = `${(selectedPaymentMethod as NewCardInformation).first_name} ${
          (selectedPaymentMethod as NewCardInformation).last_name
        }`;
        break;

      case 'new-paypal':
        name = `${(selectedPaymentMethod as NewPaypalInformation).details?.firstName} ${
          (selectedPaymentMethod as NewPaypalInformation).details?.lastName
        }`;
        break;
    }

    return name;
  };

  const getPmSubtitle = () => {
    let subtitle = '';

    if (!selectedPaymentMethod) {
      return subtitle;
    }

    switch (paymentMethodType) {
      case 'paypal':
        subtitle = (selectedPaymentMethod as PaymentMethodCard).email;
        break;

      case 'card':
        subtitle = `···· ···· ···· ${
          (selectedPaymentMethod as PaymentMethodCard).last_four_digits
        }`;
        break;

      case 'new-card':
        subtitle = (selectedPaymentMethod as NewCardInformation).title;
        break;

      case 'new-paypal':
        subtitle = (selectedPaymentMethod as NewPaypalInformation).details?.email;
        break;
    }

    return subtitle;
  };

  const calculateTotal = () => {
    const total = Number(props.totalToPay + (props.serviceFee || 0) - discount);
    return total < 0 ? '0.00' : total.toFixed(2);
  };

  const calculateDiscount = () => {
    const total = Number(props.totalToPay + (props.serviceFee || 0));

    if (discount > total) {
      return total;
    }

    return discount;
  };

  const pay = () => {
    if (props.paymentType === PaymentLine.funds) {
      payFunds();
    }
    if (props.paymentType === PaymentLine.combos) {
      payCombos();
    }
    if (props.paymentType === PaymentLine.topup) {
      payTopups();
    }
    if (props.paymentType === PaymentLine.remittance) {
      payRemittance();
    }
  };

  const payRemittance = async () => {
    const amount = Number(props.totalToPay);
    const fee = Number(props.serviceFee || 0.0);

    showModal(MODAL_TYPES.PROCESSING_PAYMENT);

    try {
      switch (paymentMethodType) {
        case 'new-card':
          await RemittanceServices.purchaseWithCreditCard({
            acceptReferral: isReferralBalanceApplicable,
            remittanceOrder: props.remittanceOrder!,
            newCardInformation: selectedPaymentMethod as NewCardInformation,
          });
          break;

        case 'new-paypal':
          await RemittanceServices.purchaseWithPaypal({
            acceptReferral: isReferralBalanceApplicable,
            remittanceOrder: props.remittanceOrder!,
            newPaypalInformation: selectedPaymentMethod as NewPaypalInformation,
          });
          break;

        case 'card':
          await RemittanceServices.purchaseWithCreditCard({
            acceptReferral: isReferralBalanceApplicable,
            remittanceOrder: props.remittanceOrder!,
            paymentMethod: selectedPaymentMethod as PaymentMethodCard,
          });
          break;

        case 'paypal':
          await RemittanceServices.purchaseWithPaypal({
            acceptReferral: isReferralBalanceApplicable,
            remittanceOrder: props.remittanceOrder!,
            paymentMethod: selectedPaymentMethod as PaymentMethodCard,
          });
      }

      hideModal();

      showModal(MODAL_TYPES.SUCCESS_PAYMENT, {
        title: 'Remesa <span class="text-secondary text-decoration-underline">exitosa</span>',
        description: '¡Muchas gracias! Su pago fue completado',
        state: 'remesas',
        secondaryButtonTitle: 'Ver reporte de Remesas',
        primaryButtonTitle: 'Seguir enviando remesas',
        onClose: (state) => {
          queryCache.invalidateQueries(QueryTypes.GetPaymentMethods);
          queryCache.invalidateQueries(QueryTypes.GetContacts);

          props.onSuccess(state === 'primary');
        },
      });
    } catch (e) {
      hideModal();
      showModal(MODAL_TYPES.ERROR, {
        title: '¡Error al pagar!',
        description:
          'No Pudimos procesar su pago.<br />Por favor, reintente el pago o seleccione otro metodo de pago',
        state: {
          buttonTitle: 'Reintentar el pago',
          buttonLinkTitle: 'Usar otro método de pago',
          cardError: true,
        },
        onClose: (state) => {
          if (state === 'primary') {
            pay();
          } else {
            setCurrentStep(1);
          }
        },
      });
    }
  };

  const payFunds = async () => {
    const amount = Number(props.totalToPay);
    const fee = Number(props.serviceFee || 0.0);

    showModal(MODAL_TYPES.PROCESSING_PAYMENT);

    try {
      switch (paymentMethodType) {
        case 'new-card':
          await FundServices.fundWithNewCreditCard({
            acceptReferral: isReferralBalanceApplicable,
            newCardInformation: selectedPaymentMethod as NewCardInformation,
            amount,
            fee,
          });
          break;

        case 'new-paypal':
          await FundServices.fundWithNewPaypal({
            acceptReferral: isReferralBalanceApplicable,
            newPaypalInformation: selectedPaymentMethod as NewPaypalInformation,
            amount,
            fee,
          });
          break;

        case 'card':
          await FundServices.fundWithCreditCard({
            acceptReferral: isReferralBalanceApplicable,
            paymentMethodId: (selectedPaymentMethod as PaymentMethodCard).id,
            amount,
            fee,
          });
          break;
        case 'paypal':
          await FundServices.fundWithPaypal({
            acceptReferral: isReferralBalanceApplicable,
            paymentMethodId: (selectedPaymentMethod as PaymentMethodCard).id,
            amount,
            fee,
          });
          break;
      }
      hideModal();

      onSuccessFundsPayment();
      showModal(MODAL_TYPES.SUCCESS_PAYMENT, {
        title: 'Saldo <span class="text-secondary text-decoration-underline">recargado</span>',
        description: '¡Muchas gracias! Su pago fue completado',
        state: 'pagos',
        secondaryButtonTitle: 'Ver reporte de Pagos',
        primaryButtonTitle: 'Seguir pagando',
        onClose: (state) => {
          queryCache.invalidateQueries(QueryTypes.GetPaymentMethods);
          props.onSuccess(state === 'primary');
        },
      });
    } catch (error) {
      hideModal();
      showModal(MODAL_TYPES.ERROR, {
        title: '¡Error al pagar!',
        description:
          'No Pudimos procesar su pago.<br />Por favor, reintente el pago o seleccione otro metodo de pago',
        state: {
          buttonTitle: 'Reintentar el pago',
          buttonLinkTitle: 'Usar otro método de pago',
          cardError: true,
        },
        onClose: (state) => {
          if (state === 'primary') {
            pay();
          } else {
            setCurrentStep(1);
          }
        },
      });
    }
  };

  const onSuccessFundsPayment = () => {
    reloadReferralBalance();
  };

  const createNewContactFlow = async () => {
    if (!createContact) {
      return false;
    }

    try {
      showModal(MODAL_TYPES.LOADING);
      const response = await ContactService.createContact(
        createContact.firstName,
        createContact.lastName,
        createContact.country,
        createContact.phone,
        createContact.email,
        createContact.address,
        createContact.province,
        createContact.municipality,
        createContact.town,
        createContact.carnet,
        createContact.mlc
      );

      hideModal();
      if (response.success) {
        return true;
      } else {
        onNewContactError(response.isDuplicated);
        return false;
      }
    } catch (e) {
      hideModal();
      onNewContactError(false);
      return false;
    }
  };

  const editContactFlow = async () => {
    if (!createContact) {
      return false;
    }

    try {
      showModal(MODAL_TYPES.LOADING);
      const response = await ContactService.editContact(
        createContact.contactId,
        createContact.firstName,
        createContact.lastName,
        createContact.email,
        createContact.address,
        createContact.province,
        createContact.municipality,
        createContact.town,
        createContact.carnet,
        createContact.mlc
      );

      hideModal();
      if (response.success) {
        return true;
      } else {
        onNewContactError(false);
        return false;
      }
    } catch (e) {
      hideModal();
      onNewContactError(false);
      return false;
    }
  };

  const payCombos = async () => {
    // lets create or update the contact
    if (createContact) {
      const response = createContact.contactId
        ? await editContactFlow()
        : await createNewContactFlow();

      if (!response) {
        return;
      }
    }

    // const amount = Number(props.totalToPay);
    // const fee = Number(props.serviceFee || 0.0);

    showModal(MODAL_TYPES.PROCESSING_PAYMENT);

    try {
      switch (paymentMethodType) {
        case 'new-card':
          await PaymentServices.purchaseWithNewCreditCard({
            newCardInformation: selectedPaymentMethod as NewCardInformation,
            products,
            productsTotal,
            shippingInformation,
            acceptReferral: isReferralBalanceApplicable,
          });
          break;

        case 'new-paypal':
          await PaymentServices.purchaseWithNewPaypal({
            newPaypalInformation: selectedPaymentMethod as NewPaypalInformation,
            products,
            productsTotal,
            shippingInformation,
            acceptReferral: isReferralBalanceApplicable,
          });
          break;

        case 'card':
          await PaymentServices.purchaseWithCreditCard({
            paymentMethod: selectedPaymentMethod as PaymentMethodCard,
            products,
            productsTotal,
            shippingInformation,
            acceptReferral: isReferralBalanceApplicable,
          });
          break;

        case 'paypal':
          await PaymentServices.purchaseWithPaypal({
            paymentMethod: selectedPaymentMethod as PaymentMethodCard,
            products,
            productsTotal,
            shippingInformation,
            acceptReferral: isReferralBalanceApplicable,
          });
          break;
      }
      hideModal();

      onSuccessCombosPayment();
      showModal(MODAL_TYPES.SUCCESS_PAYMENT, {
        title: 'Compra <span class="text-secondary text-decoration-underline">completada</span>',
        description: '¡Muchas gracias! Su pago fue completado',
        state: 'compras',
        secondaryButtonTitle: 'Ver reporte de Compras',
        primaryButtonTitle: 'Seguir comprando',
        onClose: (state) => {
          queryCache.invalidateQueries(QueryTypes.GetPaymentMethods);
          props.onSuccess(state === 'primary');
        },
      });
    } catch (error) {
      hideModal();
      showModal(MODAL_TYPES.ERROR, {
        title: '¡Error al pagar!',
        description:
          'No Pudimos procesar su pago.<br />Por favor, reintente el pago o seleccione otro metodo de pago',
        state: {
          buttonTitle: 'Reintentar el pago',
          buttonLinkTitle: 'Usar otro método de pago',
          cardError: true,
        },
        onClose: (state) => {
          if (state === 'primary') {
            pay();
          } else {
            setCurrentStep(currentStep - 1);
          }
        },
      });
    }
  };

  const onSuccessCombosPayment = () => {
    //Do something
    clearAfterSuccessfulPurchase();
  };

  const countryQuery = useQuery(
    QueryTypes.GetAuthCountries,
    CountryServices.getAllAuthorizedCountries
  );

  useEffect(() => {
    if (countryQuery.data?.length && !selectedCountry) {
      const usa = countryQuery.data.find((i) => i.count_cod?.toLowerCase() === 'us');

      setSelectedCountry(usa || countryQuery.data[0]);
    }
  }, [countryQuery.data]);

  const payTopups = async () => {
    const amount = Number(props.totalToPay);
    const fee = Number(props.serviceFee || 0.0);

    showModal(MODAL_TYPES.PROCESSING_PAYMENT);

    try {
      switch (paymentMethodType) {
        case 'new-card':
          await TopUpServices.purchaseWithNewCreditCard({
            newCardInformation: selectedPaymentMethod as NewCardInformation,
            mobileItems,
            nautaItems,
            internetItems,
            total: cartTotal,
            acceptReferral: isReferralBalanceApplicable,
          });
          break;

        case 'new-paypal':
          await TopUpServices.purchaseWithNewPaypal({
            newPayPalInformation: selectedPaymentMethod as NewPaypalInformation,
            mobileItems,
            nautaItems,
            internetItems,
            total: cartTotal,
            acceptReferral: isReferralBalanceApplicable,
          });
          break;

        case 'card':
          await TopUpServices.purchaseWithCreditCard({
            paymentMethod: selectedPaymentMethod as PaymentMethodCard,
            mobileItems,
            nautaItems,
            internetItems,
            total: cartTotal,
            acceptReferral: isReferralBalanceApplicable,
          });
          break;

        case 'paypal':
          await TopUpServices.purchaseWithPaypal({
            paymentMethod: selectedPaymentMethod as PaymentMethodCard,
            mobileItems,
            nautaItems,
            internetItems,
            total: cartTotal,
            acceptReferral: isReferralBalanceApplicable,
          });
          break;
      }
      hideModal();

      onSuccessTopupPayment();
      showModal(MODAL_TYPES.SUCCESS_PAYMENT, {
        title: 'Recarga <span class="text-secondary text-decoration-underline">completada</span>',
        description: '¡Muchas gracias! Su recarga fue completada',
        state: 'recargas',
        secondaryButtonTitle: 'Ver reporte de Recargas',
        primaryButtonTitle: 'Seguir enviando recargas',
        onClose: (state) => {
          queryCache.invalidateQueries(QueryTypes.GetPaymentMethods);
          props.onSuccess(state === 'primary');
        },
      });
    } catch (error) {
      hideModal();
      showModal(MODAL_TYPES.ERROR, {
        title: '¡Error al pagar!',
        description:
          'No Pudimos procesar su pago.<br />Por favor, reintente el pago o seleccione otro metodo de pago',
        state: {
          buttonTitle: 'Reintentar el pago',
          buttonLinkTitle: 'Usar otro método de pago',
          cardError: true,
        },
        onClose: (state) => {
          if (state === 'primary') {
            pay();
          } else {
            setCurrentStep(currentStep - 1);
          }
        },
      });
    }
  };

  const onSuccessTopupPayment = () => {
    clearAfterSuccessfulTopup();
  };

  return (
    <Modal dialogClassName="modal-fullscreen modal-payment" show={props.show}>
      <Modal.Header>
        <div className="container">
          <div className="row">
            <div className="col-6">
              <img
                src={IMAGES.ImageLogo}
                width={183}
                className={`${styles.logo} d-none d-md-block`}
                alt="Megaconecta Logo"
              />
              <LogoM width={30} className="d-block d-md-none" />
            </div>
            <div className="col-6">
              <div className="d-flex flex-row justify-content-end">
                <div className="me-4" role="button" onClick={() => props.onCancel()}>
                  <FlechaIzq width={18} className="me-2" /> Volver
                </div>
                <div>
                  <User width={20} />{' '}
                  <span className="d-none d-lg-inline">{profile?.firstName}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-4">
            <div className="col-12">
              <WizardSteps currentStep={currentStep} initialStep={initialStep} steps={steps} />
            </div>
          </div>
        </div>
        <div className={`w-100 d-flex flex-row ${styles.headerBorderWrapper}`}>
          <div className="w-50"></div>
          <div className="w-50"></div>
        </div>
      </Modal.Header>

      <Modal.Body>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-8">
              <div className={currentStep != 1 ? 'm-hide' : ''}>
                <div className={styles.paymentWrapper}>
                  <CheckoutAddressWrapper products={products} ref={formShippingRef} />
                </div>
              </div>

              <div className={currentStep != 2 ? 'm-hide' : ''}>
                <div className={styles.paymentWrapper}>
                  <h6 className="mb-4">Método de pago</h6>
                  <div>
                    <ProfilePaymentMethods onSelectPaymentMethod={onChangePaymentMethod} />

                    {!!referralBalance && referralBalance > 0 && (
                      <div className={`form-check ${styles.referralWrapper}`}>
                        <input
                          className="form-check-input mega"
                          type="checkbox"
                          name="referralBalance"
                          id="referralBalance"
                          checked={isReferralBalanceApplicable}
                          onChange={() =>
                            setIsReferralBalanceApplicable(!isReferralBalanceApplicable)
                          }
                        />
                        <label className="form-check-label" htmlFor="referralBalance">
                          Usar Comisión de Referidos para pagar. Usted tiene{' '}
                          <b>{Formatter.toCurrency(referralBalance)} disponibles</b>
                        </label>
                      </div>
                    )}
                  </div>
                </div>
              </div>

              {/* Direccion de envio */}
              <div
                className={
                  currentStep != 3 ||
                  props.paymentType != PaymentLine.combos ||
                  !selectedPaymentMethod
                    ? 'm-hide'
                    : ''
                }>
                <div className={styles.paymentWrapper}>
                  <h6 className="mb-1">Dirección de Envío</h6>
                  <div className="flex-column flex-fill">
                    <div>
                      {[
                        shippingInformation.firstName,
                        shippingInformation.lastName,
                        shippingInformation.secondLastName,
                      ].join(' ')}
                    </div>
                    <div>{[shippingInformation.address, shippingInformation.city].join(', ')}</div>
                    <div>
                      {shippingInformation.municipality}, {shippingInformation.province}
                    </div>
                    <div>{shippingInformation.phoneNumber}</div>
                    <div>{shippingInformation.email}</div>
                  </div>
                </div>
              </div>

              <div className={currentStep != 3 || !selectedPaymentMethod ? 'm-hide' : ''}>
                <div className={styles.paymentWrapper}>
                  <h6 className="mb-4">Método de pago</h6>
                  <div>
                    <div className="flex-column flex-fill">
                      <div>
                        <h6 className="m-0 p-0">
                          {paymentMethodType === 'paypal' || paymentMethodType === 'new-paypal' ? (
                            <Paypal width={20} className="me-2 mt--1" />
                          ) : (
                            <TarjetaCredito width={20} className="me-2 mt--1" />
                          )}
                          {getFullName()}
                        </h6>
                      </div>
                      <div className={styles.cc}>{getPmSubtitle()}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={props.paymentType != PaymentLine.combos ? 'm-hide' : ''}>
                <div className={styles.paymentWrapper}>
                  <h6 className="mb-4">Artículos</h6>
                  <div className="ms-3">
                    <ShoppingCartProductList isCheckout />
                  </div>
                </div>
              </div>

              <div className={props.paymentType !== PaymentLine.funds ? 'm-hide' : ''}>
                <div className={styles.paymentWrapper}>
                  <h6 className="mb-4">Recarga de Saldo</h6>

                  {props.lines.map((line, index) => (
                    <div key={index} className={styles.lineFundWrapper}>
                      <div className="me-4 d-flex flex-column justify-content-center">
                        <SignoRemesa width={40} />
                      </div>
                      <div className="d-flex flex-column">
                        <h6 className="m-0">{line.title}</h6>
                        <p className="p-0 m-0 text-secondary">{line.subtitle}</p>
                        <p className="p-0 m-0">{line.recibenDescription}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className={props.paymentType !== PaymentLine.remittance ? 'm-hide' : ''}>
                <div className={styles.paymentWrapper}>
                  <h6 className="mb-4">Remesa</h6>

                  {props.lines.map((line, index) => (
                    <div key={index} className={styles.lineFundWrapper}>
                      <div className="me-4 d-flex flex-column justify-content-center">
                        <SignoRemesa width={40} />
                      </div>
                      <div className="d-flex flex-column">
                        <div className="d-flex flex-row align-items-center">
                          <h6 className="m-0">{line.fullName}</h6>&nbsp;<span>{line.phone}</span>
                        </div>
                        <h6 className="m-0 mega-fs-mid">{line.title}</h6>
                        <p className="p-0 m-0 text-secondary">{line.subtitle}</p>
                        <p className="p-0 m-0">{line.recibenDescription}</p>
                        {line.carnet && (
                          <p className="p-0 m-0">Carnet de Identidad: {line.carnet}</p>
                        )}
                        {line.mlcCard && (
                          <p className="p-0 m-0">
                            Tarjeta MLC:{' '}
                            <span className="text-secondary">
                              {UtilService.formatCardNumber(line.mlcCard)}
                            </span>
                          </p>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <div className={props.paymentType !== PaymentLine.topup ? 'm-hide' : ''}>
                <div className={styles.paymentWrapper}>
                  <h6 className="mb-4">Recargas</h6>

                  <div className={styles.wrapperList}>
                    {mobileItems.length > 0 && (
                      <div className="">
                        {mobileItems.map((mobileItem: TopUpMobileItem, index) => (
                          <div key={`mobile-item-${index}`}>
                            <TopUpCartMobileItem
                              index={index}
                              mobileItem={mobileItem}
                              allowToDelete={false}
                              isLast={
                                index + 1 === mobileItems.length &&
                                !nautaItems.length &&
                                !internetItems.length
                              }
                            />
                          </div>
                        ))}
                      </div>
                    )}

                    {nautaItems.length > 0 && (
                      <div className="">
                        {nautaItems.map((nautaItem: TopUpNautaItem, index) => (
                          <div key={`nauta-item-${index}`}>
                            <TopUpCartNautaItem
                              index={index}
                              nautaItem={nautaItem}
                              allowToDelete={false}
                              isLast={index + 1 === nautaItems.length && !internetItems.length}
                            />
                          </div>
                        ))}
                      </div>
                    )}

                    {internetItems.length > 0 && (
                      <div>
                        {internetItems.map((internetItem: TopUpInternetItem, index) => (
                          <div key={`internet-item-${index}`}>
                            <TopUpCartInternetItem
                              index={index}
                              internetItem={internetItem}
                              allowToDelete={false}
                              isLast={index + 1 === internetItems.length}
                            />
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-lg-4">
              <div className={styles.paymentWrapper}>
                <h6 className="mb-4">Resumen</h6>                


                {props.deliveryDate && (
                  <div className="w-100 d-flex flex-row">
                    <div className="flex-fill">Fecha de entrega</div>
                    <div className="flex-fill text-end">{props.deliveryDate}</div>
                  </div>
                )}               

                {props.paymentType === PaymentLine.remittance && (
                 <div className="w-100 d-flex flex-row">
                  <div className="flex-fill">Total a Recibir</div>
                  <div className="flex-fill text-end">{props.lines[0].amountToReceive}</div>
                </div>
                )}

                {props.paymentType === PaymentLine.remittance && (
                 <div className="w-100 d-flex flex-row">
                  <div className="flex-fill">Subtotal</div>
                  <div className="flex-fill text-end">${Number(props.lines[0].productCost).toFixed(2)}</div>
                </div>
                )}               
               
               {props.paymentType !== PaymentLine.remittance && (
                <div className="w-100 d-flex flex-row">
                  <div className="flex-fill">Subtotal</div>
                  <div className="flex-fill text-end">${Number(props.totalToPay).toFixed(2)}</div>
                </div>
                 )}
                
                {props.paymentType === PaymentLine.remittance && (
                 <div className="w-100 d-flex flex-row">
                  <div className="flex-fill">Costo de Entrega</div>
                  <div className="flex-fill text-end">${Number(props.lines[0].deliveryCost).toFixed(2)}</div>
                </div>
                )}

                {discount > 0 && (
                  <div className="w-100 d-flex flex-row mt-2">
                    <div className="flex-fill">Comisión de Referidos</div>
                    <div className="flex-fill text-end text-secondary">
                      -${Number(calculateDiscount()).toFixed(2)}
                    </div>
                  </div>
                )}

                {props.serviceFee > 0 && (
                  <div className="w-100 d-flex flex-row mt-2">
                    <div className="flex-fill">Costo Adicional</div>
                    <div className="flex-fill text-end">${Number(props.serviceFee).toFixed(2)}</div>
                  </div>
                )}

                <hr />
                <div className="w-100 d-flex flex-row">
                  <div className="flex-fill">
                    <h6>Total a pagar</h6>
                  </div>
                  <div className="flex-fill text-end">${calculateTotal()}</div>
                </div>
              </div>

           

              {props.paymentType === PaymentLine.combos && (
                <div>
                  <div className={`${styles.description_frame}`}>
                    <div className={`${styles.description_line}`}>
                      <div className={`${styles.description_title}`}>Información de envío</div>
                    </div>
                    <div className={`${styles.description_line}`}>
                      <div className={styles.description_left}>
                        <div className={styles.dot}></div>
                      </div>
                      <div className={styles.description_text}>
                        El tiempo de entrega varía según el tipo de producto. Consultar detalles del
                        producto.
                      </div>
                    </div>
                    <div className={`${styles.description_line}`}>
                      <div className={styles.description_left}>
                        <div className={styles.dot}></div>
                      </div>
                      <div className={styles.description_text}>
                        Los productos serán entregados al domicilio del destinatario. Se solicitará
                        el carnet de identidad y será verificado con los datos del remitente
                        introducidos en el formulario.
                      </div>
                    </div>
                  </div>
                  <div className={`mt-3 ${styles.description_line}`}>
                    <div className={styles.description_text}>
                      Al hacer clic en &quot;Pagar&quot;, confirma que ha leído y acepta todos los{' '}
                      <Link
                        to={ROUTES.termsAndConditions}
                        className="text-secondary text-decoration-underline">
                        Términos y condiciones.
                      </Link>
                      , he revisado los productos a comprar, la dirección y nombre del contacto a
                      enviar y confirmo que este servicio no admite rembolso una vez completado el
                      pago.
                    </div>
                  </div>
                </div>
              )}

              {(props.paymentType === PaymentLine.topup) && (
                <div className={`mt-3 ${styles.description_line}`}>
                  <div className={styles.description_text}>
                    Al hacer clic en &quot;Pagar&quot;, confirma que ha leído y acepta todos los{' '}
                    <Link
                      to={ROUTES.termsAndConditions}
                      className="text-secondary text-decoration-underline">
                      Términos y condiciones.
                    </Link>
                    , he revisado cada número a recargar y confirmo que este servicio no admite
                    rembolso una vez completado el pago.
                  </div>
                </div>
              )}

              {(props.paymentType === PaymentLine.remittance) && (
                <div className={`mt-3 ${styles.description_line}`}>
                  <div className={styles.description_text}>
                    Al hacer clic en &quot;Pagar&quot;, confirma que ha leído y acepta todos los{' '}
                    <Link
                      to={ROUTES.termsAndConditions}
                      className="text-secondary text-decoration-underline">
                      Términos y condiciones.
                    </Link>
                    , he revisado la dirección a entregar y confirmo que este servicio no admite 
                    rembolso una vez entregado el pago.
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal.Footer>
        <div className="container">
          <div className="row d-flex">
            <div className="col-12 col-lg-6 order-0 order-lg-2 d-flex flex-row align-items-center justify-content-lg-end">
              {currentStep > initialStep && (
                <Button
                  variant="mega_secondary_2"
                  className={styles.backButton}
                  onClick={navigateBack}>
                  <FlechaIzq width={18} fill={'#8ab934'} className="me-1" />
                  Atras
                </Button>
              )}

              {currentStep == 1 && (
                <Button
                  type="submit"
                  form="hook-form"
                  variant="secondary"
                  className={styles.continueButton}
                  onClick={submitShippingForm}
                  disabled={false}>
                  Continuar <FlechaDer width={18} fill={'#ffffff'} className="ms-1" />
                </Button>
              )}

              {currentStep != 1 && currentStep != maxStep && (
                <Button
                  variant="secondary"
                  className={styles.continueButton}
                  onClick={navigateNext}
                  disabled={!selectedPaymentMethod}>
                  Continuar <FlechaDer width={18} fill={'#ffffff'} className="ms-1" />
                </Button>
              )}

              {currentStep == maxStep && (
                <Button variant="secondary" className={styles.payButton} onClick={pay}>
                  Pagar
                  <FlechaDer width={18} fill={'#ffffff'} className="ms-1" />
                </Button>
              )}
            </div>

            <div className="col-12 col-lg-6 order-1 mt-2 mt-lg-0">
              <div className="d-flex flex-row">
                <div className="me-3 d-flex flex-column justify-content-center">
                  <PagoSeguroVerde width={45} />
                </div>
                <div className="d-flex flex-column">
                  <h6 className={`m-0 ${styles.infoPago}`}>Pago 100% seguro</h6>
                  <p className={`m-0 ${styles.pPago}`}>
                    Su información de pago está segura con nosotros
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
