import { Button, Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { SmallSpinner } from '../../external/smallSpinner/smallSpinner';

import styles from './checkoutActions.module.scss';

type CheckoutActionsProps = {
  label: string;
  onBackClick: Function;
  onClick: Function;
  className?: string;
  continueDisabled?: boolean;
  disabled?: boolean;
  processing?: boolean;
};

export const CheckoutActions = ({
  label,
  onBackClick,
  onClick,
  className,
  continueDisabled = false,
  disabled = false,
  processing = false,
}: CheckoutActionsProps) => {
  const navigate = useNavigate();

  return (
    <Row className={className}>
      <Col>
        <Button className={styles.button} disabled={disabled} onClick={() => onBackClick()}>
          Atrás
        </Button>
      </Col>
      <Col className="text-end">
        <Button
          className={styles.button}
          disabled={disabled || continueDisabled}
          variant="secondary"
          onClick={() => onClick()}>
          {processing ? <SmallSpinner /> : label}
        </Button>
      </Col>
    </Row>
  );
};
