import { useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import {
  IconOptionCarrier,
  IconOptionContact,
  IconSingleValueCarrier,
  IconSingleValueContact,
  reactSelectStyles,
} from '../../../../config/reactSelect';
import { MegaContact } from '../../../../types/contacts';
import { MegaCarrier, MegaCarrierAllRates, MegaCarrierRate } from '../../../../types/topups';

import { toLower } from 'lodash';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { useTopUp } from '../../../../context/TopupContext';
import { UtilService } from '../../../../services';
import { CarrierServices } from '../../../../services/carrier';
import { ValidationServices } from '../../../../services/validation';
import { QueryTypes } from '../../../../types/queryTypes';
import { TopUpInternetItem } from '../../../../types/topupItems';
import { MegaLoading } from '../../../external';
import { TopupProducts, TopupProductsProps } from '../../topupProducts';
import { TopUpAddButton } from '../topupAddButton';
import { AlertHelper } from '../../../../utilities/AlertHelper';

export type ContactsProps = {
  selectedContact: MegaContact | null;
  contacts: MegaContact[];
};


export const TopUpMobileContacts = (props: ContactsProps) => {

  console.log(props);
  const [selectedContact, setSelectedContact] = useState<MegaContact>(props.selectedContact!);
  const [contactCarrierList, setContactCarrierList] = useState<MegaCarrier[]>();
  const [preferredCarrierId, setPreferredCarrierId] = useState<string>();
  const [selectedContactCarrier, setSelectedContactCarrier] = useState<MegaCarrier>();
  const [contactCarrierRateList, setContactCarrierRateList] = useState<MegaCarrierRate[]>();
  const [selectedContactCarrierRate, setSelectedContactCarrierRate] = useState<MegaCarrierRate>();
  const [isPhoneBlacklisted, setIsPhoneBlacklisted] = useState<boolean>(false);
  const [productList, setProductList] = useState<TopupProductsProps['products']>([]);
  const [prevContact, setPrevContact] = useState<MegaContact | null>(null);
  const [isLoadingCarrier, setIsLoadingCarrier] = useState<boolean>(false);
  const [isLoadingRates, setIsLoadingRates] = useState<boolean>(false);

  const { 
    addInternetItem,
    setTopUpInitialData,
    topUpInitialData,
  } = useTopUp();1  

  const contactCarriersQuery = useQuery(
    [QueryTypes.GetTopUpCarriers],
    () => {
      if (selectedContact) {
        setIsLoadingCarrier(true);
        setIsLoadingRates(true);
        return CarrierServices.getCarriers(selectedContact.country_code, 'TOPUP');
      } else {
        return [];
      }
    },
    {
      onSettled: () => {
        setIsLoadingCarrier(false);
      },
    }
  );

  const contactCarrierRatesQuery = useQuery(
    [QueryTypes.GetTopUpCarrierRates, selectedContactCarrier],
    async () => {
      if (selectedContactCarrier) {
        setIsLoadingRates(true);
        return CarrierServices.getCarrierRates(selectedContactCarrier.uid);
      } else {
        return [];
      }
    },
    {
      cacheTime: 0,
      onSettled: () => {
        setIsLoadingRates(false);
      },
    }
  );

  const preferredContactCarrierQuery = useQuery([QueryTypes.GetCarrierByPhone], () => {
    if (selectedContact) {
      return CarrierServices.getCarrierByPhoneAndCategory(
        selectedContact.contact_number,
        'BUNDLES'
      );
    }
  });

  const blacklistedPhoneQuery = useQuery([QueryTypes.GetBlacklistedPhone], () => {
    if (selectedContact) {
      return ValidationServices.checkBlacklistedPhone(selectedContact?.contact_number);
    } else {
      return false;
    }
  });

  

  useEffect(() => {
    if (!!selectedContact) {
      setSelectedContactCarrier(undefined);

      if (!prevContact || prevContact.country_code !== selectedContact.country_code) {
        setContactCarrierList(undefined);
        contactCarriersQuery.refetch();
      }

      preferredContactCarrierQuery.refetch();
      blacklistedPhoneQuery.refetch();
    }
  }, [selectedContact]);

  useEffect(() => {
    if (!!selectedContactCarrier) {
      contactCarrierRatesQuery.refetch();
    }
  }, [selectedContactCarrier]);

  useEffect(() => {
    if (contactCarriersQuery.status === 'success') {
      setSelectedContactCarrier(undefined);
      setContactCarrierList(contactCarriersQuery.data);
      setSelectedContactCarrier(
        getPreferredCarrier(contactCarriersQuery.data) || contactCarriersQuery.data[0]
      );
    }
  }, [contactCarriersQuery.data]);

  useEffect(() => {    
    if (contactCarrierRatesQuery.status === 'success') {  
      let rateData = contactCarrierRatesQuery.data as MegaCarrierAllRates; 
      if (rateData?.initial_data){
        setTopUpInitialData(rateData?.initial_data);
        let rateList = rateData.products;                   
        convertProductList(rateList);      
        setContactCarrierRateList(rateList);
        setSelectedContactCarrierRate(rateList[0]);
      }
    }
  }, [contactCarrierRatesQuery.data]);

  useEffect(() => {
    if (preferredContactCarrierQuery.status === 'success') {
      setPreferredCarrierId(preferredContactCarrierQuery.data);
    }
  }, [preferredContactCarrierQuery.data]);

  useEffect(() => {
    if (!!contactCarrierList) {
      const preferredCarrier = getPreferredCarrier(contactCarrierList!);

      setSelectedContactCarrier(preferredCarrier || contactCarrierList[0]);
    }
  }, [preferredCarrierId, contactCarrierList]);

  const getPreferredCarrier = (carriers: MegaCarrier[]) => {
    if (preferredCarrierId) {
      return carriers.find((carrier) => `${preferredCarrierId}` === `${carrier.uid}`);
    }
  };

  useEffect(() => {
    if (blacklistedPhoneQuery.status === 'success') {
      setIsPhoneBlacklisted(blacklistedPhoneQuery.data);
    }
  }, [blacklistedPhoneQuery.data]);

  const getWarningMessage = () => {
    if (isPhoneBlacklisted) {
      return 'No es posible recargar a este número. Por favor comunícate con soporte técnico para más información.';
    }

    return false;
  };

  const onSelectedProduct = (id: string) => {    
  };

  const onAddTopup = (id: string) => {             
    const rate = contactCarrierRateList?.find((r) => `${r.id}` === `${id}`);
    if (rate) {
      try {        
        addInternetItem({
          carrier: selectedContactCarrier,
          carrierRate: rate,
          contact: selectedContact,
          futurePromotionalDate: rate.future_date,
          type: 'contact',
        } as TopUpInternetItem);
      } catch (e: any) {
        AlertHelper.show({ icon: 'error', title: 'Error', text: e.toString() });
      }
    }    
  };

  const convertProductList = (list: MegaCarrierRate[]) => {   
    const newProductList: TopupProductsProps['products'] = UtilService.convertProductList(list);
    setProductList(newProductList);
  };
  
  const warningMessage = getWarningMessage();

  
  useEffect(() => {
    setSelectedContact(props.selectedContact!);
  }, [props.selectedContact]);


  return (
    <>
      <Row className="mt-4">
        <Col className="mt-3">
          <div className="megaInput">
            <label htmlFor="contactBundle">Mis Contactos</label>
            <Select
              id="contactBundle"
              placeholder=""
              value={selectedContact}
              options={props.contacts}
              isSearchable={false}
              isClearable={false}
              isLoading={false}
              className="w-100 mega-select"
              isMulti={false}
              styles={reactSelectStyles}
              components={{
                Option: IconOptionContact,
                SingleValue: IconSingleValueContact,
              }}
              onChange={(newValue) => {
                setPrevContact(selectedContact);
                setSelectedContact(newValue as MegaContact);
              }}
            />
          </div>
        </Col>
      </Row>

      {!isLoadingCarrier && (
        <div>
          {!!selectedContact && toLower(selectedContact.country_code) !== 'cu' && (
            <Row>
              <Col className="mt-4">
                <div className="megaInput">
                  <label htmlFor="carrier">Operador</label>
                  <Select
                    id="carrier"
                    placeholder=""
                    value={selectedContactCarrier}
                    options={contactCarrierList}
                    isSearchable={false}
                    isClearable={false}
                    isLoading={false}
                    className="w-100 mega-select"
                    isMulti={false}
                    styles={reactSelectStyles}
                    components={{
                      Option: IconOptionCarrier,
                      SingleValue: IconSingleValueCarrier,
                    }}
                    onChange={(newValue) => {
                      setSelectedContactCarrier(newValue as MegaCarrier);
                    }}
                  />
                </div>
              </Col>
            </Row>
          )}
        </div>
      )}

      {warningMessage && (
        <Alert variant={'warning'} className="mt-2">
          <FaExclamationTriangle /> {warningMessage}
        </Alert>
      )}

      <Row className="mt-3">
        <Col>
          <hr />
        </Col>
      </Row>

      {(isLoadingCarrier || isLoadingRates) && <MegaLoading isLoading size={50} />}

      {!isLoadingRates && !isLoadingCarrier && (
        <Row className="mt-3">
          <Col>
            {!!productList && productList.length > 0 && !!selectedContactCarrierRate && (
              <TopupProducts
                serviceType="topup"
                selectedId={selectedContactCarrierRate.id}
                products={productList}
                onSelectedChange={onSelectedProduct}
                onAddTopup={onAddTopup}
              />
            )}
          </Col>
        </Row>
      )}
    </>
  );
};
