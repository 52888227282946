import { MegaCallReportItem } from '../types/reports/callReportItem';
import { MegaFundReportItem } from '../types/reports/fundReportItem';
import { MegaRemittanceReportItem } from '../types/reports/remittanceReportItem';
import { MegaSmsReportItem } from '../types/reports/smsReportItem';
import { MegaStoreOrder } from '../types/reports/storeOrder';
import { MegaStoreReportItem } from '../types/reports/storeReportItem';
import { MegaTopUpReportItem } from '../types/reports/topUpReportItem';
import api from './axios/api';

interface DateReportParams {
  startDate: Date;
  endDate: Date;
}

interface DateAndPhoneNumberReportParams {
  startDate: Date;
  endDate: Date;
  phoneNumber: string;
}

const getFundReport = async ({
  startDate,
  endDate,
}: DateReportParams): Promise<MegaFundReportItem[]> => {
  const url = '/api/v1/funds/getfundreport';
  const response = await api.get<MegaFundReportItem>(url, {
    params: {
      start_date: startDate.toLocaleDateString('en-US'),
      end_date: endDate.toLocaleDateString('en-US'),
      sort_dir: 'DESC',
      sort_field: 'DATELOG',
    },
  });

  if (!response.has_error && !!response.array) {
    return response.array;
  }

  throw new Error(response.error);
};

const getCallReport = async ({
  startDate,
  endDate,
  phoneNumber,
}: DateAndPhoneNumberReportParams): Promise<MegaCallReportItem[]> => {
  const url = '/api/v1/calls/getcallreport';
  const response = await api.get<MegaCallReportItem>(url, {
    params: {
      start_date: startDate.toLocaleDateString('en-US'),
      end_date: endDate.toLocaleDateString('en-US'),
      search_phone: phoneNumber,
      sort_dir: 'DESC',
      sort_field: 'CALL_DISCONNECT_TIME',
    },
  });

  if (!response.has_error && response.array) {
    return response.array;
  }

  throw new Error(response.error);
};

const getTopUpReport = async ({
  startDate,
  endDate,
  phoneNumber,
}: DateAndPhoneNumberReportParams): Promise<MegaTopUpReportItem[]> => {
  const url = '/api/v1/topups/gettopupreport';
  const response = await api.get<MegaTopUpReportItem>(url, {
    params: {
      start_date: startDate.toLocaleDateString('en-US'),
      end_date: endDate.toLocaleDateString('en-US'),
      search_phone: phoneNumber,
      sort_dir: 'DESC',
      sort_field: 'TRANSACTION_TIMESTAMP',
    },
  });

  if (!response.has_error && response.array) {
    return response.array;
  }

  throw new Error(response.error);
};

const getSmsReport = async ({
  startDate,
  endDate,
  phoneNumber,
}: DateAndPhoneNumberReportParams): Promise<MegaSmsReportItem[]> => {
  const url = '/api/v1/sms/getsmsreport';
  const response = await api.get<MegaSmsReportItem>(url, {
    params: {
      start_date: startDate.toLocaleDateString('en-US'),
      end_date: endDate.toLocaleDateString('en-US'),
      search_phone: phoneNumber,
      sort_dir: 'DESC',
      sort_field: 'INITIATION_TIME',
    },
  });

  if (!response.has_error && response.array) {
    return response.array;
  }

  throw new Error(response.error);
};

const getStoreReport = async ({
  startDate,
  endDate,
}: DateReportParams): Promise<MegaStoreReportItem[]> => {
  const url = '/api/v1/store/getstorereport';
  const response = await api.get<MegaStoreReportItem>(url, {
    params: {
      start_date: startDate.toLocaleDateString('en-US'),
      end_date: endDate.toLocaleDateString('en-US'),
      sort_field: 'createdDate',
      sort_dir: 'DESC',
    },
  });

  if (!response.has_error && response.array) {
    return response.array;
  }

  throw new Error(response.error);
};

const getStoreReportDetails = async (orderId: number): Promise<MegaStoreOrder> => {
  const url = '/api/v1/store/getstorereportdetails';
  const response = await api.get<MegaStoreOrder>(url, {
    params: {
      order_id: orderId,
    },
  });

  if (!response.has_error && response.data) {
    return response.data;
  }

  throw new Error(response.error);
};

const getRemittanceReport = async ({
  startDate,
  endDate,
}: DateReportParams): Promise<MegaRemittanceReportItem[]> => {
  const url = '/api/v1/remittance/getremittancereport';
  const response = await api.get<MegaRemittanceReportItem>(url, {
    params: {
      start_date: startDate.toLocaleDateString('en-US'),
      end_date: endDate.toLocaleDateString('en-US'),
      sort_field: 'created',
      sort_dir: 'DESC',
    },
  });

  if (!response.has_error && response.array) {
    return response.array;
  }

  throw new Error(response.error);
};

const getRemittanceReportDetails = async (id: number): Promise<MegaRemittanceReportItem> => {
  const url = '/api/v1/remittance/details';
  const response = await api.get<MegaRemittanceReportItem>(url, {
    params: {
      id,
    },
  });

  if (!response.has_error && response.data) {
    return response.data;
  }

  throw new Error(response.error);
};

export const ReportsService = {
  getCallReport,
  getFundReport,
  getSmsReport,
  getStoreReport,
  getStoreReportDetails,
  getTopUpReport,
  getRemittanceReport,
  getRemittanceReportDetails,
};
