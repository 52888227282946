import * as React from 'react';
import { SVGProps } from 'react';
const SvgPromociones = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="promociones_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    {...props}>
    <defs>
      <style>{'.promociones_svg__cls-3{fill:#263645}'}</style>
    </defs>
    <g id="promociones_svg__Capa_1-2" data-name="Capa 1">
      <path
        d="M56.6 15.63c-.19-.37-1.37-2.09-1.74-2.63-2.47-3.62-5.57-6.8-9.16-9.38-1.66-1.2-4.13-1.2-5.8 0a37.635 37.635 0 0 0-9.2 9.43c-.33.49-1.71 2.45-1.97 3.08-1.05 2.51-1.05 6.79-1.05 14.05q0 17.64 15.11 17.64T57.9 30.18c0-7.77 0-12.12-1.29-14.55Z"
        style={{
          fill: '#d4e1fd',
        }}
      />
      <path
        d="M29.59 9.05c-.46-.23-2.86-1.1-3.62-1.37A46.37 46.37 0 0 0 10.11 4.9c-2.52 0-4.98 1.77-5.78 4.16a46.202 46.202 0 0 0-2.4 16c.02.73.06 3.66.24 4.48.75 3.26 3.82 7.52 9.02 14.75q12.65 17.58 27.7 6.74t2.4-28.41c-5.57-7.74-8.69-12.07-11.72-13.57Zm-13.94 9.67a3.41 3.41 0 1 1-3.98-5.54 3.41 3.41 0 1 1 3.98 5.54Z"
        style={{
          fill: '#5586f7',
        }}
      />
      <path
        className="promociones_svg__cls-3"
        d="M57.56 15.12c-.15-.29-.66-1.05-1.75-2.65l-.05-.08c-2.54-3.74-5.72-6.98-9.43-9.65-2.05-1.47-5.02-1.47-7.07 0a39.017 39.017 0 0 0-7.18 6.69c-.68-.57-1.34-1.02-2.01-1.35-.34-.17-1.43-.58-3.62-1.38l-.11-.04a47.344 47.344 0 0 0-16.2-2.84h-.03c-2.99 0-5.85 2.06-6.81 4.9A47.407 47.407 0 0 0 .85 25.09v.43c.05 2.08.12 3.6.27 4.27.8 3.47 3.78 7.62 9.2 15.14 6.47 9 9.72 13.51 13.58 14.45.51.12 1.02.19 1.54.19 3.49 0 7.33-2.76 14.1-7.64 1.53-1.1 2.91-2.09 4.14-3.01 7.27 0 11.01-.1 13.14-2.58 2.15-2.51 2.15-7.07 2.15-16.14 0-7.99 0-12.39-1.42-15.06ZM38.28 50.15c-7.25 5.22-10.88 7.83-13.86 7.11-3.07-.75-6.36-5.32-12.33-13.61-5.08-7.05-8.14-11.31-8.85-14.36-.14-.59-.19-3.03-.21-3.83v-.44c-.16-5.29.63-10.55 2.34-15.62.65-1.95 2.69-3.41 4.75-3.42h.03c5.32 0 10.52.91 15.46 2.71l.11.04c2.49.91 3.22 1.2 3.4 1.29 2.79 1.38 6 5.84 11.32 13.23 5.97 8.29 9.25 12.86 8.99 16.01-.26 3.05-3.9 5.67-11.14 10.89Zm16.9-5.25c-1.26 1.48-3.89 1.76-8.67 1.81 3.19-2.61 4.85-4.69 5.07-7.27.33-3.95-2.92-8.47-9.39-17.46-3.66-5.09-6.26-8.7-8.53-11.07 1.99-2.41 4.29-4.56 6.87-6.42.64-.46 1.45-.7 2.26-.7s1.62.23 2.26.7a36.33 36.33 0 0 1 8.9 9.11l.05.08c1.21 1.78 1.55 2.31 1.62 2.45 1.16 2.19 1.16 6.65 1.16 14.04 0 8.27 0 12.83-1.63 14.73Z"
      />
      <path
        className="promociones_svg__cls-3"
        d="M11.03 12.3c-.97.7-1.62 1.74-1.81 2.93a4.48 4.48 0 0 0 .79 3.35c.7.97 1.74 1.62 2.93 1.81a4.476 4.476 0 0 0 3.35-.79c.97-.7 1.62-1.74 1.81-2.93a4.48 4.48 0 0 0-.79-3.35 4.505 4.505 0 0 0-6.28-1.02Zm4.92 4.02c-.1.61-.43 1.15-.94 1.51-.5.36-1.12.51-1.73.41-.61-.1-1.15-.43-1.51-.94-.36-.5-.51-1.12-.41-1.73s.43-1.15.94-1.51a2.293 2.293 0 0 1 1.73-.41c.61.1 1.15.43 1.51.94.36.5.51 1.12.41 1.73Z"
      />
      <path
        d="M18.74 28.71c1.44.31 2.59 1.13 3.43 2.44s1.11 2.67.8 4.1c-.32 1.41-1.12 2.54-2.4 3.37s-2.67 1.11-4.09.83c-1.41-.32-2.54-1.12-3.39-2.43s-1.12-2.69-.84-4.11c.31-1.44 1.1-2.57 2.41-3.41s2.64-1.09 4.08-.78Zm-2.94 6.52c.73 1.13 2.05 1.48 3.1.8 1.03-.66 1.25-2.01.52-3.14-.73-1.13-2.06-1.5-3.09-.84s-1.26 2.05-.53 3.18Zm12.1-10.62 1.64 20.04c.06.68-.27 1.33-.83 1.7-1.17.76-2.72 0-2.84-1.4l-1.61-20.04c-.05-.67.26-1.32.83-1.68 1.16-.75 2.71 0 2.82 1.39Zm9.41 5.58c1.44.31 2.57 1.1 3.41 2.41.85 1.31 1.12 2.69.8 4.1-.3 1.39-1.09 2.53-2.4 3.37s-2.67 1.11-4.09.83c-1.39-.3-2.54-1.12-3.39-2.43s-1.12-2.69-.84-4.11c.31-1.44 1.1-2.57 2.41-3.41s2.64-1.09 4.09-.76Zm-2.96 6.5c.73 1.13 2.06 1.5 3.11.82s1.23-2.03.5-3.16-2.05-1.48-3.07-.81-1.27 2.02-.54 3.15Z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);
export default SvgPromociones;
