import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import { useQuery } from 'react-query';
import Select, { SingleValue } from 'react-select';
import * as yup from 'yup';
import { MegaError, PageTitle } from '../../../components/account';
import { SmallSpinner } from '../../../components/external';
import { EnviarRemesa } from '../../../components/svg';
import {
  IconOptionContact,
  IconOptionMunicipality,
  IconOptionProvince,
  IconOptionRemittanceMethod,
  IconSingleValueContact,
  IconSingleValueMunicipality,
  IconSingleValueProvince,
  IconSingleValueRemittanceMethod,
  reactSelectStyles,
} from '../../../config/reactSelect';
import { useFindPreSelectedContact } from '../../../hooks/useFindPreSelectedContact';
import { ContactService, UtilService } from '../../../services';
import { StoreService } from '../../../services/store';
import { MegaContact } from '../../../types/contacts';
import { Municipality } from '../../../types/municipality';
import { Province } from '../../../types/province';
import { QueryTypes } from '../../../types/queryTypes';
import { RemittanceMethod } from '../../../types/remittanceMethod';
import { FormHelpers } from '../../../utilities/FormHelpers';
import { errorMessages } from '../../../utilities/errorMessages';

import { toLower } from 'lodash';
import { MODAL_TYPES, useGlobalModalContext } from '../../../providers/globalModal.provider';
import { RemittanceServices } from '../../../services/remittance';
import { RemittanceOrder } from '../../../types/remittances';
import {
  RemittanceProductsScreen,
  RemittanceProductsScreenProps,
} from './remittance-products-screen';
import styles from './remittance-screen.module.scss';

const deliverySchema = yup
  .object()
  .shape({
    method: yup.object().required(errorMessages.deliveryMethod),
    fullName: yup.string().required(errorMessages.fullName),
    phone: yup
    .string()
    .matches(/^[0-9]+$/, {
      message: 'Este campo debe contener solo números.',
      excludeEmptyString: true,
    })
    .test('length', 'Este campo debe tener al menos 6 dígitos.', (value) => {
      if (!value) {
        return true;
      }

      if (`${value}`.length < 6) {
        return false;
      }

      return true;
    })
    .required(errorMessages.phoneNumber),  
    mlcCard: yup.string().when('method.id', {
      is: 'mlc',
      then: yup.string().required(errorMessages.mlcCard),
      otherwise: yup.string().nullable().optional(),
    }),
    address: yup.string().matches(/^[a-zA-Z0-9 #%]+$/, "Este campo no debe contener carácteres especiales. ").required(errorMessages.address1),
    town: yup.string().nullable().transform((curr: any, orig: any) => (orig === "" ? null : curr)).matches(/^[a-zA-Z0-9 #%]+$/, "Este campo es opcional y no debe contener carácteres especiales."),
    municipality: yup.object().required(errorMessages.municipality),
    province: yup.object().required(errorMessages.province),
    carneId: yup
      .string()
      .matches(/^[0-9]+$/, {
        message: 'Este campo debe contener solo números.',
        excludeEmptyString: true,
      })
      .test('length', 'Este campo debe tener 11 dígitos.', (value) => {
        if (!value) {
          return true;
        }

        if (`${value}`.length !== 11) {
          return false;
        }

        return true;
      })
      .required(errorMessages.idCard),
    saveRemittance: yup.boolean().optional(),
  })
  .required();

export const RemittanceScreen = () => {
  const findPreSelectedContact = useFindPreSelectedContact();
  const { showModal, hideModal } = useGlobalModalContext();

  const [contactList, setContactList] = useState<MegaContact[]>([]);
  const [selectedContact, setSelectedContact] = useState<MegaContact | null>(null);

  const {
    register,
    getValues,
    control,
    watch,
    handleSubmit,
    formState,
    setValue,
    clearErrors,
    reset,
  } = useForm({
    resolver: yupResolver(deliverySchema),
    mode: 'onChange',
  });

  const selectedProvince = watch('province');
  const selectedMunicipality = watch('municipality');
  const selectedRemittanceMethod = watch('method');

  const [tabStep, setTabStep] = useState<'contact' | 'other'>('contact');
  const [remittanceStep, setRemittanceStep] = useState<'info' | 'products'>('info');
  const [remittanceOrder, setRemittanceOrder] = useState<Partial<RemittanceOrder> | null>(null);
  const [isSubmit, setIsSubmit] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const remittanceMethods: RemittanceMethod[] = [
    {
      id: 'mlc',
      name: 'Transferencia a tarjeta MLC',
    },
    {
      id: 'cash',
      name: 'Entrega en efectivo en domicilio',
    },
  ];

  const [provinces, setProvinces] = useState<Province[]>([]);
  const [municipalities, setMunicipalities] = useState<Municipality[]>([]);

  const contactQuery = useQuery(QueryTypes.GetContacts, ContactService.getContacts);
  const provinceQuery = useQuery(QueryTypes.GetStoreProvinces, StoreService.getProvinces);
  const municipalitiesQuery = useQuery(
    [QueryTypes.GetStoreMunicipalities, selectedProvince],
    () => {
      if (!!selectedProvince) {
        return StoreService.getMunicipalities(selectedProvince.id);
      }

      return [];
    }
  );

  useEffect(() => {
    if (contactList && contactList.length) {
      findPreSelectedContact({
        contacts: contactList,
        onPreSelectedContactFound: (c) => {
          setRemittanceStep('info');
          setTabStep('contact');          
          setSelectedContact(c);
          onChangeSelectedContact(c);
        },
      });
    }
  }, [contactList]);

  useEffect(() => {
    if (tabStep === 'other'){
      setValue('fullName', '');
      setValue('phone', '');
      setValue('mlcCard', '');
      setValue('address', '');
      setValue('town', '');      
      setValue('province', undefined);      
      setValue('carneId', '');      
      setValue('saveRemittance', false);
    }
    else
    {
      setSelectedContact(null);
    }
  }, [tabStep]);

  useEffect(() => {
    if (provinceQuery.status === 'success' && provinceQuery.data.length) {
      setProvinces(provinceQuery.data);
    }
  }, [provinceQuery.status]);

  useEffect(() => {     
    setValue('municipality', undefined);
  }, [selectedProvince]);

  useEffect(() => {
    if (municipalitiesQuery.data) {
      setMunicipalities(municipalitiesQuery.data);
      setValue(
        'municipality',
        selectedContact && tabStep === 'contact'
          ? municipalitiesQuery.data.find((t) => t.id === selectedContact.municipality_id)
          : undefined
      );

      clearErrors('municipality');
    }
  }, [municipalitiesQuery.data]);

  useEffect(() => {    
    if (contactQuery.status === 'success' && contactQuery.data.length) {      
      setContactList(contactQuery.data.filter((item) => toLower(item.country_code) === 'cu'));        
    }        
  }, [contactQuery.status]);

  useEffect(() => {    
    contactList.length === 0 ? setTabStep('other') : setTabStep('contact');
  }, [contactList]);

  const onChangeSelectedContact = (sContact: SingleValue<MegaContact> | MegaContact) => {
    if (!sContact) {
      return;
    }

    setValue('fullName', sContact.contact_name || undefined);
    setValue('phone', sContact.contact_number || undefined);    
    setValue('carneId', sContact.carnet || undefined);    
    setValue('mlcCard', sContact.mlc_card || undefined);
    setValue('address', sContact.address || undefined);
    setValue('town', sContact.town || undefined);

    setValue(
      'province',
      provinces.find((item) => item.id === sContact.province_id)
    );
    setValue(
      'municipality',
      municipalities.find((item) => item.id === sContact.municipality_id)
    );

    clearErrors();
    setSelectedContact(sContact);
  };

  const getReceiverInfo = (): RemittanceProductsScreenProps['receiver'] => {
    const data = getValues();

    return {
      name: data.fullName,
      address: `${data.address}, ${data.town}, ${selectedMunicipality.name}, ${selectedProvince.name}`,
      carnet: data.carneId,      
      phone: data.phone,     
      mlcCard: selectedRemittanceMethod?.id === 'mlc' ? data.mlcCard : '',
      provinceId: selectedProvince.id,
      municipalityId: selectedMunicipality.id,
    };
  };

  const validateMlcCard = async (number: string) => {
    showModal(MODAL_TYPES.LOADING);

    const isValid = await RemittanceServices.validateMlc(number);

    hideModal();

    if (!isValid) {
      showModal(MODAL_TYPES.ERROR, {
        title: '¡Error de tarjeta MLC!',
        description:
          'Su tarjeta MLC no contiene un formato válido. Por favor, verifique su tarjeta e intente de nuevo',
        state: {
          buttonTitle: 'Continuar',
          cardError: true,
        },
        onClose: () => {},
      });
      return false;
    }

    return true;
  };

  const resetPage = () => {
    reset();

    setErrorMessage('');
    setTabStep('contact');
    setRemittanceOrder(null);
    setRemittanceStep('info');
  };

  if (UtilService.isErrorQueries([contactQuery])) {
    return <MegaError displayRetry retry={() => UtilService.refetchQueries([contactQuery])} />;
  }

  const onSubmit = async (data: FieldValues) => {
    let isMlcValid = true;
    if (data.method.id === 'mlc') {
      isMlcValid = await validateMlcCard(data.mlcCard);
    }

    if (!isMlcValid) {
      return;
    }

    const order: Partial<RemittanceOrder> = {
      address: data.address,
      carnetId: data.carneId,
      fullName: data.fullName,
      municipalityId: data.municipality.id,
      provinceId: data.province.id,
      neighborhood: data.town,
      phone: data.phone,
      remittanceType: data.method.id,
      updateContactInfo: data.saveRemittance || false,
      contactId: tabStep === 'contact' && selectedContact ? selectedContact.id : undefined,
      mlcCard: data.method.id === 'mlc' ? data.mlcCard : undefined,
    };
    setRemittanceOrder(order);

    setRemittanceStep('products');
  };

  return (
    <div className="bg-mega_light_gray">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 col-md-2 col-lg-3"></div>
          <div className="col-12 col-md-8 col-lg-6">
            <PageTitle
              title="Enviar Remesas"
              description="Envía remesas a familiares y amigos en Cuba."
              icon={EnviarRemesa}
            />

            <div className={styles.inputArea}>
              {remittanceStep === 'info' && (
                <form id="hook-form" onSubmit={handleSubmit(onSubmit)} noValidate>
                  <div className="row">
                    <div className="col-12 mt-3">
                      <h6 className="">¿Como deseas enviar el dinero?</h6>
                    </div>
                    <div className="col-12 mt-1 mb-4">
                      <div className="megaInput">
                        <label htmlFor="method">Método de entrega</label>
                        <Controller
                          control={control}
                          name="method"
                          render={({ field: { onChange, name, value } }) => (
                            <Select
                              id="method"
                              name={name}
                              placeholder="Seleccione un método de entrega"
                              value={value}
                              options={remittanceMethods}
                              isSearchable={false}
                              isClearable={false}
                              className="w-100 mega-select"
                              isMulti={false}
                              styles={reactSelectStyles}
                              components={{
                                Option: IconOptionRemittanceMethod,
                                SingleValue: IconSingleValueRemittanceMethod,
                              }}
                              onChange={onChange}
                            />
                          )}
                        />
                      </div>
                      {FormHelpers.formError('method', formState.errors)}
                    </div>
                  </div>

                  {!!selectedRemittanceMethod && (
                    <>
                      <div className="d-flex w-100 flex-column flex-lg-row">
                        <div className="flex-fill">
                          <h6>Destinatario</h6>
                        </div>
                        <div className="flex-fill d-flex flex-row justify-content-center justify-content-lg-end">
                        {contactList.length>0 && (
                          <>
                          <div
                            className={`d-inline px-3 ${styles.tabItem} ${
                              tabStep === 'contact' ? styles.activeTab : ''
                            }`}
                            onClick={() => setTabStep('contact')}>
                            Mis Contactos
                          </div>
                          
                          <div
                            className={`d-inline px-3 ${styles.tabItem} ${
                              tabStep === 'other' ? styles.activeTab : ''
                            }`}
                            onClick={() => setTabStep('other')}>
                            Otra Persona
                          </div>
                          </>
                           )}
                        </div>
                      </div>

                      <div>
                        <div className="w-100 mt-3">
                          <h6 className="mega-fs-small">Datos personales</h6>
                        </div>

                        {tabStep === 'contact' && (
                          <div>
                            <div className="w-100 mt-3">
                              <p>Elija uno de sus contactos para enviar la remesa</p>
                            </div>

                            <div className="row mt-5">
                              <div className="col-12">
                                <div className="megaInput">
                                  <label htmlFor="contact">Mis Contactos</label>
                                  <Select
                                    id="contact"
                                    placeholder=""
                                    value={selectedContact}
                                    options={contactList}
                                    isSearchable={false}
                                    isClearable={false}
                                    isLoading={contactQuery.isFetching}
                                    className="w-100 mega-select"
                                    isMulti={false}
                                    styles={reactSelectStyles}
                                    components={{
                                      Option: IconOptionContact,
                                      SingleValue: IconSingleValueContact,
                                    }}
                                    onChange={onChangeSelectedContact}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        )}

                        {(tabStep === 'other' || !!selectedContact) && (
                          <>
                            <div className="row mt-4">
                              <div className="col-12 col-lg-6 mb-4 mb-lg-0">
                                <div className="megaInput">
                                  <label htmlFor="fullName">Nombre Completo</label>
                                  <input
                                    type="text"
                                    className={`form-control mega ${
                                      formState.errors.fullName ? 'border border-danger' : ''
                                    }`}
                                    id="fullName"
                                    {...register('fullName')}
                                  />
                                </div>
                                {formState.errors.fullName && (
                                  <div className="text-danger">El nombre es requerido</div>
                                )}
                              </div>

                              <div className="col-12 col-lg-6 mb-4 mb-lg-0">
                                <div className="megaInput">
                                  {FormHelpers.formLabel('Phone', 'phone')}
                                  <input
                                    type="number"
                                    className={`form-control mega ${FormHelpers.errorClass(
                                      'phone',
                                      formState.errors
                                    )}`}
                                    id="phone"
                                    {...register('phone')}
                                  />
                                </div>
                                {FormHelpers.formError('phone', formState.errors)}
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="col-12 col-lg-6 mb-4 mb-lg-0">
                                <div className="megaInput">
                                  {FormHelpers.formLabel('Carnet de Identidad', 'carneId')}
                                  <input
                                    type="number"
                                    className={`form-control mega ${FormHelpers.errorClass(
                                      'carneId',
                                      formState.errors
                                    )}`}
                                    id="carneId"
                                    {...register('carneId')}
                                  />
                                </div>
                                {FormHelpers.formError('carneId', formState.errors)}
                              </div>

                              <div className="col-12 col-lg-6">
                                {selectedRemittanceMethod.id === 'mlc' && (
                                  <>
                                    <div className="megaInput">
                                      {FormHelpers.formLabel('Tarjeta MLC', 'mlcCard')}
                                      <input
                                        type="number"
                                        className={`form-control mega ${FormHelpers.errorClass(
                                          'mlcCard',
                                          formState.errors
                                        )}`}
                                        id="mlcCard"
                                        {...register('mlcCard')}
                                      />
                                    </div>
                                    {FormHelpers.formError('mlcCard', formState.errors)}
                                  </>
                                )}
                              </div>
                            </div>

                            <div className="w-100 mt-3">
                              <h6 className="mega-fs-small">Dirección</h6>
                            </div>

                            <div className="row mt-4">
                              <div className="col-12 col-lg-6 mb-4 mb-lg-0">
                                <div className="megaInput">
                                  <label htmlFor="address">Dirección</label>
                                  <input
                                    type="text"
                                    className={`form-control mega ${
                                      formState.errors.address ? 'border border-danger' : ''
                                    }`}
                                    id="address"
                                    {...register('address')}
                                  />
                                </div>
                                {FormHelpers.formError('address', formState.errors)}
                              </div>

                              <div className="col-12 col-lg-6">
                                <div className="megaInput">
                                  <label htmlFor="town">Reparto</label>
                                  <input
                                    type="text"
                                    className={`form-control mega ${
                                      formState.errors.town ? 'border border-danger' : ''
                                    }`}
                                    id="town"
                                    {...register('town')}
                                  />
                                </div>
                                {FormHelpers.formError('town', formState.errors)}
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="col-12 col-lg-6 mb-4 mb-lg-0">
                                <div className="megaInput">
                                  <label htmlFor="province">Provincia</label>
                                  <Controller
                                    control={control}
                                    name="province"
                                    render={({ field: { onChange, name, value } }) => (
                                      <Select
                                        id="province"
                                        placeholder="Seleccione una provincia"
                                        name={name}
                                        value={value}
                                        options={provinces}
                                        isSearchable={false}
                                        isClearable={false}
                                        isLoading={provinceQuery.isFetching}
                                        className="w-100 mega-select"
                                        isMulti={false}
                                        styles={reactSelectStyles}
                                        components={{
                                          Option: IconOptionProvince,
                                          SingleValue: IconSingleValueProvince,
                                        }}
                                        onChange={onChange}
                                      />
                                    )}
                                  />
                                </div>
                                {FormHelpers.formError('province', formState.errors)}
                              </div>

                              <div className="col-12 col-lg-6">
                                <div className="megaInput">
                                  <label htmlFor="municipality">Municipio</label>
                                  <Controller
                                    control={control}
                                    name="municipality"
                                    render={({ field: { onChange, name, value } }) => (
                                      <Select
                                        id="municipality"
                                        name={name}
                                        placeholder="Seleccione un municipio"
                                        value={value}
                                        options={municipalities}
                                        isSearchable={false}
                                        isClearable={false}
                                        isLoading={municipalitiesQuery.isFetching}
                                        className="w-100 mega-select"
                                        isMulti={false}
                                        styles={reactSelectStyles}
                                        components={{
                                          Option: IconOptionMunicipality,
                                          SingleValue: IconSingleValueMunicipality,
                                        }}
                                        onChange={onChange}
                                      />
                                    )}
                                  />
                                </div>
                                {FormHelpers.formError('municipality', formState.errors)}
                              </div>
                            </div>

                            <div className="row mt-4">
                              <div className="col-12">
                                <div className="form-check">
                                  <input
                                    type="checkbox"
                                    className={`form-check-input mega`}
                                    id="saveRemittance"
                                    {...register('saveRemittance')}
                                  />
                                  {(tabStep === 'other') && (
                                  <label htmlFor="saveRemittance" className="form-check-label mega">                                   
                                        Guardar la información de Remesas en mi contacto para usos futuros.                                     
                                  </label>
                                  )}
                                  {(tabStep !== 'other') && (
                                  <label htmlFor="saveRemittance" className="form-check-label mega">                                   
                                        Actualizar este contacto con cualquier modificación de la información de Remesas.
                                  </label>
                                  )}
                                </div>
                              </div>
                            </div>
                          </>
                        )}

                        
                         
                        
                      </div>
                    </>
                  )}

                  {errorMessage && !isSubmit && (
                    <div className="row mt-4">
                      <div className="col-12">
                        <Alert variant={'danger'}>{errorMessage}</Alert>
                      </div>
                    </div>
                  )}

                  <div className="row mt-4">
                    <div className="col-12 col-lg-6"></div>
                    <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                      <Button
                        variant="outline-secondary"
                        className="w-100 py-2"
                        type="submit"
                        disabled={!selectedRemittanceMethod}>
                        {isSubmit ? <SmallSpinner /> : 'Continuar'}
                      </Button>
                    </div>
                  </div>
                </form>
              )}
              {remittanceStep === 'products' && !!selectedRemittanceMethod && !!remittanceOrder && (
                <RemittanceProductsScreen
                  deliveryMethod={selectedRemittanceMethod}
                  receiver={getReceiverInfo()}
                  remittanceOrder={remittanceOrder}
                  navigateBack={(resetStep?: boolean) => {
                    if (resetStep) {
                      resetPage();
                    } else {
                      setRemittanceStep('info');
                    }
                  }}
                />
              )}
            </div>
          </div>
          <div className="col-12 col-md-2 col-lg-3"></div>
        </div>
      </div>
    </div>
  );
};
