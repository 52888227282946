import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { GridColDef } from '@mui/x-data-grid';
import { QueryTypes } from '../../../types/queryTypes';
import { MegaTopUpReportItem } from '../../../types/reports/topUpReportItem';
import { baseColumnDef, caseColumnDef, statusColumnDef } from './baseColumnDef';

import { ReportDataGrid } from './reportDataGrid';
import { ReportDateAndNumberSearch } from './reportDateAndNumberSearch';

import { ReportsConfig } from '../../../config/reportsConfig';
import { UtilService } from '../../../services';
import { ReportsService } from '../../../services/reports';
import { MegaError } from '../megaError';

export const TopUpReport: React.FC<{}> = ({ children }) => {
  const [reportItems, setReportItems] = useState<MegaTopUpReportItem[]>([]);
  const [startDate, setStartDate] = useState<Date>(ReportsConfig.defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(ReportsConfig.defaultEndDate);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const topUpReportQuery = useQuery(
    [QueryTypes.GetTopUpReport, startDate, endDate, phoneNumber],
    () => {
      return ReportsService.getTopUpReport({ startDate, endDate, phoneNumber });
    },
    {
      cacheTime: 0,
    }
  );

  useEffect(() => {
    setIsSearching(topUpReportQuery.isFetching);
  }, [topUpReportQuery.isFetching]);

  useEffect(() => {
    if (topUpReportQuery.status === 'success') {
      setReportItems(topUpReportQuery.data);
    }
  }, [topUpReportQuery.data]);

  const columns: GridColDef[] = [
    {
      ...baseColumnDef,
      field: 'created',
      headerName: 'Fecha',
      sortable: true,
      width: 180,
    },
    {
      ...caseColumnDef,
      field: 'payment_type',
      headerName: 'Método',
      width: 140,
    },
    {
      ...baseColumnDef,
      field: 'approval_code',
      headerName: 'Transacción',
      width: 140,
    },
    {
      ...statusColumnDef,
      field: 'status',
      headerName: 'Estado',
      width: 140,
    },
    {
      ...baseColumnDef,
      field: 'destination',
      headerName: 'Teléfono',
      width: 180,
    },
    {
      ...baseColumnDef,
      field: 'received_product',
      flex: 1,
      headerName: 'Recibido',
      minWidth: 140,
    },
  ];

  const onSearch = (startDate: Date, endDate: Date, phone: string) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setPhoneNumber(phone);

    topUpReportQuery.refetch({});
  };

  return (
    <>
      <div className="mt-5">
        <ReportDateAndNumberSearch
          startDate={startDate}
          endDate={endDate}
          isSearching={isSearching}
          phoneNumber={phoneNumber}
          onSearch={onSearch}
        />
      </div>

      {children}

      <div className="mt-3 mb-5">
        {UtilService.isErrorQueries([topUpReportQuery]) ? (
          <MegaError
            displayRetry={true}
            retry={() => {
              UtilService.refetchQueries([topUpReportQuery]);
            }}
          />
        ) : (
          <ReportDataGrid<MegaTopUpReportItem>
            columns={columns}
            getRowId={(row: MegaTopUpReportItem) => row.id}
            rows={reportItems}
            isSearching={isSearching}
          />
        )}
      </div>
    </>
  );
};
