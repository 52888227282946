import { useContext, useEffect } from 'react';
import { GiHamburgerMenu } from 'react-icons/gi';
import { Link, NavLink } from 'react-router-dom';
import { IMAGES } from '../../../config/images';
import { ROUTES } from '../../../config/routes';
import { MobileMenuContext } from '../../../providers/mobileMenu.provider';

import colors from '../../../assets/styles/_variables.module.scss';
import { SessionContext } from '../../../providers/session.provider';
import { CartWidget, TopUpWidget, UserMenu } from '../../account';
import { LogoM } from '../../svg';

import styles from './headerNav.module.scss';

export const HeaderNav = () => {
  const { getUser, isAuthenticated, isUserUpdated, logOut, setIsUserUpdated, userBalance } =
    useContext(SessionContext);
  const { toggleMenu } = useContext(MobileMenuContext);

  let authUser = getUser();

  const menuList = [
    { title: 'Inicio', link: ROUTES.external.home },
    { title: 'Llamadas', link: ROUTES.external.services.calls },
    { title: 'Recargas', link: ROUTES.external.services.topups },
    { title: 'Mensajes', link: ROUTES.external.services.messages },
    { title: 'Mercado', link: ROUTES.external.services.combos },
    { title: 'Contáctenos', link: ROUTES.external.contact },
  ];

  const toggleMenuMobile = (e: React.MouseEvent<SVGElement, MouseEvent>) => {
    e.preventDefault();
    toggleMenu();
  };

  useEffect(() => {
    if (isUserUpdated) {
      authUser = getUser();
      setIsUserUpdated(false);
    }
  }, [isUserUpdated]);

  return (
    <div className={`bg-white d-flex ${styles.megaNavbar}`}>
      <div className="container h-100">
        <div className={`row h-100 justify-content-between`}>
          <div className={`col-2 col-md-3 ${styles.logoWrapper}`}>
            <Link to={ROUTES.external.home}>
              <img
                src={IMAGES.ImageLogo}
                width={183}
                className={`${styles.logo} d-none d-md-block`}
                alt="Megaconecta Logo"
              />
              <LogoM width={30} className="d-block d-md-none" />
            </Link>
          </div>
          {!isAuthenticated() && (
            <div className={`col-6 ${styles.menuWrapper} d-none d-md-flex`}>
              {menuList.map((item) => (
                <div key={item.title} className={styles.menuItem}>
                  <NavLink
                    to={item.link}
                    className={({ isActive }) =>
                      [styles.a_menuItem, isActive ? styles.a_menuItem_active : undefined]
                        .filter(Boolean)
                        .join(' ')
                    }>
                    {item.title}
                  </NavLink>
                </div>
              ))}
            </div>
          )}

          <div
            className={`${styles.menuWrapper} ${isAuthenticated() ? 'col-10 col-md-9' : 'col-3'}`}>
            {isAuthenticated() && (
              <Link
                to={ROUTES.cuenta.fondos}
                className="btn btn-secondary btn-sm py-2 px-3 me-5 d-none d-lg-block">
                Agregar saldo
              </Link>
            )}

            {isAuthenticated() && (
              <div className="me-3">
                <TopUpWidget />
              </div>
            )}

            <div className="me-3">
              <CartWidget />
            </div>
            {!isAuthenticated() ? (
              <div className="">
                <Link to={ROUTES.login} className={`btn btn-secondary ${styles.menuLogin}`}>
                  Entrar
                </Link>
              </div>
            ) : (
              <div className="h-100 d-flex align-items-center pe-0 pe-lg-5">
                <UserMenu />
              </div>
            )}

            <div className="ms-3 me-2 d-lg-none">
              <GiHamburgerMenu onClick={toggleMenuMobile} color={colors.primary} size={25} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
