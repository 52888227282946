import * as React from 'react';
import { SVGProps } from 'react';
const SvgEligePagar = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <g data-name="Capa 1">
      <path
        data-name="Trazado 233"
        d="M29.4 6.34C51.12.83 52.19 1.2 55.84 15.61c3.66 14.41 2.9 15.24-18.83 20.76-21.72 5.51-22.79 5.14-26.45-9.27S7.66 11.86 29.39 6.34Z"
        style={{
          fill: '#fe4a81',
        }}
      />
      <path
        data-name="Trazado 233"
        d="M28.37 23.24c24.83 0 25.87.69 25.87 17.15 0 16.46-1.04 17.15-25.87 17.15S2.5 56.85 2.5 40.39c0-16.46 1.04-17.15 25.87-17.15Z"
        style={{
          fill: '#ffdbe6',
        }}
      />
      <path
        data-name="Trazado 233"
        d="M18.68 45.32c9.21 0 9.6.11 9.6 2.8s-.39 2.8-9.6 2.8-9.6-.11-9.6-2.8.39-2.8 9.6-2.8Z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        d="M56.9 15.34C55.06 8.08 53.82 3.9 49.76 2.48c-3.63-1.27-9.08-.12-20.63 2.81C17.58 8.22 12.25 9.8 9.66 12.66c-2.32 2.55-2.19 5.81-1.1 10.74-1.2.34-2.19.78-3.04 1.34-3.92 2.6-4.11 7.4-4.11 15.66s.19 13.06 4.11 15.66c3.53 2.34 9.67 2.58 22.85 2.58s19.32-.24 22.85-2.58c3.92-2.6 4.11-7.4 4.11-15.66 0-3.55-.04-6.45-.39-8.82.72-.47 1.32-.97 1.82-1.51 2.89-3.19 1.99-7.45.14-14.71ZM6.72 26.55c3.19-2.11 10.3-2.22 21.64-2.22s18.45.11 21.64 2.22c1.48.98 2.27 2.48 2.69 4.85H4.04c.42-2.37 1.21-3.87 2.69-4.85Zm43.29 27.69c-3.19 2.11-10.3 2.22-21.64 2.22s-18.46-.11-21.64-2.22c-2.84-1.88-3.14-5.66-3.14-13.84v-1.16h49.56v1.16c0 8.18-.3 11.96-3.14 13.84Zm5.13-25.64c-.21.23-.44.45-.71.66-.59-1.95-1.58-3.43-3.22-4.52-3.53-2.34-9.67-2.58-22.85-2.58-8.34 0-13.86.1-17.68.76-.99-4.52-1.03-7.02.59-8.8 2.3-2.54 8.49-4.21 18.39-6.72s16.14-4 19.37-2.86c2.86 1 3.95 4.23 5.75 11.35 1.81 7.12 2.39 10.47.35 12.72Z"
        style={{
          fill: '#263645',
        }}
      />
    </g>
  </svg>
);
export default SvgEligePagar;
