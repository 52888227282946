import * as React from 'react';
import { SVGProps } from 'react';
const SvgPago100Seguro = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Grupo 5391" viewBox="0 0 39 39" {...props}>
    <defs>
      <clipPath id="Pago100Seguro_svg__a">
        <path data-name="Rect\xE1ngulo 1352" fill="none" d="M0 0h39v39H0z" />
      </clipPath>
    </defs>
    <g data-name="Grupo 5390" clipPath="url(#Pago100Seguro_svg__a)">
      <g data-name="Grupo 5389">
        <g data-name="Grupo 5388" clipPath="url(#Pago100Seguro_svg__a)">
          <path
            data-name="Trazado 10769"
            d="M34.84 8.194a4.024 4.024 0 0 0-3.5-3.631 64.905 64.905 0 0 0-8.557-.754 50.683 50.683 0 0 0-3.189-.1h-.19c-1.063 0-2.126.036-3.189.1a64.905 64.905 0 0 0-8.557.754 4.024 4.024 0 0 0-3.5 3.631 41.42 41.42 0 0 0-.331 10.9c.791 7.41 4.93 12.432 11.757 15.345a10.043 10.043 0 0 0 3.819.854h.19a10.047 10.047 0 0 0 3.819-.854c6.829-2.914 10.968-7.939 11.759-15.346a41.42 41.42 0 0 0-.331-10.9"
            fill="#fe4a81"
          />
          <path
            data-name="Trazado 10770"
            d="M19.41 36h-.71v-.033a11.11 11.11 0 0 1-3.39-.876C8.021 31.98 3.922 26.623 3.126 19.168a42.077 42.077 0 0 1 .336-11.084A4.723 4.723 0 0 1 7.521 3.87a46.177 46.177 0 0 1 6.679-.66c.657-.033 1.312-.065 1.967-.106a55.66 55.66 0 0 1 2.526-.1v-.011L19.5 3l.8-.007v.014c.876.014 1.708.046 2.526.1.654.041 1.31.073 1.967.106a46.191 46.191 0 0 1 6.684.66 4.724 4.724 0 0 1 4.059 4.214 42.077 42.077 0 0 1 .336 11.084c-.8 7.455-4.9 12.812-12.182 15.92a11.11 11.11 0 0 1-3.39.876v.029l-.7.01h-.19m.07-31.591h-.073c-1.111.005-2.14.038-3.147.1-.66.041-1.322.074-1.985.107a45.055 45.055 0 0 0-6.48.636 3.309 3.309 0 0 0-2.938 3.048 40.672 40.672 0 0 0-.325 10.712c.737 6.908 4.549 11.878 11.33 14.77a9.383 9.383 0 0 0 3.552.8h.171a9.383 9.383 0 0 0 3.552-.8c6.781-2.892 10.593-7.862 11.33-14.77a40.672 40.672 0 0 0-.325-10.712 3.313 3.313 0 0 0-2.942-3.05 45.2 45.2 0 0 0-6.48-.635c-.663-.033-1.325-.066-1.985-.107a49.855 49.855 0 0 0-3.148-.1Z"
            fill="#263645"
          />
          <path
            data-name="Trazado 10771"
            d="M12.164 19.575c1.35-2.338 1.35-2.338 6.039.369l.019.011c.411-.727.9-1.564 1.455-2.534 4.1-7.11 4.1-7.11 6.443-5.76s2.338 1.349-1.767 8.46c-.931 1.611-1.65 2.857-2.234 3.805-.136.3-.335.643-.577 1.063-1.35 2.338-1.35 2.338-6.039-.369s-4.689-2.707-3.339-5.045"
            fill="#fff"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgPago100Seguro;
