import * as React from 'react';
import { SVGProps } from 'react';
const SvgRecargaAhora = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="recarga_ahora_svg__recarga_ahora"
    data-name="recarga ahora"
    width={40}
    height={40}
    {...props}>
    <script>
      {'(\n            function hookGeo() {\n  //'}
      {'}\n          )();'}
    </script>
    <g id="recarga_ahora_svg__Grupo_16705" data-name="Grupo 16705">
      <g
        id="recarga_ahora_svg__Grupo_16704"
        data-name="Grupo 16704"
        transform="translate(5.243 1.558)"
        fill="#6aa500">
        <path
          id="recarga_ahora_svg__Trazado_34072"
          data-name="Trazado 34072"
          d="M16.871 4.789a7.541 7.541 0 0 0-7.542 7.542 1.674 1.674 0 0 1-3.349 0 10.891 10.891 0 1 1 21.781 0 1.674 1.674 0 0 1-3.349 0 7.541 7.541 0 0 0-7.542-7.542Z"
          transform="translate(-3.082 -1.44)"
          fillRule="evenodd"
        />
        <path
          id="recarga_ahora_svg__Trazado_34073"
          data-name="Trazado 34073"
          d="M19.613 6.154a12.7 12.7 0 0 0-3.049 0 3.482 3.482 0 0 0-2.9 3.362 223.49 223.49 0 0 0-.463 12.5c-.109-.082-.231-.163-.368-.259-.708-.5-1.715-1.2-2.927-1.96a3.368 3.368 0 0 0-4.424.395 8.034 8.034 0 0 0-1.538 2.94 2.715 2.715 0 0 0 .395 2.246 45.913 45.913 0 0 0 8.672 9.883 3.912 3.912 0 0 0 1.811.776 47.085 47.085 0 0 0 14.634.191 3.619 3.619 0 0 0 2.7-2.083 23.4 23.4 0 0 0 2.024-10.661 5.371 5.371 0 0 0-3.689-4.86 26.884 26.884 0 0 0-5.513-1.212 25.001 25.001 0 0 0-1.77-.15h-.218c-.041-1.879-.272-5.364-.449-7.691A3.533 3.533 0 0 0 19.6 6.2Z"
          transform="translate(-3.852 .23)"
          opacity={0.4}
        />
      </g>
    </g>
  </svg>
);
export default SvgRecargaAhora;
