import { useEffect, useState } from 'react';
import { Alert, Button, Modal, Spinner } from 'react-bootstrap';
import ReactCodeInput from 'react-verification-code-input';
import { useGlobalModalContext } from '../../../providers/globalModal.provider';
import { RegisterService } from '../../../services';

export const PinValidationModal = () => {
  const { hideModal, currentModalProps } = useGlobalModalContext();
  const [code, setCode] = useState('');
  const [codeHasError, setCodeHasError] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    if (code) {
      setCodeHasError(!code || code.length !== 4);
    }
  }, [code]);

  const closeModal = () => {
    // do not close when loading
    if (isSubmitting) {
      return;
    }

    hideModal();
    !!currentModalProps?.onClose && currentModalProps.onClose(false);
  };

  const validatePin = async () => {
    if (!code || code.length !== 4) {
      setCodeHasError(true);
      return;
    }

    setCodeHasError(false);
    setIsSubmitting(true);

    const response = await RegisterService.validatePin(currentModalProps?.state.phone || '', code);
    setIsSubmitting(false);

    if (!response.success) {
      let error = '';
      switch (response.code) {
        case '003':
          error =
            'Su código ha expirado. Por favor, vuelva a solicitar un código nuevo en la ventana anterior.';
          break;
        case '002':
        case '004':
          error = 'El código introducido no es válido.';
          break;
        case '500':
        default:
          error = 'Hubo un error validando su código. Por favor intente de nuevo.';
          break;
      }

      setErrorMessage(error);
      return;
    }

    // success
    hideModal();
    !!currentModalProps?.onClose && currentModalProps.onClose(true);
  };

  return (
    <Modal
      dialogClassName="mega-modal-success"
      size="sm"
      show={true}
      onHide={closeModal}
      backdrop="static"
      centered>
      <Modal.Header
        className="text-center bg-secondary mega-modal-title"
        closeButton
        closeVariant="white">
        <Modal.Title className="text-white text-center">Validación de su teléfono</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="text-center my-3">
          <label className="form-label mega-bold">Entre el código recibido:</label>
          <ReactCodeInput
            type="number"
            fields={4}
            onComplete={(s) => {
              setCode(s);
            }}
            onChange={(s) => {
              setCode(s);
            }}
            loading={isSubmitting}
            className={`otpField ${codeHasError ? 'otpFieldError' : ''}`}
          />
          {codeHasError && <div className="text-danger text-center">El código es requerido</div>}
        </div>
        <div className="text-center my-2">
          {errorMessage && !isSubmitting && (
            <div className="mt-3 text-start">
              <Alert variant={'danger'}>
                <div dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
              </Alert>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div className="text-center w-100">
          <Button
            variant="secondary"
            className="px-10 ms-auto"
            onClick={validatePin}
            disabled={isSubmitting}>
            {isSubmitting ? (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                variant="light"
                aria-hidden="true"
              />
            ) : (
              <span>Validar Código</span>
            )}
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
