import * as React from 'react';
import { SVGProps } from 'react';
const SvgEnviarSms = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" {...props}>
    <g data-name="Capa 1">
      <path
        d="M21.9 21.02c1.44-.1 2.56-1.18 2.69-2.61.14-1.57.27-3.97.27-7.29s-.14-5.72-.27-7.29c-.13-1.44-1.25-2.51-2.69-2.61-1.82-.13-4.73-.26-8.9-.26-4.16 0-7.07.13-8.9.26-1.44.1-2.56 1.18-2.69 2.61-.14 1.57-.27 3.97-.27 7.29s.14 5.72.27 7.29c.13 1.44 1.25 2.51 2.69 2.61 1.7.12 4.33.24 8.05.26l4.07 3.49c.73.63 1.86.11 1.86-.86v-2.7c1.59-.05 2.85-.12 3.81-.19Z"
        style={{
          opacity: 0.4,
          fill: '#8f979f',
        }}
      />
      <path
        data-name="Uni\xF3n 3"
        d="M6.76 12.65q0-1.17 3.83-1.17t3.83 1.17-3.83 1.17-3.83-1.17Zm0-4.05q0-1.17 7.17-1.17T21.1 8.6q0 1.17-7.17 1.17T6.76 8.6Z"
        style={{
          fill: '#8f979f',
        }}
      />
    </g>
  </svg>
);
export default SvgEnviarSms;
