import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useState } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { FieldValues, useForm } from 'react-hook-form';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useLocation, useNavigate } from 'react-router-dom';
import * as yup from 'yup';
import { WizardSteps } from '../../../components/shared';
import { Ojo, OjoTachado } from '../../../components/svg';
import { ROUTES } from '../../../config/routes';
import { SessionContext } from '../../../providers/session.provider';
import { RegisterService } from '../../../services';

const accountSchema = yup
  .object()
  .shape({
    password: yup
      .string()
      .min(4, 'La Contraseña debe tener al menos 4 caracteres.')
      .required('La Contraseña es requerida.'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Las contraseñas tienen que coincidir.')
      .required('La Contraseña es requerida.'),
  })
  .required();

export const ChangePasswordScreen = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuthenticated } = useContext(SessionContext);

  const { watch, register, handleSubmit, formState } = useForm({
    resolver: yupResolver(accountSchema),
    mode: 'onChange',
  });

  const password = watch('password', '');

  // states
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [userData, setUserData] = useState<any>({});

  const [passwordType, setPasswordType] = useState<'text' | 'password'>('password');
  const [password2Type, setPassword2Type] = useState<'text' | 'password'>('password');

  useEffect(() => {
    if (isAuthenticated()) {
      navigate(ROUTES.cuenta.inicio);
      return;
    }

    if (!location.state) {
      navigate(ROUTES.forgotPassword);
    } else {
      setUserData(location.state);
    }
  }, []);

  const submitForm = async (data: FieldValues) => {
    setIsSubmitting(true);

    const response = await RegisterService.changePassword(userData.phone, data.password);

    setIsSubmitting(false);

    if (!response.success) {
      let error = '';
      switch (response.code) {
        case '002':
          error = '<strong>PIN no válido o expirado.</strong>';
          break;
        case '003':
          error =
            '<strong>Hubo un error localizando la información de su cuenta. Por favor intente de nuevo.</strong>';
          break;
        case '004':
        case '500':
        default:
          error = 'Hubo un error cambiando su contraseña. Por favor intente de nuevo.';
          break;
      }

      setErrorMessage(error);
      return;
    }

    // success
    navigate(`${ROUTES.login}?type=password`);
  };

  const switchPasswordType = (type: number) => {
    const pass = type === 1 ? passwordType : password2Type;
    const newPassType = pass === 'password' ? 'text' : 'password';

    if (type === 1) {
      setPasswordType(newPassType);
    } else {
      setPassword2Type(newPassType);
    }
  };

  return (
    <div className="container my-7">
      <form noValidate onSubmit={handleSubmit(submitForm)}>
        <div className="row">
          <div className="col-lg-3 col-md-0"></div>
          <div className="col-lg-6 col-md-12">
            <h1 className="text-center text-uppercase mega-font-bold fs-3 text-center">
              RECUPERAR CONTRASEÑA
            </h1>

            <WizardSteps
              currentStep={2}
              initialStep={2}
              steps={[{ title: 'Datos de Cuenta' }, { title: 'Validación' }]}
            />

            <p className="w-100 text-center my-5">
              Introduzca la contraseña nueva para la cuenta {userData?.phone}. Las contraseña deben
              de tener un mínimo de 4 caracteres y 25 como máximo.
            </p>
          </div>
          <div className="col-lg-3 col-md-0"></div>
        </div>

        <div className="row mt-3">
          <div className="col-xl-4 col-lg-3 col-md-0"></div>
          <div className="col-xl-4 col-lg-6 col-md-12">
            <div className="mb-4">
              <div className="megaInputWithIcon">
                <input
                  {...register('password')}
                  id="password"
                  type={passwordType}
                  placeholder="Contraseña"
                  className={`form-control mega ${
                    formState.errors.password ? 'border border-danger' : ''
                  }`}
                  maxLength={25}
                />
                {passwordType === 'password' && (
                  <OjoTachado width={20} className="icon" onClick={() => switchPasswordType(1)} />
                )}
                {passwordType === 'text' && (
                  <Ojo width={20} className="icon" onClick={() => switchPasswordType(1)} />
                )}
              </div>
              {!!password && !formState.errors.password && (
                <PasswordStrengthBar
                  password={password}
                  minLength={4}
                  shortScoreWord={'demasiado corto'}
                  barColors={['#ddd', '#ff4b55', '#ff9855', '#263645', '#8ab934']}
                  scoreWords={['débil', 'débil', 'okay', 'mejor', 'fuerte']}
                />
              )}
              {formState.errors.password && (
                <div className="text-danger">{formState.errors.password.message}</div>
              )}
            </div>
          </div>
          <div className="col-xl-4 col-lg-3 col-md-0"></div>
        </div>

        <div className="row mt-2">
          <div className="col-xl-4 col-lg-3 col-md-0"></div>
          <div className="col-xl-4 col-lg-6 col-md-12">
            <div className="mb-4">
              <div className="megaInputWithIcon">
                <input
                  type={password2Type}
                  placeholder="Confirma la contraseña"
                  className={`form-control mega ${
                    formState.errors.passwordConfirmation ? 'border border-danger' : ''
                  }`}
                  maxLength={25}
                  id="passwordConfirmation"
                  {...register('passwordConfirmation')}
                />
                {password2Type === 'password' && (
                  <OjoTachado width={20} className="icon" onClick={() => switchPasswordType(2)} />
                )}
                {password2Type === 'text' && (
                  <Ojo width={20} className="icon" onClick={() => switchPasswordType(2)} />
                )}
              </div>
              {formState.errors.passwordConfirmation && (
                <div className="text-danger">{formState.errors.passwordConfirmation.message}</div>
              )}
            </div>
          </div>
          <div className="col-xl-4 col-lg-3 col-md-0"></div>
        </div>

        <div className="row">
          <div className="col-lg-3 col-md-0"></div>
          <div className="col-lg-6 col-md-12">
            {errorMessage && !isSubmitting && (
              <div className="mt-3">
                <Alert variant={'danger'}>
                  <div dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
                </Alert>
              </div>
            )}
          </div>
          <div className="col-lg-3 col-md-0"></div>
        </div>

        <div className="row mt-4">
          <div className="col-xl-4 col-lg-3 col-md-0"></div>
          <div className="col-xl-4 col-lg-6 col-md-12">
            <Button
              variant="secondary"
              className="w-100 py-2"
              type="submit"
              disabled={isSubmitting}>
              {isSubmitting ? (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  variant="light"
                  aria-hidden="true"
                />
              ) : (
                <span>Cambiar</span>
              )}
            </Button>
          </div>
          <div className="col-xl-4 col-lg-3 col-md-0"></div>
        </div>
      </form>
    </div>
  );
};
