import Lottie, { Options } from 'react-lottie';
import errorPageJson from '../../../assets/animations/error_page.json';

type MegaErrorProps = {
  displayRetry: boolean;
  message?: string;
  retry?: () => void;
};

export const MegaError = ({ message, displayRetry, retry }: MegaErrorProps) => {
  const defaultOptions1: Options = {
    loop: true,
    autoplay: true,
    animationData: errorPageJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
      className: 'anim-error-mega',
    },
  };

  return (
    <div className="container py-7">
      <div className="row">
        <div className="d-flex col-12 col-lg-6 flex-column justify-content-center align-items-center align-items-lg-end">
          <Lottie style={{ margin: 0 }} options={defaultOptions1} height={400} width={400} />
        </div>
        <div className="d-flex col-12 col-lg-6 flex-column justify-content-center align-items-center align-items-lg-start">
          <h1>Ooops!</h1>
          <h6>Algo salio mal</h6>
          <p className="p-0 m-0">Hubo un error no esperado</p>

          {displayRetry && (
            <button className="btn btn-secondary mt-5 w-auto" type="button" onClick={retry}>
              Reintentar
            </button>
          )}
        </div>
      </div>
    </div>
  );
};
