import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { GridColDef } from '@mui/x-data-grid';
import { QueryTypes } from '../../../types/queryTypes';
import { MegaFundReportItem } from '../../../types/reports/fundReportItem';
import { baseColumnDef, currencyColumnDef, statusColumnDef } from './baseColumnDef';

import { ReportDataGrid } from './reportDataGrid';
import { ReportDateSearch } from './reportDateSearch';

import { ReportsConfig } from '../../../config/reportsConfig';
import { UtilService } from '../../../services';
import { ReportsService } from '../../../services/reports';
import { MegaError } from '../megaError';

export const FundReport: React.FC<{}> = ({ children }) => {
  const [reportItems, setReportItems] = useState<MegaFundReportItem[]>([]);

  const [startDate, setStartDate] = useState<Date>(ReportsConfig.defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(ReportsConfig.defaultEndDate);
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const fundReportQuery = useQuery(
    [QueryTypes.GetFundReport, startDate, endDate],
    () => {
      return ReportsService.getFundReport({ startDate, endDate });
    },
    {
      cacheTime: 0,
    }
  );

  useEffect(() => {
    setIsSearching(fundReportQuery.isFetching);
  }, [fundReportQuery.isFetching]);

  useEffect(() => {
    if (fundReportQuery.status === 'success') {
      setReportItems(fundReportQuery.data);
    }
  }, [fundReportQuery.data]);

  const columns: GridColDef[] = [
    {
      ...baseColumnDef,
      field: 'datelog',
      headerName: 'Fecha',
      sortable: true,
      width: 180,
    },
    {
      ...baseColumnDef,
      field: 'payment_type',
      flex: 1,
      headerName: 'Método',
      minWidth: 160,
    },
    {
      ...baseColumnDef,
      field: 'transaction_id',
      headerName: 'Transacción',
      type: 'string',
      width: 120,
    },
    {
      ...statusColumnDef,
      field: 'result',
      headerName: 'Estado',
      flex: 1,
      minWidth: 120,
    },
    {
      ...currencyColumnDef,
      field: 'amount_charged',
      headerName: 'Pagado',
      width: 120,
    },
    {
      ...currencyColumnDef,
      field: 'amount_added',
      headerName: 'Adicionado',
      width: 120,
    },
    {
      ...currencyColumnDef,
      field: 'new_balance',
      headerName: 'Balance Nuevo',
      width: 120,
    },
  ];

  const onSearch = (startDate: Date, endDate: Date) => {
    setStartDate(startDate);
    setEndDate(endDate);

    fundReportQuery.refetch({});
  };

  return (
    <>
      <div>
        <ReportDateSearch
          startDate={startDate}
          endDate={endDate}
          isSearching={isSearching}
          onSearch={onSearch}
        />
      </div>

      {children}

      <div className="mt-3 mb-5">
        {UtilService.isErrorQueries([fundReportQuery]) ? (
          <MegaError
            displayRetry={true}
            retry={() => {
              UtilService.refetchQueries([fundReportQuery]);
            }}
          />
        ) : (
          <ReportDataGrid<MegaFundReportItem>
            columns={columns}
            getRowId={(row: MegaFundReportItem) => row.transaction_id}
            rows={reportItems}
            isSearching={isSearching}
          />
        )}
      </div>
    </>
  );
};
