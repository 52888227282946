import { NewCardInformation } from '../types/newCardInformation';
import { NewPaypalInformation } from '../types/newPaypalInformation';
import { PaymentMethodCard } from '../types/payment';
import { TopUpInternetItem, TopUpMobileItem, TopUpNautaItem } from '../types/topupItems';
import { MegaCarrierInitialData } from '../types/topups';
import { Formatter } from '../utilities';
import { finalAmount } from '../utilities/TopUpHelpers';
import api from './axios/api';
import { getCardNonce, getDeviceData } from './nonce';

interface purchaseWithNewCreditCardParams {
  newCardInformation: NewCardInformation;
  mobileItems: TopUpMobileItem[];
  nautaItems: TopUpNautaItem[];
  internetItems: TopUpInternetItem[];
  total: number;
  acceptReferral: boolean;
}

interface purchaseWithCreditCardParams {
  paymentMethod: PaymentMethodCard;
  mobileItems: TopUpMobileItem[];
  nautaItems: TopUpNautaItem[];
  internetItems: TopUpInternetItem[];
  total: number;
  acceptReferral: boolean;
}

interface purchaseWithNewPaypalParams {
  newPayPalInformation: NewPaypalInformation;
  mobileItems: TopUpMobileItem[];
  nautaItems: TopUpNautaItem[];
  internetItems: TopUpInternetItem[];
  total: number;
  acceptReferral: boolean;
}

interface purchaseWithPaypalParams {
  paymentMethod: PaymentMethodCard;
  mobileItems: TopUpMobileItem[];
  nautaItems: TopUpNautaItem[];
  internetItems: TopUpInternetItem[];
  total: number;
  acceptReferral: boolean;
}

interface topUpItemsPayloadParams {
  mobileItems: TopUpMobileItem[];
  nautaItems: TopUpNautaItem[];
  internetItems: TopUpInternetItem[];
}

const getInitialData = async (): Promise<MegaCarrierInitialData> => {
  try {
    const response = await api.get<MegaCarrierInitialData>('/api/v1/topups/getinitialdata', {
      params: {},
    });

    if (!response.has_error && !!response.data) {
      return response.data;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const purchaseWithNewCreditCard = async ({
  newCardInformation,
  mobileItems,
  nautaItems,
  internetItems,
  total,
  acceptReferral,
}: purchaseWithNewCreditCardParams) => {
  const url = '/api/v1/payment/topup/creditcard';
  const device_data = await getDeviceData();
  const nonce = await getCardNonce({
    number: newCardInformation.cardNumber,
    expiration: newCardInformation.cardExpiration,
    cvv: newCardInformation.cardCvc,
  });

  let payload = {
    nonce,
    device_data,
    accept_referral: acceptReferral ? 1 : 0,
    is_new_method: 1,
    save_method: newCardInformation.saveMethod ? 1 : 0,
    // Card info
    title: newCardInformation.title,
    cc_number: newCardInformation.cardNumber.replace(/\D/g, ''),
    cvc: newCardInformation.cardCvc,
    exp_date: newCardInformation.cardExpiration.replace(/\D/g, ''),
    first_name: newCardInformation.first_name,
    last_name: newCardInformation.last_name,
    address1: newCardInformation.address1,
    // address2: ,
    city: newCardInformation.city,
    state: newCardInformation.state,
    zip: newCardInformation.zip,
    country: newCardInformation.country,
    // Items info
    topup_list: _topUpItemsPayload({ mobileItems, nautaItems, internetItems }),
    total_to_pay: total,
  };

  try {
    const response = await api.post<any>(url, payload);

    if (!response.has_error) {
      return true;
    }

    throw new Error(response.error ?? 'ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const purchaseWithCreditCard = async ({
  paymentMethod,
  mobileItems,
  nautaItems,
  internetItems,
  total,
  acceptReferral,
}: purchaseWithCreditCardParams) => {
  const url = '/api/v1/payment/topup/creditcard';
  const device_data = await getDeviceData();

  let payload = {
    device_data,
    accept_referral: acceptReferral ? 1 : 0,
    is_new_method: 0,
    save_method: 0,
    payment_method_id: paymentMethod.id,
    // Items info
    topup_list: _topUpItemsPayload({ mobileItems, nautaItems, internetItems }),
    total_to_pay: total,
  };

  try {
    const response = await api.post<any>(url, payload);

    if (!response.has_error) {
      return true;
    }

    throw new Error(response.error ?? 'ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const purchaseWithNewPaypal = async ({
  newPayPalInformation,
  mobileItems,
  nautaItems,
  internetItems,
  total,
  acceptReferral,
}: purchaseWithNewPaypalParams) => {
  const url = '/api/v1/payment/topup/paypal';
  const device_data = await getDeviceData();

  let payload = {
    nonce: newPayPalInformation.nonce,
    device_data,
    accept_referral: acceptReferral ? 1 : 0,
    is_new_method: 1,
    save_method: newPayPalInformation.saveMethod ? 1 : 0,
    // Items info
    topup_list: _topUpItemsPayload({ mobileItems, nautaItems, internetItems }),
    total_to_pay: total,
  };

  try {
    const response = await api.post<any>(url, payload);

    if (!response.has_error) {
      return true;
    }

    throw new Error(response.error ?? 'ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const purchaseWithPaypal = async ({
  paymentMethod,
  mobileItems,
  nautaItems,
  internetItems,
  total,
  acceptReferral,
}: purchaseWithPaypalParams) => {
  const url = '/api/v1/payment/topup/paypal';
  const device_data = await getDeviceData();

  let payload = {
    device_data,
    accept_referral: acceptReferral ? 1 : 0,
    is_new_method: 0,
    save_method: 0,
    payment_method_id: paymentMethod.id,
    // Items info
    topup_list: _topUpItemsPayload({ mobileItems, nautaItems, internetItems }),
    total_to_pay: total,
  };

  try {
    const response = await api.post<any>(url, payload);

    if (!response.has_error) {
      return true;
    }

    throw new Error(response.error ?? 'ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const _topUpItemsPayload = ({
  mobileItems,
  nautaItems,
  internetItems,
}: topUpItemsPayloadParams) => {
  const payload = <any>[];

  mobileItems.forEach((item) => {
    payload.push(_topUpItemsPayloadElement({ item, type: 'mobile' }));
  });

  nautaItems.forEach((item) => {
    payload.push(_topUpItemsPayloadElement({ item, type: 'nauta' }));
  });

  internetItems.forEach((item) => {
    payload.push(_topUpItemsPayloadElement({ item, type: 'internet' }));
  });

  return JSON.stringify(payload);
};

const _topUpItemsPayloadElement = ({
  item,
  type,
}: {
  item: TopUpMobileItem | TopUpNautaItem | TopUpInternetItem;
  type: 'mobile' | 'nauta' | 'internet';
}) => {
  const recipient =
    type === 'mobile' || type === 'internet'
      ? item.type === 'other'
        ? Formatter.onlyNumbers((item as TopUpInternetItem).phoneNumber!)
        : item.contact?.contact_number
      : item.type === 'other'
      ? (item as TopUpNautaItem).nauta
      : item.contact?.email;

  const discount =
    type === 'mobile' || type === 'internet' ? (item as TopUpMobileItem).discount : 0;

  return {
    carrier: item.carrier.uid,
    recipient,
    remote_amount: item.carrierRate.remote_amount,
    remote_formated_amount: item.carrierRate.description,
    remote_currency: item.carrierRate.remote_currency,
    original_price: Number(item.carrierRate.real_amount),
    discount,
    final_amount: finalAmount(item.carrierRate.real_amount, discount),
    future_date: item.futurePromotionalDate
  };
};

export const TopUpServices = {
  getInitialData,
  purchaseWithCreditCard,
  purchaseWithNewCreditCard,
  purchaseWithNewPaypal,
  purchaseWithPaypal,
};
