import * as React from 'react';
import { SVGProps } from 'react';
const SvgCreaTuCuenta = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="crea_tu_cuenta_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    {...props}>
    <defs>
      <style>
        {'.crea_tu_cuenta_svg__cls-3{fill:#263645}.crea_tu_cuenta_svg__cls-4{fill:#d0e3ae}'}
      </style>
    </defs>
    <g id="crea_tu_cuenta_svg__Capa_1-2" data-name="Capa 1">
      <path
        className="crea_tu_cuenta_svg__cls-4"
        d="M49.68 39.98c-1.31-1.27-2.86-2.18-4.45-3.01-6.62-3.47-13.67-4.87-21.13-4.46-7.28.4-13.81 2.87-19.86 6.82-3.78 2.47-3.43 8.22-.83 10.5 1.81 1.59 3.73 2.61 6.18 2.6 5.7-.03 11.4 0 17.1 0 5.5 0 11 0 16.5.02 2.06 0 3.93-.46 5.63-1.69 3.94-2.87 4.32-7.41.86-10.77ZM26.75 29.46c7.55.05 13.6-6.02 13.61-13.53 0-8.5-6.46-13.98-13.84-13.94-7.68.04-13.45 6.04-13.55 13.53-.11 7.53 5.66 13.87 13.78 13.93Z"
      />
      <circle
        cx={44.22}
        cy={43.66}
        r={14.35}
        style={{
          fill: '#8ab934',
        }}
      />
      <path
        className="crea_tu_cuenta_svg__cls-3"
        d="M26.75 30.55h.11c3.88 0 7.53-1.5 10.28-4.22 2.78-2.76 4.32-6.45 4.32-10.39 0-4.19-1.52-8.01-4.29-10.77C34.39 2.4 30.61.88 26.52.91 18.42.95 12 7.37 11.89 15.52c-.06 4.03 1.43 7.8 4.18 10.62 2.76 2.82 6.55 4.39 10.68 4.42Zm-.22-27.47h.08c3.47 0 6.67 1.29 9.02 3.63 2.39 2.37 3.65 5.56 3.64 9.22 0 3.36-1.31 6.5-3.68 8.85-2.36 2.34-5.5 3.63-8.84 3.59-3.54-.03-6.79-1.36-9.14-3.77-2.35-2.4-3.61-5.62-3.56-9.07.09-7.07 5.46-12.42 12.47-12.46Z"
      />
      <path
        className="crea_tu_cuenta_svg__cls-3"
        d="M44.22 28.22c-3.87 0-7.41 1.43-10.12 3.79-3.25-.58-6.6-.79-10.05-.6-7.12.39-13.79 2.68-20.4 7-1.91 1.25-3.1 3.38-3.28 5.84-.18 2.53.71 4.98 2.33 6.4 2.23 1.96 4.41 2.87 6.85 2.87h.05c4.06-.02 8.19-.01 12.18-.01h10.57c2.83 3.4 7.1 5.58 11.87 5.58 8.51 0 15.44-6.92 15.44-15.44s-6.92-15.44-15.44-15.44ZM26.7 51.34h-4.92c-4 0-8.12 0-12.19.01-1.94 0-3.6-.71-5.45-2.33-1.1-.96-1.72-2.77-1.59-4.61.07-1.04.47-2.97 2.3-4.17 6.28-4.1 12.6-6.28 19.33-6.65 2.76-.15 5.46-.03 8.08.34a15.397 15.397 0 0 0-3.46 9.73c0 2.8.75 5.42 2.06 7.69h-4.15Zm17.52 5.58c-7.31 0-13.26-5.95-13.26-13.26S36.91 30.4 44.22 30.4s13.26 5.95 13.26 13.26-5.95 13.26-13.26 13.26Z"
      />
      <path
        d="M50.87 43.72c0-2.03 0-2.3-4.48-2.33-.03-4.48-.3-4.48-2.33-4.48s-2.3 0-2.33 4.48c-4.48.03-4.48.3-4.48 2.33s0 2.3 4.48 2.33c.03 4.48.3 4.48 2.33 4.48s2.3 0 2.33-4.48c4.48-.03 4.48-.3 4.48-2.33Z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);
export default SvgCreaTuCuenta;
