import { useRef, useState } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';
import { useTopUp } from '../../../context/TopupContext';
import { Formatter } from '../../../utilities';
import { ListadoRecargas } from '../../svg';
import { TopUpCartItems } from '../topUpCartItems';

import styles from './topUpWidget.module.scss';

export const TopUpWidget = () => {
  const [isSidebarVisible, setIsSidebarVisible] = useState<boolean>(false);
  const { itemsCount, cartTotal } = useTopUp();

  const ref = useRef<any>();

  const onPay = () => {
    if (ref && ref.current) {
      ref.current.pay();
    }
  };

  const onSuccessfulPayment = () => {
    setIsSidebarVisible(false);
  };

  return (
    <>
      <Offcanvas
        id="topUpCanvas"
        show={isSidebarVisible}
        placement={'end'}
        onHide={() => setIsSidebarVisible(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <ListadoRecargas width={25} className="me-1" /> Listado de recargas ({itemsCount})
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className={styles.sidebar_body_content}>
            <div className={styles.sidebar_body_items_container}>
              <TopUpCartItems ref={ref} hideCTA={true} onSuccess={onSuccessfulPayment} />
            </div>

            <div className={styles.footer}>
              {/* <div className={styles.cart_total}></div> */}
              <Button variant="secondary" className="w-100" onClick={onPay}>
                Comprar ahora ({Formatter.toCurrency(cartTotal)})
              </Button>

              <NavLink to={ROUTES.cuenta.recargas}>
                <Button
                  variant="mega_secondary_2"
                  onClick={() => setIsSidebarVisible(false)}
                  className="mt-2 w-100">
                  Seguir recargando
                </Button>
              </NavLink>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>
      <div className={styles.top_up_widget} onClick={() => setIsSidebarVisible(!isSidebarVisible)}>
        <ListadoRecargas width={25} className="me-1" />
        {itemsCount}
      </div>
    </>
  );
};
