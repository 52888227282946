import { CallCountry, CallCountryRate} from '../types/call';
import api from './axios/api';

const getCountries = async () => {
  try {
    const response = await api.get<CallCountry>('/api/v1/countries/rates-call');

    if (!!response.array) {
      return response.array;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const getCountryRate = async ({
  country,
  phone  
}: {
  country: string;
  phone: string;
}): Promise<any> => {
  try {
    const response = await api.get<CallCountryRate>('/api/v1/rates/getcallcountryrate', {
      params: {
        country,
        phone
      },
    });

    if (!response.has_error) {
      return response.data;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};


export const CallService = {
  getCountries,
  getCountryRate
};
