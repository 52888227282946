import * as yup from 'yup';
import { errorMessages } from '../../../utilities/errorMessages';

export const shippingInformationFormSchema = yup
  .object()
  .shape({
    firstName: yup.string().required(errorMessages.firstName),
    lastName: yup.string().required(errorMessages.lastName),
    secondLastName: yup.string().required(),
    address: yup.string().required(errorMessages.address1),
    phoneNumber: yup.string().required(errorMessages.phoneNumber),
    municipality: yup.string().required(errorMessages.municipality),
  })
  .required();
