import { Button, Modal } from 'react-bootstrap';
import { BsCheck2Square } from 'react-icons/bs';
import { useGlobalModalContext } from '../../../providers/globalModal.provider';

export const SuccessModal = () => {
  const { hideModal, currentModalProps } = useGlobalModalContext();

  const closeModal = () => {
    hideModal();
    !!currentModalProps?.onClose && currentModalProps.onClose();
  };

  return (
    <Modal
      dialogClassName="mega-modal-success"
      size="sm"
      show={true}
      onHide={closeModal}
      backdrop="static"
      centered>
      <Modal.Header className="text-center bg-secondary mega-modal-title" color="black">
        <div className="text-center w-100">
          <BsCheck2Square size={70} className="text-white" />
        </div>
      </Modal.Header>
      <Modal.Body>
        <p className="text-center">{currentModalProps?.description}</p>
      </Modal.Body>
      <Modal.Footer>
        <div className="text-center w-100">
          <Button variant="secondary" className="px-5" onClick={closeModal}>
            OK
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
