import * as React from 'react';
import { SVGProps } from 'react';
const SvgInstagram = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="instagram_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 26"
    {...props}>
    <defs>
      <clipPath id="instagram_svg__clippath">
        <path
          style={{
            fill: 'none',
          }}
          d="M2.5 2.5h21v21h-21z"
        />
      </clipPath>
      <style>{'.instagram_svg__cls-1{fill:#fff}'}</style>
    </defs>
    <g
      style={{
        clipPath: 'url(#instagram_svg__clippath)',
      }}
      id="instagram_svg__Capa_1-2"
      data-name="Capa 1">
      <g id="instagram_svg__Grupo_96" data-name="Grupo 96">
        <path
          id="instagram_svg__Trazado_81"
          data-name="Trazado 81"
          className="instagram_svg__cls-1"
          d="M12.64 9.06c-2.24 0-4.06 1.82-4.06 4.06s1.82 4.06 4.06 4.06 4.06-1.82 4.06-4.06-1.82-4.06-4.06-4.06"
        />
        <path
          id="instagram_svg__Trazado_82"
          data-name="Trazado 82"
          className="instagram_svg__cls-1"
          d="M23.33 8.05a5.794 5.794 0 0 0-5.38-5.38 72.58 72.58 0 0 0-9.9 0c-2.88.2-5.18 2.49-5.38 5.38a72.58 72.58 0 0 0 0 9.9c.2 2.88 2.49 5.18 5.38 5.38a72.58 72.58 0 0 0 9.9 0c2.88-.2 5.18-2.49 5.38-5.38a72.58 72.58 0 0 0 0-9.9M12.64 18.61c-3.03 0-5.49-2.46-5.49-5.49s2.46-5.49 5.49-5.49 5.49 2.46 5.49 5.49-2.46 5.49-5.49 5.49m5.97-9.78c-.66 0-1.19-.53-1.19-1.19 0-.66.53-1.19 1.19-1.19s1.19.53 1.19 1.19-.53 1.19-1.19 1.19"
        />
      </g>
    </g>
  </svg>
);
export default SvgInstagram;
