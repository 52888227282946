import { useEffect, useState } from 'react';
import { Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { StoreFilter, StoreProductCard, StoreSidebarFilter } from '../../../components/account';
import { MegaLoading } from '../../../components/external';
import { ComboFeatures } from '../../../components/external/comboFeatures';
import { ProductSlider } from '../../../components/external/productSlider/productSlider';
import { Slider } from '../../../components/external/slider/slider';
import { PromoServices } from '../../../services/promotions';
import { StoreService } from '../../../services/store';
import { MegaProductCategory } from '../../../types/products/productCategory';
import { SortOption } from '../../../types/products/sortOption';
import { PromoContent } from '../../../types/promo';
import { Province } from '../../../types/province';
import { QueryTypes } from '../../../types/queryTypes';
import { MegaStoreProduct } from '../../../types/reports/storeProduct';
import styles from './store-screen.module.scss';

export const StoreScreen = () => {
  //Banner
  const [promocontents, setPromoContents] = useState<PromoContent[]>([]);
  const [displayFilter, setDisplayFilter] = useState<boolean>(false);
  const promoContentQuery = useQuery([QueryTypes.GetPromoContents], () => {
    return PromoServices.getPromoContents('COMBOS');
  });

  useEffect(() => {
    if (promoContentQuery.status === 'success') {
      setPromoContents(promoContentQuery.data);
    }
  }, [promoContentQuery.data]);

  const [selectedProvince, setSelectedProvince] = useState<Province | null>(null);
  const [selectedCategory, setSelectedCategory] = useState<MegaProductCategory | null>(null);
  const [selectedSortOption, setSelectedSortOption] = useState<SortOption | null>(null);

  //Products
  const [products, setProducts] = useState<MegaStoreProduct[]>([]);
  const productsQuery = useQuery(
    [QueryTypes.GetStoreProducts, selectedCategory?.id, selectedProvince?.id, selectedSortOption],
    () => {
      return StoreService.getProducts({
        categoryId: selectedCategory?.id,
        productLocation: selectedProvince?.id,
        sortDirection: selectedSortOption?.direction,
        sortField: selectedSortOption?.field,
      });
    },
    {
      cacheTime: 0,
    }
  );

  useEffect(() => {
    if (productsQuery.status === 'success') {
      setProducts(productsQuery.data);
    }
  }, [productsQuery.data]);

  useEffect(() => {
    handleWindowResize(window.innerWidth);

    window.addEventListener('resize', () => {
      handleWindowResize(window.innerWidth);
    });
  }, []);

  const handleWindowResize = (innerWidth: number) => {
    if (innerWidth <= 992 && !displayFilter) {
      setDisplayFilter(true);
    } else if (innerWidth > 992) {
      setDisplayFilter(false);
    }
  };

  return (
    <div className={`${styles.main_background}`}>
      {displayFilter && (
        <div>
          <StoreFilter
            selectedProvince={selectedProvince}
            selectedCategory={selectedCategory}
            selectedSortOption={selectedSortOption}
            onCategoryChange={setSelectedCategory}
            onProvinceChange={setSelectedProvince}
            onSortOptionChange={setSelectedSortOption}
          />
        </div>
      )}
      <div>
        <Slider promoContents={promocontents} autoPlay={false} removeAnimations />
      </div>

      <div className={styles.promoBanner}>
        <div className="container position-relative" style={{ zIndex: 100 }}>
          <ComboFeatures />
        </div>
        <div className={styles.promoBannerBg}></div>
      </div>

      <div className="container mt-5">
        <div className="row">
          <div className="d-none d-lg-block col-lg-3">
            <StoreSidebarFilter
              onCategoryChange={setSelectedCategory}
              onProvinceChange={setSelectedProvince}
              onSortOptionChange={setSelectedSortOption}
            />
          </div>
          <div className="col-lg-9">
            <ProductSlider />

            <div className={`mt-5 ms-3 mb-3 ${styles.area_title}`}>Listado de Productos</div>

            <div className="mb-3">
              {productsQuery.isLoading || (productsQuery.isError && productsQuery.isFetching) ? (
                <MegaLoading isLoading={true} />
              ) : (
                <Row className="">
                  {products.map((product) => (
                    <div className="col-12 col-md-6 col-lg-4 mb-3" key={product.id}>
                      <StoreProductCard product={product} />
                    </div>
                  ))}
                  {(!products || products?.length == 0) && (
                    <div className="p-3">No hay productos con estos filtros.</div>
                  )}
                </Row>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
