import { useContext, useEffect, useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { NavLink, useLocation } from 'react-router-dom';
import { Footer, HeaderNav } from '..';
import { IMAGES } from '../../../config/images';
import { ROUTES, ROUTES_REGISTERED } from '../../../config/routes';
import { MobileMenuContext } from '../../../providers/mobileMenu.provider';
import { SessionContext } from '../../../providers/session.provider';
import { AccountServices } from '../../../services';
import { MegaUserProfile } from '../../../types/profile';
import { QueryTypes } from '../../../types/queryTypes';
import { AccountMenu } from '../../account/accountMenu';
import styles from './layout.module.scss';

export const Layout: React.FC = ({ children }) => {
  const { isMobileMenuOpen, forceCloseMenu } = useContext(MobileMenuContext);
  const { isAuthenticated } = useContext(SessionContext);
  const location = useLocation();
  const { pathname } = location;

  const [profile, setProfile] = useState<MegaUserProfile>();
  const [menuUserList, setMenuUserList] = useState<{ title: string; link: string }[]>([
    { title: 'Mi Cuenta', link: ROUTES.cuenta.inicio },
    { title: 'Llamar / Mis Contactos', link: ROUTES.cuenta.contactos },
    { title: 'Enviar Recargas', link: ROUTES.cuenta.recargas },
    { title: 'Enviar SMS', link: ROUTES.cuenta.sms },
    { title: 'Reportes', link: ROUTES.cuenta.reportes },
    { title: 'Referir Amigos', link: ROUTES.cuenta.referirAmigo },
    { title: 'Mercado', link: ROUTES.cuenta.store },
  ]);
  const profileQuery = useQuery([QueryTypes.GetProfile, isAuthenticated], () => {
    if (isAuthenticated()) {
      return AccountServices.getProfileData();
    } else {
      return { user: { accept_remittance: false } as MegaUserProfile };
    }
  });

  const menuList = [
    { title: 'Inicio', link: ROUTES.external.home },
    { title: 'Llamadas', link: ROUTES.external.services.calls },
    { title: 'Recargas', link: ROUTES.external.services.topups },
    { title: 'Mensajes', link: ROUTES.external.services.messages },
    { title: 'Mercado', link: ROUTES.external.services.combos },
    { title: 'Contáctenos', link: ROUTES.external.contact },
  ];

  useEffect(() => {
    if (profileQuery.data) {
      setProfile(profileQuery.data.user);

      if (profileQuery.data.user.accept_remittance) {
        const menuUserListInit = [...menuUserList];

        if (!menuUserListInit.find((t) => t.title === 'Enviar Remesas')) {
          menuUserListInit.splice(2, 0, {
            title: 'Enviar Remesas',
            link: ROUTES.cuenta.remittance,
          });
        }

        setMenuUserList([...menuUserListInit]);
      }
    }
  }, [profileQuery.data]);

  const isAccountMenu = isAuthenticated() && ROUTES_REGISTERED.includes(pathname);
  const menuToDisplay = () => {
    if (isAuthenticated() && ROUTES_REGISTERED.includes(pathname)) {
      return { menu: menuUserList, css: 'bg-mega_secondary_2' };
    }

    return { menu: menuList, css: '' };
  };

  return (
    <div>
      <Offcanvas show={isMobileMenuOpen} onHide={forceCloseMenu} placement={'end'}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <img
              src={IMAGES.ImageLogo}
              width={183}
              className={`${styles.logo}`}
              alt="Megaconecta Logo"
            />
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className={menuToDisplay().css}>
          <ul className="navbar-nav">
            {menuToDisplay().menu.map((item) => (
              <NavLink
                to={item.link}
                key={item.title}
                className={({ isActive }) =>
                  [
                    isAccountMenu ? styles.menu_item_account : styles.a_menuItem,
                    isActive
                      ? isAccountMenu
                        ? styles.menuItemAccountActive
                        : styles.a_menuItem_active
                      : undefined,
                  ]
                    .filter(Boolean)
                    .join(' ')
                }
                onClick={forceCloseMenu}>
                {item.title}
              </NavLink>
            ))}
          </ul>
        </Offcanvas.Body>
      </Offcanvas>

      <HeaderNav />
      {isAuthenticated() && <AccountMenu />}
      {children}

      <Footer />
    </div>
  );
};
