import * as React from 'react';
import { SVGProps } from 'react';
const SvgEnviarRemesa = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 66" {...props}>
    <g data-name="Capa 1">
      <circle
        cx={42.17}
        cy={23.6}
        r={21.22}
        style={{
          fill: '#8ab934',
        }}
      />
      <path
        d="M47.03 23.41c-.56-.42-1.19-.76-1.87-1.02-.69-.26-1.35-.49-1.98-.7-.44-.14-.84-.28-1.19-.41-.35-.13-.66-.28-.91-.44-.25-.16-.45-.34-.58-.55-.13-.21-.2-.47-.2-.79 0-.37.08-.67.25-.91s.37-.43.62-.58c.25-.15.52-.25.83-.3.31-.05.61-.08.91-.08.58 0 1.18.15 1.81.44.29.13.55.29.78.46.47.35 1.14.29 1.54-.14l1.17-1.24c.51-.54.4-1.41-.23-1.81-.54-.34-1.13-.63-1.76-.85-.94-.33-1.85-.49-2.75-.54v-2.39c-1.22-1.39-2.78-.54-2.78.67v1.95c-.1.02-.21.03-.31.06-.86.22-1.64.57-2.32 1.04-.69.47-1.24 1.08-1.66 1.82-.42.74-.63 1.63-.63 2.66 0 .84.14 1.56.41 2.15.27.59.63 1.09 1.08 1.5.45.41.96.75 1.53 1.02s1.16.49 1.75.69c.62.19 1.17.37 1.65.54.48.17.89.35 1.21.55.33.2.58.43.75.67.18.25.26.55.26.9 0 .37-.07.69-.21.95-.14.26-.33.47-.57.63-.24.16-.51.27-.83.34-.32.07-.64.11-.98.11-.74 0-1.48-.19-2.23-.57-.39-.19-.73-.41-1.04-.66-.48-.38-1.16-.35-1.59.08l-1.26 1.28c-.5.51-.44 1.36.15 1.78.61.44 1.31.81 2.09 1.1.89.33 1.81.54 2.74.64v3.03c1.22 1.39 2.78.54 2.78-.67v-2.46c.35-.06.69-.12 1.03-.22.86-.25 1.63-.62 2.3-1.13.67-.51 1.2-1.16 1.6-1.94.4-.78.59-1.71.59-2.78 0-.93-.18-1.71-.55-2.34-.37-.62-.84-1.15-1.4-1.57Z"
        style={{
          fill: '#fff',
          fillRule: 'evenodd',
        }}
      />
      <path
        d="M23.44 62.71c-1.49 1.21-3.6 1.22-5.07.03A86.292 86.292 0 0 1 3.36 47.03c-1.72-2.34-.3-5.45 2.6-5.81 1.98-.25 4.4-.49 7.15-.68.31-11.95 1.11-21.43 1.5-24.23.14-1.01.67-1.92 1.58-2.39 1.12-.58 2.88-1.26 5.07-1.29 2.23-.02 4.03.66 5.15 1.25.86.45 1.37 1.31 1.51 2.27.39 2.74 1.07 12.23 1.14 24.29 2.73.15 5.12.37 7.09.58 2.9.32 4.28 3.41 2.52 5.78a90.091 90.091 0 0 1-15.22 15.91Z"
        style={{
          fill: '#d0e3ae',
        }}
      />
      <path
        d="M42.17 1.18c-7.94 0-14.92 4.16-18.9 10.41-.59-.1-1.22-.16-1.89-.16h-.13c-2.44.03-4.39.79-5.61 1.42-1.2.63-2.01 1.83-2.21 3.29-.34 2.48-1.14 11.33-1.48 23.28-2.07.16-4.13.36-6.13.61-1.82.23-3.32 1.32-4.01 2.93-.67 1.57-.45 3.35.6 4.77 3 4.07 8.08 10.14 15.23 15.93.94.76 2.09 1.14 3.25 1.14s2.36-.39 3.32-1.18A91.212 91.212 0 0 0 39.63 47.5c.37-.49.63-1.03.8-1.58.58.05 1.16.08 1.75.08 12.36 0 22.41-10.05 22.41-22.41S54.52 1.18 42.17 1.18Zm-4.46 44.9c-2.97 4-7.99 9.97-15.02 15.7-1.04.85-2.54.86-3.56.03A85.688 85.688 0 0 1 4.32 46.32c-.55-.74-.66-1.62-.32-2.41.35-.82 1.14-1.39 2.11-1.51 2.31-.29 4.69-.51 7.08-.67l1.09-.07.03-1.09c.32-12.39 1.14-21.59 1.49-24.1.09-.69.44-1.23.95-1.5.98-.51 2.57-1.13 4.53-1.15h.1c1.94 0 3.5.6 4.48 1.11.64.33.82 1.01.88 1.38.42 2.92 1.06 12.6 1.12 24.13v1.13l1.13.06c2.38.13 4.74.33 7.02.58.95.11 1.7.63 2.05 1.42.34.79.22 1.69-.35 2.45Zm4.46-2.47c-.56 0-1.1-.03-1.65-.08-.06-.29-.15-.58-.27-.86-.69-1.58-2.17-2.64-3.98-2.85-1.97-.22-3.99-.39-6.03-.52-.1-11.25-.72-20.36-1.14-23.33-.2-1.41-.98-2.56-2.14-3.16-.36-.19-.8-.39-1.28-.57 3.62-5.23 9.65-8.67 16.48-8.67 11.04 0 20.02 8.98 20.02 20.02S53.2 43.61 42.16 43.61Z"
        style={{
          fill: '#263645',
        }}
      />
    </g>
  </svg>
);
export default SvgEnviarRemesa;
