import * as nameCase from 'namecase';
import { components, GroupBase, OptionProps, SingleValueProps } from 'react-select';
import { SmsCountryRate } from '../../../types/sms';

const { Option, SingleValue } = components;

export const IconOptionSmsRateCountry = (
  props: OptionProps<SmsCountryRate, false, GroupBase<SmsCountryRate>>
) => {
  return (
    <Option {...props}>
      <div className="contact_row no_border">
        <div className="contact_right">
          <span className={`fi fi-${(props.data.country_code || '').toLowerCase()}`}></span>
        </div>
        <div className="contact_center">
          <div>
            {nameCase(props.data.country)} (+{props.data.calling_code})
          </div>
        </div>
      </div>
    </Option>
  );
};

export const IconSingleValueSmsRateCountry = ({
  children,
  ...props
}: SingleValueProps<SmsCountryRate, false, GroupBase<SmsCountryRate>>) => {
  return (
    <SingleValue {...props}>
      <div>
        <span className={`me-2 fi fi-${(props.data.country_code || '').toLowerCase()}`}></span>
        {nameCase(props.data.country)} (+{props.data.calling_code})
      </div>
    </SingleValue>
  );
};
