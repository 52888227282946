import * as React from 'react';
import { SVGProps } from 'react';
const SvgPaypal = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="paypal_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 26 26"
    {...props}>
    <defs>
      <style>
        {
          '.paypal_svg__cls-1{fill:#253b80}.paypal_svg__cls-1,.paypal_svg__cls-2,.paypal_svg__cls-3{isolation:isolate}.paypal_svg__cls-2{fill:#179bd7}.paypal_svg__cls-3{fill:#222d65}'
        }
      </style>
    </defs>
    <g id="paypal_svg__Capa_1-2" data-name="Capa 1">
      <path
        className="paypal_svg__cls-1"
        d="M21.74 5.75c0 .21-.01.43-.04.65-9.4 6.27-12.03 16.49-12.03 16.49H2.8c-.43 0-.76-.38-.69-.81L5.37 1.01c.09-.55.56-.96 1.12-.96L15.94 0c3.28-.02 5.79 2.66 5.79 5.75Z"
      />
      <path
        className="paypal_svg__cls-1"
        d="M20.35 5.8c.28.07.54.17.79.29a5.32 5.32 0 0 0-.79-.29ZM21.7 6.4c-.03-.02-.05-.03-.08-.05.03.02.05.03.08.05Z"
      />
      <path
        className="paypal_svg__cls-2"
        d="M21.14 6.09a5.32 5.32 0 0 0-.79-.29c.28.07.54.17.79.29ZM23.85 11.11l-.09.63c-.56 3.84-3.85 6.7-7.73 6.7h-1.04c-.53 0-.98.39-1.06.91l-.83 5.95c-.06.41-.42.72-.83.72H8.04c-.44 0-.77-.39-.7-.82l1.55-10.17c2.42-8.34 5.42-9.34 12.82-8.61 1.51.97 2.43 2.77 2.15 4.71Z"
      />
      <path
        className="paypal_svg__cls-2"
        d="M9.13 14.6s.08-.07.12-.11c-.04.03-.08.07-.12.11ZM21.7 6.4c-.03-.02-.05-.03-.08-.05.03.02.05.03.08.05Z"
      />
      <path
        className="paypal_svg__cls-3"
        d="M21.7 6.4s0 .07-.01.11c0 .04-.01.07-.02.11l-.03.19-.03.15-.03.11c0 .03-.01.06-.02.08l-.04.14a9.53 9.53 0 0 1-9.19 6.99H9.87c-.06 0-.12 0-.18.02h-.03c-.02 0-.03 0-.05.01-.03 0-.05.01-.08.02-.09.03-.18.07-.26.13-.01 0-.02.02-.03.02-.04.03-.08.07-.12.11-.02.02-.04.04-.05.06-.01.02-.03.03-.04.05-.03.03-.05.07-.07.1-.01.02-.02.04-.03.05-.02.05-.05.1-.06.15l1.27-8.34c.09-.56.57-.98 1.14-.98l7.8-.05h.4c.04 0 .09 0 .13.01h.05c.11.01.21.03.32.05.05 0 .1.02.14.03.02 0 .04 0 .06.01h.02l.14.03c.1.03.2.06.31.09.17.06.33.12.49.2.04.02.08.04.13.06.04.02.07.04.11.06.04.02.07.04.11.06.04.03.09.05.13.08.03.02.05.03.08.05Z"
      />
      <path className="paypal_svg__cls-3" d="M9.26 14.49s-.08.07-.12.11c.04-.04.08-.07.12-.11Z" />
    </g>
  </svg>
);
export default SvgPaypal;
