import { BrowserRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ScrollToTop } from './components/external';
import { GlobalModal } from './providers/globalModal.provider';
import { MegaQueryProvider } from './providers/megaQuery.provider';
import { MobileMenuProvider } from './providers/mobileMenu.provider';
import { SessionProvider } from './providers/session.provider';
import { MegaRouter } from './routes';

import { ShoppingCartProvider } from './context/ShoppingCartContext';
import { TopUpProvider } from './context/TopupContext';

import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
import './App.scss';

function App() {
  return (
    <ShoppingCartProvider>
      <TopUpProvider>
        <BrowserRouter>
          <MegaQueryProvider>
            <SessionProvider>
              <MobileMenuProvider>
                <GlobalModal>
                  <MegaRouter />

                  <ScrollToTop />
                </GlobalModal>
              </MobileMenuProvider>
            </SessionProvider>
          </MegaQueryProvider>
        </BrowserRouter>
        <ToastContainer />
      </TopUpProvider>
    </ShoppingCartProvider>
  );
}

export default App;
