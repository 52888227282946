import { Formatter } from '../../../utilities';
import styles from './cartTotal.module.scss';

export const CartTotal = ({
  referralBalance = 0,
  subtotal,
}: {
  referralBalance?: number;
  subtotal: number;
}) => {
  const referralDiscount = Math.min(subtotal, referralBalance);
  const total = subtotal - referralDiscount;

  return (
    <>
      {referralBalance > 0 ? (
        <>
          <div className={styles.subtotal}>
            <span>Subtotal: </span>
            {Formatter.toCurrency(subtotal)}
          </div>
          <div className={styles.subtotal}>
            <span>Balance de referidos: </span>-{Formatter.toCurrency(referralDiscount)}
          </div>
          <div className={styles.total}>
            <span>Total a pagar: </span>
            {Formatter.toCurrency(total)}
          </div>
        </>
      ) : (
        <div className={styles.subtotal}>
          <span>Total a pagar: </span>
          {Formatter.toCurrency(subtotal)}
        </div>
      )}
    </>
  );
};
