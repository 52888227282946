import { IMAGES } from '../../../config/images';
import { PalomaVerde } from '../../svg';

import styles from './ahorraSection.module.scss';

export const AhorraSection = () => {
  return (
    <div className={styles.wrapper}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-lg-6 d-flex d-lg-none align-items-center">
            <img src={IMAGES.Promos.PromoTarifas} className="img-fluid" />
          </div>
          <div className="col-12 col-lg-6">
            <h1 className="text-secondary text-decoration-underline mt-5 text-center text-lg-start">
              Ahorra más con nosotros
            </h1>
            <h1 className="text-center text-lg-start">
              Sencillo, Rápido <br />y Barato...
            </h1>

            <p className="mt-5 pe-5 pe-xl-10">
              Garantizamos las mejores tarifas y precios de recargas desde el mundo para Cuba. Para
              tu comodidad, disponemos de pago en efectivo o tarjetas prepagadas en varios países de
              Latinoamérica.
            </p>

            <h6>Recargas</h6>

            <ul className={styles.listItemsRecarga}>
              <li>
                <PalomaVerde width={15} /> Recarga Móviles
              </li>
              <li>
                <PalomaVerde width={15} /> Recarga Nauta
              </li>
              <li>
                <PalomaVerde width={15} /> Recarga Datos Móviles
              </li>
            </ul>
          </div>
          <div className="col-12 col-lg-6 d-none d-lg-flex align-items-center">
            <img src={IMAGES.Promos.PromoTarifas} className="img-fluid" />
          </div>
        </div>
      </div>
    </div>
  );
};
