import * as React from 'react';
import { SVGProps } from 'react';
const SvgInfoPago = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" {...props}>
    <g data-name="Capa 1">
      <path
        d="M22.12 3.67c-.23-1.42-1.03-2.24-2.42-2.51-1.96-.39-3.95-.4-5.93-.52-.74-.05-1.47-.07-2.21-.07h-.14c-.74 0-1.47.03-2.21.07-1.97.12-3.97.14-5.93.52-1.4.28-2.2 1.09-2.42 2.51-.4 2.51-.5 5.05-.23 7.55.55 5.13 3.41 8.61 8.14 10.62.9.38 1.78.58 2.64.59s.04 0 .07 0h.07c.87-.01 1.75-.21 2.64-.59 4.73-2.02 7.59-5.49 8.14-10.62.27-2.5.17-5.04-.23-7.55Z"
        style={{
          opacity: 0.4,
          fill: '#263645',
        }}
      />
      <path
        data-name="Uni\xF3n 5"
        d="M6.42 11.55q.93-1.62 4.18.26h.01c.29-.5.62-1.08 1.01-1.75q2.84-4.92 4.46-3.99t-1.22 5.86c-.64 1.12-1.14 1.98-1.55 2.63-.09.21-.23.45-.4.74q-.93 1.62-4.18-.26-3.25-1.87-2.31-3.49Z"
        style={{
          fill: '#263645',
        }}
      />
    </g>
  </svg>
);
export default SvgInfoPago;
