import * as React from 'react';
import { SVGProps } from 'react';
const SvgProvinciaSelect = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Grupo 234" viewBox="0 0 23 23" {...props}>
    <defs>
      <clipPath id="ProvinciaSelect_svg__a">
        <path data-name="Rect\xE1ngulo 262" fill="#fe4a81" d="M0 0h23v23H0z" />
      </clipPath>
    </defs>
    <g data-name="Grupo 232" clipPath="url(#ProvinciaSelect_svg__a)" fill="#fe4a81">
      <path
        data-name="Trazado 231"
        d="M11.5.2a9.757 9.757 0 0 0-9.746 9.742 12.582 12.582 0 0 0 3.673 8.452 23.676 23.676 0 0 0 4.9 4.074 2.2 2.2 0 0 0 2.342 0 23.7 23.7 0 0 0 4.9-4.074 12.586 12.586 0 0 0 3.673-8.452A9.757 9.757 0 0 0 11.5.2m5.049 17.273a22.628 22.628 0 0 1-4.607 3.827.83.83 0 0 1-.884 0 22.6 22.6 0 0 1-4.607-3.827 11.261 11.261 0 0 1-3.317-7.531 8.366 8.366 0 1 1 16.732 0 11.261 11.261 0 0 1-3.317 7.527"
      />
      <path
        data-name="Trazado 232"
        d="M11.5 4.773a4.311 4.311 0 1 0 4.309 4.31 4.315 4.315 0 0 0-4.309-4.31m2.072 6.382a2.931 2.931 0 1 1 .857-2.072 2.911 2.911 0 0 1-.857 2.072"
      />
    </g>
  </svg>
);
export default SvgProvinciaSelect;
