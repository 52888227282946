import { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  FlechaDer,
  FondoRelleno,
  Mas,
  OvalWrapper,
  PalomAzul,
  PanelControlAzul,
  Promociones,
  SoporteTecnicoAzul,
} from '../../../components/svg';
import { IMAGES } from '../../../config/images';
import { ROUTES } from '../../../config/routes';
import { SessionContext } from '../../../providers/session.provider';

import {
  DownloadAppCommon,
  RegisterSection,
  ThreeColumnIconSection,
} from '../../../components/external';
import styles from './service-topups-screen.module.scss';
import { useQuery } from 'react-query';
import { QueryTypes } from '../../../types/queryTypes';
import { PromoContent } from '../../../types/promo';
import { PromoServices } from '../../../services/promotions';

export const ServiceTopupsScreen = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(SessionContext);

  const [category, setCategory] = useState<string>('TOPUP');
  const [promocontent, setPromoContent] = useState<PromoContent>();

  const promoContentQuery = useQuery([QueryTypes.GetPromoContent, category], () => {
    return PromoServices.getPromoContent(category);
  });

  useEffect(() => {
    if (promoContentQuery.status === 'success') {
      setPromoContent(promoContentQuery.data);
    }
  }, [promoContentQuery.data]);

  useEffect(() => {
    if (isAuthenticated()) {
      navigate(ROUTES.cuenta.inicio);
      return;
    }
    76;
  }, []);

  const threeColumns = {
    title: 'Te garantizamos <span class="text-info text-decoration-underline">lo mejor</span>',
    mainColor: '#D4E1FD',
    columns: [
      {
        title: 'Promociones',
        icon: Promociones,
        description:
          'Te ofrecemos las mejores promociones. Puedes estar seguro de que siempre vas a encontrar el mejor precio.',
      },
      {
        title: 'Panel de Control',
        icon: PanelControlAzul,
        description:
          'Accede fácilmente a ver tu historial de llamadas, pagos y de marcación directa.',
      },
      {
        title: 'Soporte Técnico',
        icon: SoporteTecnicoAzul,
        description:
          'Nuestros agentes especializados están disponibles para atender todas tus dudas y sugerencias.',
      },
    ],
  };

  return (
    <div>
      <div className={styles.wrapper}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 d-flex align-items-center">
              <img
                src={process.env.PUBLIC_URL + '/' + promocontent?.banner}
                className="img-fluid"
              />
            </div>
            <div
              className="col-12 col-lg-6 mt-4 mt-lg-0 d-flex flex-row align-items-center"
              dangerouslySetInnerHTML={{ __html: promocontent?.content }}></div>
          </div>

          <div className="row mt-7">
            <div className="col-12 col-lg-6 d-lg-none d-flex align-items-center">
              <img src={IMAGES.Promos.PromoNauta} className="img-fluid" />
            </div>
            <div className="col-12 col-lg-6 mt-4 mt-lg-0 d-flex flex-row align-items-center">
              <div>
                <h1 className="text-center text-lg-start text-info text-decoration-underline">
                  ¿Se acabó el internet?
                </h1>
                <h1 className="pe-md-8 text-center text-lg-start">
                  ¡No cojas lucha! Recarga Nauta a Cuba
                </h1>

                <p className="mt-6 text-center text-lg-start p-0 pe-lg-5">
                  Recarga Nauta y Nauta Hogar de la manera más fácil con Nautatel para que puedas
                  mantenerte siempre conectado con tus familiares en Cuba y puedan navegar en
                  internet, hacer videollamadas y acceder a sus cuentas de correo electrónico..
                </p>

                <p className="mt-4 text-center text-lg-start p-0 pe-lg-5">
                  Con la recarga Nauta, ellos navegan más horas y tú ahorras más.
                </p>

                <div className="text-center text-lg-start">
                  <Link to={ROUTES.cuenta.recargas} className="btn btn-info text-white px-4">
                    ¡Recargar ahora! <FlechaDer width={17} fill="#fff" className="ms-2" />
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-12 col-lg-6 d-none d-lg-flex align-items-center">
              <img src={IMAGES.Promos.PromoNauta} className="img-fluid" />
            </div>
          </div>
        </div>
      </div>

      <div className="container my-10">
        <div className="row">
          <div className="col-12">
            <h1 className="text-center text-info text-decoration-underline">¡No tienes datos!</h1>
            <h1 className="text-center">Recarga de datos móviles</h1>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-12 col-md-2 col-lg-3"></div>
          <div className="col-12 col-md-8 col-lg-6">
            <p className="text-center">
              Te presentamos 3 Paquetes * para que elijas el que mejor se ajuste a tus necesidades.
              Validos por 30 días a partir de recibir la recarga y no es transferible entre
              usuarios. Los minutos y SMS pueden ser utilizados tanto para llamadas/mensajes
              nacionales como internacionales.
            </p>
          </div>
          <div className="col-12 col-md-2 col-lg-3"></div>
        </div>
        <div className="row my-4">
          <div className="col-12 col-lg-4 d-flex flex-row justify-content-center justify-content-lg-end mb-5 mb-lg-0">
            <div className={styles.paqueteBasico}>
              <p>Paquete Básico</p>
              <div className="d-flex flex-row align-items-end">
                <h1 className={styles.priceHeight}>9GB</h1>
                <h5 className={`text-info pb-1 ${styles.priceHeight}`}>/ $21.99</h5>
              </div>

              <h6>Características</h6>

              <ul className={styles.listItemsRecarga}>
                <li>
                  <PalomAzul width={15} className="me-2" /> 4GB
                </li>
                <li>
                  <PalomAzul width={15} className="me-2" />+ 5GB LTE
                </li>
                <li>
                  <PalomAzul width={15} className="me-2" />
                  75 MIN nacionales
                </li>
                <li>
                  <PalomAzul width={15} className="me-2" />
                  80 SMS nacionales
                </li>
              </ul>

              <p>Válido por 30 dias</p>
            </div>
          </div>
          <div className="col-12 col-lg-4 d-flex flex-row justify-content-center mb-5 mb-lg-0">
            <div className={styles.paquetePopular}>
              <div className="d-flex flex-row">
                <div className="w-50">
                  <p>Paquete Estrella</p>
                </div>
                <div className="w-50 text-end">
                  <span className="badge bg-info me-2">Popular</span>
                </div>
              </div>

              <div className="d-flex flex-row align-items-end">
                <h1 className={styles.priceHeight}>14GB</h1>
                <h5 className={`text-info pb-1 ${styles.priceHeight}`}>/ $32.99</h5>
              </div>

              <h6>Características</h6>

              <ul className={styles.listItemsRecarga}>
                <li>
                  <PalomAzul width={15} className="me-2" /> 4GB
                </li>
                <li>
                  <PalomAzul width={15} className="me-2" />+ 10GB LTE
                </li>
                <li>
                  <PalomAzul width={15} className="me-2" />
                  115 MIN nacionales1
                </li>
                <li>
                  <PalomAzul width={15} className="me-2" />
                  120 SMS nacionales
                </li>
              </ul>

              <p>Válido por 30 dias</p>
            </div>
          </div>
          <div className="col-12 col-lg-4 d-flex flex-row justify-content-center justify-content-lg-start mb-5 mb-lg-0">
            <div className={styles.paqueteBasico}>
              <p>Paquete Premium</p>
              <div className="d-flex flex-row align-items-end">
                <h1 className={styles.priceHeight}>20GB</h1>
                <h5 className={`text-info pb-1 ${styles.priceHeight}`}>/ $43.98</h5>
              </div>

              <h6>Características</h6>

              <ul className={styles.listItemsRecarga}>
                <li>
                  <PalomAzul width={15} className="me-2" /> 4GB
                </li>
                <li>
                  <PalomAzul width={15} className="me-2" />+ 16GB LTE
                </li>
                <li>
                  <PalomAzul width={15} className="me-2" />
                  165 MIN nacionales1
                </li>
                <li>
                  <PalomAzul width={15} className="me-2" />
                  160 SMS nacionales
                </li>
              </ul>

              <p>Válido por 30 dias</p>
            </div>
          </div>
        </div>
      </div>

      <ThreeColumnIconSection {...threeColumns} />

      <DownloadAppCommon bgColor="#EEF3FE" appStoreClassName="info" />

      <RegisterSection color="info" arrowColor="#5586F7" />
    </div>
  );
};
