import { Button } from 'react-bootstrap';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import { Formatter } from '../../../utilities/Formatter';
import { productImgUrl } from '../../../utilities/productImgUrl';

import { CartMas, CartMenos, CartRemove } from '../../svg';
import styles from './shoppingCartProductList.module.scss';

interface ShoppingCartProductListProps {
  allowToModify?: boolean;
  isCheckout?: boolean;
}

export const ShoppingCartProductList = ({
  allowToModify,
  isCheckout,
}: ShoppingCartProductListProps) => {
  const { products, removeProduct, updateProduct } = useShoppingCart();

  return (
    <div className="">
      {products.map((product, index) => (
        <div
          className={`row mx-0 align-items-start align-items-lg-center ${styles.product} ${
            isCheckout ? styles.checkout : ''
          } `}
          key={`product-${index}`}>
          <div className="col-2">
            <img className={styles.product_img} src={productImgUrl(product.main_image.image_url)} />
          </div>

          <div className="col-10 col-lg-8 d-flex flex-column">
            <div className="mega-fs-larger mega-bold mt-0 mb-2">{product.name}</div>
            <div className="mega-fs-store">{product.description}</div>
            <h6 className="text-secondary mt-2">{Formatter.toCurrency(product.price)}</h6>
            {product.deliveries.map((delivery, index) => (
              <div className="" key={`product-card--detail-${product.id}-${index}`}>
                <span className="mega-fs-store">{delivery.name}: </span>
                <span className="mega-fs-store text-secondary">{delivery.duration}</span>
              </div>
            ))}
          </div>

          <div className="offset-2 col-8 offset-lg-0 col-lg-2 mt-3 mt-lg-0">
            {allowToModify && (
              <div className="d-flex flex-row flex-lg-column align-items-end justify-content-start justify-content-lg-center d-grid gap-1">
                {product.shopping_cart_quantity > 1 && (
                  <Button
                    variant="link"
                    className="p-0"
                    onClick={() => updateProduct(product.id, -1)}>
                    <CartMenos width={30} />
                  </Button>
                )}
                {product.shopping_cart_quantity == 1 && (
                  <Button variant="link" className="p-0" onClick={() => removeProduct(product.id)}>
                    <CartRemove width={30} />
                  </Button>
                )}
                <div className={`${styles.quantity}`}>{product.shopping_cart_quantity}</div>

                <Button variant="link" className="p-0" onClick={() => updateProduct(product.id, 1)}>
                  <CartMas width={30} />
                </Button>
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};
