import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import { Formatter } from '../../../utilities/Formatter';
import styles from './shoppingCartTotals.module.scss';

type ShoppingCartTotalsParams = {
  referralBalance?: number;
};

export const ShoppingCartTotals = ({ referralBalance = 0 }: ShoppingCartTotalsParams) => {
  const { productsTotal, shippingInformation, getProductsTotalCount } = useShoppingCart();
  const navigate = useNavigate();

  const openPaymentScreen = () => {
    navigate(ROUTES.cuenta.payment);
  };

  const subtotal = productsTotal;
  const deliveryFee = shippingInformation.deliveryFee;
  const referralDiscount = Math.min(subtotal, referralBalance);
  const total = subtotal - referralDiscount;

  return (
    <div className={styles.frame}>
      <div>
        <div className={styles.title}>Resumen</div>
      </div>

      <div className="d-flex flex-row mt-2">
        <div className="mega-fs-store flex-fill">Subtotal</div>
        <div className="mega-fs-store">{Formatter.toCurrency(subtotal)}</div>
      </div>

      <div className="d-flex flex-row mt-2">
        <div className="mega-fs-store flex-fill">Gastos de envío</div>
        <div className="mega-fs-store text-secondary">{Formatter.toCurrency(deliveryFee)}</div>
      </div>

      <div className="d-flex flex-row mt-2">
        <div className="mega-fs-store flex-fill mega-bold">Total a pagar</div>
        <div className="mega-fs-store mega-bold">{Formatter.toCurrency(total)}</div>
      </div>

      <Button variant="secondary" className="mt-3 w-100" onClick={openPaymentScreen}>
        Comprar ({getProductsTotalCount})
      </Button>
    </div>
  );
};
