import styles from './reports-screen.module.scss';

import { useEffect, useState } from 'react';

import { Offcanvas } from 'react-bootstrap';
import { GiHamburgerMenu } from 'react-icons/gi';
import { useLocation } from 'react-router-dom';
import { PageTitle } from '../../../components/account';
import {
  CallReport,
  FundReport,
  RemittanceReport,
  SmsReport,
  StoreReport,
  TopUpReport,
} from '../../../components/account/reports';
import {
  Deslizar,
  ReporteLlamada,
  ReporteMisCompras,
  ReportePago,
  ReporteRecargas,
  ReporteRemesas,
  ReporteSms,
  ReportesPage,
} from '../../../components/svg';

type reportTypes = 'pagos' | 'llamadas' | 'recargas' | 'sms' | 'compras' | 'remesas';
type ReportItem = {
  id: reportTypes;
  name: string;
  icon: any;
};

export const ReportsScreen = () => {
  const reportList: ReportItem[] = [
    { id: 'pagos', name: 'Pagos', icon: ReportePago },
    { id: 'llamadas', name: 'Llamadas', icon: ReporteLlamada },
    { id: 'recargas', name: 'Recargas', icon: ReporteRecargas },
    { id: 'remesas', name: 'Remesas', icon: ReporteRemesas },
    { id: 'sms', name: 'SMS', icon: ReporteSms },
    { id: 'compras', name: 'Mis Compras', icon: ReporteMisCompras },
  ];

  const [currentReport, setCurrentReport] = useState<ReportItem>(reportList[0]);
  const [showOffCanvas, setShowOffCanvas] = useState<boolean>(false);

  const location = useLocation();

  const getReportData = (id: string) => {
    return reportList.find((r) => r.id === id) || reportList[0];
  };

  useEffect(() => {
    if (location.state) {
      setCurrentReport(getReportData(location.state as string));
    }
  }, []);

  const getDeslizarMessage = () => (
    <div className="col-12 d-block d-md-none">
      <div className={styles.wrapperDeslizar}>
        <div>
          <Deslizar width={30} />
        </div>
        <div>
          <p>Deslice el dedo hacia la izquierda y la derecha para ver más información.</p>
        </div>
      </div>
    </div>
  );

  const renderReport = () => {
    switch (currentReport.id) {
      case 'pagos':
        return <FundReport>{getDeslizarMessage()}</FundReport>;
      case 'llamadas':
        return <CallReport>{getDeslizarMessage()}</CallReport>;
      case 'recargas':
        return <TopUpReport>{getDeslizarMessage()}</TopUpReport>;
      case 'sms':
        return <SmsReport>{getDeslizarMessage()}</SmsReport>;
      case 'compras':
        return <StoreReport>{getDeslizarMessage()} </StoreReport>;
      case 'remesas':
        return <RemittanceReport>{getDeslizarMessage()} </RemittanceReport>;
    }
  };

  const changeSelectedReport = (
    report: ReportItem,
    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    event.preventDefault();

    if (report.id === currentReport.id) {
      return;
    }

    setCurrentReport(report);

    if (showOffCanvas) {
      setShowOffCanvas(false);
    }
  };

  return (
    <div className="bg-mega_light_gray">
      <div className="container py-5">
        <PageTitle
          title="Reportes"
          description="Seleccione el tipo de reporte que desea ver. Puede especificar rangos de fecha, buscar por números telefónicos y organizar los resultados (ascendente o descendentes) por columnas con solo dar un click en el nombre de la columna."
          icon={ReportesPage}
        />

        <div className="row">
          <div className={`d-none d-lg-block col-lg-3`}>
            <div className={styles.contactWrapper}>
              {reportList.map((report) => (
                <div
                  key={report.id}
                  className={`${styles.contactRowWrapper} ${
                    report.id === currentReport.id ? styles.activeRow : ''
                  }`}>
                  <a
                    href="#"
                    onClick={(e) => setCurrentReport(report)}
                    className={styles.contact_link}>
                    <div
                      className={`${styles.contact_row} ${
                        report.id === currentReport.id ? styles.active : ''
                      }`}>
                      <div className={styles.contact_right}>
                        <report.icon
                          width={20}
                          className={`${
                            report.id === currentReport.id
                              ? styles.activeIconRow
                              : styles.inActiveIconRow
                          }`}
                        />
                      </div>
                      <div
                        className={`${styles.contact_center} ${
                          report.id === currentReport.id ? styles.active : ''
                        }`}>
                        {report.name}
                      </div>
                    </div>
                  </a>
                </div>
              ))}
            </div>
          </div>
          <div className="d-block d-lg-none col-12 mb-3">
            <div className={`${styles.contactWrapper} mb-4`}>
              <div className={`${styles.contactRowWrapper}`}>
                <a
                  href="#"
                  onClick={(e) => setCurrentReport(currentReport)}
                  className={styles.contact_link}>
                  <div className={`${styles.contact_row} ${styles.alone}`}>
                    <div className={styles.contact_right}>
                      <currentReport.icon width={20} className={`${styles.activeIconRow}`} />
                    </div>
                    <div className={`${styles.contact_center} ${styles.active}`}>
                      {currentReport.name}
                    </div>

                    <div className={styles.contact_left}>
                      <GiHamburgerMenu
                        size={25}
                        color={'#616161'}
                        onClick={() => setShowOffCanvas(true)}
                      />
                    </div>
                  </div>
                </a>
              </div>

              <Offcanvas
                id="megaCanvas"
                show={showOffCanvas}
                onHide={() => setShowOffCanvas(false)}
                placement="end">
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title>Reportes</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <div className="">
                    {reportList.map((report) => (
                      <div
                        key={report.id}
                        className={`${styles.contactRowWrapper} ${
                          report.id === currentReport.id ? styles.activeRow : ''
                        }`}>
                        <a
                          href="#"
                          onClick={(e) => changeSelectedReport(report, e)}
                          className={styles.contact_link}>
                          <div
                            className={`${styles.contact_row} ${
                              report.id === currentReport.id ? styles.active : ''
                            }`}>
                            <div className={styles.contact_right}>
                              <report.icon
                                width={20}
                                className={`${
                                  report.id === currentReport.id
                                    ? styles.activeIconRow
                                    : styles.inActiveIconRow
                                }`}
                              />
                            </div>
                            <div
                              className={`${styles.contact_center} ${
                                report.id === currentReport.id ? styles.active : ''
                              }`}>
                              {report.name}
                            </div>
                          </div>
                        </a>
                      </div>
                    ))}
                  </div>
                </Offcanvas.Body>
              </Offcanvas>
            </div>
          </div>

          <div className="col-12 col-lg-9">{renderReport()}</div>
        </div>
      </div>
    </div>
  );
};
