import * as React from 'react';
import { SVGProps } from 'react';
const SvgAgregar = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" {...props}>
    <g data-name="Capa 1">
      <circle
        cx={11.5}
        cy={11.5}
        r={11.5}
        style={{
          fill: '#8ab934',
        }}
      />
      <path
        d="M16.83 11.55c0-1.63 0-1.84-3.59-1.87-.03-3.59-.24-3.59-1.87-3.59s-1.84 0-1.87 3.59c-3.59.03-3.59.24-3.59 1.87s0 1.84 3.59 1.87c.03 3.59.24 3.59 1.87 3.59s1.84 0 1.87-3.59c3.59-.03 3.59-.24 3.59-1.87Z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);
export default SvgAgregar;
