import * as React from 'react';
import { SVGProps } from 'react';
const SvgFilterCombo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <g data-name="Grupo 16768">
      <g data-name="Grupo 16767" fill="#616161">
        <path
          data-name="Trazado 34107"
          d="M29.64 4.43A4.4 4.4 0 0 0 25.59.37C23.55.2 20.06 0 15 0S6.45.2 4.42.36A4.407 4.407 0 0 0 .36 4.42C.12 7.42 0 10.98 0 15c0 5.04.2 8.54.36 10.58a4.414 4.414 0 0 0 4.05 4.06c2.03.17 5.51.36 10.59.36s8.54-.2 10.58-.36a4.4 4.4 0 0 0 4.06-4.05c.17-2.04.36-5.53.36-10.59s-.2-8.55-.36-10.57Zm-1.97 21.01a2.408 2.408 0 0 1-2.24 2.23c-2 .16-5.44.36-10.43.36s-8.43-.19-10.43-.36a2.414 2.414 0 0 1-2.23-2.23c-.16-2.02-.36-5.45-.36-10.44 0-3.91.12-7.51.35-10.43a2.408 2.408 0 0 1 2.24-2.23c2-.16 5.44-.36 10.43-.36s8.43.19 10.43.36a2.408 2.408 0 0 1 2.23 2.24c.16 2 .36 5.43.36 10.42s-.19 8.42-.36 10.44Z"
        />
        <path
          data-name="Trazado 34108"
          d="M23.67 8.68h-8.51a3.629 3.629 0 0 0-7 0H6.33a.99.99 0 0 0 0 1.98h1.84a3.633 3.633 0 0 0 7 0h8.52a.99.99 0 0 0 0-1.98Zm-12.02 2.66a1.68 1.68 0 1 1 1.68-1.68 1.677 1.677 0 0 1-1.68 1.68Z"
        />
        <path
          data-name="Trazado 34109"
          d="M23.67 19.35h-1.84a3.629 3.629 0 0 0-7 0h-8.5a.99.99 0 1 0 0 1.98h8.51a3.633 3.633 0 0 0 7 0h1.84a.99.99 0 1 0 0-1.98Zm-5.34 2.66a1.68 1.68 0 1 1 1.68-1.68 1.677 1.677 0 0 1-1.68 1.68Z"
        />
      </g>
    </g>
  </svg>
);
export default SvgFilterCombo;
