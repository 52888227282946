import { IMAGES } from '../../../config/images';
import { Estrella } from '../../svg';

import styles from './reviewSection.module.scss';

export const ReviewSection = () => {
  const reviews = [
    {
      name: 'Juan Ramos',
      photo: IMAGES.Reviews.Review1,
      content:
        '"Considero que la oferta promocional de Megaconecta para recargar saldo a teléfonos en Cuba es la mas económica y también el proceso para realizar la compra es muy fácil y rápido. Megaconecta es mi sitio favorito para este tipo de gestión y la comunicación con mis familiares en Cuba."',
    },

    {
      name: 'Marlon Perez',
      photo: IMAGES.Reviews.Review2,
      content:
        '"Calidad, Capacidad de respuesta, Profesionalidad Llame pidiendo ayuda y respondieron al instante. También; resolvieron el problema. Muchas gracias por el buen servicio."',
    },

    {
      name: 'Beatriz Maria',
      photo: IMAGES.Reviews.Review3,
      content:
        '"Megaconecta ha sido mi puente de comunicación desde hace más de dos años. En todo ese tiempo no he tenido ningún problema o duda que no solucionen en cuestión de minutos. Megaconecta es la única vía de comunicaciones que empleo con la Isla. 300% recomendado!"',
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-6 col-lg-2 d-flex d-md-none align-items-center justify-content-center">
            <img src={IMAGES.Promos.PromoAbstract1} className={`img-fluid ${styles.imageAbs}`} />
          </div>
          <div className="col-12 col-md-6 col-lg-10">
            <h1 className="text-center text-md-start mb-8 mb-md-0">
              Y nuestros clientes no
              <br /> podrían estar{' '}
              <span className="text-secondary text-decoration-underline">más felices</span>
            </h1>
          </div>
          <div className="col-12 col-md-6 col-lg-2 d-none d-md-flex align-items-center">
            <img src={IMAGES.Promos.PromoAbstract1} className="img-fluid" />
          </div>
        </div>

        <div className="row">
          {reviews.map((review) => (
            <div className="col-12 col-md-4 mb-5" key={review.name}>
              <div className={styles.reviewHeader}>
                <div className={styles.reviewHeaderImageWrapper}>
                  <img src={review.photo} className="img-fuild" />
                </div>
                <div className={styles.reviewHeaderContentWrapper}>
                  <p className="p-0 m-0">{review.name}</p>
                  <div>
                    <Estrella width={15} className="me-1" />
                    <Estrella width={15} className="me-1" />
                    <Estrella width={15} className="me-1" />
                    <Estrella width={15} className="me-1" />
                    <Estrella width={15} />
                  </div>
                </div>
              </div>

              <div className="pe-4 mt-2">
                <p className="m-0 p-0 fst-italic text-center text-md-start">{review.content}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
