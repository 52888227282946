import { createContext, useState } from 'react';

const MobileMenuContext = createContext({
  isMobileMenuOpen: false,
  toggleMenu: () => {},
  forceCloseMenu: () => {},
  forceOpenMenu: () => {},
});

const MobileMenuProvider = ({ children }: { children: any }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const forceCloseMenu = () => {
    setIsMobileMenuOpen(false);
  };

  const forceOpenMenu = () => {
    setIsMobileMenuOpen(true);
  };

  return (
    <MobileMenuContext.Provider
      value={{ isMobileMenuOpen, toggleMenu, forceCloseMenu, forceOpenMenu }}>
      {children}
    </MobileMenuContext.Provider>
  );
};

export { MobileMenuContext, MobileMenuProvider };
