import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  AhorraSection,
  DownloadApp,
  RegisterSection,
  ReviewSection,
  ServicesColumn,
} from '../../../components/external';
import { Slider } from '../../../components/external/slider/slider';
import { PromoServices } from '../../../services/promotions';
import { PromoContent } from '../../../types/promo';
import { QueryTypes } from '../../../types/queryTypes';

export const HomepageScreen = () => {
  const [category, setCategory] = useState<string>('MAINSLIDER');
  const [promocontents, setPromoContents] = useState<PromoContent[]>([]);

  const promoContentQuery = useQuery([QueryTypes.GetPromoContents, category], () => {
    return PromoServices.getPromoContents(category);
  });

  useEffect(() => {
    if (promoContentQuery.status === 'success') {
      setPromoContents(promoContentQuery.data);
    }
  }, [promoContentQuery.data]);

  return (
    <div>
      <Slider promoContents={promocontents} autoPlay />

      <ServicesColumn />

      <DownloadApp />

      <AhorraSection />

      <ReviewSection />

      <RegisterSection color="secondary" arrowColor="#8AB934" />
    </div>
  );
};
