import { Modal } from 'react-bootstrap';
import {
  AmericanExpress,
  FlechaDer,
  InfoPago,
  MasterCard,
  Paypal,
  TarjetaCredito,
  Visa
} from '../../svg';

import styles from './paymentMethodSelector.module.scss';

export type PaymentMethodSelectorProps = {
  display: boolean;
  onCancel: () => void;
  onSave: (selectedMethod: 'cc' | 'paypal') => void;
};

export const PaymentMethodSelector = (props: PaymentMethodSelectorProps) => {
  return (
    <Modal
      size="lg"
      show={props.display}
      onHide={props.onCancel}
      backdrop={'static'}
      centered
      backdropClassName="mega-double-backdrop"
      dialogClassName="mega-double-dialog">
      <Modal.Header closeButton className="mega-modal-title">
        <Modal.Title>Seleccionar medio de Pago</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div className="container py-3">
          <div className="row">
            <div className="col-12">
              <div
                className={`${styles.methodWrapper} d-flex w-100 flex-column`}
                role="button"
                onClick={() => props.onSave('cc')}>
                <div className="d-flex flex-row w-100">
                  <TarjetaCredito width={28} className="me-2" />{' '}
                  <div className="flex-fill">Agregar tarjeta de crédito / debito</div>
                  <div className="d-none d-lg-flex flex-row ">
                    <Visa width={40} className="me-2" />
                    <MasterCard width={30} className="me-2" />
                    <AmericanExpress width={35} />
                  </div>
                  <div>
                    <FlechaDer width={20} fill={'#8AB934'} className="ms-5" />
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 mt-5">
              <div
                className={`${styles.methodWrapper} d-flex w-100 flex-row`}
                role="button"
                onClick={() => props.onSave('paypal')}>
                <Paypal width={28} className="me-2" />
                <div className="flex-fill">Paypal</div>
                <div>
                  <FlechaDer width={20} fill={'#8AB934'} className="ms-5" />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-5 ">
            <div className="col-12 d-flex flex-row align-items-center">
              <p className="mb-2 mb-lg-0 text-center text-lg-left">
                <InfoPago width={18} className="me-1" /> Su información de pago está segura con
                nosotros
              </p>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
