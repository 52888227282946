import styles from './tabsWithTitle.module.scss';

export type TabsWithTitleProps = {
  title: string;
  tabs: { key: string; title: string }[];
  activeTab: string;
  wrapperClassName?: string;
  onTabChange?: (key: string) => void;
};

export const TabsWithTitle = (props: TabsWithTitleProps) => {
  return (
    <div className={`row ${props.wrapperClassName || ''}`}>
      <div className="col-12 col-xl-6 d-flex align-items-center">
        <h6 className="m-0">{props.title}</h6>
      </div>
      <div className="col-12 col-xl-6 mt-3 mt-xl-0">
        <div className="d-flex flex-row justify-content-end align-items-center">
          {props.tabs.map((tab) => (
            <div
              key={tab.key}
              role="button"
              className={`${styles.tab} ${tab.key === props.activeTab ? styles.activeTab : ''} ${
                styles['tab-' + props.tabs.length]
              }`}
              onClick={() => !!props.onTabChange && props.onTabChange(tab.key)}>
              {tab.title}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
