import * as React from 'react';
import { SVGProps } from 'react';
const SvgFilterAlimentos = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Grupo 277" viewBox="0 0 23 23" {...props}>
    <defs>
      <clipPath id="FilterAlimentos_svg__a">
        <path data-name="Rect\xE1ngulo 279" fill="#abadc4" d="M0 0h23v23H0z" />
      </clipPath>
      <clipPath id="FilterAlimentos_svg__b">
        <path
          data-name="Rect\xE1ngulo 277"
          transform="translate(1.199 .53)"
          fill="#abadc4"
          d="M0 0h21.769v21.77H0z"
        />
      </clipPath>
    </defs>
    <g data-name="Grupo 276" clipPath="url(#FilterAlimentos_svg__a)">
      <g data-name="Grupo 275">
        <g data-name="Grupo 274" clipPath="url(#FilterAlimentos_svg__a)">
          <g data-name="Grupo 273" opacity={0.4}>
            <g data-name="Grupo 272">
              <g data-name="Grupo 271" clipPath="url(#FilterAlimentos_svg__b)">
                <path
                  data-name="Trazado 244"
                  d="M18.258 1.11a1.923 1.923 0 0 0-2.48-.23 42.365 42.365 0 0 0-5.544 4.687 3.57 3.57 0 0 0-.465 4.448c.136.215.285.437.446.661l-7.856 6.363a2.879 2.879 0 0 0-.633 3.944 4.143 4.143 0 0 0 .362.427 4.059 4.059 0 0 0 .428.363 2.877 2.877 0 0 0 3.943-.634l6.363-7.856c.224.161.446.31.662.447a3.568 3.568 0 0 0 4.447-.465 42.345 42.345 0 0 0 4.687-5.546 1.923 1.923 0 0 0-.23-2.479l-.118-.118a.286.286 0 0 0-.414.009L18.077 9.27a.716.716 0 0 1-1.058-.97l3.84-4.2a.287.287 0 0 0-.009-.4L19.8 2.648a.287.287 0 0 0-.4-.009l-4.206 3.84a.716.716 0 0 1-.966-1.058l4.139-3.779a.286.286 0 0 0 .009-.414Z"
                  fill="#abadc4"
                />
              </g>
            </g>
          </g>
          <path
            data-name="Trazado 245"
            d="M5.879 16.158a2.181 2.181 0 0 0 2.358.636 12.836 12.836 0 0 0 2.392-1.19l4.077 5.436a3.558 3.558 0 0 0 1.755 1.311 2.649 2.649 0 0 0 2.14-.256 2.418 2.418 0 0 0 .671-3.773L2.19 1.24A1.355 1.355 0 0 0 .97.839a1.074 1.074 0 0 0-.85.937C-.324 4.782.8 10.6 5.879 16.158"
            fill="#abadc4"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgFilterAlimentos;
