import * as React from 'react';
import { SVGProps } from 'react';
const SvgTarjetaContacto = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" {...props}>
    <g data-name="Grupo 16746" fill="#8f979f">
      <path
        data-name="Trazado 34100"
        d="M11.5 20.652c-5.568 0-8.366 0-9.746-1.14S.383 16.081.383 11.498s0-6.879 1.37-8.01 4.178-1.14 9.746-1.14 8.366 0 9.746 1.14 1.37 3.431 1.37 8.012 0 6.881-1.37 8.012-4.178 1.14-9.746 1.14Z"
        opacity={0.4}
      />
      <path
        data-name="Trazado 34101"
        d="M.383 10.954h22.243c0-1.648-.01-2.99-.1-4.073H.483c-.09 1.083-.1 2.42-.1 4.073Z"
      />
      <rect
        data-name="Rect\xE1ngulo 2357"
        width={5.827}
        height={3.527}
        rx={1.42}
        transform="translate(13.33 14.078)"
      />
    </g>
  </svg>
);
export default SvgTarjetaContacto;
