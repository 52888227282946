import * as React from 'react';
import { SVGProps } from 'react';
const SvgOperador = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="operador_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22 22"
    {...props}>
    <defs>
      <clipPath id="operador_svg__clippath">
        <path
          style={{
            fill: 'none',
          }}
          d="M0 0h22v22H0z"
        />
      </clipPath>
      <style>{'.operador_svg__cls-1{fill:#8ab934}'}</style>
    </defs>
    <g
      style={{
        clipPath: 'url(#operador_svg__clippath)',
      }}
      id="operador_svg__Capa_1-2"
      data-name="Capa 1">
      <g id="operador_svg__Grupo_162" data-name="Grupo 162">
        <path
          id="operador_svg__Trazado_178"
          data-name="Trazado 178"
          className="operador_svg__cls-1"
          d="M21.57 7.7c-.18-2.81-1.47-5.44-3.6-7.29a.662.662 0 0 0-.93.06c-.24.27-.21.69.06.93 3.87 3.39 4.26 9.27.87 13.13-.25.27-.23.69.04.93.27.25.69.23.93-.04 0 0 .02-.02.02-.03 1.86-2.12 2.8-4.89 2.61-7.7"
        />
        <path
          id="operador_svg__Trazado_179"
          data-name="Trazado 179"
          className="operador_svg__cls-1"
          d="M15.35 3.39a.652.652 0 0 0-.93.04c-.25.27-.23.69.04.93 0 0 .02.02.03.02a5.335 5.335 0 0 1 .5 7.53.664.664 0 0 0 1 .87 6.66 6.66 0 0 0-.62-9.39"
        />
        <path
          id="operador_svg__Trazado_180"
          data-name="Trazado 180"
          className="operador_svg__cls-1"
          d="M4.9 1.4c.27-.25.28-.67.03-.93a.654.654 0 0 0-.9-.06c-4.41 3.87-4.86 10.58-1 15 .23.28.65.32.93.09s.32-.65.09-.93c0 0-.01-.02-.02-.03A9.31 9.31 0 0 1 4.9 1.4"
        />
        <path
          id="operador_svg__Trazado_181"
          data-name="Trazado 181"
          className="operador_svg__cls-1"
          d="M6.65 3.39c-2.77 2.42-3.04 6.63-.62 9.39.23.28.65.32.93.09s.32-.65.09-.93c0 0-.01-.02-.02-.03a5.335 5.335 0 0 1 .5-7.53c.28-.23.32-.65.09-.93s-.65-.32-.93-.09c0 0-.02.01-.03.02"
        />
        <path
          id="operador_svg__Trazado_182"
          data-name="Trazado 182"
          className="operador_svg__cls-1"
          d="M13.54 14.38c-.11-.88-.8-1.58-1.69-1.7-.6-.07-1.2-.07-1.8 0-.88.12-1.57.82-1.69 1.7-.22 1.26-.41 3.17-.54 4.55a2.39 2.39 0 0 0 1.89 2.73c.82.12 1.65.12 2.47 0 1.27-.25 2.1-1.46 1.89-2.73-.13-1.38-.32-3.29-.54-4.55m-1.56 5.98c-.68.1-1.37.1-2.05 0-.57-.16-.91-.73-.78-1.31.12-1.36.32-3.23.52-4.44.02-.13.13-.57.54-.62.5-.05 1-.05 1.49 0 .41.05.52.48.54.62.21 1.21.4 3.09.52 4.45.13.57-.22 1.15-.78 1.31"
        />
        <path
          id="operador_svg__Trazado_183"
          data-name="Trazado 183"
          className="operador_svg__cls-1"
          d="M10.95 11.35c1.62 0 2.94-1.31 2.94-2.94 0-1.62-1.31-2.94-2.94-2.94S8.01 6.78 8.01 8.41c0 1.62 1.32 2.93 2.94 2.94m0-4.55c.89 0 1.62.72 1.62 1.61 0 .89-.72 1.62-1.61 1.62s-1.62-.72-1.62-1.61c0-.89.72-1.62 1.62-1.62"
        />
      </g>
    </g>
  </svg>
);
export default SvgOperador;
