import { useEffect, useState } from 'react';
import { BiChevronDown, BiChevronUp } from 'react-icons/bi';
import { RecargarCelularInactive } from '../../svg';

import styles from './topupProducts.module.scss';
import { TopUpAddButton } from '../topup/topupAddButton';

export type TopupProductsProps = {
  serviceType: string;
  selectedId: string;
  products: {
    id: string;
    priceTitle: string;
    priceSubtitle: string;
    description: string;    
    futureDate?: string;
  }[];

  onSelectedChange?: (id: string) => void;
  onAddTopup?: (id: string) => void;
};

export const TopupProducts = (props: TopupProductsProps) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [seeVerMas, setSeeVerMas] = useState(false);
  const [initialList, setInitialList] = useState<TopupProductsProps['products']>([]);

  console.log(props);

  useEffect(() => {
    initialCalculations();
  }, []);

  useEffect(() => {    
    setIsExpanded(false);
    initialCalculations();
  }, [props.products]);

  const initialCalculations = () => {
    const iniList = props.products.length > 6 ? props.products.slice(0, 6) : props.products;
    setSeeVerMas(iniList.length !== props.products.length);
    setInitialList(iniList);
  };

  const expand = () => {
    setIsExpanded(true);
    setInitialList(props.products);
  };

  const collapse = () => {
    setIsExpanded(false);
    const iniList = props.products.length > 6 ? props.products.slice(0, 6) : props.products;
    setInitialList(iniList);
  };

  const onSelect = (id: string) => {
    !!props.onSelectedChange && props.onSelectedChange(id);
  };

  const onAddTopup = (id: string) => {    
    !!props.onAddTopup && props.onAddTopup(id);
  };  

  return (
    <div className={styles.productsWrapper}>
      {initialList.map((product) => (
        <div          
          className={`d-flex flex-column ${styles.product} ${
            product.id === `${props.selectedId}` && props.serviceType == 'remittance' ? styles.productSelected : ''
          }`}
          key={product.id}
          role="button"
          onClick={() => onSelect(product.id)}>
          <div className="d-flex flex-row">
            <h6 className="m-0">{product.priceTitle.replace('.00', '')}</h6>            
          </div>
          <div className="text-secondary">{product.priceSubtitle}</div>
          <p className="m-0" dangerouslySetInnerHTML={{ __html: product.description }}></p>         
          
          {product.id === `${props.selectedId}` && props.serviceType === 'remittance' && (
            <RecargarCelularInactive
              fill={`#8ab934`}
              width={20}
              className={styles.productSelectedIcon}
            />
          )}

          {product.futureDate  && product.futureDate !== '' && (
          <span className={styles.productsPromoDate}>{product.futureDate}</span>
          )}
          {props.serviceType !== 'remittance' && (
          <div className={`flex-grow-1 d-flex flex-column justify-content-end`}>
          <TopUpAddButton                    
            disabled={false}
            isProcessing={false}
            onClick={() => onAddTopup(product.id)}
          />        
          </div>
          )}
        </div>        
      ))}
      {seeVerMas && (
        <div className={`w-100 text-secondary text-center mt-3 ${styles.seeMore}`}>
          {!isExpanded && (
            <span role="button" onClick={expand}>
              Ver más <BiChevronDown size={35} />
            </span>
          )}

          {isExpanded && (
            <span role="button" onClick={collapse}>
              Ver menos <BiChevronUp size={35} />
            </span>
          )}
        </div>
      )}
    </div>
  );
};
