import * as nameCase from 'namecase';
import { useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { ContactInfo } from '../../../components/account/contactInfo';
import { MegaError } from '../../../components/account/megaError';
import { AccountServices, ContactService, UtilService } from '../../../services';
import { MegaContact } from '../../../types/contacts';
import { QueryTypes } from '../../../types/queryTypes';

import { Button, Offcanvas } from 'react-bootstrap';
import { GiHamburgerMenu } from 'react-icons/gi';
import { PageTitle } from '../../../components/account';
import { CreateContact } from '../../../components/account/createContact';
import { Agregar, LlamarMisContactos } from '../../../components/svg';
import { MODAL_TYPES, useGlobalModalContext } from '../../../providers/globalModal.provider';
import styles from './contacts-screen.module.scss';

export const ContactsScreen = () => {
  const queryCache = useQueryClient();
  const { showModal: showConfirmModal, hideModal } = useGlobalModalContext();

  const [onEditContact, setOnEditContact] = useState<MegaContact | undefined>(undefined);
  const [contacts, setContacts] = useState<MegaContact[]>([]);
  const [selectedContact, setSelectedContact] = useState<MegaContact | null>(null);
  const [accountCountry, setAccountCountry] = useState<string>('');
  const [showModal, setShowModal] = useState<boolean>(false);
  const [accessNumber, setAccessNumber] = useState<string>('');
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const [showOffCanvas, setShowOffCanvas] = useState<boolean>(false);

  const contactQuery = useQuery(QueryTypes.GetContacts, ContactService.getContacts);
  const profileQuery = useQuery(QueryTypes.GetProfile, AccountServices.getProfileData);

  const allQueries = [contactQuery, profileQuery];

  useEffect(() => {
    if (contactQuery.status === 'success' && contactQuery.data.length > 0) {
      const cArray: MegaContact[] = contactQuery.data.map<MegaContact>((c) => {
        return {
          ...c,
          formattedNumeroContacto: UtilService.getFormattedPhoneNumber(
            c.contact_number,
            c.country_code
          ),
        } as MegaContact;
      });
      setContacts(cArray);

      if (!selectedContact) {
        setSelectedContact(contactQuery.data[0]);
      } else {
        // let's refresh the selected contact in case of changes
        const newSelectedContact = contactQuery.data.find((c) => c.id === selectedContact.id);
        setSelectedContact(!!newSelectedContact ? newSelectedContact : contactQuery.data[0]);
      }
    }
  }, [contactQuery.data]);

  useEffect(() => {
    if (profileQuery.status === 'success' && profileQuery.data.user) {
      setAccountCountry(profileQuery.data.user.address.country);

      if (profileQuery.data.user.accessNumber) {
        const phoneNumber = UtilService.getFormattedPhoneNumber(
          profileQuery.data.user.accessNumber,
          profileQuery.data.user.address.country
        );

        if (phoneNumber) {
          setAccessNumber(phoneNumber);
        }
      }
    }
  }, [profileQuery.data]);

  if (UtilService.isErrorQueries(allQueries)) {
    return (
      <MegaError
        displayRetry
        retry={() => {
          UtilService.refetchQueries(allQueries);
        }}
      />
    );
  }

  const changeSelectedContact = (currentContact: MegaContact, event: any) => {
    event.preventDefault();

    if (currentContact.id === selectedContact?.id) {
      return;
    }

    setSelectedContact(currentContact);

    if (showOffCanvas) {
      setShowOffCanvas(false);
    }
  };

  const addNewContact = () => {
    setOnEditContact(undefined);
    setShowModal(true);
  };

  const onSaveContactCreation = () => {
    setShowModal(false);
    queryCache.invalidateQueries(QueryTypes.GetContacts);
  };

  const onCancelContactCreation = () => {
    setShowModal(false);
  };

  const confirmDeleteContact = (contact: MegaContact) => {
    showConfirmModal(MODAL_TYPES.CONFIRM_DELETE, {
      description: '¿Estas seguro que quieres eliminar este contacto?',
      onClose: (response: boolean) => {
        setOnEditContact(undefined);
        !!response && deleteContact(contact);
      },
    });
  };

  const deleteContact = async (contact: MegaContact) => {
    showConfirmModal(MODAL_TYPES.LOADING, {
      title: 'Eliminando su contacto',
    });
    setIsDeleting(true);
    const response = await ContactService.deleteContact(contact.id);
    setIsDeleting(false);
    hideModal();

    if (response.success) {
      setSelectedContact(null);
      queryCache.invalidateQueries(QueryTypes.GetContacts);
    } else {
      showConfirmModal(MODAL_TYPES.ERROR, {
        description: 'Hubo un error eliminando su contacto. Por favor, intente de nuevo',
      });
    }
  };

  return (
    <div className="bg-mega_light_gray">
      <div className="container py-5">
        {(!contacts || contacts.length === 0) && !UtilService.isLoadingQueries(allQueries) && (
          <>
            <div className="row mt-5">
              <div className="col-12 text-center">
                <LlamarMisContactos width={60} />

                <h5 className="mt-3">Llamar / Mis Contactos</h5>
                <p>Agregue nuevos contactos ahora para llamar, recargar o enviar SMS.</p>

                <Button variant="outline-secondary" className="mt-4 px-4" onClick={addNewContact}>
                  <Agregar width={16} className="me-2" />
                  Agregar contactos
                </Button>
              </div>
            </div>
          </>
        )}

        {!!contacts && contacts.length > 0 && (
          <>
            <PageTitle
              title="Llamar / Mis Contactos"
              description="Elija uno de sus contactos para llamar, recargar o enviar SMS."
              icon={LlamarMisContactos}
            />

            <div className="row mt-2">
              <div className={`d-none d-lg-block col-lg-3`}>
                <div className={styles.contactWrapper}>
                  {contacts.map((contact) => (
                    <div
                      key={contact.id}
                      className={`${styles.contactRowWrapper} ${
                        contact.id === selectedContact?.id ? styles.activeRow : ''
                      }`}>
                      <a
                        href="#"
                        onClick={(e) => changeSelectedContact(contact, e)}
                        className={styles.contact_link}>
                        <div
                          className={`${styles.contact_row} ${
                            contact.id === selectedContact?.id ? styles.active : ''
                          }`}>
                          <div className={styles.contact_right}>
                            <span
                              className={`fi fi-${(
                                contact.country_code || ''
                              ).toLowerCase()}`}></span>
                          </div>
                          <div className={styles.contact_center}>
                            <div>{nameCase(contact.contact_name)}</div>
                            <small className={styles.contact_phone}>
                              {contact.formattedNumeroContacto || contact.contact_number}
                            </small>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                  <div className={styles.buttonAdd}>
                    <Button
                      variant="outline-secondary"
                      className={`mt-4 w-100`}
                      onClick={addNewContact}>
                      <Agregar width={16} className="me-2" />
                      Agregar contactos
                    </Button>
                  </div>
                </div>
              </div>
              <div className="d-block d-lg-none col-12 mb-3">
                {selectedContact && (
                  <div className={`${styles.contactWrapper} mb-4`}>
                    <div key={selectedContact.id} className={`${styles.contactRowWrapper}`}>
                      <a
                        href="#"
                        onClick={(e) => changeSelectedContact(selectedContact, e)}
                        className={styles.contact_link}>
                        <div
                          className={`${styles.contact_row} ${
                            selectedContact.id === selectedContact?.id ? styles.active : ''
                          }`}>
                          <div className={styles.contact_right}>
                            <span
                              className={`fi fi-${(
                                selectedContact.country_code || ''
                              ).toLowerCase()}`}></span>
                          </div>
                          <div className={styles.contact_center}>
                            <div>{nameCase(selectedContact.contact_name)}</div>
                            <small className={styles.contact_phone}>
                              {selectedContact.formattedNumeroContacto ||
                                selectedContact.contact_number}
                            </small>
                          </div>

                          <div className={styles.contact_left}>
                            <GiHamburgerMenu
                              size={25}
                              color={'#616161'}
                              onClick={() => setShowOffCanvas(true)}
                            />
                          </div>
                        </div>
                      </a>
                    </div>

                    <Offcanvas
                      id="megaCanvas"
                      show={showOffCanvas}
                      onHide={() => setShowOffCanvas(false)}
                      placement="end">
                      <Offcanvas.Header closeButton>
                        <Offcanvas.Title>Mis Contactos</Offcanvas.Title>
                      </Offcanvas.Header>
                      <Offcanvas.Body>
                        <div className="">
                          {contacts.map((contact) => (
                            <div
                              key={contact.id}
                              className={`${styles.contactRowWrapper} ${
                                contact.id === selectedContact?.id ? styles.activeRow : ''
                              }`}>
                              <a
                                href="#"
                                onClick={(e) => changeSelectedContact(contact, e)}
                                className={styles.contact_link}>
                                <div
                                  className={`${styles.contact_row} ${
                                    contact.id === selectedContact?.id ? styles.active : ''
                                  }`}>
                                  <div className={styles.contact_right}>
                                    <span
                                      className={`fi fi-${(
                                        contact.country_code || ''
                                      ).toLowerCase()}`}></span>
                                  </div>
                                  <div className={styles.contact_center}>
                                    <div>{nameCase(contact.contact_name)}</div>
                                    <small className={styles.contact_phone}>
                                      {contact.formattedNumeroContacto || contact.contact_number}
                                    </small>
                                  </div>
                                </div>
                              </a>
                            </div>
                          ))}
                        </div>
                      </Offcanvas.Body>
                    </Offcanvas>

                    <div className={styles.buttonAdd}>
                      <Button
                        variant="outline-secondary"
                        className="mt-2 w-100"
                        onClick={addNewContact}>
                        <Agregar width={16} className="me-2" />
                        Agregar contactos
                      </Button>
                    </div>
                  </div>
                )}
              </div>
              <div className="col-12 col-lg-9">
                {selectedContact && accountCountry && (
                  <ContactInfo
                    contact={selectedContact}
                    accountCountry={accountCountry}
                    canBeDeleted={contacts.length > 1}
                    isDeleting={isDeleting}
                    onDelete={confirmDeleteContact}
                    onEdit={(c: MegaContact) => {
                      setOnEditContact(c);
                      setShowModal(true);
                    }}
                  />
                )}
              </div>
            </div>
          </>
        )}

        <div className="row mt-8 mb-5">
          <div className="col-12 col-lg-2"></div>
          <div className="col-12 col-lg-8">
            <div className="mega-alert">
              <p className="text-center m-0 p-0">
                <strong>¿No tiene el número registrado?</strong> Si no tiene el número que desea
                llamar, marque al <strong className="text-secondary">{accessNumber}</strong>
              </p>              
            </div>
          </div>
          <div className="col-12 col-lg-2"></div>
        </div>

        {showModal && (
          <CreateContact
            contact={onEditContact}
            display={showModal}
            onCancel={onCancelContactCreation}
            onSave={onSaveContactCreation}
            acceptRemittance={profileQuery.data?.user.accept_remittance || false}
          />
        )}
      </div>
    </div>
  );
};
