import * as React from 'react';
import { SVGProps } from 'react';
const SvgDireccion = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" {...props}>
    <g data-name="Capa 1">
      <path
        d="M11.5.67C6.4.67 2.26 4.8 2.26 9.91c0 3.31 1.71 6.1 3.57 8.15 1.86 2.06 3.95 3.47 4.85 4.03.51.32 1.14.32 1.65 0 .91-.56 2.99-1.97 4.85-4.03 1.86-2.06 3.57-4.84 3.57-8.15C20.74 4.8 16.6.67 11.5.67Z"
        style={{
          fill: '#8f979f',
          fillRule: 'evenodd',
          opacity: 0.4,
        }}
      />
      <path
        d="M11.5 12.73c-2.04 0-3.69-1.65-3.69-3.69s1.65-3.69 3.69-3.69S15.19 7 15.19 9.04s-1.65 3.69-3.69 3.69Z"
        style={{
          fill: '#8f979f',
          fillRule: 'evenodd',
        }}
      />
    </g>
  </svg>
);
export default SvgDireccion;
