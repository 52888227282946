import * as React from 'react';
import { SVGProps } from 'react';
const SvgIconBackground = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 79.66 79.66" {...props}>
    <g data-name="Capa 1">
      <path
        data-name="Trazado 175"
        className="icon_background_svg__cls-1"
        d="M39.83 0c19.98 0 30.29 2.21 35.35 11.07 3.45 6.03 4.47 15.15 4.47 28.75 0 16.6-1.52 26.52-7.12 32.31-5.73 5.92-15.72 7.52-32.71 7.52-15.52 0-25.2-1.33-31.13-6.08C1.81 68.05 0 57.9 0 39.83 0 23.39 1.49 13.51 6.96 7.69S22.68 0 39.83 0Z"
      />
    </g>
  </svg>
);
export default SvgIconBackground;
