import * as React from 'react';
import { SVGProps } from 'react';
const SvgEstrella = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23.99 23" {...props}>
    <g data-name="Capa 1">
      <path
        data-name="Trazado 59"
        d="M23.89 8.65c-.22-.69-.81-1.19-1.53-1.29l-6-.87-2.68-5.43a1.889 1.889 0 0 0-3.39 0L7.61 6.49l-6 .87A1.893 1.893 0 0 0 .01 9.5c.06.41.25.79.55 1.08l4.34 4.23-1.02 5.97c-.18 1.03.51 2.01 1.54 2.19.41.07.84 0 1.2-.19l5.36-2.82 5.36 2.82a1.885 1.885 0 0 0 2.74-1.99l-1.02-5.97 4.34-4.23c.52-.5.71-1.25.48-1.94"
        style={{
          fill: '#fed41d',
        }}
      />
    </g>
  </svg>
);
export default SvgEstrella;
