import * as React from 'react';
import { SVGProps } from 'react';
const SvgReferirAmigo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="referir_amigo_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 66"
    {...props}>
    <defs>
      <style>{'.referir_amigo_svg__cls-3{fill:#263645}'}</style>
    </defs>
    <g id="referir_amigo_svg__Capa_1-2" data-name="Capa 1">
      <path
        id="referir_amigo_svg__Trazado_308"
        data-name="Trazado 308"
        d="M33 2.66c-14.45 0-22.25 1.52-26.3 7.44-3.14 4.57-4.04 11.76-4.04 22.89 0 14.25 1.47 22.03 7.2 26.14 4.55 3.26 11.8 4.2 23.13 4.2 11.91 0 19.31-1.03 23.81-4.71 5.17-4.23 6.52-11.95 6.52-25.62 0-10.77-.84-17.85-3.73-22.44-3.96-6.31-11.79-7.9-26.61-7.9Z"
        style={{
          fill: '#d0e3ae',
        }}
      />
      <path
        className="referir_amigo_svg__cls-3"
        d="M33 64.53c-10.6 0-18.71-.75-23.83-4.42C2.73 55.5 1.47 46.68 1.47 33c0-10.44.72-18.44 4.25-23.57C10.27 2.77 19.17 1.47 33 1.47s23.17 1.38 27.62 8.46c3.25 5.16 3.91 12.96 3.91 23.07 0 13.2-1.14 21.78-6.96 26.55-5.06 4.14-13.48 4.98-24.57 4.98Zm0-60.67c-15.2 0-21.82 1.81-25.31 6.92C4.93 14.79 3.86 21.02 3.86 33c0 15.04 1.75 21.62 6.7 25.17 3.99 2.86 10.28 3.97 22.44 3.97s19.14-1.25 23.05-4.44c4.44-3.63 6.09-10.33 6.09-24.7 0-11.48-1.03-17.8-3.54-21.8-3.41-5.42-10.1-7.34-25.59-7.34Z"
      />
      <path
        className="referir_amigo_svg__cls-3"
        d="M25.71 45.81h-.93c-4.9-.05-9.84-.53-14.67-1.44l-.16-.03-.15-.07c-2.22-1.09-2.72-2.59-1.62-4.84a14.83 14.83 0 0 1 3.05-4.05c6.19-6.2 15.78-7.38 23.31-2.85l-1.23 2.05c-6.58-3.96-14.98-2.92-20.42 2.52-1.07 1-1.93 2.14-2.58 3.41-.5 1.01-.47 1.12.39 1.55 4.64.86 9.39 1.32 14.11 1.36h.37c4.49.05 8.98-.39 13.38-1.29.85-.44.96-.64.97-.67 0-.03 0-.29-.54-1.21l2.06-1.22c.58.99 1.08 2.05.77 3.11-.31 1.06-1.31 1.68-2.33 2.19l-.14.07-.15.03c-4.43.92-8.97 1.39-13.5 1.39Zm-.89-17c-2.72 0-5.28-1.06-7.21-2.99a10.13 10.13 0 0 1-2.99-7.21c0-5.63 4.57-10.2 10.2-10.2s10.2 4.57 10.2 10.2c0 2.72-1.06 5.28-2.99 7.21a10.13 10.13 0 0 1-7.21 2.99Zm0-18.01c-4.3 0-7.81 3.5-7.81 7.81 0 2.09.81 4.05 2.29 5.52a7.761 7.761 0 0 0 5.52 2.29c2.08 0 4.05-.81 5.52-2.29a7.778 7.778 0 0 0 2.29-5.52c0-4.3-3.5-7.81-7.81-7.81Z"
      />
      <path
        id="referir_amigo_svg__Uni\xF3n_11"
        data-name="Uni\xF3n 11"
        d="M36.75 54.65c-5.69-.05-11.36-.6-16.95-1.66-1.92-.94-2.19-1.88-1.25-3.8.84-1.62 1.94-3.09 3.27-4.34 8.18-8.19 21.45-8.2 29.64-.02 1.2 1.2 2.25 2.54 3.13 3.99 1.34 2.26 1.12 3.06-1.25 4.24a76.39 76.39 0 0 1-16.17 1.59h-.42Zm-10.53-29.9c0-5.82 4.72-10.54 10.54-10.54S47.3 18.93 47.3 24.75c0 5.82-4.72 10.54-10.54 10.54-5.82 0-10.54-4.72-10.54-10.54Z"
        style={{
          fill: '#8ab934',
        }}
      />
      <path
        className="referir_amigo_svg__cls-3"
        d="M37.81 55.86h-1.08c-5.74-.06-11.51-.62-17.15-1.69l-.16-.03-.15-.07c-2.48-1.22-3.03-2.88-1.8-5.4.91-1.77 2.09-3.34 3.53-4.69 8.6-8.62 22.66-8.63 31.3 0 1.27 1.27 2.38 2.69 3.31 4.22.77 1.3 1.21 2.39.89 3.49-.34 1.17-1.47 1.86-2.63 2.44l-.14.07-.15.03a78.083 78.083 0 0 1-15.77 1.62Zm-17.63-4.01a94.072 94.072 0 0 0 16.58 1.6h.44a75 75 0 0 0 15.74-1.52c1.06-.54 1.24-.82 1.27-.91.03-.1.02-.45-.65-1.58-.82-1.36-1.81-2.62-2.94-3.75-7.71-7.7-20.25-7.69-27.95.02a15.022 15.022 0 0 0-3.06 4.05c-.63 1.28-.59 1.52.57 2.1Zm16.59-15.36c-6.47 0-11.74-5.26-11.74-11.74s5.27-11.74 11.74-11.74 11.74 5.27 11.74 11.74c0 3.13-1.22 6.08-3.44 8.3a11.648 11.648 0 0 1-8.3 3.44Zm0-21.09c-5.15 0-9.35 4.19-9.35 9.35 0 5.16 4.19 9.35 9.35 9.35 2.5 0 4.84-.97 6.61-2.74a9.314 9.314 0 0 0 2.74-6.61c0-5.15-4.19-9.35-9.35-9.35Z"
      />
      <path
        id="referir_amigo_svg__Trazado_190"
        data-name="Trazado 190"
        d="M44.03 47.71c0-1.8-2.03-2.51-7.21-2.51s-7.21.71-7.21 2.51 2.03 2.51 7.21 2.51 7.21-.71 7.21-2.51Z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);
export default SvgReferirAmigo;
