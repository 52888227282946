import { Outlet } from 'react-router-dom';
import { Layout } from '../../shared';

export const Root = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};
