import styles from './steps.module.scss';

export type StepsProps = {
  steps: { title: string }[];
  currentStep: number;
};

export const Steps = ({ steps, currentStep }: StepsProps) => {
  const getStepClass = (position: number) => {
    if (currentStep === position) {
      return 'active';
    }

    if (currentStep > position) {
      return 'past';
    }

    return '';
  };

  return (
    <div className={styles.mg_steps}>
      {steps.map(({ title }, index) => (
        <div
          className={
            styles.inner_step +
            ` ${styles['sz-' + steps.length]} ${
              !!getStepClass(index + 1) ? styles[getStepClass(index + 1)] : ''
            }`
          }
          key={`mg-step-${index}`}>
          <div className={styles.step_title}>
            {`${index + 1}. `}
            {title}
          </div>
          <div className={styles.line}></div>
        </div>
      ))}
    </div>
  );
};
