import creditCardType from 'credit-card-type';
import { startsWith } from 'lodash';
import moment from 'moment';
import validator from 'validator';
import * as yup from 'yup';
import { errorMessages } from '../../../utilities/errorMessages';

const baseCardShape = {
  country: yup.object().required(errorMessages.country),
  address1: yup.string().required(errorMessages.address1),
  cardExpiration: yup
    .string()
    .required(errorMessages.cardExpiration)
    .test('cardExp', errorMessages.cardExpirationFormat, (value) => {
      if (!value) {
        return true;
      }

      const parts = value.split('/');
      if (parts.length !== 2) {
        return false;
      }

      if ((parts[0] || '').trim().length !== 2 || (parts[1] || '').trim().length !== 2) {
        return false;
      }

      return true;
    })
    .test('cardExp', errorMessages.cardExpirationPast, (value, context) => {
      if (!value) {
        return true;
      }

      const parts = value.split('/');
      if (parts.length !== 2) {
        return false;
      }

      const month = (parts[0] || '').trim();
      const year = (parts[1] || '').trim();

      const today = moment();
      const ccDate = moment(`${year}${month}`, 'YYMM');

      return ccDate.isValid() && ccDate.endOf('month').isSameOrAfter(today);
    }),
  cardCvc: yup
    .string()
    .required(errorMessages.cardCvc)
    .test('cardCvc', errorMessages.cardCvcFormat, (value, context) => {
      if (!value) {
        return true;
      }

      if (!context.parent.cardNumber) {
        return true;
      }

      if (
        startsWith(context.parent.cardNumber, '····') ||
        startsWith(context.parent.cardNumber, 'XXXX')
      ) {
        return true;
      }

      const ccType = creditCardType(context.parent.cardNumber);
      if (ccType[0].type === 'american-express') {
        return `${value}`.trim().length === 4;
      } else {
        return `${value}`.trim().length === 3;
      }
    }),
  city: yup.string().required(errorMessages.city),
  state: yup.string().required(errorMessages.state),
  zip: yup.string().required(errorMessages.zip),
};

export const newCardSchema = yup
  .object()
  .shape({
    cardNumber: yup
      .string()
      .required(errorMessages.cardNumber)
      .test('creditCard', errorMessages.cardNumberFormat, (value, context) => {
        return validator.isCreditCard(`${value}`);
      }),
    storeCcAccount: yup.bool().optional(),
    firstName: yup.string().required(errorMessages.firstName),
    lastName: yup.string().required(errorMessages.lastName),
    ...baseCardShape,
  })
  .required();

export const existingCardSchema = yup.object().shape(baseCardShape).required();
