import * as React from 'react';
import { SVGProps } from 'react';
const SvgPago = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} {...props}>
    <g data-name="Grupo 16715">
      <path
        data-name="Trazado 34082"
        d="M34.61 6.39a4.192 4.192 0 0 0-4.37-2.91 67.656 67.656 0 0 0-8.87 1.14c-1.1.17-2.18.37-3.26.61s-.06.01-.1.02a.44.44 0 0 0-.1.02c-1.08.24-2.15.51-3.22.82a67.452 67.452 0 0 0-8.53 2.68 4.184 4.184 0 0 0-2.74 4.47 42.858 42.858 0 0 0 2.1 11.15c2.46 7.35 7.79 11.53 15.37 12.97a10.524 10.524 0 0 0 4.07.02s.06-.01.1-.02a.355.355 0 0 0 .1-.02 10.47 10.47 0 0 0 3.69-1.72c6.29-4.48 9.37-10.51 8.52-18.22a42.7 42.7 0 0 0-2.77-11Z"
        fill="#ffdbe6"
      />
      <path
        data-name="Trazado 34083"
        d="M55.82 19.11a5.545 5.545 0 0 0-4.83-5.01 90.09 90.09 0 0 0-11.81-1.04 71.83 71.83 0 0 0-4.4-.14h-.26c-1.47 0-2.93.05-4.4.14a90.09 90.09 0 0 0-11.81 1.04 5.554 5.554 0 0 0-4.83 5.01 57.059 57.059 0 0 0-.46 15.04c1.09 10.23 6.8 17.16 16.23 21.18a13.832 13.832 0 0 0 5.27 1.18h.26a14.022 14.022 0 0 0 5.27-1.18c9.42-4.02 15.14-10.95 16.23-21.18a57.047 57.047 0 0 0-.46-15.04Z"
        fill="#fe4a81"
      />
      <path
        data-name="Trazado 34084"
        d="M56.9 18.94a6.629 6.629 0 0 0-5.69-5.91 63.53 63.53 0 0 0-9.24-.91c-.91-.04-1.81-.09-2.72-.15-.58-.04-1.16-.06-1.75-.09a45.117 45.117 0 0 0-1.87-5.89 5.245 5.245 0 0 0-5.4-3.6 46.856 46.856 0 0 0-6.99.83c-.67.11-1.35.23-2.02.33-.75.12-1.51.25-2.3.41v-.02l-2.32.5v.02c-.75.18-1.48.38-2.19.58-.66.19-1.31.37-1.97.55a48.744 48.744 0 0 0-6.69 2.18 5.241 5.241 0 0 0-3.4 5.53A44.179 44.179 0 0 0 4.5 24.73a19.4 19.4 0 0 0 7.52 10.25c1.29 9.96 6.93 17.15 16.8 21.35a15.672 15.672 0 0 0 4.61 1.21v.05h1.08a.452.452 0 0 0 .12 0h.16l1.08-.02v-.05a15.293 15.293 0 0 0 4.61-1.21C50.58 52 56.26 44.58 57.36 34.24a58.288 58.288 0 0 0-.46-15.33Zm-50.34 5.1a42.044 42.044 0 0 1-2.04-10.87 3.123 3.123 0 0 1 2.09-3.41A46.231 46.231 0 0 1 13 7.69c.67-.18 1.34-.36 2-.55 1-.29 2.03-.55 3.16-.8l.07-.02.12-.02c1.12-.24 2.17-.44 3.2-.6.68-.11 1.36-.22 2.05-.34a46.845 46.845 0 0 1 6.68-.8 3.124 3.124 0 0 1 3.33 2.22 44.143 44.143 0 0 1 1.64 5.04h-1.81c-1.17.03-2.28.08-3.38.15-.9.06-1.81.1-2.72.15a64.954 64.954 0 0 0-9.24.91 6.62 6.62 0 0 0-5.69 5.91 59.147 59.147 0 0 0-.65 13.08 17.722 17.722 0 0 1-5.19-7.98Zm48.63 10c-1.01 9.49-6.25 16.32-15.57 20.3a12.865 12.865 0 0 1-4.86 1.09h-.23a12.865 12.865 0 0 1-4.86-1.09c-9.32-3.98-14.56-10.8-15.57-20.3a55.7 55.7 0 0 1 .45-14.76 4.473 4.473 0 0 1 3.97-4.12 62.235 62.235 0 0 1 8.92-.88c.92-.05 1.83-.09 2.75-.15 1.39-.09 2.81-.13 4.34-.14h.25c1.53 0 2.95.05 4.34.14.91.06 1.83.1 2.75.15a63.687 63.687 0 0 1 8.92.87 4.482 4.482 0 0 1 3.97 4.12 56.117 56.117 0 0 1 .45 14.76Z"
        fill="#263645"
      />
      <path
        data-name="Uni\xF3n 5"
        d="M24.52 34.82q1.86-3.23 8.34.51l.03.02c.57-1 1.24-2.16 2.01-3.5q5.67-9.81 8.89-7.95t-2.44 11.68c-1.28 2.22-2.28 3.94-3.08 5.25-.19.41-.46.89-.8 1.47q-1.86 3.23-8.34-.51t-4.61-6.96Z"
        fill="#fff"
      />
    </g>
  </svg>
);
export default SvgPago;
