import { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { SessionContext } from '../../../providers/session.provider';

import { Link, Location, useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { MegaError } from '../../../components/account/megaError';
import { LoginOtp, LoginPassword } from '../../../components/external/login';
import { ROUTES } from '../../../config/routes';
import { AuthService } from '../../../services';
import { LoginResponse } from '../../../types/common';

import styles from './login-screen.module.scss';

type LoginLocationState = {
  from: Location;
};

export const LoginScreen = () => {
  const { setMegaUser, setCurrentToken, isAuthenticated, setCurrentRefreshToken } =
    useContext(SessionContext);

  const [searchParams] = useSearchParams();

  const [loginType, setLoginType] = useState<'password' | 'otp'>('otp');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);

  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as LoginLocationState)?.from?.pathname || ROUTES.cuenta.inicio;

  useEffect(() => {
    const loginType = searchParams.get('type');

    if (loginType == 'password') {
      setLoginType('password');
    }

    if (isAuthenticated()) {
      navigate(from);
    }
  }, []);

  const onSuccessLogin = async (response: LoginResponse) => {
    setCurrentToken(response.token!);
    setCurrentRefreshToken(response.refreshToken!);

    setIsSubmitting(true);
    getUser();
  };

  const getUser = async () => {
    const user = await AuthService.getUser();

    if (!!user) {
      setMegaUser(user);
      navigate(from, { replace: true });
      return;
    }

    setIsError(true);
    setIsSubmitting(false);
  };

  // if (isSubmitting) {
  //   return <MegaLoading isLoading={true} />;
  // }

  if (isError) {
    return <MegaError displayRetry retry={() => getUser()} />;
  }

  return (
    <div className="container my-7">
      <div className="row">
        <div className="col-xl-3 col-lg-1 col-md-0"></div>
        <div className="col-xl-6 col-lg-10 col-md-12">
          <h1 className="text-center text-uppercase mega-font-bold fs-3">Entrar</h1>
          <div className="px-xl-10 px-lg-10 px-md-10 px-sm-10 px-0 mx-xl-10 mx-lg-10 mx-md-5 mx-sm-0 mx-0">
            {loginType === 'password' && <LoginPassword onSuccess={onSuccessLogin} />}
            {loginType === 'otp' && <LoginOtp onSuccess={onSuccessLogin} />}

            <div className={styles.wrapperHr}>
              <label>O</label>
              <hr className="border-mega_gray_c5 border-top border-2 mt-4 mb-4" />
            </div>

            {loginType === 'otp' && (
              <Button
                variant="mega_secondary_2"
                className="w-100 mt-2"
                onClick={() => setLoginType('password')}>
                Acceder con contraseña
              </Button>
            )}

            {loginType === 'password' && (
              <Button
                variant="mega_secondary_2"
                className="w-100 mt-2"
                onClick={() => setLoginType('otp')}>
                Acceder con código SMS
              </Button>
            )}
            <Link to={ROUTES.register} className="btn btn-mega_secondary_2 w-100 mt-4">
              Crear nueva cuenta
            </Link>
          </div>
        </div>
        <div className="col-xl-3 col-lg-1 col-md-0"></div>
      </div>
    </div>
  );
};
