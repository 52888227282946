import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { CarrierServices } from '../../../../services/carrier';
import { MegaContact } from '../../../../types/contacts';
import { QueryTypes } from '../../../../types/queryTypes';
import { MegaCarrier, MegaCarrierAllRates, MegaCarrierRate } from '../../../../types/topups';
import { TabsWithTitle } from '../../tabsWithTitle';
import { TopUpNautaContacts } from '../topup-nauta-contacts';
import { TopUpNautaEmail } from '../topup-nauta-email';

export const TopupNauta = ({ contacts }: { contacts: MegaContact[] }) => {
  const COUNTRY_CODE = 'CU';
  const CATEGORY = 'NAUTA';

  const [topupStep, setTopupStep] = useState<'contact' | 'other'>('other');
  const [nautaContacts, setNautaContacts] = useState<MegaContact[]>([]);
  const [selectedCarrier, setSelectedCarrier] = useState<MegaCarrier>();
  const [carrierRates, setCarrierRates] = useState<MegaCarrierRate[]>([]);
  const [tabList, setTabList] = useState<{ key: string; title: string }[]>([
    { key: 'other', title: 'Otro Número' },
  ]);

  const carriersQuery = useQuery([QueryTypes.GetTopUpCarriers, CATEGORY], () => {
    return CarrierServices.getCarriers(COUNTRY_CODE, CATEGORY);
  });

  const carrierRatesQuery = useQuery(
    [QueryTypes.GetTopUpCarrierRates, selectedCarrier],
    async () => {
      if (selectedCarrier) {        
        return CarrierServices.getCarrierRates(selectedCarrier.uid);
      } else {
        return [];
      }
    },
    {
      cacheTime: 0,
      onSettled: () => {
      },
    }
  );

  useEffect(() => {
    if (contacts.length > 0) {
      const realContacts = contacts.filter((contact) => !!contact.email);
      setNautaContacts(realContacts);

      if (realContacts.length > 0) {
        setTabList([
          { key: 'other', title: 'Otro Número' },
          { key: 'contact', title: 'Mis Contactos' },
        ]);
      }
    }
  }, contacts);

  useEffect(() => {
    if (carriersQuery.status === 'success') {
      setSelectedCarrier(carriersQuery.data[0]);
    }
  }, [carriersQuery.data]);

  useEffect(() => {
    if (carrierRatesQuery.status === 'success') {
      let rateData = carrierRatesQuery.data as MegaCarrierAllRates; 
      if (rateData?.initial_data){
        let rateList = rateData.products;   
        setCarrierRates(rateList);
      }      
    }
  }, [carrierRatesQuery.data]);

  return (
    <div>
      <TabsWithTitle
        title="Nauta a Recargar"
        tabs={tabList}
        activeTab={topupStep}
        wrapperClassName="mt-5"
        onTabChange={(key: string) => setTopupStep(key as 'other' | 'contact')}
      />

      {selectedCarrier && topupStep === 'other' && (
        <TopUpNautaEmail carrier={selectedCarrier} carrierRates={carrierRates} />
      )}

      {selectedCarrier && topupStep === 'contact' && (
        <TopUpNautaContacts
          carrier={selectedCarrier}
          carrierRates={carrierRates}
          contacts={nautaContacts}
        />
      )}
    </div>
  );
};
