import { TopUpCountry } from '../types/country';
import { MegaCarrier, MegaCarrierAllRates, MegaCarrierRate } from '../types/topups';
import api from './axios/api';

const getCarrierByPhoneAndCategory = async (
  phone: string,
  category: 'TOPUP' | 'BUNDLES'
): Promise<string | undefined> => {
  try {
    const response = await api.get<string>('/api/v1/carrier/listbyphonecategory', {
      params: {
        category,
        phone,
      },
    });

    if (!response.has_error) {
      return response.data;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const getCountries = async (): Promise<TopUpCountry[]> => {
  try {
    const response = await api.get<TopUpCountry>('/api/v1/countries/listactivecarriercountries');

    if (!!response.array && response.array.length > 0) {
      return response.array;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const getCarriers = async (
  country: string,
  category: 'TOPUP' | 'NAUTA' | 'BUNDLES'
): Promise<MegaCarrier[]> => {
  try {
    const response = await api.get<MegaCarrier>('/api/v1/carrier/listbycountry', {
      params: {
        country,
        category,
      },
    });

    if (!!response.array && response.array.length > 0) {
      return response.array;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const getCarrierRates = async (
  operator: number
): Promise<MegaCarrierAllRates> => {
  try {
    const response = await api.get<any>('/api/v1/rates/listtopuprates', {
      params: {        
        operator,
      },
    });

    if (!!response.data ) {
      return response.data;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const getCountryPhoneMask = (country: TopUpCountry | undefined) => {
  if (country === undefined) {
    return '';
  }

  const prefix = country.mobile_mask.replaceAll('N', '').trim();
  const suffix = country.mobile_mask.replace(prefix, '').replaceAll('N', '0');
  return `{${prefix.replaceAll('0', '\\0')}} ${suffix}`;
};

export const CarrierServices = {
  getCarrierByPhoneAndCategory,
  getCarrierRates,
  getCarriers,
  getCountries,
  getCountryPhoneMask,
};
