import { useNavigate } from 'react-router-dom';
import { PaymentLine, PaymentWizard } from '../../../components/account';
import { ROUTES } from '../../../config/routes';
import { useShoppingCart } from '../../../context/ShoppingCartContext';

export const PaymentScreen = () => {
  const { productsTotal } = useShoppingCart();
  const navigate = useNavigate();

  const onCancelPaymentScreen = () => {
    navigate(-1);
  };
  const onSuccessPayment = (isPrimary: boolean) => {
    if (isPrimary) {
      navigate(ROUTES.cuenta.store);
    }
  };

  return (
    <div className="bg-mega_light_gray">
      <div className="container py-5">
        <PaymentWizard
          show={true}
          paymentType={PaymentLine.combos}
          totalToPay={Number(productsTotal)}
          serviceFee={0}
          lines={[]}
          onCancel={onCancelPaymentScreen}
          onSuccess={onSuccessPayment}
        />
      </div>
    </div>
  );
};
