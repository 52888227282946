import * as React from 'react';
import { SVGProps } from 'react';
const SvgRecargas = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="recargas_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    {...props}>
    <defs>
      <style>{'.recargas_svg__cls-1{fill:#5586f7}.recargas_svg__cls-3{fill:#263645}'}</style>
    </defs>
    <g id="recargas_svg__Capa_1-2" data-name="Capa 1">
      <path
        d="M8.88 4.8c-3.05.42-5.4 2.9-5.65 5.97-.48 5.93-.74 12.2-.74 18.69s.26 12.76.74 18.69c.25 3.07 2.6 5.55 5.65 5.97 3.87.53 7.93.81 12.11.81s8.24-.28 12.11-.81c3.05-.42 5.4-2.9 5.65-5.97.48-5.93.74-12.2.74-18.69s-.26-12.76-.74-18.69A6.565 6.565 0 0 0 33.1 4.8c-3.87-.53-7.93-.81-12.11-.81s-8.24.28-12.11.81Z"
        style={{
          fill: '#d4e1fd',
        }}
      />
      <path
        className="recargas_svg__cls-1"
        d="M20.99 3.99c-3.33 0-6.57.18-9.71.52.04.59.08 1.18.13 1.76.13 1.65 1.4 2.99 3.05 3.22 2.09.28 4.28.44 6.53.44s4.45-.15 6.53-.44a3.534 3.534 0 0 0 3.05-3.22c.05-.58.09-1.17.13-1.76-3.14-.34-6.38-.52-9.71-.52Z"
      />
      <circle className="recargas_svg__cls-1" cx={42.08} cy={40.57} r={15.43} />
      <path
        className="recargas_svg__cls-3"
        d="M20.98 45.28c-3.28 0-4.28.59-4.28 2.54s1 2.54 4.28 2.54 4.28-.59 4.28-2.54-1-2.54-4.28-2.54Z"
      />
      <path
        className="recargas_svg__cls-3"
        d="M42.08 24.05c-.53 0-1.05.03-1.57.08-.1-4.56-.32-9.07-.67-13.45-.29-3.55-3.06-6.47-6.59-6.95l-1.4-.18-1.04-.12h-.03c-.77-.08-1.55-.16-2.34-.22-.07 0-.14-.01-.21-.02-.67-.05-1.35-.1-2.03-.14-.14 0-.27-.02-.41-.02-.68-.03-1.36-.06-2.04-.08-.23 0-.46-.01-.68-.02-.58-.01-1.16-.02-1.74-.02h-.68c-.58 0-1.16.01-1.74.02-.23 0-.46.01-.68.02-.68.02-1.36.05-2.04.08-.14 0-.27.02-.41.02-.68.04-1.35.08-2.03.14-.07 0-.14.01-.21.02-.78.06-1.56.14-2.34.22h-.03l-1.04.11-1.4.18c-3.53.48-6.3 3.41-6.59 6.95-.5 6.08-.75 12.39-.75 18.78s.25 12.7.75 18.78c.29 3.55 3.06 6.47 6.59 6.95 3.98.54 8.11.82 12.26.82s8.27-.28 12.26-.82c.28-.04.55-.1.82-.17 2.37 1.32 5.1 2.08 8 2.08 9.11 0 16.52-7.41 16.52-16.52s-7.41-16.52-16.52-16.52ZM23.26 5.11c1.39.03 2.78.1 4.16.2.71.05 1.42.11 2.12.18-.02.23-.04.46-.05.69a2.457 2.457 0 0 1-2.11 2.23c-4.15.57-8.63.57-12.77 0a2.457 2.457 0 0 1-2.11-2.23c-.02-.23-.04-.46-.05-.69.7-.07 1.41-.13 2.12-.18 1.38-.1 2.77-.16 4.16-.2h.22a93.4 93.4 0 0 1 4.1 0h.22ZM9.03 53.03c-2.52-.34-4.5-2.44-4.71-4.98-.49-6.02-.74-12.27-.74-18.6s.25-12.58.74-18.6a5.517 5.517 0 0 1 4.71-4.98c.42-.06.84-.11 1.26-.16.02.21.03.43.05.64a4.647 4.647 0 0 0 3.98 4.21c2.17.3 4.42.45 6.68.45s4.51-.15 6.68-.45a4.647 4.647 0 0 0 3.98-4.21c.02-.21.03-.43.05-.64.42.05.84.1 1.26.16 2.52.34 4.5 2.44 4.71 4.98.36 4.43.58 9 .68 13.63-7.32 1.7-12.79 8.26-12.79 16.09 0 5.07 2.3 9.61 5.91 12.65-7.35.87-15.16.8-22.44-.19Zm33.05 1.89c-7.91 0-14.35-6.44-14.35-14.35s6.44-14.35 14.35-14.35 14.35 6.44 14.35 14.35-6.44 14.35-14.35 14.35Z"
      />
      <path
        d="M45.83 40.08c-.43-.33-.92-.59-1.45-.78-.53-.2-1.04-.38-1.53-.54-.34-.11-.65-.21-.92-.32-.27-.1-.51-.21-.7-.34s-.35-.27-.45-.43c-.1-.16-.15-.37-.15-.61 0-.29.06-.52.19-.7s.29-.33.48-.45.4-.19.64-.23c.24-.04.47-.06.7-.06.45 0 .91.11 1.4.34.22.1.42.22.6.36.37.27.88.22 1.19-.11l.9-.95c.4-.42.31-1.09-.18-1.4-.42-.27-.87-.49-1.36-.66-.72-.25-1.43-.38-2.13-.41v-1.84c-.95-1.08-2.15-.42-2.15.52v1.5c-.08.02-.16.03-.24.05-.67.17-1.26.44-1.79.81-.53.37-.96.84-1.28 1.41-.33.57-.49 1.26-.49 2.06 0 .65.11 1.21.32 1.66.21.46.49.84.84 1.16.35.32.74.58 1.18.78s.89.38 1.36.53c.48.15.9.29 1.27.42s.69.27.94.43c.25.16.45.33.58.52.14.19.2.42.2.69 0 .29-.05.53-.16.73-.11.2-.25.37-.44.49-.18.12-.4.21-.64.27-.24.05-.5.08-.75.08-.57 0-1.14-.15-1.72-.44-.3-.15-.57-.32-.8-.51a.93.93 0 0 0-1.23.06l-.97.99c-.39.4-.34 1.05.11 1.37.47.34 1.01.63 1.62.85.69.26 1.39.42 2.12.49v2.34c.95 1.08 2.15.42 2.15-.52v-1.9c.27-.05.53-.09.79-.17a5.07 5.07 0 0 0 1.77-.88c.52-.39.93-.89 1.23-1.5.31-.6.46-1.32.46-2.15 0-.72-.14-1.32-.43-1.8s-.65-.89-1.08-1.21Z"
        style={{
          fill: '#fff',
          fillRule: 'evenodd',
        }}
      />
    </g>
  </svg>
);
export default SvgRecargas;
