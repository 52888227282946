import * as React from 'react';
import { SVGProps } from 'react';
const SvgFlechaIzq = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" {...props}>
    <g data-name="Capa 1">
      <path
        data-name="Trazado 10288"
        className="flecha_izq_svg__cls-1"
        d="m0 11.5 8.13 8.13 1.89-1.89-4.91-4.91H23v-2.67H5.12l4.91-4.9-1.89-1.89L0 11.5Z"
      />
    </g>
  </svg>
);
export default SvgFlechaIzq;
