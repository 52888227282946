import { MODAL_TYPES, useGlobalModalContext } from '../../../providers/globalModal.provider';
import { PaymentServices } from '../../../services';
import { PaymentMethodCard } from '../../../types/payment';
import { PaymentMethodItem } from '../paymentMethodItem';

export type PaymentMethodsListProps = {
  paymentMethods: PaymentMethodCard[];
  activePaymentMethodId?: string;
  onEdit?: (paymentMethod: PaymentMethodCard) => void;
  onDeleted?: (paymentMethod: PaymentMethodCard) => void;
  onSelect?: (paymentMethod: PaymentMethodCard) => void;
  hideManageButtons?: boolean;
  showResponsive?: boolean;
  hideCheckboxes?: boolean;
};

export const PaymentMethodsList = ({
  activePaymentMethodId,
  onDeleted,
  onEdit,
  onSelect,
  paymentMethods,
  hideManageButtons,
  showResponsive,
  hideCheckboxes,
}: PaymentMethodsListProps) => {
  const { showModal, hideModal } = useGlobalModalContext();

  const deletePaymentMethod = async (pm: PaymentMethodCard) => {
    showModal(MODAL_TYPES.LOADING, {
      title: 'Eliminando método de pago',
    });
    const isDeleted = await PaymentServices.deleteExistingCard(pm.id);

    hideModal();
    if (isDeleted) {
      !!onDeleted && onDeleted(pm);
    }
  };

  const onDeleteClick = (item: PaymentMethodCard) => {
    showModal(MODAL_TYPES.CONFIRM_DELETE, {
      title: '¿Estas seguro que quieres eliminar este método de pago?',
      description: 'La eliminación del método de pago no se puede deshacer',
      onClose: (response: boolean) => {
        !!response && deletePaymentMethod(item);
      },
    });
  };

  return (
    <div>
      {!showResponsive &&
        paymentMethods.map((item) => (
          <PaymentMethodItem
            key={item.id}
            fullName={item.first_name + ' ' + item.last_name}
            subtitle={
              item.payment_type.toLowerCase() === 'paypal'
                ? item.email
                : `···· ···· ···· ${item.last_four_digits}`
            }
            paymentType={item.payment_type.toLowerCase()}
            onSelect={() => !!onSelect && onSelect(item)}
            onEdit={() => !!onEdit && onEdit(item)}
            onDelete={() => onDeleteClick(item)}
            isNew={false}
            isSelected={activePaymentMethodId === item.id}
            hideManageButtons={hideManageButtons}
            hideCheckboxes={hideCheckboxes}
          />
        ))}

      {showResponsive && (
        <div className="row">
          {paymentMethods.map((item) => (
            <div className="col-12 col-lg-6" key={item.id}>
              <PaymentMethodItem
                fullName={item.first_name + ' ' + item.last_name}
                subtitle={
                  item.payment_type.toLowerCase() === 'paypal'
                    ? item.email
                    : `···· ···· ···· ${item.last_four_digits}`
                }
                paymentType={item.payment_type.toLowerCase()}
                onSelect={() => !!onSelect && onSelect(item)}
                onEdit={() => !!onEdit && onEdit(item)}
                onDelete={() => onDeleteClick(item)}
                isNew={false}
                isSelected={activePaymentMethodId === item.id}
                hideManageButtons={hideManageButtons}
                hideCheckboxes={hideCheckboxes}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
