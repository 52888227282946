import api from './axios/api';
import { PaymentServices } from './payments';
import { Client, PayPal, PayPalTokenizePayload, client, paypal } from 'braintree-web';

export const PaypalService = {
  getTokenizedPayload: async () => {
    const tokenResponse = await PaymentServices.getBraintreeAuth();
    const clientInstance: Client = await client.create({ authorization: tokenResponse.token });
    const paypalInstance: PayPal = await paypal.create({ client: clientInstance });

    if (!paypalInstance) {
      throw Error('No Paypal instance');
    }

    const payload: PayPalTokenizePayload = await paypalInstance.tokenize({
      flow: 'vault',
    });

    return payload;
  },
  addPaymentMethod: async ({
    nonce,
    deviceData,
    email
  }: {
    nonce: string;
    deviceData: string;
    email: string;
  }): Promise<boolean> => {
    try {
      const response = await api.post('/api/v1/payment/paymentmethods', {
        nonce,
        is_paypal: '1',
        device_data: deviceData,
        email: email
      });

      if (!response.has_error) {
        return true;
      }

      throw new Error('ERROR: No data');
    } catch (error) {
      throw error;
    }
  },
};
