import { yupResolver } from '@hookform/resolvers/yup';
import { isEmpty } from 'lodash';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { InputGroup } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import { useQuery } from 'react-query';
import Select from 'react-select';
import * as yup from 'yup';
import {
  IconOptionMunicipality,
  IconOptionProvince,
  IconSingleValueMunicipality,
  IconSingleValueProvince,
  reactSelectStyles,
} from '../../../config/reactSelect';
import { UtilService } from '../../../services';
import { StoreService } from '../../../services/store';
import { MegaContact } from '../../../types/contacts';
import { Municipality } from '../../../types/municipality';
import { Province } from '../../../types/province';
import { QueryTypes } from '../../../types/queryTypes';
import { FormHelpers } from '../../../utilities/FormHelpers';
import { errorMessages } from '../../../utilities/errorMessages';
import { MegaError } from '../megaError';

type CheckoutAddressPersonProps = {
  acceptedProvinces: number[];
  acceptedMunicipalities: number[];

  editContact?: MegaContact;
};

const deliverySchema = yup
  .object()
  .shape({
    firstName: yup.string().required(errorMessages.firstName),
    lastName: yup.string().required(errorMessages.lastName),
    address: yup.string().required(errorMessages.address1),
    town: yup.string(),
    municipality: yup.object().required(errorMessages.municipality),
    province: yup.object().required(errorMessages.province),
    save: yup.boolean().optional(),
  })
  .required();

const CheckoutAddressPersonComponent = (props: CheckoutAddressPersonProps, ref: any) => {
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [municipalities, setMunicipalities] = useState<Municipality[]>([]);

  const [phone, setPhone] = useState('');
  const [phoneHasError, setPhoneHasError] = useState(false);
  const phoneRef = useRef<any>(null);

  const {
    register,
    setValue,
    control,
    watch,
    trigger,
    handleSubmit,
    formState,
    clearErrors,
    reset,
    getValues,
  } = useForm({
    resolver: yupResolver(deliverySchema),
    mode: 'onChange',
  });

  const { isValid: isFormValid } = formState;

  const selectedProvince = watch('province');
  const selectedMunicipality = watch('municipality');

  const provinceQuery = useQuery(QueryTypes.GetStoreProvinces, StoreService.getProvinces);
  const municipalitiesQuery = useQuery(
    [QueryTypes.GetStoreMunicipalities, selectedProvince],
    () => {
      if (!!selectedProvince) {
        return StoreService.getMunicipalities(selectedProvince.id);
      }

      return [];
    }
  );

  useEffect(() => {
    if (!!props.editContact) {
      reset({
        firstName: props.editContact.first_name || props.editContact.contact_name || '',
        lastName: props.editContact.last_name || '',
        address: props.editContact.address || '',
        town: props.editContact.town || '',
        province: provinces.find((p) => p.id === props.editContact?.province_id) ?? undefined,
        municipality:
          municipalities.find((p) => p.id === props.editContact?.municipality_id) ?? undefined,
      });

      let p = props.editContact.contact_number;
      p = p.replace('53', '');
      setPhone(p);

      municipalitiesQuery.refetch();
    }
  }, [props.editContact]);

  useEffect(() => {
    if (provinceQuery.status === 'success' && provinceQuery.data.length) {
      const pList = provinceQuery.data.filter((p) => props.acceptedProvinces.includes(p.id));
      setProvinces(pList);

      if (props.acceptedProvinces) {
        setValue(
          'province',
          pList.find((p) => p.id === props.acceptedProvinces[0])
        );
      }

      if (props.editContact?.province_id) {
        setValue(
          'province',
          pList.find((p) => p.id === props.editContact?.province_id)
        );
      }
    }
  }, [provinceQuery.status]);

  useEffect(() => {
    setValue('municipality', undefined);
  }, [selectedProvince]);

  useEffect(() => {
    if (municipalitiesQuery.data) {
      let mList = props.acceptedMunicipalities.length
        ? municipalitiesQuery.data.filter((m) => props.acceptedMunicipalities.includes(m.id))
        : municipalitiesQuery.data;

      if (isEmpty(mList)) {
        mList = municipalitiesQuery.data;
      }

      setMunicipalities(mList);
      setValue(
        'municipality',
        props.editContact?.municipality_id
          ? mList.find((m) => m.id === props.editContact?.municipality_id)
          : undefined
      );
      clearErrors('municipality');
    }
  }, [municipalitiesQuery.data]);

  useEffect(() => {
    !!phone && validatePhone();
  }, [phone]);

  const validatePhone = () => {
    const mask = '00000000';
    let error = false;

    if (mask) {
      error = '00000000'.length !== (phone || '').length;
    }

    setPhoneHasError(error);
    return error;
  };

  useImperativeHandle(ref, () => ({
    isFormValid() {
      trigger();
      validatePhone();
      return isFormValid && !validatePhone();
    },
    getFormValues() {
      const form = getValues();
      return {
        ...form,
        phone,
      };
    },
  }));

  const submitForm = () => {};

  const allQueries = [provinceQuery, municipalitiesQuery];

  if (UtilService.isErrorQueries(allQueries)) {
    return <MegaError displayRetry retry={() => UtilService.refetchQueries(allQueries)} />;
  }

  return (
    <form id="checkout-person" onSubmit={handleSubmit(submitForm)} noValidate>
      <div className="row">
        <div className="col-12">
          <p className="mega-bold">Información del destinatario(a)</p>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 mb-5">
          {FormHelpers.formTextField('text', 'firstName', formState.errors, register, 'Nombre *')}
        </div>
        <div className="col-12 col-md-6 mb-5">
          {FormHelpers.formTextField(
            'text',
            'lastName',
            formState.errors,
            register,
            'Apellido(s) *'
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 mb-5">
          <div className="megaInput">
            <label htmlFor="phone" className="form-label mega">
              Tel&eacute;fono *
            </label>

            <InputGroup>
              <span className="input-group-text mega less">+53</span>
              {!props.editContact && (
                <IMaskInput
                  mask={'00000000'}
                  unmask={true}
                  ref={phoneRef}
                  value={phone}
                  onAccept={(value, _) => setPhone(`${value}`)}
                  lazy={false}
                  className={`form-control mega ${phoneHasError ? 'border border-danger' : ''}`}
                />
              )}
              {!!props.editContact && (
                <input type="text" className="form-control mega" value={phone} disabled />
              )}
            </InputGroup>
          </div>
          {!!phoneHasError && <div className="text-danger">El teléfono es requerido</div>}
        </div>
        <div className="col-12 col-md-6 mb-5"></div>
      </div>

      <div className="row">
        <div className="col-12 mb-2">
          <p className="mega-bold">Dirección de envío</p>
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 mb-5">
          {FormHelpers.formTextField('text', 'address', formState.errors, register, 'Dirección *')}
        </div>
        <div className="col-12 col-md-6 mb-5">
          {FormHelpers.formTextField(
            'text',
            'town',
            formState.errors,
            register,
            'Reparto/Ciudad *'
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-md-6 mb-5">
          <div className="megaInput">
            <label htmlFor="province">Provincia *</label>
            <Controller
              control={control}
              name="province"
              render={({ field: { onChange, name, value } }) => (
                <Select
                  id="province"
                  placeholder="Seleccione una provincia"
                  name={name}
                  value={value}
                  options={provinces}
                  isSearchable={false}
                  isDisabled={
                    props.acceptedProvinces.length === 1 && !!props.editContact?.province_id
                  }
                  isClearable={false}
                  isLoading={provinceQuery.isFetching}
                  className="w-100 mega-select"
                  isMulti={false}
                  styles={reactSelectStyles}
                  components={{
                    Option: IconOptionProvince,
                    SingleValue: IconSingleValueProvince,
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          {FormHelpers.formError('province', formState.errors)}
        </div>
        <div className="col-12 col-md-6 mb-5">
          <div className="megaInput">
            <label htmlFor="municipality">Municipio *</label>
            <Controller
              control={control}
              name="municipality"
              render={({ field: { onChange, name, value } }) => (
                <Select
                  id="municipality"
                  name={name}
                  placeholder="Seleccione un municipio"
                  value={value}
                  options={municipalities}
                  isSearchable={false}
                  isDisabled={
                    props.acceptedMunicipalities.length === 1 &&
                    !!props.editContact?.municipality_id
                  }
                  isClearable={false}
                  isLoading={municipalitiesQuery.isFetching}
                  className="w-100 mega-select"
                  isMulti={false}
                  styles={reactSelectStyles}
                  components={{
                    Option: IconOptionMunicipality,
                    SingleValue: IconSingleValueMunicipality,
                  }}
                  onChange={onChange}
                />
              )}
            />
          </div>
          {FormHelpers.formError('municipality', formState.errors)}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <div className="form-check">
            <input
              type="checkbox"
              className={`form-check-input mega`}
              id="save"
              {...register('save')}
            />
            <label htmlFor="save" className="form-check-label mega">
              {!!props.editContact
                ? 'Actualizar la información del contacto para usos futuros.'
                : 'Salvar la información como contacto para usos futuros.'}
            </label>
          </div>
        </div>
      </div>
    </form>
  );
};

export const CheckoutAddressPerson = React.forwardRef(CheckoutAddressPersonComponent);
