import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { MODAL_TYPES, useGlobalModalContext } from '../../../providers/globalModal.provider';
import { PaymentServices } from '../../../services';
import { getDeviceData } from '../../../services/nonce';
import { PaypalService } from '../../../services/paypal';
import { NewCardInformation } from '../../../types/newCardInformation';
import { NewPaypalInformation } from '../../../types/newPaypalInformation';
import { PaymentMethodCard } from '../../../types/payment';
import { QueryTypes } from '../../../types/queryTypes';
import { MegaLoading } from '../../external/megaLoading';
import { Agregar } from '../../svg';
import { CreditCardForm } from '../creditCardForm';
import { PaymentMethodItem } from '../paymentMethodItem';
import { PaymentMethodSelector } from '../paymentMethodSelector';
import { PaymentMethodsList } from '../paymentMethodsList';

type ProfilePaymentMethodsProps = {
  onSelectPaymentMethod: (
    item: PaymentMethodCard | NewCardInformation | NewPaypalInformation | undefined,
    type: 'card' | 'paypal' | 'new-card' | 'new-paypal' | undefined
  ) => void;
  hideManageButtons?: boolean;
  forceAddNewPaymentMethod?: boolean;
  showResponsive?: boolean;
  hideCheckboxes?: boolean;
  onlySavePmOnDb?: boolean;
};

export const ProfilePaymentMethods = ({
  onSelectPaymentMethod,
  hideManageButtons,
  showResponsive,
  hideCheckboxes,
  onlySavePmOnDb,
  forceAddNewPaymentMethod,
}: ProfilePaymentMethodsProps) => {
  const { showModal, hideModal } = useGlobalModalContext();

  const [paymentMethods, setPaymentMethods] = useState<PaymentMethodCard[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState<
    PaymentMethodCard | undefined
  >();
  const [selectedPmType, setSelectedPmType] = useState<
    undefined | string | 'new-card' | 'new-paypal'
  >();

  const [showMethodSelector, setShowMethodSelector] = useState<boolean>(false); // add new paypal or cc
  const [showCreditCardForm, setShowCreditCardForm] = useState<boolean>(false); // this should be moved out of here
  const [editablePaymentMethodCard, setEditablePaymentMethodCard] = useState<
    PaymentMethodCard | NewCardInformation | undefined
  >();
  const [editablePaymentMethodType, setEditablePaymentMethodType] = useState<'new-card' | 'card'>();

  // new card or paypal objects
  const [newCardInformation, setNewCardInformation] = useState<NewCardInformation>();
  const [newPaypalInformation, setNewPaypalInformation] = useState<NewPaypalInformation>();

  const paymentMethodsQuery = useQuery(
    QueryTypes.GetPaymentMethods,
    PaymentServices.getPaymentMethods
  );

  useEffect(() => {
    if (paymentMethodsQuery.status === 'success') {
      setIsLoading(false);
      setPaymentMethods(paymentMethodsQuery.data);

      if (paymentMethodsQuery.data.length > 0) {
        onSelectExistingPaymentMethod(paymentMethodsQuery.data[0]);
      }
    }
  }, [paymentMethodsQuery.data]);

  // existing pm
  const onSelectExistingPaymentMethod = (item: PaymentMethodCard | undefined) => {
    setSelectedPaymentMethod(item);
    const type = !item
      ? undefined
      : (item.payment_type || '').toLowerCase() === 'paypal'
      ? 'paypal'
      : 'card';

    setSelectedPmType(type);
    onSelectPaymentMethod(item, type);
  };

  // existing pm
  const onEditExistingCreditCard = (paymentMethodCard: PaymentMethodCard) => {
    setEditablePaymentMethodCard(paymentMethodCard);
    setEditablePaymentMethodType('card'); // you cannot edit paypal
    setShowCreditCardForm(true);
  };

  // new cc
  const onDeleteNewCreditCard = () => {
    setNewCardInformation(undefined);
    if (selectedPmType === 'new-card') {
      setSelectedPmType(undefined);
      onSelectPaymentMethod(undefined, undefined);
    }
  };

  // new cc
  const onEditNewCreditCard = () => {
    setEditablePaymentMethodCard(newCardInformation);
    setEditablePaymentMethodType('new-card');
    setShowCreditCardForm(true);
  };

  // new pp
  const onDeleteNewPaypal = () => {
    setNewPaypalInformation(undefined);
    if (selectedPmType === 'new-paypal') {
      setSelectedPmType(undefined);
      onSelectPaymentMethod(undefined, undefined);
    }
  };

  const onSavePaymentSelector = (selected: 'cc' | 'paypal') => {
    setShowMethodSelector(false);

    switch (selected) {
      case 'cc':
        openCreditCardForm();
        break;
      case 'paypal':
        openPaypal();
        break;
    }
  };

  const openPaypal = async () => {
    try {
      const payload = await PaypalService.getTokenizedPayload();

      if (!onlySavePmOnDb) {
        setNewPaypalInformation({
          ...payload,
          saveMethod: false,
        } as unknown as NewPaypalInformation);
        setSelectedPmType('new-paypal');

        onSelectPaymentMethod(
          {
            ...payload,
            saveMethod: false,
          } as unknown as NewPaypalInformation,
          'new-paypal'
        );
      } else {
        showModal(MODAL_TYPES.LOADING, { title: 'Salvando su información de Paypal' });

        const deviceData = await getDeviceData();

        const isPaypalAdded = await PaypalService.addPaymentMethod({
          nonce: payload.nonce,
          deviceData,
          email: payload.details.email
        });

        if (isPaypalAdded) {
          paymentMethodsQuery.refetch();
        }

        hideModal();
      }
    } catch (error: any) {
      hideModal();

      if (error.name == 'BraintreeError' && error.code === 'PAYPAL_POPUP_CLOSED') {
        if (error.code === 'PAYPAL_POPUP_CLOSED') {
          return;
        }
      }

      showModal(MODAL_TYPES.ERROR, {
        title: 'Error',
        description:
          'Hubo un error tratando de salvar su información de Paypal. Por favor, intente de nuevo.',
        state: {
          buttonTitle: 'Reintentar',
          buttonLinkTitle: 'Continuar',
          cardError: true,
        },
        onClose: (status) => {
          if (status === 'primary') {
            openPaypal();
          }
        },
      });
    }
  };

  const openCreditCardForm = () => {
    setEditablePaymentMethodCard(undefined);
    setEditablePaymentMethodType('new-card');
    setShowCreditCardForm(true);
  };

  const closeCreditCardForm = () => {
    setShowCreditCardForm(false);
    setEditablePaymentMethodCard(undefined);
    setEditablePaymentMethodType(undefined);
  };

  const handleCreditCardFormSave = async (data: any) => {
    closeCreditCardForm();

    if (editablePaymentMethodCard === undefined) {
      if (onlySavePmOnDb) {
        try {
          showModal(MODAL_TYPES.LOADING, { title: 'Salvando su tarjeta' });
          await PaymentServices.addCreditCard({ ...data, saveMethod: true });
          paymentMethodsQuery.refetch();
          hideModal();
        } catch (error) {
          hideModal();
          showModal(MODAL_TYPES.ERROR, {
            title: 'Error',
            description:
              'Hubo un error tratando de salvar su tarjeta. Por favor, intente de nuevo.',
            state: {
              buttonTitle: 'Reintentar',
              buttonLinkTitle: 'Continuar',
              cardError: true,
            },
            onClose: (status) => {
              if (status === 'primary') {
                handleCreditCardFormSave(data);
              }
            },
          });
        }
      } else {
        // Store new card information
        setNewCardInformation(data as NewCardInformation);
        setSelectedPmType('new-card');
        onSelectPaymentMethod(data as NewCardInformation, 'new-card');
      }
    } else if (editablePaymentMethodType === 'card') {
      showModal(MODAL_TYPES.LOADING, { title: 'Salvando su tarjeta' });

      try {
        await PaymentServices.updateExistingCard(
          (editablePaymentMethodCard as PaymentMethodCard).id,
          {
            ...data,
            first_name: editablePaymentMethodCard.first_name,
            last_name: editablePaymentMethodCard.last_name,
          }
        );
        paymentMethodsQuery.refetch();

        hideModal();
      } catch (error) {
        hideModal();

        showModal(MODAL_TYPES.ERROR, {
          title: 'Error',
          description: 'Hubo un error tratando de salvar su tarjeta. Por favor, intente de nuevo.',
          state: {
            buttonTitle: 'Reintentar',
            buttonLinkTitle: 'Continuar',
            cardError: true,
          },
          onClose: (status) => {
            if (status === 'primary') {
              handleCreditCardFormSave(data);
            }
          },
        });
      }
    } else {
      setNewCardInformation(data as NewCardInformation);
      setSelectedPmType('new-card');
      onSelectPaymentMethod(data as NewCardInformation, 'new-card');
    }
  };

  const onDeleteExistingPaymentMethod = (item: PaymentMethodCard) => {
    if (item.id === selectedPaymentMethod?.id) {
      setSelectedPaymentMethod(undefined);
      setSelectedPmType(undefined);
      onSelectPaymentMethod(undefined, undefined);
    }

    paymentMethodsQuery.refetch();
  };

  return (
    <div className="w-100">
      {isLoading ? (
        <MegaLoading isLoading={true} />
      ) : (
        <PaymentMethodsList
          activePaymentMethodId={
            ['card', 'paypal'].includes(selectedPmType ?? '')
              ? selectedPaymentMethod?.id
              : undefined
          }
          paymentMethods={paymentMethods}
          onEdit={onEditExistingCreditCard}
          onSelect={onSelectExistingPaymentMethod}
          onDeleted={onDeleteExistingPaymentMethod}
          hideManageButtons={hideManageButtons}
          showResponsive={showResponsive}
          hideCheckboxes={hideCheckboxes}
        />
      )}
      {newCardInformation && (
        <div className="mb-4">
          <PaymentMethodItem
            fullName={`${newCardInformation.first_name} ${newCardInformation.last_name}`}
            subtitle={newCardInformation.title}
            paymentType={'card'}
            onSelect={() => setSelectedPmType('new-card')}
            onDelete={onDeleteNewCreditCard}
            onEdit={onEditNewCreditCard}
            isNew={true}
            isSelected={selectedPmType === 'new-card'}
            saveMethod={newCardInformation.saveMethod}
            hideManageButtons={hideManageButtons}
            hideCheckboxes={hideCheckboxes}
            onSaveMethodChange={(saveMethod) => {
              setNewCardInformation({ ...newCardInformation, saveMethod });

              if (selectedPmType === 'new-card') {
                onSelectPaymentMethod({ ...newCardInformation, saveMethod }, 'new-card');
              }
            }}
          />
        </div>
      )}
      {newPaypalInformation && (
        <div className="mb-4">
          <PaymentMethodItem
            fullName={`${newPaypalInformation.details.firstName} ${newPaypalInformation.details.lastName}`.trim()}
            subtitle={newPaypalInformation.details.email}
            paymentType={'paypal'}
            onSelect={() => setSelectedPmType('new-paypal')}
            onDelete={onDeleteNewPaypal}
            isSelected={selectedPmType === 'new-paypal'}
            isNew
            saveMethod={newPaypalInformation.saveMethod}
            hideManageButtons={hideManageButtons}
            hideCheckboxes={hideCheckboxes}
            onSaveMethodChange={(saveMethod) => {
              setNewPaypalInformation({ ...newPaypalInformation, saveMethod });

              if (selectedPmType === 'new-paypal') {
                onSelectPaymentMethod({ ...newPaypalInformation, saveMethod }, 'new-paypal');
              }
            }}
          />
        </div>
      )}

      {(!hideManageButtons || forceAddNewPaymentMethod) && (
        <div>
          <Button
            variant="outline-secondary"
            onClick={() => setShowMethodSelector(true)}
            className="w-100 w-lg-auto">
            <Agregar width={16} className="me-1" /> Agregar nuevo método de pago
          </Button>
        </div>
      )}

      <PaymentMethodSelector
        display={showMethodSelector}
        onCancel={() => setShowMethodSelector(false)}
        onSave={onSavePaymentSelector}
      />

      {showCreditCardForm && (
        <CreditCardForm
          editablePaymentMethod={editablePaymentMethodCard}
          editablePaymentMethodType={editablePaymentMethodType}
          onCancel={closeCreditCardForm}
          onSave={handleCreditCardFormSave}
          display={showCreditCardForm}
          submitButtonText={
            editablePaymentMethodType === 'new-card' ? 'Agregar nueva tarjeta' : 'Modificar tarjeta'
          }
          modalTitle={
            editablePaymentMethodType === 'new-card' ? 'Agregar nueva tarjeta' : 'Modificar tarjeta'
          }
        />
      )}
    </div>
  );
};
