import { useShoppingCart } from '../../../context/ShoppingCartContext';

import { useEffect } from 'react';
import { Button, Offcanvas } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';
import { Formatter } from '../../../utilities/Formatter';
import { productImgUrl } from '../../../utilities/productImgUrl';

import { CarritoMenu, CartMas, CartMenos, CartRemove } from '../../svg';
import styles from './cartWidget.module.scss';

export const CartWidget = () => {
  const navigate = useNavigate();
  const {
    getProductsCount,
    isSidebarVisible,
    setSidebarVisibility,
    products,
    updateProduct,
    removeProduct,
    productsTotal,
  } = useShoppingCart();

  const goToCart = () => {
    navigate(ROUTES.cuenta.shoppingCart);
    setSidebarVisibility(false);
  };

  const goToCheckout = () => {
    navigate(ROUTES.cuenta.checkout);
    setSidebarVisibility(false);
  };

  useEffect(() => {
    if (isSidebarVisible && getProductsCount === 0) {
      setSidebarVisibility(false);
    }
  }, [getProductsCount]);

  return (
    <>
      <Offcanvas
        id="cartCanvas"
        show={isSidebarVisible}
        placement={'end'}
        onHide={() => setSidebarVisibility(false)}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>
            <CarritoMenu width={25} className="me-1" /> Carrito ({getProductsCount})
          </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div className={styles.sidebar_body_content}>
            <div className={styles.sidebar_body_items_container}>
              {products.map((product, index) => (
                <div className={styles.sidebar_body_content_item} key={`sidebar-product-${index}`}>
                  <img
                    className={styles.sidebar_body_content_item_img}
                    src={productImgUrl(product.main_image.image_url)}
                  />
                  <div className={styles.sidebar_body_content_item_details}>
                    <div>{product.name}</div>
                    <div className={styles.sidebar_body_content_item_price}>
                      {Formatter.toCurrency(product.price)}
                    </div>
                    <div>
                      <div className={styles.product_info_detail}>{product.category.name}</div>
                      {product.deliveries.map((delivery, index) => (
                        <div
                          className={styles.product_info_detail}
                          key={`product-card--detail-${product.id}-${index}`}>
                          <span className={styles.product_info_detail_title}>
                            {delivery.name}:{' '}
                          </span>
                          {delivery.duration}
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className="d-flex flex-column justify-content-center align-items-center d-grid gap-1">
                    {product.shopping_cart_quantity > 1 && (
                      <Button
                        variant="link"
                        className="px-0"
                        onClick={() => updateProduct(product.id, -1)}>
                        <CartMenos width={30} />
                      </Button>
                    )}
                    {product.shopping_cart_quantity == 1 && (
                      <Button
                        variant="link"
                        className="px-0"
                        onClick={() => removeProduct(product.id)}>
                        <CartRemove width={30} />
                      </Button>
                    )}
                    <div className={`${styles.quantity}`}>{product.shopping_cart_quantity}</div>
                    <Button
                      variant="link"
                      className="px-0"
                      onClick={() => updateProduct(product.id, 1)}>
                      <CartMas width={30} />
                    </Button>
                  </div>
                </div>
              ))}
            </div>
            <div className={styles.footer}>
              <Button variant="secondary" onClick={goToCheckout} className="mb-2 w-100">
                Comprar ahora ({Formatter.toCurrency(productsTotal.toFixed(2))})
              </Button>

              <Button variant="mega_secondary_2" onClick={goToCart} className="w-100">
                Ver carrito
              </Button>
            </div>
          </div>
        </Offcanvas.Body>
      </Offcanvas>

      <div
        className={styles.cart_widget}
        onClick={() => setSidebarVisibility(getProductsCount > 0 && !isSidebarVisible)}>
        <CarritoMenu width={25} className="me-1" />
        {getProductsCount}
      </div>
    </>
  );
};
