import * as React from 'react';
import { SVGProps } from 'react';
const SvgNoTienesRecarga = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="no_tienes_recarga_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 66"
    {...props}>
    <defs>
      <clipPath id="no_tienes_recarga_svg__clippath">
        <path className="no_tienes_recarga_svg__cls-7" d="M0 0h66v66H0z" />
      </clipPath>
      <clipPath id="no_tienes_recarga_svg__clippath-1">
        <path className="no_tienes_recarga_svg__cls-7" d="M0 0h66v66H0z" />
      </clipPath>
      <clipPath id="no_tienes_recarga_svg__clippath-2">
        <path className="no_tienes_recarga_svg__cls-7" d="M0 0h66v66H0z" />
      </clipPath>
      <style>
        {'.no_tienes_recarga_svg__cls-1{fill:#c5c5c5}.no_tienes_recarga_svg__cls-7{fill:none}'}
      </style>
    </defs>
    <g
      style={{
        clipPath: 'url(#no_tienes_recarga_svg__clippath)',
      }}
      id="no_tienes_recarga_svg__Capa_1-2"
      data-name="Capa 1">
      <g id="no_tienes_recarga_svg__Grupo_5779" data-name="Grupo 5779">
        <g
          style={{
            clipPath: 'url(#no_tienes_recarga_svg__clippath-1)',
          }}
          id="no_tienes_recarga_svg__Grupo_5778"
          data-name="Grupo 5778">
          <g id="no_tienes_recarga_svg__Grupo_5777" data-name="Grupo 5777">
            <path
              id="no_tienes_recarga_svg__Trazado_10987"
              data-name="Trazado 10987"
              className="no_tienes_recarga_svg__cls-1"
              d="M36.26 58.79c-8.87 1.18-17.85 1.18-26.72 0a6.177 6.177 0 0 1-5.29-5.58c-.55-6.74-.83-13.74-.83-20.81s.28-14.08.83-20.81a6.177 6.177 0 0 1 5.29-5.58 100.89 100.89 0 0 1 26.78 0c2.85.4 5.04 2.72 5.29 5.58.44 5.37.7 10.91.78 16.53.76-.28 1.58-.4 2.39-.34-.1-5.56-.36-11.05-.79-16.38a8.58 8.58 0 0 0-7.35-7.76c-9.1-1.21-18.32-1.21-27.42 0-3.96.56-7 3.78-7.35 7.76-.55 6.8-.84 13.86-.84 21s.28 14.2.84 21a8.58 8.58 0 0 0 7.35 7.76c4.54.61 9.13.92 13.71.92 4.59 0 9.17-.3 13.71-.92.64-.09 1.26-.25 1.87-.48-.97-.3-1.78-.97-2.24-1.88"
            />
            <path
              id="no_tienes_recarga_svg__Trazado_10988"
              data-name="Trazado 10988"
              className="no_tienes_recarga_svg__cls-1"
              d="M22.92 12.95c-2.51 0-5.02-.2-7.5-.59A5.573 5.573 0 0 1 11.01 7c-.05-.77-.1-1.55-.14-2.34l-.06-1.11 1.11-.14c7.31-.93 14.71-.93 22.02 0l1.1.14-.06 1.11c-.04.79-.09 1.57-.14 2.34a5.565 5.565 0 0 1-4.41 5.36c-2.48.4-4.99.59-7.5.59m-9.6-7.31c.02.4.05.8.08 1.19 0 1.48.98 2.77 2.4 3.16 4.72.75 9.52.75 14.24 0a3.274 3.274 0 0 0 2.41-3.16c.03-.39.05-.79.08-1.19-6.38-.72-12.82-.72-19.2 0"
            />
            <path
              id="no_tienes_recarga_svg__Trazado_10989"
              data-name="Trazado 10989"
              className="no_tienes_recarga_svg__cls-1"
              d="M18.12 52.94c0-2.18 1.11-2.84 4.79-2.84s4.79.66 4.79 2.84-1.11 2.84-4.79 2.84-4.79-.66-4.79-2.84"
            />
            <path
              id="no_tienes_recarga_svg__Trazado_10990"
              data-name="Trazado 10990"
              className="no_tienes_recarga_svg__cls-1"
              d="M46.51 63.29c-10.2 0-18.46-8.26-18.46-18.46s8.26-18.46 18.46-18.46 18.46 8.26 18.46 18.46c-.01 10.19-8.27 18.45-18.46 18.46m0-34.53c-8.88 0-16.07 7.19-16.07 16.07S37.63 60.9 46.51 60.9s16.07-7.19 16.07-16.07c0-8.87-7.2-16.06-16.07-16.07"
            />
            <path
              id="no_tienes_recarga_svg__Trazado_10991"
              data-name="Trazado 10991"
              d="M50.7 44.28c-.49-.37-1.04-.67-1.62-.88-.59-.22-1.16-.42-1.71-.6-.38-.12-.72-.24-1.03-.35-.27-.1-.54-.23-.79-.38-.2-.12-.37-.28-.5-.48-.12-.21-.18-.44-.17-.68-.01-.28.06-.55.22-.79.14-.2.32-.37.53-.5.22-.13.46-.22.72-.26.26-.04.52-.07.79-.07.54 0 1.08.14 1.56.38.24.11.46.24.67.4.41.3.98.25 1.33-.12l1.01-1.07a1.014 1.014 0 0 0-.2-1.56c-.48-.3-.99-.55-1.52-.74-.77-.27-1.57-.42-2.38-.46v-2.07c-1.06-1.2-2.4-.47-2.4.58v1.68c-.09.02-.18.03-.27.05-.72.18-1.4.48-2.01.9-.59.41-1.08.95-1.44 1.57-.38.71-.57 1.5-.55 2.3-.02.64.1 1.27.35 1.86.22.49.54.93.94 1.3.39.36.84.66 1.32.88.49.23 1 .43 1.52.59.53.17 1.01.32 1.42.47.37.12.72.28 1.05.48.25.15.47.35.65.58.16.23.24.5.23.78 0 .28-.05.57-.18.82-.12.22-.28.41-.49.55-.22.14-.46.25-.72.3-.28.06-.56.09-.84.09-.67 0-1.33-.18-1.93-.49-.32-.16-.62-.35-.9-.57-.41-.33-1-.3-1.37.07l-1.09 1.11a1.023 1.023 0 0 0 .13 1.54c.56.4 1.16.72 1.81.95.76.28 1.56.47 2.37.55v2.62c1.06 1.21 2.4.47 2.4-.58v-2.12c.3-.05.59-.11.89-.19.72-.2 1.39-.53 1.99-.98.58-.45 1.05-1.02 1.38-1.68.36-.75.53-1.57.51-2.41.03-.7-.14-1.4-.48-2.02-.31-.53-.72-.99-1.21-1.36"
              style={{
                fillRule: 'evenodd',
                fill: '#c5c5c5',
              }}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default SvgNoTienesRecarga;
