import * as React from 'react';
import { SVGProps } from 'react';
const SvgFormasPago = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <g data-name="Capa 1">
      <path
        d="M37.78 43.08c-11.47 2.87-17.23 4.31-20.66 2.67-3.41-1.63-4.6-6.36-6.96-15.81-2.36-9.44-3.54-14.18-1.3-17.22 2.26-3.05 8.02-4.49 19.49-7.36 11.47-2.87 17.23-4.31 20.66-2.67 3.41 1.63 4.6 6.36 6.96 15.81 2.36 9.44 3.54 14.18 1.3 17.22-2.26 3.05-8.02 4.49-19.49 7.36Z"
        style={{
          fill: '#8ab934',
        }}
      />
      <path
        d="M27.12 58.12c-12.74 0-19.14 0-22.29-2.6-3.14-2.59-3.14-7.85-3.14-18.33s0-15.74 3.14-18.33c3.16-2.6 9.55-2.6 22.29-2.6s19.14 0 22.29 2.6c3.14 2.59 3.14 7.85 3.14 18.33s0 15.74-3.14 18.33c-3.16 2.6-9.55 2.6-22.29 2.6Z"
        style={{
          fill: '#d0e3ae',
        }}
      />
      <path
        d="M57.02 18.23c-2.44-9.77-3.67-14.67-7.54-16.52-3.78-1.8-9.67-.33-21.4 2.6-11.72 2.93-17.61 4.4-20.1 7.77-.93 1.26-1.35 2.73-1.4 4.55-.96.36-1.77.82-2.45 1.38C.6 20.92.6 26.36.6 37.18s0 16.26 3.54 19.17c3.46 2.85 9.98 2.85 22.98 2.85s19.53 0 22.98-2.85c3.26-2.69 3.52-7.53 3.54-16.76 2.09-.92 3.55-1.93 4.51-3.23 2.55-3.45 1.33-8.36-1.12-18.13ZM5.51 19.68c2.85-2.35 9.12-2.35 21.6-2.35s18.75 0 21.6 2.35c1.55 1.28 2.23 3.46 2.52 6.95H3c.29-3.49.97-5.67 2.52-6.95Zm43.2 34.99c-2.85 2.35-9.12 2.35-21.6 2.35s-18.75 0-21.6-2.35c-2.75-2.26-2.75-7.35-2.75-17.5v-1.24h48.69v1.24c0 10.14 0 15.23-2.75 17.5Zm7.68-19.61c-.58.79-1.5 1.47-2.76 2.11 0-10.83 0-16.26-3.54-19.17-3.46-2.85-9.98-2.85-22.98-2.85-8.62 0-14.39 0-18.31.83.11-1.05.41-1.91.93-2.62 2.02-2.74 8.01-4.23 18.88-6.95 7.95-1.99 13.29-3.32 16.78-3.32 1.28 0 2.32.18 3.15.57 2.95 1.4 4.09 5.98 6.37 15.09 2.28 9.11 3.42 13.68 1.48 16.31Z"
        style={{
          fill: '#263645',
        }}
      />
      <rect
        x={31.3}
        y={43.07}
        width={13.34}
        height={8.06}
        rx={3.1}
        ry={3.1}
        style={{
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);
export default SvgFormasPago;
