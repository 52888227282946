import { Button } from 'react-bootstrap';
import { useTopUp } from '../../../context/TopupContext';
import { UtilService } from '../../../services';
import { TopUpMobileItem } from '../../../types/topupItems';
import { Formatter } from '../../../utilities';
import { EliminarItemListado, Operador, RecargarCelularInactive } from '../../svg';

import styles from './topUpCartItems.module.scss';

export type TopUpCartMobileItemProps = {
  index: number;
  mobileItem: TopUpMobileItem;
  allowToDelete?: boolean;
  isLast?: boolean;
};

export const TopUpCartMobileItem = ({
  index,
  mobileItem,
  allowToDelete = true,
  isLast,
}: TopUpCartMobileItemProps) => {
  const { removeMobileItem } = useTopUp();

  const getContactName = () => {
    return mobileItem.type === 'contact' ? mobileItem.contact?.contact_name + ' ' : '';
  };

  const getPhone = () => {
    const phone =
      mobileItem.type === 'contact' ? mobileItem.contact!.contact_number : mobileItem.phoneNumber;
    const country =
      mobileItem.type === 'contact'
        ? mobileItem.contact!.country_code
        : mobileItem.country?.count_cod;

    const phoneFormatted = UtilService.getFormattedPhoneNumber(phone || '', country || '');

    return `${phoneFormatted}, `;
  };

  return (
    <div className={isLast ? '' : 'mb-4'}>
      <div className="d-flex flex-column flex-lg-row">
        <div className="flex-fill">
          <div className="d-flex flex-row">
            <div className="me-3">
              <RecargarCelularInactive fill={`#8ab934`} width={40} />
            </div>
            <div className="d-flex flex-column">
              <div>
                <span className={styles.lineTitle}>{getContactName()}</span>
                <span>{getPhone()}</span>
                <span className="text-secondary">
                  {mobileItem.carrier.name} <Operador width={15} className="ms-1" />
                </span>
              </div>
              <div>
                <div className={styles.lineRecarga}>
                  Recarga {mobileItem.carrierRate.remote_amount}{' '}
                  {mobileItem.carrierRate.remote_currency}
                </div>
                <div className="text-secondary">
                  {Formatter.toCurrency(mobileItem.carrierRate.real_amount)}
                </div>
                <div className="m-0" dangerouslySetInnerHTML={{ __html: 'Recibe ' + mobileItem.carrierRate.description }}></div>                
              </div>
              {!!mobileItem.futurePromotionalDate && (
                <div>
                  <div className={styles.productsPromoDate}>                    
                    Su recarga se hará efectiva {mobileItem.futurePromotionalDate}                    
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {allowToDelete && (
          <>
            <div className="d-none d-lg-block">
              <Button
                size="sm"
                className="mt-2 mt-lg-0 text-decoration-none"
                variant={'link'}
                onClick={() => removeMobileItem(index)}>
                <EliminarItemListado width={15} />
              </Button>
            </div>
            <div className="d-block d-lg-none text-end mt-2">
              <Button
                size="sm"
                className="mt-2 mt-lg-0 text-decoration-none text-primary"
                variant={'link'}
                onClick={() => removeMobileItem(index)}>
                Eliminar
              </Button>
            </div>
          </>
        )}
      </div>
      {!isLast && <hr />}
    </div>
  );
};
