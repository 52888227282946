import * as React from 'react';
import { SVGProps } from 'react';
const SvgFilterAseo = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Capa 1" viewBox="0 0 23 23" {...props}>
    <g data-name="Grupo 16774">
      <g data-name="Grupo 16773" fill="#abadc4">
        <path
          data-name="Trazado 34120"
          d="M.9 14.96V9.67a2.756 2.756 0 0 1 2.68-2.73c2.04.08 4.08.02 6.12.02.83 0 .95.12.94.94a7.4 7.4 0 0 0 .94 4.09 6.394 6.394 0 0 0 1.86 1.91 1.554 1.554 0 0 1 .74 1.43c-.03 1.68 0 3.37-.01 5.06a2.593 2.593 0 0 1-2.56 2.6c-2.67-.06-5.35-.08-8.02 0a2.725 2.725 0 0 1-2.69-2.7c.02-1.78 0-3.56 0-5.33Z"
          opacity={0.4}
        />
        <path
          data-name="Trazado 34121"
          d="M21.79 7.23a23.611 23.611 0 0 0-1.73-3.58c-.58-1.02-1.29-1.96-1.94-2.93A1.274 1.274 0 0 0 17.06 0 1.212 1.212 0 0 0 16 .54a35.537 35.537 0 0 0-3.35 5.43 8.912 8.912 0 0 0-.81 2.64 6 6 0 0 0 .31 1.77 4.808 4.808 0 0 0 2.17 2.68 5.232 5.232 0 0 0 3.35.65A4.72 4.72 0 0 0 21 11.87a4.639 4.639 0 0 0 .79-4.64Zm-4.82 4.78c-.11.01-.19.02-.26.02a3.211 3.211 0 0 1-1.52-.48 3.108 3.108 0 0 1-1.5-2.54 1.305 1.305 0 0 0 0-.2c0-.18-.04-.71.53-.76.65 0 .68.55.7.78v.13a2.137 2.137 0 0 0 1.9 1.79h.1a.677.677 0 0 1 .72.63c.02.53-.48.59-.67.61Z"
          opacity={0.4}
        />
        <path
          data-name="Trazado 34122"
          d="M6.59 7.16H4.36c-.55 0-.76-.21-.77-.75a3.75 3.75 0 0 1 .05-1.11 1.438 1.438 0 0 1 1.28-1 .636.636 0 0 0 .63-.82 2.615 2.615 0 0 1 0-.28c-.02-.37.12-.81-.36-1a1.253 1.253 0 0 0-1.47.48 2.072 2.072 0 0 1-1.01.97c-.69.23-1.56-.42-1.39-1.13A3.91 3.91 0 0 1 2.89.25 1.174 1.174 0 0 1 3.6.08h5.33a.967.967 0 0 1 1.05 1.03 1.031 1.031 0 0 1-1.05 1.05h-.51a.646.646 0 0 0-.72.71v.19a1.259 1.259 0 0 0 1.02 1.33 1.47 1.47 0 0 1 1 1.26c.07 1.33-.1 1.51-1.42 1.51H6.58Z"
        />
        <path
          data-name="Trazado 34123"
          d="M7.02 19.05H.9v-4.14h6.12a1.127 1.127 0 0 1 1.12 1.12v1.89a1.11 1.11 0 0 1-.38.84 1.149 1.149 0 0 1-.74.28Z"
        />
      </g>
    </g>
  </svg>
);
export default SvgFilterAseo;
