import { toLower } from 'lodash';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import Select, { SingleValue } from 'react-select';
import {
  IconOptionContact,
  IconSingleValueContact,
  reactSelectStyles,
} from '../../../config/reactSelect';
import { ContactService } from '../../../services';
import { MegaContact } from '../../../types/contacts';
import { QueryTypes } from '../../../types/queryTypes';
import { CheckoutAddressPerson } from './checkoutAddressPerson';

type CheckoutAddressContactProps = {
  acceptedProvinces: number[];
  acceptedMunicipalities: number[];
};

const CheckoutAddressContactComponent = (props: CheckoutAddressContactProps, ref: any) => {
  const [contactList, setContactList] = useState<MegaContact[]>([]);
  const [contactHasError, setContactHasError] = useState<boolean>(false);
  const [selectedContact, setSelectedContact] = useState<MegaContact | null>(null);
  const formRef = useRef<any>();

  const contactQuery = useQuery(QueryTypes.GetContacts, ContactService.getContacts);

  useEffect(() => {
    if (contactQuery.status === 'success' && contactQuery.data.length) {
      let c = contactQuery.data.filter(
        (item) =>
          toLower(item.country_code) === 'cu' &&
          (!item.province_id || props.acceptedProvinces.includes(item.province_id))
      );

      if (props.acceptedMunicipalities.length) {
        c = c.filter(
          (cont) =>
            !cont.municipality_id || props.acceptedMunicipalities.includes(cont.municipality_id)
        );
      }

      setContactList(c);
    }
  }, [contactQuery.status]);

  useEffect(() => {
    if (!!selectedContact) {
      setContactHasError(false);
    }
  }, [selectedContact]);

  const onChangeSelectedContact = (sContact: SingleValue<MegaContact> | MegaContact) => {
    setSelectedContact(sContact as MegaContact);
  };

  const validateContact = () => {
    setContactHasError(!selectedContact);
  };

  useImperativeHandle(ref, () => ({
    isFormValid() {
      validateContact();
      return formRef?.current?.isFormValid();
    },
    getFormValues() {
      return {
        ...formRef?.current?.getFormValues(),
        contact: selectedContact,
      };
    },
  }));

  return (
    <>
      <div className="row">
        <div className="col-12 col-md-2 col-lg-3"></div>
        <div className="col-12 col-md-8 col-lg-6">
          <div className="w-100 mt-3">
            <p>Elija uno de sus contactos como el destinatario</p>
          </div>

          <div className="row mt-3">
            <div className="col-12">
              <div className="megaInput">
                <label htmlFor="contact">Mis Contactos</label>
                <Select
                  id="contact"
                  placeholder=""
                  value={selectedContact}
                  options={contactList}
                  isSearchable={false}
                  isClearable={false}
                  isLoading={contactQuery.isFetching}
                  className="w-100 mega-select"
                  isMulti={false}
                  styles={reactSelectStyles}
                  components={{
                    Option: IconOptionContact,
                    SingleValue: IconSingleValueContact,
                  }}
                  onChange={onChangeSelectedContact}
                />
                {!!contactHasError && (
                  <div className="text-danger">Escoge un contacto para continuar.</div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-12 col-md-2 col-lg-3"></div>
      </div>

      {!!selectedContact && (
        <div className="mt-5">
          <CheckoutAddressPerson
            editContact={selectedContact}
            acceptedProvinces={props.acceptedProvinces}
            acceptedMunicipalities={props.acceptedMunicipalities}
            ref={formRef}
          />
        </div>
      )}
    </>
  );
};

export const CheckoutAddressContact = React.forwardRef(CheckoutAddressContactComponent);
