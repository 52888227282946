import { useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import { SessionContext } from '../../../providers/session.provider';
import { MegaStoreProduct } from '../../../types/reports/storeProduct';
import { productImgUrl } from '../../../utilities';
import { Formatter } from '../../../utilities/Formatter';
import styles from './addToCart.module.scss';

export type AddToCartProps = {
  product: MegaStoreProduct;
  display: boolean;
  onCancel: () => void;
  onSave: () => void;
};

export const AddToCart = (props: AddToCartProps) => {
  const { isAuthenticated } = useContext(SessionContext);
  const navigate = useNavigate();
  const { getProductsCount, productsTotal } = useShoppingCart();

  const storeRoute = isAuthenticated()
    ? ROUTES.cuenta.store
    : '../../' + ROUTES.external.services.combos;

  const goToCart = () => {
    navigate(ROUTES.cuenta.shoppingCart);
  };

  const goToStore = () => {
    navigate(storeRoute);
  };

  return (
    <Modal
      show={props.display}
      onHide={props.onCancel}
      backdrop={'static'}
      centered
      backdropClassName="mega-double-backdrop"
      dialogClassName="mega-double-dialog">
      <Modal.Header closeButton className="mega-modal-title">
        <Modal.Title>Agregado al Carrito</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div className="container py-2">
          <div className="row">
            <div className="col-md-3">
              <img
                className={styles.productImg}
                src={productImgUrl(props.product.main_image.image_url)}
              />
            </div>
            <div className="col-md-9">
              <div className={`d-flex ${styles.textWrapper}`}>
                <span className="fw-bold">Subtotal del carrito</span>
                &nbsp;
                <span>
                  ({getProductsCount} artículo{getProductsCount > 1 ? 's' : ''}):
                </span>
                <span className={`${styles.productPrice}`}>
                  {Formatter.toCurrency(productsTotal)}
                </span>
              </div>
              <div className="d-flex mt-4">
                <Button variant="mega_danger_2" className="me-1" onClick={goToCart}>
                  Ver carrito
                </Button>
                <Button variant="mega_danger_3" className="ms-1" onClick={goToStore}>
                  Seguir Comprando
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
