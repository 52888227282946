export class ReportsConfig {
  static defaultPageSize: number = 10;
  static rowsPerPageOptions = [10, 25, 50, 100];

  static get defaultStartDate(): Date {
    let date = new Date();
    date.setDate(date.getDate() - 7);
    return date;
  }

  static get defaultEndDate(): Date {
    return new Date();
  }
}
