import * as React from 'react';
import { SVGProps } from 'react';
const SvgPagoSeguroVerde = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 39 39" {...props}>
    <g data-name="Capa 1">
      <path
        d="M34.84 8.19c-.33-2.06-1.48-3.23-3.5-3.63-2.83-.56-5.71-.58-8.56-.75-1.06-.07-2.13-.1-3.19-.1h-.2c-1.06 0-2.13.04-3.19.1-2.85.18-5.73.2-8.56.75-2.02.4-3.17 1.57-3.5 3.63-.58 3.62-.72 7.29-.33 10.9.79 7.41 4.93 12.43 11.76 15.35 1.3.55 2.57.84 3.82.85s.06 0 .1 0h.1c1.25-.02 2.52-.31 3.82-.86 6.83-2.91 10.97-7.94 11.76-15.35.38-3.61.25-7.28-.33-10.9Z"
        style={{
          fill: '#8ab934',
        }}
      />
      <path
        d="M19.41 36h-.71v-.04c-1.12-.1-2.25-.39-3.39-.88-7.29-3.11-11.39-8.46-12.18-15.92-.38-3.57-.27-7.3.34-11.08.37-2.34 1.74-3.76 4.06-4.21 2.23-.44 4.49-.55 6.68-.66.66-.03 1.31-.06 1.97-.11.82-.05 1.65-.08 2.53-.1s.8 0 .8 0h.8c.88.01 1.71.05 2.53.1.65.04 1.31.07 1.97.11 2.19.11 4.46.22 6.68.66 2.32.46 3.69 1.88 4.06 4.21.6 3.78.72 7.51.34 11.08-.8 7.46-4.89 12.81-12.18 15.92-1.14.49-2.27.78-3.39.88v.03H19.42Zm.07-31.59h-.07c-1.11 0-2.14.04-3.15.1-.66.04-1.32.07-1.99.11-2.14.11-4.35.22-6.48.64-1.75.35-2.66 1.29-2.94 3.05-.58 3.66-.69 7.26-.33 10.71.74 6.91 4.55 11.88 11.33 14.77 1.21.51 2.4.78 3.55.8h.17c1.15-.02 2.35-.28 3.55-.8 6.78-2.89 10.59-7.86 11.33-14.77.37-3.45.26-7.05-.33-10.71-.28-1.76-1.19-2.7-2.94-3.05-2.13-.42-4.34-.53-6.48-.64-.66-.03-1.32-.07-1.99-.11-1.01-.06-2.04-.1-3.15-.1h-.11Z"
        style={{
          fill: '#263645',
        }}
      />
      <path
        data-name="Uni\xF3n 5"
        d="M12.16 19.57q1.35-2.34 6.04.37h.02c.41-.72.9-1.55 1.46-2.52q4.11-7.11 6.44-5.76t-1.77 8.46c-.93 1.61-1.65 2.86-2.23 3.81-.14.3-.34.64-.58 1.06q-1.35 2.34-6.04-.37-4.69-2.71-3.34-5.04Z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);
export default SvgPagoSeguroVerde;
