import { Modal } from 'react-bootstrap';
import { useGlobalModalContext } from '../../../providers/globalModal.provider';
import { CaretFlecha, RecargaAhora, RecargaPromocion } from '../../svg';

import styles from './futureTopupModal.module.scss';

export const FutureTopupModal = () => {
  const { hideModal, currentModalProps } = useGlobalModalContext();

  const closeModal = (isFuture?: boolean) => {
    hideModal();
    !!currentModalProps?.onClose &&
      currentModalProps.onClose({
        isFuture,
      });
  };

  return (
    <Modal
      dialogClassName="mega-modal-success"
      size="sm"
      show={true}
      onHide={closeModal}
      backdrop="static"
      centered>
      <Modal.Header className="mega-modal-title" closeButton>
        <h6 className="mega-fs-mid">¿Cuándo quiere que se efectúe su recarga?</h6>
      </Modal.Header>
      <Modal.Body>
        <div className="w-100 d-flex flex-column">
          <div
            className={`d-flex flex-row align-items-center mb-3 ${styles.wrapperPromocion}`}
            onClick={() => closeModal(true)}>
            <div>
              <RecargaPromocion width={40} className="me-2" />
            </div>
            <div className="flex-fill d-flex flex-column">
              <h6 className="mega-fs-mid m-0">Recarga en Promoción</h6>
              <p className="m-0 p-0 mega-fs-small">
                Su recarga se hará efectiva el día {currentModalProps?.state.futureDate}
              </p>
            </div>
            <div>
              <CaretFlecha width={15} />
            </div>
          </div>

          <div
            className={`d-flex flex-row align-items-center mb-3 ${styles.wrapperNow}`}
            onClick={() => closeModal(false)}>
            <div>
              <RecargaAhora width={40} className="me-2" />
            </div>
            <div className="flex-fill d-flex flex-column">
              <h6 className="mega-fs-mid m-0">Recargar Ahora</h6>
              <p className="m-0 p-0 mega-fs-small">Su recarga se hará efectiva de inmediato</p>
            </div>
            <div>
              <CaretFlecha width={15} />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
