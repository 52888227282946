import { StoreService } from '../../services/store';
import { MegaStoreProduct, MegaStoreProductDelivery } from '../../types/reports/storeProduct';

export type ShippingInformationParams = {
  firstName?: string;
  lastName?: string;
  secondLastName?: string;
  email?: string;
  address?: string;
  city?: string;
  phoneNumber?: string;
  province?: string;
  provinceId?: number;
  municipality?: string;
  municipalityId?: number;
  deliveryFee?: number;
  delivery?: MegaStoreProductDelivery;
};

export const emptyShippingInformation = {
  firstName: '',
  lastName: '',
  secondLastName: '',
  email: '',
  address: '',
  city: '',
  phoneNumber: '',
  municipality: '',
  deliveryFee: 0,
};

export const getDelivery = ({
  provinceId,
  municipalityId,
  products,
}: {
  provinceId: string;
  municipalityId: string;
  products: MegaStoreProduct[];
}) => {
  if (products.length) {
    const delivery = products[0].deliveries.find((delivery) => {
      const acceptedProvinces = delivery.accept_provinces.split(',').filter((v) => v !== '');
      const excludedProvinces = delivery.exclude_provinces.split(',').filter((v) => v !== '');
      const acceptedMunicipalities = delivery.accept_municipes.split(',').filter((v) => v !== '');
      const excludedMunicipalities = delivery.exclude_municipes.split(',').filter((v) => v !== '');

      return (
        (acceptedProvinces.includes(provinceId) ||
          (acceptedProvinces.length === 0 && !excludedProvinces.includes(provinceId))) &&
        (acceptedMunicipalities.includes(municipalityId) ||
          (acceptedMunicipalities.length === 0 && !excludedMunicipalities.includes(municipalityId)))
      );
    });

    return delivery;
  }

  return undefined;
};

export const getProvinceId = async (provinceName: string) => {
  const provinces = await StoreService.getProvinces();

  const currentProvince = provinces.find((province) => province.name === provinceName);

  return currentProvince?.id;
};
