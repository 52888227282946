import { Button, Modal } from 'react-bootstrap';
import Lottie from 'react-lottie';
import { useNavigate } from 'react-router-dom';
import completeSuccessJson from '../../../assets/animations/complete-success.json';
import { ROUTES } from '../../../config/routes';
import { useGlobalModalContext } from '../../../providers/globalModal.provider';
import { PagoSeguroVerde } from '../../svg';
import styles from './successPaymentModal.module.scss';

export const SuccessPaymentModal = () => {
  const { hideModal, currentModalProps } = useGlobalModalContext();
  const navigate = useNavigate();
  const closeModal = (source: 'primary' | 'secondary') => {
    hideModal();
    !!currentModalProps?.onClose && currentModalProps.onClose(source);
  };

  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: completeSuccessJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Modal
      size="lg"
      show={true}
      backdrop="static"
      backdropClassName="mega-double-backdrop"
      dialogClassName="mega-double-dialog mega-smaller-lg-dialog"
      centered>
      <Modal.Body>
        <div className={`container`}>
          <div className={`row`}>
            <div className={`col-12`}>
              <div className={`d-flex flex-column justify-content-center align-items-center`}>
                <Lottie options={defaultOptions1} height={100} width={100} />

                <h3
                  className="text-center"
                  dangerouslySetInnerHTML={{ __html: currentModalProps?.title || '' }}></h3>
                <h6
                  className="mt-4 text-center"
                  dangerouslySetInnerHTML={{ __html: currentModalProps?.description || '' }}></h6>

                <Button
                  variant="secondary"
                  className="mt-4 px-4 py-2"
                  onClick={() => closeModal('primary')}>
                  {currentModalProps?.primaryButtonTitle || 'Seguir recargando'}
                </Button>

                <Button
                  variant="link"
                  className="text-secondary text-decoration-none mt-4"
                  onClick={() => {
                    navigate(ROUTES.cuenta.reportes, { state: currentModalProps?.state });
                    closeModal('secondary');
                  }}>
                  {currentModalProps?.secondaryButtonTitle || 'Ver reporte de la recarga'}
                </Button>

                <div className={`${styles.wrapperPaySafe} mt-10 mb-3`}>
                  <div className="d-flex flex-row">
                    <div className="me-3 d-flex flex-column justify-content-center">
                      <PagoSeguroVerde width={45} />
                    </div>
                    <div className="d-flex flex-column">
                      <h6 className="m-0">Pago 100% seguro</h6>
                      <p className="m-0">Su información de pago está segura con nosotros</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};
