import * as React from 'react';
import { SVGProps } from 'react';
const SvgRecargaPromocion = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={40} height={40} {...props}>
    <g data-name="recarga promocion">
      <g data-name="Grupo 16703">
        <g data-name="Grupo 16702">
          <g data-name="Grupo 16700" opacity={0.4}>
            <path
              data-name="Trazado 34069"
              d="m38.137 13.439-.585-.718.146-.917a5.2 5.2 0 0 0-3.283-5.676l-.864-.332-.332-.864a5.2 5.2 0 0 0-5.676-3.281l-.917.146-.718-.585a5.185 5.185 0 0 0-6.553 0l-.718.585-.918-.146a5.2 5.2 0 0 0-5.676 3.283l-.332.864-.864.332a5.2 5.2 0 0 0-3.283 5.676l.146.917-.585.718a5.185 5.185 0 0 0 0 6.553l.585.718-.146.917a5.2 5.2 0 0 0 3.283 5.676l.864.332.332.864a5.2 5.2 0 0 0 5.676 3.283l.917-.146.718.585a5.185 5.185 0 0 0 6.553 0l.718-.585.917.146a5.2 5.2 0 0 0 5.676-3.283l.332-.864.864-.332a5.2 5.2 0 0 0 3.283-5.676l-.146-.917.585-.718a5.185 5.185 0 0 0 .001-6.555Zm-23.409 6.554q-4.2 4.107-5.45 2.818t2.938-5.384q4.187-4.094 5.45-2.818t-2.938 5.384Z"
              fill="#263645"
              fillRule="evenodd"
            />
          </g>
          <g data-name="Grupo 16701" fill="#263645" fillRule="evenodd">
            <path
              data-name="Trazado 34070"
              d="m18.69 30.095 3.416-.784a7.6 7.6 0 0 1-13.492 6.912 7.461 7.461 0 0 1-1.01-3.576l3.549-.811-.16.625a4.1 4.1 0 0 0 6.261 3.523 4.121 4.121 0 0 0 1.662-5.5l-.213-.359Z"
            />
            <path
              data-name="Trazado 34071"
              d="M30.228 21.508a25.192 25.192 0 0 0-2.791-6.66 25.5 25.5 0 0 0-4.4-5.716 10.2 10.2 0 0 0-2.472-1.755 4.223 4.223 0 0 0-2.7-.425 3.287 3.287 0 0 0-1.7 1.09l.824.758-.825-.756L2.087 23.355a5.634 5.634 0 0 0 5.41 9.3l20.285-4.652a3.31 3.31 0 0 0 1.781-.93 4.31 4.31 0 0 0 .97-2.565 10.085 10.085 0 0 0-.306-3.017Zm-15.485-1.502c-2.991 2.938-4.48 3.669-5.45 2.818-1.4-1.223.984-3.589 2.938-5.384 4.32-3.961 4.2-4.107 5.45-2.818q1.262 1.29-2.938 5.384Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default SvgRecargaPromocion;
