import * as React from 'react';
import { SVGProps } from 'react';
const SvgYoutube = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" {...props}>
    <defs>
      <clipPath id="youtube_svg__a">
        <path
          style={{
            fill: 'none',
          }}
          d="M1.26 3h23.48v20H1.26z"
        />
      </clipPath>
    </defs>
    <g data-name="Capa 1">
      <g
        data-name="Grupo 99"
        style={{
          clipPath: 'url(#youtube_svg__a)',
        }}>
        <path
          data-name="Trazado 83"
          d="M24.55 8.28c-.23-2.75-2.78-4.93-6.01-5.12C16.74 3.05 14.89 3 13 3s-3.74.06-5.54.16c-3.23.19-5.78 2.37-6.01 5.12-.13 1.53-.19 3.11-.19 4.72s.07 3.19.19 4.72c.23 2.75 2.78 4.93 6.01 5.12 1.8.11 3.65.16 5.54.16s3.74-.06 5.54-.16c3.23-.19 5.78-2.37 6.01-5.12.12-1.53.19-3.11.19-4.72s-.07-3.19-.19-4.72m-7.14 5.54-6.01 3.47a.94.94 0 0 1-1.29-.35.977.977 0 0 1-.13-.47V9.53c0-.52.42-.95.95-.95.17 0 .33.04.47.13l6.01 3.47c.45.26.61.84.35 1.29-.08.14-.2.26-.35.35"
          style={{
            fill: '#fff',
          }}
        />
      </g>
    </g>
  </svg>
);
export default SvgYoutube;
