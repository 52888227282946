export class FormHelpers {
  public static formLabel(label: string, htmlFor: string) {
    return <label htmlFor={htmlFor}>{label}</label>;
  }

  public static formError(field: string, errors: { [key: string]: any }) {
    if (errors[field]) {
      return <div className="text-danger">{errors[field].message}</div>;
    }
  }

  public static errorClass(field: string, errors: { [key: string]: any }) {
    return errors[field] ? 'border border-danger' : '';
  }

  public static formTextField(
    inputType: 'text' | 'number' | 'date' | 'password' | 'email',
    field: string,
    errors: { [key: string]: any },
    register: any,
    label?: string,
    options?: { hideError?: boolean; hideLabel?: boolean; disabled?: boolean }
  ) {
    return (
      <>
        <div className="megaInput">
          {!options?.hideLabel && FormHelpers.formLabel(label || '', field)}
          <input
            type={inputType}
            className={`form-control mega ${FormHelpers.errorClass(field, errors)}`}
            id={field}
            {...register(field)}
            disabled={options?.disabled}
          />
        </div>
        {!options?.hideError && FormHelpers.formError(field, errors)}
      </>
    );
  }
}
