import { HiLocationMarker, HiMail, HiPhone } from 'react-icons/hi';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import { Formatter } from '../../../utilities';
import { SummaryCard } from './summaryCard';

export const InvoicingAddressSummaryCard = () => {
  const {
    newCardInformation,
    newPaypalInformation,
    currentPaymentMethod,
    currentPaymentMethodType,
  } = useShoppingCart();

  let fullName = '';
  let address = '';
  let phone = '';
  let email = '';

  switch (currentPaymentMethodType) {
    case 'new-card':
      fullName = `${newCardInformation?.first_name} ${newCardInformation?.last_name}`.trim();
      address = Formatter.joinPurgeEmpty([
        newCardInformation?.address1,
        newCardInformation?.city,
        newCardInformation?.state,
      ]);
      break;
    case 'new-paypal':
      fullName =
        `${newPaypalInformation?.details.firstName} ${newPaypalInformation?.details.lastName}`.trim();
      address = newPaypalInformation?.details.countryCode ?? '';
      email = newPaypalInformation?.details.email ?? '';
      break;
    case 'card':
    case 'paypal':
      fullName = `${currentPaymentMethod?.first_name} ${currentPaymentMethod?.last_name}`.trim();
      address = Formatter.joinPurgeEmpty([
        currentPaymentMethod?.address1,
        currentPaymentMethod?.city,
        currentPaymentMethod?.state,
      ]);
      phone = currentPaymentMethod?.phone ?? '';
      email = currentPaymentMethod?.email ?? '';
      break;
    default:
      break;
  }

  return (
    <SummaryCard header="Dirección de facturación">
      <div>{fullName}</div>
      {address && (
        <div>
          <HiLocationMarker className="me-1" size={20} />
          {address}
        </div>
      )}
      {phone && (
        <div>
          <HiPhone className="me-1" size={20} />
          {phone}
        </div>
      )}
      <div>
        <HiMail className="me-1" size={20} />
        {email}
      </div>
    </SummaryCard>
  );
};
