import * as React from 'react';
import { SVGProps } from 'react';
const SvgFilterMiscelaneas = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Grupo 346" viewBox="0 0 23 23" {...props}>
    <defs>
      <clipPath id="FilterMiscelaneas_svg__a">
        <path data-name="Rect\xE1ngulo 312" fill="none" d="M0 0h23v23H0z" />
      </clipPath>
      <clipPath id="FilterMiscelaneas_svg__b">
        <path data-name="Rect\xE1ngulo 310" fill="none" d="M0 0h12.913v12.913H0z" />
      </clipPath>
    </defs>
    <g data-name="Grupo 345" clipPath="url(#FilterMiscelaneas_svg__a)">
      <g data-name="Grupo 344">
        <g data-name="Grupo 343" clipPath="url(#FilterMiscelaneas_svg__a)">
          <g data-name="Grupo 342" opacity={0.4}>
            <g data-name="Grupo 341">
              <g
                data-name="Grupo 340"
                clipPath="url(#FilterMiscelaneas_svg__b)"
                transform="translate(5.009 5.021)">
                <path
                  data-name="Trazado 272"
                  d="M2.241 2.242c2.988-2.988 5.442-2.988 8.43 0s2.988 5.442 0 8.43-5.442 2.988-8.43 0-2.988-5.442 0-8.43"
                  fill="#abadc4"
                />
              </g>
            </g>
          </g>
          <path
            data-name="Trazado 273"
            d="M5.378 13.138a26.465 26.465 0 0 0-3.6 1.23c-1.348.517-1.607 1.343-.9 2.617A11.426 11.426 0 0 0 2.7 19.3a13.656 13.656 0 0 0 3.648 2.918 1.506 1.506 0 0 0 2.366-.867 27.46 27.46 0 0 0 1.17-3.751 8.489 8.489 0 0 1-2.634-1.907 8.647 8.647 0 0 1-1.872-2.555"
            fill="#abadc4"
          />
          <path
            data-name="Trazado 274"
            d="M22.118 6.014A11.408 11.408 0 0 0 20.3 3.7 13.661 13.661 0 0 0 16.652.787a1.506 1.506 0 0 0-2.366.868 27.741 27.741 0 0 0-1.167 3.733 8.655 8.655 0 0 1 2.562 1.874 8.528 8.528 0 0 1 1.9 2.611 26.29 26.29 0 0 0 3.641-1.241c1.348-.517 1.607-1.342.9-2.618"
            fill="#abadc4"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgFilterMiscelaneas;
