import { NavLink } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';
import { MegaStoreProduct } from '../../../types/reports/storeProduct';
import { productImgUrl } from '../../../utilities/productImgUrl';
import styles from './storeProductCard.module.scss';

interface StoreProductCardProps {
  product: MegaStoreProduct;
}

export const StoreProductCard = ({ product }: StoreProductCardProps) => {
  const outOfStock = product.in_stock === 0;

  return (
    <>
      <div className={`${styles.slide} ${outOfStock ? styles.outOfStock : ''}`}>
        <NavLink
          onClick={(e) => {
            // do not navigate if item is out of stock
            outOfStock && e.preventDefault();
          }}
          to={`${ROUTES.cuenta.productStore.replace(':id', product.id.toString())}`}
          className={styles.link}>
          <div className={styles.imgWrapper}>
            <img
              className={`${styles.product_image}`}
              src={productImgUrl(product.main_image.image_url)}
            />

            {outOfStock && <div className={styles.outOfStockBanner}>Agotado</div>}
          </div>

          <h6 className={`${styles.product_name} mega-fs-mid`}>{product.name}</h6>

          <div className={`${styles.product_description}`}>{product.description}</div>

          <h6 className={`${styles.product_price} mega-fs-mid`}>${product.price}</h6>

          {/* <div>
            {product.deliveries.map((delivery, index) => (
              <div
                className={`${styles.product_info_detail} fw-bold mega-fs-small`}
                key={`product-card--detail-${product.id}-${index}`}>
                <span className="mega-fs-small">{delivery.name}: </span>
                {delivery.duration}
              </div>
            ))}
          </div> */}
        </NavLink>
      </div>
    </>
  );
};
