import * as React from 'react';
import { SVGProps } from 'react';
const SvgMarcadoDirecto1 = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="marcado_directo_1_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    {...props}>
    <defs>
      <style>{'.marcado_directo_1_svg__cls-1{fill:#8f979f}'}</style>
    </defs>
    <g id="marcado_directo_1_svg__Capa_1-2" data-name="Capa 1">
      <path
        className="marcado_directo_1_svg__cls-1"
        d="M12.96 22.09c-1.08-.09-2.33-.36-3.44-1.05-3.29-2.03-5.99-4.65-7.9-8.03C-.27 9.68 1.1 4.79 4.43 2.87c.21-.12.46-.2.68-.26 1.39-.34 2.88 1.73 3.48 3.16.41.97.36 1.62-.34 2.37-1.84 1.96-1.81 2.03-.15 4.23 1 1.32 2.24 2.34 3.64 3.2.85.52 1.49.49 2.19-.28.63-.7 1.27-1.84 2.38-1.39 1.39.56 2.95 1.43 3.66 2.62.34.57.37 1.3-.07 1.82-1.77 2.08-3.82 3.72-6.95 3.76Z"
      />
      <g
        style={{
          opacity: 0.4,
        }}>
        <path
          className="marcado_directo_1_svg__cls-1"
          d="M19.49 3.79C17.51 1.81 15.23.91 13.1.65c-.52-.06-.99.29-1.13.78l-.23.83c-.18.67.3 1.33.96 1.44 1.57.27 3.27.92 4.61 2.25s1.99 3.04 2.25 4.61c.11.67.77 1.15 1.44.96l.83-.23c.5-.14.85-.6.78-1.13-.26-2.13-1.16-4.41-3.14-6.39Z"
        />
        <path
          className="marcado_directo_1_svg__cls-1"
          d="M16.09 7.2c-1.22-1.22-2.41-1.73-3.65-1.9-.55-.08-1.02.32-1.13.84l-.15.74c-.12.58.32 1.09.85 1.22.67.16 1.44.49 2.07 1.11s.96 1.39 1.11 2.07c.13.54.64.97 1.22.85l.74-.15c.52-.1.92-.58.84-1.13-.17-1.24-.68-2.43-1.9-3.65Z"
        />
      </g>
    </g>
  </svg>
);
export default SvgMarcadoDirecto1;
