import * as React from 'react';
import { SVGProps } from 'react';
const SvgReportesPage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="reportes_page_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 66"
    {...props}>
    <defs>
      <style>
        {
          '.reportes_page_svg__cls-1{fill:#fff}.reportes_page_svg__cls-3{fill:#263645}.reportes_page_svg__cls-4{fill:#d0e3ae}'
        }
      </style>
    </defs>
    <g id="reportes_page_svg__Capa_1-2" data-name="Capa 1">
      <path
        className="reportes_page_svg__cls-4"
        d="M2.24 54.18c0 2.35.12 3.97.28 5.1.34 2.38 2.99 3.74 5.89 3.81 1.14.03 2.65.05 4.62.05s3.48-.02 4.62-.05c2.9-.07 5.55-1.43 5.89-3.81.16-1.13.28-2.75.28-5.1s-.12-3.97-.28-5.1c-.34-2.38-2.99-3.74-5.89-3.81-1.14-.03-2.65-.05-4.62-.05s-3.48.02-4.62.05c-2.9.07-5.55 1.43-5.89 3.81-.16 1.13-.28 2.75-.28 5.1Z"
      />
      <path
        className="reportes_page_svg__cls-3"
        d="M13.02 64.33c-1.77 0-3.33-.02-4.65-.05-3.82-.09-6.65-2.04-7.04-4.84-.19-1.37-.29-3.14-.29-5.27s.1-3.89.29-5.27c.39-2.8 3.22-4.74 7.04-4.84 1.34-.03 2.91-.05 4.65-.05s3.31.02 4.65.05c3.82.09 6.65 2.04 7.04 4.84.19 1.37.29 3.14.29 5.27s-.1 3.89-.29 5.27c-.39 2.8-3.23 4.75-7.04 4.84-1.32.03-2.88.05-4.65.05Zm0-17.91c-1.73 0-3.27.02-4.59.05-2.1.05-4.47.94-4.73 2.78-.18 1.26-.27 2.92-.27 4.93s.09 3.67.27 4.93c.26 1.84 2.64 2.73 4.73 2.78 1.3.03 2.84.05 4.59.05s3.29-.02 4.59-.05c2.1-.05 4.47-.94 4.73-2.78.18-1.26.27-2.92.27-4.93s-.09-3.67-.27-4.93c-.26-1.84-2.64-2.73-4.73-2.78-1.32-.03-2.87-.05-4.59-.05Z"
      />
      <path
        className="reportes_page_svg__cls-4"
        d="M39.04 45.82c0 6.79.24 10.64.49 12.81.27 2.4 3 4.27 6.87 4.42 1.23.05 2.78.08 4.7.08s3.47-.03 4.7-.08c3.87-.15 6.6-2.03 6.87-4.42.24-2.16.49-6.02.49-12.81s-.24-10.64-.49-12.81c-.27-2.4-3-4.27-6.87-4.42-1.23-.05-2.78-.08-4.7-.08s-3.47.03-4.7.08c-3.87.15-6.6 2.03-6.87 4.42-.24 2.16-.49 6.02-.49 12.81Z"
      />
      <path
        className="reportes_page_svg__cls-3"
        d="M51.09 64.33c-1.75 0-3.35-.03-4.75-.08-4.37-.17-7.66-2.43-8.01-5.49-.33-2.92-.5-7.27-.5-12.94s.17-10.02.5-12.94c.35-3.06 3.64-5.31 8.01-5.49 1.39-.05 2.99-.08 4.75-.08s3.36.03 4.75.08c4.37.17 7.66 2.43 8.01 5.49.33 2.92.5 7.27.5 12.94s-.17 10.03-.5 12.94c-.35 3.06-3.64 5.31-8.01 5.49-1.4.05-2.99.08-4.75.08Zm0-34.63c-1.73 0-3.29.03-4.65.08-3.11.12-5.52 1.54-5.73 3.36-.22 1.94-.48 5.72-.48 12.67s.26 10.73.48 12.67c.21 1.83 2.62 3.24 5.73 3.36 1.37.05 2.93.08 4.65.08s3.29-.03 4.65-.08c3.11-.12 5.52-1.54 5.73-3.36.22-1.94.48-5.72.48-12.67s-.26-10.73-.48-12.67c-.21-1.83-2.62-3.24-5.73-3.36a127.9 127.9 0 0 0-4.65-.08Z"
      />
      <path
        d="M20.64 50.59c0 4.07.16 6.64.35 8.26.29 2.45 2.89 4.11 6.18 4.22 1.1.03 2.5.06 4.26.06s3.16-.02 4.26-.06c3.29-.1 5.89-1.77 6.18-4.22.19-1.62.35-4.19.35-8.26s-.16-6.64-.35-8.26c-.29-2.45-2.89-4.11-6.18-4.22-1.1-.03-2.5-.06-4.26-.06s-3.16.02-4.26.06c-3.29.1-5.89 1.77-6.18 4.22-.19 1.62-.35 4.19-.35 8.26Z"
        style={{
          fill: '#8ab934',
        }}
      />
      <path
        className="reportes_page_svg__cls-3"
        d="M31.42 64.33c-1.61 0-3.06-.02-4.3-.06-3.96-.13-6.98-2.29-7.33-5.27-.24-2.05-.36-4.87-.36-8.4s.12-6.35.36-8.4c.35-2.98 3.36-5.15 7.33-5.27 1.26-.04 2.7-.06 4.3-.06s3.04.02 4.3.06c3.97.13 6.98 2.29 7.33 5.28.24 2.04.36 4.87.36 8.4s-.12 6.35-.36 8.4c-.35 2.98-3.36 5.15-7.33 5.27-1.24.04-2.69.06-4.3.06Zm0-25.07c-1.57 0-2.99.02-4.22.06-2.75.09-4.82 1.39-5.03 3.16-.23 1.95-.34 4.68-.34 8.12s.12 6.17.34 8.12c.21 1.78 2.27 3.08 5.03 3.16 1.21.04 2.63.06 4.22.06s3.01-.02 4.22-.06c2.75-.09 4.82-1.39 5.03-3.16.23-1.95.34-4.69.34-8.12s-.12-6.17-.34-8.12c-.21-1.78-2.27-3.08-5.03-3.16-1.23-.04-2.65-.06-4.22-.06ZM57.29.99a44.98 44.98 0 0 0-10.55.85c-2.71.56-3.45 3.82-1.65 5.61l2.43 2.43c-.77.7-1.77 1.62-2.9 2.69-2.62 2.5-5.99 5.88-8.89 9.31-.04.04-.1.08-.21.08-.05 0-.08-.01-.1-.02-.01 0-.02-.01-.03-.03-1.23-1.72-2.85-3.92-4.58-6.05-1.67-2.07-4.66-2.6-6.94-1.05-3.26 2.21-10.02 7.3-17.59 15.95-.46.53-.92 1.06-1.39 1.62a2.7 2.7 0 0 0 .33 3.81 2.7 2.7 0 0 0 3.81-.33c.44-.53.88-1.04 1.32-1.54 7.06-8.07 13.34-12.83 16.36-14.9 1.59 1.97 3.1 4.02 4.26 5.65 2.16 3.03 6.58 3.05 8.89.31 2.71-3.22 5.92-6.44 8.48-8.88 1.19-1.13 2.23-2.09 3-2.78l2.59 2.59c1.8 1.8 5.06 1.06 5.61-1.65.94-4.6.93-8.63.85-10.55A3.247 3.247 0 0 0 57.27.99Z"
      />
      <ellipse className="reportes_page_svg__cls-1" cx={53.49} cy={43.62} rx={2.97} ry={8.99} />
      <ellipse className="reportes_page_svg__cls-1" cx={33.18} cy={49.89} rx={2.97} ry={5.86} />
    </g>
  </svg>
);
export default SvgReportesPage;
