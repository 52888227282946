import * as React from 'react';
import { SVGProps } from 'react';
const SvgSalir = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="salir_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    {...props}>
    <defs>
      <clipPath id="salir_svg__clippath">
        <path className="salir_svg__cls-7" d="M0 0h23v23H0z" />
      </clipPath>
      <clipPath id="salir_svg__clippath-1">
        <path className="salir_svg__cls-7" d="M0 0h23v23H0z" />
      </clipPath>
      <clipPath id="salir_svg__clippath-2">
        <path className="salir_svg__cls-7" d="M0 0h23v23H0z" />
      </clipPath>
      <clipPath id="salir_svg__clippath-3">
        <path className="salir_svg__cls-7" d="M.5 1.15h15.75v20.7H.5z" />
      </clipPath>
      <style>{'.salir_svg__cls-1{fill:#abadc4}.salir_svg__cls-7{fill:none}'}</style>
    </defs>
    <g
      style={{
        clipPath: 'url(#salir_svg__clippath)',
      }}
      id="salir_svg__Capa_1-2"
      data-name="Capa 1">
      <g id="salir_svg__Grupo_5637" data-name="Grupo 5637">
        <g
          style={{
            clipPath: 'url(#salir_svg__clippath-1)',
          }}
          id="salir_svg__Grupo_5636"
          data-name="Grupo 5636">
          <g id="salir_svg__Grupo_5635" data-name="Grupo 5635">
            <g
              id="salir_svg__Grupo_5634"
              data-name="Grupo 5634"
              style={{
                opacity: 0.4,
              }}>
              <g
                style={{
                  clipPath: 'url(#salir_svg__clippath-3)',
                }}
                id="salir_svg__Grupo_5633"
                data-name="Grupo 5633">
                <g id="salir_svg__Grupo_5632" data-name="Grupo 5632">
                  <path
                    id="salir_svg__Trazado_10947"
                    data-name="Trazado 10947"
                    className="salir_svg__cls-1"
                    d="M8.38 1.15c3.85 0 5.88.54 6.91 2.7.75 1.56.97 3.98.97 7.65 0 4.66-.35 7.3-1.69 8.74-1.17 1.25-3.09 1.6-6.18 1.6s-4.82-.32-6.01-1.43C.89 19.01.51 16.35.51 11.49c0-3.8.23-6.25 1.05-7.81 1.05-2.02 3.08-2.54 6.83-2.54"
                  />
                </g>
              </g>
            </g>
            <path
              id="salir_svg__Trazado_10948"
              data-name="Trazado 10948"
              className="salir_svg__cls-1"
              d="M22.22 10.39c.38.45.38 1.11.01 1.57a26.814 26.814 0 0 1-4.87 4.65c-.5.37-1.2.28-1.58-.22-.13-.17-.21-.37-.22-.59-.08-.61-.15-1.36-.21-2.22-3.7-.1-6.64-.35-7.51-.46a.964.964 0 0 1-.74-.49c-.25-.49-.39-1.02-.4-1.57 0-.56.13-1.11.39-1.6.14-.26.4-.43.7-.47 2.5-.24 5.01-.36 7.53-.35.05-.85.11-1.59.18-2.2.04-.61.56-1.07 1.17-1.02.23.02.44.1.62.24 1.83 1.36 3.49 2.95 4.93 4.72"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default SvgSalir;
