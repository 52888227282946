export const translations: {
  referralCategories: { [key: string]: string };
  remittanceTypes: { [key: string]: string };
} = {
  referralCategories: {
    DEDUCT_FUNDS: 'Gastado al realizar pago',
    DEDUCT_TOPUP: 'Gastado en recarga',
    FUNDS: 'Ganado por pago',
    REFUND_FUNDS: 'Retorno de pago',
    REFUND_TOPUP: 'Retorno de recarga',
    TOPUP: 'Ganado por recarga',
    WITHDRAW_ZELLE: 'Transferencia a Zelle',
  },
  remittanceTypes: {
    cash: 'Entrega en efectivo en domicilio',
    mlc: 'Transferencia a tarjeta MLC',
  },
};
