import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import { MegaError } from '../../../components/account';
import { editProfileSchema } from '../../../components/account/editProfileForm/editProfileFormSchema';
import { FlechaIzq, Ojo, OjoTachado } from '../../../components/svg';
import {
  IconOptionAuthorizedCountry,
  IconSingleValueAuthorizedCountry,
  reactSelectStyles,
} from '../../../config/reactSelect';
import { ROUTES } from '../../../config/routes';
import { AccountServices, CountryServices, UtilService } from '../../../services';
import { AuthorizedCountry } from '../../../types/country';
import { QueryTypes } from '../../../types/queryTypes';

import PasswordStrengthBar from 'react-password-strength-bar';
import { MODAL_TYPES, useGlobalModalContext } from '../../../providers/globalModal.provider';
import { SessionContext } from '../../../providers/session.provider';
import { MegaUser } from '../../../types/user';

import styles from './edit-profile-screen.module.scss';

export const EditProfileScreen = () => {
  const queryCache = useQueryClient();
  const navigate = useNavigate();
  const { showModal, hideModal } = useGlobalModalContext();
  const { getUser, setMegaUser } = useContext(SessionContext);

  const [selectedCountry, setSelectedCountry] = useState<AuthorizedCountry | null>(null);
  const [passwordType, setPasswordType] = useState<'text' | 'password'>('password');
  const [password2Type, setPassword2Type] = useState<'text' | 'password'>('password');
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();

  const { control, formState, getValues, handleSubmit, register, reset, setValue, watch, trigger } =
    useForm({
      mode: 'onChange',
      resolver: yupResolver(editProfileSchema),
    });

  const password = watch('password', '');
  const passwordConfirmation = watch('passwordConfirmation', '');

  // Queries
  const profileQuery = useQuery(QueryTypes.GetProfile, AccountServices.getProfileData);
  const countryQuery = useQuery(
    QueryTypes.GetAuthCountries,
    CountryServices.getAllAuthorizedCountries
  );

  useEffect(() => {
    if (
      profileQuery.status === 'success' &&
      countryQuery.status === 'success' &&
      !!profileQuery.data &&
      !!countryQuery.data
    ) {
      setErrorMessage(undefined);
      reset();
      setValue('firstName', profileQuery.data.user.firstName);
      setValue('lastName', profileQuery.data.user.lastName);
      setValue('email', profileQuery.data.user.email);

      setValue('address1', profileQuery.data.user.address.address1 ?? '');
      setValue('city', profileQuery.data.user.address.city ?? '');
      setValue('state', profileQuery.data.user.address.state ?? '');
      setValue('zipCode', profileQuery.data.user.address.zip ?? '');
      setValue('country', findCountryByCode(profileQuery.data.user.address.country));

      setValue('receiveEmail', profileQuery.data.user.receive_emails);
      setValue('receiveSms', profileQuery.data.user.receive_sms);

      setValue('changePassword', false);
      setValue('password', '');
      setValue('passwordConfirmation', '');
    }
  }, [profileQuery.data, countryQuery.data]);

  useEffect(() => {
    if (password || passwordConfirmation) {
      setValue('changePassword', true);
    } else {
      setValue('changePassword', false);
    }

    trigger('password');
    trigger('passwordConfirmation');
  }, [password, passwordConfirmation]);

  const findCountryByCode = (code: string | undefined) => {
    if (countryQuery.data && code) {
      return countryQuery.data.find((i) => i.count_cod?.toLowerCase() === code.toLowerCase());
    }
  };

  const switchPasswordType = (type: number) => {
    const pass = type === 1 ? passwordType : password2Type;
    const newPassType = pass === 'password' ? 'text' : 'password';

    if (type === 1) {
      setPasswordType(newPassType);
    } else {
      setPassword2Type(newPassType);
    }
  };

  const onSubmit = async (data: any) => {
    showModal(MODAL_TYPES.LOADING, {
      title: 'Actualizando su perfil',
    });

    setIsProcessing(true);
    setErrorMessage(undefined);
    try {
      const profileData = { ...data, country: data.country.count_cod };
      await AccountServices.updateProfile(profileData);
      updateAuthUser(profileData);

      queryCache.invalidateQueries(QueryTypes.GetProfile);

      navigate(ROUTES.cuenta.inicio);
    } catch (err: any) {
      setErrorMessage('Hubo un error al guardar los datos. Verifica todos los campos.');
    } finally {
      setIsProcessing(false);
      hideModal();
    }
  };

  const updateAuthUser = (data: any) => {
    const user = getUser();

    if (user) {
      setMegaUser({
        ...user,
        CUSTOMERNAME: data.firstName,
        CUSTOMER_LASTNAME: data.lastName,
        COUNTRY: data.country,
        EMAILADDRESS: data.email,
      } as MegaUser);
    }
  };

  if (profileQuery.isError) {
    return <MegaError displayRetry retry={() => profileQuery.refetch()} />;
  }

  const profile = profileQuery.data?.user || ({} as any);
  const account = profileQuery.data?.account || ({} as any);

  return (
    <div className="bg-mega_light_gray">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 col-md-2 col-lg-3"></div>
          <div className="col-12 col-md-8 col-lg-6">
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <div className="w-100 d-none d-md-block mb-3">
                <Button
                  variant="link"
                  onClick={() => navigate(ROUTES.cuenta.inicio)}
                  className="text-secondary text-decoration-none px-0 ms-0 ms-md-2">
                  <FlechaIzq width={20} fill={'#8ab934'} className="me-1" /> Volver a mi Cuenta
                </Button>
              </div>

              <div className="w-100 d-flex flex-column flex-md-row mb-3 align-items-center">
                <div className="flex-fill">
                  <h5>Editar Cuenta</h5>
                </div>
                <div className="flex-fill d-flex flex-row">
                  <div className="flex-fill me-3">
                    <Button
                      variant="outline-secondary"
                      className="w-100"
                      onClick={() => navigate(ROUTES.cuenta.inicio)}>
                      Cancelar
                    </Button>
                  </div>
                  <div className="flex-fill">
                    <Button variant="secondary" type="submit" className="w-100">
                      Guardar
                    </Button>
                  </div>
                </div>
              </div>

              <div className="column-alert columns">
                <h6 className="mb-3">Datos de la cuenta</h6>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="megaInput">
                      <label htmlFor="name">Nombre</label>
                      <input
                        type="text"
                        className={`form-control mega ${
                          formState.errors.firstName ? 'border border-danger' : ''
                        }`}
                        id="name"
                        {...register('firstName')}
                      />
                    </div>
                    {formState.errors.firstName && (
                      <div className="text-danger">El nombre es requerido</div>
                    )}
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="megaInput">
                      <label htmlFor="lastName">Apellido(s)</label>
                      <input
                        type="text"
                        className={`form-control mega ${
                          formState.errors.lastName ? 'border border-danger' : ''
                        }`}
                        id="lastName"
                        {...register('lastName')}
                      />
                    </div>
                    {formState.errors.lastName && (
                      <div className="text-danger">El Apellido es requerido</div>
                    )}
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-12 col-lg-6">
                    <div className="megaInput">
                      <label htmlFor="phone">Número de teléfono</label>
                      <input
                        type="text"
                        className={`form-control mega `}
                        id="phone"
                        disabled
                        defaultValue={profile.username}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="megaInput">
                      <label htmlFor="email">Email</label>
                      <input
                        type="text"
                        className={`form-control mega ${
                          formState.errors.email ? 'border border-danger' : ''
                        }`}
                        id="email"
                        {...register('email')}
                      />
                    </div>
                    {formState.errors.email && (
                      <div className="text-danger">El Email es requerido</div>
                    )}
                  </div>
                </div>
              </div>

              <div className="column-alert columns mt-5">
                <h6 className="mb-3">Datos de la dirección</h6>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="megaInput">
                      <label htmlFor="address">Dirección</label>
                      <input
                        type="text"
                        className={`form-control mega ${
                          formState.errors.address1 ? 'border border-danger' : ''
                        }`}
                        id="address"
                        {...register('address1')}
                      />
                    </div>
                    {formState.errors.address1 && (
                      <div className="text-danger">La Dirección es requerida</div>
                    )}
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="megaInput">
                      <label htmlFor="city">Ciudad</label>
                      <input
                        type="text"
                        className={`form-control mega ${
                          formState.errors.city ? 'border border-danger' : ''
                        }`}
                        id="city"
                        {...register('city')}
                      />
                    </div>
                    {formState.errors.city && (
                      <div className="text-danger">La Ciudad es requerida</div>
                    )}
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-12 col-lg-6">
                    <div className="megaInput">
                      <label htmlFor="state">Estado</label>
                      <input
                        type="text"
                        className={`form-control mega ${
                          formState.errors.state ? 'border border-danger' : ''
                        }`}
                        id="state"
                        {...register('state')}
                      />
                    </div>
                    {formState.errors.state && (
                      <div className="text-danger">El Estado es requerido</div>
                    )}
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="megaInput">
                      <label htmlFor="country">Pa&iacute;s</label>

                      <Controller
                        control={control}
                        name="country"
                        render={({ field: { onChange, name, value } }) => (
                          <Select
                            id="country"
                            name={name}
                            placeholder=""
                            value={value}
                            options={countryQuery.data}
                            isSearchable={false}
                            isClearable={false}
                            isLoading={UtilService.isLoadingQueries([countryQuery])}
                            className="w-100 mega-select"
                            isMulti={false}
                            styles={reactSelectStyles}
                            components={{
                              Option: IconOptionAuthorizedCountry,
                              SingleValue: IconSingleValueAuthorizedCountry,
                            }}
                            onChange={onChange}
                          />
                        )}
                      />
                    </div>
                    {formState.errors.country && (
                      <div className="text-danger">El País es requerido</div>
                    )}
                  </div>
                </div>

                <div className="row mt-5">
                  <div className="col-12 col-lg-6">
                    <div className="megaInput">
                      <label htmlFor="zipCode">Código Postal</label>
                      <input
                        type="text"
                        className={`form-control mega ${
                          formState.errors.zipCode ? 'border border-danger' : ''
                        }`}
                        id="zipCode"
                        {...register('zipCode')}
                      />
                    </div>
                    {formState.errors.zipCode && (
                      <div className="text-danger">El Código postal es requerido</div>
                    )}
                  </div>
                  <div className="col-12 col-lg-6"></div>
                </div>
              </div>

              <div className="column-alert columns mt-5">
                <h6 className="mb-3">Contraseña</h6>
                <p>Si cambias tu contraseña, se cerrará sesión en todos tus dispositivos</p>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <div className="megaInputWithIcon">
                      <input
                        id="password"
                        type={passwordType}
                        placeholder="Contraseña"
                        className={`form-control mega ${
                          formState.errors.password ? 'border border-danger' : ''
                        }`}
                        maxLength={25}
                        {...register('password')}
                      />
                      {passwordType === 'password' && (
                        <OjoTachado
                          width={20}
                          className="icon"
                          onClick={() => switchPasswordType(1)}
                        />
                      )}
                      {passwordType === 'text' && (
                        <Ojo width={20} className="icon" onClick={() => switchPasswordType(1)} />
                      )}
                    </div>
                    {!!password && !formState.errors.password && (
                      <div className="mt-3">
                        <PasswordStrengthBar
                          password={password}
                          minLength={4}
                          shortScoreWord={'demasiado corto'}
                          barColors={['#ddd', '#ff4b55', '#ff9855', '#263645', '#8ab934']}
                          scoreWords={['débil', 'débil', 'okay', 'mejor', 'fuerte']}
                        />
                      </div>
                    )}
                    {formState.errors.password && (
                      <div className="text-danger">{formState.errors.password.message}</div>
                    )}
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="megaInputWithIcon">
                      <input
                        type={password2Type}
                        placeholder="Confirma la contraseña"
                        className={`form-control mega ${
                          formState.errors.passwordConfirmation ? 'border border-danger' : ''
                        }`}
                        maxLength={25}
                        id="passwordConfirmation"
                        {...register('passwordConfirmation')}
                      />
                      {password2Type === 'password' && (
                        <OjoTachado
                          width={20}
                          className="icon"
                          onClick={() => switchPasswordType(2)}
                        />
                      )}
                      {password2Type === 'text' && (
                        <Ojo width={20} className="icon" onClick={() => switchPasswordType(2)} />
                      )}
                    </div>
                    {formState.errors.passwordConfirmation && (
                      <div className="text-danger">
                        {formState.errors.passwordConfirmation.message}
                      </div>
                    )}
                  </div>
                </div>

                <p className={`${styles.pColor} mb-1 mt-4`}>Seguridad de la contraseña:</p>
                <p className={`${styles.pColor} mb-0`}>
                  Utiliza al menos 8 caracteres. No uses una contraseña de otro sitio ni un nombre
                  demasiado obvio, como el de tu mascota.
                </p>
              </div>

              <div className="column-alert columns mt-5">
                <h6 className="mb-3">Notificaciones</h6>
                <p>
                  Selecciona por qué medios quieres recibir ofertas promocionales y publicidad de
                  Megaconecta.
                </p>

                <div className="row mt-2">
                  <div className="col-12">
                    <div className="form-check form-switch d-flex flex-row p-0">
                      <div className="flex-fill">
                        <label className="form-check-label" htmlFor="notif-sms">
                          Notificaciones por SMS
                        </label>
                      </div>
                      <div className="flex-fill d-flex align-items-center justify-content-end">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="notif-sms"
                          {...register('receiveSms')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mt-3">
                    <div className="form-check form-switch d-flex flex-row p-0">
                      <div className="flex-fill">
                        <label className="form-check-label" htmlFor="notif-email">
                          Notificaciones por Email
                        </label>
                      </div>
                      <div className="flex-fill d-flex align-items-center justify-content-end">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          role="switch"
                          id="notif-email"
                          {...register('receiveEmail')}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12"></div>
                </div>
              </div>
            </form>
          </div>
          <div className="col-12 col-md-2 col-lg-3"></div>
        </div>
      </div>
    </div>
  );
};
function setIsProcessing(arg0: boolean) {
  throw new Error('Function not implemented.');
}
