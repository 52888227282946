export const errorMessages = {
  address1: 'La Dirección es requerida',
  cardCvc: 'El CVC es requerido',
  cardCvcFormat: 'El formato de CVC no es válido.',
  cardExpiration: 'La Fecha de expiración es requerida',
  cardExpirationFormat: 'La Fecha de expiración no es válida.',
  cardExpirationPast: 'La Fecha de expiración no puede estar en el pasado.',
  cardNumber: 'El Número de la tarjeta es requerido',
  cardNumberFormat: 'El Número de la tarjeta no es válido',
  city: 'La Ciudad/Reparto es requerida',
  country: 'El País es requerido',
  neighborhood: 'El Reparto es requerido',
  directions: 'Las Instrucciones son requeridas',
  email: {
    required: 'El Email es requerido',
    format: 'El Email es inválido',
  },
  firstName: 'El Nombre es requerido',
  fullName: 'El Nombre Completo es requerido',
  idCard: 'El Carnet de Identidad es requerido',
  lastName: 'El Apellido(s) es requerido',
  mlcCard: 'La Tarjeta MLC es requerida',
  municipality: 'El Municipio es requerido',
  password: {
    required: 'La Contraseña es requerida',
  },
  phoneNumber: 'El Teléfono es requerido',
  state: 'El Estado es requerido',
  zip: 'El Código Postal es requerido',
  zipCode: 'El Código Postal es requerido',
  province: 'La Provincia es requerida',
  deliveryMethod: 'El Método de Entrga es requerido',
};
