import * as React from 'react';
import { SVGProps } from 'react';
const SvgMasterCard = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.36 20" {...props}>
    <g data-name="Capa 1">
      <path
        d="M20.56 17.86h-8.75V2.14h8.75v15.73Z"
        style={{
          fill: '#ff5f00',
        }}
      />
      <path
        d="M12.36 10a9.99 9.99 0 0 1 3.82-7.86C11.84-1.27 5.55-.52 2.14 3.82A9.971 9.971 0 0 0 0 10c0 5.52 4.48 10 10 10 2.24 0 4.41-.75 6.18-2.14A9.99 9.99 0 0 1 12.36 10Z"
        style={{
          fill: '#eb001b',
        }}
      />
      <path
        d="M32.36 10c0 5.52-4.48 10-10 10-2.24 0-4.42-.75-6.18-2.14C18.51 16.03 20 13.19 20 10s-1.49-6.03-3.82-7.86c4.34-3.41 10.63-2.66 14.04 1.68 1.38 1.76 2.14 3.94 2.14 6.18Z"
        style={{
          fill: '#f79e1b',
        }}
      />
    </g>
  </svg>
);
export default SvgMasterCard;
