import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import {
  DownloadAppCommon,
  RegisterSection,
  ThreeColumnIconSection,
} from '../../../components/external';
import {
  FlechaDer,
  OvalWrapper,
  PanelControlAnaranjado,
  PromocionesAnaranjado,
  SoporteTecnicoAnaranjado,
} from '../../../components/svg';
import { IMAGES } from '../../../config/images';
import { reactSelectStyles } from '../../../config/reactSelect';
import { ROUTES } from '../../../config/routes';
import { SessionContext } from '../../../providers/session.provider';
import { SmsService } from '../../../services';
import { QueryTypes } from '../../../types/queryTypes';
import { SmsCountryRate } from '../../../types/sms';
import {
  IconOptionSmsRateCountry,
  IconSingleValueSmsRateCountry,
} from '../../account/send-sms/options';

import styles from './service-messages-screen.module.scss';

export const ServiceMessagesScreen = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(SessionContext);

  const [countryList, setCountryList] = useState<SmsCountryRate[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<SmsCountryRate | null>(null);

  const countryQuery = useQuery(QueryTypes.GetSmsRateCountries, SmsService.getCountryRates);

  useEffect(() => {
    if (isAuthenticated()) {
      navigate(ROUTES.cuenta.inicio);
      return;
    }
  }, []);

  useEffect(() => {
    if (countryQuery.status === 'success' && countryQuery.data.length) {
      setCountryList(countryQuery.data);
    }
  }, [countryQuery.status]);

  useEffect(() => {
    const cubaCountry = countryList.find((item) => item.country_code.toLowerCase() === 'cu');
    if (cubaCountry) {
      setSelectedCountry(cubaCountry);
    }
  }, [countryList]);

  const threeColumns = {
    title: 'Te garantizamos <span class="text-warning text-decoration-underline">lo mejor</span>',
    mainColor: '#FFE5D4',
    columns: [
      {
        title: 'Promociones',
        icon: PromocionesAnaranjado,
        description:
          'Te ofrecemos las mejores promociones. Puedes estar seguro de que siempre vas a encontrar el mejor precio.',
      },
      {
        title: 'Panel de Control',
        icon: PanelControlAnaranjado,
        description:
          'Accede fácilmente a ver tu historial de llamadas, pagos y de marcación directa.',
      },
      {
        title: 'Soporte Técnico',
        icon: SoporteTecnicoAnaranjado,
        description:
          'Nuestros agentes especializados están disponibles para atender todas tus dudas y sugerencias.',
      },
    ],
  };

  const getSmsCost = () => {
    let country = selectedCountry?.country_code;
    country = (country || '').toLowerCase();

    if (!country) {
      return Number(0.0).toFixed(2);
    }

    const smsCountry = countryList.find((item) => item.country_code.toLowerCase() === country);
    return Number(smsCountry?.price ?? 0.0).toFixed(2);
  };

  return (
    <div>
      <div className={styles.wrapperHeader}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 d-flex align-items-center">
              <img src={IMAGES.Promos.PromoMensajes} className="img-fluid" />
            </div>
            <div className="col-12 col-lg-6">
              <h1 className="text-warning text-decoration-underline text-center text-lg-start">
                ¡Conecta con los que más quieres!
              </h1>
              <h1 className="pe-md-8  text-center text-lg-start">
                Envía mensajes de texto hacia Cuba y el mundo
              </h1>

              <p className="fs-mg-20 mt-5 text-center text-lg-start">
                ¡Hazles saber que los amas! Envía mensajes de texto a tus familiares en Cuba, amigos
                y el mundo y mantente siempre en contacto con ellos.
              </p>

              <div className="d-flex justify-content-center justify-content-lg-start">
                <div className={`${styles.priceWrapper}`}>
                  <div className="bubble bubble-warning">
                    <div className={styles.priceContentWrapper}>
                      <div className="ps-4">
                        <h1 className="d-inline">$0.05</h1>
                        <small className="fs-mg-20 d-inline text-warning">/SMS</small>
                      </div>
                      <p className={`fs-mg-20 ps-4 mb-0 ${styles.pPriceDesc}`}>Tarifa para Cuba</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center text-lg-start">
                <Link to={ROUTES.cuenta.contactos} className="btn btn-warning text-white mt-4 px-4">
                  ¡Llama ahora! <FlechaDer width={15} fill="#fff" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`container ${styles.rateContainer}`}>
        <div className={styles.rateWrapper}>
          <div className="row">
            <div
              className={`col-12 col-lg-4 mb-5 mb-lg-0 ${styles.rateTitleWrapper} d-flex align-items-center`}>
              <h2 className="text-white text-center text-lg-start">
                Conoce las <span className="text-warning text-decoration-underline">tarifas</span>{' '}
                a otros países
              </h2>
            </div>
            <div className="d-none d-lg-flex col-lg-1"></div>
            <div className={`col-12 col-lg-7 ${styles.ratePriceWrapper}`}>
              <div className="row">
                <div className="col-12">
                  <h6 className="mb-4">Te ofrecemos las tarifas más económicas del mercado</h6>
                </div>
                <div className="col-12 col-md-6">
                  <div className={styles.wrapperSelect}>
                    <Select
                      id="tel"
                      placeholder="Seleccione el país"
                      value={selectedCountry}
                      options={countryList}
                      isSearchable={false}
                      isClearable={false}
                      className="w-100 mega-select"
                      isMulti={false}
                      styles={reactSelectStyles}
                      components={{
                        Option: IconOptionSmsRateCountry,
                        SingleValue: IconSingleValueSmsRateCountry,
                      }}
                      onChange={(newValue) => {
                        setSelectedCountry(newValue);
                      }}
                    />
                  </div>
                </div>
                <div className="col-12 col-md-6 mt-5 mt-md-0 mb-3 mb-md-0">
                  <div
                    className={`flex-column ${styles.wrapperSelect2} ${
                      selectedCountry ? '' : styles.opac
                    }`}>
                    <div className="d-flex flex-row align-items-center justify-content-center justify-content-md-start">
                      <span>
                        <span className="fs-2 fw-bolder futura-bold">${getSmsCost()}</span>
                        <span className="fs-6 fw-bold text-warning">/ SMS</span>
                      </span>
                    </div>
                    <p className="p-0 text-center text-md-start">Celulares</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ThreeColumnIconSection {...threeColumns} />

      <DownloadAppCommon bgColor="#FFEEE3" appStoreClassName="warning" />

      <RegisterSection color="warning" arrowColor="#FF9855" />
    </div>
  );
};
