import { PromoContent } from '../types/promo';
import api from './axios/api';

const getPromoContent = async (category: string): Promise<PromoContent> => {
  let params = {
    category: category
  };

  try {
    const response = await api.get<PromoContent>('/api/v1/promotions/getfirstcontent', {
      params: params,
    });

    if (!response.has_error && !!response.data) {
      return response.data;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const getPromoContents = async (category: string): Promise<PromoContent[]> => {
  let params = {
    category: category
  };

  try {
    const response = await api.get<PromoContent[]>('/api/v1/promotions/getallcontents', {
      params: params,
    });

    if (!response.has_error && !!response.data) {
      return response.data;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

export const PromoServices = {  
  getPromoContent,
  getPromoContents
};
