import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ShoppingCartTotals } from '../../../components/account';
import { ShoppingCartProductList } from '../../../components/account/shoppingCartProductList';
import { ROUTES } from '../../../config/routes';
import { useShoppingCart } from '../../../context/ShoppingCartContext';

export const ShoppingCartScreen = () => {
  const navigate = useNavigate();
  const { getProductsCount } = useShoppingCart();

  useEffect(() => {
    if (getProductsCount === 0) {
      navigate(ROUTES.cuenta.store);
    }
  }, []);

  return (
    <div className="bg-mega_light_gray">
      <div className="container py-5">
        <div className="row">
          <div className="col-12 col-md-8 mb-4 mb-md-0">
            <h5 className="mb-3">Carrito de Compras ({getProductsCount})</h5>
            <ShoppingCartProductList allowToModify />
          </div>
          <div className="col-12 col-md-4">
            <ShoppingCartTotals />
          </div>
        </div>
      </div>
    </div>
  );
};
