import { keyframes } from '@emotion/react';
import { useEffect, useState } from 'react';
import Reveal from 'react-awesome-reveal';
import { A11y, Autoplay, EffectFade, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { PromoContent } from '../../../types/promo';

import 'swiper/css/bundle';
import 'swiper/css/effect-fade';
import styles from './slider.module.scss';

import SwiperClass from 'swiper/types/swiper-class';

const customAnimation1 = keyframes`
  from {
    opacity: 0.1;
    transform: translate3d(-200px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

const customAnimation2 = keyframes`
  from {
    opacity: 0.1;
    transform: translate3d(200px, 0, 0);
  }
  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
`;

function CustomAnimation1({ children }: { children: any }) {
  return <Reveal keyframes={customAnimation1}>{children}</Reveal>;
}
function CustomAnimation2({ children }: { children: any }) {
  return <Reveal keyframes={customAnimation2}>{children}</Reveal>;
}

export type SliderProps = {
  promoContents: PromoContent[];
  autoPlay?: boolean;
  removeAnimations?: boolean;
};

export const Slider = (props: SliderProps) => {
  const [swiper, setSwiper] = useState<SwiperClass>();
  const [currentColor, setCurrentColor] = useState('');

  const slideChange = () => {
    if (swiper) {
      setCurrentColor(props.promoContents[swiper.realIndex]?.backcolor);
    }
  };

  useEffect(() => {
    if (swiper) {
      setCurrentColor(props.promoContents[swiper.realIndex]?.backcolor);
    }
  }, [swiper, props.promoContents]);

  return (
    <div style={{ backgroundColor: currentColor }}>
      <Swiper
        modules={[Navigation, Pagination, A11y, Autoplay, EffectFade]}
        spaceBetween={50}
        className="container"
        slidesPerView={1}
        navigation
        loop
        autoHeight
        effect="fade"
        pagination={{ clickable: true }}
        autoplay={
          props.autoPlay
            ? {
                delay: 6000,
                pauseOnMouseEnter: false,
                disableOnInteraction: false,
                waitForTransition: true,
              }
            : false
        }
        onSlideChange={() => slideChange()}
        onSwiper={setSwiper}>
        {props.promoContents.map((promocontent: any, idx) => (
          <div key={promocontent.id}>
            {!props.removeAnimations && (
              <SwiperSlide style={{ backgroundColor: currentColor }} key={promocontent.id}>
                <div className={styles.sliderwrapper} style={{ backgroundColor: currentColor }}>
                  <div className={styles.sliderrow}>
                    <div className={styles.slidercolumn}>
                      <CustomAnimation1>
                        <div>
                          <div
                            className="d-flex flex-row align-items-center"
                            dangerouslySetInnerHTML={{ __html: promocontent?.content }}></div>
                        </div>
                      </CustomAnimation1>
                    </div>

                    <div className={styles.slidercolumn}>
                      <CustomAnimation2>
                        <img src={promocontent.banner} className="img-fluid" />
                      </CustomAnimation2>
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            )}

            {props.removeAnimations && (
              <SwiperSlide style={{ backgroundColor: currentColor }}>
                <div className={styles.sliderwrapper} style={{ backgroundColor: currentColor }}>
                  <div className={styles.sliderrow}>
                    <div className={styles.slidercolumn}>
                      <div>
                        <div
                          className="d-flex flex-row align-items-center"
                          dangerouslySetInnerHTML={{ __html: promocontent?.content }}></div>
                      </div>
                    </div>

                    <div className={styles.slidercolumn}>
                      <img src={promocontent.banner} className="img-fluid" />
                    </div>
                  </div>
                </div>
              </SwiperSlide>
            )}
          </div>
        ))}
      </Swiper>
    </div>
  );
};
