import * as React from 'react';
import { SVGProps } from 'react';
const SvgFechaInput = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="fecha_input_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 20 20"
    {...props}>
    <defs>
      <clipPath id="fecha_input_svg__clippath">
        <path
          style={{
            fill: 'none',
          }}
          d="M0 0h20v20H0z"
        />
      </clipPath>
      <style>{'.fecha_input_svg__cls-1{fill:#616161}'}</style>
    </defs>
    <g
      style={{
        clipPath: 'url(#fecha_input_svg__clippath)',
      }}
      id="fecha_input_svg__Capa_1-2"
      data-name="Capa 1">
      <g id="fecha_input_svg__Grupo_5907" data-name="Grupo 5907">
        <path
          id="fecha_input_svg__Trazado_11028"
          data-name="Trazado 11028"
          className="fecha_input_svg__cls-1"
          d="M8.43 14.03H5.11c-.33 0-.6.27-.6.6 0 .33.27.6.6.6h3.32c.33 0 .6-.27.6-.6 0-.33-.27-.6-.6-.6"
        />
        <path
          id="fecha_input_svg__Trazado_11029"
          data-name="Trazado 11029"
          className="fecha_input_svg__cls-1"
          d="M5.1 11.04h3.24c.33 0 .6-.27.6-.6 0-.33-.27-.6-.6-.6H5.1c-.33 0-.6.27-.6.6 0 .33.27.6.6.6"
        />
        <path
          id="fecha_input_svg__Trazado_11030"
          data-name="Trazado 11030"
          className="fecha_input_svg__cls-1"
          d="M14.9 14.03h-3.32c-.33 0-.6.27-.6.6 0 .33.27.6.6.6h3.32c.33 0 .6-.27.6-.6 0-.33-.27-.6-.6-.6"
        />
        <path
          id="fecha_input_svg__Trazado_11031"
          data-name="Trazado 11031"
          className="fecha_input_svg__cls-1"
          d="M11.57 11.04h3.24c.33 0 .6-.27.6-.6 0-.33-.27-.6-.6-.6h-3.24c-.33 0-.6.27-.6.6 0 .33.27.6.6.6"
        />
        <path
          id="fecha_input_svg__Trazado_11032"
          data-name="Trazado 11032"
          className="fecha_input_svg__cls-1"
          d="M19.38 5.13a2.716 2.716 0 0 0-2.34-2.32c-.25-.03-.5-.06-.77-.09v-.27c.04-1.19-.89-2.18-2.08-2.21h-.49c-1.19.02-2.13.99-2.11 2.18v.02c-1.04-.02-2.13-.02-3.18 0 .03-1.18-.9-2.17-2.09-2.2H5.85c-1.19.02-2.13.99-2.11 2.18V2.72c-.27.03-.52.06-.77.09C1.75 2.96.79 3.91.62 5.13a42.31 42.31 0 0 0-.38 5.96c-.01 1.99.11 3.98.38 5.96a2.716 2.716 0 0 0 2.34 2.32c2.34.29 4.69.42 7.04.4 2.35.02 4.7-.11 7.04-.4 1.22-.15 2.18-1.1 2.34-2.32.27-1.97.39-3.97.38-5.96.01-1.99-.11-3.98-.38-5.96m-6.6-2.66c-.04-.53.35-.99.87-1.03h.48c.53.01.95.45.93.98V3.56c.05.53-.34.99-.87 1.04h-.47a.96.96 0 0 1-.94-.98V2.48m-7.86 0c-.04-.53.35-.99.87-1.03h.48c.53.01.95.45.93.98V3.57a.97.97 0 0 1-.87 1.04h-.48a.946.946 0 0 1-.93-.98V2.49m13.26 14.4c-.09.68-.63 1.22-1.32 1.3-2.28.28-4.58.41-6.88.39-2.3.02-4.6-.11-6.88-.39-.68-.08-1.23-.62-1.31-1.3-.26-1.92-.38-3.85-.37-5.79-.02-1.94.11-3.87.36-5.79.09-.68.63-1.22 1.31-1.3.21-.03.42-.05.64-.08a2.126 2.126 0 0 0 2.09 1.88h.45a2.14 2.14 0 0 0 2.11-2.16c1.05-.02 2.14-.02 3.18 0a2.14 2.14 0 0 0 2.11 2.16h.45c1.07 0 1.96-.81 2.08-1.87.22.03.44.05.65.08.69.08 1.23.62 1.32 1.3.26 1.92.38 3.85.37 5.79.01 1.94-.11 3.87-.37 5.79"
        />
      </g>
    </g>
  </svg>
);
export default SvgFechaInput;
