import { createContext, ReactNode, useContext, useState } from 'react';
import { useSessionStorage } from 'usehooks-ts';
import { useLocalStorage } from '../hooks/useLocalStorage';
import { NewCardInformation } from '../types/newCardInformation';
import { NewPaypalInformation } from '../types/newPaypalInformation';
import { PaymentMethodCard, PaymentMethodType } from '../types/payment';
import { MegaStoreProduct } from '../types/reports/storeProduct';
import { ShippingInformation } from '../types/shippingInformation';
import {
  emptyShippingInformation,
  getDelivery,
  getProvinceId,
  ShippingInformationParams,
} from './shoppingCartContextHelpers';

type ShoppingCartProviderProps = {
  children: ReactNode;
};

type ShoppingCartContext = {
  products: MegaStoreProduct[];

  addProduct: (product: MegaStoreProduct, quantity: number) => void;
  updateProduct: (productId: number, change: number) => void;
  removeProduct: (productId: number) => void;

  getProductCount: (productId: number) => number;
  getProductsCount: number;
  getProductsTotalCount: number;
  productsTotal: number;

  clearAfterSuccessfulPurchase: () => void;
  clearCart: () => void;
  clearCurrentPaymentMethod: () => void;
  currentPaymentMethod: PaymentMethodCard | undefined;
  currentPaymentMethodType?: PaymentMethodType;
  isSidebarVisible: boolean;
  newCardInformation: NewCardInformation | undefined;
  newPaypalInformation: NewPaypalInformation | undefined;
  setCurrentPaymentMethod: (
    currentPaymentMethodType?: PaymentMethodType,
    paymentMethod?: PaymentMethodCard
  ) => void;
  setSidebarVisibility: (visible: boolean) => void;
  shippingInformation: ShippingInformation;
  updateShippingInformation: (params: ShippingInformationParams) => void;
  updateNewCardInformation: (newCardInformation?: NewCardInformation) => void;
  updateNewPaypalInformation: (newPaypalInformation?: NewPaypalInformation) => void;
  deleteNewCardInformation: () => void;
  deleteNewPaypalInformation: () => void;
};

const ShoppingCartContext = createContext({} as ShoppingCartContext);

export function useShoppingCart() {
  return useContext(ShoppingCartContext);
}

export function ShoppingCartProvider({ children }: ShoppingCartProviderProps) {
  const [products, setProducts] = useLocalStorage<MegaStoreProduct[]>('shopping-cart', []);
  const [shippingInformation, setShippingInformation] = useLocalStorage<ShippingInformation>(
    'shipping-information',
    emptyShippingInformation
  );
  const [newCardInformation, setNewCardInformation] = useSessionStorage<
    NewCardInformation | undefined
  >('new-card-information', undefined);
  const [newPaypalInformation, setNewPaypalInformation] = useSessionStorage<
    NewPaypalInformation | undefined
  >('new-paypal-information', undefined);
  const [isSidebarVisible, setIsSidebarVisible] = useState<boolean>(false);
  const [currentPaymentMethod, setSelectedPaymentMethod] = useState<PaymentMethodCard | undefined>(
    undefined
  );
  const [currentPaymentMethodType, setCurrentPaymentMethodType] = useState<
    'new-card' | 'new-paypal' | 'card' | 'paypal' | undefined
  >(undefined);

  const setCurrentProvince = async (provinceName: string | undefined) => {
    if (provinceName) {
      const provinceId = await getProvinceId(provinceName);
      updateShippingInformation({ province: provinceName, provinceId: provinceId });
    }
  };

  const addProduct = (product: MegaStoreProduct, quantity: number) => {
    const provinceName = product.details.find(
      (detail) => detail.title === 'Localidad'
    )?.description;

    if (products.length === 0) {
      setCurrentProvince(provinceName);
    }

    if (!shippingInformation?.province || shippingInformation.province === provinceName) {
      const existingProducts = products.filter((_product, index) => _product.id == product.id);
      if (existingProducts.length == 0) {
        product.shopping_cart_quantity = quantity;
        setProducts([...products, ...Array(1).fill(product)]);
      } else {
        updateProduct(product.id, quantity);
      }
    } else {
      throw 'Error: Unicamente puede agregar productos de la misma provincia.';
    }
  };

  const updateProduct = (productId: number, change: number) => {
    const updatedProducts = products.map((product, index) => {
      if (product.id == productId) {
        product.shopping_cart_quantity = product.shopping_cart_quantity + change;
      }
      return product;
    });

    setProducts(updatedProducts);
  };

  const removeProduct = (productId: number) => {
    const productList = products.filter((_product, index) => _product.id !== productId);
    if (productList.length == 0) {
      setShippingInformation(emptyShippingInformation);
    }
    setProducts(productList);
  };

  const productsTotal = products.reduce(
    (a, b) => a + b.shopping_cart_quantity * parseFloat(b.price),
    0
  );

  const clearCart = () => {
    setProducts([]);
    setShippingInformation(emptyShippingInformation);
  };

  const getProductCount = (productId: number) => {
    const foundProducts = products.filter((product) => product.id === productId);
    return foundProducts.length > 0 ? foundProducts[0].shopping_cart_quantity : 0;
  };

  const getProductsCount = products.length;

  const getProductsTotalCount = products.reduce((a, b) => a + b.shopping_cart_quantity, 0);

  const setSidebarVisibility = (visible: boolean) => setIsSidebarVisible(visible);

  const updateNewCardInformation = (newCardInformation?: NewCardInformation) => {
    setNewCardInformation(newCardInformation);
  };

  const updateNewPaypalInformation = (newPaypalInformation?: NewPaypalInformation) => {
    setNewPaypalInformation(newPaypalInformation);
  };

  const deleteNewCardInformation = () => {
    setNewCardInformation(undefined);
    if (currentPaymentMethodType === 'new-card') {
      setCurrentPaymentMethodType(undefined);
    }
  };

  const deleteNewPaypalInformation = () => {
    setNewPaypalInformation(undefined);
    if (currentPaymentMethodType === 'new-paypal') {
      setCurrentPaymentMethodType(undefined);
    }
  };

  const setCurrentPaymentMethod = (
    currentPaymentMethodType?: PaymentMethodType,
    paymentMethod?: PaymentMethodCard
  ) => {
    setCurrentPaymentMethodType(currentPaymentMethodType);
    if (['new-card', 'new-paypal'].includes(currentPaymentMethodType ?? '')) {
      setSelectedPaymentMethod(undefined);
    } else {
      setSelectedPaymentMethod(paymentMethod);
    }
  };

  const clearCurrentPaymentMethod = () => {
    setCurrentPaymentMethodType(undefined);
    setSelectedPaymentMethod(undefined);
  };

  const updateShippingInformation = (params: ShippingInformationParams) => {
    if (Object.keys(params).includes('municipalityId')) {
      const delivery = getDelivery({
        provinceId: `${shippingInformation.provinceId}`,
        municipalityId: `${params.municipalityId}`,
        products,
      });
      params.delivery = delivery;
      params.deliveryFee = parseFloat(delivery?.price ?? '0');
    }
    setShippingInformation({ ...shippingInformation, ...params });
  };

  const clearAfterSuccessfulPurchase = () => {
    clearCart();
    setNewCardInformation(undefined);
    setNewPaypalInformation(undefined);
    setSelectedPaymentMethod(undefined);
    setCurrentPaymentMethodType(undefined);
    setShippingInformation(emptyShippingInformation);
  };

  return (
    <ShoppingCartContext.Provider
      value={{
        addProduct,
        updateProduct,
        removeProduct,
        clearAfterSuccessfulPurchase,
        clearCart,
        clearCurrentPaymentMethod,
        currentPaymentMethod,
        currentPaymentMethodType,
        deleteNewCardInformation,
        deleteNewPaypalInformation,
        getProductCount,
        getProductsCount,
        getProductsTotalCount,
        isSidebarVisible,
        newCardInformation,
        newPaypalInformation,
        products,
        productsTotal,
        setCurrentPaymentMethod,
        setSidebarVisibility,
        shippingInformation,
        updateNewCardInformation,
        updateNewPaypalInformation,
        updateShippingInformation,
      }}>
      {children}
    </ShoppingCartContext.Provider>
  );
}
