import {
  ChatearVivo,
  DireccionContactanos,
  EnviarCorreo,
  FlechaDer,
  FondoContactenos,
  Llamanos,
  Whatsapp,
} from '../../../components/svg';
import { IMAGES } from '../../../config/images';

import styles from './contact-screen.module.scss';

export const ContactScreen = () => {
  const contactUs = [
    {
      key: 'k1',
      title: 'Llámanos',
      description:
        'Nuestro Servicio de Atención al Cliente están disponibles para atender todas tus dudas y sugerencias. <br/> <div class="mt-2">US / +1 (855) 210 3000</div>',
      icon: Llamanos,
      className: 'bubble-svc bubble-white',
    },
    {
      key: 'k2',
      title: 'Chatear en vivo',
      description:
        'Chatea con uno de nuestros agentes, busca la ventana de chat abajo a la derecha en esta misma página o pulsa el botón “Chatear”.',
      icon: ChatearVivo,
      className: 'bubble-svc bubble-white',
    },
    {
      key: 'k3',
      title: 'WhatsApp',
      description:
        'Chatea a través de la aplicación de WhatsApp, uno de nuestros agentes resolverá todas tus dudas.',
      icon: Whatsapp,
      className: 'bubble-svc bubble-white',
    },
    {
      key: 'k4',
      title: 'Email',
      description:
        'Envíanos un correo electrónico para remitirnos tus dudas o consultas. Te responderemos por mensaje o email lo antes posible. <br/> <div class="mt-2">ayuda@megaconecta.com</div>',
      icon: EnviarCorreo,
      className: 'bubble-svc bubble-white',
    },
  ];

  return (
    <div>
      <div className={styles.wrapper}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-3 d-none d-md-block">
              <img src={IMAGES.Promos.PromoAbstract1} className="img-fluid" />
            </div>
            <div className="col-12 col-md-6 text-center">
              <div className="d-flex flex-row">
                <img
                  src={IMAGES.Promos.PromoAbstract1}
                  className={`d-block d-md-none ${styles.imgContact}`}
                />
                <div className="d-none d-md-block">
                  <h1 className="text-decoration-underline text-secondary">¿Necesitas una mano?</h1>
                  <h1>Contáctenos</h1>
                </div>

                <div className="d-block d-md-none text-end">
                  <h3 className="text-decoration-underline text-secondary">¿Necesitas una mano?</h3>
                  <h4>Contáctenos</h4>
                </div>
              </div>

              <h6 className="mt-6">
                <DireccionContactanos width={25} /> Megaconecta Corp, Miami, Florida USA
              </h6>

              <p className="mt-6">
                Utiliza el medio de contacto que más te convenga, nuestros agentes te ayudarán paso
                a paso en todo lo que necesites.
              </p>
            </div>
            <div className="col-12 col-md-3"></div>
          </div>
        </div>
      </div>
      <div className={`container ${styles.wrapperColumns}`}>
        <div className="row">
          {contactUs.map((contact) => (
            <div
              className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-5 mb-lg-0"
              key={contact.key}>
              <div className={`${contact.className} ${styles.servicesSvg} px-7 px-lg-5`}>
                <div>
                  <contact.icon className="serviceIcon" />
                  <h6 className="mt-1">{contact.title}</h6>
                  <p
                    className={`mb-2 pDescription`}
                    dangerouslySetInnerHTML={{ __html: contact.description }}></p>

                  <div className="wrapperArrow">
                    <div className="arrowBody"></div>
                    <FlechaDer width={20} />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
