import * as React from 'react';
import { SVGProps } from 'react';
const SvgOjoTachado = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" {...props}>
    <g data-name="Capa 1">
      <path
        d="M22.5 11.5v-.02c-.02-.52-.17-1.05-.47-1.59-1.61-2.83-3.92-4.89-6.89-6.16-1.85-.79-3.78-1.02-5.76-.48-3.72 1.02-6.39 3.36-8.36 6.61-.34.55-.5 1.09-.51 1.62s0 .02 0 .02v.02c.02.53.18 1.06.51 1.62 1.97 3.25 4.64 5.59 8.36 6.61 1.98.54 3.91.32 5.76-.48 2.98-1.28 5.28-3.33 6.89-6.16.31-.54.46-1.07.47-1.59s0-.02 0-.02Z"
        style={{
          opacity: 0.4,
          fill: '#abadc4',
        }}
      />
      <path
        d="m21.46 20.04-6.75-6.75c.3-.53.48-1.14.48-1.79 0-2.04-1.65-3.69-3.69-3.69-.65 0-1.26.18-1.79.48L2.96 1.54a.996.996 0 1 0-1.41 1.41l18.5 18.5c.2.2.45.29.71.29s.51-.1.71-.29a.996.996 0 0 0 0-1.41Z"
        style={{
          fill: '#abadc4',
        }}
      />
    </g>
  </svg>
);
export default SvgOjoTachado;
