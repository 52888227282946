import { createContext, useEffect, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '../config/routes';
import { AccountServices, AuthService, StorageService } from '../services';
import { ReferralServices } from '../services/referral';
import { QueryTypes } from '../types/queryTypes';
import { MegaUser } from '../types/user';

export enum StorageAuthKey {
  token = 'msesstk',
  refreshToken = 'msessrftk',
  user = 'msess',
}

type ISessionContext = {
  setMegaUser: (_: MegaUser) => void;
  setCurrentToken: (_: string) => void;
  setCurrentRefreshToken: (_: string) => void;
  isAuthenticated: () => boolean;
  isUserUpdated: boolean;
  setIsUserUpdated: (_: boolean) => void;
  logOut: () => Promise<void>;
  getUser: () => MegaUser | null;
  getToken: () => string | null;
  getRefreshToken: () => string | null;
  referralBalance: number;
  userBalance: number | undefined;
  reloadReferralBalance: () => void;
  reloadUserBalance: () => void;
};

const SessionContext = createContext<ISessionContext>({
  setMegaUser: (_: MegaUser) => {},
  setCurrentToken: (_: string) => {},
  setCurrentRefreshToken: (_: string) => {},
  isAuthenticated: () => false,
  isUserUpdated: false,
  setIsUserUpdated: (_: boolean) => {},
  logOut: () => Promise.resolve(),
  getUser: () => null,
  getToken: () => '',
  getRefreshToken: () => '',
  userBalance: undefined,
  referralBalance: 0,
  reloadReferralBalance: () => {},
  reloadUserBalance: () => {},
});

const SessionProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const queryCache = useQueryClient();

  const navigate = useNavigate();

  const [isUserUpdated, setIsUserUpdated] = useState<boolean>(false);
  const [userBalance, setUserBalance] = useState<number>();
  const [referralBalance, setReferralBalance] = useState<number>(0);

  const setCurrentToken = (currentToken: string) => {
    StorageService.setItem(StorageAuthKey.token, currentToken);
  };

  const setCurrentRefreshToken = (currentRefreshToken: string) => {
    StorageService.setItem(StorageAuthKey.refreshToken, currentRefreshToken);
  };

  const setMegaUser = (user: MegaUser) => {
    StorageService.setItem(StorageAuthKey.user, user);
    setIsUserUpdated(true);
  };

  const getUserFromStorage = (): MegaUser | null => {
    return StorageService.getItem<MegaUser>(StorageAuthKey.user);
  };

  const getTokenFromStorage = (): string | null => {
    return StorageService.getItem<string>(StorageAuthKey.token);
  };

  const getRefreshTokenFromStorage = (): string | null => {
    return StorageService.getItem<string>(StorageAuthKey.token);
  };

  const isAuthenticated = () => {
    const authUser = getUserFromStorage();
    const token = getTokenFromStorage();
    const refreshToken = getRefreshTokenFromStorage();

    return !!authUser && !!authUser.USERNAME && !!token && !!refreshToken;
  };

  const logOut = async (ignoreApi?: boolean) => {
    try {
      !ignoreApi && (await AuthService.logOut());

      StorageService.removeItem(StorageAuthKey.token);
      StorageService.removeItem(StorageAuthKey.refreshToken);
      StorageService.removeItem(StorageAuthKey.user);

      navigate(ROUTES.login, { replace: true });
    } catch (_) {}
  };

  const balanceQuery = useQuery(QueryTypes.GetBalance, AccountServices.getBalance, {
    enabled: isAuthenticated(),
  });

  const referralBalanceQuery = useQuery(
    QueryTypes.GetReferralBalance,
    ReferralServices.getReferralBalance,
    {
      enabled: isAuthenticated(),
    }
  );

  useEffect(() => {
    if (balanceQuery.data) {
      setUserBalance(balanceQuery.data.balance);
    }
  }, [balanceQuery.data]);

  useEffect(() => {
    if (referralBalanceQuery.data) {
      setReferralBalance(referralBalanceQuery.data);
    }
  }, [referralBalanceQuery.data]);

  const reloadUserBalance = () => {
    balanceQuery.refetch();
  };

  const reloadReferralBalance = () => {
    // referralBalanceQuery.refetch();
    queryCache.invalidateQueries(QueryTypes.GetReferralBalance);
  };

  return (
    <SessionContext.Provider
      value={{
        setMegaUser,
        setCurrentToken,
        setCurrentRefreshToken,
        isAuthenticated,
        isUserUpdated,
        setIsUserUpdated,
        logOut,
        getUser: getUserFromStorage,
        getToken: getTokenFromStorage,
        getRefreshToken: getRefreshTokenFromStorage,
        userBalance,
        referralBalance,
        reloadUserBalance,
        reloadReferralBalance,
      }}>
      {children}
    </SessionContext.Provider>
  );
};

export { SessionContext, SessionProvider };

