import * as React from 'react';
import { SVGProps } from 'react';
const SvgCarnet = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" {...props}>
    <g data-name="Grupo 16749">
      <g data-name="Grupo 16748" fill="#8f979f">
        <path
          data-name="Trazado 34102"
          d="M20.125 20.257a2.667 2.667 0 0 0 2.61-2.309c.133-1.389.265-3.512.265-6.449s-.133-5.06-.265-6.449a2.435 2.435 0 0 0-.575-1.345 2.9 2.9 0 0 0-2.035-.973c-1.769-.115-4.591-.23-8.625-.23s-6.856.115-8.625.23a2.667 2.667 0 0 0-2.61 2.31C.133 6.448 0 8.563 0 11.5s.133 5.06.265 6.449a2.659 2.659 0 0 0 2.61 2.309c1.645.106 4.2.212 7.8.23l5.75-.062a99.75 99.75 0 0 0 3.7-.169Z"
          opacity={0.4}
        />
        <g data-name="Grupo 16747">
          <path
            data-name="Trazado 34103"
            d="M17.055 10.323c2.026 0 2.84 0 3.167-.221.212-.15.212-.4.212-.814 0-.469 0-.725-.3-.858a10.572 10.572 0 0 0-3.078-.177c-2.3 0-3.034 0-3.273.327a1.2 1.2 0 0 0-.115.708c0 .5 0 .752.363.885a12.378 12.378 0 0 0 3.017.15Z"
          />
          <path
            data-name="Trazado 34104"
            d="M17.055 12.676a13.983 13.983 0 0 0-2.963.133c-.425.133-.425.389-.425.911 0 .619 0 .867.708.964a23.847 23.847 0 0 0 2.68.071c2.008 0 2.831 0 3.158-.221.221-.15.221-.4.221-.823s0-.672-.23-.823c-.336-.212-1.15-.212-3.149-.212Z"
          />
          <path
            data-name="Trazado 34105"
            d="M11.279 14.896a5.057 5.057 0 0 0-6.953-1.619 5.344 5.344 0 0 0-.867.681 3.541 3.541 0 0 0-.778 1.026c-.23.451-.159.672.3.893a23.008 23.008 0 0 0 4.043.389 18.547 18.547 0 0 0 3.954-.372c.566-.283.619-.469.3-1Z"
          />
          <path
            data-name="Trazado 34106"
            d="M7.032 11.756A2.512 2.512 0 1 0 4.52 9.243a2.511 2.511 0 0 0 2.512 2.513Z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgCarnet;
