import * as React from 'react';
import { SVGProps } from 'react';
const SvgCartMas = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 33 33" {...props}>
    <g>
      <g data-name="fondo cantidad">
        <g data-name="Trazado 277">
          <path
            data-name="Trazado 34112"
            d="M16.5 1a66.839 66.839 0 0 0-8.96.39c-2.13.32-3.49.86-4.39 1.77a7.549 7.549 0 0 0-1.77 4.39 65.018 65.018 0 0 0-.39 8.96 66.75 66.75 0 0 0 .39 8.96c.32 2.13.86 3.49 1.77 4.39a7.549 7.549 0 0 0 4.39 1.77 65.018 65.018 0 0 0 8.96.39 66.75 66.75 0 0 0 8.96-.39c2.13-.32 3.49-.86 4.39-1.77a7.549 7.549 0 0 0 1.77-4.39 65.018 65.018 0 0 0 .39-8.96 66.75 66.75 0 0 0-.39-8.96c-.32-2.13-.86-3.49-1.77-4.39a7.549 7.549 0 0 0-4.39-1.77A65.018 65.018 0 0 0 16.5 1m0-1C31.97 0 33 1.03 33 16.5S31.97 33 16.5 33 0 31.97 0 16.5 1.03 0 16.5 0Z"
            fill="#8ab934"
          />
        </g>
      </g>
      <path
        data-name="Uni\xF3n 10"
        d="M15.09 23.07v-5.16H9.93a1.411 1.411 0 0 1 0-2.82h5.16V9.93a1.41 1.41 0 0 1 2.82 0v5.16h5.16a1.411 1.411 0 0 1 0 2.82h-5.16v5.16a1.41 1.41 0 0 1-2.82 0Z"
        fill="#163344"
      />
    </g>
  </svg>
);
export default SvgCartMas;
