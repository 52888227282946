import { yupResolver } from '@hookform/resolvers/yup';
import { useState } from 'react';
import { Alert, Button, Modal } from 'react-bootstrap';
import { Controller, FieldValues, useForm } from 'react-hook-form';
import { FaExclamationTriangle } from 'react-icons/fa';
import { IMaskInput } from 'react-imask';
import { ReferralServices } from '../../../../services/referral';
import { FormHelpers } from '../../../../utilities/FormHelpers';
import { SmallSpinner } from '../../../external/smallSpinner/smallSpinner';
import { referralBalanceWithdrawalFormSchema } from './referralBalanceWithdrawalFormSchema';

export const ReferralBalanceWithdrawalForm = ({
  afterSuccess,
  isDisabled,
}: {
  afterSuccess: Function;
  isDisabled?: boolean;
}) => {
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isModalVisible, setIsModalVisible] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>();

  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
    reset,
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(referralBalanceWithdrawalFormSchema),
  });

  const onWithdraw = async (formData: FieldValues) => {
    setIsProcessing(true);

    const { first_name, last_name, phone, email } = formData;

    try {
      await ReferralServices.withdrawByZelle({
        phone,
        email,
        full_name: `${first_name} ${last_name}`,
      });
      setIsModalVisible(false);
      afterSuccess();
    } catch (error) {
      let message = 'Ha ocurrido un error. Verifica que todos los campos sean correctos.';

      setErrorMessage(message);
    } finally {
      setIsProcessing(false);
    }
  };

  const clearForm = () => {
    reset();
    setIsModalVisible(false);
    setErrorMessage(undefined);
  };

  return (
    <>
      <Button
        variant="outline-secondary"
        onClick={() => setIsModalVisible(true)}
        disabled={isDisabled}>
        Retirar Comision
      </Button>
      <Modal show={isModalVisible} onHide={clearForm} backdrop="static" centered size="lg">
        <form noValidate onSubmit={handleSubmit(onWithdraw)}>
          <Modal.Header closeButton className="mega-modal-title">
            <Modal.Title>Retirar comisión</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div>
              Ingrese la información de la cuenta de Zelle donde desea transferir su balance de
              referidos. Se requiere el teléfono o correo electrónico asociado a la cuenta de Zelle.
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                <div className="mt-4 megaInput">
                  {FormHelpers.formLabel('Nombre', 'first_name')}
                  <input
                    type="text"
                    className={`form-control mega`}
                    id="first_name"
                    {...register('first_name')}
                  />
                  {FormHelpers.formError('first_name', errors)}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mt-4 megaInput">
                  {FormHelpers.formLabel('Apellido(s)', 'last_name')}
                  <input
                    type="text"
                    className={`form-control mega`}
                    id="last_name"
                    {...register('last_name')}
                  />
                  {FormHelpers.formError('last_name', errors)}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-md-6">
                <div className="mt-4 megaInput">
                  {FormHelpers.formLabel('Email', 'email')}
                  <input
                    type="email"
                    className={`form-control mega`}
                    id="email"
                    {...register('email')}
                  />
                  {FormHelpers.formError('email', errors)}
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="mt-4 megaInput">
                  {FormHelpers.formLabel('Teléfono', 'phone')}
                  <Controller
                    defaultValue={''}
                    control={control}
                    name="phone"
                    render={({ field: { onChange, name, value } }) => (
                      <IMaskInput
                        className="form-control mega"
                        mask={'(000) 000 - 0000'}
                        value={value}
                        onAccept={(_value, mask) => {
                          onChange(mask.unmaskedValue);
                          // setPhoneNumber(mask.unmaskedValue);
                        }}
                        lazy={false}
                      />
                    )}
                  />
                  {FormHelpers.formError('phone', errors)}
                </div>
              </div>
            </div>

            {errorMessage && (
              <div className="row mt-4">
                <div className="col-12">
                  <Alert variant={'danger'} className="my-2">
                    <FaExclamationTriangle /> {errorMessage}
                  </Alert>
                </div>
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="outline-secondary"
              className="px-5 me-4"
              disabled={isProcessing}
              type="button"
              onClick={clearForm}>
              Cancelar
            </Button>

            <Button
              variant="secondary text-white"
              className="px-5"
              type="submit"
              disabled={isProcessing}>
              {isProcessing ? <SmallSpinner /> : 'Retirar'}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};
