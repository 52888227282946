import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { ReportsConfig } from '../../../config/reportsConfig';
import { UtilService } from '../../../services';
import { ReportsService } from '../../../services/reports';
import { QueryTypes } from '../../../types/queryTypes';
import { MegaRemittanceReportItem } from '../../../types/reports/remittanceReportItem';
import { FlechaDer } from '../../svg';
import { MegaError } from '../megaError';
import { baseColumnDef, currencyColumnDef, statusColumnDef } from './baseColumnDef';
import { RemittanceReportItemDetails } from './remittanceReportItemDetails';
import { ReportDataGrid } from './reportDataGrid';
import { ReportDateSearch } from './reportDateSearch';

export const RemittanceReport: React.FC<{}> = ({ children }) => {
  const [reportItems, setReportItems] = useState<MegaRemittanceReportItem[]>([]);
  const [startDate, setStartDate] = useState<Date>(ReportsConfig.defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(ReportsConfig.defaultEndDate);
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [reportItemId, setReportItemId] = useState<number | undefined>();

  const remittanceReportQuery = useQuery(
    [QueryTypes.GetRemittanceReport, startDate, endDate],
    () => {
      return ReportsService.getRemittanceReport({ startDate, endDate });
    }
  );

  useEffect(() => {
    setIsSearching(remittanceReportQuery.isFetching);
  }, [remittanceReportQuery.isFetching]);

  useEffect(() => {
    if (remittanceReportQuery.status === 'success') {
      setReportItems(remittanceReportQuery.data);
    }
  }, [remittanceReportQuery.data]);

  const columns: GridColDef[] = [
    {
      ...baseColumnDef,
      field: 'created_date',
      headerName: 'Fecha',
      sortable: true,
      width: 180,
    },
    {
      ...baseColumnDef,
      field: 'full_name',
      headerName: 'Recibe',
      flex: 1,
      minWidth: 160,
    },
    {
      ...baseColumnDef,
      field: 'payment_type',
      headerName: 'Método',
      width: 100,
    },
    {
      ...statusColumnDef,
      field: 'status',
      headerName: 'Estado',
      width: 100,
    },
    {
      ...currencyColumnDef,
      field: 'paid',
      headerName: 'Pagado',
      width: 100,
    },
    {
      ...currencyColumnDef,
      field: 'received',
      headerName: 'A Recibir',
      width: 140,
      renderCell: (params: GridRenderCellParams<number>) => {
        return (
          <>
            ${params.value} {params.row.receivedCurrency}
          </>
        );
      },
    },
    {
      ...baseColumnDef,
      field: 'id',
      headerName: 'Acciones',
      renderCell: (params: GridRenderCellParams<number>) => (
        <strong>
          <Button
            variant="link"
            size="sm"
            tabIndex={params.hasFocus ? 0 : -1}
            className="text-secondary text-decoration-none"
            onClick={() => setReportItemId(params.value)}>
            Ver detalle
            <FlechaDer width={20} fill="#8ab934" className="ms-2" />
          </Button>
        </strong>
      ),
      width: 140,
    },
  ];

  const onSearch = (startDate: Date, endDate: Date) => {
    setStartDate(startDate);
    setEndDate(endDate);

    remittanceReportQuery.refetch({});
  };

  return (
    <>
      <div className="mt-5">
        <ReportDateSearch
          startDate={startDate}
          endDate={endDate}
          isSearching={isSearching}
          onSearch={onSearch}
        />
      </div>

      {children}

      <div className="mt-3 mb-5">
        {UtilService.isErrorQueries([remittanceReportQuery]) ? (
          <MegaError
            displayRetry={true}
            retry={() => {
              UtilService.refetchQueries([remittanceReportQuery]);
            }}
          />
        ) : (
          <ReportDataGrid<MegaRemittanceReportItem>
            columns={columns}
            rows={reportItems}
            isSearching={isSearching}
          />
        )}
      </div>

      <RemittanceReportItemDetails id={reportItemId} onHide={() => setReportItemId(undefined)} />
    </>
  );
};
