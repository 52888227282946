import { Button, FormCheck } from 'react-bootstrap';
import { Paypal, TarjetaCredito } from '../../svg';

import styles from './paymentMethodItem.module.scss';

export type PaymentMethodItemProps = {
  fullName: string;
  subtitle: string;
  paymentType: 'paypal' | 'card' | string;
  onSelect?: VoidFunction;
  onEdit?: VoidFunction;
  onDelete?: VoidFunction;
  onSaveMethodChange?: (saveMethod: boolean) => void;
  isNew?: boolean;
  isSelected?: boolean;
  saveMethod?: boolean;
  hideManageButtons?: boolean;
  hideCheckboxes?: boolean;
};

export const PaymentMethodItem = ({
  fullName,
  paymentType,
  subtitle,
  onSelect,
  onEdit,
  onDelete,
  onSaveMethodChange,
  isNew,
  isSelected,
  saveMethod,
  hideManageButtons,
  hideCheckboxes,
}: PaymentMethodItemProps) => {
  return (
    <div
      className={`d-flex flex-row ${styles.paymentMethod} ${
        isSelected || hideCheckboxes ? styles.selected : ''
      }`}
      onClick={() => !!onSelect && onSelect()}>
      {!hideCheckboxes && (
        <div className="me-2 me-lg-4 d-flex flex-column justify-content-lg-center mt-0 mt-lg--2">
          <input
            className="form-check-input"
            type="radio"
            name="paymentMethod"
            checked={isSelected}
            onChange={() => !!onSelect && onSelect()}
          />
        </div>
      )}
      <div className="flex-column flex-fill">
        <div>
          <h6 className="m-0 p-0">
            {paymentType.toLowerCase() === 'paypal' ? (
              <Paypal width={20} className="me-2 mt--1" />
            ) : (
              <TarjetaCredito width={20} className="me-2 mt--1" />
            )}
            {fullName}
          </h6>
        </div>
        <div className={styles.cc}>{subtitle}</div>
        {isNew && isSelected && (
          <div className="mt-2">
            <FormCheck
              type="switch"
              className="mega-check"
              checked={saveMethod}
              label="Guardar en mi cuenta"
              onChange={(e) => {
                e.stopPropagation();
                !!onSaveMethodChange && onSaveMethodChange(e.target.checked);
              }}
            />
          </div>
        )}
        {!hideManageButtons && (
          <div className={`d-flex flex-row justify-content-end align-items-end mt-0 mt-lg--4`}>
            {paymentType.toLowerCase() !== 'paypal' && (
              <Button
                variant="link"
                className={styles.ccButtons}
                onClick={() => !!onEdit && onEdit()}>
                Editar
              </Button>
            )}

            <Button
              variant="link"
              className={styles.ccButtons}
              onClick={() => !!onDelete && onDelete()}>
              Eliminar
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
