import { trimStart } from 'lodash';
import { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import { useQuery, useQueryClient } from 'react-query';
import { MegaError } from '../../../components/account/megaError';
import { SessionContext } from '../../../providers/session.provider';
import { AccountServices } from '../../../services';
import { MegaAddress } from '../../../types/profile';
import { QueryTypes } from '../../../types/queryTypes';

import {
  Correo,
  Direccion,
  FlechaDer,
  MiCuenta,
  NotificacionesSmsEmail,
} from '../../../components/svg';

import { useNavigate } from 'react-router-dom';
import { ProfilePaymentMethods } from '../../../components/account';
import { ROUTES } from '../../../config/routes';
import styles from './profile-screen.module.scss';

export const ProfileScreen = () => {
  const queryCache = useQueryClient();
  const { logOut } = useContext(SessionContext);
  const [isProfileEditing, setIsProfileEditing] = useState<boolean>(false);
  const navigate = useNavigate();

  // Queries
  const profileQuery = useQuery(QueryTypes.GetProfile, AccountServices.getProfileData);

  const getValueOrEmpty = (value: string | null | undefined, emptySpaceBefore?: boolean) => {
    return value ? (emptySpaceBefore ? ' ' : '') + value : '';
  };

  const getAddress1 = (a: MegaAddress) => {
    if (!a) {
      return '';
    }

    const stringAddress = `${getValueOrEmpty(a.address1)}${getValueOrEmpty(
      a.address2,
      true
    )}, ${getValueOrEmpty(a.city)}`;

    return trimStart(stringAddress, ', ');
  };

  const getAddress2 = (a: MegaAddress) => {
    if (!a) {
      return '';
    }

    const stringAddress = `${getValueOrEmpty(a.state)}, ${getValueOrEmpty(
      a.country
    )}${getValueOrEmpty(a.zip, true)}`;

    return trimStart(stringAddress, ', ');
  };

  const goToNavigate = (route: string) => {
    navigate(route);
  };

  const getNotification = (prof: any) => {
    let text = [];
    if (prof.receive_sms) {
      text.push('SMS');
    }

    if (prof.receive_emails) {
      text.push('Emails');
    }

    return text.join(' y ');
  };

  if (profileQuery.isError) {
    return <MegaError displayRetry retry={() => profileQuery.refetch()} />;
  }

  const profile = profileQuery.data?.user || ({} as any);
  const account = profileQuery.data?.account || ({} as any);

  return (
    <div className="bg-mega_light_gray">
      <div className="container py-5">
        <div className="row mb-5">
          <div className="col-12">
            <div className="d-flex flex-column flex-lg-row">
              <div className="d-flex flex-row flex-fill mb-5 mb-lg-0">
                <div className="d-inline">
                  <MiCuenta width={60} />
                </div>
                <div className="flex-column ms-2">
                  <h5 className="m-0 p-0">Hola, {profile.firstName + ' ' + profile.lastName}</h5>
                  <div>{profile.phone}</div>
                </div>
              </div>

              <div className="flex-column flex-fill">
                <div className={styles.profileDataNotif}>
                  <Correo width={20} /> <span>{profile.email}</span>
                </div>

                <div className={`mt-1 ${styles.profileDataNotif}`}>
                  <Direccion width={20} />{' '}
                  <span>
                    {getAddress1(profile.address)}
                    {!!getAddress1(profile.address) && ' '}
                    {getAddress2(profile.address)}
                  </span>
                </div>
              </div>

              {(profile.receive_emails || profile.receive_sms) && (
                <div className="flex-column flex-fill">
                  <div className={styles.profileDataNotif}>
                    <NotificacionesSmsEmail width={20} />{' '}
                    <span>Notificaciones por ({getNotification(profile)})</span>
                  </div>
                </div>
              )}

              <div className="d-flex align-items-center justify-content-center justify-content-lg-end flex-fill mt-5 mt-lg-0">
                <Button
                  variant="secondary"
                  onClick={() => goToNavigate(ROUTES.cuenta.fondos)}
                  className={`d-block d-lg-none ${styles.buttonProfileSaldo}`}>
                  Agregar Saldo
                </Button>
                <Button
                  variant="outline-secondary"
                  onClick={() => goToNavigate(ROUTES.cuenta.editarCuenta)}
                  className={styles.buttonProfileCuenta}>
                  Editar Cuenta
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12">
            <div className="column-alert">
              <div className="item-column w3">
                <h3 className="text-secondary d-none d-md-block">${account.balance_format}</h3>
                <h5 className={`text-secondary d-block d-md-none ${styles.h5Saldo}`}>
                  ${account.balance_format}
                </h5>
                <span>Mi Saldo</span>
              </div>
              <div className="item-column w3">
                <h3 className="d-none d-md-block">{account.sms_free_out}</h3>
                <h5 className={`d-block d-md-none ${styles.h5Saldo}`}>{account.sms_free_out}</h5>
                <span>SMS Gratis Para Cuba</span>
              </div>
              <div className="item-column w3">
                <h3 className="d-none d-md-block">{account.free_min_cuba}</h3>
                <h5 className={`d-block d-md-none ${styles.h5Saldo}`}>{account.free_min_cuba}</h5>
                <span>Minutos gratis para Cuba</span>
              </div>
            </div>
          </div>
        </div>

        <div className="column-alert mt-4">
          <ProfilePaymentMethods
            onSelectPaymentMethod={(item) => {}}
            showResponsive={true}
            hideCheckboxes={true}
            onlySavePmOnDb={true}
          />
        </div>

        <div className="row mt-5">
          <div className="col-12 col-md-2"></div>
          <div className="col-12 col-md-8">
            <div className="column-alert d-flex flex-row align-items-center justify-content-center p-3">
              <div>
                Megaconecta le permite ganar&nbsp;<b>$5 USD</b>&nbsp;por cada nuevo cliente que
                usted refiera.
                <br className="d-block d-md-none" />
                <Button
                  variant="link"
                  onClick={() => goToNavigate(ROUTES.cuenta.referirAmigo)}
                  className="text-secondary text-decoration-none px-0 ms-0 ms-md-2">
                  Referir Amigo <FlechaDer width={20} fill={'#8ab934'} className="ms-1" />
                </Button>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-2"></div>
        </div>
      </div>
    </div>
  );
};
