export const TermsAndConditionsScreen = () => {
  return (
    <>
      <div className="container">
        <h1 className="mt-6">Términos y Condiciones</h1>

        <div className="mt-4">
          <span className="text-secondary mega-font-bold">MegaConecta</span> es un servicio de Mega
          Connect Corp. El objetivo del Mega Connect Corp es proveer el servicio de telefonía de la
          más alta calidad con las mejores tarifas. El uso de este sitio web está expresamente
          sujeto a la aceptación de todos los términos y condiciones establecidos en el presente
          documento y en cualquier otro lugar de este sitio Web. No utilice este sitio web, si no
          acepta todos los términos y condiciones establecidos en el presente documento.
        </div>

        <div className="mt-4">
          Es una ofensa federal y estatal el comprar cualquier producto o servicio por medios
          fraudulentos. Los productos y/o servicios adquiridos en este sitio Web no se deberán ser
          utilizados para ningún propósito ilegal. Al usar este sitio web, usted admite tener 18
          anos de edad, ser mayor o poseer el consentimiento de sus padres o tutores y que son
          plenamente capaces y competentes para entender los términos, condiciones, representaciones
          y garantías establecidos en los términos y condiciones de lo contrario, por favor deje el
          sitio web.
        </div>

        <div className="mt-4">
          Al utilizar el servicio de llamadas de prepago de{' '}
          <span className="text-secondary mega-font-bold">MegaConecta</span>, el usuario acepta las
          tarifas, términos y condiciones establecidos en este sitio Web.{' '}
          <span className="text-secondary mega-font-bold">MegaConecta</span> puede cambiar o
          modificar los términos sin más aviso que publicar los términos modificados en el sitio
          web. <span className="text-secondary mega-font-bold">MegaConecta</span> se reserva el
          derecho de cambiar, modificar o discontinuar temporal o permanentemente el sitio Web (o
          cualquier parte del mismo), incluyendo cualquier y todo el contenido en el sitio web, en
          cualquier momento sin previo aviso. Usted acepta que
          <span className="text-secondary mega-font-bold">MegaConecta</span> no será responsable
          ante usted o terceros por cualquier modificación, suspensión o interrupción del sitio web.
        </div>

        <div className="mt-4">
          Siempre que usted proporciona información en nuestro sitio web, usted acepta: (a)
          proporcionar información verdadera, precisa, actual y completa y (b) mantener y actualizar
          dicha información a la brevedad posible para mantenerla verdadera, precisa, actual y
          completa. Si usted brinda cualquier información o tengamos motivos razonables para
          sospechar que la información es falsa, inexacta, no actual o incompleta,{' '}
          <span className="text-secondary mega-font-bold">MegaConecta</span> puede, sin previo
          aviso, suspender o cancelar su cuenta y negarle todo uso actual o futuro de nuestro sitio
          web (o cualquier parte del mismo). Por favor, seleccione una contraseña que no sería
          evidente a cualquiera que intente adivinarla, y cámbiela regularmente como una precaución
          adicional. Además, le recomendamos utilizar una contraseña diferente de la cuenta de
          correo electrónico como una medida de seguridad adicional. Usted es responsable de
          mantener la confidencialidad de la contraseña y la cuenta, y usted es plenamente
          responsable de todas las actividades que ocurran bajo su cuenta.Usted acepta notificar
          inmediatamente a <span className="text-secondary mega-font-bold">MegaConecta</span> de
          cualquier uso no autorizado de su contraseña o cuenta o cualquier otra violación de
          seguridad.
        </div>

        <div className="mt-4">
          Mediante la aceptación por su parte de los Términos y Condiciones expuestos acá por{' '}
          <span className="text-secondary mega-font-bold">MegaConecta</span>, usted da su
          consentimiento a la compañía para que le sean enviados e-mails y mensajes de texto (SMS)
          con anuncios y promociones válidas y de utilidad para cada cliente. A pesar de ello, usted
          tendrá la opción de eliminar su suscripción en el momento en que lo requiera.
        </div>

        <div className="mt-4">
          Nuestro sitios web se comunican a través del protocolo Secure Socket Layer mediante
          certificados para garantizar la protección de sus datos personales.
        </div>

        <div className="mt-4">
          Acuerdo de llamadas prepago de{' '}
          <span className="text-secondary mega-font-bold">MegaConecta</span> - sujeto a cambio en
          cualquier momento sin previo aviso.
        </div>

        <div className="mt-4 mb-6">
          <ul>
            <li>Las tarifas pueden variar y están sujetas a cambios sin previo aviso. </li>
            <li>
              Las llamadas de teléfonos están disponibles sólo a través de los números de acceso
              local.
            </li>
            <li>Las cuentas inactivas por mas de tres meses seran cerradas.</li>
            <li>
              Al utilizar los números de acceso local, el usuario acepta los cargos por la llamada
              local a nuestro número desde su compañía telefónica.{' '}
            </li>
            <li>
              Las llamadas serán facturadas en incrementos de un minuto, con fracciones redondeadas
              al minuto.
            </li>
            <li>
              No aceptamos reclamaciones de llamadas despues de transcurrido 48 horas de la misma.
            </li>
          </ul>
        </div>
      </div>
    </>
  );
};
