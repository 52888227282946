import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { GridColDef } from '@mui/x-data-grid';
import { QueryTypes } from '../../../types/queryTypes';
import { MegaCallReportItem } from '../../../types/reports/callReportItem';
import { baseColumnDef, currencyColumnDef } from './baseColumnDef';

import { ReportDataGrid } from './reportDataGrid';
import { ReportDateAndNumberSearch } from './reportDateAndNumberSearch';

import { ReportsConfig } from '../../../config/reportsConfig';
import { UtilService } from '../../../services';
import { ReportsService } from '../../../services/reports';
import { MegaError } from '../megaError';

export const CallReport: React.FC<{}> = ({ children }) => {
  const [reportItems, setReportItems] = useState<MegaCallReportItem[]>([]);
  const [startDate, setStartDate] = useState<Date>(ReportsConfig.defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(ReportsConfig.defaultEndDate);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const callReportQuery = useQuery(
    [QueryTypes.GetCallReport, startDate, endDate, phoneNumber],
    () => {
      return ReportsService.getCallReport({ startDate, endDate, phoneNumber });
    },
    {
      cacheTime: 0,
    }
  );

  useEffect(() => {
    setIsSearching(callReportQuery.isFetching);
  }, [callReportQuery.isFetching]);

  useEffect(() => {
    if (callReportQuery.status === 'success') {
      setReportItems(callReportQuery.data);
    }
  }, [callReportQuery.data]);

  const columns: GridColDef[] = [
    {
      ...baseColumnDef,
      field: 'call_connect_time',
      headerName: 'Fecha',
      sortable: true,
      width: 180,
    },
    {
      ...baseColumnDef,
      field: 'called_number',
      headerName: 'Número marcado',
      width: 180,
    },
    {
      ...baseColumnDef,
      field: 'duration',
      headerName: 'Duración',
      width: 120,
    },
    {
      ...currencyColumnDef,
      field: 'cost_before_fees',
      headerName: 'Costo',
      width: 120,
    },
    {
      ...baseColumnDef,
      field: 'rate_table',
      flex: 1,
      headerName: 'Descripción',
    },
  ];

  const onSearch = (startDate: Date, endDate: Date, phone: string) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setPhoneNumber(phone);

    callReportQuery.refetch({});
  };

  return (
    <>
      <div className="mt-5">
        <ReportDateAndNumberSearch
          startDate={startDate}
          endDate={endDate}
          isSearching={isSearching}
          phoneNumber={phoneNumber}
          onSearch={onSearch}
        />
      </div>

      {children}

      <div className="mt-3 mb-5">
        {UtilService.isErrorQueries([callReportQuery]) ? (
          <MegaError
            displayRetry={true}
            retry={() => {
              UtilService.refetchQueries([callReportQuery]);
            }}
          />
        ) : (
          <ReportDataGrid<MegaCallReportItem>
            columns={columns}
            getRowId={(row: MegaCallReportItem) => row.call_connect_time}
            rows={reportItems}
            isSearching={isSearching}
          />
        )}
      </div>
    </>
  );
};
