import * as React from 'react';
import { SVGProps } from 'react';
const SvgComentario = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={60} height={60} {...props}>
    <g data-name="Grupo 16711">
      <path
        data-name="Trazado 34074"
        d="M20.37 22.22a2.567 2.567 0 0 1 2.2-1.66c2.28-.16 5.32-1.3 5.74-2.63.86-2.75 1.99-5.41 2.76-8.19.91-3.27 2.39-4.52 5.03-4.52 3.51 0 6.18 1.82 7.05 4.74a18.225 18.225 0 0 1 .52 5.15c.02 2.04.24 2.29 2.1 1.88a26.654 26.654 0 0 1 5.42-.35c3.86-.05 6.62 2.23 6.8 6.01.37 7.69-1.79 15.33-4.32 22.7a7.306 7.306 0 0 1-6.93 5.05c-7.69.25-19.35-.07-22.32-1.56-3.98-1.99-6.22-20.71-4.05-26.62Z"
        fill="#ffdbe6"
      />
      <path
        data-name="Trazado 34075"
        d="M21.35 49.54a53.55 53.55 0 0 1-13.91.3c-1.6-.2-3.01-1.84-3.54-4.2a86.8 86.8 0 0 1-2.19-18.76c-.03-3.15 1.49-5.77 3.5-6.12a71.456 71.456 0 0 1 15.47-1.12c2 .09 3.69 2.46 3.94 5.55a162.76 162.76 0 0 1 .31 18.62c-.11 3.04-1.62 5.48-3.58 5.75Z"
        fill="#fe4a81"
      />
      <path
        data-name="Trazado 34076"
        d="M59.08 22.59c-.2-4.23-3.31-7.05-7.76-7.05h-1.01a22.269 22.269 0 0 0-4.77.37 5.722 5.722 0 0 1-.73.13c-.02-.18-.04-.47-.05-.96v-.14a19.007 19.007 0 0 0-.57-5.3c-1.01-3.4-4.11-5.52-8.09-5.52-3.18 0-5.05 1.64-6.07 5.32-.43 1.55-.99 3.11-1.53 4.61a86.86 86.86 0 0 0-1.22 3.55c-.17.54-2.14 1.53-4.23 1.82a4.009 4.009 0 0 0-2.31-.88 72.334 72.334 0 0 0-15.71 1.14c-2.58.45-4.43 3.48-4.4 7.2a87.71 87.71 0 0 0 2.22 18.99c.64 2.81 2.39 4.79 4.47 5.04a45.091 45.091 0 0 0 5.64.34 61.743 61.743 0 0 0 8.55-.64 4.161 4.161 0 0 0 2.11-.99 3.371 3.371 0 0 0 .32.19c2.65 1.33 10.85 1.76 17.79 1.76 1.81 0 3.54-.03 5.06-.08a8.363 8.363 0 0 0 7.92-5.79c2.5-7.29 4.76-15.13 4.38-23.11ZM21.21 48.46a51.762 51.762 0 0 1-13.63.29c-1.14-.14-2.19-1.49-2.62-3.37A84.718 84.718 0 0 1 2.8 26.85c-.02-2.53 1.15-4.79 2.6-5.04A72.372 72.372 0 0 1 18 20.65q1.32 0 2.64.06c1.42.06 2.7 2.06 2.9 4.55a158.433 158.433 0 0 1 .3 18.49c-.09 2.45-1.25 4.52-2.64 4.71Zm31.43-3.47a6.262 6.262 0 0 1-5.94 4.32c-8.47.28-19.12-.19-21.74-1.42a9.367 9.367 0 0 0 1.05-4.06 162.629 162.629 0 0 0-.31-18.74 9.458 9.458 0 0 0-1.08-3.77c2.05-.51 4.25-1.55 4.72-3.07.36-1.16.78-2.33 1.19-3.46.55-1.54 1.13-3.14 1.58-4.77.87-3.11 2.11-3.72 3.98-3.72 3.03 0 5.27 1.48 6.01 3.96a17.33 17.33 0 0 1 .48 4.71v.14c.01 1.19.08 2.15.8 2.73a3.023 3.023 0 0 0 2.62.21 20.457 20.457 0 0 1 4.32-.32h.88c3.37-.07 5.55 1.86 5.7 4.98.36 7.62-1.83 15.22-4.26 22.3Z"
        fill="#263645"
      />
      <path
        data-name="Trazado 190"
        d="M16.51 32.9c1.62-.17 2.44 1.59 2.93 6.22s.04 6.53-1.58 6.69-2.44-1.59-2.93-6.22-.04-6.53 1.58-6.69Z"
        fill="#fff"
      />
    </g>
  </svg>
);
export default SvgComentario;
