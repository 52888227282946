import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { toLower } from 'lodash';
import * as nameCase from 'namecase';
import { Formatter } from '../../../utilities/Formatter';
import { translations } from '../../../utilities/translations';

export const baseColumnDef: GridColDef = {
  disableColumnMenu: true,
  field: '',
  filterable: false,
  resizable: false,
  sortable: false,
  headerClassName: 'super-app-theme--header',
};

export const currencyColumnDef: GridColDef = {
  ...baseColumnDef,
  renderCell: (params: GridRenderCellParams<string>) => Formatter.toCurrency(params.value!),
};

export const referralCategoryColumnDef: GridColDef = {
  ...baseColumnDef,
  renderCell: (params: GridRenderCellParams<string>) =>
    translations.referralCategories[params.value!],
};

export const statusColumnDef: GridColDef = {
  ...baseColumnDef,
  renderCell: (params: GridRenderCellParams<string>) => {
    return getStatusColors(params.value || '');
  },
};

export const caseColumnDef: GridColDef = {
  ...baseColumnDef,
  renderCell: (params: GridRenderCellParams<string>) => nameCase(params.value || ''),
};

export const getStatusColors = (status: string) => {
  switch (toLower(status)) {
    case 'approved':
    case 'completed':
      return <span className="badge bg-mega_approved text-secondary">{nameCase(status)}</span>;

    case 'pending':
    case 'in process':
      return <span className="badge bg-mega_pending text-warning">{nameCase(status)}</span>;

    case 'en camino':
      return <span className="badge bg-mega_en_camino text-info">{nameCase(status)}</span>;

    case 'returned':
    case 'canceled':
      return (
        <span className="badge bg-mega_returned text-mega_returned_color">{nameCase(status)}</span>
      );
  }

  return <>{nameCase(status)}</>;
};
