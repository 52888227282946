import ImageLogo from '../assets/images/Logo_megaconecta.svg';
import NautaLogo from '../assets/images/nauta.webp';
import UcImage from '../assets/images/uc.png';

import CallHeaderLogo from '../assets/images/CallHeaderLogo.png';
import CombosHeaderLogo from '../assets/images/CombosHeaderLogo.png';
import NautaHeaderLogo from '../assets/images/NautaHeaderLogo.png';
import TopupHeaderLogo from '../assets/images/TopupHeaderLogo.png';

import AddFunds from '../assets/images/AddFunds.png';
import BannerDescarga from '../assets/images/BannerDescarga.png';
import CreateAccount from '../assets/images/CreateAccount.png';
import MakeCalls from '../assets/images/MakeCalls.png';

import AppStore from '../assets/images/app-store.png';
import PlayStore from '../assets/images/play-store.png';

import LandingCalls from '../assets/images/LandingCalls.png';
import LandingCombos from '../assets/images/LandingCombos.png';
import LandingNauta from '../assets/images/LandingNauta.png';
import LandingSMS from '../assets/images/LandingSMS.png';
import LandingTopups from '../assets/images/LandingTopups.png';

import Badge from '../assets/images/Badge.png';
import Broadcast from '../assets/images/Broadcast.png';
import Calling from '../assets/images/Calling.png';
import Chart from '../assets/images/Chart.png';
import Connection from '../assets/images/Connection.png';
import CreditCards from '../assets/images/CreditCards.png';
import CustomerService from '../assets/images/CustomerService.png';
import Tags from '../assets/images/Tags.png';

import PromoAbstract1 from '../assets/images/promos/abstract1.webp';
import PromoCombos from '../assets/images/promos/Combos.webp';
import PromoDescargaApp from '../assets/images/promos/Descarga_App.webp';
import PromoLlamadas from '../assets/images/promos/Llamadas.webp';
import PromoMensajes from '../assets/images/promos/Mensajes.webp';
import PromoNauta from '../assets/images/promos/Nauta.webp';
import PromoOperadores from '../assets/images/promos/operadores.webp';
import PromoPromocion from '../assets/images/promos/Promocion.webp';
import PromoRecarga from '../assets/images/promos/Recarga.webp';
import PromoRegalo from '../assets/images/promos/Regalo.webp';
import PromoTarifas from '../assets/images/promos/Tarifas.webp';

import Review1 from '../assets/images/reviews/review1.png';
import Review2 from '../assets/images/reviews/review2.png';
import Review3 from '../assets/images/reviews/review3.png';

import Delivery from '../assets/images/delivery.png';
import Eye from '../assets/images/eye.png';

import Aseo from '../assets/images/food-categories/aseo.png';
import Buffet from '../assets/images/food-categories/buffet.png';
import ComidaPreparada from '../assets/images/food-categories/comida-preparada.png';
import Comida from '../assets/images/food-categories/comida.png';
import Mixto from '../assets/images/food-categories/mixto.png';
import Todas from '../assets/images/food-categories/todas.png';

export const IMAGES = {
  ImageLogo,
  NautaLogo,
  UcImage,

  CallHeaderLogo,
  TopupHeaderLogo,
  NautaHeaderLogo,
  CombosHeaderLogo,

  CreateAccount,
  AddFunds,
  MakeCalls,

  BannerDescarga,
  PlayStore,
  AppStore,

  LandingCalls,
  LandingTopups,
  LandingSMS,
  LandingCombos,
  LandingNauta,

  Calling,
  Chart,
  CreditCards,
  Tags,
  CustomerService,
  Badge,
  Broadcast,
  Connection,

  Eye,
  Delivery,

  Promos: {
    PromoAbstract1,
    PromoCombos,
    PromoDescargaApp,
    PromoLlamadas,
    PromoMensajes,
    PromoNauta,
    PromoOperadores,
    PromoPromocion,
    PromoRecarga,
    PromoRegalo,
    PromoTarifas,
  },

  Reviews: {
    Review1,
    Review2,
    Review3,
  },

  Combos: {
    Todas,
    Aseo,
    Comida,
    ComidaPreparada,
    Mixto,
    Buffet,
  },
};
