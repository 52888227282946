import * as React from 'react';
import { SVGProps } from 'react';
const SvgMiCuentaUser = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="mi_cuenta_user_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    {...props}>
    <defs>
      <clipPath id="mi_cuenta_user_svg__clippath">
        <path className="mi_cuenta_user_svg__cls-7" d="M0 0h23v23H0z" />
      </clipPath>
      <clipPath id="mi_cuenta_user_svg__clippath-1">
        <path className="mi_cuenta_user_svg__cls-7" d="M0 0h23v23H0z" />
      </clipPath>
      <clipPath id="mi_cuenta_user_svg__clippath-2">
        <path className="mi_cuenta_user_svg__cls-7" d="M0 0h23v23H0z" />
      </clipPath>
      <clipPath id="mi_cuenta_user_svg__clippath-3">
        <path className="mi_cuenta_user_svg__cls-7" d="M.5.5h22v22H.5z" />
      </clipPath>
      <style>
        {'.mi_cuenta_user_svg__cls-1{fill:#abadc4}.mi_cuenta_user_svg__cls-7{fill:none}'}
      </style>
    </defs>
    <g
      style={{
        clipPath: 'url(#mi_cuenta_user_svg__clippath)',
      }}
      id="mi_cuenta_user_svg__Capa_1-2"
      data-name="Capa 1">
      <g id="mi_cuenta_user_svg__Grupo_5626" data-name="Grupo 5626">
        <g
          style={{
            clipPath: 'url(#mi_cuenta_user_svg__clippath-1)',
          }}
          id="mi_cuenta_user_svg__Grupo_5625"
          data-name="Grupo 5625">
          <g id="mi_cuenta_user_svg__Grupo_5624" data-name="Grupo 5624">
            <g
              id="mi_cuenta_user_svg__Grupo_5623"
              data-name="Grupo 5623"
              style={{
                opacity: 0.4,
              }}>
              <g
                style={{
                  clipPath: 'url(#mi_cuenta_user_svg__clippath-3)',
                }}
                id="mi_cuenta_user_svg__Grupo_5622"
                data-name="Grupo 5622">
                <g id="mi_cuenta_user_svg__Grupo_5621" data-name="Grupo 5621">
                  <path
                    id="mi_cuenta_user_svg__Trazado_10944"
                    data-name="Trazado 10944"
                    className="mi_cuenta_user_svg__cls-1"
                    d="M11.5.5c5.37 0 8.21.58 9.65 2.87C22.2 5.03 22.5 7.6 22.5 11.5c0 4.96-.49 7.76-2.37 9.29-1.63 1.33-4.32 1.71-8.63 1.71s-6.74-.34-8.39-1.52C1.03 19.49.5 16.67.5 11.5c0-4.04.33-6.64 1.46-8.3C3.43 1.05 6.26.5 11.5.5"
                  />
                </g>
              </g>
            </g>
            <path
              id="mi_cuenta_user_svg__Trazado_10945"
              data-name="Trazado 10945"
              className="mi_cuenta_user_svg__cls-1"
              d="M18.21 16.85a7.942 7.942 0 0 0-12.3-1.47c-.5.46-.92 1.01-1.23 1.61-.36.71-.25 1.06.47 1.4 2.1.39 4.23.6 6.36.61 2.09.02 4.18-.17 6.23-.59.89-.44.97-.73.47-1.57"
            />
            <path
              id="mi_cuenta_user_svg__Trazado_10946"
              data-name="Trazado 10946"
              className="mi_cuenta_user_svg__cls-1"
              d="M11.52 11.91c2.19 0 3.96-1.77 3.96-3.96s-1.77-3.96-3.96-3.96-3.96 1.77-3.96 3.96 1.77 3.96 3.96 3.96"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default SvgMiCuentaUser;
