import * as React from 'react';
import { SVGProps } from 'react';
const SvgPalomAzul = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" {...props}>
    <g data-name="Capa 1">
      <g data-name="Grupo 5127">
        <circle
          data-name="Elipse 16"
          cx={11.5}
          cy={11.5}
          r={11.5}
          style={{
            fill: '#5586f7',
          }}
        />
        <path
          d="M10 16.34c-.28 0-.54-.11-.74-.31L5.13 11.9c-.41-.41-.41-1.07 0-1.48s1.07-.41 1.48 0L10 13.81l6.39-6.39c.41-.41 1.07-.41 1.48 0s.41 1.07 0 1.48l-7.13 7.13c-.2.2-.46.31-.74.31Z"
          style={{
            fill: '#fff',
          }}
        />
      </g>
    </g>
  </svg>
);
export default SvgPalomAzul;
