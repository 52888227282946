import * as React from 'react';
import { SVGProps } from 'react';
const SvgRecargarDatosInactive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="recargar_datos_inactive_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    {...props}>
    <defs>
      <style>{'.recargar_datos_inactive_svg__cls-2{opacity:.4}'}</style>
    </defs>
    <g id="recargar_datos_inactive_svg__Capa_1-2" data-name="Capa 1">
      <path
        className="recargar_datos_inactive_svg__cls-2"
        d="M8.38 0C5.09 0 2.45 1.14.46 2.7c-.49.38-.59 1.06-.29 1.59l.5.88c.41.71 1.35.86 2 .4C4.2 4.48 6.15 3.61 8.38 3.61s4.18.88 5.71 1.96c.65.46 1.59.32 2-.4l.5-.88c.3-.53.2-1.2-.29-1.59C14.31 1.14 11.67 0 8.37 0Z"
      />
      <path
        className="recargar_datos_inactive_svg__cls-2"
        d="M8.38 5.66c-2.03 0-3.44.56-4.62 1.45-.52.39-.59 1.12-.24 1.64l.49.74c.38.57 1.17.64 1.72.3.69-.43 1.6-.79 2.64-.79s1.95.37 2.64.79c.55.34 1.34.27 1.72-.3l.49-.74c.35-.52.28-1.25-.24-1.64-1.18-.89-2.58-1.45-4.62-1.45Z"
      />
      <path
        className="recargar_datos_inactive_svg__cls-1"
        d="M8.37 10.92a1.799 1.799 0 1 0 1.8 1.8c0-1-.81-1.8-1.8-1.8ZM22.82 10.21a2.2 2.2 0 0 0-.28-.92c-.51-.91-1.72-1.17-2.64-.67-4.74 2.53-10.3 8.23-12.29 11.09-.84 1.21-.11 2.96 1.36 3.1H9c1.09.09 3.28.18 5.74.18s4.96-.09 6.05-.18c1.1-.09 1.94-.93 2.03-2.03.09-1.09.18-2.82.18-5.29s-.09-4.2-.18-5.29ZM19.22 19c-.88 0-1.15-.75-1.15-3.21s.27-3.21 1.15-3.21 1.15.75 1.15 3.21S20.1 19 19.22 19Z"
      />
    </g>
  </svg>
);
export default SvgRecargarDatosInactive;
