import * as React from 'react';
import { SVGProps } from 'react';
const SvgLogoM = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 49.6 50.26" {...props}>
    <path
      d="M25 0C12.88-.25 2.65 8.1 0 19.36c-.03.13.07.25.2.25h5.04c.09 0 .17-.06.19-.14C7.89 11.05 15.67 4.89 24.87 4.89s16.79 5.88 19.37 14.35c.04.13-.06.26-.2.26h-4.56c-.08 0-.15-.05-.18-.13a15.554 15.554 0 0 0-5.54-6.98 15.423 15.423 0 0 0-9.87-2.77c-7.95.49-14.31 7.07-14.55 15.03-.27 8.78 6.8 16.02 15.53 16.02 3.2 0 6.27-.97 8.89-2.8 2.5-1.75 4.41-4.16 5.54-6.98.03-.08.1-.13.18-.13h4.56c.14 0 .23.13.2.26-2.58 8.47-10.49 14.35-19.37 14.35S7.89 39.2 5.43 30.79c-.02-.09-.1-.14-.19-.14H.2c-.13 0-.23.12-.2.25C2.61 42 12.57 50.26 24.47 50.26c14.08 0 25.46-11.58 25.12-25.74C49.28 11.17 38.37.28 25 0Zm9 33.23c0 .13-.11.24-.24.24h-3.55c-.13 0-.24-.11-.24-.24V21.74c0-.17-.18-.29-.34-.22-.96.41-1.84.98-2.6 1.68a9.033 9.033 0 0 0-2.34 3.54c-.08.22-.38.22-.46 0a8.926 8.926 0 0 0-2.34-3.54c-.76-.7-1.63-1.27-2.59-1.68a.244.244 0 0 0-.34.22v11.49c0 .13-.11.24-.24.24h-3.55c-.13 0-.24-.11-.24-.24v-16.2c0-.13.1-.23.23-.24a12.825 12.825 0 0 1 3.82.37c2 .5 3.82 1.46 5.33 2.77.09.08.23.08.32 0 1.51-1.31 3.33-2.27 5.33-2.77 1.02-.26 2.08-.39 3.18-.39.21 0 .43 0 .64.02.13 0 .23.11.23.24v16.2Z"
      style={{
        fill: '#8ab934',
      }}
      data-name="Capa 1"
    />
  </svg>
);
export default SvgLogoM;
