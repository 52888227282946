import * as yup from 'yup';
import { errorMessages } from '../../../utilities/errorMessages';

export const editProfileSchema = yup
  .object()
  .shape({
    firstName: yup.string().required(errorMessages.firstName),
    lastName: yup.string().required(errorMessages.lastName),
    email: yup.string().email(errorMessages.email.format).required(errorMessages.email.required),
    address1: yup.string().required(errorMessages.address1),
    city: yup.string().required(errorMessages.city),
    state: yup.string().required(errorMessages.state),
    country: yup.object().required(errorMessages.country),
    zipCode: yup.string().required(errorMessages.zipCode),
    receiveEmail: yup.boolean(),
    receiveSms: yup.boolean(),
    changePassword: yup.boolean(),
    password: yup.string().when('changePassword', {
      is: true,
      then: yup.string().required(errorMessages.password.required),
      otherwise: yup.string().optional(),
    }),
    passwordConfirmation: yup
      .string()
      .when('changePassword', {
        is: true,
        then: yup.string().required(errorMessages.password.required),
        otherwise: yup.string().optional(),
      })
      .oneOf([yup.ref('password')], 'Password do not match'),
  })
  .required();
