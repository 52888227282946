import * as React from 'react';
import { SVGProps } from 'react';
const SvgGooglePlay = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="google_play_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 128.42 32.15"
    {...props}>
    <defs>
      <clipPath id="google_play_svg__clippath">
        <path
          className="google_play_svg__cls-7"
          d="M.48 1.19c-.34.41-.51.92-.48 1.45v22.87c-.03.53.14 1.05.48 1.45l.08.08 12.81-12.81v-.3L.56 1.12l-.08.08Z"
        />
      </clipPath>
      <clipPath id="google_play_svg__clippath-1">
        <path
          className="google_play_svg__cls-7"
          d="M14.92 13.92v.3l4.27 4.27.1-.06 5.06-2.87c1.44-.82 1.44-2.17 0-2.99L19.29 9.7l-.1-.06-4.27 4.27Z"
        />
      </clipPath>
      <clipPath id="google_play_svg__clippath-2">
        <path
          className="google_play_svg__cls-7"
          d="M1.26 27.74c.61.54 1.51.56 2.15.06l15.11-8.58-4.37-4.37L1.26 27.74Z"
        />
      </clipPath>
      <clipPath id="google_play_svg__clippath-3">
        <path
          className="google_play_svg__cls-7"
          d="M1.26.41 14.15 13.3l4.37-4.37L3.41.35C3.06.13 2.66.01 2.25 0h-.03c-.36 0-.71.15-.96.41"
        />
      </clipPath>
      <style>{'.google_play_svg__cls-1{fill:#fff}.google_play_svg__cls-7{fill:none}'}</style>
    </defs>
    <g id="google_play_svg__Capa_1-2" data-name="Capa 1">
      <g id="google_play_svg__Grupo_26" data-name="Grupo 26">
        <path
          id="google_play_svg__Trazado_11"
          data-name="Trazado 11"
          className="google_play_svg__cls-1"
          d="M40.92 1.61c.54.27.99.69 1.28 1.22.31.57.46 1.21.45 1.86.02.65-.14 1.29-.45 1.86-.29.52-.74.94-1.28 1.2-.62.29-1.29.43-1.98.41h-2.17V1.18h2.17c.68-.02 1.36.13 1.98.43M41 6.69c.5-.54.75-1.26.71-2 .04-.75-.21-1.48-.72-2.03-.56-.52-1.3-.78-2.06-.73h-1.26v5.48h1.26c.76.05 1.5-.21 2.06-.72"
        />
        <path
          id="google_play_svg__Trazado_12"
          data-name="Trazado 12"
          className="google_play_svg__cls-1"
          d="M43.87 1.6a.604.604 0 0 1-.18-.44c0-.34.27-.62.61-.62h.02c.16 0 .31.06.42.18.12.12.18.28.17.44 0 .16-.06.32-.17.44a.56.56 0 0 1-.42.18c-.17 0-.33-.06-.44-.18m.88 6.55h-.91V2.67h.91v5.48Z"
        />
        <path
          id="google_play_svg__Trazado_13"
          data-name="Trazado 13"
          className="google_play_svg__cls-1"
          d="M47.11 8.03a1.94 1.94 0 0 1-.79-.6c-.19-.26-.31-.56-.32-.88h.94c.02.27.16.51.38.67.27.18.58.28.91.26.29.02.58-.06.82-.23.19-.13.3-.35.3-.58a.57.57 0 0 0-.32-.53 4.46 4.46 0 0 0-.99-.34c-.34-.08-.67-.19-.99-.32-.26-.11-.48-.28-.66-.49-.19-.24-.29-.55-.28-.86 0-.28.09-.55.25-.77.18-.25.43-.44.71-.56.33-.14.69-.21 1.05-.2.53-.03 1.05.14 1.47.46.38.31.6.77.6 1.26h-.91a.934.934 0 0 0-.35-.69c-.24-.18-.54-.28-.85-.26-.28-.01-.55.06-.78.21-.18.12-.29.33-.29.55 0 .17.06.33.18.45.13.12.28.22.44.28.18.07.42.15.74.24.33.08.65.19.96.32.25.1.46.26.64.46.18.23.28.52.28.81 0 .29-.08.57-.25.81-.18.25-.42.44-.7.56-.33.14-.69.21-1.04.2-.39 0-.77-.07-1.13-.22"
        />
        <path
          id="google_play_svg__Trazado_14"
          data-name="Trazado 14"
          className="google_play_svg__cls-1"
          d="M53.24 2.89c.38-.22.81-.32 1.24-.32.47 0 .92.12 1.33.35.4.23.72.58.94.98.23.46.35.96.34 1.48 0 .51-.11 1.02-.34 1.48-.43.85-1.31 1.38-2.26 1.37-.43 0-.86-.1-1.24-.32-.33-.19-.61-.46-.81-.78v3.61h-.91V2.67h.91v1.01c.19-.33.47-.6.8-.79m2.67 1.41c-.15-.29-.39-.54-.67-.7-.29-.16-.61-.24-.94-.24-.32 0-.64.08-.92.24-.29.17-.52.41-.68.71-.18.33-.26.7-.26 1.07 0 .38.08.75.26 1.09.16.3.39.54.68.71.28.16.6.25.92.24.33 0 .65-.08.94-.24.29-.17.52-.41.67-.71.17-.34.26-.71.25-1.1 0-.38-.08-.75-.25-1.08"
        />
        <path
          id="google_play_svg__Trazado_15"
          data-name="Trazado 15"
          className="google_play_svg__cls-1"
          d="M59.28 7.89a2.42 2.42 0 0 1-.98-1c-.24-.46-.37-.98-.35-1.5 0-.52.12-1.03.37-1.49.23-.42.58-.76.99-.99.88-.46 1.94-.46 2.82 0 .42.23.77.57 1 .98.25.46.38.97.37 1.49.01.52-.12 1.04-.38 1.5-.24.42-.59.77-1.02 1-.44.24-.92.36-1.42.35-.49 0-.97-.11-1.4-.35m2.31-.68c.29-.16.53-.4.69-.69.19-.34.28-.73.26-1.12.01-.39-.08-.78-.26-1.12a1.65 1.65 0 0 0-.68-.68c-.57-.3-1.25-.3-1.83 0-.28.16-.52.4-.67.68-.18.35-.26.73-.25 1.12-.01.39.07.78.25 1.13.15.29.37.53.65.69.28.15.59.23.9.22.32 0 .64-.08.92-.23"
        />
        <path
          id="google_play_svg__Trazado_16"
          data-name="Trazado 16"
          className="google_play_svg__cls-1"
          d="M68.97 3.18c.44.47.66 1.1.62 1.74v3.23h-.9v-3.1c.03-.46-.12-.91-.41-1.26-.29-.3-.7-.46-1.12-.43-.43-.02-.85.14-1.14.45-.31.36-.46.83-.43 1.31v3.03h-.91V2.67h.91v.78c.18-.28.43-.5.73-.65.32-.16.67-.23 1.03-.23.6-.03 1.18.19 1.62.61"
        />
        <path
          id="google_play_svg__Trazado_17"
          data-name="Trazado 17"
          className="google_play_svg__cls-1"
          d="M71.11 1.6a.604.604 0 0 1-.18-.44c0-.34.27-.62.61-.62h.01c.16 0 .31.06.43.18s.18.28.18.44c0 .16-.06.32-.18.44-.11.12-.26.18-.43.18-.17 0-.32-.06-.44-.18m.88 6.55h-.91V2.67h.91v5.48Z"
        />
        <path
          id="google_play_svg__Trazado_18"
          data-name="Trazado 18"
          className="google_play_svg__cls-1"
          d="M75.27 2.89c.38-.21.8-.32 1.23-.31.47 0 .93.11 1.33.35.39.24.72.58.93.98.23.46.35.96.34 1.48 0 .51-.11 1.02-.34 1.48-.43.85-1.31 1.38-2.26 1.37-.44 0-.87-.1-1.25-.31a2.27 2.27 0 0 1-.81-.79v1.01h-.91V.75h.91v2.94c.2-.34.48-.61.82-.8m2.65 1.42c-.15-.29-.39-.54-.68-.7a1.924 1.924 0 0 0-1.86 0c-.29.17-.52.41-.68.71-.18.33-.26.7-.26 1.07 0 .38.08.75.26 1.09.16.3.39.54.68.71.58.33 1.28.33 1.86 0 .29-.17.52-.41.68-.71.17-.34.26-.71.25-1.09 0-.38-.08-.75-.25-1.08"
        />
        <path
          id="google_play_svg__Rect\xE1ngulo_156"
          data-name="Rect\xE1ngulo 156"
          className="google_play_svg__cls-1"
          d="M80.3.75h.91v7.4h-.91z"
        />
        <path
          id="google_play_svg__Trazado_19"
          data-name="Trazado 19"
          className="google_play_svg__cls-1"
          d="M87.73 5.75h-4.38c0 .95.77 1.72 1.72 1.72h.06c.35.01.69-.08.99-.28.26-.18.45-.44.56-.74h.98c-.14.52-.45.97-.88 1.28-.48.34-1.05.52-1.64.49-.49 0-.97-.11-1.39-.35-.41-.23-.75-.58-.96-1-.24-.46-.36-.98-.35-1.49-.01-.52.11-1.03.34-1.49.21-.41.55-.76.96-.98.43-.24.92-.36 1.41-.35.48 0 .96.11 1.38.34.39.22.71.54.92.93.22.41.33.88.33 1.34 0 .17-.01.36-.03.55m-1.14-1.64a1.52 1.52 0 0 0-.62-.56c-.27-.13-.57-.2-.88-.19-.43-.01-.86.15-1.18.44-.34.32-.53.76-.55 1.22h3.46c0-.32-.07-.63-.23-.9"
        />
        <path
          id="google_play_svg__Trazado_20"
          data-name="Trazado 20"
          className="google_play_svg__cls-1"
          d="M96.6 5.75h-4.38c0 .95.77 1.72 1.72 1.72H94c.35.01.69-.08.98-.28.26-.18.45-.44.56-.74h.98c-.14.52-.45.97-.88 1.28-.48.34-1.05.52-1.64.49-.49 0-.97-.11-1.39-.35-.41-.23-.75-.58-.97-1-.24-.46-.36-.98-.35-1.49-.01-.52.11-1.03.34-1.49.21-.41.55-.76.96-.98.43-.24.92-.36 1.42-.35.48 0 .96.11 1.38.34.39.22.71.54.93.93.22.41.33.88.32 1.34 0 .17-.01.36-.03.55m-1.14-1.63c-.15-.25-.37-.44-.62-.57-.27-.13-.57-.2-.88-.19-.43 0-.85.15-1.18.44-.34.32-.53.76-.55 1.22h3.46c0-.32-.07-.63-.23-.9"
        />
        <path
          id="google_play_svg__Trazado_21"
          data-name="Trazado 21"
          className="google_play_svg__cls-1"
          d="M102.12 3.18c.44.47.66 1.1.62 1.74v3.23h-.9v-3.1c.03-.46-.12-.91-.41-1.26-.3-.3-.7-.46-1.12-.43-.43-.02-.84.14-1.14.45-.31.36-.46.83-.43 1.31v3.03h-.91V2.67h.91v.78c.18-.28.43-.5.74-.65.32-.16.67-.23 1.03-.23.6-.03 1.18.19 1.62.61"
        />
        <path
          id="google_play_svg__Trazado_22"
          data-name="Trazado 22"
          className="google_play_svg__cls-1"
          d="M46.76 27.34c-1.27.68-2.7 1.03-4.14 1.01-1.59.07-3.14-.53-4.27-1.64a5.901 5.901 0 0 1-1.64-4.35c-.06-1.69.59-3.34 1.79-4.54a6.162 6.162 0 0 1 4.54-1.77c1.14-.03 2.28.16 3.34.58v2.03c-.97-.62-2.11-.94-3.26-.91-1.17-.04-2.3.42-3.12 1.25-.83.87-1.26 2.04-1.22 3.25a4.72 4.72 0 0 0 1.04 3.23c.72.79 1.77 1.23 2.84 1.17.74.02 1.47-.14 2.12-.47v-2.83h-2.51v-1.66h4.48v5.65Z"
        />
        <path
          id="google_play_svg__Trazado_23"
          data-name="Trazado 23"
          className="google_play_svg__cls-1"
          d="M52.88 28.35c-1.17.06-2.3-.38-3.14-1.19-.8-.85-1.23-1.99-1.17-3.16a4.203 4.203 0 0 1 3.82-4.55c.23-.02.47-.02.7 0a4.07 4.07 0 0 1 3.1 1.17 4.53 4.53 0 0 1 1.11 3.25c.06 1.2-.38 2.38-1.2 3.26a4.31 4.31 0 0 1-3.23 1.22m.1-7.36c-.69-.03-1.35.26-1.78.79-.47.62-.7 1.4-.65 2.17-.04.76.19 1.5.66 2.1.44.51 1.1.8 1.78.77.67.04 1.32-.24 1.75-.76.44-.63.66-1.38.61-2.15a3.42 3.42 0 0 0-.61-2.17c-.43-.52-1.08-.8-1.75-.76"
        />
        <path
          id="google_play_svg__Trazado_24"
          data-name="Trazado 24"
          className="google_play_svg__cls-1"
          d="M63.03 28.35a4.2 4.2 0 0 1-3.14-1.19c-.8-.85-1.23-1.99-1.17-3.16a4.2 4.2 0 0 1 3.81-4.55c.24-.02.48-.02.71 0 1.15-.07 2.28.36 3.11 1.17a4.53 4.53 0 0 1 1.11 3.25c.06 1.2-.38 2.38-1.2 3.26-.86.84-2.03 1.28-3.22 1.22m.09-7.36c-.69-.03-1.35.26-1.78.79-.47.62-.7 1.4-.66 2.17-.04.76.19 1.5.66 2.1.44.51 1.1.8 1.78.77.67.04 1.32-.24 1.75-.76.44-.62.66-1.38.61-2.15a3.42 3.42 0 0 0-.61-2.17c-.43-.52-1.08-.8-1.75-.76"
        />
        <path
          id="google_play_svg__Trazado_25"
          data-name="Trazado 25"
          className="google_play_svg__cls-1"
          d="M77.15 27.47c0 3.12-1.57 4.68-4.71 4.68-1 .02-1.98-.17-2.9-.56v-1.76c.8.49 1.71.77 2.65.8 2.02 0 3.03-.99 3.03-2.98v-.93h-.03a3.34 3.34 0 0 1-5.37.49 4.578 4.578 0 0 1-.95-3.04c-.07-1.23.29-2.45 1.02-3.44.68-.85 1.72-1.33 2.81-1.28 1.03-.05 2 .48 2.5 1.38h.03v-1.18h1.93v7.82Zm-1.91-3.21v-1.1c0-.57-.21-1.12-.6-1.53-.38-.41-.92-.65-1.48-.63a2.01 2.01 0 0 0-1.71.81 3.64 3.64 0 0 0-.62 2.26c-.04.71.17 1.42.59 2 .37.49.96.77 1.57.75.62.02 1.22-.25 1.62-.72.43-.51.65-1.17.62-1.84"
        />
        <path
          id="google_play_svg__Rect\xE1ngulo_157"
          data-name="Rect\xE1ngulo 157"
          className="google_play_svg__cls-1"
          d="M79.66 15.57h1.93v12.58h-1.93z"
        />
        <path
          id="google_play_svg__Trazado_26"
          data-name="Trazado 26"
          className="google_play_svg__cls-1"
          d="M91.29 24.42H85.5c-.02.68.24 1.34.73 1.82.53.45 1.21.68 1.9.64.9 0 1.78-.28 2.5-.81v1.55c-.93.53-2 .78-3.07.73-1.1.06-2.18-.36-2.94-1.16a4.66 4.66 0 0 1-1.07-3.26c-.05-1.19.37-2.35 1.17-3.23.75-.82 1.81-1.27 2.92-1.25 1.02-.06 2.02.35 2.7 1.12.68.89 1.02 1.99.95 3.11v.74Zm-1.86-1.36c.03-.58-.14-1.15-.49-1.61-.33-.39-.82-.6-1.33-.58-.53 0-1.05.21-1.41.6-.4.44-.65.99-.71 1.59h3.93Z"
        />
        <path
          id="google_play_svg__Trazado_27"
          data-name="Trazado 27"
          className="google_play_svg__cls-1"
          d="M99.99 23.83v4.32h-1.97v-11.9h3.61c1.14-.07 2.27.27 3.18.95.77.68 1.19 1.67 1.13 2.69.04 1.07-.39 2.11-1.18 2.84-.88.77-2.02 1.16-3.19 1.1h-1.59Zm0-5.96v4.35h1.28c.7.05 1.38-.16 1.93-.59.46-.43.7-1.04.66-1.66 0-1.4-.81-2.1-2.44-2.1h-1.44Z"
        />
        <path
          id="google_play_svg__Rect\xE1ngulo_158"
          data-name="Rect\xE1ngulo 158"
          className="google_play_svg__cls-1"
          d="M107.74 15.57h1.93v12.58h-1.93z"
        />
        <path
          id="google_play_svg__Trazado_28"
          data-name="Trazado 28"
          className="google_play_svg__cls-1"
          d="M118.7 28.15h-1.87v-1.33h-.03c-.49.96-1.5 1.56-2.58 1.53-.72.04-1.43-.2-1.96-.68a2.38 2.38 0 0 1-.71-1.8c0-1.6.92-2.54 2.77-2.81l2.52-.36c0-1.21-.58-1.82-1.73-1.82-1.01 0-1.99.37-2.74 1.05v-1.69c.94-.54 2.02-.82 3.11-.8 2.15 0 3.22 1.06 3.22 3.17v5.53Zm-1.86-4.18-1.79.25c-.44.03-.87.17-1.25.4-.3.25-.46.64-.42 1.03-.01.34.13.66.39.88.29.24.66.36 1.03.34.55.01 1.08-.21 1.46-.62.39-.42.6-.98.58-1.56v-.74Z"
        />
        <path
          id="google_play_svg__Trazado_29"
          data-name="Trazado 29"
          className="google_play_svg__cls-1"
          d="m128.42 19.65-3.83 9.86c-.8 1.76-1.92 2.64-3.35 2.64-.34 0-.68-.03-1.01-.11v-1.6c.27.09.55.14.83.15.7.01 1.34-.41 1.61-1.06l.57-1.4-3.38-8.48h2.13l2.03 6.18c.02.08.07.28.15.61h.04c.03-.13.08-.33.15-.6l2.12-6.19h1.94Z"
        />
      </g>
      <g
        style={{
          clipPath: 'url(#google_play_svg__clippath)',
        }}
        id="google_play_svg__Grupo_29"
        data-name="Grupo 29">
        <g id="google_play_svg__Grupo_28" data-name="Grupo 28">
          <path
            id="google_play_svg__Rect\xE1ngulo_160"
            data-name="Rect\xE1ngulo 160"
            style={{
              fill: '#0df',
            }}
            d="M-.03 1.12h13.4v25.92H-.03z"
          />
        </g>
      </g>
      <g
        style={{
          clipPath: 'url(#google_play_svg__clippath-1)',
        }}
        id="google_play_svg__Grupo_31"
        data-name="Grupo 31">
        <g id="google_play_svg__Grupo_30" data-name="Grupo 30">
          <path
            id="google_play_svg__Rect\xE1ngulo_161"
            data-name="Rect\xE1ngulo 161"
            style={{
              fill: '#ff9600',
            }}
            d="M14.92 9.65h10.87v8.85H14.92z"
          />
        </g>
      </g>
      <g
        style={{
          clipPath: 'url(#google_play_svg__clippath-2)',
        }}
        id="google_play_svg__Grupo_33"
        data-name="Grupo 33">
        <g id="google_play_svg__Grupo_32" data-name="Grupo 32">
          <path
            id="google_play_svg__Rect\xE1ngulo_162"
            data-name="Rect\xE1ngulo 162"
            style={{
              fill: '#bd1162',
            }}
            d="M1.26 14.85h17.26V28.3H1.26z"
          />
        </g>
      </g>
      <g
        style={{
          clipPath: 'url(#google_play_svg__clippath-3)',
        }}
        id="google_play_svg__Grupo_35"
        data-name="Grupo 35">
        <g id="google_play_svg__Grupo_34" data-name="Grupo 34">
          <path
            id="google_play_svg__Rect\xE1ngulo_163"
            data-name="Rect\xE1ngulo 163"
            style={{
              fill: '#00ea76',
            }}
            d="M1.26 0h17.26v13.31H1.26z"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgGooglePlay;
