import { IconBackground } from '../../svg';
import styles from './threeColumnIconSection.module.scss';

export type Props = {
  title: string;
  mainColor: string;
  columns: {
    icon: any;
    title: string;
    description: string;
  }[];
};

export const ThreeColumnIconSection = (props: Props) => {
  return (
    <div className={styles.wrapper}>
      <div className="container">
        <div className="row">
          <div className="col-12 text-center">
            <h1 dangerouslySetInnerHTML={{ __html: props.title }}></h1>
          </div>
        </div>

        <div className="row mt-5">
          {props.columns.map((column) => (
            <div
              className="col-12 mb-4 mb-lg-0 col-lg-4 text-center d-flex flex-column align-items-center"
              key={column.title}>
              <div className={`${styles.iconWrapper}`}>
                <IconBackground fill={props.mainColor} className={styles.iconBackground} />

                <div className={styles.iconTopWrapper}>
                  <column.icon className={styles.iconTop} />
                </div>
              </div>
              <h6 className="my-3">{column.title}</h6>
              <p>{column.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
