import { HiLocationMarker, HiMail, HiPhone } from 'react-icons/hi';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import { SummaryCard } from './summaryCard';

export const ShippingInformationSummaryCard = () => {
  const { shippingInformation } = useShoppingCart();
  return (
    <SummaryCard header="Dirección de envío">
      <div>
        {[
          shippingInformation.firstName,
          shippingInformation.lastName,
          shippingInformation.secondLastName,
        ].join(' ')}
      </div>
      <div>
        <HiLocationMarker className="me-1" size={20} />
        {[
          shippingInformation.address,
          shippingInformation.province,
          shippingInformation.municipality,
        ].join(', ')}
      </div>
      <div>
        <HiPhone className="me-1" size={20} />
        {shippingInformation.phoneNumber}
      </div>
      <div>
        <HiMail className="me-1" size={20} />
        {shippingInformation.email}
      </div>
    </SummaryCard>
  );
};
