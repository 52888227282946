import * as React from 'react';
import { SVGProps } from 'react';
const SvgEntregaRapida = (props: SVGProps<SVGSVGElement>) => (
  <svg
    data-name="entrega rapida"
    xmlns="http://www.w3.org/2000/svg"
    width={60}
    height={60}
    {...props}>
    <g data-name="Grupo 16714">
      <g data-name="Grupo 16713">
        <path
          data-name="Trazado 34078"
          d="M58.45 25.82a7.7 7.7 0 0 0-7.08-7.38 73.639 73.639 0 0 0-7.4-.19c-.94.04-1.86.06-2.74.09-.08-1.98-.18-3.6-.28-4.87a5.506 5.506 0 0 0-5.01-5.15c-2.69-.22-8.83-.46-14.92-.46s-11.48.24-14.17.46a5.5 5.5 0 0 0-5.01 5.15c-.22 2.77-.45 7.15-.45 13.41s.23 10.64.45 13.41a5.506 5.506 0 0 0 5.01 5.15c2.69.22 8.08.46 14.17.46s12.23-.24 14.92-.46a3.468 3.468 0 0 0 .45-.06c2.04.1 4.7.17 7.59.17 4.37 0 8.77-.17 10.71-.33a3.949 3.949 0 0 0 3.6-3.69c.16-1.98.26-5.13.32-9.62.02-1.71-.05-3.95-.15-6.07Z"
          fill="#ffdbe6"
        />
        <circle
          data-name="Elipse 17"
          cx={7.14}
          cy={7.14}
          r={7.14}
          transform="translate(37.77 37.87)"
          fill="#fe4a81"
        />
        <circle
          data-name="Elipse 18"
          cx={7.14}
          cy={7.14}
          r={7.14}
          transform="translate(8.2 37.87)"
          fill="#fe4a81"
        />
        <path
          data-name="Trazado 34079"
          d="M4.34 22.87c0-1.58 3.14-2.06 13.5-2.06s13.51.48 13.51 2.06-3.14 2.06-13.51 2.06-13.5-.48-13.5-2.06Z"
          fill="#fff"
        />
        <path
          data-name="Trazado 34080"
          d="M9.09 30.97c0-1.58 2.07-2.06 8.91-2.06s8.91.48 8.91 2.06-2.07 2.06-8.91 2.06-8.91-.48-8.91-2.06Z"
          fill="#fff"
        />
        <path
          data-name="Trazado 34081"
          d="M59.54 25.76a8.812 8.812 0 0 0-8.08-8.42 71.622 71.622 0 0 0-7.52-.19l-1.66.06c-.07-1.38-.15-2.67-.24-3.84a6.621 6.621 0 0 0-6-6.15c-2.69-.22-8.87-.46-15.01-.46s-11.5.23-14.26.46a6.6 6.6 0 0 0-6 6.15c-.21 2.6-.45 7.05-.45 13.5s.25 10.89.45 13.5a6.621 6.621 0 0 0 6 6.15c.15.01.32.03.49.04a8.24 8.24 0 0 0 8.08 6.7 8.117 8.117 0 0 0 7.99-6.28c5.33-.05 10.36-.26 12.71-.45.14-.01.28-.03.41-.05a2.3 2.3 0 0 1 .37.02 8.217 8.217 0 0 0 16.19-.07c.71-.04 1.31-.08 1.77-.11a5.057 5.057 0 0 0 4.59-4.69c.16-2.03.27-5.2.32-9.69.03-1.99-.07-4.44-.15-6.14ZM15.33 51.07a6.05 6.05 0 1 1 6.06-6.05 6.058 6.058 0 0 1-6.06 6.05Zm29.57 0a6.05 6.05 0 1 1 6.05-6.05 6.058 6.058 0 0 1-6.05 6.05Zm12.61-19.19c-.06 4.44-.16 7.57-.32 9.55a2.86 2.86 0 0 1-2.6 2.7c-.4.03-.91.07-1.5.1a8.224 8.224 0 0 0-16.38.07 2.437 2.437 0 0 1-.27-.01h-.12l-.11.01c-.12.02-.24.04-.36.05-2.28.19-7.12.39-12.3.44a8.235 8.235 0 0 0-8.22-8 8.139 8.139 0 0 0-8.2 7.57 1.231 1.231 0 0 1-.2-.02 4.391 4.391 0 0 1-4.02-4.15c-.2-2.56-.44-6.95-.44-13.32s.24-10.76.44-13.32A4.391 4.391 0 0 1 6.93 9.4c2.72-.23 8.13-.46 14.08-.46s12.18.23 14.83.46a4.391 4.391 0 0 1 4.02 4.15c.01.18.03.37.04.56v5.44c0 8.24.48 10.74 2.06 10.74s2.06-2.5 2.06-10.74v-.2a72.362 72.362 0 0 1 7.26.19 6.634 6.634 0 0 1 6.07 6.35c.11 2.37.17 4.5.15 6.01Z"
          fill="#263645"
        />
      </g>
    </g>
  </svg>
);
export default SvgEntregaRapida;
