import { useCallback, useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Select from 'react-select';
import {
  DownloadAppCommon,
  RegisterSection,
  ThreeColumnIconSection,
} from '../../../components/external';
import {
  FlechaDer,
  FormasPago,
  MarcadoDirecto,
  OvalWrapper,
  PanelControl,
} from '../../../components/svg';
import { IMAGES } from '../../../config/images';
import {
  IconOptionCallCountry,
  IconSingleValueCallCountry,
  reactSelectStyles,
} from '../../../config/reactSelect';
import { ROUTES } from '../../../config/routes';
import { SessionContext } from '../../../providers/session.provider';

import styles from './service-calls-screen.module.scss';
import { CallCountry, CallCountryRate } from '../../../types/call';
import { useQuery } from 'react-query';
import { QueryTypes } from '../../../types/queryTypes';
import { CallService } from '../../../services/call';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { debounce } from 'lodash';

const mainSchema = yup
  .object()
  .shape({
    phone: yup.string(),
  })
  .required();
interface MainFormData extends yup.TypeOf<typeof mainSchema> {}

export const ServiceCallsScreen = () => {
  const navigate = useNavigate();
  const { isAuthenticated } = useContext(SessionContext);

  const [countryList, setCountryList] = useState<CallCountry[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<CallCountry | null>(null);
  const [selectedCost, setSelectedCost] = useState(0);
  const countryQuery = useQuery(QueryTypes.GetCountriesForCall, CallService.getCountries);

  const { setValue, register, handleSubmit, formState, getValues, setFocus } = useForm({
    resolver: yupResolver(mainSchema),
    mode: 'onChange',
  });

  const getRate = (event: React.ChangeEvent<HTMLInputElement>, country?: CallCountry | null) => {
    if (!event || !event.target || !event.target.value || !country) {
      return;
    }

    const fixedPhone = event.target.value.replace('+' + country?.calling_code, '');
    if (fixedPhone !== undefined && fixedPhone !== '') {
      CallService.getCountryRate({
        country: country?.country_code ?? '',
        phone: fixedPhone,
      }).then((response) => {
        if (response) {
          setSelectedCost(response.cost);
        }
      });
    } else {
      setSelectedCost(0);
    }
  };

  useEffect(() => {
    if (isAuthenticated()) {
      navigate(ROUTES.cuenta.inicio);
      return;
    }
  }, []);

  useEffect(() => {
    if (countryQuery.status === 'success' && countryQuery.data.length) {
      setCountryList(countryQuery.data);
    }
  }, [countryQuery.status]);

  useEffect(() => {
    const cubaCountry = countryList.find((item) => item.country_code.toLowerCase() === 'cu');
    if (cubaCountry) {
      setSelectedCountry(cubaCountry);
    }
  }, [countryList]);

  useEffect(() => {
    setSelectedCost(0);
    if (selectedCountry?.calling_code !== undefined) {
      setValue('phone', '+' + selectedCountry?.calling_code);
    }
    setFocus('phone', { shouldSelect: false });
  }, [selectedCountry]);

  const debounceRate = useCallback(debounce(getRate, 500), []);

  const threeColumns = {
    title: 'Te garantizamos <span class="text-secondary text-decoration-underline">lo mejor</span>',
    mainColor: '#E4F6C3',
    columns: [
      {
        title: 'Marcado Directo',
        icon: MarcadoDirecto,
        description:
          'Olvídate de comprar tarjetas e introducir el PIN. Con Nautatel hazlo rápido y ahorra más del 80% en llamadas de larga distancia.',
      },
      {
        title: 'Panel de Control',
        icon: PanelControl,
        description:
          'Accede fácilmente a ver tu historial de llamadas, pagos y de marcación directa.',
      },
      {
        title: 'Múltiples Formas de Pago',
        icon: FormasPago,
        description: 'Puedes pagar de manera segura con tarjetas de crédito, débito o PayPal.',
      },
    ],
  };

  return (
    <div className={`${styles.main_background}`}>
      <div className={styles.wrapperHeader}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-lg-6 d-flex align-items-center">
              <img src={IMAGES.Promos.PromoLlamadas} className="img-fluid" />
            </div>
            <div className="col-12 col-lg-6">
              <h1 className="text-secondary text-decoration-underline text-center text-lg-start">
                ¡A dar muela!
              </h1>
              <h1 className="pe-md-8  text-center text-lg-start">
                Llamadas baratas para hablar con familiares y amigos en Cuba
              </h1>

              <p className="fs-mg-20 mt-5 text-center text-lg-start">
                Te ofrecemos las tarifas más económicas del mercado y la mejor calidad para que
                hables con tus familiares y amigos sin preocupaciones.
              </p>

              <div className="d-flex justify-content-center justify-content-lg-start">
                <div className={`${styles.priceWrapper}`}>
                  <div className="bubble bubble-secondary">
                    <div className={styles.priceContentWrapper}>
                      <div className="ps-4">
                        <h1 className="d-inline">$0.69</h1>
                        <small className="fs-mg-20 d-inline text-secondary">/minuto</small>
                      </div>
                      <p className={`fs-mg-20 ps-4 mb-0 ${styles.pPriceDesc}`}>Fijos y celulares</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="text-center text-lg-start">
                <Link to={ROUTES.cuenta.contactos} className="btn btn-secondary mt-4 px-4">
                  ¡Llama ahora! <FlechaDer width={15} fill="#fff" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={`container ${styles.rateContainer}`}>
        <div className={styles.rateWrapper}>
          <div className="row">
            <div
              className={`col-12 col-lg-4 mb-5 mb-lg-0 ${styles.rateTitleWrapper} d-flex align-items-center`}>
              <h2 className="text-white text-center text-lg-start">
                Conoce las <span className="text-secondary text-decoration-underline">tarifas</span>{' '}
                a otros países
              </h2>
            </div>
            <div className="d-none d-lg-flex col-lg-1"></div>
            <div className={`col-12 col-lg-7 ${styles.ratePriceWrapper}`}>
              <h6 className="mb-4">Te ofrecemos las tarifas más económicas del mercado</h6>

              <form noValidate>
                <div className="row">
                  <div className={`col-12 col-md-4 mb-5 mb-md-0 ${styles.wrapperSelect1}`}>
                    <div className="megaInput">
                      <label htmlFor="country">País</label>
                      <Select
                        id="country"
                        placeholder="Seleccione el país"
                        value={selectedCountry}
                        options={countryList}
                        isSearchable={false}
                        isClearable={false}
                        className="w-100 mega-select"
                        isMulti={false}
                        styles={reactSelectStyles}
                        components={{
                          Option: IconOptionCallCountry,
                          SingleValue: IconSingleValueCallCountry,
                        }}
                        onChange={(newValue) => {
                          setSelectedCountry(newValue);
                        }}
                      />
                    </div>
                  </div>
                  <div className={`col-12 col-md-4 mb-5 mb-md-0 ${styles.wrapperSelect2}`}>
                    <div className="megaInput">
                      <label htmlFor="phone">Teléfono</label>
                      <input
                        type="phone"
                        className={`form-control mega`}
                        id="phone"
                        {...register('phone')}
                        onChange={(e) => debounceRate(e, selectedCountry)}
                      />
                    </div>
                  </div>
                  <div
                    className={`col-12 col-md-4 ${styles.wrapperSelect3} ${
                      selectedCost ? '' : styles.opac
                    }`}>
                    <div className="d-flex flex-row align-items-center justify-content-center justify-content-md-start">
                      <span className="mt-2 mb-4 mb-md-0">
                        <span className="fs-2 fw-bolder futura-bold">${selectedCost}</span>
                        <span className="fs-6 fw-bold text-secondary">/ minuto</span>
                      </span>
                    </div>
                    <p className="p-0"></p>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <ThreeColumnIconSection {...threeColumns} />

      <DownloadAppCommon bgColor="#E1EEE7" appStoreClassName="secondary" />

      <RegisterSection color="secondary" arrowColor="#8AB934" />
    </div>
  );
};
