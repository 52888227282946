import * as React from 'react';
import { SVGProps } from 'react';
const SvgMarcadoDirecto = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="marcado_directo_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    {...props}>
    <defs>
      <style>{'.marcado_directo_svg__cls-1{fill:#fff}'}</style>
    </defs>
    <g id="marcado_directo_svg__Capa_1-2" data-name="Capa 1">
      <path
        id="marcado_directo_svg__Trazado_233"
        data-name="Trazado 233"
        d="M37.79 15.22c19.79 0 20.62.51 20.62 12.61s-.83 12.61-20.62 12.61c-19.79 0-20.62-.51-20.62-12.61S18 15.22 37.79 15.22Z"
        style={{
          fill: '#8ab934',
        }}
      />
      <path
        className="marcado_directo_svg__cls-1"
        d="M27.14 24.43c-2.54 0-3.31.69-3.31 2.96s.77 2.96 3.31 2.96 3.31-.69 3.31-2.96-.77-2.96-3.31-2.96ZM37.79 24.43c-2.54 0-3.31.69-3.31 2.96s.77 2.96 3.31 2.96 3.31-.69 3.31-2.96-.77-2.96-3.31-2.96ZM48.44 24.43c-2.54 0-3.31.69-3.31 2.96s.77 2.96 3.31 2.96 3.31-.69 3.31-2.96-.77-2.96-3.31-2.96Z"
      />
      <path
        d="M33.44 55.46c-2.83-.24-6.07-.95-8.98-2.75-8.59-5.29-15.64-12.12-20.62-20.94-4.91-8.7-1.34-21.46 7.34-26.46.55-.32 1.19-.53 1.78-.68 3.64-.9 7.51 4.52 9.07 8.25 1.06 2.53.94 4.23-.87 6.17-4.79 5.12-4.73 5.29-.4 11.04 2.6 3.44 5.83 6.11 9.5 8.35 2.21 1.35 3.87 1.29 5.71-.74 1.65-1.82 3.31-4.81 6.22-3.64 3.63 1.46 7.7 3.74 9.55 6.84.88 1.48.97 3.39-.18 4.75-4.62 5.43-9.96 9.7-18.12 9.81Z"
        style={{
          fill: '#d0e3ae',
        }}
      />
      <path
        d="M56.09 16.06c-2.85-1.74-7.77-1.93-18.3-1.93-6.63 0-11.02.07-14.09.56-.11-.69-.33-1.42-.67-2.22-1.75-4.17-5.94-9.98-10.34-8.89-.81.2-1.5.46-2.06.79C1.35 9.72-2.34 23.04 2.9 32.31c4.83 8.56 11.89 15.73 20.99 21.34 2.63 1.62 5.82 2.6 9.46 2.9h.1c9.14-.12 14.7-5.22 18.93-10.19 1.29-1.51 1.47-3.66.52-5.59 1.3-.28 2.34-.66 3.18-1.17 3.24-1.98 3.41-5.77 3.41-11.77s-.16-9.79-3.41-11.77Zm-5.36 28.89c-3.91 4.59-9.01 9.3-17.25 9.43-3.28-.28-6.12-1.15-8.45-2.58-8.78-5.41-15.59-12.32-20.24-20.55-4.6-8.15-1.23-20.29 6.93-25 .39-.22.89-.41 1.5-.56.18-.04.36-.07.55-.07 2.64 0 5.84 4.3 7.26 7.68.94 2.25.78 3.46-.67 5.01-5.15 5.5-5.17 6.2-.47 12.43 2.49 3.3 5.69 6.12 9.8 8.62 2.78 1.69 4.96 1.4 7.08-.93.3-.33.6-.69.88-1.04 1.52-1.86 2.56-2.95 4.13-2.32 3.13 1.26 7.24 3.39 9.02 6.39.55.92.86 2.39-.08 3.49Zm.82-6.13c-2.34-2.69-6.04-4.59-8.95-5.76-3.16-1.27-5.16 1.17-6.62 2.95-.28.35-.55.67-.81.96-1.41 1.55-2.47 1.68-4.34.54-3.87-2.36-6.88-5-9.2-8.07-1.89-2.51-3.03-4.02-3-5.06.03-1 1.16-2.26 3.33-4.57.89-.95 1.45-1.89 1.69-2.9 2.89-.54 7.32-.6 14.14-.6 19.54 0 19.54.43 19.54 11.52 0 7.38 0 10.04-5.77 10.99Z"
        style={{
          fill: '#263645',
        }}
      />
    </g>
  </svg>
);
export default SvgMarcadoDirecto;
