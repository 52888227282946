import { useEffect, useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { UtilService } from '../../../services';
import { ReportsService } from '../../../services/reports';
import { QueryTypes } from '../../../types/queryTypes';
import { MegaStoreOrder, MegaStoreOrderDetail } from '../../../types/reports/storeOrder';
import { productImgUrl } from '../../../utilities/productImgUrl';
import { MegaLoading } from '../../external/megaLoading';
import { MegaError } from '../megaError';

import { Formatter } from '../../../utilities';
import { FechaInput } from '../../svg';
import { getStatusColors } from './baseColumnDef';
import styles from './storeReportItemDetails.module.scss';

type StoreReportItemDetailsProps = {
  id?: number;
  onHide: () => void;
};

export const StoreReportItemDetails = ({ id, onHide }: StoreReportItemDetailsProps) => {
  const [order, setOrder] = useState<MegaStoreOrder>();

  const callStoreReportDetailsQuery = useQuery([QueryTypes.GetStoreReportDetails, id], () => {
    if (id !== undefined) {
      return ReportsService.getStoreReportDetails(id);
    }
  });

  const allQueries = [callStoreReportDetailsQuery];

  useEffect(() => {
    if (callStoreReportDetailsQuery.status === 'success') {
      setOrder(callStoreReportDetailsQuery.data);
    }
  }, [callStoreReportDetailsQuery.data]);

  const modalBody = () => {
    if (UtilService.isErrorQueries(allQueries)) {
      return (
        <MegaError
          displayRetry
          retry={() => {
            UtilService.refetchQueries(allQueries);
          }}
        />
      );
    }

    const getDeliveryDescription = (detail: MegaStoreOrderDetail) => {
      let text = '';
      if (detail.product.deliveries.length > 0) {
        const dm = detail.product.deliveries[0];
        text = `entrega en ${dm.duration}`;
      }

      return text;
    };

    return (
      <Modal.Body className="px-0 pt-0">
        <div className="bg-mega_light_gray p-4">
          <Row className="no-guttsers">
            <Col xs={12} lg={8} className="mb-5 mb-lg-0">
              <div className={styles.sectionWrapper}>
                <div className="row">
                  <div className="col-12 col-lg-6">
                    <span className={styles.labelBig}>ID de la Compra: {order?.reference}</span>
                  </div>
                  <div className="col-12 col-lg-6 text-start text-lg-end">
                    <FechaInput width={16} fill="#8ab934" className={styles.icon} />{' '}
                    {order?.created_date}
                  </div>
                </div>

                <div className="mt-3 d-flex flex-column flex-lg-row">
                  <div className="flex-fill mb-3 mb-lg-0">
                    <div className={styles.labelSmall}>Dirección de envío</div>
                    {order && (
                      <>
                        <div>{order.full_name}</div>
                        <div>{order.address1}</div>
                        <div>{order.address2}</div>
                        <div>
                          {[order.city, order.state, order.country].filter((v) => v).join(', ')}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="flex-fill  mb-3 mb-lg-0">
                    <div className={styles.labelSmall}>Método de Pago</div>
                    {order?.payment_method}
                  </div>

                  <div className="flex-fill">
                    <div className={styles.labelSmall}>Estado</div>
                    {getStatusColors(order?.order_status || '')}
                  </div>
                </div>
              </div>
            </Col>
            <Col xs={12} lg={4} className="mt-4 mt-md-0">
              <div className={styles.sectionWrapper}>
                <div className={`${styles.labelBig} mb-1`}>Resumen</div>

                <div className={styles.paymentRow}>
                  <div className={styles.label}>Subtotal</div>
                  <div className={styles.price}>${order?.product_total}</div>
                </div>

                <div className={`${styles.paymentRow} ${styles.border}`}>
                  <div className={styles.label}>Gastos de Envío</div>
                  <div className={`${styles.price} ${styles.highlight}`}>
                    ${order?.delivery_total}
                  </div>
                </div>

                <div className={`${styles.paymentRow} ${styles.total} mt-2`}>
                  <div className={styles.label}>Pagado</div>
                  <div className={`${styles.price}`}>${order?.order_total}</div>
                </div>
              </div>
            </Col>
          </Row>

          <Row>
            <Col xs={12} className="mt-5">
              <div className={styles.sectionWrapper}>
                <div className={`${styles.labelBig} mb-3`}>Artículos ({order?.details.length})</div>

                {UtilService.isLoadingQueries(allQueries) && <MegaLoading isLoading={true} />}
                {order &&
                  order.details.map((orderDetail) =>
                    renderOrderDetail(orderDetail, order, getDeliveryDescription)
                  )}
              </div>
            </Col>
          </Row>
        </div>
      </Modal.Body>
    );
  };

  const renderOrderDetail = (
    orderDetail: MegaStoreOrderDetail,
    order: MegaStoreOrder,
    getDeliveryDescription: Function
  ) => {
    return (
      <div key={orderDetail.product.id} className={styles.productLine}>
        <div className="d-flex flex-row">
          <div>
            <img
              src={productImgUrl(orderDetail.product.main_image.image_url)}
              className={`img-fluid rounded ${styles.productImg}`}
            />
          </div>
          <div className="flex-fill ps-3">
            <div className="d-flex flex-column">
              <div className={`${styles.labelBig} pb-1`}>{orderDetail.product.name}</div>
              <div>{orderDetail.product.description}</div>
              <div className="d-flex flex-row align-items-end py-1">
                <div className={`${styles.labelBig} text-secondary`}>
                  {Formatter.toCurrency(orderDetail.product.price)}
                </div>
                <div className={styles.labelSmall}>&nbsp; x {1}</div>
              </div>
              <div>
                {order.state},{' '}
                <span className="text-secondary">{getDeliveryDescription(orderDetail)}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <Modal
      show={id !== undefined}
      onHide={onHide}
      centered
      size="xl"
      backdrop={'static'}
      backdropClassName="mega-double-backdrop"
      dialogClassName="mega-double-dialog">
      <Modal.Header closeButton className="mega-modal-title">
        <Modal.Title>Detalles de orden</Modal.Title>
      </Modal.Header>
      {modalBody()}
    </Modal>
  );
};
