import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import {
  MegaError,
  PageTitle,
  ReferralBalanceWithdrawalForm,
  ReferralForm
} from '../../../components/account';
import { ReferralReport } from '../../../components/account/reports';
import { ReferirAmigo } from '../../../components/svg';
import { SessionContext } from '../../../providers/session.provider';
import { UtilService } from '../../../services';
import { ReferralServices } from '../../../services/referral';
import { QueryTypes } from '../../../types/queryTypes';
import { Formatter } from '../../../utilities';
import { AlertHelper } from '../../../utilities/AlertHelper';

import styles from './refer-friend-screen.module.scss';

export const ReferFriendScreen = () => {
  const [referralBalance, setReferralBalance] = useState<number>();
  const { getUser } = useContext(SessionContext);

  const authUser = getUser();

  const referralBalanceQuery = useQuery(
    QueryTypes.GetReferralBalance,
    ReferralServices.getReferralBalance
  );

  useEffect(() => {
    if (referralBalanceQuery.status === 'success') {
      setReferralBalance(referralBalanceQuery.data);
    }
  }, [referralBalanceQuery.status]);

  const allQueries = [referralBalanceQuery];

  // if (UtilService.isLoadingQueries(allQueries)) {
  //   return <MegaLoading isLoading={true} />;
  // }

  if (UtilService.isErrorQueries(allQueries)) {
    return (
      <MegaError
        displayRetry
        retry={() => {
          UtilService.refetchQueries(allQueries);
        }}
      />
    );
  }

  const afterSuccessWithdrawal = () => {
    AlertHelper.show({
      icon: 'success',
      title: 'Su retiro se ha programado exitosamente',
    });
    referralBalanceQuery.refetch();
  };

  return (
    <div className="bg-mega_light_gray">
      <div className="container py-5">
        <PageTitle
          title="Referir Amigos"
          description="Gana dinero refiriendo amigos."
          icon={ReferirAmigo}>
          <div className="d-flex flex-column flex-lg-row justify-content-end">
            <div className="flex-column me-0 me-lg-2 text-center text-lg-start mt-5 mt-lg-0">
              <h4 className="text-secondary">{Formatter.toCurrency(referralBalance)}</h4>
              <div className={styles.textTitle}>Comisión ganada</div>
            </div>
            <div className="d-flex justify-content-center align-items-lg-center mt-2 mt-lg-0">
              <ReferralBalanceWithdrawalForm
                isDisabled={!(referralBalance !== undefined && referralBalance > 0)}
                afterSuccess={() => afterSuccessWithdrawal()}
              />
            </div>
          </div>
        </PageTitle>

        <div className="row">
          <div className="col-12 col-lg-4">
            <div className={styles.inputArea}>
              <h6>Teléfono de un amigo</h6>

              <div className={styles.infoMessage}>
                Se enviará un <b>SMS</b> notificando a su amigo de que usted lo refirió con el
                enlace a la aplicación móvil.
              </div>

              <div className="w-100">
                <ReferralForm defaultCountryCode={authUser?.COUNTRY ?? 'US'} />
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-8">
            <h6>Reporte de referencias</h6>
            <ReferralReport />
          </div>
        </div>

        <div className="row mt-5">
          <div className="col-12 col-md-2"></div>
          <div className="col-12 col-md-8">
            <div className="mega-alert">
              <div className="">
                <strong>MegaConecta</strong> le permite ganar <strong>5 USD </strong>
                por cada nuevo cliente que usted refiera. Las condiciones son sencillas, el nuevo
                cliente debe registrarse usando el mismo número de teléfono que usted proporcionó en
                la referencia.
              </div>
              <div className="mt-2">
                De esta forma podemos comprobar que usted nos está refiriendo esta persona y asi
                podemos enviarle su comisión. Una vez que el nuevo cliente se ha registrado y{' '}
                <strong>efectua su primer pago </strong>usted recibe inmediatamente{' '}
                <strong>5 USD </strong>en su cuenta en <strong>MegaConecta</strong>. Usted puede
                usar su balance para llamadas o envío de SMS.
              </div>
            </div>
          </div>
          <div className="col-12 col-md-2"></div>
        </div>
      </div>
    </div>
  );
};
