import axios from 'axios';
import { client, Client, dataCollector, DataCollector } from 'braintree-web';
import { PaymentServices } from './payments';

export const getCardNonce = async ({
  number,
  expiration,
  cvv,
}: {
  number: string;
  expiration: string;
  cvv: string;
}) => {
  const config = {
    headers: {
      Authorization: `Bearer ${process.env.REACT_APP_BRAINTREE_TOKENIZATION_KEY}`,
      'Braintree-Version': '2018-05-10',
    },
  };

  const expirationDateParts = expiration.split('/');

  try {
    const response = await axios.post(
      process.env.REACT_APP_BRAINTREE_TOKENIZATION_URL!,
      {
        query:
          'mutation TokenizeCreditCard($input: TokenizeCreditCardInput!) { tokenizeCreditCard(input: $input) { token creditCard { bin brandCode last4 cardholderName expirationMonth expirationYear binData { prepaid healthcare debit durbinRegulated commercial payroll issuingBank countryOfIssuance productId } } } }',
        variables: {
          input: {
            creditCard: {
              number,
              expirationMonth: expirationDateParts[0].trim(),
              expirationYear: expirationDateParts[1].trim(),
              cvv,
            },
            options: {
              validate: false,
            },
          },
        },
        operationName: 'TokenizeCreditCard',
      },
      config
    );

    return response.data.data.tokenizeCreditCard.token;
  } catch (error) {
    throw error;
  }
};

export const getDeviceData = async () => {
  try {
    const tokenResponse = await PaymentServices.getBraintreeAuth();
    const clientInstance: Client = await client.create({ authorization: tokenResponse.token });
    const dataCollectorInstance: DataCollector = await dataCollector.create({
      client: clientInstance,
      kount: true,
    });
    return dataCollectorInstance.deviceData;
  } catch (error) {
    throw error;
  }
};
