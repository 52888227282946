import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useRef, useState } from 'react';
import { Alert, Button, Col, Container, InputGroup, Modal, Row, Spinner } from 'react-bootstrap';
import { FieldValues, useForm } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import { useQuery } from 'react-query';
import Select from 'react-select';
import * as yup from 'yup';
import {
  IconOptionCountry,
  IconOptionProvince,
  IconSingleValueCountry,
  IconSingleValueProvince,
  reactSelectStyles,
} from '../../../config/reactSelect';
import { MODAL_TYPES, useGlobalModalContext } from '../../../providers/globalModal.provider';
import { ContactService, CountryServices, UtilService } from '../../../services';
import { RemittanceServices } from '../../../services/remittance';
import { StoreService } from '../../../services/store';
import { MegaContact } from '../../../types/contacts';
import { Country } from '../../../types/country';
import { Municipality } from '../../../types/municipality';
import { Province } from '../../../types/province';
import { QueryTypes } from '../../../types/queryTypes';
import { FormHelpers } from '../../../utilities/FormHelpers';
import { MegaLoading } from '../../external/megaLoading';
import { MegaError } from '../megaError';

export type CreateContactProp = {
  display: boolean;
  acceptRemittance?: boolean;
  onCancel?: () => void;
  onSave?: () => void;
  contact?: MegaContact;
};

const contactSchema = yup
  .object()
  .shape({
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    nauta: yup
      .string()
      .optional()
      .test('email', 'Su email nauta no es válido.', (value) => {
        if (!value) {
          return true;
        }

        const schema = yup.string().email();
        return schema.isValidSync(`${value}@nauta.com.cu`);
      }),
    address: yup.string().optional(),
    town: yup.string().optional(),
    carneId: yup
      .string()
      .matches(/^[0-9]+$/, {
        message: 'Este campo debe contener solo números.',
        excludeEmptyString: true,
      })
      .test('length', 'Este campo debe tener 11 dígitos.', (value) => {
        if (!value) {
          return true;
        }

        if (`${value}`.length !== 11) {
          return false;
        }

        return true;
      })
      .optional(),
    mlcCard: yup.string().optional(),
  })
  .required();

export const CreateContact = ({
  display,
  onCancel,
  onSave,
  acceptRemittance,
  contact,
}: CreateContactProp) => {
  const { showModal, hideModal } = useGlobalModalContext();
  const phoneRef = useRef<any>(null);

  const [selectedCountry, setSelectedCountry] = useState<Country | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneHasError, setPhoneHasError] = useState(false);
  const [provinces, setProvinces] = useState<Province[]>([]);
  const [municipalities, setMunicipalities] = useState<Municipality[]>([]);
  const [selectedProvince, setSelectedProvince] = useState<Province | null>(null);
  const [selectedMunicipality, setSelectedMunicipality] = useState<Municipality | null>(null);

  const { register, handleSubmit, formState, reset, clearErrors, setValue } = useForm({
    resolver: yupResolver(contactSchema),
    mode: 'onChange',
  });

  const countryQuery = useQuery(
    QueryTypes.GetCountriesForContact,
    CountryServices.getAllCountriesForContacts
  );

  const provincesQuery = useQuery(QueryTypes.GetStoreProvinces, StoreService.getProvinces);
  const municipalitiesQuery = useQuery(
    [QueryTypes.GetStoreMunicipalities, selectedProvince],
    () => {
      if (!!selectedProvince) {
        return StoreService.getMunicipalities(selectedProvince.id);
      }

      return [];
    }
  );

  const screenQueries = [countryQuery, provincesQuery];

  useEffect(() => {
    if (provincesQuery.data) {
      setProvinces(provincesQuery.data);

      if (!selectedProvince && provincesQuery.data.length > 0) {
        let defaultValue = provincesQuery.data[0];
        if (contact && contact.province_id) {
          const v = provincesQuery.data.find((t) => t.id === contact.province_id);
          if (v) {
            defaultValue = v;
          }
        }
        setSelectedProvince(defaultValue);
        setSelectedMunicipality(null);
      }
    }
  }, [provincesQuery.data]);

  useEffect(() => {
    if (municipalitiesQuery.data) {
      setMunicipalities(municipalitiesQuery.data);

      if (
        municipalitiesQuery.data.length > 0 &&
        (!selectedMunicipality ||
          !municipalitiesQuery.data.find((t) => t.id === selectedMunicipality.id))
      ) {
        let defaultValue = municipalitiesQuery.data[0];
        if (contact && contact.municipality_id) {
          const v = municipalitiesQuery.data.find((t) => t.id === contact.municipality_id);
          if (v) {
            defaultValue = v;
          }
        }

        setSelectedMunicipality(defaultValue);
      }
    }
  }, [municipalitiesQuery.data]);

  useEffect(() => {
    if (!contact) {
      return;
    }

    let localNauta = '';
    if (contact.email) {
      localNauta = (contact.email || '').split('@')[0];
    }

    setValue('first_name', contact.first_name || contact.contact_name);
    setValue('last_name', contact.last_name);
    setValue('last_name', contact.last_name);
    setValue('nauta', localNauta);
    setValue('address', contact.address);
    setValue('town', contact.town);
    setValue('carneId', contact.carnet);
    setValue('mlcCard', contact.mlc_card);
  }, [contact]);

  useEffect(() => {
    if (countryQuery.status === 'success') {
      const defaultCountry = contact?.country_code ?? 'cu';
      let defaultCountryObj = (countryQuery.data || []).find(
        (e) => e.countcod.toLowerCase() === defaultCountry.toLowerCase()
      );
      defaultCountryObj = defaultCountryObj ?? countryQuery.data[0];

      if (contact && contact.contact_number) {
        let myPhone = contact.contact_number;
        myPhone = myPhone.replace(defaultCountryObj.calling_code, '');
        setPhone(myPhone);

        if (phoneRef && phoneRef.current && phoneRef.current.maskRef) {
          phoneRef.current.maskRef.masked.reset();
        }
      }

      setSelectedCountry(defaultCountryObj);
    }
  }, [countryQuery.status]);

  useEffect(() => {
    if (!contact) {
      setPhone('');
      setPhoneHasError(false);

      if (phoneRef && phoneRef.current && phoneRef.current.maskRef) {
        phoneRef.current.maskRef.masked.reset();
      }
    }
  }, [selectedCountry]);

  useEffect(() => {
    !!phone && validatePhone();
  }, [phone]);

  const getMask = () => {
    let phoneMask: string | null = null;
    if (selectedCountry?.mobile_mask) {
      phoneMask = selectedCountry?.mobile_mask;
    }

    if (!phoneMask) {
      return null;
    }

    return phoneMask;
  };

  const getPhoneMask = () => {
    const mask = getMask();
    if (mask) {
      return UtilService.convertPhoneMask(mask, true);
    }

    return Number;
  };

  const validatePhone = () => {
    const mask = getMask();
    let error = false;

    if (mask) {
      error = UtilService.getMaskLength(mask) !== (phone || '').length;
    }

    setPhoneHasError(error);
    return error;
  };

  const onCloseReset = (isSave?: boolean) => {
    reset();
    setPhone('');
    setPhoneHasError(false);
    setErrorMessage('');

    if (phoneRef && phoneRef.current && phoneRef.current.maskRef) {
      phoneRef.current.maskRef.masked.reset();
    }

    if (isSave) {
      !!onSave && onSave();
    } else {
      !!onCancel && onCancel();
    }
  };

  const validateMlcCard = async (number: string) => {
    showModal(MODAL_TYPES.LOADING);

    const isValid = await RemittanceServices.validateMlc(number);

    hideModal();

    if (!isValid) {
      showModal(MODAL_TYPES.ERROR, {
        title: '¡Error de tarjeta MLC!',
        description:
          'Su tarjeta MLC no contiene un formato válido. Por favor, verifique su tarjeta e intente de nuevo',
        state: {
          buttonTitle: 'Continuar',
          cardError: true,
        },
        onClose: () => {},
      });
      return false;
    }

    return true;
  };

  const onSubmit = (data: FieldValues) => {
    if (!contact) {
      submitNewContact(data);
    } else {
      submitEditContact(data);
    }
  };

  const submitEditContact = async (data: FieldValues) => {
    try {
      if (!!data.mlcCard) {
        const isValid = await validateMlcCard(data.mlcCard);
        if (!isValid) {
          return;
        }
      }

      showModal(MODAL_TYPES.LOADING);

      const response = await ContactService.editContact(
        contact!.id,
        data.first_name,
        data.last_name,
        data.nauta ? `${data.nauta}@nauta.com.cu` : undefined,
        data.address,
        !!selectedProvince ? selectedProvince.id : undefined,
        !!selectedMunicipality ? selectedMunicipality.id : undefined,
        data.town,
        data.carneId,
        data.mlcCard
      );

      hideModal();

      if (response.success) {
        onCloseReset(true);
      } else {
        onError(false);
      }
    } catch (e) {
      hideModal();
      onError(false);
    }
  };

  const submitNewContact = async (data: FieldValues) => {
    validatePhone();
    if (validatePhone() || !selectedCountry) {
      return;
    }

    try {
      if (!!data.mlcCard) {
        const isValid = await validateMlcCard(data.mlcCard);
        if (!isValid) {
          return;
        }
      }

      showModal(MODAL_TYPES.LOADING);

      const response = await ContactService.createContact(
        data.first_name,
        data.last_name,
        selectedCountry.countcod,
        `${selectedCountry.calling_code}${phone}`,
        data.nauta ? `${data.nauta}@nauta.com.cu` : undefined,
        data.address,
        !!selectedProvince ? selectedProvince.id : undefined,
        !!selectedMunicipality ? selectedMunicipality.id : undefined,
        data.town,
        data.carneId,
        data.mlcCard
      );

      hideModal();

      if (response.success) {
        onCloseReset(true);
      } else {
        onError(response.isDuplicated);
      }
    } catch (e) {
      hideModal();

      onError(false);
    }
  };

  const onError = (isDuplicated: boolean) => {
    showModal(MODAL_TYPES.ERROR, {
      title: '¡Error!',
      description: isDuplicated
        ? 'Ya existe un contacto con el número de teléfono entrado. Por favor entre uno diferente.'
        : 'Hubo un error salvando su contacto. Por favor, intente de nuevo.',
      state: {
        buttonTitle: isDuplicated ? 'Continuar' : 'Reintentar',
        cardError: false,
      },
      onClose: (_) => {},
    });
  };

  return (
    <Modal size="lg" show={display} onHide={onCloseReset} backdrop="static" centered>
      <form noValidate onSubmit={handleSubmit(onSubmit)}>
        <Modal.Header closeButton className="mega-modal-title">
          {!contact && <Modal.Title>Agrega tu contacto</Modal.Title>}
          {!!contact && <Modal.Title>Edita tu contacto</Modal.Title>}
        </Modal.Header>
        <Modal.Body>
          {UtilService.isLoadingQueries(screenQueries) && <MegaLoading isLoading={true} />}
          {UtilService.isErrorQueries(screenQueries) && (
            <MegaError displayRetry retry={() => UtilService.refetchQueries(screenQueries)} />
          )}
          {UtilService.isSuccessQueries(screenQueries) && (
            <Container>
              {errorMessage && !isSubmitting && (
                <Row className="mb-3">
                  <Col xs={12}>
                    <Alert variant={'danger'}>{errorMessage}</Alert>
                  </Col>
                </Row>
              )}

              <Row className="mt-4">
                <Col xs={12} lg={6}>
                  <div className="megaInput mb-4">
                    <label htmlFor="first_name">Nombre</label>
                    <input
                      type="text"
                      className={`form-control mega ${
                        formState.errors.first_name ? 'border border-danger' : ''
                      }`}
                      id="first_name"
                      {...register('first_name')}
                    />
                    {formState.errors.first_name && (
                      <div className="text-danger">El Nombre es requerido</div>
                    )}
                  </div>
                </Col>
                <Col xs={12} lg={6}>
                  <div className="megaInput mb-4">
                    <label htmlFor="last_name">Apellidos(s)</label>
                    <input
                      type="text"
                      className={`form-control mega ${
                        formState.errors.last_name ? 'border border-danger' : ''
                      }`}
                      id="last_name"
                      {...register('last_name')}
                    />
                    {formState.errors.last_name && (
                      <div className="text-danger">El Apellido(s) es requerido</div>
                    )}
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={12} lg={6}>
                  <div className="megaInput mb-4">
                    <label htmlFor="country">Pa&iacute;s</label>

                    <Select
                      id="country"
                      placeholder=""
                      value={selectedCountry}
                      options={countryQuery.data}
                      isSearchable={false}
                      isClearable={false}
                      className="w-100 mega-select"
                      isMulti={false}
                      styles={reactSelectStyles}
                      components={{
                        Option: IconOptionCountry,
                        SingleValue: IconSingleValueCountry,
                      }}
                      onChange={(newValue) => {
                        setSelectedCountry(newValue);
                      }}
                      isDisabled={!!contact}
                    />
                  </div>
                </Col>

                <Col xs={12} lg={6} className="mb-4">
                  <div className="megaInput">
                    <label htmlFor="phone" className="form-label mega">
                      Tel&eacute;fono
                    </label>

                    <InputGroup>
                      <span className="input-group-text mega less">
                        +{selectedCountry?.calling_code}
                      </span>
                      {!contact && (
                        <IMaskInput
                          mask={getPhoneMask() as any}
                          unmask={true}
                          ref={phoneRef}
                          value={phone}
                          onAccept={(value, _) => setPhone(`${value}`)}
                          lazy={false}
                          className={`form-control mega ${
                            phoneHasError ? 'border border-danger' : ''
                          }`}
                        />
                      )}
                      {!!contact && (
                        <input type="text" className="form-control mega" value={phone} disabled />
                      )}
                    </InputGroup>
                  </div>
                  {!!phoneHasError && <div className="text-danger">El teléfono es requerido</div>}
                </Col>
              </Row>

              {(selectedCountry?.countcod || '').toLowerCase() === 'cu' && (
                <Row>
                  <Col xs={12} className="mb-2">
                    <div className="megaInput">
                      <label htmlFor="nauta">
                        Nauta <small>(Opcional)</small>
                      </label>

                      <div className="input-group">
                        <input
                          type="text"
                          className={`form-control mega ${
                            formState.errors.nauta ? 'border border-danger' : ''
                          }`}
                          id="nauta"
                          autoComplete="off"
                          {...register('nauta')}
                        />
                        <span className="input-group-text mega gray">@nauta.com.cu</span>
                      </div>
                    </div>
                    {formState.errors.nauta && (
                      <div className="text-danger">{formState.errors.nauta.message}</div>
                    )}

                    <hr className="mt-5 mb-0 pb-0" />
                  </Col>
                </Row>
              )}
              {(selectedCountry?.countcod || '').toLowerCase() === 'cu' && (
                <>
                  <Row className="mt-2 mb-4">
                    <Col>
                      <h6>Información para Compras</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12} lg={6}>
                      <div className="megaInput mb-4">
                        <label htmlFor="address">Dirección</label>
                        <input
                          type="text"
                          className={`form-control mega ${
                            formState.errors.address ? 'border border-danger' : ''
                          }`}
                          id="address"
                          {...register('address')}
                        />
                      </div>
                    </Col>

                    <Col xs={12} lg={6}>
                      <div className="megaInput mb-4">
                        <label htmlFor="town">Reparto</label>
                        <input
                          type="text"
                          className={`form-control mega ${
                            formState.errors.town ? 'border border-danger' : ''
                          }`}
                          id="town"
                          {...register('town')}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={12} lg={6} className="mb-4">
                      <div className="megaInput">
                        {FormHelpers.formLabel('Provincia', 'province_id')}
                        <Select
                          id="province_id"
                          placeholder=""
                          value={selectedProvince}
                          options={provinces}
                          isSearchable={false}
                          isClearable={false}
                          className="w-100 mega-select"
                          isMulti={false}
                          styles={reactSelectStyles}
                          components={{
                            Option: IconOptionProvince,
                            SingleValue: IconSingleValueProvince,
                          }}
                          onChange={(newValue) => {
                            setSelectedProvince(newValue);
                          }}
                        />
                      </div>
                    </Col>

                    <Col xs={12} lg={6} className="mb-4">
                      <div className="megaInput">
                        {FormHelpers.formLabel('Municipio', 'municipality_id')}
                        <Select
                          id="municipality_id"
                          placeholder=""
                          value={selectedMunicipality}
                          options={municipalities}
                          isSearchable={false}
                          isClearable={false}
                          className="w-100 mega-select"
                          isMulti={false}
                          styles={reactSelectStyles}
                          components={{
                            Option: IconOptionProvince,
                            SingleValue: IconSingleValueProvince,
                          }}
                          onChange={(newValue) => {
                            setSelectedMunicipality(newValue);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                </>
              )}

              {acceptRemittance && (selectedCountry?.countcod || '').toLowerCase() === 'cu' && (
                <>
                  <Row>
                    <Col>
                      <hr className="mt-2 mb-0 pb-0" />
                      <h6 className="mt-3 mb-4">Información para Remesas</h6>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="mb-3">
                      <div className="megaInput">
                        {FormHelpers.formLabel('Carnet de Identidad', 'carneId')}
                        <input
                          type="text"
                          className={`form-control mega ${FormHelpers.errorClass(
                            'carneId',
                            formState.errors
                          )}`}
                          id="carneId"
                          autoComplete="off"
                          {...register('carneId')}
                        />
                      </div>
                      {FormHelpers.formError('carneId', formState.errors)}
                    </Col>
                    <Col className="mb-3">
                      <div className="megaInput">
                        {FormHelpers.formLabel('Tarjeta MLC', 'mlcCard')}
                        <input
                          type="text"
                          className={`form-control mega ${FormHelpers.errorClass(
                            'mlcCard',
                            formState.errors
                          )}`}
                          autoComplete="cc-number"
                          id="mlcCard"
                          {...register('mlcCard')}
                        />
                      </div>
                      {FormHelpers.formError('mlcCard', formState.errors)}
                    </Col>
                  </Row>
                </>
              )}
            </Container>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="outline-secondary"
            onClick={() => onCloseReset()}
            type="button"
            className="px-5"
            disabled={isSubmitting}>
            Cancelar
          </Button>

          <Button
            variant="secondary text-white"
            type="submit"
            className="px-5"
            disabled={isSubmitting}>
            {isSubmitting ? (
              <Spinner
                as="span"
                animation="grow"
                size="sm"
                role="status"
                variant="light"
                aria-hidden="true"
              />
            ) : (
              <>
                {!contact && <span>Crear</span>}
                {!!contact && <span>Guardar</span>}
              </>
            )}
          </Button>
        </Modal.Footer>
      </form>
    </Modal>
  );
};
