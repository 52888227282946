import { useContext, useEffect, useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';
import { SessionContext } from '../../../providers/session.provider';
import { MegaLoading } from '../../external/megaLoading';

export const RequireAuth: React.FC = ({ children }) => {
  const [isReady, setIsReady] = useState(false);
  const [isAuth, setIsAuth] = useState(false);

  const { isAuthenticated } = useContext(SessionContext);
  const location = useLocation();

  useEffect(() => {
    const isCurrentAuth = isAuthenticated();

    setIsAuth(isCurrentAuth);
    setIsReady(true);
  }, []);

  if (!isReady) {
    return <MegaLoading isLoading />;
  }

  if (!isAuth) {
    return <Navigate to={ROUTES.login} state={{ from: location }} replace />;
  }

  return <>{children}</>;
};
