import { MegaFund } from '../types/funds';
import { NewCardInformation } from '../types/newCardInformation';
import { NewPaypalInformation } from '../types/newPaypalInformation';
import api from './axios/api';
import { getCardNonce, getDeviceData } from './nonce';

const creditCardUrl = '/api/v1/payment/funds/creditcard';
const paypalUrl = '/api/v1/payment/funds/paypal';

type FundWithPaypalParams = {
  acceptReferral: boolean;
  amount: number;
  fee: number;
  paymentMethodId: string;
};

type FundWithNewCreditCardParams = {
  acceptReferral: boolean;
  amount: number;
  fee: number;
  newCardInformation: NewCardInformation;
};

type FundWithNewPaypalParams = {
  amount: number;
  acceptReferral: boolean;
  fee: number;
  newPaypalInformation: NewPaypalInformation;
};

const getFundProducts = async (): Promise<MegaFund[]> => {
  try {
    const response = await api.get<MegaFund>('/api/v1/funds/listfundproducts');

    if (!!response.array && response.array.length > 0) {
      return response.array.map((item) => ({
        amount: item.amount,
        bonus: item.bonus,
        label: `${parseFloat(item.amount).toFixed(2)}${item.bonus ? ' ' + item.bonus : ''}`,
      }));
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const fundWithCreditCard = async ({
  acceptReferral,
  amount,
  fee,
  paymentMethodId,
}: FundWithPaypalParams): Promise<boolean> => {
  try {
    const deviceData = await getDeviceData();
    const response = await api.post<any>(creditCardUrl, {
      accept_referral: acceptReferral ? 1 : 0,
      amount,
      device_data: deviceData,
      fee,
      payment_method_id: paymentMethodId,
      is_new_method: 0,
      save_method: 0,
    });

    if (!response.has_error) {
      return true;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const fundWithPaypal = async ({
  acceptReferral,
  amount,
  fee,
  paymentMethodId,
}: FundWithPaypalParams): Promise<boolean> => {
  try {
    const deviceData = await getDeviceData();
    const response = await api.post<any>(paypalUrl, {
      accept_referral: acceptReferral ? 1 : 0,
      amount,
      device_data: deviceData,
      fee,
      payment_method_id: paymentMethodId,
      is_new_method: 0,
      save_method: 0,
    });

    if (!response.has_error) {
      return true;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const fundWithNewCreditCard = async ({
  acceptReferral,
  amount,
  fee,
  newCardInformation,
}: FundWithNewCreditCardParams): Promise<boolean> => {
  try {
    const nonce = await getCardNonce({
      number: newCardInformation.cardNumber,
      expiration: newCardInformation.cardExpiration,
      cvv: newCardInformation.cardCvc,
    });
    const deviceData = await getDeviceData();

    const response = await api.post<any>(creditCardUrl, {
      nonce,
      device_data: deviceData,
      accept_referral: acceptReferral ? 1 : 0,
      is_new_method: 1,
      save_method: newCardInformation.saveMethod ? 1 : 0,
      title: newCardInformation.title,
      cc_number: newCardInformation.cardNumber.replace(/\D/g, ''),
      cvc: newCardInformation.cardCvc,
      exp_date: newCardInformation.cardExpiration.replace(/\D/g, ''),
      first_name: newCardInformation.first_name,
      last_name: newCardInformation.last_name,
      address1: newCardInformation.address1,
      city: newCardInformation.city,
      state: newCardInformation.state,
      zip: newCardInformation.zip,
      country: newCardInformation.country,
      amount,
      fee,
    });

    if (!response.has_error) {
      return true;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const fundWithNewPaypal = async ({
  amount,
  acceptReferral,
  fee,
  newPaypalInformation,
}: FundWithNewPaypalParams): Promise<boolean> => {
  try {
    const deviceData = await getDeviceData();
    const response = await api.post<any>(paypalUrl, {
      accept_referral: acceptReferral ? 1 : 0,
      amount,
      device_data: deviceData,
      fee,
      nonce: newPaypalInformation.nonce,
      is_new_method: 1,
      save_method: newPaypalInformation.saveMethod ? 1 : 0,
    });

    if (!response.has_error) {
      return true;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

export const FundServices = {
  getFundProducts,
  fundWithCreditCard,
  fundWithPaypal,
  fundWithNewCreditCard,
  fundWithNewPaypal,
};
