import { useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { FaExclamationTriangle } from 'react-icons/fa';
import { IMaskInput } from 'react-imask';
import { useQuery } from 'react-query';
import Select from 'react-select';
import {
  IconOptionCarrier,
  IconOptionTopUpCountry,
  IconSingleValueCarrier,
  IconSingleValueTopUpCountry,
  reactSelectStyles,
} from '../../../../config/reactSelect';
import { useTopUp } from '../../../../context/TopupContext';
import { UtilService } from '../../../../services';
import { CarrierServices } from '../../../../services/carrier';
import { ValidationServices } from '../../../../services/validation';
import { TopUpCountry } from '../../../../types/country';
import { QueryTypes } from '../../../../types/queryTypes';
import { TopUpMobileItem } from '../../../../types/topupItems';
import { MegaCarrier, MegaCarrierAllRates, MegaCarrierRate } from '../../../../types/topups';
import { Formatter } from '../../../../utilities';
import { handlePhoneNumberPaste } from '../../../../utilities/TopUpHelpers';
import { MegaLoading } from '../../../external';
import { TopupProducts, TopupProductsProps } from '../../topupProducts';
import { TopUpAddButton } from '../topupAddButton';

export const TopUpMobilePhone = () => {
  
  const [countryList, setCountryList] = useState<TopUpCountry[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<TopUpCountry>();
  const [carrierList, setCarrierList] = useState<MegaCarrier[]>();
  const [selectedCarrier, setSelectedCarrier] = useState<MegaCarrier>();
  const [carrierRateList, setCarrierRateList] = useState<MegaCarrierRate[]>();
  const [selectedCarrierRate, setSelectedCarrierRate] = useState<MegaCarrierRate>();
  const [productList, setProductList] = useState<TopupProductsProps['products']>([]);
  const [otherPhoneNumber, setOtherPhoneNumber] = useState<string>();
  const [otherPhoneNumberMask, setOtherPhoneNumberMask] = useState<string>('');
  const [isCarrierAndRateVisible, setIsCarrierAndRateVisible] = useState<boolean>(false);  
  const [warningMessage, setWarningMessage] = useState<string>(''); 
  const [isLoadingCarrier, setIsLoadingCarrier] = useState<boolean>(false);
  const [isLoadingRates, setIsLoadingRates] = useState<boolean>(false);  
  
  const {
    addMobileItem,
    getApplicableMobileDiscount,    
    setTopUpInitialData,
    topUpInitialData,
  } = useTopUp();
  const countriesQuery = useQuery(QueryTypes.GetCountriesForTopUp, CarrierServices.getCountries);

  const carriersQuery = useQuery(
    [QueryTypes.GetTopUpCarriers, selectedCountry],
    () => {
      if (selectedCountry) {
        setIsLoadingCarrier(true);
        setIsLoadingRates(true);
        return CarrierServices.getCarriers(selectedCountry.count_cod, 'TOPUP');
      } else {
        return [];
      }
    },
    {
      cacheTime: 0,
      onSettled: () => {
        setIsLoadingCarrier(false);
      },
    }
  );

  const carrierRatesQuery = useQuery(
    [QueryTypes.GetTopUpCarrierRates, selectedCarrier],
    async () => {
      if (selectedCarrier) {
        setIsLoadingRates(true);
        return CarrierServices.getCarrierRates(selectedCarrier.uid);
      } else {
        return [];
      }
    },
    {
      cacheTime: 0,
      onSettled: () => {
        setIsLoadingRates(false);
      },
    }
  );

  const blacklistedPhoneQuery = useQuery([QueryTypes.GetBlacklistedPhone, otherPhoneNumber], () => {
    if (isOtherPhoneNumberComplete()) {
      return ValidationServices.checkBlacklistedPhone(Formatter.onlyNumbers(otherPhoneNumber!));
    } else {
      return false;
    }
  });

  const checkPreviousTopupQuery = useQuery([QueryTypes.GetPreviousTopup, otherPhoneNumber], () => {
    if (isOtherPhoneNumberComplete()) {
      return ValidationServices.checkPreviousTopup(Formatter.onlyNumbers(otherPhoneNumber!));
    } else {
      return false;
    }
  });

  const placeholderMask = () => {
    return selectedCountry?.mobile_mask.replaceAll('N', '_');
  };

  const isOtherPhoneNumberComplete = () => {
    return !!otherPhoneNumber && otherPhoneNumber.length == selectedCountry?.mobile_mask.length;
  };

  useEffect(() => {
    if (countriesQuery.status === 'success') {
      setCountryList(countriesQuery.data);
      setSelectedCountry(
        countriesQuery.data.find((country) => country.count_cod == 'CU') || countriesQuery.data[0]
      );
    }
  }, [countriesQuery.status]);

  useEffect(() => {
    if (carriersQuery.status === 'success') {
      setCarrierList(carriersQuery.data);
      setSelectedCarrier(carriersQuery.data[0]);
      setIsCarrierAndRateVisible(carriersQuery.data.length === 1);
    }
  }, [carriersQuery.data]);

  useEffect(() => {    
    if (carrierRatesQuery.status === 'success') {  
      let rateData = carrierRatesQuery.data as MegaCarrierAllRates; 
      if (rateData?.initial_data){
        setTopUpInitialData(rateData?.initial_data);
        let rateList = rateData.products;                   
        convertProductList(rateList);      
        setCarrierRateList(rateList);
        setSelectedCarrierRate(rateList[0]);
      }
    }
  }, [carrierRatesQuery.data]);

  useEffect(() => {
    if (blacklistedPhoneQuery.status === 'success') {      
      if (blacklistedPhoneQuery.data) {
        setWarningMessage('No es posible recargar a este número. Por favor comunícate con soporte técnico para más información.');
      }
    }
  }, [blacklistedPhoneQuery.data]);

  useEffect(() => {
    if (checkPreviousTopupQuery.status === 'success') {                 
      if (checkPreviousTopupQuery.data) {
        setWarningMessage('No es posible recargar a este número debido a que se recargó en los últimos 5 minutos.');
      }
    }
  }, [checkPreviousTopupQuery.data]);
  

  useEffect(() => {
    if (selectedCountry) {
      setOtherPhoneNumber('');
      setOtherPhoneNumberMask(CarrierServices.getCountryPhoneMask(selectedCountry));
    }
  }, [selectedCountry, topUpInitialData]);

  useEffect(() => {
    if (isOtherPhoneNumberComplete()) {
      getCarrierByPhone().then(() => {
        setIsCarrierAndRateVisible(true);
      });
    }
    if (otherPhoneNumber === '') {
      setIsCarrierAndRateVisible(false);
    } else {
      setIsCarrierAndRateVisible(carrierList !== undefined && carrierList.length === 1);
    }
  }, [otherPhoneNumber]);
 
  const getCarrierByPhone = async () => {
    try {
      const carrierId = await CarrierServices.getCarrierByPhoneAndCategory(
        otherPhoneNumber!,
        'TOPUP'
      );

      if (carrierId) {
        const preferredCarrier = carrierList?.find((carrier) => {
          return `${carrier.uid}` === `${carrierId}`;
        });

        if (preferredCarrier) {
          setSelectedCarrier(preferredCarrier);
        }
      }
    } catch (error) {
      setSelectedCarrier(undefined);
    }
  }; 

  const convertProductList = (list: MegaCarrierRate[], futureDate: string = '') => {   
    const newProductList: TopupProductsProps['products'] = UtilService.convertProductList(list);
    setProductList(newProductList);
  };

  const onSelectedProduct = (id: string) => {   
  };

  const onAddTopup = (id: string) => {         
    const rate = carrierRateList?.find((r) => `${r.id}` === `${id}`);
    if (rate) {
      try {        
        addMobileItem({
          carrier: selectedCarrier,
          carrierRate: rate,
          country: selectedCountry,
          discount: applicableDiscount,
          phoneNumber: otherPhoneNumber,
          futurePromotionalDate: rate.future_date,
          type: 'other',
        } as TopUpMobileItem);
  
        setOtherPhoneNumber(undefined); 
        setWarningMessage('');     
      } catch (e: any) {                
        setWarningMessage(e.toString());
        window.scrollTo(0, 0);
      }
    }    
  };
  
  const applicableDiscount = getApplicableMobileDiscount({
    countryCode: selectedCountry?.count_cod,
    remote_amount: Number(selectedCarrierRate?.remote_amount ?? 0),
  });

  return (
    <>
      <Row className="mt-5">
        <Col lg={6}>
          <div className="megaInput">
            <label htmlFor="country">Seleccione el país</label>
            <Select
              id="country"
              name="country"
              placeholder=""
              value={selectedCountry}
              options={countryList}
              isSearchable={false}
              isClearable={false}
              isLoading={false}
              className="w-100 mega-select"
              isMulti={false}
              styles={reactSelectStyles}
              components={{
                Option: IconOptionTopUpCountry,
                SingleValue: IconSingleValueTopUpCountry,
              }}
              onChange={(newValue) => {
                setSelectedCountry(newValue as TopUpCountry);
              }}
            />
          </div>
        </Col>

        <Col lg={6} className="mt-4 mt-lg-0">
          <div className="megaInput">
            <label htmlFor="numero1">Número</label>
            <IMaskInput
              id="numero1"
              className={"form-control mega " + (warningMessage!='' ? 'border border-danger' : '')}
              mask={otherPhoneNumberMask}
              placeholder={placeholderMask()}
              value={otherPhoneNumber ?? ''}
              onAccept={(_value, mask) => {
                setOtherPhoneNumber(mask.unmaskedValue ?? '');
              }}
              lazy={false}
              onPasteCapture={(e) => {
                handlePhoneNumberPaste({
                  event: e,
                  mask: selectedCountry?.mobile_mask,
                  setter: (value) => setOtherPhoneNumber(value ?? ''),
                });
              }}
            />
          </div>
        </Col>
      </Row>

      {!isLoadingCarrier && (
        <>
          {warningMessage && (
            <Alert variant={'warning'} className="mt-2">
              <FaExclamationTriangle /> {warningMessage}
            </Alert>
          )}

          {isCarrierAndRateVisible && !isLoadingCarrier && (
            <>
              {selectedCountry && selectedCountry.count_cod !== 'CU' && (
                <Row>
                  <Col className="mt-4">
                    <div className="megaInput">
                      <label htmlFor="carrier">Operador</label>
                      <Select
                        id="carrier"
                        placeholder=""
                        value={selectedCarrier}
                        options={carrierList}
                        isSearchable={false}
                        isClearable={false}
                        isLoading={false}
                        className="w-100 mega-select"
                        isMulti={false}
                        styles={reactSelectStyles}
                        components={{
                          Option: IconOptionCarrier,
                          SingleValue: IconSingleValueCarrier,
                        }}
                        onChange={(newValue) => {
                          setSelectedCarrier(newValue as MegaCarrier);
                        }}
                      />
                    </div>
                  </Col>
                </Row>
              )}

              <Row className="mt-3">
                <Col>
                  <hr />
                </Col>
              </Row>

              {!isLoadingRates && !isLoadingCarrier && (
                <Row className="mt-3">
                  <Col>
                    {!!productList && productList.length > 0 && !!selectedCarrierRate && (
                      <TopupProducts
                        serviceType="topup"
                        selectedId={selectedCarrierRate.id}
                        products={productList}
                        onSelectedChange={onSelectedProduct}
                        onAddTopup={onAddTopup}                        
                      />
                    )}
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
      )}

      {(isLoadingCarrier || isLoadingRates) && <MegaLoading isLoading size={50} />}
    
    </>
  );
};
