import { Comentario, EligePagar, EntregaRapida, Pago } from '../../svg';

import styles from './comboFeatures.module.scss';

export const ComboFeatures = () => {
  const features = [
    {
      key: 'll1',
      title: 'Entrega rápida',
      description: 'Empezar desde $10.',
      icon: EntregaRapida,
    },

    {
      key: 'll2',
      title: 'Comentario',
      description: '97% positivo.',
      icon: Comentario,
    },

    {
      key: 'll3',
      title: 'Elige cómo pagar',
      description: 'Tarjeta, débito o efectivo.',
      icon: EligePagar,
    },

    {
      key: 'll4',
      title: 'Pago',
      description: '100% seguro.',
      icon: Pago,
    },
  ];

  return (
    <div className={`row ${styles.wrapper}`}>
      <div className="col-1 d-lg-none"></div>
      <div className="col-10 col-lg-12">
        <div className="column-alert border-bottom flex-column flex-lg-row">
          {features.map((column, index) => (
            <div className="item-column w-100" key={column.title}>
              <div className="d-flex w-100 flex-column flex-lg-row align-items-center py-4 py-lg-0">
                <div>
                  <column.icon width={60} className={`mt-3 ms-5 ${styles.iconTop}`} />
                </div>
                <div className={`flex-column text-center text-lg-start ${styles.textWrapper}`}>
                  <div className="fw-bold">{column.title}</div>
                  <div>{column.description}</div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="col-1 d-lg-none"></div>
    </div>
  );
};
