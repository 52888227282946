import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useRef, useState } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { FieldValues, useForm } from 'react-hook-form';
import { IMaskInput } from 'react-imask';
import PasswordStrengthBar from 'react-password-strength-bar';
import { useQuery } from 'react-query';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import Select from 'react-select';
import * as yup from 'yup';
import { MegaError } from '../../../components/account/megaError';
import { WizardSteps } from '../../../components/shared';
import { Ojo, OjoTachado } from '../../../components/svg';
import { reactSelectStyles } from '../../../config/reactSelect';
import { ROUTES } from '../../../config/routes';
import { SessionContext } from '../../../providers/session.provider';
import { CountryServices, RegisterService, UtilService } from '../../../services';
import { AuthorizedCountry } from '../../../types/country';
import { QueryTypes } from '../../../types/queryTypes';
import { IconOptionAuthorizedCountry, IconSingleValueAuthorizedCountry } from './options';

import styles from './register-screen.module.scss';

const accountSchema = yup
  .object()
  .shape({
    firstName: yup.string().required('El Nombre es requerido.'),
    lastName: yup.string().required('El Apellido es requerido.'),
    email: yup.string().email('El Email no es válido.').required('El Email es requerido.'),
    password: yup
      .string()
      .min(4, 'La Contraseña debe tener al menos 4 caracteres.')
      .required('La Contraseña es requerida.'),
    passwordConfirmation: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Las contraseñas tienen que coincidir.')
      .required('La Contraseña es requerida.'),
    promoSms: yup.bool().optional(),
  })
  .required();

export const RegisterScreen = () => {
  const phoneRef = useRef<any>(null);
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();
  const { isAuthenticated } = useContext(SessionContext);

  const { watch, register, handleSubmit, formState } = useForm({
    resolver: yupResolver(accountSchema),
    mode: 'onChange',
  });

  const password = watch('password', '');

  // states
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [cofReference, setCofReference] = useState('');
  const [phoneHasError, setPhoneHasError] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState<AuthorizedCountry | null>(null);

  const [passwordType, setPasswordType] = useState<'text' | 'password'>('password');
  const [password2Type, setPassword2Type] = useState<'text' | 'password'>('password');

  // const [password, setPassword] = useState('');

  const countryQuery = useQuery(
    QueryTypes.GetAuthCountries,
    CountryServices.getAllAuthorizedCountries
  );

  const initialQueries = [countryQuery];

  // get ref code from url if exist
  useEffect(() => {
    if (isAuthenticated()) {
      navigate(ROUTES.cuenta.inicio);
      return;
    }

    const ref = searchParams.get('ref');
    if (ref) {
      setCofReference(ref);
    }
  }, []);

  useEffect(() => {
    if (countryQuery.data?.length && !selectedCountry) {
      const usa = countryQuery.data.find((i) => i.count_cod?.toLowerCase() === 'us');

      setSelectedCountry(usa || countryQuery.data[0]);
    }
  }, [countryQuery.data]);

  useEffect(() => {
    !!phone && validatePhone();
  }, [phone]);

  useEffect(() => {
    setPhone('');
    setPhoneHasError(false);

    if (phoneRef && phoneRef.current && phoneRef.current.maskRef) {
      phoneRef.current.maskRef.masked.reset();
    }
  }, [selectedCountry]);

  const validatePhone = () => {
    const error = UtilService.getMaskLength(selectedCountry?.phone_mask) !== phone.length;
    setPhoneHasError(error);
    return error;
  };

  const submitForm = async (data: FieldValues) => {
    const phoneError = validatePhone();

    if (phoneError || !selectedCountry) {
      try {
        phoneRef.current.maskRef.el.input.focus();
      } catch (_) {}
      return;
    }

    setIsSubmitting(true);
    const response = await RegisterService.preRegister(
      data.email,
      phone,
      selectedCountry.count_cod
    );
    setIsSubmitting(false);

    if (!response.success) {
      let error = '';
      const accountError =
        'Si ya tiene una cuenta con nosotros puede entrar con sus credenciales o solicitar un cambio de contraseña si no se acuerda.';
      switch (response.code) {
        case '003':
          error =
            '<strong>Ya existe una cuenta con el número de teléfono entrado.</strong><br/>' +
            accountError;
          break;
        case '004':
          error = '<strong>Ya existe una cuenta con el email entrado.</strong><br/>' + accountError;
          break;
        case '005':
          error =
            'El email entrado no existe o no puede ser verificado. Por favor intente con otro email.';
          break;
        case '006':
          error = 'Imposible crear una nueva cuenta debido a que su teléfono no es válido.';
          break;
        case '500':
        default:
          error = 'Hubo un error verificando su información. Por favor intente de nuevo.';
          break;
      }

      setErrorMessage(error);
      return;
    }

    const storedData = {
      ...data,
      phone,
      cofReference,
      countryCode: selectedCountry?.count_cod,
    };

    navigate(ROUTES.register_validation, { state: storedData });
  };

  const onInvalidSubmit = (fields: FieldValues | undefined) => {
    const hasError = validatePhone();
    if (!fields && hasError) {
      try {
        phoneRef.current.maskRef.el.input.focus();
      } catch (_) {}
      return;
    }
  };

  const switchPasswordType = (type: number) => {
    const pass = type === 1 ? passwordType : password2Type;
    const newPassType = pass === 'password' ? 'text' : 'password';

    if (type === 1) {
      setPasswordType(newPassType);
    } else {
      setPassword2Type(newPassType);
    }
  };

  // if (UtilService.isLoadingQueries(initialQueries)) {
  //   return <MegaLoading isLoading={true} />;
  // }

  if (UtilService.isErrorQueries(initialQueries)) {
    return <MegaError displayRetry retry={() => UtilService.refetchQueries(initialQueries)} />;
  }

  return (
    <div>
      <div className="container my-7">
        <form noValidate onSubmit={handleSubmit(submitForm, onInvalidSubmit)}>
          <div className="row">
            <div className="col-xl-3 col-lg-2 col-md-0"></div>
            <div className="col-xl-6 col-lg-8 col-md-12">
              <h1 className="text-center text-uppercase mega-font-bold fs-3 text-center">
                CREA TU CUENTA
              </h1>

              <WizardSteps
                currentStep={1}
                initialStep={1}
                steps={[{ title: 'Datos Personales' }, { title: 'Validación' }]}
              />

              <p className="w-100 text-center my-5">
                Complete el siguiente formulario con sus datos personales. Por razones de seguridad,
                su email y teléfono celular serán validados.
              </p>
            </div>
            <div className="col-xl-3 col-lg-2 col-md-0"></div>
          </div>

          <div className="row mt-4 mb-5">
            <div className="col-xl-4 col-lg-4 col-md-0"></div>
            <div className="col-xl-4 col-lg-4 col-md-12">
              <div className="megaInput">
                <label htmlFor="tel">Seleccione el país</label>
                <Select
                  placeholder=""
                  value={selectedCountry}
                  options={countryQuery.data}
                  isSearchable={false}
                  isClearable={false}
                  isLoading={UtilService.isLoadingQueries([countryQuery])}
                  className="w-100 mega-select"
                  isMulti={false}
                  styles={reactSelectStyles}
                  components={{
                    Option: IconOptionAuthorizedCountry,
                    SingleValue: IconSingleValueAuthorizedCountry,
                  }}
                  onChange={(newValue) => {
                    setSelectedCountry(newValue);
                  }}
                />
              </div>
            </div>
            <div className="col-xl-4 col-lg-4 col-md-0"></div>
          </div>

          <div className="row">
            <div className="col-xl-3 col-lg-2 col-md-0"></div>
            <div className="col-xl-3 col-lg-4 col-md-12">
              <div className="mb-4">
                <div className="megaInput">
                  <label htmlFor="firstName">Nombre</label>
                  <input
                    type="text"
                    className={`form-control mega ${
                      formState.errors.firstName ? 'border border-danger' : ''
                    }`}
                    id="firstName"
                    {...register('firstName')}
                  />
                  {formState.errors.firstName && (
                    <div className="text-danger">{formState.errors.firstName.message}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-12">
              <div className="mb-4">
                <div className="megaInput">
                  <label htmlFor="lastName">Apellido(s)</label>
                  <input
                    type="text"
                    className={`form-control mega ${
                      formState.errors.lastName ? 'border border-danger' : ''
                    }`}
                    id="lastName"
                    {...register('lastName')}
                  />
                  {formState.errors.lastName && (
                    <div className="text-danger">{formState.errors.lastName.message}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-2 col-md-0"></div>
          </div>

          <div className="row">
            <div className="col-xl-3 col-lg-2 col-md-0"></div>
            <div className="col-xl-3 col-lg-4 col-md-12">
              <div className="mb-4">
                <div className="megaInput">
                  <label htmlFor="email">Email</label>
                  <input
                    type="text"
                    className={`form-control mega ${
                      formState.errors.email ? 'border border-danger' : ''
                    }`}
                    id="email"
                    {...register('email')}
                  />
                  {formState.errors.email && (
                    <div className="text-danger">{formState.errors.email.message}</div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-12">
              <div className="mb-4">
                <div className="megaInput">
                  <label htmlFor="phone">Número de teléfono</label>

                  <IMaskInput
                    mask={UtilService.convertPhoneMask(selectedCountry?.phone_mask)}
                    unmask={true}
                    ref={phoneRef}
                    value={phone}
                    type="tel"
                    id="phone"
                    onAccept={(value, _) => setPhone(`${value}`)}
                    placeholder=""
                    className={`form-control mega ${phoneHasError ? 'border border-danger' : ''}`}
                  />
                  {phoneHasError && <div className="text-danger">El teléfono es requerido</div>}
                </div>
              </div>
            </div>
            <div className="col-xl-3 col-lg-2 col-md-0"></div>
          </div>

          <div className="row">
            <div className="col-xl-3 col-lg-2 col-md-0"></div>
            <div className="col-xl-3 col-lg-4 col-md-12">
              <div className="mb-4">
                <div className="megaInputWithIcon">
                  <input
                    {...register('password')}
                    id="password"
                    type={passwordType}
                    placeholder="Contraseña"
                    className={`form-control mega ${
                      formState.errors.password ? 'border border-danger' : ''
                    }`}
                    maxLength={25}
                  />
                  {passwordType === 'password' && (
                    <OjoTachado width={20} className="icon" onClick={() => switchPasswordType(1)} />
                  )}
                  {passwordType === 'text' && (
                    <Ojo width={20} className="icon" onClick={() => switchPasswordType(1)} />
                  )}
                </div>
                {!!password && !formState.errors.password && (
                  <PasswordStrengthBar
                    password={password}
                    minLength={4}
                    shortScoreWord={'demasiado corto'}
                    barColors={['#ddd', '#ff4b55', '#ff9855', '#263645', '#8ab934']}
                    scoreWords={['débil', 'débil', 'okay', 'mejor', 'fuerte']}
                  />
                )}
                {formState.errors.password && (
                  <div className="text-danger">{formState.errors.password.message}</div>
                )}
              </div>
            </div>
            <div className="col-xl-3 col-lg-4 col-md-12">
              <div className="mb-4">
                <div className="megaInputWithIcon">
                  <input
                    type={password2Type}
                    placeholder="Confirma la contraseña"
                    className={`form-control mega ${
                      formState.errors.passwordConfirmation ? 'border border-danger' : ''
                    }`}
                    maxLength={25}
                    id="passwordConfirmation"
                    {...register('passwordConfirmation')}
                  />
                  {password2Type === 'password' && (
                    <OjoTachado width={20} className="icon" onClick={() => switchPasswordType(2)} />
                  )}
                  {password2Type === 'text' && (
                    <Ojo width={20} className="icon" onClick={() => switchPasswordType(2)} />
                  )}
                </div>
                {formState.errors.passwordConfirmation && (
                  <div className="text-danger">{formState.errors.passwordConfirmation.message}</div>
                )}
              </div>
            </div>
            <div className="col-xl-3 col-lg-2 col-md-0"></div>
          </div>

          <div className="row mt-3">
            <div className="col-xl-3 col-lg-2 col-md-0"></div>
            <div className="col-xl-6 col-lg-8 col-md-12">
              <div className="mt-2 form-check text-start text-md-center">
                <input
                  type="checkbox"
                  className={`form-check-input mega-register`}
                  id="promoSms"
                  {...register('promoSms')}
                />
                <label htmlFor="promoSms" className="form-check-label ps-2">
                  Acepto recibir emails y SMS promocionales de Megaconecta
                </label>
              </div>

              <div className="text-center mt-4">
                <p className={styles.pTerms}>
                  Al hacer clic en el botón a continuación, usted acepta y ha leído nuestros{' '}
                  <Link
                    to={ROUTES.termsAndConditions}
                    className="text-secondary text-decoration-underline">
                    Términos y condiciones.
                  </Link>
                </p>
              </div>

              {errorMessage && !isSubmitting && (
                <div className="mt-3">
                  <Alert variant={'danger'}>
                    <div dangerouslySetInnerHTML={{ __html: errorMessage }}></div>
                  </Alert>
                </div>
              )}

              <div className={`mt-3 text-center ${styles.buttonWrapper}`}>
                <Button
                  variant="secondary"
                  className="py-2 w-100"
                  type="submit"
                  disabled={isSubmitting}>
                  {isSubmitting ? (
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      variant="light"
                      aria-hidden="true"
                    />
                  ) : (
                    <span>Continuar</span>
                  )}
                </Button>
              </div>

              <div className="mt-3">
                <p className="text-center">
                  ¿Ya posee una cuenta con nosotros?{' '}
                  <Link to={ROUTES.login} className="text-secondary">
                    Iniciar sesión
                  </Link>
                </p>
              </div>
            </div>
            <div className="col-xl-3 col-lg-2 col-md-0"></div>
          </div>
        </form>
      </div>
    </div>
  );
};
