import { Link } from 'react-router-dom';
import { ROUTES } from '../../../config/routes';
import { FlechaDer } from '../../svg';

import styles from './registerSection.module.scss';

export type RegisterSectionProps = {
  color: 'secondary' | 'info' | 'warning';
  arrowColor: string;
};

export const RegisterSection = (props: RegisterSectionProps) => {
  let cssClass = '';

  switch (props.color) {
    case 'info':
      cssClass = styles.infoWrapper;
      break;

    case 'secondary':
      cssClass = styles.secondaryWrapper;
      break;

    case 'warning':
      cssClass = styles.warningWrapper;
      break;
  }

  return (
    <div className={styles.wrapper}>
      <div className="container">
        <div className="row">
          <div className="col-6 col-md-1 d-none d-md-flex"></div>
          <div className="col-12 col-md-10 px-0">
            <div className={`${styles.wrapperContent} ${cssClass}`}>
              <div className={styles.titleWrapper}>
                <h2 className="text-white text-center text-md-start">
                  ¿Listo para <span className="text-primary text-decoration-underline">unirte</span>{' '}
                  a esta gran familia?
                </h2>

                <div className="text-center text-md-start mt-4">
                  <Link to={ROUTES.register} className="btn btn-primary">
                    Regístrate Ahora{' '}
                    <FlechaDer width={20} fill={props.arrowColor} className="ms-2" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 col-md-1 d-none d-md-flex"></div>
        </div>
      </div>
    </div>
  );
};
