import { AppStore, GooglePlay } from '../../svg';

export type AppDownloadButtonProps = {
  isAndroid: boolean;
  className: string;
};

export const AppDownloadButton = ({
  isAndroid,
  className = 'secondary',
}: AppDownloadButtonProps) => {
  return (
    <div>
      {!isAndroid && (
        <a
          href="https://itunes.apple.com/us/app/megaconecta/id1000791688?ls=1&mt=8"
          target="_blank"
          rel="noreferrer">
          <div className={`buttonDownload ${className}`}>
            <AppStore width={100} fill={'#fff'} />
          </div>
        </a>
      )}

      {isAndroid && (
        <a
          href="https://play.google.com/store/apps/details?id=com.embarcadero.MegaConecta&hl=es"
          target="_blank"
          rel="noreferrer">
          <div className={`buttonDownload ${className}`}>
            <GooglePlay width={100} fill={'#fff'} />
          </div>
        </a>
      )}
    </div>
  );
};
