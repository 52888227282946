import { NewCardInformation } from '../types/newCardInformation';
import { NewPaypalInformation } from '../types/newPaypalInformation';
import { PaymentMethodCard } from '../types/payment';
import { RemittanceAmount, RemittanceOrder } from '../types/remittances';
import api from './axios/api';
import { getCardNonce, getDeviceData } from './nonce';

type purchaseWithPaypalParams = {
  acceptReferral: boolean;
  paymentMethod?: PaymentMethodCard;
  newPaypalInformation?: NewPaypalInformation;
  remittanceOrder: RemittanceOrder;
};

const validateMlc = async (mlc_card: string): Promise<boolean> => {
  try {
    const response = await api.post<any>('/api/v1/remittance/check_mlc_card', {
      mlc_card,
    });

    if (!response.has_error) {
      return response.data.is_valid;
    }

    return false;
  } catch (error) {
    return false;
  }
};

const getAmounts = async (
  currency: string,
  munId: number,
  provinceId: number,
  remitType: 'cash' | 'mlc'
): Promise<RemittanceAmount[]> => {
  try {
    const response = await api.get<any>('/api/v1/remittance/getlocationproducts', {
      params: {
        municipality: munId,
        province: provinceId,
        remit_type: remitType,
        currency: currency,
      },
    });

    if (!!response.array && response.array.length > 0) {
      return response.array;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const purchaseWithPaypal = async ({
  acceptReferral,
  paymentMethod,
  newPaypalInformation,
  remittanceOrder,
}: purchaseWithPaypalParams) => {
  const url = '/api/v1/payment/remittance/paypal';
  const device_data = await getDeviceData();

  let payload = {
    nonce: newPaypalInformation?.nonce,
    device_data,
    accept_referral: acceptReferral ? 1 : 0,
    is_new_method: newPaypalInformation !== undefined ? 1 : 0,
    save_method: newPaypalInformation?.saveMethod ? 1 : 0,
    payment_method_id: paymentMethod?.id,
    ..._remittanceOrderPayload(remittanceOrder),
  };

  try {
    const response = await api.post<any>(url, payload);

    if (!response.has_error) {
      return true;
    }

    throw new Error(response.error ?? 'ERROR: No data');
  } catch (error) {
    throw error;
  }
};

interface purchaseWithCreditCardParams {
  acceptReferral: boolean;
  paymentMethod?: PaymentMethodCard;
  newCardInformation?: NewCardInformation;
  remittanceOrder: RemittanceOrder;
}

const purchaseWithCreditCard = async ({
  acceptReferral,
  paymentMethod,
  newCardInformation,
  remittanceOrder,
}: purchaseWithCreditCardParams) => {
  const url = '/api/v1/payment/remittance/creditcard';
  const nonce =
    newCardInformation !== undefined
      ? await getCardNonce({
          number: newCardInformation.cardNumber,
          expiration: newCardInformation.cardExpiration,
          cvv: newCardInformation.cardCvc,
        })
      : undefined;

  const device_data = await getDeviceData();

  let payload = {
    // nonce,
    device_data,
    accept_referral: acceptReferral ? 1 : 0,
    is_new_method: newCardInformation !== undefined ? 1 : 0,
    save_method: newCardInformation?.saveMethod ? 1 : 0,
    ...(newCardInformation !== undefined
      ? await _newCardPayload(newCardInformation)
      : {
          payment_method_id: paymentMethod?.id,
        }),
    ..._remittanceOrderPayload(remittanceOrder),
  };

  try {
    const response = await api.post<any>(url, payload);

    if (!response.has_error) {
      return true;
    }

    throw new Error(response.error ?? 'ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const _newCardPayload = async (newCardInformation: NewCardInformation) => {
  const nonce = await getCardNonce({
    number: newCardInformation.cardNumber,
    expiration: newCardInformation.cardExpiration,
    cvv: newCardInformation.cardCvc,
  });

  return {
    nonce,
    title: newCardInformation.title,
    cc_number: newCardInformation.cardNumber.replace(/\D/g, ''),
    cvc: newCardInformation.cardCvc,
    exp_date: newCardInformation.cardExpiration.replace(/\D/g, ''),
    first_name: newCardInformation.first_name,
    last_name: newCardInformation.last_name,
    address1: newCardInformation.address1,
    address2: newCardInformation.address2,
    city: newCardInformation.city,
    state: newCardInformation.state,
    zip: newCardInformation.zip,
    country: newCardInformation.country,
    // 'card.phone': newCardInformation.
    // 'card.email': 'sometimes|required_if:is_new_method,1|email',
  };
};

const _remittanceOrderPayload = (remittanceOrder: RemittanceOrder) => {
  return {
    type: remittanceOrder.remittanceType,
    full_name: remittanceOrder.fullName,
    address: remittanceOrder.address,
    neighborhood: remittanceOrder.neighborhood,
    province_id: remittanceOrder.provinceId,
    municipality_id: remittanceOrder.municipalityId,
    instructions: remittanceOrder.instructions,
    product_id: remittanceOrder.productId,
    currency_to_add: remittanceOrder.currency,
    mlc_card: remittanceOrder.mlcCard,
    carne_id: remittanceOrder.carnetId,
    phone: remittanceOrder.phone,
    contact_id: remittanceOrder.contactId,
    update_contact_info: remittanceOrder.updateContactInfo,
  };
};

export const RemittanceServices = {
  getAmounts,
  purchaseWithPaypal,
  purchaseWithCreditCard,
  validateMlc,
};
