import * as React from 'react';
import { SVGProps } from 'react';
const SvgTarjetaCredito = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" {...props}>
    <g data-name="Capa 1">
      <path
        data-name="Trazado 10892"
        d="M13 23.6c-6.45 0-9.69 0-11.29-1.32S.12 18.31.12 13s0-7.97 1.59-9.28S6.55 2.4 13 2.4s9.69 0 11.29 1.32S25.88 7.7 25.88 13s0 7.97-1.59 9.28S19.45 23.6 13 23.6"
        style={{
          fill: '#8ab934',
        }}
      />
      <path
        data-name="Trazado 10893"
        d="M.12 12.38h25.76c0-1.91-.01-3.46-.11-4.71H.23c-.1 1.25-.11 2.8-.11 4.71"
        style={{
          fill: '#263645',
        }}
      />
      <path
        data-name="Trazado 10894"
        d="M16.69 15.98h3.61c.87 0 1.57.7 1.57 1.57v.94c0 .87-.7 1.57-1.57 1.57h-3.61c-.87 0-1.57-.7-1.57-1.57v-.94c0-.87.7-1.57 1.57-1.57"
        style={{
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);
export default SvgTarjetaCredito;
