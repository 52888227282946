import * as React from 'react';
import { SVGProps } from 'react';
const SvgListadoRecargas = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="listado_recargas_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    {...props}>
    <defs>
      <style>{'.listado_recargas_svg__cls-4{fill:#263645}'}</style>
    </defs>
    <g id="listado_recargas_svg__Capa_1-2" data-name="Capa 1">
      <path
        d="M3.27 1.68c-1.19.16-2.1 1.13-2.2 2.33C.88 6.32.78 8.77.78 11.3s.1 4.97.29 7.29c.1 1.2 1.01 2.16 2.2 2.33 1.51.21 3.09.32 4.72.32s3.21-.11 4.72-.32c1.19-.16 2.1-1.13 2.2-2.33.19-2.31.29-4.76.29-7.29s-.1-4.97-.29-7.29c-.1-1.2-1.01-2.16-2.2-2.33-1.51-.21-3.09-.32-4.72-.32s-3.21.11-4.72.32Z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        className="listado_recargas_svg__cls-4"
        d="M7.99 21.88c-1.63 0-3.25-.11-4.81-.32a3.195 3.195 0 0 1-2.77-2.93c-.19-2.37-.29-4.84-.29-7.34s.1-4.96.29-7.34a3.195 3.195 0 0 1 2.77-2.93c3.13-.43 6.49-.43 9.62 0 1.49.2 2.65 1.43 2.77 2.93a92.648 92.648 0 0 1 0 14.68 3.243 3.243 0 0 1-2.77 2.93c-1.56.21-3.18.32-4.81.32Zm0-19.85c-1.57 0-3.13.1-4.63.31-.89.12-1.56.83-1.63 1.72-.19 2.34-.29 4.77-.29 7.23s.1 4.89.29 7.23c.07.89.74 1.6 1.63 1.72 3.01.41 6.25.41 9.26 0 .87-.12 1.56-.84 1.63-1.72.19-2.34.29-4.78.29-7.23s-.1-4.89-.29-7.23c-.07-.88-.76-1.6-1.63-1.72-1.51-.21-3.06-.31-4.63-.31Z"
      />
      <path
        d="M7.99 1.36c-1.3 0-2.56.08-3.78.24.02.27.03.54.05.81.05.76.55 1.38 1.19 1.49a16.261 16.261 0 0 0 5.1 0c.64-.1 1.14-.72 1.19-1.49.02-.27.03-.54.05-.81-1.22-.16-2.49-.24-3.78-.24Z"
        style={{
          fill: '#d0e3ae',
        }}
      />
      <path
        className="listado_recargas_svg__cls-4"
        d="M7.99 4.76c-.9 0-1.79-.07-2.65-.21-.95-.15-1.67-1.01-1.74-2.09-.02-.27-.04-.54-.05-.82l-.03-.62.61-.08c2.55-.32 5.19-.32 7.74 0l.61.08-.03.62c-.02.28-.03.55-.05.82-.07 1.08-.79 1.94-1.74 2.09-.86.14-1.75.21-2.65.21ZM4.91 2.19c0 .06 0 .12.01.18.03.45.3.82.63.88 1.59.26 3.3.26 4.88 0 .33-.05.6-.42.63-.88 0-.06 0-.12.01-.18-2.04-.22-4.13-.22-6.17 0ZM6.31 18.45c0-.76.39-.99 1.67-.99s1.67.23 1.67.99-.39.99-1.67.99-1.67-.23-1.67-.99Z"
      />
      <circle
        cx={16.21}
        cy={15.62}
        r={6.02}
        style={{
          fill: '#8ab934',
        }}
      />
      <path
        className="listado_recargas_svg__cls-4"
        d="M16.21 22.3c-3.68 0-6.68-3-6.68-6.68s3-6.68 6.68-6.68 6.68 3 6.68 6.68-3 6.68-6.68 6.68Zm0-12.03c-2.95 0-5.35 2.4-5.35 5.35s2.4 5.35 5.35 5.35 5.35-2.4 5.35-5.35-2.4-5.35-5.35-5.35Z"
      />
      <path
        d="M17.67 15.43c-.17-.13-.36-.23-.56-.31-.21-.08-.41-.15-.6-.21a7.58 7.58 0 0 1-.36-.12c-.11-.04-.2-.08-.27-.13s-.13-.1-.17-.17a.427.427 0 0 1-.06-.24c0-.11.03-.2.08-.27s.11-.13.19-.17c.07-.05.16-.08.25-.09.09-.02.18-.02.27-.02.18 0 .36.04.54.13.09.04.16.09.24.14.14.11.34.09.47-.04l.35-.37c.15-.16.12-.42-.07-.55a2.9 2.9 0 0 0-.53-.26c-.28-.1-.56-.15-.83-.16v-.72c-.37-.42-.84-.16-.84.2v.59s-.06 0-.09.02c-.26.07-.49.17-.7.31-.21.14-.37.33-.5.55-.13.22-.19.49-.19.8 0 .25.04.47.12.65.08.18.19.33.33.45.13.12.29.23.46.31s.35.15.53.21c.19.06.35.11.5.16.15.05.27.11.37.17.1.06.17.13.23.2.05.07.08.16.08.27 0 .11-.02.21-.06.29a.52.52 0 0 1-.17.19c-.07.05-.16.08-.25.1-.1.02-.19.03-.29.03-.22 0-.45-.06-.67-.17-.12-.06-.22-.12-.31-.2a.365.365 0 0 0-.48.02l-.38.39c-.15.15-.13.41.04.54.19.13.4.24.63.33.27.1.54.16.83.19v.91c.37.42.84.16.84-.2v-.74c.1-.02.21-.04.31-.07.26-.07.49-.19.69-.34.2-.15.36-.35.48-.58.12-.24.18-.52.18-.84 0-.28-.06-.52-.17-.7-.11-.19-.25-.35-.42-.47Z"
        style={{
          fillRule: 'evenodd',
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);
export default SvgListadoRecargas;
