import parsePhoneNumber from 'libphonenumber-js';
import { startsWith } from 'lodash';
import { UseQueryResult } from 'react-query';
import { TopupProductsProps } from '../components/account';
import { MegaCarrierRate } from '../types/topups';
import { Formatter } from '../utilities';

const isSuccessQueries = (queries: UseQueryResult[]): boolean => {
  let isSuccess = true;

  queries.forEach((query) => {
    isSuccess = isSuccess && query.isSuccess;
  });

  return isSuccess;
};

const isLoadingQueries = (queries: UseQueryResult[]): boolean => {
  let isLoading = false;

  queries.forEach((query) => {
    isLoading = isLoading || query.isLoading || (query.isError && query.isFetching);
  });

  return isLoading;
};

const isErrorQueries = (queries: UseQueryResult[]): boolean => {
  let isLoading = false;

  queries.forEach((query) => {
    isLoading = isLoading || query.isError;
  });

  return isLoading;
};

const getMaskLength = (phoneMask: string | undefined | null): number => {
  if (!phoneMask) {
    return 0;
  }

  return (phoneMask.match(/N/g) || []).length;
};

const convertPhoneMask = (
  phoneMask: string | undefined | null,
  removeCountryCode?: boolean
): string => {
  if (!phoneMask) {
    return '';
  }

  const hasPlusSign = startsWith(phoneMask, '+');
  const plus = hasPlusSign || removeCountryCode ? '' : '+';

  const length = getMaskLength(phoneMask);

  const mMask = removeCountryCode
    ? ''.padStart(length, '0')
    : plus + phoneMask.replaceAll('N', '0');

  const position = mMask.indexOf('0');
  if (position === -1 || removeCountryCode) {
    return mMask;
  }

  return [mMask.slice(0, position), ' ', mMask.slice(position)].join('');
};

const refetchQueries = (queries: UseQueryResult[], retryAll?: boolean) => {
  queries.forEach((q) => {
    if (retryAll || q.status === 'error' || q.status === 'idle') {
      q.refetch();
    }
  });
};

const getFormattedPhoneNumber = (
  phone: string,
  countryCode: string,
  asInternational: boolean = true
) => {
  const phoneNumber = parsePhoneNumber(phone, countryCode as any);

  if (phoneNumber) {
    return asInternational ? phoneNumber.formatInternational() : phoneNumber.formatNational();
  }

  return null;
};

const convertProductList = (list: MegaCarrierRate[]) => {
  const newProductList: TopupProductsProps['products'] = list.map((l) => {    
    return {
      id: `${l.id}`,
      priceTitle: `${l.title}`,      
      priceSubtitle: Formatter.toCurrency(l.real_amount),
      description: `Recibe ${l.description}`,      
      futureDate: (l.future_date!=='') ? `Su recarga se hará efectiva ${l.future_date}` : ''
    };
  });
  
  return newProductList;
};

const formatCardNumber = (value: string) => {
  const regex = /^(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})$/g;
  const onlyNumbers = value.replace(/[^\d]/g, '');

  return onlyNumbers.replace(regex, (regex, $1, $2, $3, $4) =>
    [$1, $2, $3, $4].filter((group) => !!group).join('-')
  );
};

export const UtilService = {
  isLoadingQueries,
  isErrorQueries,
  getMaskLength,
  convertPhoneMask,
  refetchQueries,
  isSuccessQueries,
  getFormattedPhoneNumber,
  convertProductList,
  formatCardNumber,
};
