import * as React from 'react';
import { SVGProps } from 'react';
const SvgTwiter = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" {...props}>
    <defs>
      <clipPath id="twiter_svg__a">
        <path
          style={{
            fill: 'none',
          }}
          d="M.38 3h25.24v20H.38z"
        />
      </clipPath>
    </defs>
    <g data-name="Capa 1">
      <g
        data-name="Grupo 88"
        style={{
          clipPath: 'url(#twiter_svg__a)',
        }}>
        <path
          data-name="Trazado 80"
          d="M23.22 8.93c.15 6.28-4.92 19-22.84 12.05 2.38 0 6.25-.87 7.21-1.83-1.4-.2-4.21-1.2-4.21-3.6h2.4l-.12-.06c-1.27-.63-4.08-2.03-4.08-5.35.64.61 1.55.84 2.4.6C2.76 9.66.02 6.61 2.18 3.52c1.8 2 6.37 6.01 10.22 6.01-.7-2.48.61-5.08 3.01-6.01 3-1.2 5.15-.14 6.01 1.2.94-.13 1.84-.47 2.63-.99.14-.08.27-.15.38-.21v.12c.16 1.12-.32 2.23-1.23 2.89.6.45 1.23.63 2.43 0 0 1.02-.95 2.01-2.4 2.4"
          style={{
            fill: '#fff',
            fillRule: 'evenodd',
          }}
        />
      </g>
    </g>
  </svg>
);
export default SvgTwiter;
