import * as React from 'react';
import { SVGProps } from 'react';
const SvgReportePago = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" {...props}>
    <g data-name="Capa 1">
      <circle
        cx={11.5}
        cy={11.5}
        r={11.5}
        style={{
          opacity: 0.4,
        }}
      />
      <path
        className="reporte_pago_svg__cls-1"
        d="M14.86 12.01c-.2-.34-.45-.62-.75-.84-.3-.23-.64-.41-1.01-.55-.37-.14-.72-.26-1.06-.38-.24-.08-.45-.15-.64-.22s-.35-.15-.49-.23a.894.894 0 0 1-.31-.3.806.806 0 0 1-.11-.43c0-.2.05-.36.14-.49s.2-.23.33-.31c.13-.08.28-.13.45-.16a2.366 2.366 0 0 1 1.46.19c.15.07.29.15.42.25.26.19.61.15.83-.08l.63-.66c.28-.29.21-.76-.12-.97-.29-.19-.61-.34-.95-.46-.41-.14-.81-.22-1.21-.26V5.08a.96.96 0 0 0-1.92 0v1.15c-.46.12-.88.31-1.25.56-.37.26-.67.58-.89.98-.23.4-.34.88-.34 1.43 0 .45.07.84.22 1.16.15.32.34.59.58.81.24.22.52.4.82.55.31.14.62.27.94.37.33.1.63.2.89.29s.48.19.65.3c.17.11.31.23.4.36.1.13.14.29.14.48s-.04.37-.11.51c-.08.14-.18.26-.3.34-.13.09-.28.15-.45.18-.17.04-.35.06-.53.06-.4 0-.8-.1-1.2-.31-.21-.1-.39-.22-.56-.35a.64.64 0 0 0-.85.04l-.68.69a.64.64 0 0 0 .08.96c.33.24.71.44 1.13.59.47.18.95.29 1.45.34v1.46c0 .47.43.85.96.85s.96-.38.96-.85v-1.5s0-.06-.01-.09l.15-.03c.46-.13.88-.34 1.24-.61.36-.27.65-.62.86-1.04s.32-.92.32-1.5c0-.5-.1-.92-.3-1.26Z"
      />
    </g>
  </svg>
);
export default SvgReportePago;
