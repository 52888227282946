import * as React from 'react';
import { SVGProps } from 'react';
const SvgEnviarCorreo = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66 66" {...props}>
    <g data-name="Capa 1">
      <path
        d="M62.29 35.25V33.22c-.05-7.8-.35-13.37-.65-17-.32-3.96-3.38-7.02-7.34-7.34-3.94-.32-12.93-.66-21.84-.66s-16.81.33-20.75.66c-3.96.32-7.02 3.38-7.34 7.34-.27 3.35-.55 8.37-.63 15.26 0 .58-.01 1.17-.02 1.78v2.07c0 6.1.16 10.95.36 14.67v.11c.03.63.07 1.24.11 1.81v.12c.06.88.12 1.68.18 2.4.25 3.11 2.2 5.66 4.95 6.77.24.1.49.18.74.26.12.04.24.07.37.1.42.1.84.18 1.29.21.48.04 1.01.08 1.6.12.34.02.7.04 1.07.07 4.32.25 10.86.47 18.08.47s13.94-.2 18.56-.45l.6-.03c.31-.02.61-.03.9-.05.22-.01.44-.03.64-.04.23-.02.44-.03.65-.05.17-.01.34-.03.5-.04.1 0 .2-.03.29-.04a8.174 8.174 0 0 0 2.48-.69c.21-.1.42-.21.62-.32.4-.23.78-.5 1.13-.79.35-.3.68-.62.97-.97a8.025 8.025 0 0 0 1.84-4.52c.2-2.46.41-5.83.53-10.18.08-2.61.12-5.58.12-8.92v-.08Z"
        style={{
          fill: '#d0e3ae',
        }}
      />
      <path
        d="M16.45 37.6c1.38.94 2.71 1.3 4.35 1.58 3.87.67 7.63 1.79 11.55 2.19 3.56.36 6.88-.75 10.26-1.75 3.32-.97 6.86-1.46 10.02-2.89.04-.02.08-.03.12-.05.04-2.16.06-4.55.06-7.18 0-8.54-.23-14.52-.45-18.3-.22-3.79-2.33-6.72-5.07-7.03-2.72-.31-8.93-.63-15.09-.63s-11.61.32-14.34.63c-2.74.31-4.85 3.23-5.07 7.03-.22 3.77-.45 9.76-.45 18.3 0 2.18.02 4.19.04 6.04 1.56.35 2.77 1.16 4.07 2.05Z"
        style={{
          fill: '#8ab934',
        }}
      />
      <path
        d="m63.46 31.47-.02-2c-.11-6.07-.37-10.49-.61-13.34-.37-4.52-3.91-8.07-8.43-8.43-.47-.04-1.01-.08-1.62-.11C51.75 5.03 49.8 3.27 47.43 3c-2.73-.31-8.99-.63-15.23-.63s-11.67.32-14.47.63c-2.38.27-4.34 2.05-5.37 4.64-.26.02-.52.04-.75.06-4.52.37-8.07 3.91-8.43 8.43-.23 2.85-.5 7.27-.61 13.34s-.02 2-.02 2v.6c-.01 1.05-.02 2.13-.02 3.26 0 9.17.36 15.51.66 19.2.37 4.52 3.91 8.07 8.43 8.43 4.04.33 12.05.66 20.84.66s18.01-.34 21.94-.66c4.52-.37 8.07-3.91 8.43-8.43.3-3.7.66-10.03.66-19.2 0-1.35 0-2.63-.02-3.86ZM54.2 10.08c3.4.28 5.97 2.84 6.24 6.24.24 3.01.53 7.78.62 14.43-1.21.6-3.81 1.79-7.07 3.12.01-1.37.02-2.82.02-4.36 0-8.76-.25-14.83-.45-18.37-.02-.38-.07-.75-.12-1.12.27.02.53.04.77.06ZM18 5.38c2.74-.31 8.19-.62 14.2-.62s12.29.32 14.96.62c2.14.24 3.82 2.73 4.01 5.91.21 3.51.45 9.52.45 18.23 0 1.9-.01 3.67-.03 5.31-6.16 2.37-13.83 4.8-19.24 4.8s-12.97-2.51-18.78-4.91c-.02-1.61-.03-3.34-.03-5.2 0-8.71.25-14.72.45-18.23.19-3.18 1.87-5.67 4.01-5.91Zm-6.29 4.72c-.05.34-.09.69-.11 1.05-.21 3.54-.45 9.6-.45 18.37 0 1.47 0 2.86.02 4.18-2.92-1.28-5.19-2.4-6.23-2.93.09-6.65.37-11.43.62-14.44.27-3.37 2.8-5.92 6.15-6.23Zm48.73 44.25c-.28 3.4-2.84 5.97-6.24 6.24-3.89.32-12.83.65-21.75.65s-16.66-.33-20.65-.65c-3.4-.28-5.97-2.84-6.24-6.24-.3-3.65-.65-9.92-.65-19.01v-1.91c4.77 2.36 18.2 8.59 27.43 8.59s23.52-6.15 28.74-8.61v1.93c0 9.09-.35 15.36-.65 19.01Z"
        style={{
          fill: '#263645',
        }}
      />
      <path
        data-name="Uni\xF3n 3"
        d="M20.36 27.24q0-2.22 7.24-2.22t7.24 2.22-7.24 2.22-7.24-2.22Zm0-7.66q0-2.22 13.58-2.22t13.58 2.22q0 2.22-13.58 2.22t-13.58-2.22Z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);
export default SvgEnviarCorreo;
