import { components, OptionProps, SingleValueProps, GroupBase } from 'react-select';
import { AuthorizedCountry } from '../../../types/country';

const { Option, SingleValue } = components;

export const IconOptionAuthorizedCountry = (
  props: OptionProps<AuthorizedCountry, false, GroupBase<AuthorizedCountry>>
) => {
  return (
    <Option {...props}>
      <div className="contact_row no_border">
        <div className="contact_right">
          <span className={`fi fi-${(props.data.count_cod || '').toLowerCase()}`}></span>
        </div>
        <div className="contact_center">
          <h6>
            {props.data.count_name} (+{props.data.count_prefix})
          </h6>
        </div>
      </div>
    </Option>
  );
};

export const IconSingleValueAuthorizedCountry = ({
  children,
  ...props
}: SingleValueProps<AuthorizedCountry, false, GroupBase<AuthorizedCountry>>) => {
  return (
    <SingleValue {...props}>
      <div>
        <span className={`fi fi-${(props.data.count_cod || '').toLowerCase()}`}></span>{' '}
        <span>
          {props.data.count_name} (+{props.data.count_prefix})
        </span>
      </div>
    </SingleValue>
  );
};
