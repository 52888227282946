import { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import Select from 'react-select';
import {
  IconOptionContactNauta,
  IconSingleValueContactNauta,
  reactSelectStyles,
} from '../../../../config/reactSelect';
import { useTopUp } from '../../../../context/TopupContext';
import { UtilService } from '../../../../services';
import { MegaContact } from '../../../../types/contacts';
import { TopUpNautaItem } from '../../../../types/topupItems';
import { MegaCarrier, MegaCarrierRate } from '../../../../types/topups';
import { TopupProducts, TopupProductsProps } from '../../topupProducts';
import { TopUpAddButton } from '../topupAddButton';
import { AlertHelper } from '../../../../utilities/AlertHelper';

export const TopUpNautaContacts = ({
  carrier,
  carrierRates,
  contacts,
}: {
  carrier: MegaCarrier;
  carrierRates: MegaCarrierRate[];
  contacts: MegaContact[];
}) => {
  const [selectedContact, setSelectedContact] = useState<MegaContact>();
  const [selectedContactCarrierRate, setSelectedContactCarrierRate] = useState<MegaCarrierRate>();
  const [productList, setProductList] = useState<TopupProductsProps['products']>([]);

  const { addNautaItem } = useTopUp();

  useEffect(() => {
    if (contacts.length > 0) {
      setSelectedContact(contacts[0]);
    }
  }, [contacts]);

  useEffect(() => {
    if (carrierRates.length > 0) {
      setSelectedContactCarrierRate(carrierRates[0]);
      convertProductList(carrierRates);
    }
  }, [carrierRates]);

  const convertProductList = (list: MegaCarrierRate[]) => {
    const newProductList: TopupProductsProps['products'] = UtilService.convertProductList(list);
    setProductList(newProductList);
  };

  const onSelectedProduct = (id: string) => {
  };

  const onAddTopup = (id: string) => {             
    const rate = carrierRates.find((r) => `${r.id}` === `${id}`);
    setSelectedContactCarrierRate(rate);
    if (rate) {
      try {        
        addNautaItem({
          carrier: carrier,
          carrierRate: rate,
          contact: selectedContact,
          type: 'contact',
        } as TopUpNautaItem);
      } catch (e: any) {
        AlertHelper.show({ icon: 'error', title: 'Error', text: e.toString() });
      }
    }    
  };

  return (
    <>
      <Row>
        <Col lg={12}>
          <p className="mt-5 mb-2">Contacto</p>
          <Select
            value={selectedContact}
            options={contacts}
            isSearchable={false}
            isClearable={false}
            isLoading={false}
            className="w-100 mega-select"
            isMulti={false}
            styles={reactSelectStyles}
            components={{
              Option: IconOptionContactNauta,
              SingleValue: IconSingleValueContactNauta,
            }}
            onChange={(newValue) => {
              setSelectedContact(newValue as MegaContact);
            }}
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <hr />
        </Col>
      </Row>

      <Row>
        <Col>
          {!!productList && productList.length > 0 && !!selectedContactCarrierRate && (
            <TopupProducts
              serviceType="nauta"
              selectedId={selectedContactCarrierRate.id}
              products={productList}
              onSelectedChange={onSelectedProduct}
              onAddTopup={onAddTopup}
            />
          )}
        </Col>
      </Row>     
    </>
  );
};
