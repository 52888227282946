import React, { createContext, useContext, useState } from 'react';
import {
  FutureTopupModal,
  LoadingModal,
  ProcessingPaymentModal,
  SuccessPaymentModal,
} from '../components/account';
import { ConfirmDeleteModal } from '../components/account/modals/confirmDeleteModal';
import { ErrorModal } from '../components/account/modals/errorModal';
import { PinValidationModal } from '../components/account/modals/pinValidationModal';
import { SuccessModal } from '../components/account/modals/successModal';

export enum MODAL_TYPES {
  SUCCESS = 'SUCCESS_MODAL',
  ERROR = 'ERROR_MODAL',
  PIN_VALIDATION = 'PIN_VALIDATION',
  CONFIRM_DELETE = 'CONFIRM_DELETE',
  PROCESSING_PAYMENT = 'PROCESSING_PAYMENT',
  SUCCESS_PAYMENT = 'SUCCESS_PAYMENT_MODAL',
  LOADING = 'LOADING_MODAL',
  FUTURE_TOPUP = 'FUTURE_TOPUP_MODAL',
}

export type GlobalModalProps = {
  title?: string;
  description?: string;
  state?: any;
  onClose?: (state?: any) => void;

  primaryButtonTitle?: string;
  secondaryButtonTitle?: string;
};

const MODAL_COMPONENTS: Record<MODAL_TYPES, () => (props: any) => JSX.Element> = {
  [MODAL_TYPES.SUCCESS]: () => SuccessModal,
  [MODAL_TYPES.ERROR]: () => ErrorModal,
  [MODAL_TYPES.PIN_VALIDATION]: () => PinValidationModal,
  [MODAL_TYPES.CONFIRM_DELETE]: () => ConfirmDeleteModal,
  [MODAL_TYPES.PROCESSING_PAYMENT]: () => ProcessingPaymentModal,
  [MODAL_TYPES.SUCCESS_PAYMENT]: () => SuccessPaymentModal,
  [MODAL_TYPES.LOADING]: () => LoadingModal,
  [MODAL_TYPES.FUTURE_TOPUP]: () => FutureTopupModal,
};

type GlobalModalContextType = {
  showModal: (modalType: MODAL_TYPES, modalProps?: Partial<GlobalModalProps>) => void;
  hideModal: () => void;
  currentModalType: MODAL_TYPES | null;
  currentModalProps: GlobalModalProps | null;
};

export const GlobalModalContext = createContext<GlobalModalContextType>({
  showModal: (_: MODAL_TYPES, __?: Partial<GlobalModalProps>) => {},
  hideModal: () => {},
  currentModalType: null,
  currentModalProps: null,
});

export const useGlobalModalContext = () => useContext(GlobalModalContext);

export const GlobalModal: React.FC<{}> = ({ children }) => {
  const [currentModalType, setCurrentModalType] = useState<MODAL_TYPES | null>(null);
  const [currentModalProps, setCurrentModalProps] = useState<Partial<GlobalModalProps> | null>(
    null
  );

  const showModal = (modalType: MODAL_TYPES, modalProps?: Partial<GlobalModalProps>) => {
    setCurrentModalType(modalType);
    setCurrentModalProps(modalProps || null);
  };

  const hideModal = () => {
    setCurrentModalType(null);
    setCurrentModalProps(null);
  };

  const renderComponent = () => {
    if (!currentModalType) {
      return null;
    }

    const ModalComponent = MODAL_COMPONENTS[currentModalType]();
    if (!ModalComponent) {
      return null;
    }

    return <ModalComponent id="global-modal" />;
  };

  return (
    <GlobalModalContext.Provider
      value={{ currentModalType, currentModalProps, showModal, hideModal }}>
      {renderComponent()}
      {children}
    </GlobalModalContext.Provider>
  );
};
