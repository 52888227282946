import { useEffect, useState } from 'react';
import { Alert, Button } from 'react-bootstrap';
import { FaExclamationTriangle } from 'react-icons/fa';
import { IMaskInput } from 'react-imask';
import { useQuery } from 'react-query';
import Select from 'react-select';
import { toast } from 'react-toastify';
import {
  IconOptionAuthorizedCountry,
  IconSingleValueAuthorizedCountry,
  reactSelectStyles
} from '../../../../config/reactSelect';
import { CountryServices } from '../../../../services';
import { ReferralServices } from '../../../../services/referral';
import { AuthorizedCountry } from '../../../../types/country';
import { QueryTypes } from '../../../../types/queryTypes';
import { Formatter } from '../../../../utilities';
import { SmallSpinner } from '../../../external/smallSpinner/smallSpinner';
import { ReferralTerms } from '../referralTerms';

import styles from './referralForm.module.scss';

export const ReferralForm = ({ defaultCountryCode }: { defaultCountryCode: string }) => {
  const [countries, setCountries] = useState<AuthorizedCountry[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<AuthorizedCountry>();
  const [phoneNumberMask, setPhoneNumberMask] = useState<string>('');
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isTermsVisible, setIsTermsVisible] = useState<boolean>(false);

  const countriesQuery = useQuery(
    QueryTypes.GetAuthCountries,
    CountryServices.getAllAuthorizedCountries
  );

  useEffect(() => {
    if (countriesQuery.status === 'success') {
      setCountries(countriesQuery.data);
      setSelectedCountry(
        countriesQuery.data.find((country) => country.count_cod == defaultCountryCode) ||
          countriesQuery.data[0]
      );
    }
  }, [countriesQuery.status]);

  useEffect(() => {
    if (selectedCountry) {
      setPhoneNumber('');
      setPhoneNumberMask(Formatter.inputPhoneMask(selectedCountry.phone_mask));
    }
  }, [selectedCountry]);

  const isButtonDisabled = () => {
    return phoneNumber.length != selectedCountry?.phone_mask.length;
  };

  const onSubmit = () => {
    setIsProcessing(true);

    ReferralServices.sendReferral({
      phone: phoneNumber.slice(`${selectedCountry?.count_prefix}`.length),
      country: selectedCountry?.count_cod || '',
    })
      .then((isSuccessful) => {
        if (isSuccessful) {
          toast.success('Solicitud completada exitosamente');
          setErrorMessage(undefined);
          setPhoneNumber('');
        }
      })
      .catch((error) => {
        let message = 'Ha ocurrido un error. Verifica que todos los campos sean correctos.';

        if ((error.error ?? error) == 'EXISTING_REFERRAL') {
          message = 'El número especificado ya ha sido referido.';
        }

        setErrorMessage(message);
      })
      .finally(() => setIsProcessing(false));
  };

  return (
    <>
      <div className="mt-4 megaInput">
        <label htmlFor="country">Seleccione el país</label>
        <Select
          id="country"
          placeholder=""
          value={selectedCountry}
          options={countries}
          isSearchable={false}
          isClearable={false}
          isLoading={false}
          className="w-100 mega-select"
          isMulti={false}
          styles={reactSelectStyles}
          components={{
            Option: IconOptionAuthorizedCountry,
            SingleValue: IconSingleValueAuthorizedCountry,
          }}
          onChange={(newValue) => {
            setSelectedCountry(newValue as AuthorizedCountry);
          }}
        />
      </div>
      <div className="mt-4 megaInput">
        <label htmlFor="tel">Número</label>
        <IMaskInput
          id="tel"
          className="form-control mega"
          mask={phoneNumberMask}
          value={phoneNumber || ''}
          onAccept={(_value, mask) => {
            setPhoneNumber(mask.unmaskedValue);
          }}
          lazy={false}
        />
      </div>

      {errorMessage && (
        <Alert variant={'danger'} className="mt-2">
          <FaExclamationTriangle /> {errorMessage}
        </Alert>
      )}

      <div className="mt-4">
        <Button
          variant="secondary"
          className="w-100 text-white"
          onClick={() => onSubmit()}
          disabled={isButtonDisabled() || isProcessing}>
          {isProcessing ? <SmallSpinner /> : 'Enviar referencia'}
        </Button>
      </div>

      <div
        className={`mt-4 ${styles.conditions}`}
        role="button"
        onClick={() => setIsTermsVisible(true)}>
        Ver condiciones de uso
      </div>

      <ReferralTerms isVisible={isTermsVisible} onHide={() => setIsTermsVisible(false)} />
    </>
  );
};
