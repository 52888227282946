import { useEffect, useState } from 'react';
import { MegaContact } from '../../../../types/contacts';
import { TabsWithTitle } from '../../tabsWithTitle';
import { TopUpMobileContacts } from '../topup-mobile-contacts';
import { TopUpMobilePhone } from '../topup-mobile-phone';


export type ContactsProps = {
  selectedContact: MegaContact | null;
  contacts: MegaContact[];
};

export const TopupMobile = (props: ContactsProps) => {
  const [step, setStep] = useState<'contact' | 'other'>('other');
  const tabList = [{ key: 'other', title: 'Otro Número' }, { key: 'contact', title: 'Mis Contactos' }];

  useEffect(() => {
    if (props.contacts.length > 0) {
      setStep('contact');
    }
  }, [props.selectedContact]);  

  return (
    <div>
      <TabsWithTitle
        title="Celular a Recargar"
        tabs={tabList}
        activeTab={step}
        wrapperClassName="mt-5"
        onTabChange={(key: string) => setStep(key as 'other' | 'contact')}
      />

      {step === 'other' && <TopUpMobilePhone />}
      {step === 'contact' && props.contacts.length>0 && <TopUpMobileContacts contacts={props.contacts} selectedContact={props.selectedContact}/>}
    </div>
  );
};
