import { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Steps } from '../../../components/account';
import { CheckoutActions } from '../../../components/account/checkoutActions';
import { ROUTES } from '../../../config/routes';
import { useShoppingCart } from '../../../context/ShoppingCartContext';

export const CheckoutPaymentMethodsScreen = () => {
  const navigate = useNavigate();

  const {
    currentPaymentMethod,
    currentPaymentMethodType,
    getProductsCount,
    newCardInformation,
    newPaypalInformation,
    setCurrentPaymentMethod,
    updateNewCardInformation,
    updateNewPaypalInformation,
  } = useShoppingCart();

  useEffect(() => {
    if (getProductsCount === 0) {
      navigate(ROUTES.cuenta.store);
    }
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="container">
      <div className="mt-6">
        <Steps
          steps={[
            { title: 'Detalles de envío' },
            { title: 'Método de Pago' },
            { title: 'Resumen de compra' },
          ]}
          currentStep={2}
        />
        <h2 className="mt-6 mg-page-title">MÉTODO DE PAGO</h2>
        <p className="mt-5">Seleccione el método de pago:</p>
        <Row>
          <Col lg={5}>
            {/* <ProfilePaymentMethods
              activePaymentMethodId={currentPaymentMethod?.id}
              addWithoutSave={true}
              currentPaymentMethodType={currentPaymentMethodType}
              newCardInformation={newCardInformation}
              newPaypalInformation={newPaypalInformation}
              onPaymentMethodTypeChange={(paymentMethodType: PaymentMethodType | undefined) =>
                setCurrentPaymentMethod(paymentMethodType)
              }
              onSelect={(paymentMethod: PaymentMethodCard) => {
                setCurrentPaymentMethod(
                  paymentMethod.payment_type.toLowerCase() === 'paypal' ? 'paypal' : 'card',
                  paymentMethod
                );
              }}
              selectable={true}
              updateNewCardInformation={(newCardInformation) =>
                updateNewCardInformation(newCardInformation)
              }
              updateNewPayPalInformation={(newPaypalInformation) =>
                updateNewPaypalInformation(newPaypalInformation)
              }
            /> */}
          </Col>
        </Row>
      </div>
      <CheckoutActions
        onBackClick={() => navigate(ROUTES.cuenta.checkout)}
        className="my-5"
        continueDisabled={currentPaymentMethodType === undefined}
        label="Continuar"
        onClick={() => navigate(ROUTES.cuenta.checkoutSummary)}
      />
    </div>
  );
};
