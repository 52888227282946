import { Button } from 'react-bootstrap';
import { useTopUp } from '../../../context/TopupContext';
import { TopUpNautaItem } from '../../../types/topupItems';
import { Formatter } from '../../../utilities';
import { EliminarItemListado, RecargarNautaInactive } from '../../svg';

import styles from './topUpCartItems.module.scss';

interface TopUpCartNautaItemProps {
  index: number;
  nautaItem: TopUpNautaItem;
  allowToDelete?: boolean;
  isLast?: boolean;
}

export const TopUpCartNautaItem = ({
  index,
  nautaItem,
  allowToDelete = true,
  isLast,
}: TopUpCartNautaItemProps) => {
  const { removeNautaItem } = useTopUp();

  const getContactName = () => {
    return nautaItem.type === 'contact' ? nautaItem.contact?.contact_name + ' ' : '';
  };

  const getNauta = () => {
    return nautaItem.type === 'contact' ? nautaItem.contact?.email + ' ' : nautaItem.nauta;
  };

  return (
    <div className={isLast ? '' : 'mb-4'}>
      <div className="d-flex flex-column flex-lg-row">
        <div className="flex-fill">
          <div className="d-flex flex-row">
            <div className="me-3">
              <RecargarNautaInactive fill={`#8ab934`} width={40} />
            </div>
            <div className="d-flex flex-column">
              <div>
                <span className={`${styles.lineTitle} ${!!getContactName() ? 'me-1' : ''}`}>
                  {getContactName()}
                </span>
                <span>{getNauta()}</span>
              </div>
              <div>
                <div className={styles.lineRecarga}>
                  {nautaItem.carrierRate.remote_amount} {nautaItem.carrierRate.remote_currency} Nauta
                </div>
                <div className="text-secondary">
                  {Formatter.toCurrency(nautaItem.carrierRate.real_amount)}
                </div>
                <div>{'Recibe ' + nautaItem.carrierRate.description}</div>
              </div>
            </div>
          </div>
        </div>
        {allowToDelete && (
          <>
            <div className="d-none d-lg-block">
              <Button
                size="sm"
                className="mt-2 mt-lg-0 text-decoration-none"
                variant={'link'}
                onClick={() => removeNautaItem(index)}>
                <EliminarItemListado width={15} />
              </Button>
            </div>
            <div className="d-block d-lg-none text-end mt-2">
              <Button
                size="sm"
                className="mt-2 mt-lg-0 text-decoration-none text-primary"
                variant={'link'}
                onClick={() => removeNautaItem(index)}>
                Eliminar
              </Button>
            </div>
          </>
        )}
      </div>
      {!isLast && <hr />}
    </div>
  );
};
