import * as React from 'react';
import { SVGProps } from 'react';
const SvgRecargarNautaInactive = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="recargar_nauta_inactive_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 23 23"
    {...props}>
    <defs>
      <style>{'.recargar_nauta_inactive_svg__cls-2{opacity:.4}'}</style>
    </defs>
    <g id="recargar_nauta_inactive_svg__Capa_1-2" data-name="Capa 1">
      <path
        className="recargar_nauta_inactive_svg__cls-2"
        d="M11.5 1.97c-4.32 0-7.79 1.5-10.4 3.54-.64.5-.77 1.39-.38 2.08l.66 1.16c.53.94 1.77 1.12 2.63.52C6.02 7.85 8.58 6.7 11.5 6.7s5.48 1.15 7.49 2.57c.85.6 2.09.42 2.63-.52l.66-1.16c.39-.69.26-1.58-.38-2.08-2.61-2.04-6.08-3.54-10.4-3.54Z"
      />
      <path
        className="recargar_nauta_inactive_svg__cls-2"
        d="M11.5 9.4c-2.67 0-4.52.74-6.06 1.91-.68.52-.77 1.47-.31 2.15l.65.97c.5.75 1.53.85 2.26.4.9-.56 2.1-1.04 3.47-1.04s2.56.48 3.47 1.04c.72.45 1.75.35 2.26-.4l.65-.97c.46-.68.37-1.64-.31-2.15-1.54-1.17-3.39-1.91-6.06-1.91Z"
      />
      <path
        className="recargar_nauta_inactive_svg__cls-1"
        d="M11.48 16.3c-1.31 0-2.37 1.06-2.36 2.37 0 1.31 1.06 2.37 2.37 2.37s2.37-1.06 2.36-2.37c0-1.31-1.06-2.37-2.37-2.37Z"
      />
    </g>
  </svg>
);
export default SvgRecargarNautaInactive;
