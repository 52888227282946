import * as React from 'react';
import { SVGProps } from 'react';
const SvgRecargarCelularInactive = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" {...props}>
    <g data-name="Capa 1">
      <path
        d="M2.89.37A2.94 2.94 0 0 0 .34 3.06C.12 5.74 0 8.57 0 11.5s.12 5.76.34 8.44a2.94 2.94 0 0 0 2.55 2.69c1.75.24 3.58.37 5.47.37s3.72-.13 5.47-.37a2.958 2.958 0 0 0 2.55-2.69c.22-2.68.34-5.51.34-8.44s-.12-5.76-.34-8.44A2.94 2.94 0 0 0 13.83.37C12.08.13 10.25 0 8.36 0S4.64.13 2.89.37Z"
        style={{
          opacity: 0.4,
        }}
      />
      <path
        className="recargar_celular_inactive_svg__cls-1"
        d="M8.36 0C6.85 0 5.39.1 3.97.28l.06.94c.06.89.63 1.6 1.38 1.72.94.15 1.93.23 2.95.23s2.01-.08 2.95-.23c.74-.12 1.32-.84 1.38-1.72l.06-.94C11.33.1 9.86 0 8.36 0ZM6.42 19.8c0-.88.45-1.15 1.93-1.15s1.93.27 1.93 1.15-.45 1.15-1.93 1.15-1.93-.27-1.93-1.15ZM23 11.82c0-3.17-1.1-5.71-2.6-7.62-.37-.47-1.02-.56-1.53-.28l-.85.48c-.69.39-.82 1.3-.38 1.92 1.04 1.47 1.88 3.35 1.88 5.49s-.84 4.01-1.88 5.49c-.44.62-.3 1.53.38 1.92l.85.48c.5.29 1.16.19 1.53-.28 1.5-1.91 2.6-4.45 2.6-7.62Z"
      />
      <path
        className="recargar_celular_inactive_svg__cls-1"
        d="M17.56 11.82c0-1.95-.54-3.31-1.4-4.44-.38-.5-1.07-.56-1.58-.23l-.71.47c-.55.37-.62 1.12-.29 1.65.41.66.76 1.54.76 2.54s-.35 1.88-.76 2.54c-.33.53-.26 1.28.29 1.65l.71.47c.5.33 1.2.27 1.58-.23.85-1.13 1.4-2.48 1.4-4.44Z"
      />
    </g>
  </svg>
);
export default SvgRecargarCelularInactive;
