import { Llamadas, ServicesBackground, Recargas, FlechaDer, Mensajes, Mercado } from '../../svg';
import { useNavigate } from 'react-router-dom';

import styles from './servicesColumn.module.scss';
import { ROUTES } from '../../../config/routes';

export const ServicesColumn = () => {
  const navigate = useNavigate();

  const services = [
    {
      key: 'll1',
      title: 'Llamadas',
      description: 'Llama a Cuba y a decenas de países con la mejor calidad y al más bajo costo.',
      icon: Llamadas,
      className: 'bubble-svc bubble-green',
      route: ROUTES.external.services.calls,
    },

    {
      key: 'll2',
      title: 'Recargas',
      description:
        'Envía recargas móviles, nautas y datos a familiares y amigos en Cuba y el mundo.',
      icon: Recargas,
      className: 'bubble-svc bubble-blue',
      route: ROUTES.external.services.topups,
    },

    {
      key: 'll3',
      title: 'Mensajes',
      description: 'Envía mensajes de texto pagando mucho menos que lo que paga con su operador.',
      icon: Mensajes,
      className: 'bubble-svc bubble-orange',
      route: ROUTES.external.services.messages,
    },

    {
      key: 'll4',
      title: 'Mercado',
      description:
        'Haz tu orden en nuestra tienda online desde cualquier parte del mundo en pocos minuto.',
      icon: Mercado,
      className: 'bubble-svc bubble-red',
      route: ROUTES.external.services.combos,
    },
  ];

  const navigateToService = (route: string) => {
    navigate(route);
  };

  return (
    <div className={`container py-7 ${styles.wrapper}`}>
      <div className="row">
        <div className="col-12 text-center">
          <h1>
            Conoce nuestros{' '}
            <span className="text-secondary text-decoration-underline">servicios</span>
          </h1>
          <p className="mt-4 mx-0 mx-lg-4">
            Contamos con una gran variedad de servicios con las mejores tarifas y
            <br className="d-none d-md-block" /> precios del mercado, facilitándole su comunicación
            con familiares y amigos.
          </p>
        </div>
      </div>

      <div className="row mt-5">
        {services.map((service) => (
          <div
            className="col-12 col-sm-12 col-md-6 col-lg-3 col-xl-3 mb-5 mb-lg-0"
            key={service.key}>
            <div
              className={`${service.className} px-7 px-lg-5`}
              onClick={() => navigateToService(service.route)}>
              <div>
                <service.icon className="serviceIcon" />
                <h6 className="mt-1">{service.title}</h6>
                <p className={`mb-2 pDescription`}>{service.description}</p>

                <div className="wrapperArrow">
                  <div className="arrowBody"></div>
                  <FlechaDer width={20} />
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
