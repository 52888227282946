import * as React from 'react';
import { SVGProps } from 'react';
const SvgMensajes = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="mensajes_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    {...props}>
    <defs>
      <style>{'.mensajes_svg__cls-2{fill:#263645}'}</style>
    </defs>
    <g id="mensajes_svg__Capa_1-2" data-name="Capa 1">
      <path
        d="M9.12 44.29c-2.94-.2-5.23-2.4-5.49-5.33-.28-3.21-.56-8.09-.56-14.87s.28-11.66.56-14.87c.26-2.93 2.55-5.13 5.49-5.33 3.72-.26 9.66-.53 18.15-.53s14.43.27 18.15.53c2.94.2 5.23 2.4 5.49 5.33.28 3.21.56 8.09.56 14.87s-.28 11.66-.56 14.87c-.26 2.93-2.55 5.13-5.49 5.33-3.46.24-8.84.5-16.42.53l-8.3 7.11c-1.49 1.28-3.8.22-3.8-1.75v-5.51c-3.24-.1-5.82-.24-7.78-.38Z"
        style={{
          fill: '#ffe5d4',
        }}
      />
      <path
        className="mensajes_svg__cls-2"
        d="M19.21 53.57c-.48 0-.96-.1-1.42-.31a3.361 3.361 0 0 1-1.97-3.07v-4.47c-2.44-.09-4.72-.2-6.77-.35-3.46-.24-6.19-2.9-6.49-6.31C2.19 34.81 2 29.77 2 24.09s.19-10.71.56-14.97c.3-3.42 3.03-6.07 6.49-6.31 3.5-.24 9.51-.54 18.22-.54s14.72.29 18.22.54c3.46.24 6.19 2.9 6.49 6.31.37 4.25.56 9.29.56 14.97s-.19 10.71-.56 14.97c-.3 3.42-3.03 6.07-6.49 6.31-3.15.22-8.47.49-16.09.53l-8 6.85c-.63.54-1.4.82-2.19.82ZM9.19 43.21c2.32.16 4.93.29 7.74.38l1.04.03v6.56c0 .49.27.91.71 1.11.45.21.94.14 1.31-.18l8.6-7.37h.4c7.77-.04 13.18-.31 16.35-.53 2.43-.17 4.28-1.96 4.49-4.35.25-2.88.56-7.78.56-14.78s-.3-11.9-.56-14.78c-.21-2.39-2.06-4.18-4.49-4.35-3.47-.24-9.42-.53-18.07-.53s-14.6.29-18.07.53c-2.43.17-4.28 1.96-4.49 4.35-.25 2.88-.56 7.78-.56 14.78s.3 11.9.56 14.78c.21 2.39 2.06 4.18 4.49 4.35Z"
      />
      <path
        d="M51.87 49.99c1.99-.14 3.54-1.62 3.72-3.61.19-2.18.38-5.48.38-10.07s-.19-7.9-.38-10.07c-.17-1.99-1.73-3.47-3.72-3.61-2.52-.18-6.54-.36-12.29-.36-5.75 0-9.77.19-12.29.36-1.99.14-3.54 1.62-3.72 3.61-.19 2.18-.38 5.48-.38 10.07s.19 7.9.38 10.07c.17 1.99 1.73 3.47 3.72 3.61 2.34.16 5.99.34 11.12.36l5.62 4.82c1.01.87 2.58.15 2.58-1.18v-3.73c2.2-.07 3.94-.16 5.27-.26Z"
        style={{
          fill: '#ff9855',
        }}
      />
      <path
        className="mensajes_svg__cls-2"
        d="M45.03 56.62c-.61 0-1.22-.22-1.71-.64L38 51.42c-5.1-.03-8.67-.21-10.79-.36-2.51-.18-4.5-2.11-4.71-4.59-.25-2.89-.38-6.31-.38-10.17s.13-7.28.38-10.17c.22-2.49 2.2-4.42 4.71-4.59 2.38-.17 6.46-.36 12.36-.36s9.98.2 12.36.36c2.51.18 4.5 2.11 4.71 4.59.25 2.89.38 6.31.38 10.17s-.13 7.28-.38 10.17c-.22 2.49-2.2 4.42-4.71 4.59-1.3.09-2.73.17-4.26.22v2.69c0 1.04-.59 1.96-1.54 2.4-.36.17-.74.25-1.11.25Zm-5.45-33.28c-5.84 0-9.87.19-12.21.36-1.47.1-2.59 1.18-2.72 2.63-.17 1.94-.37 5.25-.37 9.98s.2 8.03.37 9.98c.13 1.44 1.24 2.53 2.72 2.63 2.14.15 5.79.33 11.05.36h.4l5.92 5.08c.2.17.41.12.51.07.1-.05.28-.17.28-.44v-4.78l1.04-.03c1.91-.06 3.66-.15 5.23-.26 1.47-.1 2.59-1.18 2.72-2.63.17-1.94.37-5.25.37-9.98s-.2-8.03-.37-9.98c-.13-1.45-1.24-2.53-2.72-2.63-2.35-.16-6.37-.36-12.21-.36Z"
      />
      <path
        id="mensajes_svg__Uni\xF3n_3"
        data-name="Uni\xF3n 3"
        d="M30.96 38.42q0-1.62 5.28-1.62t5.28 1.62-5.28 1.62-5.28-1.62Zm0-5.59q0-1.62 9.91-1.62t9.91 1.62q0 1.62-9.91 1.62t-9.91-1.62Z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);
export default SvgMensajes;
