import { IMAGES } from '../../../config/images';
import { AgregarSaldo, CreaTuCuenta, IconBackground, Listo } from '../../svg';
import { AppDownloadButton } from '../appDownloadButton';

import styles from './downloadApp.module.scss';

export const DownloadApp = () => {
  const steps = [
    {
      isDivider: false,
      title: 'Crea tu cuenta',
      description: 'Crea tu cuenta usando tu número de teléfono o correo electrónico.',
      icon: CreaTuCuenta,
    },
    {
      title: '1',
      isDivider: true,
      icon: CreaTuCuenta,
    },
    {
      isDivider: false,
      title: 'Agrega fondos',
      description: 'Agrega fondos a tu cuenta para hacer llamadas y enviar SMS.',
      icon: AgregarSaldo,
    },
    {
      title: '2',
      isDivider: true,
      icon: CreaTuCuenta,
    },
    {
      isDivider: false,
      title: 'Listo',
      description: 'Ya puedes comunicarte con tus contactos de todo el mundo.',
      icon: Listo,
    },
  ];

  return (
    <div className={styles.wrapper}>
      <div className="container position-relative">
        <div className="row">
          <div className="col-12 col-lg-6 d-flex align-items-center">
            <img src={IMAGES.Promos.PromoDescargaApp} className="img-fluid" />
          </div>
          <div className="col-12 col-lg-6 mt-5 mt-lg-0">
            <h1 className="pe-0 pe-lg-7 text-center text-lg-start">
              Unirse a nuestra familia es{' '}
              <span className="text-secondary text-decoration-underline">muy sencillo</span>
            </h1>

            <div className="mt-5">
              {steps.map((step) => (
                <div className={styles.listItemWrapper} key={step.title}>
                  {!step.isDivider && (
                    <div className={`${styles.iconWrapper}`}>
                      <IconBackground fill="#E4F6C3" className={styles.iconBackground} />

                      <div className={styles.iconTopWrapper}>
                        <step.icon className={styles.iconTop} />
                      </div>
                    </div>
                  )}

                  {step.isDivider && (
                    <div className={`${styles.iconWrapper} ${styles.dividerWrap}`}>
                      <div className={styles.divider}></div>
                    </div>
                  )}

                  {!step.isDivider && (
                    <div className={styles.listDescriptionWrapper}>
                      <h6>{step.title}</h6>
                      <p className="m-0">{step.description}</p>
                    </div>
                  )}
                </div>
              ))}
            </div>

            <h6 className="mb-4 mt-6 text-center text-lg-start">
              ¡Instala nuestra App y envía SMS Gratis!
            </h6>

            <div className={styles.wrapperAppDownload}>
              <div className="me-2">
                <AppDownloadButton isAndroid={false} className={'secondary'} />
              </div>
              <div>
                <AppDownloadButton isAndroid={true} className={'secondary'} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
