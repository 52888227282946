import { MegaContact } from '../types/contacts';
import api from './axios/api';

const getContacts = async (): Promise<MegaContact[]> => {
  const response = await api.get<any>('/api/v1/contacts?source_app=WEB', {
    withCredentials: true,
  });

  if (!response.has_error && response.array) {
    return response.array;
  }

  throw new Error(response.error);
};

const createContact = async (
  firstName: string,
  lastName: string,
  countryCode: string,
  phone: string,
  email?: string,
  address?: string,
  provinceId?: number,
  municipalityId?: number,
  town?: string,
  carneId?: string,
  mlcCard?: string
) => {
  try {
    const response = await api.post('/api/v1/contacts', {
      first_name: firstName,
      last_name: lastName,
      countryCode,
      phone,
      email,

      address,
      province_id: provinceId,
      municipality_id: municipalityId,
      town: town,
      carne_id: carneId,
      mlc_card: mlcCard,
    });

    return { success: !response.has_error, isDuplicated: false };
  } catch (error: any) {
    return {
      success: false,
      isDuplicated: !!error.data?.isDuplicated || false,
    };
  }
};

const deleteContact = async (id: number) => {
  try {
    const response = await api.delete('/api/v1/contacts', { params: { id } });

    return { success: !response.has_error };
  } catch (error: any) {
    return {
      success: false,
    };
  }
};

const editContact = async (
  contactId: number,
  firstName: string,
  lastName: string,
  email?: string,
  address?: string,
  provinceId?: number,
  municipalityId?: number,
  town?: string,
  carneId?: string,
  mlcCard?: string
) => {
  try {
    const response = await api.put('/api/v1/contacts', {
      first_name: firstName,
      last_name: lastName,
      contact_id: contactId,

      email,
      address,
      province_id: provinceId,
      municipality_id: municipalityId,
      town: town,
      carne_id: carneId,
      mlc_card: mlcCard,
    });

    return { success: !response.has_error, isDuplicated: false, notFound: false };
  } catch (error: any) {
    return {
      success: false,
      isDuplicated: !!error.data?.isDuplicated || false,
      notFound: !!error.data?.notFound || false,
    };
  }
};

export const ContactService = {
  getContacts,
  createContact,
  deleteContact,
  editContact,
};
