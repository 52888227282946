import * as React from 'react';
import { SVGProps } from 'react';
const SvgSoporteTecnicoAnaranjado = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <g data-name="Capa 1">
      <path
        d="M4.69 26.89c0-12.39 10.15-22.43 22.65-22.43s22.4 9.78 22.65 21.96l4.62 6.44c.83 1.15.74 2.83-.6 3.65-1.02.62-2.5 1.34-4.49 1.87l-.68 8.13a4.17 4.17 0 0 1-4.66 3.79l-3-.37v3.47c0 1.79-1.17 3.42-3 3.76-2.21.41-5.82.88-10.83.88s-8.63-.47-10.83-.88c-1.83-.34-3-1.96-3-3.76v-8.76C8.15 40.54 4.7 34.11 4.7 26.88Z"
        style={{
          fill: '#ffe5d4',
          fillRule: 'evenodd',
        }}
      />
      <path
        d="M42.78 39.45c-5.74-.29-10.04-2.46-12.8-6.46a16 16 0 0 1-1.14-1.94c2.88-1.31 4.89-4.21 4.89-7.58s-2.07-6.37-5.03-7.65V3.72c0-1.22-.89-2.28-2.1-2.45-.8-.11-1.59-.11-2.39 0-1.21.17-2.1 1.23-2.1 2.45v12.09a8.33 8.33 0 0 0-5.03 7.65c0 4.34 3.31 7.9 7.55 8.29.45 1.06 1.04 2.23 1.83 3.41 2.42 3.59 7.18 7.97 16.12 8.42h.11c1.09 0 2.01-.86 2.06-1.96.06-1.14-.82-2.11-1.96-2.17Z"
        style={{
          fill: '#ff9855',
        }}
      />
      <path
        d="m55.5 32.23-4.43-6.17c-.21-6.05-2.73-11.71-7.11-15.96a23.697 23.697 0 0 0-14.18-6.6c-.1-1.67-1.37-3.07-3.03-3.31-.89-.13-1.8-.13-2.69 0-1.73.25-3.04 1.76-3.04 3.53v.51C10.99 6.98 3.6 16.09 3.6 26.89c0 7.17 3.21 13.8 8.83 18.29v8.23c0 2.42 1.6 4.4 3.89 4.83 2.19.41 5.89.9 11.03.9s8.84-.49 11.03-.9c2.29-.43 3.89-2.41 3.89-4.83v-2.24l1.78.22c1.43.17 2.84-.23 3.96-1.14s1.8-2.2 1.92-3.64l.62-7.38c1.48-.45 2.84-1.05 4.04-1.78.83-.51 1.39-1.3 1.58-2.24.21-1.01-.04-2.1-.66-2.97Zm-32.3-15.7V3.72c0-.69.5-1.28 1.17-1.37.35-.05.69-.07 1.04-.07s.7.02 1.04.07c.67.09 1.17.69 1.17 1.37v12.81l.66.28c2.66 1.15 4.37 3.76 4.37 6.65s-1.67 5.42-4.25 6.59l-1.02.46.5 1.01c.36.73.77 1.43 1.22 2.08 2.96 4.29 7.55 6.63 13.64 6.93.26.01.5.13.68.32.18.19.27.45.25.71-.03.52-.46.93-.98.93h-.05c-8.49-.43-12.99-4.55-15.27-7.94-.67-1-1.25-2.08-1.73-3.22l-.25-.6-.65-.06a7.207 7.207 0 0 1-6.56-7.21c0-2.89 1.72-5.5 4.37-6.65l.66-.28Zm30.84 18.25c-.07.35-.27.62-.59.82-1.22.74-2.63 1.33-4.2 1.75l-.74.2-.75 8.9c-.07.84-.47 1.6-1.13 2.13-.66.53-1.48.77-2.32.67l-4.22-.52v4.7c0 1.35-.87 2.46-2.11 2.69-2.1.39-5.66.86-10.63.86s-8.53-.47-10.63-.86c-1.24-.23-2.11-1.34-2.11-2.69v-9.3l-.43-.33c-5.34-4.08-8.4-10.24-8.4-16.9 0-9.59 6.43-17.71 15.25-20.39v8.63A9.412 9.412 0 0 0 16 23.47c0 4.67 3.35 8.56 7.88 9.29a20.94 20.94 0 0 0 1.68 3.01c2.56 3.8 7.58 8.43 16.97 8.9h.16a3.154 3.154 0 0 0 2.34-5.27 3.13 3.13 0 0 0-2.18-1.03c-5.38-.27-9.4-2.29-11.96-5.99-.19-.28-.38-.58-.56-.88 2.77-1.7 4.5-4.74 4.5-8.03a9.4 9.4 0 0 0-5.03-8.33V5.7c10.53 1.2 18.9 10.05 19.12 20.75v.34l4.82 6.71c.27.38.39.86.3 1.27Z"
        style={{
          fill: '#263645',
        }}
      />
      <circle
        cx={25.43}
        cy={23.26}
        r={3.91}
        style={{
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);
export default SvgSoporteTecnicoAnaranjado;
