import * as React from 'react';
import { SVGProps } from 'react';
const SvgLlamadas = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 60 60" {...props}>
    <g data-name="Capa 1">
      <path
        d="M54.73 40.91c-1.85-3.1-5.92-5.37-9.55-6.84-2.91-1.17-4.57 1.82-6.22 3.64-1.84 2.03-3.5 2.08-5.71.74-.64-.39-1.26-.8-1.87-1.21-.06.08-.12.17-.2.24-.79.79-2.31.89-3.07 0-.74-.86-1.13-1.91-1.83-2.76-.73-.89-1.82-1.62-2.77-2.26-.97-.66-1.41-1.9-.78-2.97.08-.13.17-.25.28-.36-3.58-4.8-3.36-5.24 1.14-10.05 1.82-1.94 1.94-3.64.87-6.17-1.56-3.73-5.44-9.15-9.07-8.25-.59.15-1.24.36-1.78.68-8.69 4.97-12.26 17.73-7.35 26.43 4.98 8.83 12.03 15.66 20.62 20.94 2.91 1.79 6.16 2.51 8.98 2.75 8.16-.11 13.5-4.38 18.12-9.81 1.15-1.36 1.07-3.27.18-4.75Z"
        style={{
          fill: '#d0e3ae',
        }}
      />
      <path
        d="M49.31 8.37c1.53.16 2.74 1.35 2.9 2.86.76 7.24.21 13.47-.4 17.45-.35 2.31-2.92 3.27-4.78 1.82-1.26-.99-2.78-2.22-4.45-3.67-6.96 6.61-12.8 11.54-14.61 12.91-.65.49-1.47.71-2.26.46a9.02 9.02 0 0 1-3.61-2.15 8.962 8.962 0 0 1-2.22-3.63c-.23-.74-.03-1.52.44-2.15 1.34-1.78 6.34-7.55 13.15-14.44-1.46-1.63-2.7-3.12-3.69-4.36-1.46-1.83-.49-4.37 1.85-4.71 4.04-.6 10.35-1.14 17.68-.39Z"
        style={{
          fill: '#8ab934',
        }}
      />
      <path
        d="M55.66 40.35c-2.09-3.51-6.63-5.9-10.07-7.29-3.16-1.27-5.16 1.17-6.62 2.96-.28.35-.55.67-.81.96-1.41 1.55-2.47 1.68-4.34.54-.28-.17-.55-.35-.82-.52 2.51-2.16 5.85-5.13 9.63-8.7 1.24 1.07 2.5 2.09 3.75 3.07 1.2.93 2.72 1.17 4.07.63 1.32-.52 2.23-1.7 2.45-3.15.61-4.02 1.18-10.36.4-17.73-.21-2.01-1.84-3.62-3.87-3.83-7.45-.76-13.88-.2-17.95.4-1.48.22-2.68 1.14-3.2 2.47-.52 1.33-.28 2.83.66 4 .97 1.21 1.99 2.43 3.05 3.64a249.24 249.24 0 0 0-8.93 9.55c-.94-1.32-1.47-2.25-1.45-2.96.03-1 1.16-2.26 3.33-4.57 2.04-2.18 2.35-4.3 1.08-7.33-1.75-4.17-5.94-9.98-10.34-8.89-.81.2-1.5.46-2.06.79C4.33 9.72.64 23.03 5.88 32.31c4.83 8.56 11.89 15.73 20.99 21.34 2.63 1.62 5.82 2.6 9.46 2.9h.1c9.14-.12 14.7-5.22 18.93-10.19 1.38-1.63 1.5-3.98.29-6.01ZM34.29 17.11a93.858 93.858 0 0 1-3.65-4.31c-.45-.57-.57-1.24-.34-1.85.24-.6.78-1.01 1.49-1.11 2.58-.38 6.13-.74 10.3-.74 2.21 0 4.6.1 7.11.36 1.01.1 1.82.9 1.93 1.89.75 7.14.2 13.28-.39 17.18-.1.69-.51 1.22-1.11 1.45-.63.25-1.35.13-1.93-.33a96.28 96.28 0 0 1-4.4-3.64l-.75-.65-.72.68c-7.22 6.85-12.9 11.6-14.52 12.83-.41.31-.88.42-1.27.29-.84-.26-2.07-.8-3.18-1.89a7.81 7.81 0 0 1-1.94-3.18c-.16-.5.1-.95.27-1.17 1.42-1.89 6.55-7.75 13.05-14.33l.72-.73-.68-.76Zm19.43 27.84c-3.91 4.59-9.01 9.3-17.25 9.43-3.28-.28-6.12-1.15-8.45-2.58-8.78-5.41-15.59-12.32-20.24-20.55-4.6-8.14-1.23-20.28 6.93-24.99.39-.22.89-.41 1.5-.56.18-.04.36-.07.55-.07 2.64 0 5.84 4.3 7.26 7.68.94 2.25.78 3.46-.67 5.01-4.63 4.95-5.11 6.01-1.75 10.7-.97 1.13-1.7 2.03-2.13 2.6-.71.95-.93 2.08-.6 3.12a9.985 9.985 0 0 0 2.49 4.09c1.42 1.38 2.98 2.07 4.04 2.41.33.1.66.15 1 .15.78 0 1.56-.27 2.24-.79.51-.39 1.42-1.12 2.63-2.14.46.31.93.61 1.41.91 2.78 1.69 4.96 1.4 7.09-.93.3-.33.6-.69.88-1.04 1.53-1.86 2.56-2.95 4.13-2.32 3.13 1.26 7.24 3.39 9.02 6.39.55.92.86 2.39-.08 3.49Z"
        style={{
          fill: '#263645',
        }}
      />
      <ellipse
        cx={29.11}
        cy={31.14}
        rx={5.9}
        ry={1.63}
        transform="rotate(-45 29.118 31.139)"
        style={{
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);
export default SvgLlamadas;
