import { useEffect, useRef, useState } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { IMaskInput } from 'react-imask';
import { useQuery, useQueryClient } from 'react-query';
import Select from 'react-select';
import { MegaError, PageTitle } from '../../../components/account';
import { EnviarSmsPage } from '../../../components/svg';
import {
  IconOptionContact,
  IconSingleValueContact,
  reactSelectStyles,
} from '../../../config/reactSelect';
import { useFindPreSelectedContact } from '../../../hooks/useFindPreSelectedContact';
import { AccountServices, ContactService, SmsService, UtilService } from '../../../services';
import { MegaContact } from '../../../types/contacts';
import { QueryTypes } from '../../../types/queryTypes';
import { SmsCountryRate } from '../../../types/sms';
import { handlePhoneNumberPaste } from '../../../utilities/TopUpHelpers';
import { IconOptionSmsRateCountry, IconSingleValueSmsRateCountry } from './options';

import styles from './send-sms-screen.module.scss';

export const SendSmsScreen = () => {
  const queryCache = useQueryClient();
  const phoneRef = useRef<any>(null);
  const charLimitSms = 160;

  const findPreSelectedContact = useFindPreSelectedContact();
  const [smsStep, setSmsStep] = useState<'contact' | 'other'>('other');
  const [contactList, setContactList] = useState<MegaContact[]>([]);
  const [selectedContact, setSelectedContact] = useState<MegaContact | null>(null);
  const [countryList, setCountryList] = useState<SmsCountryRate[]>([]);
  const [selectedCountry, setSelectedCountry] = useState<SmsCountryRate | null>(null);
  const [customPhone, setCustomPhone] = useState<string>('');
  const [charCount, setCharCount] = useState<number>(charLimitSms);
  const [smsContent, setSmsContent] = useState<string>('');
  const [phoneHasError, setPhoneHasError] = useState<boolean>(false);
  const [smsContentHasError, setSmsContentHasError] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string>('');

  const profileQuery = useQuery(QueryTypes.GetProfile, AccountServices.getProfileData);
  const contactQuery = useQuery(QueryTypes.GetContacts, ContactService.getContacts);
  const countryQuery = useQuery(QueryTypes.GetSmsRateCountries, SmsService.getCountryRates);

  const screenQueries = [countryQuery, contactQuery, profileQuery];

  useEffect(() => {
    if (countryQuery.status === 'success' && countryQuery.data.length) {
      setCountryList(countryQuery.data);

      if (!selectedCountry) {
        const cuba = (countryQuery.data || []).find(
          (item) => (item.country_code || '').toLowerCase() == 'cu'
        );

        setSelectedCountry(cuba || countryQuery.data[0]);
      }
    }
  }, [countryQuery.status]);

  useEffect(() => {
    if (contactQuery.status === 'success' && contactQuery.data.length) {
      setContactList(contactQuery.data);
    }
  }, [contactQuery.status]);

  useEffect(() => {
    if (contactList.length > 0) {
      findPreSelectedContact({
        contacts: contactList,
        onPreSelectedContactFound: (contact) => {
          setSelectedContact(contact);
          setSmsStep('contact');
        },
        onPreSelectedContactNotFound: () => setSelectedContact(contactList[0]),
      });
    }
  }, [contactList]);

  const getSmsCost = () => {
    let country =
      smsStep === 'contact' ? selectedContact?.country_code : selectedCountry?.country_code;
    country = (country || '').toLowerCase();

    if (!country) {
      return Number(0.0).toFixed(2);
    }

    const smsCountry = countryList.find((item) => item.country_code.toLowerCase() === country);
    return Number(smsCountry?.price ?? 0.0).toFixed(2);
  };

  const onSmsContentChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setSmsContent(event.target.value);
    setCharCount(charLimitSms - (event.target.value || '').length);
  };

  const resetValues = () => {
    setSmsContent('');
    setCharCount(charLimitSms);
  };

  const onSubmit = async () => {
    let phone = smsStep === 'contact' ? selectedContact?.contact_name || '' : customPhone;
    const countryCode =
      smsStep === 'contact' ? selectedContact?.country_code : selectedCountry?.country_code;

    const countryCallingCode = selectedCountry?.calling_code;

    if (smsStep === 'other' && UtilService.getMaskLength(selectedCountry?.mask) !== phone.length) {
      setPhoneHasError(true);
      return;
    }

    if (!countryCode) {
      setErrorMessage('Por favor seleccione un país o contacto;');
      return;
    }

    if (!smsContent) {
      setSmsContentHasError(true);
      return;
    }

    // cleaning the number
    phone = smsStep === 'other' ? `${countryCallingCode}${phone}` : phone;
    phone = phone.replace(/[^0-9]/gi, '');

    setPhoneHasError(false);
    setSmsContentHasError(false);
    setIsSubmitting(true);
    setErrorMessage('');

    try {
      const response = await SmsService.sendSms(phone, smsContent, countryCode);

      if (response) {
        resetValues();
        queryCache.invalidateQueries(QueryTypes.GetProfile);
      }
      setIsSubmitting(false);
    } catch (error: any) {
      setIsSubmitting(false);
      if (error && error.data?.code === '002') {
        setErrorMessage('No tienes suficientes fondos para mandar este SMS.');
        return;
      }

      setErrorMessage('Hubo un error mandando su mensaje. Por favor, intente de nuevo.');
    }
  };

  // if (UtilService.isLoadingQueries(screenQueries)) {
  //   return <MegaLoading isLoading={true} />;
  // }

  if (UtilService.isErrorQueries(screenQueries)) {
    return <MegaError displayRetry retry={() => UtilService.refetchQueries(screenQueries)} />;
  }

  const account = profileQuery.data?.account;

  return (
    <div className="bg-mega_light_gray">
      <div className="container py-5">
        <PageTitle
          title="Enviar SMS"
          description="Envía mensajes de texto pagando mucho menos que lo que paga con su operador."
          icon={EnviarSmsPage}
        />

        <div className="row">
          <div className="col-12 col-lg-6 d-block d-lg-none mb-5">
            <div className="column-alert">
              <div className="item-column">
                <h3 className="text-secondary">${account?.balance_format}</h3>
                <span>Mi Saldo</span>
              </div>
              <div className="item-column">
                <h3 className="">{account?.sms_free_out}</h3>
                <span>SMS gratis para Cuba</span>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className={styles.inputArea}>
              <div className="d-flex w-100 flex-column flex-lg-row">
                <div className="flex-fill">
                  <h6>Destinatario</h6>
                </div>
                <div className="flex-fill d-flex flex-row justify-content-center justify-content-lg-end">
                  <div
                    className={`d-inline px-3 ${styles.tabItem} ${
                      smsStep === 'other' ? styles.activeTab : ''
                    }`}
                    onClick={() => setSmsStep('other')}>
                    Otro Número
                  </div>
                  <div
                    className={`d-inline px-3 ${styles.tabItem} ${
                      smsStep === 'contact' ? styles.activeTab : ''
                    }`}
                    onClick={() => setSmsStep('contact')}>
                    Mis Contactos
                  </div>
                </div>
              </div>

              {smsStep === 'other' && (
                <div>
                  <div className="w-100 mt-3">
                    <p>Ingrese el número al que quiere enviar un mensaje</p>
                  </div>

                  <div className="row mt-5">
                    <div className="col-12 col-lg-6">
                      <div className="megaInput">
                        <label htmlFor="country">Seleccione el país</label>
                        <Select
                          id="country"
                          placeholder=""
                          value={selectedCountry}
                          options={countryList}
                          isSearchable={false}
                          isClearable={false}
                          isLoading={false}
                          className="w-100 mega-select"
                          isMulti={false}
                          styles={reactSelectStyles}
                          components={{
                            Option: IconOptionSmsRateCountry,
                            SingleValue: IconSingleValueSmsRateCountry,
                          }}
                          onChange={(newValue) => {
                            setSelectedCountry(newValue);
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                      <div className="megaInput">
                        <label htmlFor="tel">Número</label>
                        <IMaskInput
                          id="tel"
                          mask={UtilService.convertPhoneMask(selectedCountry?.mask)}
                          unmask={true}
                          ref={phoneRef}
                          value={customPhone}
                          onAccept={(value, _) => setCustomPhone(`${value}`)}
                          placeholder="Teléfono"
                          className={`form-control mega ${
                            phoneHasError ? 'border border-danger' : ''
                          }`}
                          onPasteCapture={(e: any) => {
                            handlePhoneNumberPaste({
                              mask: selectedCountry?.mask,
                              event: e,
                              setter: (value) => setCustomPhone(value),
                            });
                          }}
                        />
                        {phoneHasError && (
                          <div className="text-danger">El teléfono es requerido</div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {smsStep === 'contact' && (
                <div>
                  <div className="w-100 mt-3">
                    <p>Elija uno de sus contactos para enviar un mensaje</p>
                  </div>

                  <div className="row mt-5">
                    <div className="col-12">
                      <div className="megaInput">
                        <label htmlFor="contact">Mis Contactos</label>
                        <Select
                          id="contact"
                          placeholder=""
                          value={selectedContact}
                          options={contactList}
                          isSearchable={false}
                          isClearable={false}
                          isLoading={false}
                          className="w-100 mega-select"
                          isMulti={false}
                          styles={reactSelectStyles}
                          components={{
                            Option: IconOptionContact,
                            SingleValue: IconSingleValueContact,
                          }}
                          onChange={(newValue) => {
                            setSelectedContact(newValue as MegaContact);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}

              <div className="row mt-4">
                <div className="col-12">
                  <textarea
                    className="form-control mega"
                    placeholder="Escribe tu mensaje"
                    rows={5}
                    value={smsContent}
                    maxLength={charLimitSms}
                    onChange={onSmsContentChange}
                  />
                  <small className="text-muted">{charCount} caracteres disponibles</small>
                  {smsContentHasError && (
                    <div className="text-danger">El contenido del SMS es requerido</div>
                  )}
                </div>
              </div>

              {errorMessage && !isSubmitting && (
                <div className="row mt-4">
                  <div className="col-12">
                    <Alert variant={'danger'}>{errorMessage}</Alert>
                  </div>
                </div>
              )}

              <div className="row mt-4">
                <div className="col-12 col-lg-6">
                  <div className="w-100 h-100 d-flex align-items-center">
                    <div className="me-2">
                      <span className={styles.smsCostLabel}>Costo del SMS:</span>
                    </div>
                    <div className="flex-fill text-end text-lg-start">
                      <span className={styles.smsCost}>${getSmsCost()}</span>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-6 mt-4 mt-lg-0">
                  <Button
                    variant="secondary"
                    className="w-100 text-white py-2"
                    onClick={onSubmit}
                    disabled={isSubmitting}>
                    {isSubmitting ? (
                      <Spinner
                        as="span"
                        animation="grow"
                        size="sm"
                        role="status"
                        variant="light"
                        aria-hidden="true"
                      />
                    ) : (
                      <span>Enviar SMS</span>
                    )}
                  </Button>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-lg-6 d-none d-lg-block">
            <div className="column-alert">
              <div className="item-column">
                <h3 className="text-secondary">${account?.balance_format}</h3>
                <span>Mi Saldo</span>
              </div>
              <div className="item-column">
                <h3 className="">{account?.sms_free_out}</h3>
                <span>SMS gratis para Cuba</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
