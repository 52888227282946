import * as React from 'react';
import { SVGProps } from 'react';
const SvgNotificacionesSmsEmail = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" {...props}>
    <g data-name="Capa 1">
      <path
        d="m21.3 15.45-.74-1.21a8.082 8.082 0 0 1-1.18-3.56l-.24-2.83a7.706 7.706 0 0 0-1.64-4.14C16.07 1.91 13.83.94 11.53.94h-.04c-2.3 0-4.54.97-5.97 2.77a7.675 7.675 0 0 0-1.64 4.14l-.24 2.83a8.082 8.082 0 0 1-1.18 3.56l-.74 1.21c-.75 1.21-.34 2.46 1.07 2.65 1.61.21 4.28.41 8.73.41h-.05.1c4.41 0 7.08-.19 8.68-.41 1.41-.19 1.82-1.44 1.07-2.65Zm-5.19-6.53c-.63.56-.9-1.21-2.46-2.98s-2.01-2.21-1.38-2.77 1.47-.46 3.03 1.31c1.56 1.77 1.44 3.88.81 4.44Z"
        style={{
          fill: '#8f979f',
        }}
      />
      <path
        d="M7.53 18.44c.22 2.03 1.94 3.6 4.02 3.6s3.8-1.58 4.02-3.6"
        style={{
          opacity: 0.4,
          fill: '#8f979f',
        }}
      />
    </g>
  </svg>
);
export default SvgNotificacionesSmsEmail;
