import * as React from 'react';
import { SVGProps } from 'react';
const SvgAllbreadcrumb = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" data-name="Grupo 367" viewBox="0 0 24.895 23" {...props}>
    <defs>
      <clipPath id="Allbreadcrumb_svg__a">
        <path data-name="Rect\xE1ngulo 330" fill="#263645" d="M0 0h24.895v23H0z" />
      </clipPath>
      <clipPath id="Allbreadcrumb_svg__b">
        <path data-name="Rect\xE1ngulo 326" fill="#263645" d="M0 0h10.95v10.257H0z" />
      </clipPath>
      <clipPath id="Allbreadcrumb_svg__c">
        <path data-name="Rect\xE1ngulo 327" fill="#263645" d="M0 0h10.95v10.261H0z" />
      </clipPath>
      <clipPath id="Allbreadcrumb_svg__d">
        <path data-name="Rect\xE1ngulo 328" fill="#263645" d="M0 0h11.09v10.257H0z" />
      </clipPath>
    </defs>
    <g data-name="Grupo 378" clipPath="url(#Allbreadcrumb_svg__a)">
      <g data-name="Grupo 377">
        <g data-name="Grupo 376" clipPath="url(#Allbreadcrumb_svg__a)">
          <g data-name="Grupo 369" opacity={0.4}>
            <g data-name="Grupo 368">
              <g data-name="Grupo 367" clipPath="url(#Allbreadcrumb_svg__b)">
                <path
                  data-name="Trazado 290"
                  d="M.259 3.346C.153 4.645.044 6.536 0 9.086a1.507 1.507 0 0 0 1.326 1.047c.75.061 2.252.125 3.948.125s3.406-.064 4.155-.125a1.509 1.509 0 0 0 1.4-1.4c.061-.749.125-1.939.125-3.635s-.064-2.885-.125-3.635a1.51 1.51 0 0 0-1.4-1.4C9.128.047 8.67.022 8.121 0 6.095.054 4.416.141 3.38.225A3.378 3.378 0 0 0 .259 3.346"
                  fill="#263645"
                />
              </g>
            </g>
          </g>
          <g data-name="Grupo 372" opacity={0.4}>
            <g data-name="Grupo 371">
              <g
                data-name="Grupo 370"
                clipPath="url(#Allbreadcrumb_svg__c)"
                transform="translate(0 12.682)">
                <path
                  data-name="Trazado 291"
                  d="M9.429.125C8.679.064 6.969 0 5.273 0s-3.2.064-3.947.125A1.507 1.507 0 0 0 0 1.173c.044 2.553.153 4.445.259 5.745a3.377 3.377 0 0 0 3.121 3.121c1.02.083 2.664.168 4.649.222.59-.021 1.082-.048 1.4-.074a1.51 1.51 0 0 0 1.4-1.4c.061-.749.125-1.939.125-3.635s-.064-2.885-.125-3.635a1.511 1.511 0 0 0-1.4-1.4"
                  fill="#263645"
                />
              </g>
            </g>
          </g>
          <g data-name="Grupo 375" opacity={0.4}>
            <g data-name="Grupo 374">
              <g
                data-name="Grupo 373"
                clipPath="url(#Allbreadcrumb_svg__d)"
                transform="translate(13.751 .001)">
                <path
                  data-name="Trazado 292"
                  d="M11.02 8.736c.024-.3.049-.675.07-1.124a82.755 82.755 0 0 0-.224-4.267A3.377 3.377 0 0 0 7.749.224C6.716.14 4.876.054 2.731-.001c-.5.021-.913.045-1.21.07a1.51 1.51 0 0 0-1.4 1.395c-.061.75-.124 1.94-.124 3.636S.06 7.985.121 8.735a1.511 1.511 0 0 0 1.4 1.4c.75.061 2.252.125 3.948.125s3.405-.064 4.155-.125a1.511 1.511 0 0 0 1.4-1.4"
                  fill="#263645"
                />
              </g>
            </g>
          </g>
          <path
            data-name="Trazado 293"
            d="M24.9 17.743a5.257 5.257 0 1 1-5.257-5.257 5.257 5.257 0 0 1 5.257 5.257"
            fill="#263645"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgAllbreadcrumb;
