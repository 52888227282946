import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { GridColDef } from '@mui/x-data-grid';
import { QueryTypes } from '../../../types/queryTypes';
import { baseColumnDef, currencyColumnDef, referralCategoryColumnDef } from './baseColumnDef';

import { ReportDataGrid } from './reportDataGrid';

import { ReportsConfig } from '../../../config/reportsConfig';
import { UtilService } from '../../../services';
import { ReferralServices } from '../../../services/referral';
import { MegaReferralReportItem } from '../../../types/reports/referralReportItem';
import { MegaError } from '../megaError';
import { ReportDateSearch } from './reportDateSearch';

export const ReferralReport = () => {
  const [reportItems, setReportItems] = useState<MegaReferralReportItem[]>([]);
  const [startDate, setStartDate] = useState<Date>(ReportsConfig.defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(ReportsConfig.defaultEndDate);
  const [isSearching, setIsSearching] = useState<boolean>(true);

  const referralReportQuery = useQuery(
    [QueryTypes.GetReferralReport, startDate, endDate, isSearching],
    () => {
      if (isSearching === true) {
        return ReferralServices.getReferralReport({ startDate, endDate });
      } else {
        return [];
      }
    }
  );

  useEffect(() => {
    if (!isSearching) {
      return;
    }

    if (referralReportQuery.status === 'success') {
      setReportItems(referralReportQuery.data);
    }

    if (['error', 'success'].includes(referralReportQuery.status)) {
      setIsSearching(false);
    }
  }, [referralReportQuery.data]);

  const columns: GridColDef[] = [
    {
      ...baseColumnDef,
      field: 'created',
      headerName: 'Fecha',
      sortable: true,
      width: 180,
    },
    {
      ...baseColumnDef,
      field: 'payer_username',
      headerName: 'Pagador',
      width: 130,
    },
    {
      ...referralCategoryColumnDef,
      field: 'category',
      headerName: 'Categoría',
      flex: 1,
      minWidth: 200,
    },
    {
      ...currencyColumnDef,
      field: 'amount',
      headerName: 'Cantidad',
      width: 100,
    },
    {
      ...currencyColumnDef,
      field: 'balance_end',
      headerName: 'Balance final',
      width: 120,
    },
  ];

  const onSearch = (startDateSearch: Date, endDateSearch: Date) => {
    setIsSearching(true);
    setStartDate(startDateSearch);
    setEndDate(endDateSearch);
  };

  return (
    <>
      <ReportDateSearch
        startDate={startDate}
        endDate={endDate}
        isSearching={isSearching}
        onSearch={onSearch}
      />

      <div className="mt-5">
        {UtilService.isErrorQueries([referralReportQuery]) ? (
          <MegaError
            displayRetry={true}
            retry={() => {
              UtilService.refetchQueries([referralReportQuery]);
            }}
          />
        ) : (
          <ReportDataGrid<MegaReferralReportItem>
            columns={columns}
            getRowId={(row: MegaReferralReportItem) => row['id ']}
            rows={reportItems}
            isSearching={isSearching}
          />
        )}
      </div>
    </>
  );
};
