import { ReactElement } from 'react-imask/dist/mixin';
import { TopUpCountry } from '../types/country';
import { Formatter } from './Formatter';

export const finalAmount = (cost: string, discount: number | undefined) => {
  const finalAmount = Number(cost ?? 0) - (discount ?? 0);

  return Number(finalAmount.toFixed(2));
};

export const handlePhoneNumberPaste = ({
  mask,
  event,
  setter,
}: {
  mask: string | undefined;
  event: React.ClipboardEvent<ReactElement>;
  setter: (value: string) => void;
}) => {
  if (mask) {
    const maskLength = (mask.match(/N/g) || []).length;
    const text = Formatter.onlyNumbers(event.clipboardData.getData('text'));
    setter(text.slice(maskLength * -1));
  }
};
