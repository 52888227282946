import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { ROUTES } from '../../config/routes';
import { StorageAuthKey } from '../../providers/session.provider';
import { StorageService } from '../index';

const API_URL = process.env.REACT_APP_API_BASE_URL;
let isRefreshingToken = false;

const onRequest = (config: AxiosRequestConfig): AxiosRequestConfig => {
  const token = StorageService.getItem<string>(StorageAuthKey.token);

  if (!config) {
    config = {};
  }
  if (!config.headers) {
    config.headers = {};
  }

  config.headers['X-App-Id'] = `${process.env.REACT_APP_ID}`;
  if (token) {
    config.headers['Authorization'] = `Bearer ${token}`;
  }

  return config;
};

const onRequestError = (error: AxiosError): Promise<AxiosError> => {
  return Promise.reject(error);
};

const onResponse = (response: AxiosResponse): AxiosResponse => {
  if (response && response.data) {
    return response.data;
  }

  return response;
};

const onResponseError = async (error: AxiosError): Promise<AxiosError | undefined> => {
  if (error.response) {
    // Access Token was expired
    if (error.response.status === 401 && !isRefreshingToken) {
      try {
        isRefreshingToken = true;
        const storedToken = StorageService.getItem<string>(StorageAuthKey.refreshToken);

        const rs = await axios.get(`${API_URL}/api/v1/w/auth/refresh`, {
          headers: {
            Authorization: `Bearer ${storedToken}`,
            'X-App-Id': `${process.env.REACT_APP_ID}`,
          },
        });

        const { token, refresh_token } = rs.data?.data;

        if (token && refresh_token) {
          StorageService.setItem(StorageAuthKey.token, token);
          StorageService.setItem(StorageAuthKey.refreshToken, refresh_token);
          isRefreshingToken = false;
          return;
        }

        return Promise.reject(error);
      } catch (_error: any) {
        // refresh token has expired, so let's get out of here to the login page
        if (((_error.message as string) || '').includes('status code 401')) {
          StorageService.removeItem(StorageAuthKey.token);
          StorageService.removeItem(StorageAuthKey.refreshToken);
          StorageService.removeItem(StorageAuthKey.user);
          isRefreshingToken = false;

          (
            window as any
          ).location = `${window.location.protocol}//${window.location.host}${ROUTES.login}`;
          return;
        }

        return Promise.reject(_error);
      }
    }

    if (error.response.status === 401 && isRefreshingToken) {
      return Promise.reject({ _isRetrying: true });
    }
  }

  if (error && error.response?.data) {
    return Promise.reject(error.response?.data);
  }

  return Promise.reject(error);
};

export const setupInterceptorsTo = (axiosInstance: AxiosInstance): AxiosInstance => {
  axiosInstance.interceptors.request.use(onRequest, onRequestError);
  axiosInstance.interceptors.response.use(onResponse, onResponseError);

  return axiosInstance;
};
