import * as React from 'react';
import { SVGProps } from 'react';
const SvgFilterMixtos = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" {...props}>
    <g data-name="Capa 1">
      <g data-name="Grupo 16772">
        <g data-name="Grupo 16771" fill="#abadc4">
          <path
            data-name="Trazado 34114"
            d="M7.448 1.188a2.674 2.674 0 0 0-2.335 1.331 38.938 38.938 0 0 0-2.351 4.8c.449 0 1-.015 1.651-.015A37.442 37.442 0 0 1 6.429 3.28a1.169 1.169 0 0 1 1.019-.571h1.431a.761.761 0 1 0 0-1.522H7.448Zm10.264 1.331a38.938 38.938 0 0 1 2.351 4.8c-.449 0-1-.015-1.651-.015a37.442 37.442 0 0 0-2.016-4.025 1.169 1.169 0 0 0-1.019-.571h-1.43a.761.761 0 1 1 0-1.522h1.43a2.674 2.674 0 0 1 2.336 1.331Zm-6.3 9.32c-4.907 0-7.882-.038-9.251-.053a2.666 2.666 0 0 1-.472-.046c.046.441.114.951.183 1.522.243 1.864.639 4.344 1.217 6.786a3.483 3.483 0 0 0 3.028 2.67 50.931 50.931 0 0 0 5.3.281 50.931 50.931 0 0 0 5.3-.281 3.483 3.483 0 0 0 3.028-2.67 67.185 67.185 0 0 0 1.208-6.79c.076-.571.137-1.08.183-1.522a2.666 2.666 0 0 1-.472.046 605.09 605.09 0 0 1-9.251.053Z"
            fillRule="evenodd"
            opacity={0.4}
          />
          <path
            data-name="Trazado 34115"
            d="M11.412 11.84c-4.907 0-7.882-.038-9.251-.053a2.026 2.026 0 0 1-2.054-1.37 3.523 3.523 0 0 1 0-1.719 2.036 2.036 0 0 1 2.054-1.37c1.369-.023 4.339-.053 9.251-.053s7.882.038 9.251.053a2.026 2.026 0 0 1 2.054 1.369 3.523 3.523 0 0 1 0 1.719 2.036 2.036 0 0 1-2.054 1.369 581.38 581.38 0 0 1-9.251.055Z"
          />
          <path
            data-name="Trazado 34116"
            d="M11.412 3.729c.814 0 1.407-.008 1.811-.023a1.474 1.474 0 0 0 1.476-1.347c.008-.122.015-.251.015-.4a3.312 3.312 0 0 0-.015-.4A1.468 1.468 0 0 0 13.223.207c-.4-.015-1-.023-1.811-.023s-1.407.008-1.811.023a1.474 1.474 0 0 0-1.476 1.347 5.917 5.917 0 0 0-.015.4 3.312 3.312 0 0 0 .015.4 1.468 1.468 0 0 0 1.476 1.347c.403.021.997.028 1.811.028Z"
          />
          <path
            data-name="Trazado 34117"
            d="M7.258 14.381a.762.762 0 0 1 .852.662l.51 4.055a.763.763 0 0 1-1.514.19l-.51-4.055a.762.762 0 0 1 .662-.852Z"
            fillRule="evenodd"
          />
          <path
            data-name="Trazado 34118"
            d="M15.566 14.381a.762.762 0 0 0-.852.662l-.51 4.055a.763.763 0 0 0 1.514.19l.51-4.055a.762.762 0 0 0-.662-.852Z"
            fillRule="evenodd"
          />
          <path
            data-name="Trazado 34119"
            d="M11.412 14.381a.763.763 0 0 1 .761.761v4.055a.761.761 0 0 1-1.522 0v-4.055a.763.763 0 0 1 .761-.761Z"
            fillRule="evenodd"
          />
        </g>
      </g>
    </g>
  </svg>
);
export default SvgFilterMixtos;
