import { Oval } from 'react-loader-spinner';

import colors from '../../../assets/styles/_variables.module.scss';
import styles from './megaLoading.module.scss';

type MegaLoadingProps = {
  isLoading: boolean;
  size?: number;
};

export const MegaLoading = (props: MegaLoadingProps) => {
  const size = props.size ?? 100;

  return (
    <div className="container py-7">
      <div className={styles.mega_loading_wrapper}>
        <Oval
          color={colors.secondary}
          secondaryColor={colors.lightSecondary}
          height={size}
          width={size}
          visible={props.isLoading}
          wrapperClass={styles.mega_loading}
        />
      </div>
    </div>
  );
};
