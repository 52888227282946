import * as React from 'react';
import { SVGProps } from 'react';
const SvgFacebook = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" {...props}>
    <defs>
      <clipPath id="facebook_svg__a">
        <path
          style={{
            fill: 'none',
          }}
          d="M7.16 2.29h11.68v21.42H7.16z"
        />
      </clipPath>
    </defs>
    <g data-name="Capa 1">
      <g
        data-name="Grupo 86"
        style={{
          clipPath: 'url(#facebook_svg__a)',
        }}>
        <path
          data-name="Trazado 79"
          d="M14.46 2.29c-2.42 0-4.38 1.96-4.38 4.38v3.41H7.65a.49.49 0 0 0-.49.49v3.9c0 .27.22.49.49.49h2.43v8.28c0 .27.22.49.49.49h3.89c.27 0 .49-.22.49-.49v-8.28h2.43c.22 0 .42-.15.47-.37l.98-3.89a.483.483 0 0 0-.35-.59c-.04 0-.08-.01-.12-.01h-3.41V8.64c0-.81.65-1.46 1.46-1.46h1.95c.27 0 .49-.22.49-.49V2.8a.49.49 0 0 0-.49-.49h-3.89Z"
          style={{
            fill: '#fff',
          }}
        />
      </g>
    </g>
  </svg>
);
export default SvgFacebook;
