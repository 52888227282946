import * as React from 'react';
import { SVGProps } from 'react';
const SvgCorreoFooter = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 30 30" {...props}>
    <g data-name="Capa 1">
      <g data-name="Grupo 5138">
        <g data-name="Grupo 5139">
          <g data-name="Grupo 5136">
            <path
              data-name="Trazado 10371"
              d="M.88 3.28C.46 2.32.9 1.21 1.86.8c.45-.2.97-.21 1.43-.03 8.75 2.98 17.1 7.04 24.84 12.08a2.591 2.591 0 0 1 0 4.31c-7.75 5.05-16.09 9.11-24.84 12.08-.97.37-2.07-.11-2.44-1.09-.18-.46-.17-.97.03-1.43 1.37-3.38 2.94-6.62 3.72-8.19.32-.63.86-1.12 1.53-1.36l6.01-2.18-6.01-2.18c-.67-.24-1.21-.73-1.53-1.36C3.82 9.88 2.25 6.64.88 3.26"
              style={{
                fill: '#8ab934',
              }}
            />
            <path
              data-name="Trazado 10372"
              d="M2.6 29.94c-.68 0-1.32-.27-1.79-.76-.67-.72-.86-1.76-.47-2.67 1.37-3.38 2.93-6.6 3.74-8.23.38-.77 1.05-1.36 1.85-1.64l4.51-1.63-4.51-1.63c-.81-.29-1.47-.88-1.85-1.64C3.27 10.1 1.71 6.88.34 3.5A2.48 2.48 0 0 1 1.64.26C2.22 0 2.88 0 3.48.22c8.8 3 17.19 7.08 24.98 12.15 1.45.97 1.84 2.94.87 4.4-.23.34-.53.64-.87.87A111.67 111.67 0 0 1 3.48 29.79c-.28.1-.57.15-.87.15M1.41 3.06c1.36 3.34 2.9 6.54 3.7 8.15.25.5.68.88 1.21 1.07L13.83 15l-7.51 2.72c-.53.19-.96.57-1.21 1.07-.8 1.61-2.34 4.81-3.7 8.15-.22.48-.12 1.05.24 1.43.37.39.94.51 1.44.32 8.7-2.96 17-7 24.71-12.02.93-.61 1.18-1.86.56-2.79a1.99 1.99 0 0 0-.56-.56c-7.7-5.01-16-9.05-24.7-12.01-.5-.19-1.07-.07-1.44.32-.36.39-.46.95-.24 1.43"
              style={{
                fill: '#384a5a',
              }}
            />
            <path
              data-name="Trazado 10373"
              d="M18.15 10.8c.37-.78 1.39-.68 3.64.38s2.99 1.77 2.62 2.56-1.4.68-3.64-.38-2.99-1.77-2.62-2.56"
              style={{
                fill: '#fff',
              }}
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
);
export default SvgCorreoFooter;
