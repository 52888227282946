import { useContext, useEffect, useState } from 'react';
import { Alert, Col, FormCheck, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { Steps } from '../../../components/account';
import { CheckoutActions } from '../../../components/account/checkoutActions';
import { ShoppingCartProductList } from '../../../components/account/shoppingCartProductList';
import { ShoppingCartTotals } from '../../../components/account/shoppingCartTotals';
import {
  InvoicingAddressSummaryCard,
  PaymentMethodSummaryCard,
  ShippingInformationSummaryCard,
} from '../../../components/account/summaryCard';
import { ROUTES } from '../../../config/routes';
import { useShoppingCart } from '../../../context/ShoppingCartContext';
import { SessionContext } from '../../../providers/session.provider';
import { PaymentServices } from '../../../services';
import { Formatter } from '../../../utilities';
import { AlertHelper } from '../../../utilities/AlertHelper';

export const CheckoutSummaryScreen = () => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState<boolean>(false);
  const [isReferralBalanceApplicable, setIsReferralBalanceApplicable] = useState<boolean>(false);

  const { referralBalance, reloadReferralBalance } = useContext(SessionContext);

  const navigate = useNavigate();

  const {
    clearAfterSuccessfulPurchase,
    currentPaymentMethod,
    currentPaymentMethodType,
    newCardInformation,
    newPaypalInformation,
    products,
    productsTotal,
    shippingInformation,
  } = useShoppingCart();

  useEffect(() => {
    if (currentPaymentMethodType === undefined) {
      navigate(ROUTES.cuenta.checkoutPaymentMethods);
    } else {
      reloadReferralBalance();
    }

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setIsLoading(false);
  }, [referralBalance]);

  const processPurchase = async () => {
    try {
      setIsProcessing(true);
      if (await completePurchase()) {
        clearAfterSuccessfulPurchase();
        AlertHelper.show({ title: 'Compra exitosa', icon: 'success' });
        navigate(ROUTES.cuenta.store);
      }
    } catch (e: any) {
      setErrorMessage('La compra no pudo ser completada, por favor verifique su método de pago');
    } finally {
      setIsProcessing(false);
    }
  };

  const completePurchase = () => {
    switch (currentPaymentMethodType) {
      case 'new-card':
        return PaymentServices.purchaseWithNewCreditCard({
          newCardInformation,
          products,
          productsTotal,
          shippingInformation,
          acceptReferral: isReferralBalanceApplicable,
        });
      case 'new-paypal':
        return PaymentServices.purchaseWithNewPaypal({
          newPaypalInformation,
          products,
          productsTotal,
          shippingInformation,
          acceptReferral: isReferralBalanceApplicable,
        });
      case 'card':
        return PaymentServices.purchaseWithCreditCard({
          paymentMethod: currentPaymentMethod!,
          products,
          productsTotal,
          shippingInformation,
          acceptReferral: isReferralBalanceApplicable,
        });
      case 'paypal':
        return PaymentServices.purchaseWithPaypal({
          paymentMethod: currentPaymentMethod!,
          products,
          productsTotal,
          shippingInformation,
          acceptReferral: isReferralBalanceApplicable,
        });
    }
  };

  // if (isLoading) {
  //   return <MegaLoading isLoading={true} />;
  // }

  return (
    <div className="container">
      <div className="mt-6">
        <Steps
          steps={[
            { title: 'Detalles de envío' },
            { title: 'Método de Pago' },
            { title: 'Resumen de compra' },
          ]}
          currentStep={3}
        />
      </div>

      <div className="mt-6">
        <h2 className="mg-page-title mb-4">RESUMEN DE COMPRA</h2>
      </div>
      <div className="mt-5">
        <Row>
          <Col md={4} className="mb-4">
            <ShippingInformationSummaryCard />
          </Col>
          <Col md={4} className="mb-4">
            <InvoicingAddressSummaryCard />
          </Col>
          <Col md={4} className="mb-4">
            <PaymentMethodSummaryCard
              currentPaymentMethod={currentPaymentMethod}
              currentPaymentMethodType={currentPaymentMethodType}
              newCardInformation={newCardInformation}
              newPaypalInformation={newPaypalInformation}
            />
          </Col>
        </Row>
      </div>
      <div className="mt-5">
        <h2 className="mg-page-title mb-4">ARTÍCULOS</h2>
        <ShoppingCartProductList allowToModify={false} />
        <hr className="my-6" />
        <Row>
          <Col md={6}>
            <div className="d-flex align-items-center">
              <div className="me-4">
                Acepto los términos y condiciones, he revisado los productos a comprar, la dirección
                y nombre del contacto a enviar y confirmo que este servicio no admite rembolso una
                vez completado el pago.
              </div>
              <FormCheck
                type="switch"
                checked={isTermsAccepted}
                onChange={(e) => {
                  e.stopPropagation();
                  setIsTermsAccepted(!isTermsAccepted);
                }}
              />
            </div>

            {referralBalance > 0 && (
              <div className="mt-4 d-flex align-items-center">
                <div className="me-4">
                  Use su Balance de Referidos. Tiene {Formatter.toCurrency(referralBalance)}{' '}
                  disponibles.
                </div>
                <FormCheck
                  type="switch"
                  checked={isReferralBalanceApplicable}
                  onChange={(e) => {
                    e.stopPropagation();
                    setIsReferralBalanceApplicable(!isReferralBalanceApplicable);
                  }}
                />
              </div>
            )}
          </Col>
          <Col md={6}>
            <ShoppingCartTotals
              // displayDeliveryFee={true}
              referralBalance={isReferralBalanceApplicable ? referralBalance : 0}
            />
          </Col>
        </Row>
      </div>
      {errorMessage && <Alert variant={'danger'}>{errorMessage}</Alert>}
      <CheckoutActions
        onBackClick={() => navigate(ROUTES.cuenta.checkoutPaymentMethods)}
        className="my-5"
        disabled={isProcessing}
        label="Pagar"
        processing={isProcessing}
        onClick={() => {
          if (isTermsAccepted) {
            processPurchase();
          } else {
            AlertHelper.show({
              icon: 'error',
              text: 'Para completar la compra es necesario aceptar los términos y condiciones.',
              title: 'Error',
            });
          }
        }}
      />
    </div>
  );
};
