import * as React from 'react';
import { SVGProps } from 'react';
const SvgReporteRemesas = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" {...props}>
    <g data-name="Capa 1">
      <circle
        cx={16.58}
        cy={9.39}
        r={8.36}
        style={{
          opacity: 0.4,
        }}
      />
      <path
        className="reporte_remesas_svg__cls-1"
        d="M19.19 9.79a2.3 2.3 0 0 0-.58-.66c-.24-.18-.5-.32-.78-.42-.29-.11-.56-.2-.83-.29-.18-.06-.35-.12-.5-.17a3.43 3.43 0 0 1-.38-.18.666.666 0 0 1-.24-.23.578.578 0 0 1-.08-.33c0-.15.04-.28.1-.38s.16-.18.26-.24.22-.1.35-.13c.13-.02.26-.03.38-.03.24 0 .5.06.76.18.12.06.23.12.33.19.2.15.48.12.65-.06l.49-.52c.21-.23.17-.59-.1-.76a4.23 4.23 0 0 0-.74-.36c-.32-.11-.63-.17-.94-.2v-.8c0-.41-.34-.75-.75-.75s-.75.34-.75.75v.89c-.36.09-.68.24-.97.44-.29.2-.52.45-.7.76-.18.31-.26.68-.26 1.11 0 .35.06.65.17.9.11.25.26.46.45.63.19.17.4.31.64.42.24.11.48.21.73.29.26.08.49.16.69.23s.37.15.51.23c.14.08.24.18.31.28.07.1.11.23.11.38s-.03.29-.09.4a.67.67 0 0 1-.24.26.99.99 0 0 1-.35.14c-.13.03-.27.04-.41.04-.31 0-.62-.08-.93-.24-.16-.08-.31-.17-.44-.28a.49.49 0 0 0-.66.03l-.53.54c-.21.21-.18.57.06.74.26.19.55.34.87.46.37.14.74.22 1.13.26v1.14c0 .36.34.66.75.66s.75-.3.75-.66v-1.23c.04 0 .08-.01.12-.03.36-.1.68-.26.96-.47.28-.21.5-.48.67-.81s.25-.72.25-1.16c0-.39-.08-.72-.23-.98ZM9.21 24.8c-.59.48-1.42.48-2 .01-2.77-2.25-4.74-4.6-5.91-6.19-.68-.92-.12-2.15 1.03-2.29.78-.1 1.73-.19 2.82-.27.12-4.71.44-8.44.59-9.54.05-.4.27-.76.62-.94.44-.23 1.14-.5 2-.51.88 0 1.59.26 2.03.49.34.18.54.52.59.9.15 1.08.42 4.82.45 9.57 1.07.06 2.02.14 2.79.23 1.14.13 1.68 1.35.99 2.28a35.29 35.29 0 0 1-6 6.27Z"
      />
    </g>
  </svg>
);
export default SvgReporteRemesas;
