import { Button, Modal } from 'react-bootstrap';
import Lottie from 'react-lottie';
import deleteJson from '../../../assets/animations/delete.json';
import { useGlobalModalContext } from '../../../providers/globalModal.provider';

export const ConfirmDeleteModal = () => {
  const { hideModal, currentModalProps } = useGlobalModalContext();

  const onNo = () => {
    hideModal();
    !!currentModalProps?.onClose && currentModalProps.onClose(false);
  };

  const onYes = () => {
    hideModal();
    !!currentModalProps?.onClose && currentModalProps.onClose(true);
  };

  const defaultOptions1 = {
    loop: true,
    autoplay: true,
    animationData: deleteJson,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice',
    },
  };

  return (
    <Modal
      size="sm"
      show={true}
      onHide={() => onNo()}
      backdrop="static"
      backdropClassName="mega-double-backdrop"
      dialogClassName="mega-double-dialog mega-smaller-lg-dialog"
      centered>
      <Modal.Body>
        <div className="text-center w-100">
          <Lottie options={defaultOptions1} height={50} width={50} />

          <div className="mt-3">
            <b className="text-center">{currentModalProps?.title}</b>
          </div>
          <p className="mega-fs-small mt-4">{currentModalProps?.description}</p>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <div className="text-center w-100">
          <Button variant="mega_danger_2" className="w-auto px-7 me-4" onClick={onNo}>
            No
          </Button>

          <Button variant="mega_danger" className="px-7" onClick={onYes}>
            Si
          </Button>
        </div>
      </Modal.Footer>
    </Modal>
  );
};
