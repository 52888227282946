import * as React from 'react';
import { SVGProps } from 'react';
const SvgDireccionContactanos = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" {...props}>
    <g data-name="Capa 1">
      <path
        d="M25 1.45c-11.1 0-20.09 9-20.09 20.09 0 7.2 3.72 13.26 7.75 17.73 4.05 4.48 8.58 7.54 10.55 8.77 1.1.69 2.47.69 3.58 0 1.97-1.23 6.5-4.28 10.55-8.77 4.04-4.47 7.76-10.53 7.76-17.73 0-11.1-9-20.09-20.09-20.09Zm0 26.22c-4.43 0-8.03-3.6-8.03-8.03 0-4.44 3.59-8.03 8.03-8.03s8.03 3.6 8.03 8.03c0 4.44-3.59 8.03-8.03 8.03Z"
        style={{
          fill: '#8ab934',
          fillRule: 'evenodd',
        }}
      />
      <path
        d="M25 49.46c-.78 0-1.57-.22-2.27-.65-2.29-1.42-6.72-4.47-10.74-8.93C6.69 34.01 4 27.85 4 21.55 4 9.96 13.42.54 25 .54s21 9.42 21 21c0 6.3-2.69 12.47-7.99 18.33-4.02 4.45-8.46 7.5-10.74 8.93-.7.44-1.48.65-2.27.65Zm0-47.1c-10.58 0-19.19 8.6-19.19 19.18 0 5.84 2.53 11.6 7.52 17.12 4.02 4.45 8.58 7.5 10.36 8.6.81.5 1.81.5 2.62 0 1.77-1.11 6.34-4.16 10.36-8.6 4.99-5.52 7.52-11.28 7.52-17.12 0-10.58-8.61-19.19-19.19-19.19Zm0 26.22c-4.93 0-8.94-4.01-8.94-8.94 0-2.39.93-4.63 2.62-6.32A8.872 8.872 0 0 1 25 10.7c4.93 0 8.94 4.01 8.94 8.94 0 2.39-.93 4.63-2.62 6.32A8.872 8.872 0 0 1 25 28.58Zm0-16.06c-1.9 0-3.69.74-5.04 2.09a7.08 7.08 0 0 0-2.09 5.04c0 3.93 3.2 7.13 7.13 7.13 1.9 0 3.69-.74 5.04-2.09a7.08 7.08 0 0 0 2.09-5.04c0-3.93-3.2-7.13-7.13-7.13Z"
        style={{
          fill: '#263645',
        }}
      />
      <path
        data-name="Trazado 190"
        d="M37.54 31.34c-.97-.94-2.42-.22-5.13 2.56-2.71 2.78-3.4 4.25-2.43 5.19s2.42.22 5.13-2.56c2.71-2.78 3.4-4.25 2.43-5.19Z"
        style={{
          fill: '#fff',
        }}
      />
    </g>
  </svg>
);
export default SvgDireccionContactanos;
