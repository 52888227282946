import api from './axios/api';
import { BraintreeAuth, CardVerification, PaymentMethodCard } from '../types/payment';
import { NewCardInformation } from '../types/newCardInformation';
import { ShippingInformation } from '../types/shippingInformation';
import { getCardNonce, getDeviceData } from './nonce';
import { MegaStoreProduct } from '../types/reports/storeProduct';
import { UpdatedCardInformation } from '../types/updatedCardInformation';
import { NewPaypalInformation } from '../types/newPaypalInformation';

const getBraintreeAuth = async (): Promise<BraintreeAuth> => {
  try {
    const response = await api.get<BraintreeAuth>('/api/v1/payment/getbtauthorization');

    if (!!response.data) {
      return response.data;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const getPaymentMethods = async () => {
  try {
    const response = await api.get<PaymentMethodCard>('/api/v1/payment/paymentmethods');

    if (!!response.array) {
      return response.array;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const addCreditCard = async (newCardInformation: NewCardInformation): Promise<CardVerification> => {
  return postPaymentMethod(newCardInformation, true);
};

const validateNewCreditCard = async (
  newCardInformation: NewCardInformation
): Promise<CardVerification> => {
  return postPaymentMethod(newCardInformation, false);
};

const postPaymentMethod = async (
  newCardInformation: NewCardInformation,
  save: boolean
): Promise<CardVerification> => {
  try {
    const nonce = await getCardNonce({
      number: newCardInformation.cardNumber,
      expiration: newCardInformation.cardExpiration,
      cvv: newCardInformation.cardCvc,
    });
    const deviceData = await getDeviceData();
    const response = await api.post(
      '/api/v1/payment/paymentmethods',
      newCreditCardPayload({
        newCardInformation,
        nonce,
        deviceData,
        save,
        additionalData: {
          // address2: 'NA',
        },
      })
    );

    // TODO: Fix this response
    if (!response.has_error) {
      return response.data as unknown as CardVerification;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

type PurchaseWithNewCreditCardParams = {
  productsTotal: number;
  products: MegaStoreProduct[];
  shippingInformation: ShippingInformation;
  newCardInformation?: NewCardInformation;
  acceptReferral: boolean;
};

const purchaseWithNewCreditCard = async ({
  products,
  productsTotal,
  shippingInformation,
  newCardInformation,
  acceptReferral,
}: PurchaseWithNewCreditCardParams) => {
  const url = '/api/v1/payment/store/creditcard';
  const deviceData = await getDeviceData();
  const total_to_pay = productsTotal + shippingInformation.deliveryFee;

  let payload = {
    accept_referral: acceptReferral ? 1 : 0,
    products: JSON.stringify(products.map((product) => {
      return {
        id: product.id,
        quantity: product.shopping_cart_quantity,
      }
    })),
    total_products: productsTotal,
    total_to_pay,
    ...deliveryPayload(shippingInformation),
  };


  if (newCardInformation) {
    const nonce = await getCardNonce({
      number: newCardInformation.cardNumber,
      expiration: newCardInformation.cardExpiration,
      cvv: newCardInformation.cardCvc,
    });

    payload = {
      ...payload,
      ...newCreditCardPayload({ newCardInformation, nonce, deviceData }),
    };
  }

  try {
    const response = await api.post<any>(url, payload);

    if (!response.has_error) {
      return true;
    }

    throw new Error(response.error ?? 'ERROR: No data');
  } catch (error) {
    throw error;
  }
};

type PurchaseWithCreditCardParams = {
  paymentMethod: PaymentMethodCard;
  productsTotal: number;
  products: MegaStoreProduct[];
  shippingInformation: ShippingInformation;
  acceptReferral: boolean;
};

const purchaseWithCreditCard = async ({
  paymentMethod,
  products,
  productsTotal,
  shippingInformation,
  acceptReferral,
}: PurchaseWithCreditCardParams) => {
  const url = '/api/v1/payment/store/creditcard';
  const deviceData = await getDeviceData();
  const total_to_pay = productsTotal + shippingInformation.deliveryFee;

  let payload = {
    ...deliveryPayload(shippingInformation),
    accept_referral: acceptReferral ? 1 : 0,
    device_data: deviceData,
    is_new_method: 0,
    payment_method_id: paymentMethod.id,
    products: JSON.stringify(products.map((product) => {
      return {
        id: product.id,
        quantity: product.shopping_cart_quantity,
      }
    })),
    save_method: 0,
    total_products: productsTotal,
    total_to_pay,
  };

  try {
    const response = await api.post<any>(url, payload);

    if (!response.has_error) {
      return true;
    }

    throw new Error(response.error ?? 'ERROR: No data');
  } catch (error) {
    throw error;
  }
};

type PurchaseWithNewPaypalParams = {
  productsTotal: number;
  newPaypalInformation?: NewPaypalInformation;
  products: MegaStoreProduct[];
  shippingInformation: ShippingInformation;
  acceptReferral: boolean;
};

const purchaseWithNewPaypal = async ({
  newPaypalInformation,
  products,
  productsTotal,
  shippingInformation,
  acceptReferral,
}: PurchaseWithNewPaypalParams) => {
  const url = '/api/v1/payment/store/paypal';
  const deviceData = await getDeviceData();
  const total_to_pay = productsTotal + shippingInformation.deliveryFee;

  let payload = {
    accept_referral: acceptReferral ? 1 : 0,
    products: JSON.stringify(products.map((product) => {
      return {
        id: product.id,
        quantity: product.shopping_cart_quantity,
      }
    })),
    total_products: productsTotal,
    total_to_pay,
    ...deliveryPayload(shippingInformation),
  };

  if (newPaypalInformation) {
    payload = {
      ...payload,
      ...{
        device_data: deviceData,
        nonce: newPaypalInformation.nonce,
        is_new_method: 1,
        save_method: newPaypalInformation.saveMethod ? 1 : 0,
      },
    };
  }

  try {
    const response = await api.post<any>(url, payload);

    if (!response.has_error) {
      return true;
    }

    throw new Error(response.error ?? 'ERROR: No data');
  } catch (error) {
    throw error;
  }
};

type PurchaseWithPaypalParams = {
  paymentMethod: PaymentMethodCard;
  productsTotal: number;
  products: MegaStoreProduct[];
  shippingInformation: ShippingInformation;
  acceptReferral: boolean;
};

const purchaseWithPaypal = async ({
  paymentMethod,
  products,
  productsTotal,
  shippingInformation,
  acceptReferral,
}: PurchaseWithPaypalParams) => {
  const url = '/api/v1/payment/store/paypal';
  const deviceData = await getDeviceData();
  const total_to_pay = productsTotal + shippingInformation.deliveryFee;

  let payload = {
    accept_referral: acceptReferral ? 1 : 0,
    products: JSON.stringify(products.map((product) => {
      return {
        id: product.id,
        quantity: product.shopping_cart_quantity,
      }
    })),
    total_products: productsTotal,
    total_to_pay,
    ...deliveryPayload(shippingInformation),
    device_data: deviceData,
    payment_method_id: paymentMethod.id,
    is_new_method: 0,
    save_method: 0,
  };

  try {
    const response = await api.post<any>(url, payload);

    if (!response.has_error) {
      return true;
    }

    throw new Error(response.error ?? 'ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const deliveryPayload = (shippingInformation: ShippingInformation) => {
  return {
    delivery_id: shippingInformation.delivery?.id,
    delivery_first_name: shippingInformation.firstName,
    delivery_last_name: [shippingInformation.lastName, shippingInformation.secondLastName]
      .map((value) => value.trim())
      .filter((value) => value !== '')
      .join(' '),
    // delivery_second_last_name: ,
    delivery_email: shippingInformation.email,
    delivery_address: shippingInformation.address,
    // delivery_city: ,
    // delivery_state,
    // delivery_zip,
    delivery_phone: shippingInformation.phoneNumber,
    delivery_province: shippingInformation.provinceId,
    delivery_municipe: shippingInformation.municipalityId,
    delivery_country: 'CU',
    total_shipping: shippingInformation.deliveryFee,
  };
};

const newCreditCardPayload = ({
  deviceData,
  newCardInformation,
  nonce,
  additionalData = {},
  save = true,
}: {
  deviceData: string;
  newCardInformation: NewCardInformation;
  nonce: string;
  additionalData?: object;
  save?: boolean;
}) => {
  return {
    is_new_method: 1,
    is_paypal: 0,
    nonce: nonce,
    device_data: deviceData,
    title: newCardInformation.title,
    cc_number: newCardInformation.cardNumber.replace(/\D/g, ''),
    cvc: newCardInformation.cardCvc,
    exp_date: newCardInformation.cardExpiration.replace(/\D/g, ''),
    first_name: newCardInformation.first_name,
    last_name: newCardInformation.last_name,
    address1: newCardInformation.address1,
    // address2: ,
    city: newCardInformation.city,
    state: newCardInformation.state,
    zip: newCardInformation.zip,
    country: newCardInformation.country,
    save_method: save && newCardInformation.saveMethod ? 1 : 0,
    ...additionalData,
  };
};

const deleteExistingCard = async (cardId: number | string): Promise<boolean> => {
  const url = `/api/v1/payment/paymentmethods/${cardId}`;

  try {
    const response = await api.delete<any>(url);

    if (response.has_error === false) {
      return response.has_error === false;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const updateExistingCard = async (
  cardId: number | string,
  updatedCardInformation: UpdatedCardInformation
): Promise<boolean> => {
  const url = `/api/v1/payment/paymentmethods/${cardId}`;

  try {
    const response = await api.put<any>(url, {
      //   is_new_method: 1,
      // nonce: nonce,
      // device_data: deviceData,
      // title: newCardInformation.title,
      // cc_number: newCardInformation.cardNumber.replace(/\D/g, ''),
      cvc: updatedCardInformation.cardCvc,
      exp_date: updatedCardInformation.cardExpiration.replace(/\D/g, ''),
      first_name: updatedCardInformation.first_name,
      last_name: updatedCardInformation.last_name,
      address1: updatedCardInformation.address1,
      // address2: ,
      city: updatedCardInformation.city,
      state: updatedCardInformation.state,
      zip: updatedCardInformation.zip,
      country: updatedCardInformation.country,
    });

    if (response.has_error === false) {
      return response.has_error === false;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

export const PaymentServices = {
  addCreditCard,
  deleteExistingCard,
  getBraintreeAuth,
  getPaymentMethods,
  purchaseWithCreditCard,
  purchaseWithNewCreditCard,
  purchaseWithNewPaypal,
  purchaseWithPaypal,
  updateExistingCard,
  validateNewCreditCard,
};
