import { compact, uniqBy } from 'lodash';
import React, { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { MegaStoreProduct } from '../../../types/reports/storeProduct';
import { TabsWithTitle } from '../tabsWithTitle';
import { CheckoutAddressContact } from './checkoutAddressContact';
import { CheckoutAddressPerson } from './checkoutAddressPerson';

type CheckoutAddressWrapperProps = {
  products: MegaStoreProduct[];
};

const CheckoutAddressWrapperComponent = (props: CheckoutAddressWrapperProps, ref: any) => {
  const formPersonRef = useRef<any>();
  const formContactRef = useRef<any>();

  const [tabList, setTabList] = useState<{ key: string; title: string }[]>([
    { key: 'other', title: 'Otra Persona' },
    { key: 'contact', title: 'De Mis Contactos' },
  ]);

  const [step, setStep] = useState<'contact' | 'other'>('other');
  const [acceptedProvinces, setAcceptedProvinces] = useState<number[]>([]);
  const [acceptedMunicipalities, setAcceptedMunicipalities] = useState<number[]>([]);

  useEffect(() => {
    let pFullList: number[] = [];
    let mFullList: number[] = [];

    props.products.forEach((product) => {
      product.deliveries.forEach((delivery) => {
        const pList = delivery.accept_provinces.split(',').map(Number);
        const mList = delivery.accept_municipes.split(',').map(Number);

        pFullList = uniqBy<number>([...pFullList, ...pList], Number);
        mFullList = uniqBy<number>([...mFullList, ...mList], Number);
      });
    });

    setAcceptedProvinces(compact(pFullList));
    setAcceptedMunicipalities(compact(mFullList));
  }, [props.products]);

  const getCurrentRef = () => {
    return step === 'other' ? formPersonRef?.current : formContactRef?.current;
  };

  useImperativeHandle(ref, () => ({
    isFormValid() {
      return getCurrentRef()?.isFormValid();
    },
    getFormValues() {
      return {
        ...getCurrentRef()?.getFormValues(),
        step,
      };
    },
  }));

  return (
    <div>
      <TabsWithTitle
        title="Destinatario"
        tabs={tabList}
        activeTab={step}
        wrapperClassName="mb-5"
        onTabChange={(key: string) => setStep(key as 'other' | 'contact')}
      />

      {step === 'other' && acceptedProvinces.length > 0 && (
        <CheckoutAddressPerson
          acceptedProvinces={acceptedProvinces}
          acceptedMunicipalities={acceptedMunicipalities}
          ref={formPersonRef}
        />
      )}
      {step === 'contact' && acceptedProvinces.length > 0 && (
        <CheckoutAddressContact
          acceptedProvinces={acceptedProvinces}
          acceptedMunicipalities={acceptedMunicipalities}
          ref={formContactRef}
        />
      )}
    </div>
  );
};

export const CheckoutAddressWrapper = React.forwardRef(CheckoutAddressWrapperComponent);
