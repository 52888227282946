import { createRef, useEffect, useRef, useState } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { IMaskInput } from 'react-imask';
import { useQuery } from 'react-query';
import { AuthService, CountryServices, UtilService } from '../../../../services';
import { GenericResponse, LoginResponse } from '../../../../types/common';
import { QueryTypes } from '../../../../types/queryTypes';
import { MegaError } from '../../../account/megaError';
import { MegaLoading } from '../../megaLoading';
import { VerifyOtp } from '../verifyOtp/verifyOtp';

const captchaRef = createRef<ReCAPTCHA>();

type LoginOtpProps = {
  onSuccess: (response: LoginResponse) => void;
};

export const LoginOtp = (props: LoginOtpProps) => {
  const phoneRef = useRef<any>(null);

  const [currentStep, setCurrentStep] = useState<'request' | 'validate'>('request');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneHasError, setPhoneHasError] = useState(false);
  const [failedCounter, setFailedCounter] = useState(0);
  const [showCaptcha, setShowCaptcha] = useState(false);
  const [captchaHasError, setCaptchaHasError] = useState(false);  

  const countryQuery = useQuery(
    QueryTypes.GetAuthCountries,
    CountryServices.getAllAuthorizedCountries
  );

  useEffect(() => {
    setShowCaptcha(failedCounter>2);
  }, [failedCounter]);  

  if (UtilService.isLoadingQueries([countryQuery])) {
    return <MegaLoading isLoading={true} />;
  }

  if (UtilService.isErrorQueries([countryQuery])) {
    return <MegaError displayRetry retry={() => countryQuery.refetch()} />;
  }

  const onCaptchaChange = (token: string | null) => {
    if (token) {
      setCaptchaHasError(false);
    }
  };

  const onNext = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    await processLogin();
  };

  const processLogin = async () => {
    let hasAnyError = false;  
    let token ='';
    if (showCaptcha){
      token = captchaRef.current?.getValue() || '';      
      if (!token) {
        setCaptchaHasError(true);
        return;
      }    
    }    

    if (hasAnyError) {
      return;
    }

    // success
    setIsSubmitting(true);
    setErrorMessage('');

    const response: GenericResponse = await AuthService.requestOtpLogin(
      phone,           
      token
    );

    setIsSubmitting(false);
    if (response.success) {
      setCurrentStep('validate');
    } else {
      setPhoneHasError(true);
      setFailedCounter(failedCounter+1);
      setErrorMessage(response.error || 'Su Teléfono no es válido.');
    }
  };

  return (
    <div className="row">
      {currentStep === 'request' && (
        <form noValidate onSubmit={(e) => onNext(e)}>
          <p className="text-center mb-5">
            Introduzca su teléfono principal para acceder a tu cuenta
          </p>

          {errorMessage && !isSubmitting && (
            <div className="mb-3">
              <Alert variant={'danger'}>{errorMessage}</Alert>
            </div>
          )}

          <div className="col-12">          
            <div className="mt-3">
              <div className="megaInput">
                <label htmlFor="tel">Teléfono</label>
                <IMaskInput
                  id="tel"
                  mask={Number}
                  unmask={true}
                  ref={phoneRef}
                  value={phone}
                  onAccept={(value, _) => setPhone(`${value}`)}
                  className={`form-control mega ${phoneHasError ? 'border border-danger' : ''}`}
                />
              </div>
              {phoneHasError && <div className="text-danger">El teléfono es requerido</div>}
            </div>
            
            {showCaptcha &&  ( 
              <div className="mt-4 w-100 text-center">
                <ReCAPTCHA
                  ref={captchaRef}
                  sitekey={process.env.REACT_APP_CAPTCHA_V2_KEY || ''}
                  onChange={onCaptchaChange}
                  hl={'es'}
                  style={{ display: 'inline-block' }}
                />
                {captchaHasError && (
                  <div className="text-danger text-start">La validación es requerida</div>
                )}
              </div>
            )}

            <Button variant="secondary" type="submit" className="w-100 mt-5 py-2">
              {isSubmitting ? (
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  variant="light"
                  aria-hidden="true"
                />
              ) : (
                <span>Enviar SMS</span>
              )}
            </Button>
          </div>
        </form>
      )}

      {currentStep === 'validate' && (
        <VerifyOtp
          countryCode=""
          phone={phone!}
          submitTime={new Date()}
          onSuccess={(response) => {
            props.onSuccess(response);
          }}
          goBack={() => {
            setCurrentStep('request');
          }}
          onResend={(token: string) => {
            processLogin();
          }}
        />
      )}
    </div>
  );
};
