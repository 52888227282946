import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect, useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { FieldValues, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { useTopUp } from '../../../../context/TopupContext';
import { UtilService } from '../../../../services';
import { TopUpNautaItem } from '../../../../types/topupItems';
import { MegaCarrier, MegaCarrierRate } from '../../../../types/topups';
import { TopupProducts, TopupProductsProps } from '../../topupProducts';
import { TopUpAddButton } from '../topupAddButton';
import { AlertHelper } from '../../../../utilities/AlertHelper';
import { FaExclamationTriangle } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { QueryTypes } from '../../../../types/queryTypes';
import { ValidationServices } from '../../../../services/validation';

const NAUTA_SUFFIX = '@nauta.com.cu';

export const TopUpNautaEmail = ({
  carrier,
  carrierRates,
}: {
  carrier: MegaCarrier;
  carrierRates: MegaCarrierRate[];
}) => {
  const [nauta, setNauta] = useState<string>();
  const [selectedCarrierRate, setSelectedCarrierRate] = useState<MegaCarrierRate>();
  const [productList, setProductList] = useState<TopupProductsProps['products']>([]);
  const [warningMessage, setWarningMessage] = useState<string>(''); 
  const { addNautaItem } = useTopUp();

  useEffect(() => {
    if (carrierRates.length > 0) {
      setSelectedCarrierRate(carrierRates[0]);
      convertProductList(carrierRates);
    }
  }, [carrierRates]); 

  const convertProductList = (list: MegaCarrierRate[]) => {
    const newProductList: TopupProductsProps['products'] = UtilService.convertProductList(list);
    setProductList(newProductList);
  };

  const onSelectedProduct = (id: string) => {
 
  };

  
  const isNautaComplete = () => {
    return !!nauta && nauta.length >= 3 ;
  };

  const blacklistedPhoneQuery = useQuery([QueryTypes.GetBlacklistedPhone, nauta], () => {
    if (isNautaComplete()) {
      return ValidationServices.checkBlacklistedPhone(nauta! + '@nauta.com.cu');
    } else {
      return false;
    }
  });  

  const checkPreviousTopupQuery = useQuery([QueryTypes.GetPreviousTopup, nauta], () => {
    if (isNautaComplete()) {
      return ValidationServices.checkPreviousTopup(nauta! + '@nauta.com.cu');
    } else {
      return false;
    }
  });

  useEffect(() => {
    if (blacklistedPhoneQuery.status === 'success') {      
      if (blacklistedPhoneQuery.data) {
        setWarningMessage('No es posible recargar esta cuenta. Por favor comunícate con soporte técnico para más información.');
      }
    }
  }, [blacklistedPhoneQuery.data]);

  useEffect(() => {
    if (checkPreviousTopupQuery.status === 'success') {                 
      if (checkPreviousTopupQuery.data) {
        setWarningMessage('No es posible recargar sta cuenta debido a que se recargó en los últimos 5 minutos.');
      }
    }
  }, [checkPreviousTopupQuery.data]);

  const onAddTopup = (id: string) => {  

    const rate = carrierRates.find((r) => `${r.id}` === `${id}`);
    if (rate) {
      try {         
        addNautaItem({
          carrier: carrier,
          carrierRate: rate,
          nauta: `${nauta}${NAUTA_SUFFIX}`,
          type: 'other',
        } as TopUpNautaItem);  
        setNauta('');
        setWarningMessage('');     
      } catch (e: any) {
        //AlertHelper.show({ icon: 'error', title: 'Error', text: e.toString() });
        setWarningMessage(e.toString());
        window.scrollTo(0, 0);
      }
    }  
  };   

  return (
    <div>
      <form noValidate>
        <div>
          <div className="megaInput mt-5">
            <label htmlFor="nauta">Nauta</label>
            <div className="input-group">
              <input
                id="nauta"
                type="text"
                value={nauta ?? ''}                
                className={"form-control mega " + (warningMessage!='' ? 'border border-danger' : '')}
                onChange={(event) => {                  
                  setNauta(event.target.value);
                }}
                autoComplete="off"
              />
              <span className={"bg-mega_gray_light input-group-text mega " + (warningMessage!='' ? 'border border-danger' : '')}>{NAUTA_SUFFIX}</span>
            </div>
          </div>          
        </div>

        {warningMessage && (
            <Alert variant={'warning'} className="mt-2">
              <FaExclamationTriangle /> {warningMessage}
            </Alert>
        )}

        <Row className="mt-3">
          <Col>
            <hr />
          </Col>
        </Row>

        <Row>
          <Col>
            {!!productList && productList.length > 0 && !!selectedCarrierRate && (
              <TopupProducts
                serviceType="nauta"
                selectedId={selectedCarrierRate.id}
                products={productList}
                onSelectedChange={onSelectedProduct}
                onAddTopup={onAddTopup}
              />
            )}
          </Col>
        </Row>
       
      </form>
    </div>
  );
};
