import { AppDownloadButton } from '../appDownloadButton';
import styles from './downloadAppCommon.module.scss';

export type DownloadAppCommonProps = {
  bgColor: string;
  appStoreClassName: string;
};

export const DownloadAppCommon = (props: DownloadAppCommonProps) => {
  return (
    <div className={styles.wrapper} style={{ backgroundColor: props.bgColor }}>
      <div className="container">
        <div className="row">
          <div className="col-12 d-flex flex-column flex-lg-row justify-content-center">
            <div className="d-flex flex-column justify-content-center">
              <h4 className="text-center text-lg-start">
                ¡Instala nuestra App y envía SMS Gratis!
              </h4>
              <p className="fs-mg-20 m-0 text-center text-lg-start">
                Instala nuestra aplicación para llamar, recargar celulares y enviar SMS a Cuba.
              </p>
            </div>
            <div className="ms-6 d-none d-lg-block">
              <AppDownloadButton isAndroid={false} className={props.appStoreClassName} />
              <br />
              <AppDownloadButton isAndroid={true} className={props.appStoreClassName} />
            </div>

            <div className="mt-4 d-flex d-lg-none flex-row justify-content-center">
              <div className="d-inline me-4">
                <AppDownloadButton isAndroid={false} className={props.appStoreClassName} />
              </div>
              <div className="d-inline">
                <AppDownloadButton isAndroid={true} className={props.appStoreClassName} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
