import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { GridColDef } from '@mui/x-data-grid';
import { QueryTypes } from '../../../types/queryTypes';
import { MegaSmsReportItem } from '../../../types/reports/smsReportItem';
import { baseColumnDef, currencyColumnDef } from './baseColumnDef';

import { ReportDataGrid } from './reportDataGrid';
import { ReportDateAndNumberSearch } from './reportDateAndNumberSearch';

import { ReportsConfig } from '../../../config/reportsConfig';
import { UtilService } from '../../../services';
import { ReportsService } from '../../../services/reports';
import { MegaError } from '../megaError';

export const SmsReport: React.FC<{}> = ({ children }) => {
  const [reportItems, setReportItems] = useState<MegaSmsReportItem[]>([]);
  const [startDate, setStartDate] = useState<Date>(ReportsConfig.defaultStartDate);
  const [endDate, setEndDate] = useState<Date>(ReportsConfig.defaultEndDate);
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [isSearching, setIsSearching] = useState<boolean>(false);

  const smsReportQuery = useQuery(
    [QueryTypes.GetTopUpReport, startDate, endDate, phoneNumber],
    () => {
      return ReportsService.getSmsReport({ startDate, endDate, phoneNumber });
    },
    {
      cacheTime: 0,
    }
  );

  useEffect(() => {
    setIsSearching(smsReportQuery.isFetching);
  }, [smsReportQuery.isFetching]);

  useEffect(() => {
    if (smsReportQuery.status === 'success') {
      setReportItems(smsReportQuery.data);
    }
  }, [smsReportQuery.data]);

  const columns: GridColDef[] = [
    {
      ...baseColumnDef,
      field: 'created',
      headerName: 'Fecha',
      sortable: true,
      width: 180,
    },
    {
      ...baseColumnDef,
      field: 'destination',
      headerName: 'Destinatario',
      width: 140,
    },
    {
      ...baseColumnDef,
      field: 'message',
      flex: 1,
      headerName: 'Mensaje',
      minWidth: 200,
    },
    {
      ...currencyColumnDef,
      field: 'cost',
      headerName: 'Costo',
      width: 100,
    },
  ];

  const onSearch = (startDate: Date, endDate: Date, phone: string) => {
    setStartDate(startDate);
    setEndDate(endDate);
    setPhoneNumber(phone);

    smsReportQuery.refetch({});
  };

  return (
    <>
      <div className="mt-5">
        <ReportDateAndNumberSearch
          startDate={startDate}
          endDate={endDate}
          isSearching={isSearching}
          phoneNumber={phoneNumber}
          onSearch={onSearch}
        />
      </div>

      {children}

      <div className="mt-3 mb-5">
        {UtilService.isErrorQueries([smsReportQuery]) ? (
          <MegaError
            displayRetry={true}
            retry={() => {
              UtilService.refetchQueries([smsReportQuery]);
            }}
          />
        ) : (
          <ReportDataGrid<MegaSmsReportItem>
            columns={columns}
            getRowId={(row: MegaSmsReportItem) => row.created}
            rows={reportItems}
            isSearching={isSearching}
          />
        )}
      </div>
    </>
  );
};
