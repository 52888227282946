import { Alert, Button } from 'react-bootstrap';
import { useTopUp } from '../../../../context/TopupContext';
import { SmallSpinner } from '../../../external/smallSpinner/smallSpinner';

export const TopUpAddButton = ({
  disabled,
  isProcessing,
  onClick,
  isSubmit,
}: {
  disabled: boolean | undefined;
  isProcessing: boolean | undefined;
  onClick?: () => void;
  isSubmit?: boolean;
}) => {
  const { isCartFull } = useTopUp();

  if (isCartFull) {
    return <Alert>No puedes agregar más de 5 productos al carrito.</Alert>;
  }

  return (
    <div className="mt-3">
      <Button
        variant="outline-secondary"
        className="w-100 py-2 align-bottom"
        type={isSubmit ? 'submit' : 'button'}
        onClick={() => !!onClick && onClick()}
        disabled={disabled || isProcessing}>
        {isProcessing ? <SmallSpinner /> : 'Adicionar al listado'}
      </Button>
    </div>
  );
};
