import * as React from 'react';
import { SVGProps } from 'react';
const SvgAgregarSaldo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="agregar_saldo_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 60 60"
    {...props}>
    <defs>
      <style>{'.agregar_saldo_svg__cls-3{fill:#263645}'}</style>
    </defs>
    <g id="agregar_saldo_svg__Capa_1-2" data-name="Capa 1">
      <path
        id="agregar_saldo_svg__Trazado_233"
        data-name="Trazado 233"
        d="M18.19 9.96C34.76-.19 35.79-.07 43.7 12.85s7.54 13.89-9.03 24.03c-16.58 10.14-17.6 10.03-25.51-2.89S1.61 20.1 18.19 9.96Z"
        style={{
          fill: '#8ab934',
        }}
      />
      <path
        id="agregar_saldo_svg__Trazado_233-2"
        data-name="Trazado 233"
        d="M28.25 15.85c24.94 0 25.99.85 25.99 21.04s-1.05 21.04-25.99 21.04-25.99-.85-25.99-21.04S3.3 15.85 28.25 15.85Z"
        style={{
          fill: '#d0e3ae',
        }}
      />
      <path
        id="agregar_saldo_svg__Trazado_233-3"
        data-name="Trazado 233"
        d="M45.07 29.26c12.64 0 13.17.31 13.17 7.63s-.53 7.63-13.17 7.63-13.17-.31-13.17-7.63.53-7.63 13.17-7.63Z"
        style={{
          fill: '#fff',
        }}
      />
      <path
        className="agregar_saldo_svg__cls-3"
        d="M59.33 36.89c0-5.24-.63-7.34-4.19-8.17-.5-7.87-2.28-11.32-8.36-12.82-.64-1.11-1.36-2.31-2.16-3.61-3.96-6.48-6.4-10.17-10.31-10.61-3.77-.43-8.55 2.37-16.7 7.36-6.22 3.8-10.4 6.45-12.63 9.06h.03c-3.47 2.95-3.84 8.47-3.84 18.8 0 20.89 1.52 22.13 27.08 22.13 21.71 0 26.07-.9 26.9-13.96 3.56-.83 4.19-2.93 4.19-8.17ZM18.75 10.88c8.23-5.04 12.43-7.38 15.32-7.05 2.76.31 4.77 3.16 8.7 9.58.42.69.82 1.34 1.18 1.96-3.82-.52-8.91-.61-15.71-.61-7.49 0-12.91.11-16.83.78 1.93-1.32 4.35-2.83 7.34-4.66Zm31.16 43.26c-3.01 2.44-9.21 2.71-21.67 2.71s-18.65-.27-21.67-2.71c-2.93-2.37-3.24-7.08-3.24-17.25s.31-14.88 3.24-17.25c3.01-2.44 9.21-2.71 21.67-2.71s18.65.27 21.67 2.71c1.95 1.58 2.74 4.19 3.05 8.75-1.98-.19-4.54-.21-7.89-.21-12.65 0-14.26.32-14.26 8.72s1.62 8.72 14.26 8.72c3.34 0 5.91-.02 7.89-.21-.31 4.55-1.1 7.17-3.05 8.75Zm-4.84-10.71q-12.09 0-12.09-6.54t12.09-6.54 12.09 6.54-12.09 6.54Z"
      />
      <path
        className="agregar_saldo_svg__cls-3"
        d="M41.48 34.54c-3.28 0-4.28.59-4.28 2.54s1 2.54 4.28 2.54 4.28-.59 4.28-2.54-1-2.54-4.28-2.54Z"
      />
    </g>
  </svg>
);
export default SvgAgregarSaldo;
