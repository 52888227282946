import styles from './pageTitle.module.scss';

export type PageTitleProps = {
  title: string;
  description: string;
  icon: any;
};

export const PageTitle: React.FC<PageTitleProps> = ({ icon, title, description, children }) => {
  const props = { icon };

  return (
    <div>
      <div className="row mb-5 d-none d-lg-flex">
        <div className="col-8">
          <div className="d-flex flex-row mb-lg-0">
            <div className="d-inline">
              <props.icon width={60} />
            </div>
            <div className="flex-column ms-2">
              <h5 className="m-0 p-0">{title}</h5>
              <div className="page-description">{description}</div>
            </div>
          </div>
        </div>
        <div className="col-4">{children}</div>
      </div>

      <div className="row mb-5 d-block d-lg-none">
        <div className="col-12">
          <div className="d-flex flex-column mb-lg-0">
            <div className="d-flex align-items-end">
              <props.icon width={45} className="d-inline" />
              <h5 className={`mb-0 ms-2 p-0 d-inline ${styles.title}`}>{title}</h5>
            </div>
            <div className="mt-2">
              <div className="page-description">{description}</div>
            </div>
          </div>
        </div>
        <div className="col-12">{children}</div>
      </div>
    </div>
  );
};
