import { ResponseSmsHistory, SmsCountryRate, SmsHistoryRecord } from '../types/sms';
import api from './axios/api';

const getCountryRates = async () => {
  try {
    const response = await api.get<SmsCountryRate>('/api/v1/rates/sms');

    if (!!response.array) {
      return response.array;
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const getSmsHistory = async (
  phone: string,
  take: number,
  skip: number
): Promise<ResponseSmsHistory> => {
  try {
    const response = await api.get<SmsHistoryRecord>('/api/v1/sms/history', {
      params: {
        phone,
        take,
        skip,
      },
    });

    if (!!response.array) {
      return {
        history: response.array,
        total: response.total ?? response.array.length ?? 0,
      };
    }

    throw new Error('ERROR: No data');
  } catch (error) {
    throw error;
  }
};

const sendSms = async (phone: string, content: string, country: string): Promise<any> => {
  try {
    const response = await api.post('/api/v1/sms', {
      phone,
      content,
      country,
    });

    if (response.has_error) {
      throw response;
    }

    return true;
  } catch (error) {
    throw error;
  }
};

export const SmsService = {
  getCountryRates,
  getSmsHistory,
  sendSms,
};
