import * as yup from 'yup';
import { errorMessages } from '../../../../utilities/errorMessages';

export const referralBalanceWithdrawalFormSchema = yup
  .object()
  .shape(
    {
      first_name: yup.string().required(errorMessages.firstName),
      last_name: yup.string().required(errorMessages.lastName),
      email: yup.string().when('phone', {
        is: (phone: string) => !phone || phone.length === 0,
        then: yup.string().email(errorMessages.email.format).required(errorMessages.email.required),
      }),
      phone: yup.string().when('email', {
        is: (email: string) => !email || email.length === 0,
        then: yup.string().required(errorMessages.phoneNumber),
      }),
    },
    [['email', 'phone']]
  )
  .required();
