import * as React from 'react';
import { SVGProps } from 'react';
const SvgReporteRecargas = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" {...props}>
    <g data-name="Capa 1">
      <path
        d="m14.46 17.83.41-.41a.37.37 0 0 1 .41-.08c.03-.44.05-.89.07-1.35-.14-.08-.28-.16-.39-.27-.14-.13-.26-.3-.35-.49-.09-.19-.13-.42-.13-.69 0-.34.07-.62.2-.86.14-.24.32-.43.54-.59.07-.05.15-.09.23-.13 0-.56.02-1.12.02-1.69 0-2.71-.11-5.33-.31-7.81A2.735 2.735 0 0 0 12.8.97C11.18.75 9.49.63 7.74.63S4.29.75 2.68.97A2.735 2.735 0 0 0 .31 3.46C.11 5.94 0 8.56 0 11.27s.11 5.33.31 7.81c.1 1.28 1.09 2.32 2.36 2.49 1.62.22 3.31.34 5.06.34s3.45-.12 5.06-.34a2.735 2.735 0 0 0 2.36-2.49c0-.11.01-.22.02-.32-.25-.09-.47-.21-.67-.36a.377.377 0 0 1-.05-.57Z"
        style={{
          opacity: 0.4,
        }}
      />
      <path
        className="reporte_recargas_svg__cls-1"
        d="M7.73.63c-1.39 0-2.75.09-4.06.26.02.29.03.58.05.87.06.82.59 1.48 1.27 1.59.87.14 1.79.22 2.73.22s1.86-.08 2.73-.22c.69-.11 1.22-.77 1.27-1.59.02-.29.04-.58.05-.87C10.46.72 9.1.63 7.71.63ZM5.94 18.95c0-.81.42-1.06 1.79-1.06s1.79.25 1.79 1.06-.42 1.06-1.79 1.06-1.79-.25-1.79-1.06ZM16.55 9.47c-3.56 0-6.45 2.89-6.45 6.45s2.89 6.45 6.45 6.45S23 19.48 23 15.92s-2.89-6.45-6.45-6.45Zm2.01 8.41c-.13.25-.3.46-.52.63-.22.16-.46.29-.74.37-.11.03-.22.05-.33.07v.79c0 .39-.5.67-.9.22v-.98c-.3-.03-.6-.1-.88-.21-.25-.09-.48-.21-.68-.36a.377.377 0 0 1-.05-.57l.41-.41c.14-.14.36-.15.51-.03.1.08.21.15.34.21a1.598 1.598 0 0 0 1.04.15c.1-.02.19-.06.27-.11.08-.05.14-.12.18-.2s.07-.19.07-.31-.03-.21-.09-.29a1.03 1.03 0 0 0-.24-.22c-.11-.07-.24-.12-.39-.18s-.33-.11-.53-.17c-.19-.06-.38-.14-.57-.22-.18-.09-.35-.19-.49-.33-.14-.13-.26-.3-.35-.49-.09-.19-.13-.42-.13-.69 0-.34.07-.62.2-.86.14-.24.32-.43.54-.59.22-.15.47-.27.75-.34.03 0 .07-.01.1-.02v-.63c0-.39.5-.67.9-.22v.77c.29.02.59.07.89.17.2.07.39.16.57.27.2.13.24.41.07.58l-.38.4c-.13.14-.35.16-.5.05a1.445 1.445 0 0 0-.83-.29c-.1 0-.19 0-.29.03-.1.02-.19.05-.27.1-.08.05-.15.11-.2.19-.05.08-.08.17-.08.29 0 .1.02.19.06.26.04.07.11.13.19.18.08.05.18.1.29.14.11.04.24.09.38.13.2.07.42.14.64.23.22.08.42.19.6.33s.33.31.45.51.18.45.18.75c0 .35-.06.65-.19.9Z"
      />
    </g>
  </svg>
);
export default SvgReporteRecargas;
