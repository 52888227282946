import { yupResolver } from '@hookform/resolvers/yup';
import { createRef, useEffect, useState } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import * as yup from 'yup';
import { ROUTES } from '../../../../config/routes';
import { MODAL_TYPES, useGlobalModalContext } from '../../../../providers/globalModal.provider';
import { AuthService } from '../../../../services';
import { LoginResponse } from '../../../../types/common';

const loginSchema = yup
  .object()
  .shape({
    phone: yup.string().required(),
    password: yup.string().required(),
  })
  .required();

interface LoginFormData extends yup.TypeOf<typeof loginSchema> {}

type LoginPasswordProps = {
  onSuccess: (response: LoginResponse) => void;
};

const captchaRef = createRef<ReCAPTCHA>();

export const LoginPassword = (props: LoginPasswordProps) => {
  const { showModal } = useGlobalModalContext();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [captchaHasError, setCaptchaHasError] = useState(false);
  const [failedCounter, setFailedCounter] = useState(0);
  const [showCaptcha, setShowCaptcha] = useState(false);

  useEffect(() => {
    setShowCaptcha(failedCounter>2);
  }, [failedCounter]);  

  const { register, handleSubmit, formState } = useForm({
    resolver: yupResolver(loginSchema),
    mode: 'onChange',
  });
  const { isValid: isValidForm } = formState;

  const onSubmit = async (data: LoginFormData) => {
    const validationMessage = 'Hubo un error inesperado. For favor, intente de nuevo.';
    if (!isValidForm) {
      setErrorMessage(validationMessage);
      return;
    }

    let token ='';
    if (showCaptcha){
      token = captchaRef.current?.getValue() || '';      
      if (!token) {
        setCaptchaHasError(true);
        return;
      }    
    }   

    setErrorMessage('');
    setIsSubmitting(true);

    const response = await AuthService.login(data.phone!, data.password!, token);

    if (!response.success) {
      captchaRef.current?.reset();
      setIsSubmitting(false);
      setFailedCounter(failedCounter+1);

      if (response.isAccountDisabled) {
        showModal(MODAL_TYPES.ERROR, {
          description: response.error,
          // onClose: props.goBack,
        });
      } else {
        setErrorMessage(!!response.error ? response.error : validationMessage);
      }

      return;
    }

    // no token? then error
    if (!!response.token) {
      setIsSubmitting(false);
      props.onSuccess(response);
    } else {
      captchaRef.current?.reset();
      setIsSubmitting(false);
      setErrorMessage(validationMessage);
      return;
    }
  };

  const onCaptchaChange = (token: string | null) => {
    if (token) {
      setCaptchaHasError(false);
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate>
      <p className="text-center mb-5">
        Introduzca su teléfono principal y su contraseña para acceder a su cuenta.
      </p>

      {errorMessage && !isSubmitting && (
        <div className="mb-5">
          <Alert variant={'danger'}>{errorMessage}</Alert>
        </div>
      )}

      <div className="mb-3">
        <div className="megaInput">
          <label htmlFor="phone">Teléfono principal</label>
          <input
            type="phone"
            className={`form-control mega ${formState.errors.phone ? 'border border-danger' : ''}`}
            id="phone"
            {...register('phone')}
          />
        </div>

        {formState.errors.phone && <div className="text-danger">El teléfono es requerido</div>}
      </div>

      <div className="mb-4 mt-4">
        <div className="megaInput">
          <label htmlFor="password">Contraseña</label>
          <input
            type="password"
            className={`form-control mega  ${
              formState.errors.password ? 'border border-danger' : ''
            }`}
            id="password"
            {...register('password')}
          />
        </div>
        {formState.errors.password && <div className="text-danger">La contraseña es requerida</div>}
      </div>
      {showCaptcha &&  ( 
        <div className="mb-4 text-center">
          <ReCAPTCHA
            ref={captchaRef}
            sitekey={process.env.REACT_APP_CAPTCHA_V2_KEY || ''}
            onChange={onCaptchaChange}
            hl={'es'}
            style={{ display: 'inline-block' }}
          />
          {captchaHasError && (
            <div className="text-danger text-start">La validación es requerida</div>
          )}
        </div>
       )}

      <button className="btn btn-secondary w-100" type="submit" disabled={isSubmitting}>
        {isSubmitting ? (
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            variant="light"
            aria-hidden="true"
          />
        ) : (
          <span>Acceder</span>
        )}
      </button>

      <div className="text-center mt-3">
        <Link to={ROUTES.forgotPassword} className="text-secondary">
          Olvidé mi contraseña
        </Link>
      </div>
    </form>
  );
};
