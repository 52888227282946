import { createRef, useEffect, useState } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import ReactCodeInput from 'react-verification-code-input';
import { MODAL_TYPES, useGlobalModalContext } from '../../../../providers/globalModal.provider';
import { AuthService } from '../../../../services';
import { LoginResponse } from '../../../../types/common';
import { OtpTimer } from '../otpTimer/otpTimer';

import { FlechaIzq } from '../../../svg';
import styles from './verifyOtp.module.scss';

type VerifyOtpProps = {
  phone: string;
  countryCode: string;
  submitTime: Date;
  goBack: () => void;
  onSuccess: (response: LoginResponse) => void;
  onResend: (token: string) => void;
};

const captchaRef = createRef<ReCAPTCHA>();

export const VerifyOtp = (props: VerifyOtpProps) => {
  const { showModal } = useGlobalModalContext();

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [codeHasError, setCodeHasError] = useState(false);
  const [code, setCode] = useState('');

  useEffect(() => {
    if (code) {
      setCodeHasError(false);
    }
  }, [code]);

  const onSubmit = async () => {
    if (!code) {
      setCodeHasError(true);
    }

    setIsSubmitting(true);
    setErrorMessage('');

    const response = await AuthService.validateOtpLogin(props.phone, code);

    setIsSubmitting(false);
    if (response.success) {
      props.onSuccess(response);
    } else {
      if (response.isAccountDisabled) {
        showModal(MODAL_TYPES.ERROR, {
          description: response.error,
          onClose: props.goBack,
        });
      } else {
        setErrorMessage(response.error || 'El código no es válido.');
      }
    }
  };

  const goBack = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    props.goBack();
  };

  const onResend = async () => {
    const token = await captchaRef.current?.executeAsync();

    if (!token) {
      setErrorMessage('Hubo un error re-enviando su códido. Por favor, intente de nuevo.');
      return;
    }

    props.onResend(token);
    captchaRef.current?.reset();
  };

  return (
    <div className="col-12">
      <p className="text-center mb-5">
        Introduce el código de verificación que hemos enviado al teléfono:
      </p>

      {errorMessage && !isSubmitting && (
        <div className="my-3">
          <Alert variant={'danger'}>{errorMessage}</Alert>
        </div>
      )}

      <div className={`mega-font-bold text-center ${styles.phoneWrapper}`}>
        <a href="#" className={styles.backArrow} onClick={goBack}>
          <FlechaIzq width={25} className="ms-2" />
        </a>

        <span className={`me-2 fi fi-${(props.countryCode || '').toLowerCase()}`}></span>
        {props.phone}
      </div>

      <div className="text-center">
        <ReactCodeInput
          type="number"
          fields={4}
          onComplete={(s) => {
            setCode(s);
          }}
          loading={false}
          className={`otpField ${codeHasError ? 'otpFieldError' : ''}`}
        />
        {codeHasError && <div className="text-danger text-center">El código es requerido</div>}
      </div>

      <div className="mt-4 w-100 text-center">
        <ReCAPTCHA
          ref={captchaRef}
          sitekey={process.env.REACT_APP_CAPTCHA_INVISIBLE_KEY || ''}
          hl={'es'}
          style={{ display: 'inline-block' }}
          size="invisible"
        />
      </div>

      <Button variant="secondary" className="py-2 w-100 mb-4 mt-5" onClick={onSubmit}>
        {isSubmitting ? (
          <Spinner
            as="span"
            animation="grow"
            size="sm"
            role="status"
            variant="light"
            aria-hidden="true"
          />
        ) : (
          <span>Validar</span>
        )}
      </Button>

      <OtpTimer seconds={35} onActionClick={onResend} disabledBtn={isSubmitting} />
    </div>
  );
};
