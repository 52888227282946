import * as React from 'react';
import { SVGProps } from 'react';
const SvgDeslizar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="deslizar_svg__deslizar"
    width={26}
    height={26}
    {...props}>
    <script>
      {'(\n            function hookGeo() {\n  //'}
      {'}\n          )();'}
    </script>
    <g id="deslizar_svg__Grupo_3935" data-name="Grupo 3935">
      <g
        id="deslizar_svg__Grupo_3934"
        data-name="Grupo 3934"
        transform="translate(.071 .114)"
        fill="#263645">
        <path
          id="deslizar_svg__Trazado_9395"
          data-name="Trazado 9395"
          d="M19.9 12.607a17.965 17.965 0 0 0-4.264-.832c-.039-1.157-.156-2.86-.26-4.225a2.672 2.672 0 0 0-2.223-2.522 6.462 6.462 0 0 0-2 0A2.648 2.648 0 0 0 8.939 7.55c-.1 1.625-.234 4-.273 6.656a28.968 28.968 0 0 0-1.287-.832 2.546 2.546 0 0 0-3.341.338A5.1 5.1 0 0 0 3 15.675a2.159 2.159 0 0 0 .312 1.807 27.762 27.762 0 0 0 5.876 6.461.781.781 0 1 0 .923-1.261 25.884 25.884 0 0 1-5.5-6.058.557.557 0 0 1-.1-.494 3.675 3.675 0 0 1 .741-1.391 1.055 1.055 0 0 1 1.339-.013c1.43.884 2.379 1.612 2.392 1.625a.779.779 0 0 0 1.261-.611c0-3.224.156-6.162.273-8.073a1.1 1.1 0 0 1 .871-1.079 5.316 5.316 0 0 1 1.573 0 1.147 1.147 0 0 1 .9 1.092c.182 2.366.273 4.1.273 4.849a.782.782 0 0 0 .78.78 16.125 16.125 0 0 1 4.537.793 2.23 2.23 0 0 1 1.539 2.028 13.12 13.12 0 0 1-1.573 6.825.772.772 0 0 0 .689 1.144.786.786 0 0 0 .689-.4 14.652 14.652 0 0 0 1.768-7.631 3.794 3.794 0 0 0-2.613-3.432Z"
          transform="translate(.804 1.371)"
        />
        <path
          id="deslizar_svg__Trazado_9396"
          data-name="Trazado 9396"
          d="M22.255 4.94s.026-.039.039-.065l.039-.078a.185.185 0 0 1 .026-.078c0-.026.013-.039.026-.065a.582.582 0 0 0 0-.3c0-.026-.013-.039-.026-.065a.185.185 0 0 0-.026-.078c0-.026-.026-.052-.039-.078s-.026-.039-.039-.065a.841.841 0 0 0-.1-.117L18.511.312a.793.793 0 0 0-1.1 0 .793.793 0 0 0 0 1.1l2.3 2.3H12.57a.78.78 0 0 0 0 1.56h7.137l-2.3 2.3a.793.793 0 0 0 0 1.105.77.77 0 0 0 .546.234.748.748 0 0 0 .546-.234l3.64-3.64s.065-.078.1-.117Z"
          transform="translate(3.466 -.088)"
        />
        <path
          id="deslizar_svg__Trazado_9397"
          data-name="Trazado 9397"
          d="m.126 4.8.039.078c0 .026.026.039.039.065s.065.078.091.117l3.64 3.64a.77.77 0 0 0 .546.234.748.748 0 0 0 .547-.234.793.793 0 0 0 0-1.1l-2.3-2.3h7.135a.78.78 0 0 0 0-1.56H2.739l2.3-2.3a.793.793 0 0 0 0-1.1.793.793 0 0 0-1.1 0L.3 3.959s-.07.078-.1.117c0 .013-.026.039-.039.065l-.039.078A.185.185 0 0 1 .1 4.3c0 .026-.013.039-.026.065a.582.582 0 0 0 0 .3c0 .026.013.039.026.065a.185.185 0 0 0 .026.07Z"
          transform="translate(-.055 -.082)"
        />
      </g>
    </g>
  </svg>
);
export default SvgDeslizar;
