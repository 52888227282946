import { Button } from 'react-bootstrap';
import { useTopUp } from '../../../context/TopupContext';
import { UtilService } from '../../../services';
import { TopUpInternetItem } from '../../../types/topupItems';
import { Formatter } from '../../../utilities';
import { EliminarItemListado, Operador, RecargarDatosInactive } from '../../svg';

import styles from './topUpCartItems.module.scss';

interface TopUpCartInternetItemProps {
  index: number;
  internetItem: TopUpInternetItem;
  allowToDelete?: boolean;
  isLast?: boolean;
}

export const TopUpCartInternetItem = ({
  index,
  internetItem,
  allowToDelete = true,
  isLast,
}: TopUpCartInternetItemProps) => {
  const { removeInternetItem } = useTopUp();

  const getContactName = () => {
    return internetItem.type === 'contact' ? internetItem.contact?.contact_name + ' ' : '';
  };

  const getPhone = () => {
    const phone =
      internetItem.type === 'contact'
        ? internetItem.contact!.contact_number
        : internetItem.phoneNumber;
    const country =
      internetItem.type === 'contact'
        ? internetItem.contact!.country_code
        : internetItem.country?.count_cod;

    const phoneFormatted = UtilService.getFormattedPhoneNumber(phone || '', country || '');

    return `${phoneFormatted}, `;
  };

  return (
    <div className={isLast ? '' : 'mb-4'}>
      <div className="d-flex flex-column flex-lg-row">
        <div className="flex-fill">
          <div className="d-flex flex-row">
            <div className="me-3">
              <RecargarDatosInactive fill={`#8ab934`} width={40} />
            </div>
            <div className="d-flex flex-column">
              <div>
                <span className={styles.lineTitle}>{getContactName()}</span>
                <span>{getPhone()}</span>
                <span className="text-secondary">
                  {internetItem.carrier.name} <Operador width={15} className="ms-1" />
                </span>
              </div>
              <div>
                <div className={styles.lineRecarga}>
                  Datos {internetItem.carrierRate.remote_amount}{' '}
                  {internetItem.carrierRate.remote_currency}
                </div>
                <div className="text-secondary">
                  {Formatter.toCurrency(internetItem.carrierRate.real_amount)}
                </div>

                <div>
                  Recibe{' '}
                  <span
                    dangerouslySetInnerHTML={{
                      __html: internetItem.carrierRate.description,
                    }}></span>
                </div>

                {!!internetItem.futurePromotionalDate && (
                <div>
                  <div className="mega-alert mt-2">
                    <p>
                      Comienza el <b>{internetItem.futurePromotionalDate}</b>
                    </p>
                  </div>
                </div>
                )}
              </div>
            </div>
          </div>
        </div>
        {allowToDelete && (
          <>
            <div className="d-none d-lg-block">
              <Button
                size="sm"
                className="mt-2 mt-lg-0 text-decoration-none"
                variant={'link'}
                onClick={() => removeInternetItem(index)}>
                <EliminarItemListado width={15} />
              </Button>
            </div>
            <div className="d-block d-lg-none text-end mt-2">
              <Button
                size="sm"
                className="mt-2 mt-lg-0 text-decoration-none text-primary"
                variant={'link'}
                onClick={() => removeInternetItem(index)}>
                Eliminar
              </Button>
            </div>
          </>
        )}
      </div>
      {!isLast && <hr />}
    </div>
  );
};
