import * as React from 'react';
import { SVGProps } from 'react';
const SvgVisa = (props: SVGProps<SVGSVGElement>) => (
  <svg data-name="Capa 2" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 65.09 20" {...props}>
    <g data-name="Capa 1">
      <path
        d="m12.36 10.3-1.77-8.58C10.32.69 9.33 0 8.27.15H.06L0 .56c6.38 1.53 10.6 5.25 12.36 9.74ZM18.32.34l-5.19 13.24-.54-1.99a20.76 20.76 0 0 0-7.35-8.04l4.73 16.18h5.6L23.92.34h-5.6Zm7.75 0-3.31 19.42h5.25L31.33.34h-5.26Zm34.74 0h-4.12a2.63 2.63 0 0 0-2.77 1.62L46.1 19.74h5.55s.9-2.39 1.11-2.92h6.77c.16.67.64 2.9.64 2.9h4.91L60.81.34Zm-6.52 12.51c.44-1.11 2.1-5.43 2.1-5.43s.44-1.11.7-1.85l.37 1.67 1.22 5.61h-4.39ZM42 8.12c-1.84-.89-2.97-1.5-2.96-2.4s.96-1.67 3.02-1.67c1.36-.04 2.71.22 3.96.75l.48.22.71-4.2C45.69.26 44.09-.01 42.48 0c-5.25 0-8.92 2.63-8.95 6.4 0 2.78 2.62 4.35 4.63 5.25s2.78 1.56 2.78 2.41c0 1.3-1.65 1.9-3.15 1.9-1.72.04-3.42-.32-4.98-1.05l-.68-.31-.75 4.35c1.89.7 3.89 1.06 5.9 1.05 5.55 0 9.16-2.6 9.21-6.63-.01-2.18-1.42-3.86-4.5-5.25Z"
        style={{
          fill: '#273991',
        }}
      />
      <path
        d="M10.58 1.72C10.32.69 9.33 0 8.27.15H.06L0 .56c6.38 1.54 10.6 5.25 12.36 9.75l-1.77-8.59Z"
        style={{
          fill: '#f99f1b',
        }}
      />
    </g>
  </svg>
);
export default SvgVisa;
