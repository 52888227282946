import api from './axios/api';
import { MegaProductCategory } from '../types/products/productCategory';
import { MegaProductType } from '../types/products/productType';
import { MegaProductLocation } from '../types/products/productLocation';
import { MegaStoreProduct } from '../types/reports/storeProduct';
import { Province } from '../types/province';
import { Municipality } from '../types/municipality';

const getProductCategories = async (): Promise<MegaProductCategory[]> => {
  const url = '/api/v1/store/getproductcategories';
  const response = await api.get<MegaProductCategory>(url);

  if (!response.has_error && response.array) {
    return response.array;
  }

  throw new Error(response.error);
};

const getProductTypes = async (categoryId: number): Promise<MegaProductType[]> => {
  const url = '/api/v1/store/getproducttypes';
  const response = await api.get<MegaProductType>(url, {
    params: { category: categoryId },
  });

  if (!response.has_error && response.array) {
    return response.array;
  }

  throw new Error(response.error);
};

const getProductLocations = async (categoryId: number): Promise<MegaProductLocation[]> => {
  const url = '/api/v1/store/getproductlocations';
  const response = await api.get<MegaProductLocation>(url, {
    params: { category: categoryId },
  });

  if (!response.has_error && response.array) {
    return response.array;
  }

  throw new Error(response.error);
};

interface ProductFilters {
  categoryId?: number;
  productLocation?: number;
  sortDirection?: string;
  sortField?: string;
}

const getProducts = async ({
  categoryId,
  productLocation,
  sortDirection,
  sortField,
}: ProductFilters): Promise<MegaStoreProduct[]> => {
  const url = '/api/v1/store/getproducts';

  const response = await api.get<MegaStoreProduct>(url, {
    params: {
      category: categoryId??0,
      location: productLocation??0,
      sort_dir: sortDirection ?? 'asc',
      sort_field: sortField ?? 'priority',
    },
  });

  if (!response.has_error && response.array) {
    return response.array;
  }

  throw new Error(response.error);
};

const getPromotedProducts = async (): Promise<MegaStoreProduct[]> => {
  const url = '/api/v1/store/getpromotedproducts';
  const response = await api.get<MegaStoreProduct>(url, {});

  if (!response.has_error && response.array) {
    return response.array;
  }

  throw new Error(response.error);
};

const getProductDetails = async (
  productId: string | number | undefined
): Promise<MegaStoreProduct> => {
  const url = `/api/v1/store/products/${productId}`;
  const response = await api.get<MegaStoreProduct>(url);

  if (!response.has_error && response.data) {
    return response.data;
  }

  throw new Error(response.error);
};

const getProvinces = async (): Promise<Province[]> => {
  const url = '/api/v1/store/getprovinces';
  const response = await api.get<Province>(url);

  if (!response.has_error && response.array) {
    return response.array;
  }

  throw new Error(response.error);
};

const getMunicipalities = async (provinceId: number): Promise<Municipality[]> => {
  const url = '/api/v1/store/getmunicipalities';
  const response = await api.get<Municipality>(url, {
    params: {
      province: provinceId,
    },
  });

  if (!response.has_error && response.array) {
    return response.array;
  }

  throw new Error(response.error);
};

export const StoreService = {
  getMunicipalities,
  getProductCategories,
  getProductDetails,
  getProductLocations,
  getProductTypes,
  getProducts,
  getPromotedProducts,
  getProvinces
};
