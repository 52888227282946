import * as React from 'react';
import { SVGProps } from 'react';
const SvgEnviarRecargas = (props: SVGProps<SVGSVGElement>) => (
  <svg
    id="enviar_recargas_svg__Capa_2"
    data-name="Capa 2"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 66 66"
    {...props}>
    <defs>
      <style>
        {'.enviar_recargas_svg__cls-2{fill:#8ab934}.enviar_recargas_svg__cls-3{fill:#263645}'}
      </style>
    </defs>
    <g id="enviar_recargas_svg__Capa_1-2" data-name="Capa 1">
      <path
        d="M9.37 4.81a7.34 7.34 0 0 0-6.32 6.67c-.54 6.63-.83 13.65-.83 20.91s.29 14.27.83 20.91a7.332 7.332 0 0 0 6.32 6.67c4.33.59 8.87.9 13.55.9s9.22-.31 13.55-.9a7.34 7.34 0 0 0 6.32-6.67c.54-6.63.83-13.65.83-20.91s-.29-14.27-.83-20.91a7.332 7.332 0 0 0-6.32-6.67c-4.33-.59-8.87-.9-13.55-.9s-9.22.31-13.55.9Z"
        style={{
          fill: '#d0e3ae',
        }}
      />
      <path
        className="enviar_recargas_svg__cls-3"
        d="M22.92 62.07c-4.64 0-9.25-.31-13.71-.92-3.93-.54-7.03-3.8-7.35-7.76-.55-6.8-.83-13.86-.83-21s.28-14.2.83-21c.32-3.96 3.41-7.22 7.35-7.76 8.91-1.21 18.51-1.21 27.42 0 3.93.54 7.03 3.8 7.35 7.76.55 6.8.83 13.86.83 21s-.28 14.2-.83 21c-.32 3.96-3.41 7.22-7.35 7.76-4.46.61-9.07.92-13.71.92ZM9.53 6c-2.83.39-5.06 2.74-5.29 5.59-.55 6.73-.83 13.74-.83 20.81s.28 14.07.83 20.81c.23 2.85 2.46 5.2 5.29 5.59 8.7 1.19 18.08 1.19 26.78 0 2.83-.39 5.06-2.74 5.29-5.59.55-6.73.83-13.73.83-20.81s-.28-14.07-.83-20.81c-.23-2.85-2.46-5.2-5.29-5.59-8.7-1.19-18.08-1.19-26.78 0Z"
      />
      <path
        className="enviar_recargas_svg__cls-2"
        d="M22.92 3.91c-3.72 0-7.35.24-10.86.69.04.78.09 1.56.14 2.33.15 2.19 1.57 3.97 3.41 4.26 2.34.38 4.78.58 7.31.58s4.97-.2 7.31-.58c1.84-.3 3.26-2.07 3.41-4.26.05-.77.1-1.54.14-2.33-3.51-.45-7.14-.69-10.86-.69Z"
      />
      <path
        className="enviar_recargas_svg__cls-3"
        d="M22.92 12.96c-2.54 0-5.06-.2-7.5-.59-2.41-.39-4.22-2.59-4.41-5.36-.05-.77-.1-1.55-.14-2.34l-.06-1.11 1.11-.14c7.24-.92 14.78-.92 22.02 0l1.11.14-.06 1.11c-.04.79-.09 1.57-.14 2.34-.19 2.77-2 4.97-4.41 5.36-2.44.39-4.96.59-7.5.59Zm-9.6-7.31c.02.4.05.8.08 1.19.11 1.63 1.12 2.96 2.41 3.16 4.62.75 9.61.75 14.24 0 1.28-.21 2.29-1.54 2.41-3.16.03-.39.05-.79.08-1.19-6.34-.72-12.87-.72-19.2 0ZM18.12 52.94c0-2.18 1.11-2.84 4.79-2.84s4.79.66 4.79 2.84-1.12 2.84-4.79 2.84-4.79-.66-4.79-2.84Z"
      />
      <circle className="enviar_recargas_svg__cls-2" cx={46.51} cy={44.83} r={17.27} />
      <path
        className="enviar_recargas_svg__cls-3"
        d="M46.51 63.29c-10.18 0-18.46-8.28-18.46-18.46s8.28-18.46 18.46-18.46 18.46 8.28 18.46 18.46-8.28 18.46-18.46 18.46Zm0-34.53c-8.86 0-16.07 7.21-16.07 16.07S37.65 60.9 46.51 60.9s16.07-7.21 16.07-16.07-7.21-16.07-16.07-16.07Z"
      />
      <path
        d="M50.7 44.28c-.49-.36-1.03-.66-1.62-.88-.59-.22-1.16-.42-1.71-.6-.38-.12-.72-.24-1.03-.35-.3-.11-.57-.24-.79-.38s-.39-.3-.5-.48c-.11-.18-.17-.41-.17-.68 0-.32.07-.58.22-.79s.32-.37.54-.5c.21-.13.45-.22.72-.26.27-.05.53-.07.79-.07.5 0 1.02.13 1.56.38.25.11.47.25.68.4.41.3.98.25 1.34-.12l1.01-1.07c.44-.47.34-1.22-.2-1.56-.47-.3-.98-.54-1.52-.74-.81-.28-1.6-.42-2.38-.46v-2.06c-1.06-1.2-2.4-.47-2.4.58v1.68c-.09.02-.18.03-.27.05-.74.19-1.41.49-2.01.9-.59.41-1.07.94-1.44 1.57-.36.64-.55 1.41-.55 2.3 0 .73.12 1.35.35 1.86.24.51.55.94.94 1.3.39.36.83.65 1.32.88s1 .43 1.52.59c.53.17 1.01.32 1.43.47.42.14.77.3 1.05.48.28.17.5.37.65.58.15.21.23.47.23.78 0 .32-.06.59-.18.82-.12.23-.28.41-.49.55-.21.14-.44.24-.72.3-.27.06-.55.09-.84.09-.64 0-1.28-.16-1.93-.49a5.75 5.75 0 0 1-.9-.57c-.41-.33-1-.31-1.37.07l-1.09 1.11c-.44.44-.38 1.17.13 1.54.53.38 1.13.7 1.81.95.77.29 1.56.47 2.37.55v2.62c1.06 1.21 2.4.47 2.4-.58v-2.12c.3-.05.6-.1.89-.19a5.83 5.83 0 0 0 1.98-.98c.58-.44 1.04-1 1.38-1.68.34-.68.51-1.48.51-2.41 0-.81-.16-1.48-.48-2.02-.32-.54-.72-.99-1.21-1.36Z"
        style={{
          fill: '#fff',
          fillRule: 'evenodd',
        }}
      />
    </g>
  </svg>
);
export default SvgEnviarRecargas;
